import React from "react";

import { CardContent, Card } from "@mui/material";

const PageLoginNotVerified = () => {
  return (
    <div className="pageBase">
      <img
        className="registrationPageLogoFrame"
        src="/assets/images/usertip-logo-color-vertical-transparent-v2.png"
        alt=""
      />

      <Card>
        <CardContent className="registrationCardContent">
          <h2>Your Account Is Not Verified.</h2>
          <p>
            You can login to your account{" "}
            <a className="registrationPageLink" href="/login">
              here
            </a>
            .
          </p>
        </CardContent>
      </Card>
    </div>
  );
};

export default PageLoginNotVerified;
