import { Button } from "usertip-component-library";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Walkthrough } from "../../../interfaces/interface Organization";
import { RootState } from "../../../store";
import { analyticsRetrieveDataForSingleWalkthrough } from "../../../store/reducers/reducerAnalytics";
import ComponentOverviewTimeSpent from "./ComponentOverviewTimeSpent";
import RefreshIcon from "@mui/icons-material/Refresh";

const ComponentOverviewDetailAnalytic = () => {
  const dispatch = useDispatch();

  const activeWalkthrough = useSelector(
    (state: RootState) => state.walkthrough.activeWalkthrough
  ) as Walkthrough;

  const lastUpdated = useSelector(
    (state: RootState) => state.analytics.lastUpdate
  );

  // /** Selection: publishedWalkthroughId */
  // const [selectionPublishedWalkthroughId, setSelectionPublishedWalkthroughId] =
  //   useState<string | null>(null);

  /** Org Id */
  const orgId = useSelector((state: RootState) => state.org.org_id);

  useEffect(() => {
    if (activeWalkthrough.walkthrough_id && orgId) {
      // @ts-ignore
      dispatch(
        analyticsRetrieveDataForSingleWalkthrough({
          walkthroughId: activeWalkthrough.walkthrough_id,
          apiKey: activeWalkthrough.api_key,
          orgId: orgId,
        })
      );
    }
  }, [activeWalkthrough.walkthrough_id]);

  const onRefreshClick = () => {
    if (activeWalkthrough.walkthrough_id && orgId) {
      // @ts-ignore
      dispatch(
        analyticsRetrieveDataForSingleWalkthrough({
          walkthroughId: activeWalkthrough.walkthrough_id,
          apiKey: activeWalkthrough.api_key,
          orgId: orgId!,
        })
      );
    }
  };
  return (
    <>
      <div className="mt-6 mb-3 flex items-baseline gap-4 content-center">
        <h3 className="pageHeader1">Overview Analytics</h3>
        <span className="refresh flex gap-2 items-center ml-auto">
          <p className="text-gray-400">Last Updated: {lastUpdated}</p>
          <Button
            color="primary"
            size="small"
            text="Refresh"
            variant="primary"
            icon={<RefreshIcon fontSize="small" />}
            iconLeft
            onClick={onRefreshClick}
          />
        </span>
      </div>
      {<ComponentOverviewTimeSpent />}
    </>
  );
};

export default ComponentOverviewDetailAnalytic;
