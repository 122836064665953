import { fromUnixTime } from "date-fns";
import { callPostlinkGoogleAccount } from "../../../axios/v2/callsAuth";
import { callPOSTRetrieveUserPermissionAccess } from "../../../axios/v2/callsOrganization";
import { callGETRetrieveUserAccountInformation } from "../../../axios/v2/callsUser";
import verifyPayload from "../../../utility/functions/verifyPayload";
import { setOrgPlanInformation } from "../reducerOrganization";

//function to link signed google account
export async function RegisterGoogleNewUser(
  googleToken: string,
  authResult: any
) {
  //link google account to get cognito ID
  const registerUser = await callPostlinkGoogleAccount(authResult);

  if (registerUser.data.hasOrg === false) {
    const registerUserData = {
      token: googleToken,
      email: authResult.email,
      cognitoId: registerUser.data.cognitoId,
      name: authResult.name,
      expiresIn: authResult.exp,
    };

    localStorage.setItem(
      "ut-google-register-user",
      JSON.stringify(registerUserData)
    );

    /**Set sign in type for get user info */
    localStorage.setItem("ut-signInType", "Google");
    /**set auth token for get user info */
    localStorage.setItem("ut-token", googleToken);

    window.location.replace("/new-register-create-new-org");
    return;
  }
}

/**function to link signed google account and get user info */
export async function authGoogleSignIn({
  authResult,
  googleToken,
  thunkApi,
  store,
}: {
  authResult: any;
  googleToken: string;
  thunkApi: any;
  store: any;
}) {
  /**Link google account to get cognito ID */
  const linkedAccount = await callPostlinkGoogleAccount(authResult);
  if (linkedAccount.data.success === true) {
    /**Set sign in type for get user info */
    localStorage.setItem("ut-signInType", "Google");
    /**set auth token for get user info */
    localStorage.setItem("ut-token", googleToken);
    /**Call api for getting user infp */
    const getUser = await callGETRetrieveUserAccountInformation(
      linkedAccount.data.cognitoId
    );

    /**If there's error found display alert */
    if (getUser.data.success === false) {
      /**If fail remove the token and sign in type */
      localStorage.removeItem("ut-signInType");
      localStorage.removeItem("ut-token");
      const result = {
        alert: {
          type: "error",
          message: "Failed to get user info",
        },
      };
      return thunkApi.rejectWithValue(result);
    }
    const user = getUser.data.payload.user;

    // retrieve user role information API
    const response = await callPOSTRetrieveUserPermissionAccess(user.org);
    // Abstract role for checking
    if (!response.data) throw response;

    const signed_payload = response.data.signed_payload;
    const verified_payload = await verifyPayload(signed_payload);

    /** Get user role from verified payload */
    const role = verified_payload ? verified_payload.role : null;
    /** Get user organization from verified payload */
    const getOrg = verified_payload.org;
    /** Get user access permissions from verified payload */
    const orgPlan = verified_payload.orgPlan;
    // Store AuthenticationResult & User details to localStorage
    user.role = role;
    store.dispatch(setOrgPlanInformation({ orgPlan: orgPlan }));

    const expireIn = fromUnixTime(authResult.exp).toString();
    localStorage.setItem("ut-expires", expireIn);
    localStorage.setItem("ut-cognitoid", linkedAccount.data.cognitoId);
    localStorage.setItem("ut-email", authResult.email);
    localStorage.setItem("auth", "true");
    localStorage.setItem("ut-user", JSON.stringify(user));
    //set sign type to google
    localStorage.setItem("ut-signInType", "Google");

    // window.location.replace("/");
    window.history.pushState({}, "", "/");
    return {
      auth: authResult,
      user: user,
    };
  }
}
