import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DefaultHeader from "../components/03-organisms/04-pageHeader/DefaultHeader";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import { getSingleWalkthroughReq } from "../store/reducers/reducerWalkthrough";
import WalkthroughOverviewCard from "../components/03-organisms/01-cards/WalkthroughOverviewCard";
import GuideOverviewCards from "../components/Templates/WalkthrougOverviewCard/WalkthroughOverviewCards";
import GlobalLoading from "../components/UI/Loading/GlobalLoading";
import {
  Button,
  InputField,
  PreviousIcon,
  Tooltip,
} from "usertip-component-library";
import EditIcon from "@mui/icons-material/Edit";
import { getSingleGuide, renameGuide } from "../store/reducers/reducerGuide";
import { Snap } from "../interfaces/interfaceGuide";
import ComponentPageHeaderLinksBackBtn from "../components/ComponentPageHeaderLinksBackBtn";

const PageGuidesOverview = () => {
  const { guideID } = useParams();
  const walkthroughData = useSelector(
    (state: RootState) => state.guide.activeGuideData.walkthrough
  );

  const snapData = useSelector(
    (state: RootState) => state.guide.activeGuideData.snap as Snap | null
  );

  const guideData = useSelector(
    (state: RootState) => state.guide.activeGuideData
  );

  const dispatch = useDispatch();
  useEffect(() => {
    // Grab the Walkthrough details based on "walkthrough_id", and then Grab the first instance of the results out of the Array to reply as a Walkthrough Object
    if (guideID) {
      dispatch(getSingleGuide(guideID));
    }
  }, [dispatch, guideID]);

  const [newGuideName, setNewGuideName] = useState("");
  const [isEditNameMode, setIsEditNameMode] = useState(false);

  const navigate = useNavigate();
  const onSelectOverviewCard = (cardName: string) => {
    if (cardName === "Walkthrough" && walkthroughData) {
      navigate(
        `/guides/walkthroughs/${walkthroughData.walkthrough_id}/settings/general`,
        { replace: true }
      );
    }
    if (cardName === "Video") {
      //TODO: Change to /guides/${guide_id}/video/${video_id}
      navigate(`/guides/videoguide/${"TO_ADD_VIDEO_GUIDE_ID"}`, {
        replace: true,
      });
    }
    if (cardName === "Picture" && snapData) {
      navigate(`/guides/snap/${snapData.snap_id}`, { replace: true });
    }
  };

  function getTagList() {
    const tagList = [] as string[];
    if (guideData.walkthrough && !guideData.walkthrough.is_deleted) {
      tagList.push("Walkthrough");
    }
    if (guideData.snap && !guideData.snap.is_deleted) {
      tagList.push("Snap");
    }
    return tagList;
  }
  return (
    <div className="pageBase">
      <ComponentPageHeaderLinksBackBtn
        btnURL={`/guides/all`}
        btnLabel={"Guides"}
        btnDisabled={false}
      />
      {!isEditNameMode ? (
        <div className="flex gap-2">
          <DefaultHeader
            headerTitle={`${guideData.name}`}
            tagList={getTagList()}
          />
          <div
            className="hover:cursor-pointer"
            onClick={() => {
              setNewGuideName(guideData.name!);
              setIsEditNameMode(true);
            }}
          >
            <Tooltip content="edit name" position="left" width="small">
              <EditIcon color="primary" />
            </Tooltip>
          </div>
        </div>
      ) : (
        <div className="flex gap-2">
          {/* generate input field and save button using usertip-component-library */}
          <InputField
            value={newGuideName}
            onChange={(e) => setNewGuideName(e.target.value)}
            placeholder="Enter new name"
            variant="text"
          />
          <Button
            onClick={() => {
              const renameData = {
                newGuideName: newGuideName,
                guideId: guideID!,
              };

              dispatch(renameGuide(renameData));
              setIsEditNameMode(false);
            }}
            size="small"
            color="primary"
            text="Save"
            variant="primary"
            disabled={newGuideName === "" || newGuideName === guideData.name}
          />
          <Button
            color="secondary"
            size="small"
            text="Cancel"
            variant="primary"
            onClick={() => setIsEditNameMode(false)}
          />
        </div>
      )}
      <GuideOverviewCards
        onSelectCard={onSelectOverviewCard}
        className="mt-5"
        walkthrough_data={
          walkthroughData && !walkthroughData.is_deleted
            ? walkthroughData
            : null
        }
        snap_data={snapData && !snapData.is_deleted ? snapData : null}
        video_guide_data={null}
        guideId={guideID!}
      />
      <GlobalLoading />
    </div>
  );
};

export default PageGuidesOverview;
