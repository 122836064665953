import React from "react";
import { Button } from "usertip-component-library";
import { authSignOutUserAccount } from "../../store/reducers/reducerAuth";

interface Props {
  isLogoutButton?: boolean;
  children?: React.ReactNode;
}

const WarningMessage = ({ isLogoutButton, children }: Props) => {
  return (
    <div className="px-20 py-8 w-full ">
      {/* usertip logo */}
      <div className="flex justify-between items-center">
        <img
          src="/assets/images/usertip-logo-color-horizontal-transparent-v2.png"
          alt="usertip logo"
          className="sidebarHeaderLogo"
        />
        {isLogoutButton && (
          <Button
            color="secondary"
            size="medium"
            text="Log Out"
            variant="primary"
            onClick={() => {
              authSignOutUserAccount();
            }}
          />
        )}
      </div>

      <div className="w-ful h-full flex justify-center items-center flex-col">
        {children}
      </div>
    </div>
  );
};

export default WarningMessage;
