import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface SelectedState {
  isEmpty: boolean;
  tabsOrgSuccess: number;
}

const initialState: SelectedState = {
  isEmpty: true,
  tabsOrgSuccess: 0,
};

const selectedSlice = createSlice({
  name: "selected",
  initialState,
  reducers: {
    // to disable confirm button on invite member modal if the role not selected
    setIsEmpty: (state, action: PayloadAction<boolean>) => {
      state.isEmpty = action.payload;
    },
    // to select tabs after adding new member by using invite member button on sidebar
    setTabsOrgSuccess: (state, action: PayloadAction<number>) => {
      state.tabsOrgSuccess = action.payload;
    },
  },
});

export const { setIsEmpty, setTabsOrgSuccess } = selectedSlice.actions;

export default selectedSlice.reducer;
