import {
  Card,
  CardHeader,
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React, { useState } from "react";
import ComponentSearch from "./ComponentSearch";
import { not, numberOfChecked, union } from "./_functions/FunctionEditList";

const ComponenentEditSingleSharedWalkthrough = ({
  dataSet,
  checked,
  setChecked,
  selectedRow,
}) => {
  const [searchInput, setSearchInput] = useState("");
  const [prevSelected, setPrevSelected] = useState(selectedRow);

  const handleToggleAll = (lists) => () => {
    if (numberOfChecked(checked, lists) === lists.length) {
      setChecked(not(checked, lists));
    } else {
      setChecked(union(checked, lists));
    }
  };

  // Handles each Toggle of Checkbox-Checked/UnChecked
  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  //TODO: make list checked based on previous selected list
  // const handleChecked = (index, itemID, item) => {
  //   if (index < prevSelected.length) {
  //     if (prevSelected[index].walkthrough_id == itemID) {
  //       console.log("this run");
  //       setChecked(checked.push(prevSelected[index]));
  //       return true;
  //     }
  //   } else {
  //     return checked.indexOf(item) !== -1;
  //   }
  // };

  //handle search bar
  const handleSearch = (e) => {
    e.preventDefault();
    setSearchInput(e.target.value);
  };

  return (
    <div className="flex-1 w-44">
      <ComponentSearch handleSearch={handleSearch} />
      <Card>
        <div className="flex flex-col">
          <div className="flex-row flex-grow w-full">
            <CardHeader
              avatar={
                <Checkbox
                  onClick={handleToggleAll(dataSet)}
                  inputProps={{
                    "aria-label": "all item selected",
                  }}
                />
              }
              title={"Walkthroughs"}
              sx={{
                padding: "10px 10px",
              }}
            />
          </div>

          <div className="flex-row pageDivider my-0" />

          <div className="w-full">
            <List
              sx={{
                height: 230,
                bgcolor: "background.paper",
                overflow: "auto",
              }}
              dense
              component="div"
              role="list"
            >
              {dataSet !== null && dataSet.length > 0
                ? dataSet
                    .filter((list) => {
                      if (searchInput === "") {
                        //if query is empty
                        return list;
                      } else if (
                        list.name
                          .toLowerCase()
                          .includes(searchInput.toLowerCase())
                      ) {
                        return list;
                      }
                      return false;
                    })
                    .map((item, index) => {
                      return (
                        <div key={index}>
                          <ListItem
                            role="listitem"
                            button
                            onClick={
                              // Must push the entire Member Object in for the list to work
                              handleToggle(item)
                            }
                            sx={{
                              padding: "0px 10px",
                            }}
                          >
                            <ListItemIcon>
                              <Checkbox
                                checked={
                                  // Using the entire Member Object as a Index check
                                  // handleChecked(index, item.walkthrough_id, item)
                                  checked.indexOf(item) !== -1
                                }
                                tabIndex={-1}
                                // for cypress testing
                                test-shared-walkthrough={index}
                                disableRipple
                              />
                            </ListItemIcon>
                            <ListItemText
                              primary={item.name}
                              walkthrough_id={item.walkthrough_id}
                            />
                          </ListItem>
                        </div>
                      );
                    })
                : null}

              <List />
            </List>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default ComponenentEditSingleSharedWalkthrough;
