// https://app.usertip.com/quickBuilder/share/:publicId/5aa86b74-6cf5-4f6c-9068-f79c1d9185da

import React, { useEffect } from "react";
import GlobalLoading from "../components/UI/Loading/GlobalLoading";
import DefaultHeader from "../components/03-organisms/04-pageHeader/DefaultHeader";
import PublicPictureViewers from "../components/Templates/PictureViewer/PublicPictureViewers";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import {
  getPublicSingleSnap,
  getSingleSnapGuide,
  verifySnapShareLink,
} from "../store/reducers/reducerGuide";
import { useParams } from "react-router-dom";
import ForbiddenPermissionView from "../components/Snap/ForbiddenPermissionView";

const PagePublicSnapGuide = () => {
  const snapName = useSelector(
    (state: RootState) => state.guide.snapGuide?.name
  );

  const { appLoading, isPublicSnap } = useSelector(
    (state: RootState) => state.guide
  );

  const dispatch = useDispatch();
  const { shareId, guideId } = useParams();
  console.log(shareId, guideId, "shareId, guideId");

  //call to check if snap is for public viewing
  useEffect(() => {
    if (shareId && guideId) {
      const reducerParam = {
        shareId: shareId,
        snapId: guideId,
        isNeedToken: false,
      };
      dispatch(verifySnapShareLink(reducerParam));
    }
  }, [dispatch, shareId, guideId]);

  useEffect(() => {
    // Grab the Walkthrough details based on "walkthrough_id", and then Grab the first instance of the results out of the Array to reply as a Walkthrough Object
    if (guideId) {
      /**Dispatch walkthrough name */
      console.log("guideId", guideId);
      dispatch(getPublicSingleSnap(guideId));
    }
  }, [dispatch, guideId]);
  return (
    <>
      <GlobalLoading />
      {isPublicSnap ? (
        <div className="pageBase">
          <DefaultHeader
            headerTitle={`Picture guide for: ${snapName ? snapName : ""}`}
          />
          <div className="flex justify-center w-full mt-4">
            <PublicPictureViewers />
          </div>
        </div>
      ) : (
        <ForbiddenPermissionView />
      )}
    </>
  );
};

export default PagePublicSnapGuide;
