import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../store";
import ComponentPaymentSetting from "../components/ComponentPaymentSetting";
import ComponentPlanDetails from "../components/OrgPlan/ComponentPlanDetails";
import ComponentAlertAppOverallSnackbar from "../components/ComponentAlertAppOverallSnackbar";
import { resetAlert } from "../store/reducers/reducerPayment";
import BackgroundSwirlWrapper from "../components/01-atoms/BackgroundSwirlWrapper";
import ComponentStripeCheckout from "../components/Payment/ComponentStripeCheckout";
import ComponentInvoiceList from "../components/Payment/ComponentInvoiceList";
import { organizationGetOrganizationInfo } from "../store/reducers/reducerOrganization";

const PageBillingSettings = () => {
  const dispatch = useDispatch();

  const { org_id } = useSelector((state: RootState) => state.org);

  /** Alert is triggered when something goes wrong in payment reducer */
  const alert = useSelector((state: RootState) => state.payment.alert);

  // Pull any new org data for org plan info
  useEffect(() => {
    console.log("---PageBillingSettings", org_id);
    if (org_id) {
      dispatch(organizationGetOrganizationInfo(org_id));
    }
  }, [org_id]);

  return (
    <>
      <div className="pageBase">
        <BackgroundSwirlWrapper>
          <div className="pageHeader">Billing Information</div>
          <ComponentPaymentSetting />
        </BackgroundSwirlWrapper>
        <div className="pageDivider" />
        <BackgroundSwirlWrapper>
          <div className="pageHeader">Plan Information & Usage</div>
          <ComponentPlanDetails planList={"all"} />
        </BackgroundSwirlWrapper>

        <div className="pageDivider" />
        <BackgroundSwirlWrapper>
          <ComponentInvoiceList />
        </BackgroundSwirlWrapper>
      </div>
      {alert.type !== "" && alert.type !== undefined && (
        <ComponentAlertAppOverallSnackbar
          alert={alert}
          reset={() => dispatch(resetAlert())}
        />
      )}
    </>
  );
};

export default PageBillingSettings;
