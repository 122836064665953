import { CircularProgress } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { getImageListScroll } from "../../../store/reducers/reducerMedia";
import ImageCardContainer from "../../03-organisms/04-ImageCardContainer/ImageCardContainer";
import { Spinner } from "usertip-component-library";

interface Props {
  sortBy: "dateCreated" | "name";
  sort: number;
  name: string;
  isBulkDeleteMode: boolean;
  imagePerCol: number;
  handleDeleteBulkImage: (
    event: React.ChangeEvent<HTMLInputElement>,
    mediaId: string
  ) => void;
}

const InfinityScrollImageList = ({
  sortBy,
  sort,
  name,
  isBulkDeleteMode,
  imagePerCol,
  handleDeleteBulkImage,
}: Props) => {
  const IMAGE_LIST_LIMIT = imagePerCol * 2;
  const gridStyle = {
    gridTemplateColumns: `repeat(${imagePerCol}, minmax(0, 1fr))`,
  };

  const loader = useRef<null | HTMLDivElement>(null);
  const [items, setItems] = useState(0);
  const [observer, setObserver] = useState<IntersectionObserver>();
  const imageListScroll = useSelector(
    (state: RootState) => state.media.imageListScroll
  );
  const cognitoId = useSelector(
    (state: RootState) => state.user.userData.cognito_id
  );
  const imageListScrollLoading = useSelector(
    (state: RootState) => state.media.imageListScrollLoading
  );

  const isObserverOn = useSelector((state: RootState) => state.media.observer);

  const dispatch = useDispatch();
  const handleObserver = useCallback((entries) => {
    const target = entries[0];
    if (target.isIntersecting) {
      /**load cards everytime target.isInteracting true and loading is false */
      setItems((prev) => prev + imagePerCol * 2);
    }
  }, []);

  useEffect(() => {
    if (!isObserverOn) {
      observer?.disconnect();
    }
  }, [isObserverOn]);
  useEffect(() => {
    const imageData = {
      items: items,
      cognitoId: cognitoId,
      sortBy: sortBy,
      sort: sort,
      name: name,
      limit: IMAGE_LIST_LIMIT,
    };

    if (items > 0) {
      dispatch(getImageListScroll(imageData));
    }
  }, [items, sortBy, sort, name]);

  useEffect(() => {
    if (isObserverOn) {
      const option = {
        root: null,
        rootMargin: "20px",
        threshold: 0,
      };
      const observer = new IntersectionObserver(handleObserver, option);
      setObserver(observer);
      if (loader.current) observer.observe(loader.current);
    }
  }, [handleObserver]);
  return (
    <>
      {imageListScroll.length > 0 && (
        <div
          className="w-full grid mt-4 auto-rows-auto gap-4"
          style={gridStyle}
        >
          {imageListScroll.map((val) => {
            return (
              <ImageCardContainer
                key={val.media_id}
                isBulkDeleteMode={isBulkDeleteMode}
                handleDeleteBulkImage={handleDeleteBulkImage}
                date_modified={val.date_modified}
                date_uploaded={val.date_uploaded}
                img_name={val.img_name}
                img_url={val.img_url}
                media_id={val.media_id}
                owner_email={val.owner_email}
              />
            );
          })}
        </div>
      )}
      {imageListScrollLoading && (
        <div className="flex justify-center mt-4">
          {/* <CircularProgress color="primary" /> */}
          <div className="ml-[-200px]">
            <Spinner size="l" />
          </div>
        </div>
      )}
      <div ref={loader} />
    </>
  );
};

export default InfinityScrollImageList;
