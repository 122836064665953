import { Paper } from "@mui/material";
import React from "react";

const ComponentCard = ({
  cardType,
  cardValue,
  cardText,
  percentage,
}: {
  cardType: "normal" | "percentage";
  cardValue: string;
  cardText: string;
  percentage?: string;
}) => {
  const CardNormal = () => {
    return (
      <Paper
        elevation={6}
        sx={{
          padding: "1em",
          margin: "0.5em",
          maxWidth: "200px",
          minWidth: "150px",
        }}
      >
        <div className="overview-firstTimeView">
          <div className="flex items-center">
            {/* <PreviewIcon sx={{ fontSize: "45px" }} /> */}
            <p className="text-default-primary text-3xl font-bold ">
              {cardValue}
            </p>
          </div>
          <p className="font-medium text-slate-400 text-base">{cardText}</p>
        </div>
      </Paper>
    );
  };

  const CardPercentage = () => {
    return (
      <Paper
        elevation={6}
        sx={{
          padding: "1em",
          margin: "0.5em",
          maxWidth: "200px",
          minWidth: "150px",
        }}
      >
        <div className="overview-firstTimeView">
          <div className="flex items-center">
            {/* <PreviewIcon sx={{ fontSize: "45px" }} /> */}
            <p className="text-default-primary text-3xl font-bold ">
              {percentage}
              %
              <br />({cardValue} times)
            </p>
          </div>
          <p className="font-medium text-slate-400 text-base">{cardText}</p>
        </div>
      </Paper>
    );
  };

  return cardType === "normal" ? <CardNormal /> : <CardPercentage />;
};

export default ComponentCard;
