import { Button } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { HostWalkthrough } from "../interfaces/interface Organization";
import { RootState } from "../store";
import { organizationPostDuplicateWalkthrough } from "../store/reducers/reducerOrganization";
import ComponentDuplicateList from "./ComponentDuplicateList";
import ComponentModal from "./ComponentModal";
import { EmptyModal } from "usertip-component-library";

interface Props {
  hostListWalkthrough: HostWalkthrough[];
  userOrgID: string;
  setShowModalAdd: React.Dispatch<React.SetStateAction<boolean>>;
  showModalAdd: boolean;
}

const ComponentDuplicateWalkthrough = ({
  hostListWalkthrough,
  userOrgID,
  setShowModalAdd,
  showModalAdd,
}: Props) => {
  const [checked, setChecked] = useState<HostWalkthrough[]>([]);
  const ownerID = useSelector(
    (state: RootState) => state.user.userData.cognito_id
  );
  const dispatch = useDispatch();

  // Modal close handler
  const handleHideModal = () => {
    setShowModalAdd(false);
    setChecked([]);
  };

  const handleDuplicateWalkthrough = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const orgData = {
      org_id: userOrgID,
      owner: ownerID,
      duplicateWalkthrough: checked,
    };

    //@ts-ignore
    dispatch(organizationPostDuplicateWalkthrough(orgData));
    handleHideModal();
  };

  return (
    <EmptyModal
      isOpen={showModalAdd}
      onClose={handleHideModal}
      customWidthClass="w-[860px] p-5"
    >
      <div className="modalHeader">Duplicate Walkthrough</div>

      <div className="modalSubHeader"></div>
      <form
        noValidate
        autoComplete="off"
        onSubmit={handleDuplicateWalkthrough}
        className="modalForm"
      >
        <div className="inline-flex mb-8 gap-2">
          <ComponentDuplicateList
            checked={checked}
            setChecked={setChecked}
            hostListWalkthrough={hostListWalkthrough}
          />
        </div>
        <div className="modalActionBtnBar">
          <Button
            variant="contained"
            className="modalActionBtn"
            onClick={handleHideModal}
          >
            Cancel
          </Button>
          <Button
            disabled={!checked || checked.length === 0 ? true : false}
            variant="contained"
            color="primary"
            className="modalActionBtn"
            type="submit"
          >
            Confirm
          </Button>
        </div>
      </form>
    </EmptyModal>
  );
};

export default ComponentDuplicateWalkthrough;
