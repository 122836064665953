import React from "react";
import Title from "../../01-atoms/01-typography/Title";

interface Props {
  msgText: string;
}
const StepName = ({ msgText }: Props) => {
  return (
    <Title header="h2" size="l" classNames="!mb-0 ml-4" messageText={msgText} />
  );
};

export default StepName;
