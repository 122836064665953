import React from "react";

import { Button } from "usertip-component-library";

const PageUserCreateNewOrg = () => {
  return (
    <>
      <section className="m-auto">
        <div className="m-auto w-full">
          <div className="flex flex-col justify-center items-center mb-14">
            <h2 className="text-heading-m text-center text-default-neutral-100 mb-3">
              You're currently not part of any organization
            </h2>

            <h2 className="text-heading-m text-center text-default-neutral-100 mb-3">
              CONTACT <a href="mailto:">support@usertip.com</a> if you wish to
              join an existing org
            </h2>
            <h2 className="text-heading-m text-center text-default-neutral-100 mb-3">
              Otherwise click below to setup your own organization
            </h2>
            <div className="mt-14">
              <Button
                color="primary"
                size="medium"
                text="Confirm"
                variant="primary"
                //TODO: click to open modal
                //   onClick={() => setPageState("success")}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PageUserCreateNewOrg;
