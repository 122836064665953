import React from "react";

import { useDispatch } from "react-redux";
import { deleteSingleWalkthroughReq } from "../store/reducers/reducerWalkthrough";
import { EmptyModal, Button } from "usertip-component-library";

const ComponentRemoveWalkthroughs = ({
  selectedWalkthroughList,

  showModalRemove,
  setShowModalRemove,

  removeMode,

  setResetTableSelectAll,
}) => {
  // state from redux
  const dispatch = useDispatch();
  // Handle Removal of selected walkthroughs after submission click
  const handleConfirmRemoveWalkthroughsFormSubmission = (e) => {
    e.preventDefault();
    e.stopPropagation();

    // Handles Removal submission where appropriate
    if (removeMode === "single") {
      const walkthrough_id = selectedWalkthroughList[0].walkthrough_id;

      dispatch(deleteSingleWalkthroughReq(walkthrough_id));
      handleHideModal();
    } else if (removeMode === "bulk") {
      if (selectedWalkthroughList) {
        for (let i = 0; i < selectedWalkthroughList.length; i++) {
          //TODO: ADD, BULK DELETE WALKTHROUGH
          const walkthrough_id = selectedWalkthroughList[i].walkthrough_id;
          dispatch(deleteSingleWalkthroughReq(walkthrough_id));
        }

        // Resets the "Select All" control on the table.
        setResetTableSelectAll(true);
        handleHideModal();
      }
    }
  };

  // Modal close handler
  const handleHideModal = () => {
    setShowModalRemove(false);
  };

  return (
    <EmptyModal
      isOpen={showModalRemove}
      onClose={handleHideModal}
      size="large"
      customClass="ut-py-5"
    >
      <div className="modalHeader">
        {removeMode === "single"
          ? `Confirm Removal of Walkthrough?`
          : removeMode === "bulk"
          ? `Confirm Removal of Multiple Walkthrough(s)?`
          : ""}
      </div>

      {removeMode === "single" && (
        <>
          <div className="modalSubHeader">
            WARNING: You have selected <i>“Delete”</i> to remove{" "}
            <b>{`${
              selectedWalkthroughList[0] ? selectedWalkthroughList[0].name : ""
            }`}</b>
            , please confirm to proceed or select cancel to return to the
            previous page.
          </div>
        </>
      )}
      {removeMode === "bulk" && (
        <>
          <div className="modalSubHeader">
            WARNING: You have selected to <i>“Bulk Delete”</i> the following
            team(s), please confirm to proceed or select cancel to return to the
            previous page.
          </div>

          <ol className="list-decimal list-inside mb-8">
            {selectedWalkthroughList &&
              selectedWalkthroughList.map((walkthrough) => (
                <li key={walkthrough.name ? walkthrough.name : ""}>
                  {`${walkthrough.name ? walkthrough.name : ""}`}
                </li>
              ))}
          </ol>
        </>
      )}

      <form
        noValidate
        autoComplete="off"
        onSubmit={handleConfirmRemoveWalkthroughsFormSubmission}
        className="modalForm"
      >
        <div className="flex gap-2 w-1/3 ml-auto">
          <Button
            variant="primary"
            color="primary"
            size="fluid"
            text="Cancel"
            onClick={handleHideModal}
          />
          <Button variant="primary" color="primary" size="fluid" text="Yes" />
        </div>
      </form>
    </EmptyModal>
  );
};

export default ComponentRemoveWalkthroughs;
