import { format, isSameWeek } from "date-fns";

export const convertDateToStandardFormat = (inputDate) => {
  return format(new Date(inputDate), "dd MMM yyyy");
};

// https://stackoverflow.com/questions/1197928/how-to-add-30-minutes-to-a-javascript-date-object
export const addBySecond = (date, minutes) => {
  return new Date(date.getTime() + minutes * 1000).toString();
};

export const checkSameWeek = (firstDate, secondDate) => {
  return isSameWeek(firstDate, secondDate);
};
