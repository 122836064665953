/**Page for inviting existing user to new organization as viewer  */
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ComponentRegisterViewerSuccess from "../components/RegisterAsViewer/ComponentRegisterViewerSuccess";
import { Button } from "usertip-component-library";
import * as jose from "jose";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import {
  orgInviteUserToOrg,
  setChangeOrgStatus,
} from "../store/reducers/reducerOrganization";
import PageNotFound from "./PageNotFound";
import GlobalLoading from "../components/UI/Loading/GlobalLoading";
import ComponentRegistrationByInvite from "../components/ComponentRegistrationByInvite";
import { UserDataForInvitationToOrg } from "../interfaces/interface Organization";
import PageInviteUserToDifferentOrgAsAdmin from "./PageInviteUserToDifferentOrgAsAdmin";

const PageInviteUserToDIffOrg = ({ isLoggedIn }: { isLoggedIn: boolean }) => {
  const navigate = useNavigate();
  let { identifier, token } = useParams();

  const [jwtData, setJwtData] = useState<UserDataForInvitationToOrg | null>(
    null
  );
  const [pageState, setPageState] = useState<"inviteForm" | "success">(
    "inviteForm"
  );
  const [isUserVerified, setIsUserVerified] = useState<boolean>(false);

  useEffect(() => {
    if (
      isLoggedIn === false &&
      jwtData?.invitedRole &&
      jwtData.invitedRole !== "viewer"
    ) {
      /**If user is not logged in, store the identifier and token in local storage */
      localStorage.setItem("ut_identifier", identifier!);
      localStorage.setItem("ut_inviteToken", token!);
      //Redirect to login page
      navigate("/login");
    }
  }, [isLoggedIn]);
  const orgID = useSelector((state: RootState) => state.user.userData.org);
  const cognitoId = useSelector(
    (state: RootState) => state.user.userData.cognito_id
  );
  const changeOrgStatus = useSelector(
    (state: RootState) => state.org.changeOrgStatus
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (changeOrgStatus === true) {
      setPageState("success");
    }
    return () => {
      dispatch(setChangeOrgStatus(false));
    };
  }, [changeOrgStatus]);
  // Requires public key to decrypt
  let key = "secret";
  // jose: jwtVerify
  // REF: https://github.com/panva/jose/blob/main/docs/functions/jwt_verify.jwtVerify.md#readme
  // Converting key to required Uint8Array format
  //@ts-ignore
  let uint8arrayKey = new TextEncoder("utf-8").encode(key);

  // Actively grab and verify existing url query
  useEffect(() => {
    // Only execute verification when "jwtData" is null, else will end up with an endless loop.
    if (jwtData === null && isLoggedIn && cognitoId) {
      jose
        .jwtVerify(token!, uint8arrayKey, {
          algorithms: ["HS384"],
          typ: "JWT",
        })
        .then((response) => {
          //check if user are verified to view page based on figma board
          const checkIfUserVerified = (jwtData: UserDataForInvitationToOrg) => {
            if (
              jwtData.code === "ALLOW_INVITE_TO_ORG" &&
              jwtData.code === "ALLOW_INVITE_TO_ORG" &&
              jwtData.cognito_id === cognitoId &&
              jwtData.isRegistered === true
            ) {
              setIsUserVerified(true);
            }
          };

          //@ts-ignore
          checkIfUserVerified(response.payload);
          //@ts-ignore
          setJwtData(response.payload);
        })
        .catch((err) => console.log(`err: ${err}`));
    }

    if (jwtData === null && !isLoggedIn) {
      //get jwt token and identifier from local storage

      jose
        .jwtVerify(token!, uint8arrayKey, {
          algorithms: ["HS384"],
          typ: "JWT",
        })
        .then((response) => {
          //@ts-ignore
          setJwtData(response.payload);
        })
        .catch((err) => console.log(`err: ${err}`));
    }
  }, [token, uint8arrayKey, jwtData, cognitoId]);

  console.log("jwtData", jwtData);

  /**Submit handler */
  function onSubmitOrg() {
    const localIdentifier = localStorage.getItem("ut_identifier");
    const params = {
      role_type: jwtData?.invitedRole,
      cognito_id: cognitoId,
      email: jwtData?.email,
      new_org_id: jwtData?.invitedOrgId,
      //if user is not logged in, use the identifier from the local storage
      identifier: isLoggedIn ? identifier : localIdentifier,
    };
    //@ts-ignore
    dispatch(orgInviteUserToOrg(params));
  }

  /**Element for if user success register to new org */
  if (pageState === "success") {
    return (
      <ComponentRegisterViewerSuccess
        registerType="existingUserRegister"
        jwtData={jwtData!}
      />
    );
  }

  /**Element for invite user to viewer */
  if (jwtData?.code === "ALLOW_VIEWER_INVITE_TO_ORG") {
    return (
      <>
        <GlobalLoading />
        <section className="m-auto">
          <div className="m-auto w-full">
            <div className="flex flex-col justify-center items-center mb-14">
              <h2 className="text-heading-m text-center text-default-neutral-100 mb-3">
                You're invited to join {jwtData?.invitedOrgName} as a{" "}
                {jwtData?.invitedRole}
              </h2>
              <div className="mt-14">
                <Button
                  color="primary"
                  size="medium"
                  text="Confirm"
                  variant="primary"
                  onClick={() => onSubmitOrg()}
                />
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }

  /**Element for inviting user to org as ADMIN/BUILDER */
  if (jwtData?.code === "ALLOW_INVITE_TO_ORG") {
    return (
      <PageInviteUserToDifferentOrgAsAdmin
        jwtData={jwtData!}
        onSubmitOrg={onSubmitOrg}
      />
    );
  }

  /**Element/ page not found if user cognito is not the same with user cognito from jwt data (user not verified) */
  if (jwtData && !isUserVerified && isLoggedIn) {
    return <PageNotFound />;
  }
  return <></>;
};

export default PageInviteUserToDIffOrg;
