import React from "react";
import { Toggle, ToggleItem } from "usertip-component-library";

interface Props {
  /** Prop for set value of the input (selected value) */
  value: string;
  /** Prop for handling the toggle  */
  settingHandler: (value: string) => void;
}

const SlideoutAnimationSetting = ({ value, settingHandler }: Props) => {
  return (
    <>
      <h1 className="text-sm">Slideout Animation position:</h1>
      <Toggle name="animation-position" width="full">
        <ToggleItem
          label="LEFT"
          id="left"
          value="ut-slideout-left"
          selectedValue={value}
          onSelectedValue={settingHandler}
        />
        <ToggleItem
          label="BOTTOM"
          id="bottom"
          value="ut-slideout-bottom"
          selectedValue={value}
          onSelectedValue={settingHandler}
        />

        <ToggleItem
          label="RIGHT"
          id="right"
          value="ut-slideout-right"
          selectedValue={value}
          onSelectedValue={settingHandler}
        />
      </Toggle>
    </>
  );
};

export default SlideoutAnimationSetting;
