import React from "react";

import { Switch } from "@mui/material";

interface Props {
  assistantPublishStatus: boolean;
  setAssistantPublishStatus: React.Dispatch<React.SetStateAction<boolean>>;
}

const ComponentSettingsAssistantStatus = ({
  assistantPublishStatus,
  setAssistantPublishStatus,
}: Props) => {
  /** Handler for switch change */
  const changeHanlder = (e: any) => {
    setAssistantPublishStatus(e.target.checked);
  };

  return (
    <div className="flex items-center justify-between">
      <p>Activate Usertip Assistant:</p>
      <Switch
        aria-label={"usertip-assistant-status"}
        checked={assistantPublishStatus}
        onChange={changeHanlder}
      />
    </div>
  );
};

export default ComponentSettingsAssistantStatus;
