import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { UserInteractivityProcDataSet } from "../../../interfaces/interfaceAnalytic";
import { RootState } from "../../../store";
import groupUserInteraction from "../../_functions/FunctionGroupUserInteraction";
import ComponentUserInteractionTable from "./Table/ComponentUserInteractionTable";

const ComponentUserInteraction = () => {
  const [procDataSet, setProcDataSet] =
    useState<UserInteractivityProcDataSet[]>();

  //set state for filtering by meta data
  const [filteredDataSet, setFilteredDataSet] = useState<
    UserInteractivityProcDataSet[]
  >([]);

  const walkthroughSessionData = useSelector(
    (state: RootState) => state.analytics.walkthroughSessions
  );

  useEffect(() => {
    if (walkthroughSessionData) {
      const rawData = [...walkthroughSessionData];
      const groupData = groupUserInteraction(rawData);
      setProcDataSet(groupData);
    }
  }, [walkthroughSessionData]);

  useEffect(() => {
    if (procDataSet !== undefined) {
      setFilteredDataSet(procDataSet);
    }
  }, [procDataSet]);
  return (
    <>
      {procDataSet && (
        <ComponentUserInteractionTable
          dataSet={procDataSet}
          setFilteredDataSet={setFilteredDataSet}
          filterDataSet={filteredDataSet}
        />
      )}
    </>
  );
};

export default ComponentUserInteraction;
