import React, { useEffect, useState } from "react";
import WalkthroughOverviewCard from "../../03-organisms/01-cards/WalkthroughOverviewCard";
import { Walkthrough } from "../../../interfaces/interface Organization";
import { Snap } from "../../../interfaces/interfaceGuide";
import ModalSnapSharingSetting from "../../03-organisms/07-sharingSnap/ModalSnapSharingSetting";
import { useDispatch } from "react-redux";
import { postUpdateSnapSharingPermission } from "../../../store/reducers/reducerGuide";
import ComponentAlertSnackbar from "../../ComponentAlertSnackbar";
interface Props {
  /** Walkthrough Data, if null means the quick build guide did not have walkthrough option selected */
  walkthrough_data: Walkthrough | null;
  /** Snap Data, if null means the quick build guide did not have snap guide option selected */
  snap_data: Snap | null;
  /** Video Guide Data, if null means the quick build guide did not have video guide option selected */
  video_guide_data: any | null;
  className?: string;
  guideId: string;
  onSelectCard: (cardName: string) => void;
}

const GuideOverviewCards = ({
  walkthrough_data,
  snap_data,
  video_guide_data,
  className,
  onSelectCard,
  guideId,
}: Props) => {
  const dispatch = useDispatch();

  /**state for showing modal permission */
  const [showModalShare, setShowModalShare] = useState(false);
  /**state for selected value of sharing permission*/
  const [sharingSnapSelectedValue, setSharingSnapSelectedValue] = useState("");

  useEffect(() => {
    if (snap_data?.option) setSharingSnapSelectedValue(snap_data.option);
  }, [snap_data?.option]);

  /**handler for showing sharing permission modal */
  const onClickSnapeShare = () => {
    setShowModalShare(true);
  };

  /**handler for confirming sharing permission and api interaction */
  const onConfirmShareOption = () => {
    const dispatchData = {
      snapId: snap_data?.snap_id!,
      option: sharingSnapSelectedValue,
    };

    dispatch(postUpdateSnapSharingPermission(dispatchData));
  };

  return (
    <div className={`flex flex-wrap gap-5 ${className}`}>
      {walkthrough_data && (
        <WalkthroughOverviewCard
          id={walkthrough_data.walkthrough_id}
          cardName={walkthrough_data.name}
          tag="Walkthrough"
          onClick={onSelectCard}
        />
      )}
      {snap_data && (
        <WalkthroughOverviewCard
          id={snap_data.snap_id}
          cardName={snap_data.name}
          tag="Snap"
          onClick={onSelectCard}
          onClickSnapShare={onClickSnapeShare}
        />
      )}
      {video_guide_data && (
        <WalkthroughOverviewCard
          id={"1234 random video"}
          cardName="Example XYZ guide video"
          tag="Video"
          onClick={onSelectCard}
          status="Uploading"
        />
      )}
      {/* Component for showing modal snap sharing option. Only showed when user click share snap */}
      <ModalSnapSharingSetting
        open={showModalShare}
        setOpen={setShowModalShare}
        selectedValue={sharingSnapSelectedValue}
        onSelectedValue={setSharingSnapSelectedValue}
        onConfirm={onConfirmShareOption}
        walkthrough_data={walkthrough_data}
        snapId={snap_data?.snap_id!}
        guideId={snap_data?.guide_id!}
        shareId={snap_data?.share_id}
        option={snap_data?.option}
      />
    </div>
  );
};

export default GuideOverviewCards;
