import React, { useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import ComponentAlertAppOverallSnackbar from "../components/ComponentAlertAppOverallSnackbar";

import ComponentFormLogin from "../components/ComponentFormLogin";
import ComponentLoading from "../components/ComponentLoading";
import { RootState } from "../store";
import { resetAlert } from "../store/reducers/reducerAuth";

import Background from "../assets/images/curly-background-1.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { UsertipHorizontalIcon } from "usertip-component-library";
import LoginForm from "../components/03-organisms/05-form/LoginForm";
import PageFooter from "../components/03-organisms/05-form/PageFooter";

const PageLogin = () => {
  //state from redux
  const isLoading = useSelector(
    (state: RootState) => state.auth.appLoading,
    shallowEqual
  );
  const alert = useSelector(
    (state: RootState) => state.auth.alert,
    shallowEqual
  );

  // Web Application, how to get user data
  const { userData } = useSelector((state: RootState) => state.user);

  useEffect(() => {
    console.log("Initial userData:", userData);
  }, []);

  const dispatch = useDispatch();

  // console.log("Hi");
  // useEffect(() => { // Explicitly typing the callback function
  //   // Log the userData specifically
  //   fetch(`https://jsonplaceholder.typicode.com/todos/${userData}`)
  //     .then(response => response.json())
  //     .then(json => console.log(json))
  // }, [userData]) // useEffect runs whenever userData changes

  // console.log("Bye");
  return (
    <div className="w-screen h-min min-h-screen relative flex items-center justify-center">
      <div className="absolute top-2 left-20">
        <UsertipHorizontalIcon />
      </div>
      <div className="flex flex-col gap-20 p-8 my-4">
        <div className="flex flex-col items-center gap-[0.625em]">
          <h1 className="text-5xl font-medium text-default-neutral-100">
            Welcome to Usertip
          </h1>
          <p className="text-base font-medium text-default-neutral-60">
            To get started, please login
          </p>
        </div>
        <LoginForm />
      </div>
      {/* <ComponentFormLogin /> */}

      <PageFooter />

      {/* SOME BIG ERROR HERE AS PROBLEM SOURCE */}
      {alert.type !== "" && alert.type !== undefined && (
        <ComponentAlertAppOverallSnackbar
          alert={alert}
          reset={() => dispatch(resetAlert())}
        />
      )}
    </div>
  );
};

export default PageLogin;
