import { Button } from "@mui/material";
import React, { useState } from "react";
import AddBoxIcon from "@mui/icons-material/AddBox";
import ComponentShareWalkthroughs from "./ComponentShareWalkthroughs";
import ComponentListSharedWalkthroughsInTable from "./ComponentListSharedWalkthroughsInTable";
import { GuestWalkthrough } from "../interfaces/interface Organization";

interface Props {
  displayShowAddBtn: boolean;
  introMsg: string;
  indicatorMsg: string;
  dataSet: GuestWalkthrough[];
  orgID: string;
}

const ComponentListSharedWalkthroughs = ({
  displayShowAddBtn,
  introMsg,
  indicatorMsg,
  dataSet,
  orgID,
}: Props) => {
  const [showModalAdd, setShowModalAdd] = useState(false);

  const handleAddSharedWalkthrough = () => {
    setShowModalAdd(true);
  };
  return (
    <div className="pageBase">
      <div className="pageHeader">List of Shared Walkthroughs</div>

      <div className="pageSubHeader">{introMsg}</div>

      <div className="pageAddBtnBar">
        {
          // If displayShowAddBtn is true, show the button for adding new shared walkthrough
          displayShowAddBtn && (
            <Button
              className="pageAddBtn"
              variant={"outlined"}
              aria-label={`Share Walkthrough`}
              startIcon={<AddBoxIcon />}
              onClick={() => handleAddSharedWalkthrough()}
              disableElevation
            >
              Share Walkthrough
            </Button>
          )
        }
      </div>

      {
        // If dataSet is not empty, Display table with data, else Display IndicatorMsg
        dataSet && dataSet.length > 0 ? (
          <ComponentListSharedWalkthroughsInTable
            dataSet={dataSet} // Walkthroughs List
          />
        ) : (
          <div className="pageIndicatorMsg">{indicatorMsg}</div>
        )
      }
      <div className="pageDivider" />
      <ComponentShareWalkthroughs
        orgID={orgID}
        showModalAdd={showModalAdd}
        setShowModalAdd={setShowModalAdd}
      />
    </div>
  );
};

export default ComponentListSharedWalkthroughs;
