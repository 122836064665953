import React, { useState } from "react";
import { useSelector } from "react-redux";

import { Button, PlusIcon } from "usertip-component-library";

import ComponentAddTeams from "./ComponentAddTeams";
import {
  teamAddNewBuilderTeam,
  teamAddNewViewerTeam,
} from "../store/reducers/reducerTeam";
import TableTeamList from "./Table/TableTeamList";
import ComponentFilterTable from "./ComponentFilterTable";

const ComponentListTeams = ({
  displayUserRole,
  displayUserRoleFull,
  displayShowAddBtn,
  introMsg,
  dataSet,
  teamDataSet,

  indicatorMsg,
}) => {
  // state from redux
  const orgID = useSelector((state) => state.user.userData.org);

  const [showModalAdd, setShowModalAdd] = useState(false);
  const [filteredTeam, setFilteredTeam] = useState([]);

  const handleAddTeamClick = () => {
    // Open any Modal, as at any one time, only ONE MODAL will be pre-loaded and opened
    setShowModalAdd(true);
  };

  const walktroughFilter = [
    {
      field: "name",
      type: "String",
      label: "Team",
    },
    {
      field: "createdAt",
      type: "Date",
      label: "Date Created",
    },
    {
      field: "updatedAt",
      type: "Date",
      label: "Date Updated",
    },
  ];

  return (
    <div className="pageBase">
      <div className="pageHeader">List of {displayUserRoleFull} Teams</div>
      <div className="text-[#9CA3AF]">{introMsg}</div>

      {dataSet && (
        <ComponentFilterTable
          filters={walktroughFilter}
          setFilteredData={setFilteredTeam}
          originalData={dataSet}
        />
      )}

      {
        // If dataSet is not empty, Display table with data, else Display IndicatorMsg
        dataSet && dataSet.length > 0 ? (
          <TableTeamList
            teamData={filteredTeam}
            allTeamData={teamDataSet}
            handleAddTeam={handleAddTeamClick}
          />
        ) : (
          <div className="pageIndicatorMsg">{indicatorMsg}</div>
        )
      }
      <div className="pageDivider" />
      {
        // Preload Add Builder Modal
        displayUserRole === "Builder" && (
          <ComponentAddTeams
            displayUserRole={displayUserRole}
            orgID={orgID}
            dataSet={dataSet}
            onAddBuilderTeam={teamAddNewBuilderTeam}
            showModalAdd={showModalAdd}
            setShowModalAdd={setShowModalAdd}
          />
        )
      }
      {
        // Preload Add Viewer Modal
        displayUserRole === "Viewer" && (
          <ComponentAddTeams
            displayUserRole={displayUserRole}
            orgID={orgID}
            dataSet={dataSet}
            onAddNewViewerTeam={teamAddNewViewerTeam}
            showModalAdd={showModalAdd}
            setShowModalAdd={setShowModalAdd}
          />
        )
      }
    </div>
  );
};

export default ComponentListTeams;
