import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";

// import { LocalizationProvider } from "@mui/lab";
// import Local

import { ThemeProvider } from "@mui/styles";

import store from "./store/index";
import theme from "./theme/light";
import "./index.css";

import App from "./App";

import reportWebVitals from "./reportWebVitals";

import DateFnsUtils from "@date-io/date-fns";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const container = document.getElementById("root");
const root = createRoot(container!);

console.log("---container", container, root);

// Add usertip component library styles
let cssComponentLib = document.createElement("link");
cssComponentLib.setAttribute("rel", "stylesheet");
cssComponentLib.setAttribute(
  "href",
  "https://static.usertip.com/css/usertip-component-lib-styles.css"
);
cssComponentLib.setAttribute("type", "text/css");

document.head.appendChild(cssComponentLib);

// replace console.* for disable log on production
if (process.env.NODE_ENV === "production") {
  console.log = () => {};
  // console.error = () => {};
  // console.debug = () => {};
}

root.render(
  <>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </LocalizationProvider>
      </ThemeProvider>
    </Provider>
  </>
);

// root.render(
//   <React.StrictMode>
{
  /* <Provider store={store}>
  <ThemeProvider theme={theme}>
    <LocalizationProvider dateAdapter={DateFnsUtils}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </LocalizationProvider>
  </ThemeProvider>
</Provider>; */
}
//   </React.StrictMode>
// );

// ReactDOM.render(
//   // @ts-ignore
//   <Provider store={store}>
//     {/* @ts-ignore */}
//     <ThemeProvider theme={theme}>
//       <LocalizationProvider dateAdapter={DateFnsUtils}>
//         <BrowserRouter>
//           <App />
//         </BrowserRouter>
//       </LocalizationProvider>
//     </ThemeProvider>
//   </Provider>,
//   document.getElementById("root")
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
