import React from "react";

import { SearchDropdown } from "usertip-component-library";

interface Props {
  assistantDisplayLocation: { value: string } | null;
  setAssistantDisplayLocation: React.Dispatch<
    React.SetStateAction<{ value: string } | null>
  >;
}

const ComponentSettingsAssistantUX = ({
  assistantDisplayLocation,
  setAssistantDisplayLocation,
}: Props) => {
  /** Original Position Data */
  const dataArray = [
    { id: "1", value: "Right - Center" },
    { id: "2", value: "Left - Center" },
    { id: "3", value: "Right - Bottom" },
    { id: "4", value: "Left - Bottom" },
  ];

  // Usertip Assistant: Update Display Position
  const changeHandler = (__: string, value: string) => {
    setAssistantDisplayLocation({
      value: value,
    });
  };

  return (
    <div className="flex items-center justify-between">
      <p>Usertip Assistant Display Location:</p>
      <div className="w-40">
        <SearchDropdown
          dataArray={dataArray}
          onClick={changeHandler}
          selectedValue={assistantDisplayLocation}
          customWidth="ut-w-full"
          search={false}
        />
      </div>
    </div>
  );
};

export default ComponentSettingsAssistantUX;
