import React from "react";
import ComponentCodeBlock from "./ComponentCodeBlock";

const ComponentInstallationSPA = ({ api_key }: { api_key: string }) => {
  const spaCode = `
  <script>
  {(function (api_key) {
    console.log("running script");
    let node = document.createElement("script");
    node.id = "usertip-cs";
    node.setAttribute("data-usertip-key", api_key);
    node.src =
    "https://static.usertip.com/p/usertip.min.js";
      const head =
      document.head || document.documentElement.firstElementChild;
      head.appendChild(node);
  })("${api_key ? api_key : "<YOUR_API_KEY>"}")}
  </script>;
  `;

  const initCode = `
  /** INITIALIZE USERTIP CODE SNIPPET HERE **/
  let config = {};
  // add additional meta data of visitor to record
  let visitorMetaData = {
    //unique_id: user.id, // visitor unique id, must be unique
    //email: user.email, // user’s email (optional)
    //role: user.role, // user role or title
    //fname: user.fname, // first name
    //lname: user.lname, //last name
    //department: user.department, // department user may belong to
  };

  if (window.Usertip) {
    window.Usertip.init(config, visitorMetaData);
  }
  /** END INITIALIZE USERTIP CODE SNIPPET HERE **/
  `;

  const reactExample = `
  useEffect(() => {
    /** Ensure that the user object/data is valid **/
    if (user) {
        /** INITIALIZE USERTIP CODE SNIPPET HERE **/
        let config = {};
        // add additional meta data of visitor to record
        let visitorMetaData = {
          unique_id: user.id, // visitor unique id, must be unique
          //email: user.email, // user’s email (optional)
          //role: user.role, // user role or title
          //fname: user.fname, // first name
          //lname: user.lname, //last name
          //department: user.department, // department user may belong to
        };
 
        if (window.Usertip) {
          window.Usertip.init(config, visitorMetaData);
        }
        /** END INITIALIZE USERTIP CODE SNIPPET HERE **/
    };
  }, [user]);
  `;
  return (
    <>
      <p>
        If your website is a single page application, and using technologies
        like React, Angular or Vue, we would recommend that you install the
        Usertip code snippet with this method. You can copy the code below and
        share it with your engineering team.
      </p>
      <br />
      <ComponentCodeBlock codeBlock={spaCode} />
      <br />
      <p>How to initialize (start) Usertip in your Web Application.</p>
      <br />
      <ComponentCodeBlock codeBlock={initCode} />
      <br />
      <p>
        For example if your application is built using React.js, you will call{" "}
        <strong>window.Usertip.init</strong> in a useEffect (see below for code
        example). If you need to, you can also add metadata for your visitors
        which can be used to filter a user by email (for example).
      </p>
      <br />
      <ComponentCodeBlock codeBlock={reactExample} />
      <br />
      <p>
        NOTE: config &amp;&amp; visitorMetaData are <strong>REQUIRED</strong>{" "}
        parameters when calling window.Usertip.init
      </p>
    </>
  );
};

export default ComponentInstallationSPA;
