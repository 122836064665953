import React from "react";
import { Toggle, ToggleItem } from "usertip-component-library";

interface Props {
  /** Prop for set the name of the input (to grouping font weight input) */
  label: string;
  /** Prop for set the value of the input */
  value: number;
  /** Prop for handling the input */
  settingHandler: (value: any) => void;
}

const FontWeightSetting = ({ label, value, settingHandler }: Props) => {
  return (
    <>
      <h1 className="text-sm">Font Weight:</h1>
      <Toggle name={`${label}-text-font-weight`} width="full">
        <ToggleItem
          id="100"
          label="100"
          icon={<p className="font-thin font-sans">A</p>}
          value={100}
          selectedValue={value}
          onSelectedValue={settingHandler}
        />
        <ToggleItem
          id="300"
          label="300"
          icon={<p className="font-light font-sans">A</p>}
          value={300}
          selectedValue={value}
          onSelectedValue={settingHandler}
        />
        <ToggleItem
          id="400"
          label="400"
          icon={<p className="font-sans">A</p>}
          value={400}
          selectedValue={value}
          onSelectedValue={settingHandler}
        />
        <ToggleItem
          id="500"
          label="500"
          icon={<p className="font-medium font-sans">A</p>}
          value={500}
          selectedValue={value}
          onSelectedValue={settingHandler}
        />
        <ToggleItem
          id="700"
          label="700"
          icon={<p className="font-bold font-sans">A</p>}
          value={700}
          selectedValue={value}
          onSelectedValue={settingHandler}
        />
      </Toggle>
    </>
  );
};

export default FontWeightSetting;
