import React, { useEffect, useState } from "react";

import { TextField, Chip } from "@mui/material";
import { Button } from "usertip-component-library";

import DriveFolderUpload from "@mui/icons-material/DriveFolderUpload";

import { validateEmailFormat } from "../../utility/functions/validation";
import { useDispatch, useSelector } from "react-redux";
import { setTabsOrgSuccess } from "../../store/reducers/reducerInviteQuickAccess";
import { useNavigate } from "react-router-dom";

const ComponentAddUsersEmailsToChips = ({
  orgID,
  onInviteUserToOrg,
  displayUserRole,

  usersEmails,
  setUsersEmails,
  email,
  setEmail,
  emailError,
  setEmailError,

  handleHideModal,

  handleMassImportClick,

  resetUsersEmailsKeys,

  resetEmailErrorHandler,
  resetEmailHandler,
}) => {
  // to disable confirm button if the role not selected
  const isEmpty = useSelector((state) => state.inviteQuick.isEmpty);
  // state from reducerOrganization to decide invite / add members success or failed
  const inviteFailed = useSelector((state) => state.org.isInviteFailed);
  // console.log("------", inviteFailed);
  // console.log(displayUserRole);

  const dispatch = useDispatch();
  const [confirmBtnDisabled, setConfirmBtnDisabled] = useState(true);

  const emailDefaultInfoText = "*Hit <Enter> to add email to list";
  const emailDefaultInvalidFormatText = "*Invalid email format";

  // User email field change handler.
  const emailChangeHandler = (e) => {
    const value = e.target.value;

    setEmail(value.toLowerCase());
    if (value.trim()) {
      resetEmailErrorHandler();
    }
  };

  // Set user email input field warning.
  const setEmailErrorHandler = (message) => {
    setEmailError({ option: true, message });
  };

  // Validate email input field.
  const validateEmailField = () => {
    const isEmpty = !email.trim();
    const isValidated = validateEmailFormat(email.trim());
    if (isEmpty) {
      setEmailErrorHandler(emailDefaultInfoText);
    } else if (!isValidated) {
      setEmailErrorHandler(emailDefaultInvalidFormatText);
    } else {
      resetEmailErrorHandler();
    }

    return !isEmpty && isValidated;
  };

  const navigate = useNavigate();

  // Create walkthrough form submission handler
  const handleAddNewUserRoleToOrgFormSubmission = (e) => {
    e.preventDefault();
    e.stopPropagation();

    // Checks if list of emails contains New Email(s) not in database

    let role = null;
    // If New Email(s) Not in database Exists, send new user registration invites through email & Add the emails to the list
    // dispatch(setTabsOrgSuccess(number)) is to set selected tabs after navigate to organization page
    if (displayUserRole === "Admin") {
      role = "admin";
      dispatch(setTabsOrgSuccess(0));
    } else if (displayUserRole === "Builder") {
      role = "builder";
      dispatch(setTabsOrgSuccess(1));
    } else if (displayUserRole === "Viewer") {
      role = "viewer";
      dispatch(setTabsOrgSuccess(2));
    }

    // If the email array is not empty
    if (usersEmails && usersEmails.length > 0) {
      let finalEmailList = [];
      for (let i = 0; i < usersEmails.length; i++) {
        // Forward each new user email invite manually one-by-one, as server does not handle bulk invites at the moment
        // Format to send: orgID, email, role, mfa
        finalEmailList.push(usersEmails[i].userEmail);
      }
      const orgData = {
        orgID: orgID,
        emails: finalEmailList,
        role: role,
      };
      dispatch(onInviteUserToOrg(orgData));
      // dispatch(setTabsOrgSuccess(selectTabsOrg));
    }
    // handleHideModal();
  };

  // to handle the behavour when the invite / add members success or failed
  if (inviteFailed === true) {
    console.log("INVITE FAILED +++++");
  } else if (inviteFailed === false) {
    console.log("INVITE SUCCESS +++++");
    setTimeout(() => {
      handleHideModal();
      navigate("/organization");
    }, 500);
  }

  // Checks if array list is empty, if true, disable Confirm btn
  const verifyIfArrayIsEmptyToDisableConfirmBtn = (usersEmailsArray) => {
    if (usersEmailsArray.length > 0) {
      setConfirmBtnDisabled(false);
    } else {
      setConfirmBtnDisabled(true);
    }
  };

  // Checks if new email already exists in array list
  const verifyIfEmailExistsInUsersEmails = () => {
    for (let i = 0; i < usersEmails.length; i++) {
      // If email already exists in the current arraylist
      if (usersEmails[i].userEmail.includes(email)) {
        return true;
      }
    }
    return false;
  };

  const handleAddNewEmail = (e) => {
    // Only process add email from textfield when "Enter" key is pressed
    if (e.key === "Enter") {
      // Execute check to valid email before adding to array list
      if (validateEmailField()) {
        // If email already exists on list, inform to try new email
        if (verifyIfEmailExistsInUsersEmails()) {
          setEmailErrorHandler(
            "*Email already added to list. Please try again."
          );
        }
        // If email does not exist, add to array list
        else {
          // Reset array list keys count
          resetUsersEmailsKeys();

          // Add new email to array list usersEmails if verified email is of valid format
          usersEmails.push({
            key: usersEmails.length,
            userEmail: email,
          });

          // Clears textfield after successful add
          resetEmailHandler();
          // Checks if array list is empty and whether to disable Confirm btn. Verify with usersEmails as the active array to check
          verifyIfArrayIsEmptyToDisableConfirmBtn(usersEmails);
        }
      }
    }
  };

  const handleDeleteSelectedEmail = (emailSelectedToDelete) => () => {
    // Remove selected email to delete
    const newUsersEmailsAfterDeletion = usersEmails.filter(
      (emailToDelete) => emailToDelete.key !== emailSelectedToDelete.key
    );
    setUsersEmails(newUsersEmailsAfterDeletion);

    // Reset array list keys count
    resetUsersEmailsKeys();
    // Checks if array list is empty and whether to disable Confirm btn. Verify with newUsersEmailsAfterDeletion as apparently the original array will not be deducted to zero, and it will only work when using newArray newUsersEmailsAfterDeletion
    verifyIfArrayIsEmptyToDisableConfirmBtn(newUsersEmailsAfterDeletion);
  };

  return (
    <>
      <div className="mb-8">
        <Button
          onClick={handleMassImportClick}
          color="primary"
          size="fluid"
          text="Mass Import"
          variant="outlined"
          icon={<DriveFolderUpload />}
          iconLeft={true}
        />
      </div>

      <div className="modalForm">
        <div className="modalChipsListBox">
          <ul className="modalChipsList max-h-60 mb-12 overscroll-contain">
            {usersEmails.map((inputEmail) => {
              let icon;

              return (
                <li key={inputEmail.key} className="modalChip">
                  <Chip
                    icon={icon}
                    label={inputEmail.userEmail}
                    onDelete={handleDeleteSelectedEmail(inputEmail)}
                  />
                </li>
              );
            })}
          </ul>
        </div>

        <div className=" relative">
          <TextField
            label={"Enter the user's email"}
            value={email}
            autoFocus={true}
            onChange={emailChangeHandler}
            onKeyPress={handleAddNewEmail}
            error={emailError.option}
            helperText={
              emailError.option ? emailError.message : emailDefaultInfoText
            }
            variant={"standard"}
            fullWidth
          />

          {usersEmails.length > 0 && (
            <div className="text-xs absolute right-0 bottom-0 text-gray-600 font-medium">
              Total Emails: {usersEmails.length}
            </div>
          )}
        </div>
        <span className="modalInputTextfield pt-1 text-xs text-default-error">
          {inviteFailed
            ? `Error: Cannot add ${displayUserRole} as members`
            : ""}
        </span>

        <div className="modalActionBtnBar">
          <Button
            onClick={handleHideModal}
            color="primary"
            size="medium"
            text="Cancel"
            variant="primary"
          />
          <Button
            onClick={handleAddNewUserRoleToOrgFormSubmission}
            color="primary"
            size="medium"
            text="Confirm"
            variant="primary"
            disabled={confirmBtnDisabled || isEmpty}
          />
        </div>
      </div>
    </>
  );
};

export default ComponentAddUsersEmailsToChips;
