import { Typography } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "usertip-component-library";
import { callPostUpdateUserOnBoard } from "../../axios/v2/callsUser";
import { updateOnBoardingStatus } from "../../store/reducers/reducerUser";
import { RootState } from "../../store";
import { useNavigate } from "react-router-dom";
import { ONBOARDING_FLOW_PAGE_PATH } from "../../App";

interface Props {
  is_onboarded: boolean | null | undefined;
}

const ComponentOnBoarding = ({ is_onboarded }: Props) => {
  const { cognito_id: cognitoId, role } = useSelector(
    (state: RootState) => state.user.userData
  );

  const dispatch = useDispatch();

  const navigate = useNavigate();

  /** For updates the user_onboarding status and direct to home */
  const completeOnBoarding = () => {
    async function updateUserOnboard() {
      await callPostUpdateUserOnBoard(cognitoId);
    }

    updateUserOnboard();
    dispatch(updateOnBoardingStatus());
    navigate("/");
  };

  /** For go to invitation onboarding page */
  const inviteOnboarding = () => {
    navigate(ONBOARDING_FLOW_PAGE_PATH);
  };

  return (
    <div className="pageBase">
      <div className="pageHeader">Getting Started With Usertip!</div>
      <div className="pageParagraph-lg mt-8">
        {`Welcome to Usertip, it is our pleasure to have you onboard with us. In the following we provide a series of introductory videos and useful links to get you started. This guide will take you through building your very 1st walkthrough and some of the basic settings you can control. 
`}
        To revisit this page at any time, you can navigate to the{" "}
        <span className="font-bold text-default-primary">Need help Button</span>{" "}
        <span className="text-default-primary-light">{">>"}</span>{" "}
        <span className="font-bold text-default-primary">Onboarding Guide</span>
        .
      </div>
      <div className="pageDivider-sm" />
      <div className="flex flex-row justify-between flex-wrap">
        <div className="px-4">
          <div className="pageParagraph-lg">
            <b>1. Usertip Initial Setup</b>
          </div>
          <div className="pageSettings">
            <iframe
              width="400"
              height="200"
              src="https://www.youtube.com/embed/l11FKVAf27c"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </div>
        </div>
        <div className="pageHorizontalDivider-sm" />
        <div className="px-4">
          <div className="pageParagraph-lg">
            <b>2. Build Your First Walkthrough With Usertip</b>
          </div>
          <div className="pageSettings">
            <iframe
              width="400"
              height="200"
              src="https://www.youtube.com/embed/PRqEKxt0gNM"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </div>
        </div>
        <div className="pageHorizontalDivider-sm" />
        <div className="px-4">
          <div className="pageParagraph-lg">
            <b>3. Additional Walkthrough Settings</b>
          </div>
          <div className="pageSettings">
            <iframe
              width="400"
              height="200"
              src="https://www.youtube.com/embed/ILZnjx34Sa8"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
      <div className="pageDivider-sm" />
      <div className="pageHeader">Manage member</div>
      <div className="pageParagraph-lg py-0 mb-4">
        You can invite members to your organization by clicking the button below
        or navigating to the{" "}
        <span className="font-bold text-default-primary">Organization</span>{" "}
        button in the navigation.
      </div>
      <Button
        color="primary"
        size="medium"
        text="Invite Member"
        variant="primary"
        disabled={role == "builder" || role == "viewer"}
        onClick={inviteOnboarding}
      />
      {(role == "builder" || role == "viewer") && (
        <p className="text-default-secondary text-sm mt-2">
          **Only the owner and administrators have the authority to invite new
          members.
        </p>
      )}
      <div className="pageHeader mt-8">
        <b>Important Links</b>
      </div>
      <ul>
        <li className="pl-4 pageParagraph-lg py-0">
          1. Download the Builder Chrome Extension &nbsp; (
          <a href="https://chrome.google.com/webstore/detail/builder-10-by-usertip/ceiihcjapecalcblnbcodnnkpbdbpbem?hl=en">
            Link
          </a>
          )
        </li>
        <li className="pl-4 pageParagraph-lg py-0">
          <div>
            2. Download the Launcher Chrome Extension &nbsp; (
            <a href="https://chrome.google.com/webstore/detail/pull-usertip-walkthroughs/bfnanoabpjghcopngcdkladpoeijbgff?hl=en">
              Link
            </a>
            )
          </div>
        </li>
        <li className="pl-4 pageParagraph-lg py-0">
          <div>
            3. Check out our User Guide for more help &nbsp; (
            <a href="https://usertip.notion.site/Usertip-Guide-9debaeb3c94848e580a281ce0bb1ccd1">
              Link
            </a>
            )
          </div>
        </li>
      </ul>
      {!is_onboarded && (
        <div className="py-9 mr-auto flex justify-end">
          <Button
            color="success"
            size="medium"
            text="Complete Onboarding"
            variant="primary"
            onClick={completeOnBoarding}
          />
        </div>
      )}
    </div>
  );
};
export default ComponentOnBoarding;
