// import jwt from "jsonwebtoken";
// import jwtToPem from "jwk-to-pem";
import * as jose from "jose";
import PUBLIC_KEY from "../../keys/publicKey.json";

const verifyPayload = async (encrypted_payload: any) => {
  const rsaPublicKey = await jose.importJWK(
    {
      kty: "RSA",
      e: PUBLIC_KEY.e,
      n: PUBLIC_KEY.n,
    },
    "PS256"
  );

  const { payload, protectedHeader } = await jose.jwtVerify(
    encrypted_payload,
    rsaPublicKey
  );

  return payload;
};

export default verifyPayload;
