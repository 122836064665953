import React, { useEffect, useState } from "react";
import { DataGrid, GridColDef, enUS } from "@mui/x-data-grid";
import { convertDateToStandardFormat } from "../_functions/FunctionDate";
import {
  AddCircle,
  Cloud,
  LockClock,
  PersonAdd,
  Delete,
  TransferWithinAStation,
} from "@mui/icons-material";
import { Button } from "usertip-component-library";
import { useDispatch, useSelector } from "react-redux";
import ComponentAddUsers from "../ComponentAddUsers";
import {
  organizationPostInviteUserToOrganization,
  removeUserFromOrganization,
  setTheIndex,
} from "../../store/reducers/reducerOrganization";
import { RootState } from "../../store";
import ComponentMoveUserBetweenRoles from "../ComponentMoveUserBetweenRoles";
import ComponentRemoveUsers from "../ComponentRemoveUsers";

export default function TableOrgViewer() {
  const [showModalMove, setShowModalMove] = useState(false);
  const [showModalRemove, setShowModalRemove] = useState(false);
  // const [removeMode, setRemoveMode] = useState("");
  const [resetTableSelectAll, setResetTableSelectAll] = useState(false);

  const dispatch = useDispatch();

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      flex: 0.35,
      minWidth: 173,
      editable: false,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 0.9,
      minWidth: 363,
      editable: false,
    },
    {
      field: "confirmed_invitation",
      headerName: "Status",
      minWidth: 145,
      editable: false,
      valueFormatter: (params) => {
        return params.value ? "Confirmed" : "Pending";
      },
    },
    {
      field: "createdAt",
      headerName: "Date Joined",
      minWidth: 196,
      editable: false,
      valueFormatter: (params) => convertDateToStandardFormat(params.value),
    },
    {
      field: "plugin",
      headerName: "Plugin",
      minWidth: 145,
      editable: false,
      valueFormatter: (params) => {
        return params.value ? "Plugin" : "-";
      },
    },
    {
      field: "code_snippet",
      headerName: "Code Snippet",
      minWidth: 145,
      editable: false,
      valueFormatter: (params) => {
        return params.value ? "Code Snippet" : "-";
      },
    },
    {
      field: "action",
      headerName: "Action(s)",
      minWidth: 132,
      renderCell: (params) => (
        <div className="flex flex-row">
          <div
            className="cursor-pointer text-neutral-500"
            onClick={() => {
              setShowModalMove(true);
              dispatch(setTheIndex(params.id));
            }}
          >
            <TransferWithinAStation />
          </div>
          <div
            className="cursor-pointer text-neutral-500"
            onClick={() => {
              setShowModalRemove(true);
              dispatch(setTheIndex(params.id));
            }}
          >
            <Delete />
          </div>
        </div>
      ),
    },
  ];

  const { viewers, emailInviteMsg, appLoading, alert } = useSelector(
    (state: RootState) => state.org
  );
  const viewerArray = viewers || [];
  console.log(viewerArray);

  //state from redux
  const orgID = useSelector((state: any) => state.user.userData.org);
  const { role } = useSelector((state: any) => state.user.userData);

  const [showModalAdd, setShowModalAdd] = useState(false);
  const [preloadModalState, setPreloadModalState] = useState("AddUsers");

  /** For manipulating the columns data */
  const [columnsState, setColumnsState] = useState(columns);

  /** This is for removing the action column from the table for the builder role */
  useEffect(() => {
    if (role === "builder") {
      const newColumn = columns.filter((val) => {
        return val.field !== "action";
      });

      setColumnsState(newColumn);
    } else {
      setColumnsState(columns);
    }
  }, [role]);

  const handleAddUserRoleClick = () => {
    // Open any Modal, as at any one time, only ONE MODAL will be pre-loaded and opened
    setPreloadModalState("AddUsers");
    setShowModalAdd(true);
  };

  const handleMassImportClick = () => {
    setPreloadModalState("MassImport");
    setShowModalAdd(true);
  };

  const customLocalization = {
    ...enUS,
    noRowsLabel: "No viewers found. Please add one!",
  };

  return (
    <div>
      <div className="bg-default-primary rounded-tr-2xl rounded-tl-2xl flex flex-row justify-between px-6 py-4 items-center text-white">
        <div className="text-base font-bold">Manage Viewer</div>
        {role !== "builder" && (
          <div className="flex flex-row gap-2 items-center border-whi">
            <Button
              text="Add Viewer"
              iconLeft
              icon={<PersonAdd />}
              size="small"
              variant="outlined"
              color="neutral"
              onClick={() => handleAddUserRoleClick()}
              customClass={"ut-btn-org"}
            />

            <Button
              text="Mass Import"
              iconLeft
              icon={<LockClock />}
              size="small"
              variant="outlined"
              color="neutral"
              onClick={() => handleMassImportClick()}
              customClass={"ut-btn-org"}
            />
          </div>
        )}
      </div>
      <DataGrid
        rows={viewerArray}
        columns={columnsState}
        autoHeight
        checkboxSelection
        localeText={customLocalization}
        initialState={{
          pagination: {
            page: 0,
            pageSize: 25,
          },
        }}
        rowsPerPageOptions={[25, 50, 100]}
        sx={{ borderEndEndRadius: 16, borderEndStartRadius: 16 }}
      />
      <ComponentAddUsers
        preloadModalState={preloadModalState}
        orgID={orgID}
        showModalAdd={showModalAdd}
        setShowModalAdd={setShowModalAdd}
        onInviteUserToOrg={organizationPostInviteUserToOrganization}
        displayUserRole={"Viewer"}
      />
      <ComponentMoveUserBetweenRoles
        selectedUserList={viewerArray}
        currentRole={"viewer"}
        showModalMove={showModalMove}
        setShowModalMove={setShowModalMove}
        moveMode={"single"}
        setResetTableSelectAll={false}
      />
      {/* move mode mode is single and bulk, check ComponentMoveUserBetweenRoles.js */}

      <ComponentRemoveUsers
        listType={"User"}
        selectedUserOrgID={orgID}
        selectedUserList={viewerArray}
        onConfirmRemoveUserFromOrg={removeUserFromOrganization}
        showModalRemove={showModalRemove}
        setShowModalRemove={setShowModalRemove}
        removeMode={"single"}
        setResetTableSelectAll={setResetTableSelectAll}
        viewingPermisionsType={undefined}
        selectedWalkthroughViewingPermissionsList={undefined}
        onDeleteOneIndvWalkthroughViewingPermission={undefined}
        onHandleBulkEditIndvWalkthroughPermission={undefined}
      />
      {/* remove mode mode is single and bulk, check ComponentRemoveUsers.js */}
    </div>
  );
}
