import React from "react";

import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../store";
import { changeThemeValue } from "../../../store/reducers/reducerTheme";
import SpacingSetting from "../../01-atoms/05-themeInputs/SpacingSetting";
import SizeSetting from "../../01-atoms/05-themeInputs/SizeSetting";
import RadiusSetting from "../../01-atoms/05-themeInputs/RadiusSetting";
import ColorSetting from "../../01-atoms/05-themeInputs/ColorSetting";
import SlideoutPositionSetting from "../../01-atoms/05-themeInputs/SlideoutPositionSetting";
import SlideoutAnimationSetting from "../../01-atoms/05-themeInputs/SlideoutAnimationSetting";
import SpotlightTooltipPositionSetting from "../../01-atoms/05-themeInputs/SpotlightTooltipPositionSetting";

interface Props {
  type: "modal" | "slideout" | "spotlight" | "tooltip";
  style: any;
}

const BaseSettings = ({ type, style }: Props) => {
  const {
    padding,
    height,
    width,
    borderRadius,
    backgroundColor,
    position,
    animation,
  } = style;

  const dispatch = useDispatch<AppDispatch>();

  /** for handling padding input */
  const paddingHandler = (value: number) => {
    const style = { padding: value };
    dispatch(changeThemeValue({ type, style }));
  };

  /** for handling height value input */
  const heightValueHandler = (value: number) => {
    const style = { height: { value: value, unit: height.unit } };
    dispatch(changeThemeValue({ type, style }));
  };

  /** for handling height unit toggle */
  const heightUnitHandler = (value: any) => {
    const style = { height: { value: height.value, unit: value } };
    dispatch(changeThemeValue({ type, style }));
  };

  /** for handling width value input */
  const widthValueHandler = (value: number) => {
    const style = { width: { value: value, unit: width.unit } };
    dispatch(changeThemeValue({ type, style }));
  };

  /** for handling width unit toggle */
  const widthUnitHandler = (value: any) => {
    const style = { width: { value: width.value, unit: value } };
    dispatch(changeThemeValue({ type, style }));
  };

  /** for handling border radius input */
  const borderRadiusHandler = (value: number) => {
    const style = { borderRadius: value };
    dispatch(changeThemeValue({ type, style }));
  };

  /** for handling border radius slider */
  const sliderBorderRadiusHandler = (__: any, value: number | number[]) => {
    const style = { borderRadius: value };
    dispatch(changeThemeValue({ type, style }));
  };

  /** For handling background color input */
  const backgroundColorHandler = (value: string) => {
    const style = { backgroundColor: value };
    dispatch(changeThemeValue({ type, style }));
  };

  /** For handling spotlight position toggle */
  const spotlightPositionHandler = (value: any) => {
    const style = { position: value };
    dispatch(changeThemeValue({ type, style }));
  };

  /** For handling slideout position toggle */
  const slideoutPositionHandler = (value: any) => {
    const style = { position: value };
    dispatch(changeThemeValue({ type, style }));
  };

  /** For handling slideout animation toggle */
  const slideoutAnimationHandler = (value: any) => {
    const style = { animation: value };
    dispatch(changeThemeValue({ type, style }));
  };

  return (
    <div className="w-full h-full flex flex-col gap-3">
      <div className="w-full bg-default-primary-lightest py-2 px-5 text-sm font-light text-default-primary-light">
        Spacing
      </div>

      <div className="grid grid-cols-2 items-center gap-y-2 px-4">
        <SpacingSetting
          label="Overall Padding"
          value={padding}
          settingHandler={paddingHandler}
          min={1}
        />

        <SizeSetting
          label="Overall Height"
          value={height}
          name="base-height-units"
          valueSettingHandler={heightValueHandler}
          valueUnitHandler={heightUnitHandler}
        />

        <SizeSetting
          label="Overall Width"
          value={width}
          name="base-width-units"
          valueSettingHandler={widthValueHandler}
          valueUnitHandler={widthUnitHandler}
        />
      </div>

      <div className="w-full bg-default-primary-lightest py-2 px-5 text-sm text-default-primary-light">
        Background
      </div>

      <div className="grid grid-cols-2 items-center gap-y-2 px-4">
        <RadiusSetting
          value={borderRadius}
          handlerSetting={borderRadiusHandler}
          sliderHandler={sliderBorderRadiusHandler}
        />

        <ColorSetting
          label="Background Color"
          settingHandler={backgroundColorHandler}
          value={backgroundColor}
          transparentOption={false}
        />
      </div>

      {type !== "modal" && (
        <>
          <div className="w-full bg-default-primary-lightest py-2 px-5 text-sm text-default-primary-light">
            Position
          </div>

          <div className="grid grid-cols-2 items-center gap-y-2 px-4">
            {type !== "slideout" && (
              <>
                <SpotlightTooltipPositionSetting
                  value={position}
                  settingHandler={spotlightPositionHandler}
                />
              </>
            )}

            {type === "slideout" && (
              <>
                <SlideoutPositionSetting
                  value={position}
                  settingHandler={slideoutPositionHandler}
                />

                <SlideoutAnimationSetting
                  value={animation}
                  settingHandler={slideoutAnimationHandler}
                />
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default BaseSettings;
