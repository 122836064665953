import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { registerFromGoogle } from "../store/reducers/reducerAuth";
import { RootState } from "../store";
import { Button, InputField } from "usertip-component-library";
// import Button from "../components/UI/Button/Button";

const PageRegisterCreateNewOrg = () => {
  const [orgName, setOrgName] = useState("");
  const dispatch = useDispatch();

  /**Check if storage has ut-google-register-user */
  const googleData = localStorage.getItem("ut-google-register-user");

  const loading = useSelector((state: RootState) => state.auth.appLoading);

  type GoogleData = {
    token: string;
    email: string;
    cognitoId: string;
    name: string;
    expiresIn: number;
  };
  const handlingGoogleRegisterNewOrg = () => {
    const data = JSON.parse(googleData!) as GoogleData;
    dispatch(
      registerFromGoogle({
        ...data,
        orgName: orgName,
      })
    );
  };
  return (
    <>
      <section className="m-auto w-2/4">
        <div className="m-auto w-full">
          <div className="flex flex-col justify-center items-center mb-14">
            <h2 className="text-heading-m text-center text-default-neutral-100 mb-3">
              Create Organization Name
            </h2>
            <div className="mt-14 flex flex-column gap-4 items-center">
              <InputField
                variant="text"
                placeholder="Organization Name"
                onChange={(e) => setOrgName(e.target.value)}
              />
              <Button
                color="primary"
                size="medium"
                text="Confirm"
                variant="primary"
                onClick={handlingGoogleRegisterNewOrg}
                disabled={loading}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PageRegisterCreateNewOrg;
