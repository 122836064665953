import { Box, Paper, Popover, Typography } from "@mui/material";
import { Button, EmptyModal } from "usertip-component-library";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import ComponentLaunchAnalytic from "../ManualAndAutomaticLaunch/ComponentLaunchAnalytic";
import ComponentOverAllStep from "../OverAllStep/ComponentOverAllStep";
import ComponentSeenDismissed from "../SeenDissmissed/ComponentSeenDismissed";
import ComponentUserInteraction from "../UserInteraction/ComponentUserInteraction";
interface WalkthroughVersion {
  published_version: number;
  published_walkthrough_id: string;
}

interface Props {
  walkthroughVersions: WalkthroughVersion[];
  selectionStartDate: Date;
  setSelectionStartDate: React.Dispatch<React.SetStateAction<Date>>;
  selectionEndDate: Date;
  setSelectionEndDate: React.Dispatch<React.SetStateAction<Date>>;
  selectionViewerTeam: string;
  setSelectionViewerTeam: React.Dispatch<React.SetStateAction<string>>;
  selectionPublishedWalkthroughId: string;
  setSelectionPublishedWalkthroughId: React.Dispatch<
    React.SetStateAction<string>
  >;
  viewerTeamSummary: { viewer_team_id: string; name: string }[];
}

const ComponentSelectDataSet = ({
  // publishedVersionList,
  // walkthroughName,
  walkthroughVersions,
  selectionStartDate,
  setSelectionStartDate,
  selectionEndDate,
  setSelectionEndDate,
  viewerTeamSummary,
  selectionViewerTeam,
  setSelectionViewerTeam,
  selectionPublishedWalkthroughId,
  setSelectionPublishedWalkthroughId,
}: Props) => {
  const walkthroughName = useSelector(
    //@ts-ignore
    (state: RootState) => state.walkthrough.activeWalkthrough.name
  );
  /** State to control if Daily/Weekly/Monthly is allowed as dropdown fields */
  const [viewByDailyDisabled, setViewByDailyDisabled] = useState<string[]>([]);

  const viewerTeamList = useSelector(
    (state: RootState) => state.team.viewerTeamData
  );

  const handleOnViewerChange = (viewer: string) => {
    setSelectionViewerTeam(viewer);
  };

  const handleOnPublishedWalkthroughChange = (
    publishedWalkthroughId: string
  ) => {
    setSelectionPublishedWalkthroughId(publishedWalkthroughId);
  };

  const [dateOpen, setDateOpen] = useState(false);

  // if custom date is selected, store string value, if non-custom date was selected set this to null
  const [customDate, setCustomDate] = useState<null | string>(null);

  const [dateOption, setDateOption] = useState(7);

  /** Modal date picker start date state */
  const [datePickerStartDate, setDatePickerStartDate] = useState<null | Date>(
    selectionStartDate
  );

  /** Modal date picker start end state */
  const [datePickerEndDate, setDatePickerEndDate] = useState<null | Date>(
    selectionEndDate
  );

  const today = new Date();

  const handleSelectDateOption = (day: number) => {
    /** Custom Date option is represented by -1
     * if day === -2, represents the date range selected through the custom date
     */
    if (day === -1) {
      /** if custom date option is selected, setDateOpen to true to open up the modal */
      setDateOpen(true);
    }
    setDateOption(day);
    if (day >= 0) {
      // if custom date was selected previously, make sure to remove it
      if (customDate) {
        setCustomDate(null);
      }
      // const convertDayToNumber = +day;
      // const dayToNumber = +day
      setSelectionStartDate(
        new Date(new Date().setDate(today.getDate() - day))
      );
      setSelectionEndDate(today);
      let viewByOptions = [];

      if (day <= 7) {
        viewByOptions.push("daily");
      } else if (day > 7 && day <= 30) {
        viewByOptions.push("daily", "weekly");
      } else {
        viewByOptions.push("weekly", "monthly");
      }
      // day > 7
      //   ? viewByOptions.push("weekly", "monthly")
      //   : viewByOptions.push("daily");

      setViewByDailyDisabled([...viewByOptions]);
    }
  };

  const handleDateRangeChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setDatePickerStartDate(start!);
    setDatePickerEndDate(end!);
  };

  const handleCloseDatePickerModal = () => {
    setDateOpen(false);
  };

  const handleApplyCustomDate = () => {
    // const convertDayToNumber = +day;
    // const dayToNumber = +day
    // handleDateRangeChange;

    /** Set selectionStartDate and selectionEndDate, which should trigger an API call */
    if (datePickerStartDate && datePickerEndDate) {
      setSelectionStartDate(datePickerStartDate);
      setSelectionEndDate(datePickerEndDate);
    }

    /** Add a new option for the selected custom date, and set it as the selected field */
    setCustomDate(
      `${selectionStartDate.getDate()} ${
        dateMonthMapper[selectionStartDate.getMonth()]
      } ${selectionStartDate.getFullYear()} - ${selectionEndDate.getDate()} ${
        dateMonthMapper[selectionEndDate.getMonth()]
      } ${selectionEndDate.getFullYear()}`
    );
    setDateOption(-2);
    setDateOpen(false);
  };

  /** If no views are set, set default to last 7 days */
  useEffect(() => {
    if (viewByDailyDisabled.length === 0) {
      handleSelectDateOption(7);
    }
  }, []);

  return (
    <>
      {/* SELECTION COMPONENT */}
      <div
        id="select_data_set"
        className="selectDataSet flex gap-1 items-center"
      >
        <div className="select select_viewer mx-1">
          <div>Select Viewer Team:</div>
          <select
            name="viewer"
            id="viewer"
            className="p-3 border-neutral-400 border-2 rounded-md w-64 bg-white"
            onChange={(e) => handleOnViewerChange(e.target.value)}
            value={selectionViewerTeam}
          >
            {viewerTeamSummary && viewerTeamSummary.length > 0 ? (
              viewerTeamSummary.map((item, i) => {
                return (
                  <option
                    key={i}
                    value={item.viewer_team_id}
                  >{`${item.name}`}</option>
                );
              })
            ) : (
              <option>{`No viewer teams found.`}</option>
            )}
            {/* <option value="everyone">Everyone</option> */}
          </select>
        </div>
        <div className="select select_date mx-1" id="analytics-date-picker">
          <div>Select Date Range:</div>
          <select
            name="date"
            id="date"
            className="p-3 border-neutral-400 border-2 rounded-md w-64 bg-white"
            // onClick={(e) =>
            //   console.log((e.target as HTMLSelectElement).value, e)
            // }
            onChange={(e) => handleSelectDateOption(parseInt(e.target.value))}
            defaultValue={dateOption}
          >
            {customDate && <option value={-2}>{customDate}</option>}
            <option value={0}>Today</option>
            <option value={1}>Yesterday</option>
            <option value={7}>Last 7 Days</option>
            <option value={30}>Last 30 Days</option>
            <option value={60}>Last 60 Days</option>
            <option value={90}>Last 90 Days</option>
            <option value={180}>Last 180 Days</option>
            <option value={-1}>Custom Date</option>
          </select>
          <EmptyModal
            isOpen={dateOpen}
            onClose={() => {}}
            customWidthClass="w-auto"
            size="small"
            // aria-labelledby="modal-modal-title"
            // aria-describedby="modal-modal-description"
          >
            <Paper
              sx={{
                position: "relative",
                left: "50%",
                transform: "translate(-50%, 0%)",
                width: "fit-content",
                height: "fit-content",
                padding: "1.2em",
              }}
            >
              <div className="flex my-1 justify-center content-center">
                {selectionStartDate && (
                  <Typography>{`${selectionStartDate.getDate()} ${
                    dateMonthMapper[selectionStartDate.getMonth()]
                  } ${selectionStartDate.getFullYear()}`}</Typography>
                )}
                <Typography sx={{ paddingX: "1em" }}>{`-`}</Typography>
                {selectionEndDate && (
                  <Typography>{`${selectionEndDate.getDate()} ${
                    dateMonthMapper[selectionEndDate.getMonth()]
                  } ${selectionEndDate.getFullYear()}`}</Typography>
                )}
              </div>
              <DatePicker
                // selected={new Date()}
                onChange={handleDateRangeChange!}
                startDate={datePickerStartDate}
                endDate={datePickerEndDate}
                minDate={new Date(new Date().setDate(today.getDate() - 360))} //set min day to a year ago
                maxDate={new Date()} //set max date to today
                className="dataSet-date"
                selectsRange
                inline
              />
              <div className="flex my-1 justify-center gap-1 content-center">
                <Button
                  color="secondary"
                  size="medium"
                  text="Cancel"
                  variant="primary"
                  onClick={handleCloseDatePickerModal}
                />
                <Button
                  color="primary"
                  size="medium"
                  text="Apply Filter"
                  variant="primary"
                  onClick={handleApplyCustomDate}
                />
              </div>
            </Paper>
          </EmptyModal>
        </div>
        <div className="select select_version mx-1">
          <div>Select Published Version:</div>
          <select
            name="version"
            id="version"
            className="p-3 border-neutral-400 border-2 rounded-md w-48 bg-white"
            onChange={(e) => handleOnPublishedWalkthroughChange(e.target.value)}
            value={selectionPublishedWalkthroughId}
          >
            {walkthroughVersions && walkthroughVersions.length > 0 ? (
              walkthroughVersions.map((list, i) => {
                return (
                  <option
                    key={i}
                    value={list.published_walkthrough_id}
                  >{`Version ${list.published_version}`}</option>
                );
              })
            ) : (
              <option value={0}>{`No versions published.`}</option>
            )}
          </select>
        </div>
        {/* Refresh button goes here  */}
      </div>
      <>
        {/* CHART COMPONENTS */}
        {selectionStartDate &&
          selectionEndDate &&
          viewByDailyDisabled &&
          selectionPublishedWalkthroughId && (
            <ComponentSeenDismissed
              viewByDailyDisabled={viewByDailyDisabled}
              selectionPublishedWalkthroughId={selectionPublishedWalkthroughId}
              selectionStartDate={selectionStartDate}
              selectionEndDate={selectionEndDate}
            />
          )}
        <br />
        <h3 className="pageHeader1 mt-6 mb-3">Step Performance</h3>
        {selectionStartDate &&
          selectionEndDate &&
          selectionPublishedWalkthroughId && (
            <ComponentOverAllStep
              selectionPublishedWalkthroughId={selectionPublishedWalkthroughId}
              selectionStartDate={selectionStartDate}
              selectionEndDate={selectionEndDate}
            />
          )}
        <br />
        {/* code for manual and automatic analytic chart*/}
        {/* <h2 className="pageSettingsHeader mt-6 mb-3">
          {"Manual & Automatic Launch Analytics (in this period)"}
        </h2>
        {selectionStartDate &&
          selectionEndDate &&
          viewByDailyDisabled &&
          selectionPublishedWalkthroughId && (
            <ComponentLaunchAnalytic
              viewByDailyDisabled={viewByDailyDisabled}
              selectionStartDate={selectionStartDate}
              selectionEndDate={selectionEndDate}
            />
          )}
        <br /> */}
        <h3 className="pageHeader1 mt-6 mb-3">User Interaction Table</h3>
        <ComponentUserInteraction />
      </>
    </>
  );
};

const dateMonthMapper: {
  [key: number]: string;
} = {
  0: "Jan",
  1: "Feb",
  2: "Mar",
  3: "Apr",
  4: "May",
  5: "Jun",
  6: "Jul",
  7: "Aug",
  8: "Sep",
  9: "Oct",
  10: "Nov",
  11: "Dec",
};

export default ComponentSelectDataSet;
