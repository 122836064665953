// Hack config for tailwindcss-mui-integration.
// Creating this second copy due to both (a) Restrictions of /src folder code calling partent folder, and (b) Required to call directly to a tailwindcss config file to activate & align with the theme settings.

// PLEASE REMEMBER TO STILL UPDATE tailwind.config.js AS IT IS STILL THE PRIMARY CONFIG FILE FOR TAILWINDCSS, WHILE THIS IS MEANT ONLY FOR MATERIALUI INTEGRATION SETTINGS.

// module.exports = {
//   theme: {
//     extend: {
//       colors: {
//         default: {
//           primary: "#7657F1",
//           secondary: "#FC5453",
//           neutral: "#EDEDF1",
//           success: "#16A34A",
//           warning: "#FB8C00",
//           info: "#2563EB",
//           error: "#DC2626",
//           "neutral-black": "#00002D",
//         },
//       },
//     },
//   },
// };

export default {
  theme: {
    extend: {
      colors: {
        default: {
          primary: "#7657F1",
          secondary: "#FC5453",
          neutral: "#EDEDF1",
          success: "#16A34A",
          warning: "#FB8C00",
          info: "#2563EB",
          error: "#DC2626",
          "neutral-black": "#00002D",
        },
      },
    },
  },
};
