import store from "../../store";
import { PaymentTier } from "../../store/reducers/reducerPayment";
import attachHTTPRequestHeaders from "../../utility/functions/attachHTTPRequestHeaders";
import { api } from "./_axios";

/*
    ===================================================================    
    CALLS-PAYMENT API
    ===================================================================
*/

/** Api call for subscripe payment*/
export const callPostSubscribe = async (
  tier: "MICRO_PLAN" | "STARTER_PLAN" | "BUSINESS_PLAN",
  billing_cycle: "BILLING_MONTHLY" | "BILLING_YEARLY"
) => {
  const config = attachHTTPRequestHeaders(true);

  const state = store.getState().payment.temporaryAuth;

  const customConfig = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application",
      Authorization: state,
      "SignIn-Type": "AWS",
    },
  };

  let planCode = billing_cycle === "BILLING_MONTHLY" ? tier : `${tier}_YEARLY`; // "MICRO_PLAN" | "STARTER_PLAN" | "BUSINESS_PLAN" | "MICRO_PLAN_YEARLY" | "STARTER_PLAN_YEARLY" | "BUSINESS_PLAN_YEARLY"

  const data = {
    plan_tier: planCode,
    billing_cycle: billing_cycle,
  };

  const usedConfig = state ? customConfig : config;

  console.log("---callPostSubscribe API", tier);

  return (
    api
      //@ts-ignore
      .post(`payment/subscribe`, data, usedConfig)
      .then((res) => res)
      .catch((err) => err)
  );
};

/** Api call to cancel an active subscription */
export const callPostSubscriptionCancel = async (orgId: string) => {
  const config = attachHTTPRequestHeaders(true);

  return api
    .post(`payment/subscription/cancel/${orgId}`, {}, config)
    .then((res) => res)
    .catch((err) => err);
};

/** Api call to update email that receives invoices*/
export const callPOSTUpdateInvoiceEmail = async (
  orgID: string,
  email: string
) => {
  const config = attachHTTPRequestHeaders(true);

  const data = {
    invoice_email: email,
  };

  return api
    .post(`/email/update/${orgID}`, data, config)
    .then((res) => res)
    .catch((err) => err);
};

/** GET - API call to get list of invoices*/
export const callGETInvoiceListByOrgId = async (orgId: string) => {
  const config = attachHTTPRequestHeaders(true);

  return api
    .get(`payment/invoice/${orgId}`, config)
    .then((res) => res)
    .catch((err) => err);
};
