import React from "react";

const ForbiddenPermissionView = () => {
  return (
    <div className="px-20 w-full">
      {/* usertip logo */}
      <div className="">
        <img
          src="/assets/images/usertip-logo-color-horizontal-transparent-v2.png"
          alt="usertip logo"
          className="sidebarHeaderLogo"
        />
      </div>
      <div className="w-ful h-full flex justify-center items-center flex-col">
        <h1 className="title-m">You're not allowed to view this snap</h1>
        <p className="text-default-neutral-60 font-normal text-xl leading-5 ">
          Please ask your organization to change snap sharing permission or
          contact
          <span className="text-default-primary hover:cursor-pointer">
            <a href="mailto:support@usertip.com"> support</a>
          </span>
        </p>
      </div>
    </div>
  );
};

export default ForbiddenPermissionView;
