import React, { useState } from "react";

import ComponentAlertSnackbar from "./ComponentAlertSnackbar";
import { useDispatch } from "react-redux";
import { Button, EmptyModal, InputField } from "usertip-component-library";

const ComponentAddTeams = ({
  displayUserRole,
  orgID,

  dataSet,

  onAddBuilderTeam,
  onAddNewViewerTeam,

  showModalAdd,
  setShowModalAdd,
}) => {
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState({ option: false, message: "" });

  const [showAlertSnackbar, setShowAlertSnackbar] = useState(false);
  const [confirmBtnDisabled, setConfirmBtnDisabled] = useState(true);

  const nameDefaultInfoText = "";
  const nameDefaultInvalidTeamNameText = "*Team name already exists";
  const nameDefaultInvalidFormatText =
    "*Team name should be more than 3 characters";

  const dispatch = useDispatch();
  // Team name field change handler.
  const nameChangeHandler = (e) => {
    const value = e.target.value.trim();

    // Only allow Team names more than 3 characters
    if (value.length >= 3) {
      setConfirmBtnDisabled(false);

      setName(value);
      resetNameErrorHandler();
    } else {
      setConfirmBtnDisabled(true);
      setTeamNameErrorHandler(nameDefaultInvalidFormatText);
    }
  };

  // Set Team Name input field warning.
  const setTeamNameErrorHandler = (message) => {
    setNameError({ option: true, message });
  };

  // Remove user email input field warning.
  const resetNameErrorHandler = () => {
    setNameError({ option: false, message: "" });
  };

  // Create walkthrough form submission handler
  const handleAddNewTeamFormSubmission = (e) => {
    e.preventDefault();
    e.stopPropagation();

    let isTeamNameAlreadyExists = false;
    for (let i = 0; i < dataSet.length; i++) {
      if (dataSet[i].name === name.trim()) {
        isTeamNameAlreadyExists = true;
        break;
      }
    }

    // If New Team Name Does Not Exist, Process Add New Team Name to respective Builder / Viewer Team via API
    if (!isTeamNameAlreadyExists) {
      if (displayUserRole === "Builder") {
        handleHideModal();
        const teamData = {
          org_id: orgID,
          org_name: name,
        };
        dispatch(onAddBuilderTeam(teamData));
      } else if (displayUserRole === "Viewer") {
        handleHideModal();
        const teamData = {
          org_id: orgID,
          org_name: name,
        };
        dispatch(onAddNewViewerTeam(teamData));
      }
    } else {
      setTeamNameErrorHandler(nameDefaultInvalidTeamNameText);
    }
  };

  // Modal close handler
  const handleHideModal = () => {
    setShowAlertSnackbar(false);
    resetNameErrorHandler();

    setShowModalAdd(false);
  };

  return (
    <EmptyModal
      isOpen={showModalAdd}
      onClose={handleHideModal}
      size="large"
      customClass="ut-py-5"
    >
      <div className="modalHeader">Add a new {displayUserRole} Team</div>

      <div className="modalSubHeader">
        Add a new {displayUserRole} Team to manage access to building & editing
        specific walkthroughs.
      </div>

      <div className="modalForm">
        <div className="modalInputTextfield">
          <InputField
            variant="text"
            label="Enter the team name"
            placeholder="Enter the team name"
            helperText={nameDefaultInfoText}
            error={nameError.option}
            errorText={nameError.message}
            onChange={nameChangeHandler}
            customWidth="ut-w-full"
            autoFocus={true}
          />
        </div>

        <div className="flex justify-end gap-2">
          <Button
            color="primary"
            variant="primary"
            size="medium"
            text="Cancel"
            onClick={handleHideModal}
          />
          <Button
            onClick={handleAddNewTeamFormSubmission}
            size="medium"
            text="Confirm"
            variant="primary"
            color="primary"
            disabled={confirmBtnDisabled}
          />
        </div>
      </div>

      <ComponentAlertSnackbar
        showAlertSnackbar={showAlertSnackbar}
        setShowAlertSnackbar={setShowAlertSnackbar}
        variant={"error"}
        title={`${displayUserRole} Team Name already exists. Please use another name.`}
      />
    </EmptyModal>
  );
};

export default ComponentAddTeams;
