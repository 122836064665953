import React, { useEffect, useState } from "react";
import { DataGrid, GridColDef, enUS } from "@mui/x-data-grid";
import { convertDateToStandardFormat } from "../_functions/FunctionDate";
import {
  LockClock,
  PersonAdd,
  Delete,
  TransferWithinAStation,
} from "@mui/icons-material";
import { Button } from "usertip-component-library";
import { useDispatch, useSelector } from "react-redux";
import ComponentAddUsers from "../ComponentAddUsers";
import {
  organizationPostInviteUserToOrganization,
  removeUserFromOrganization,
  setTheIndex,
} from "../../store/reducers/reducerOrganization";
import { RootState } from "../../store";
import ComponentMoveUserBetweenRoles from "../ComponentMoveUserBetweenRoles";
import ComponentRemoveUsers from "../ComponentRemoveUsers";

export default function TableOrgBuilder() {
  const [showModalMove, setShowModalMove] = useState(false);
  const [showModalRemove, setShowModalRemove] = useState(false);
  // const [removeMode, setRemoveMode] = useState("");
  const [resetTableSelectAll, setResetTableSelectAll] = useState(false);

  const dispatch = useDispatch();

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      flex: 0.35,
      minWidth: 173,
      editable: false,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 0.9,
      minWidth: 363,
      editable: false,
    },
    {
      field: "confirmed_invitation",
      headerName: "Status",
      minWidth: 145,
      editable: false,
      valueFormatter: (params) => {
        return params.value ? "Confirmed" : "Pending";
      },
    },
    {
      field: "createdAt",
      headerName: "Date Joined",
      minWidth: 196,
      editable: false,
      valueFormatter: (params) => convertDateToStandardFormat(params.value),
    },
    {
      field: "action",
      headerName: "Action(s)",
      minWidth: 132,
      renderCell: (params) => (
        <div className="flex flex-row">
          <div
            className="cursor-pointer text-neutral-500"
            onClick={() => {
              setShowModalMove(true);
              dispatch(setTheIndex(params.id));
            }}
          >
            <TransferWithinAStation />
          </div>
          <div
            className="cursor-pointer text-neutral-500"
            onClick={() => {
              setShowModalRemove(true);
              dispatch(setTheIndex(params.id));
            }}
          >
            <Delete />
          </div>
        </div>
      ),
    },
  ];

  /** For manipulating the columns data */
  const [columnsState, setColumnsState] = useState(columns);

  const { builders, emailInviteMsg, appLoading, alert } = useSelector(
    (state: RootState) => state.org
  );
  const builderArray = builders || [];

  //state from redux
  const orgID = useSelector((state: any) => state.user.userData.org);
  const { role } = useSelector((state: any) => state.user.userData);

  const [showModalAdd, setShowModalAdd] = useState(false);
  const [preloadModalState, setPreloadModalState] = useState("AddUsers");

  /** This is for removing the action column from the table for the builder role */
  useEffect(() => {
    if (role === "builder") {
      const newColumns = columns.filter((val) => {
        return val.field !== "action";
      });

      setColumnsState(newColumns);
    } else {
      setColumnsState(columns);
    }
  }, [role]);

  const handleAddUserRoleClick = () => {
    // Open any Modal, as at any one time, only ONE MODAL will be pre-loaded and opened
    setPreloadModalState("AddUsers");
    setShowModalAdd(true);
  };

  const handleMassImportClick = () => {
    setPreloadModalState("MassImport");
    setShowModalAdd(true);
  };

  const addIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.54163 6.32302C6.14846 6.32302 7.43649 5.01832 7.43649 3.3907C7.43649 1.76307 6.14846 0.458374 4.54163 0.458374C2.93479 0.458374 1.64676 1.76307 1.64676 3.3907C1.64676 5.01832 2.93479 6.32302 4.54163 6.32302ZM4.54163 7.7589C2.18159 7.7589 0.166626 8.13624 0.166626 9.6438C0.166626 11.1508 2.16933 11.5417 4.54163 11.5417C6.90108 11.5417 8.91663 11.1644 8.91663 9.65681C8.91663 8.14925 6.91392 7.7589 4.54163 7.7589ZM10.6071 4.59296H11.3089C11.5978 4.59296 11.8333 4.8318 11.8333 5.12474C11.8333 5.41767 11.5978 5.65651 11.3089 5.65651H10.6071V6.34326C10.6071 6.6362 10.3722 6.87504 10.0827 6.87504C9.79382 6.87504 9.55829 6.6362 9.55829 6.34326V5.65651H8.85769C8.56822 5.65651 8.33329 5.41767 8.33329 5.12474C8.33329 4.8318 8.56822 4.59296 8.85769 4.59296H9.55829V3.90682C9.55829 3.61388 9.79382 3.37504 10.0827 3.37504C10.3722 3.37504 10.6071 3.61388 10.6071 3.90682V4.59296Z"
        fill="white"
      />
    </svg>
  );

  const customLocalization = {
    ...enUS,
    noRowsLabel: "No builders found. Please add one!",
  };

  return (
    <div>
      <div className="bg-default-primary rounded-tr-2xl rounded-tl-2xl flex flex-row justify-between px-6 py-4 items-center text-white">
        <div className="text-base font-bold">Manage Builder</div>
        {role !== "builder" && (
          <div className="flex flex-row gap-2 items-center border-whi">
            <Button
              text="Add Builder"
              iconLeft
              icon={<PersonAdd />}
              size="small"
              variant="outlined"
              color="neutral"
              onClick={() => handleAddUserRoleClick()}
              customClass={"ut-btn-org"}
            />

            <Button
              text="Mass Import"
              iconLeft
              icon={<LockClock />}
              size="small"
              variant="outlined"
              color="neutral"
              onClick={() => handleMassImportClick()}
              customClass={"ut-btn-org"}
            />
          </div>
        )}
      </div>
      <DataGrid
        rows={builderArray}
        columns={columnsState}
        autoHeight
        checkboxSelection
        localeText={customLocalization}
        initialState={{
          pagination: {
            page: 0,
            pageSize: 25,
          },
        }}
        rowsPerPageOptions={[25, 50, 100]}
        sx={{ borderEndEndRadius: 16, borderEndStartRadius: 16 }}
      />
      <ComponentAddUsers
        preloadModalState={preloadModalState}
        orgID={orgID}
        showModalAdd={showModalAdd}
        setShowModalAdd={setShowModalAdd}
        onInviteUserToOrg={organizationPostInviteUserToOrganization}
        displayUserRole={"Builder"}
      />
      <ComponentMoveUserBetweenRoles
        selectedUserList={builderArray}
        currentRole={"builder"}
        showModalMove={showModalMove}
        setShowModalMove={setShowModalMove}
        moveMode={"single"}
        setResetTableSelectAll={false}
      />
      {/* move mode mode is single and bulk, check ComponentMoveUserBetweenRoles.js */}

      <ComponentRemoveUsers
        listType={"User"}
        selectedUserOrgID={orgID}
        selectedUserList={builderArray}
        onConfirmRemoveUserFromOrg={removeUserFromOrganization}
        showModalRemove={showModalRemove}
        setShowModalRemove={setShowModalRemove}
        removeMode={"single"}
        setResetTableSelectAll={setResetTableSelectAll}
        viewingPermisionsType={undefined}
        selectedWalkthroughViewingPermissionsList={undefined}
        onDeleteOneIndvWalkthroughViewingPermission={undefined}
        onHandleBulkEditIndvWalkthroughPermission={undefined}
      />
      {/* remove mode mode is single and bulk, check ComponentRemoveUsers.js */}
    </div>
  );
}
