import React from "react";

import { TablePagination } from "@mui/material";

const ComponentListTablePagination = ({
  dataSet,

  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
}) => {
  // Handles Pagination: Page Change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handles Pagination: Total Rows Selected Per Page
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <TablePagination
      component={"div"}
      rowsPerPageOptions={[10, 25]}
      rowsPerPage={rowsPerPage}
      count={dataSet.length}
      page={dataSet.length <= 0 ? 0 : page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  );
};

export default ComponentListTablePagination;
