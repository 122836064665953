import React, { useEffect, useState } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { convertDateToStandardFormat } from "../_functions/FunctionDate";
import { Delete, Create } from "@mui/icons-material";
import { Button } from "usertip-component-library";
import { useSelector } from "react-redux";
import ComponentRemoveUsers from "../ComponentRemoveUsers";
import { RootState } from "../../store";
import {
  deleteOneIndvSharingPermissionFromSingleWalkthrough,
  deleteOneTeamSharingPermissionFromSingleWalkthrough,
} from "../../store/reducers/reducerWalkthrough";
import ComponentEditWalkthroughsViewingPermissions from "../ComponentEditWalkthroughsViewingPermissions";
import ComponentRemoveTeams from "../ComponentRemoveTeams";
import ComponentEditWalkthroughsViewerTeamPermissions from "../ComponentEditWalkthroughsViewerTeamPermissions";

interface TableData {
  theDataSet: [] | any;
  onHandleBulkEditTeamWalkthroughPermission: any;
  editData: [] | any;
  editPermissionData: [] | any;
  walkthroughID: any;
}

export default function TableWalkthroughsViewingPermissionTeams({
  theDataSet,
  onHandleBulkEditTeamWalkthroughPermission,
  editData,
  editPermissionData,
  walkthroughID,
}: TableData) {
  //   console.log(theDataSet);

  //state from redux
  const walkthroughs = useSelector(
    (state: any) => state.walkthrough.walkthroughs
  );
  const { userData } = useSelector((state: RootState) => state.user);
  const orgID = userData.org;

  const [selectedRowIds, setSelectedRowIds] = useState([]);
  console.log(selectedRowIds);

  const handleSelectionModelChange = (selectionModel: any) => {
    const selectedRowObjects = selectionModel.map((rowId: any) =>
      theDataSet.find((row: any) => row._id === rowId)
    );
    setSelectedRowIds(selectedRowObjects);
  };

  const [showModalRemove, setShowModalRemove] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);

  const [removeMode, setRemoveMode] = useState("");
  const [editMode, setEditMode] = useState("");

  const [resetTableSelectAll, setResetTableSelectAll] = useState(false);

  useEffect(() => {
    !showModalRemove &&
      setTimeout(() => {
        setSelectedRowIds([]);
      }, 200);
  }, [showModalRemove]);

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Team",
      flex: 0.3,
      minWidth: 165,
      editable: false,
      renderCell: (params) => {
        return params.value ? params.value : "-";
      },
    },
    {
      field: "createdAt",
      headerName: "Date Created",
      flex: 0.3,
      minWidth: 250,
      editable: false,
      valueFormatter: (params) => convertDateToStandardFormat(params.value),
    },
    {
      field: "updatedAt",
      headerName: "Last Edited Date",
      flex: 0.3,
      minWidth: 289,
      editable: false,
      valueFormatter: (params) => convertDateToStandardFormat(params.value),
    },
    {
      field: "id",
      headerName: "Action(s)",
      minWidth: 160,
      //   renderCell: (params) => (
      //     <div className="flex flex-row gap-3">
      //       <div
      //         className="cursor-pointer text-neutral-500"
      //         onClick={(e) => {
      //           setShowModalRemove(true);
      //           setRemoveMode("single");
      //           console.log(params.row);
      //         }}
      //       >
      //         <Delete />
      //       </div>
      //     </div>
      //   ),
    },
  ];

  return (
    <div>
      <div className="bg-default-primary rounded-tr-[20px] rounded-tl-[20px] flex flex-row justify-between px-6 py-4 items-center text-white">
        <div className="text-base font-bold w-full">
          {selectedRowIds.length > 0 ? (
            <span>{selectedRowIds.length} selected</span>
          ) : (
            <div className="flex justify-between">
              <span className="flex items-center">
                Manage Viewing Permission
              </span>
              <span>
                <Button
                  text="Edit Permissions"
                  iconLeft
                  icon={<Create fontSize="small" />}
                  size="small"
                  variant="outlined"
                  color="neutral"
                  onClick={() => {
                    setShowModalEdit(true);
                    setEditMode("bulk");
                  }}
                  customClass={"ut-btn-org"}
                />
              </span>
            </div>
          )}
        </div>
        <div
          className={` flex-row gap-2 items-center border-whi ${
            selectedRowIds.length > 0 ? "flex" : "hidden"
          }`}
        >
          <Button
            text="Edit Permissions"
            iconLeft
            icon={<Create fontSize="small" />}
            size="small"
            variant="outlined"
            color="neutral"
            onClick={() => {
              setShowModalEdit(true);
              setEditMode("bulk");
            }}
            customClass={"ut-btn-org"}
          />
          <Button
            text="Bulk Delete"
            iconLeft
            icon={<Delete fontSize="small" />}
            size="small"
            variant="outlined"
            color="neutral"
            onClick={() => {
              setShowModalRemove(true);
              setRemoveMode("bulk");
            }}
            customClass={"ut-btn-org"}
          />
        </div>
      </div>
      <div className="bg-white">
        <DataGrid
          rows={theDataSet}
          columns={columns}
          autoHeight
          checkboxSelection
          selectionModel={selectedRowIds.map((row: any) => row._id)}
          onSelectionModelChange={handleSelectionModelChange}
          initialState={{
            pagination: {
              page: 0,
              pageSize: 25,
            },
          }}
          rowsPerPageOptions={[25, 50, 100]}
          sx={{ borderEndEndRadius: 20, borderEndStartRadius: 20 }}
          getRowId={(row) => row._id || row._id}
        />
      </div>

      <ComponentEditWalkthroughsViewerTeamPermissions
        listType={"WalkthroughViewerTeamPermissions"}
        dataSet={editData}
        permissionDataSet={editPermissionData}
        walkthroughID={walkthroughID}
        onHandleBulkEditTeamWalkthroughPermission={
          onHandleBulkEditTeamWalkthroughPermission
        }
        showModalEdit={showModalEdit}
        setShowModalEdit={setShowModalEdit}
        editMode={editMode}
      />

      <ComponentRemoveTeams
        displayUserRoleFull={"Viewer"}
        listType={"WalkthroughViewerTeamPermissions"}
        viewerTeamType={"Individual"}
        // @ts-ignore
        selectedUserOrgID={orgID}
        selectedWalkthroughViewerTeamPermissionsList={selectedRowIds}
        onDeleteOneTeamPermissions={
          deleteOneTeamSharingPermissionFromSingleWalkthrough
        }
        onHandleBulkEditTeamWalkthroughPermission={
          onHandleBulkEditTeamWalkthroughPermission
        }
        showModalRemove={showModalRemove}
        setShowModalRemove={setShowModalRemove}
        removeMode={removeMode}
        setResetTableSelectAll={setResetTableSelectAll}
      />
    </div>
  );
}
