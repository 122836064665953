import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import { TextField, Chip, Tooltip, IconButton } from "@mui/material";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import { updateWalkhtroughTags } from "../store/reducers/reducerWalkthrough";
import { Tags } from "usertip-component-library";

const ComponentAddTags = ({ inputWalkthroughTags, walkthroughID }) => {
  const [walkthroughTags, setWalkthroughTags] = useState([]);
  const [tag, setTag] = useState("");
  const [tagError, setTagError] = useState({ option: false, message: "" });

  const [showInitialNoTag, setShowInitialNoTag] = useState(true);
  const [showAddTag, setShowAddTag] = useState(true);
  const [showAddTagTextfield, setShowAddTagTextfield] = useState(false);

  const dispatch = useDispatch();

  // User tag field change handler.
  const tagChangeHandler = (e) => {
    const value = e.target.value;

    setTag(value);
    if (value.trim()) {
      resetTagErrorHandler();
    }
  };

  // Remove user tag input field warning.
  const resetTagErrorHandler = () => {
    setTagError({ option: false, message: "" });
  };

  // Remove user tag input field warning.
  const resetTagHandler = () => {
    setTag("");
  };

  // Check if tag input field is empty
  const checkIfEntryTagIsEmpty = () => {
    const isEmpty = !tag.trim();

    return isEmpty;
  };

  // Checks if new tag already exists in array list
  const verifyIfTagExistsInWalkthroughTags = (tag) => {
    for (let i = 0; i < walkthroughTags.length; i++) {
      // If tag already exists in the current arraylist
      if (walkthroughTags[i].name === tag) {
        return true;
      }
    }
    return false;
  };

  // Reset helps to realign keys to count from 0 to length of array, else it will throw erros when an tag is deleted in between the array and a new one is added
  const resetWalkthroughTagsKeys = () => {
    for (let i = 0; i < walkthroughTags.length; i++) {
      walkthroughTags[i].key = i;
    }
  };

  const handleAddNewTag = (e) => {
    // Only process add tag from textfield when "Enter" key is pressed
    if (e.key === "Enter") {
      // Execute check to valid tag before adding to array list
      if (!checkIfEntryTagIsEmpty()) {
        // If tag already exists on list, inform to try new tag
        if (verifyIfTagExistsInWalkthroughTags(tag)) {
          resetTagHandler();
        }
        // If tag does not exist, add to array list
        else {
          // Reset array list keys count
          resetWalkthroughTagsKeys();

          // Add new tag to array list walkthroughTags if verified tag is of valid format
          const newlyAddedTagToWalkthroughTags = [...walkthroughTags];
          newlyAddedTagToWalkthroughTags.push({
            name: tag,
            key: walkthroughTags.length,
          });

          setWalkthroughTags(newlyAddedTagToWalkthroughTags);

          // Call API to update tags
          const walkthroughData = {
            walkthroughID: walkthroughID,
            tags: newlyAddedTagToWalkthroughTags,
          };
          dispatch(updateWalkhtroughTags(walkthroughData));
          // Clears textfield after successful add
          resetTagHandler();
        }
      }
    }
  };

  const handleDeleteSelectedTag = (tagSelectedToDelete) => () => {
    // Remove selected tag to delete
    const newWalkthroughTagsAfterDeletion = [...walkthroughTags].filter(
      (tagToDelete) => tagToDelete.name !== tagSelectedToDelete
    );

    setWalkthroughTags(newWalkthroughTagsAfterDeletion);

    // Call API to update tags

    const walkthroughData = {
      walkthroughID: walkthroughID,
      tags: newWalkthroughTagsAfterDeletion,
    };
    dispatch(updateWalkhtroughTags(walkthroughData));

    if (newWalkthroughTagsAfterDeletion.length === 0) {
      setShowInitialNoTag(true);
    }

    // Reset array list keys count
    resetWalkthroughTagsKeys();
  };

  const handleAddTagClick = () => {
    setShowAddTag(false);
    setShowAddTagTextfield(true);
    setShowInitialNoTag(false);
  };

  const handleAddTagTextfieldBlur = () => {
    setShowAddTag(true);
    setShowAddTagTextfield(false);
    resetTagHandler();

    if (walkthroughTags.length === 0) {
      setShowInitialNoTag(true);
    }
  };

  // Tracks changes in "walkthroughTags". Update when new change is available.
  useEffect(() => {
    if (inputWalkthroughTags) {
      setWalkthroughTags(inputWalkthroughTags);
    }
  }, [inputWalkthroughTags]);

  return (
    <div className="tableForm">
      <ul className="tableChipsList">
        {walkthroughTags.length > 0 ? (
          walkthroughTags.map((inputTag) => {
            let icon;

            return (
              <li key={inputTag.name} className="tableChip">
                {/* <Chip
                  icon={icon}
                  label={inputTag.name}
                  onDelete={handleDeleteSelectedTag(inputTag.name)}
                  size={"small"}
                /> */}
                <Tags
                  size="small"
                  variant="filled"
                  text={inputTag.name}
                  icon={icon}
                  clickable
                  onClick={handleDeleteSelectedTag(inputTag.name)}
                />
              </li>
            );
          })
        ) : (
          <>
            {
              // Show "Add A Tag" initial tag if no tags are present
              showInitialNoTag && (
                <div className="tableInitialNoTag">Add A Tag</div>
              )
            }
          </>
        )}

        {
          // Show the "Add Tag" logo
          showAddTag && (
            <Tooltip title={"Add Tag"} placement={"right"} arrow>
              <IconButton
                aria-label={"addTag"}
                size={"small"}
                sx={{
                  color: "#9CA3AF",
                  padding: "0px",
                  "&:hover": {
                    color: "#3B82F6",
                    backgroundColor: "transparent",
                  },
                }}
                onClick={handleAddTagClick}
              >
                <AddCircleIcon fontSize={"small"} />
              </IconButton>
            </Tooltip>
          )
        }
        {
          // Show the input textfield for "Add Tag"
          showAddTagTextfield && (
            <div className="tableInputTextfield">
              <TextField
                value={tag}
                autoFocus={true}
                onChange={tagChangeHandler}
                onKeyPress={handleAddNewTag}
                error={tagError.option}
                variant={"standard"}
                size={"small"}
                onBlur={handleAddTagTextfieldBlur}
              />
            </div>
          )
        }
      </ul>
    </div>
  );
};

export default ComponentAddTags;
