import { Button } from "@mui/material";
import React, { useState } from "react";
import AddBoxIcon from "@mui/icons-material/AddBox";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ComponentListHostInTable from "./ComponentListHostInTable";
import { HostWalkthrough } from "../interfaces/interface Organization";
import ComponentDuplicateWalkthrough from "./ComponentDuplicateWalkthrough";
import ComponentChangeStatusHostWalkthrough from "./ComponentChangeStatusHostWalkthrough";

interface Props {
  displayShowAddBtn: boolean;
  introMsg: string;
  indicatorMsg: string;
  dataSet: HostWalkthrough[];
  orgID: string;
}

const ComponentListHostWalkthroughs = ({
  displayShowAddBtn,
  introMsg,
  indicatorMsg,
  dataSet,
  orgID,
}: Props) => {
  const [showModalAdd, setShowModalAdd] = useState(false);
  const [showModalStatus, setShowModalStatus] = useState(false);
  const handleAddSharedWalkthrough = () => {
    setShowModalAdd(true);
  };

  const handleChangeStatus = () => {
    setShowModalStatus(true);
  };

  return (
    <div className="pageBase">
      <div className="pageHeader">List of Host Walkthroughs</div>

      <div className="pageSubHeader">{introMsg}</div>

      <div className="pageAddBtnBar">
        {
          // If displayShowAddBtn is true, show the button for adding new host walkthrough
          displayShowAddBtn && (
            <>
              <Button
                className="pageAddBtn"
                variant={"outlined"}
                aria-label={`Duplicate Walkthrough`}
                startIcon={<AddBoxIcon />}
                onClick={() => handleAddSharedWalkthrough()}
                disableElevation
              >
                Duplicate Walkthrough
              </Button>
              <Button
                className="pageAddBtn"
                variant={"outlined"}
                aria-label={`Change Status`}
                startIcon={<VisibilityIcon />}
                onClick={() => handleChangeStatus()}
                disableElevation
              >
                Change Status
              </Button>
            </>
          )
        }
      </div>

      {
        // If dataSet is not empty, Display table with data, else Display IndicatorMsg
        dataSet && dataSet.length > 0 ? (
          <ComponentListHostInTable dataSet={dataSet} />
        ) : (
          <div className="pageIndicatorMsg">{indicatorMsg}</div>
        )
      }
      <div className="pageDivider" />

      <ComponentDuplicateWalkthrough
        hostListWalkthrough={dataSet}
        userOrgID={orgID}
        setShowModalAdd={setShowModalAdd}
        showModalAdd={showModalAdd}
      />
      <ComponentChangeStatusHostWalkthrough
        userOrgID={orgID}
        walkthroughList={dataSet}
        setShowModalStatus={setShowModalStatus}
        showModalStatus={showModalStatus}
      />
    </div>
  );
};

export default ComponentListHostWalkthroughs;
