import React from "react";
import ComponentCodeBlock from "./ComponentCodeBlock";

const ComponentInstallationWPA = ({ api_key }: { api_key: string }) => {
  const initScript = `
 <script>
  {(function (api_key) {
    console.log("running script");
    let node = document.createElement("script");
    node.id = "usertip-cs";
    node.setAttribute("data-usertip-key", api_key);
    node.src =
      "https://static.usertip.com/p/usertip.min.js";
    const head = document.head || document.documentElement.firstElementChild;
    document.addEventListener("DOMContentLoaded", function () {
    head.appendChild(node);
    node.onload = function () {
      let config = {
        apiKey: api_key,
        };
  
     // add additional meta data of visitor to record
      let visitorMetaData = {
        //unique_id: visitor unique id, must be unique in client db
        //email: user email
        //title: user title or role
        //fname: first name
        //lname: last name
        //department: department
        };
  
        Usertip.init(config, visitorMetaData);
      };
      });
    )("${api_key}")}
  </script>;
    `;
  return (
    <>
      <p>
        If your website is an MPA or a server-side rendering MPA, we would
        recommend installing Usertip by adding the JavaScript code snippet below
        to every page that you want Usertip to be displayed on. The Usertip
        initialization code will be added together with this snippet as well.
        You can copy the code below and share it with your engineering team.
      </p>
      <br />
      <p>
        If you need to, you can also add metadata for your visitors which can be
        used to filter a user by email (for example).
      </p>
      <br />
      <ComponentCodeBlock codeBlock={initScript} />
      <br />
      <p>
        Once the snippet has been added, deploy the changes on your environment
        and the Usertip Code Snippet will be automatically installed and running
        on your website.
      </p>
    </>
  );
};

export default ComponentInstallationWPA;
