import React from "react";

import { TableBody, TableCell, TableRow } from "@mui/material";

import { stablizeSorting, getComparator } from "./_functions/FunctionListTable";

import { convertDateToStandardFormat } from "./_functions/FunctionDate";

const ComponentViewTableBody = ({
  listType,

  dataSet,

  order,
  orderBy,

  page,
  rowsPerPage,
}) => {
  // Handles Pagination: Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataSet.length) : 0;

  return (
    <TableBody>
      {
        // Populates per-row table from dataSet
        stablizeSorting(dataSet, getComparator(order, orderBy))
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) // Handles pagination
          .map((row, index) => {
            return (
              <TableRow key={index} hover>
                {listType === "Team" && (
                  <>
                    {/* <TableCell align="left">{row.name}</TableCell> */}
                    <TableCell align="left">{row.email}</TableCell>
                    <TableCell align="left">{row.unique_id}</TableCell>
                    <TableCell align="left">{row.role_id}</TableCell>
                    <TableCell align="left">{row.role}</TableCell>
                    <TableCell align="right">
                      {convertDateToStandardFormat(row.createdAt)}
                    </TableCell>
                  </>
                )}
              </TableRow>
            );
          })
      }

      {
        // Creating rows to help avoid a layout jump with emptyRows existing
        emptyRows > 0 && (
          <TableRow
            style={{
              height: 33 * emptyRows,
            }}
          >
            <TableCell colSpan={6} />
          </TableRow>
        )
      }
    </TableBody>
  );
};

export default ComponentViewTableBody;
