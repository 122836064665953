import { Alert, Snackbar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useState } from "react";
import ComponentModal from "../../ComponentModal";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteImage,
  confirmHardDeleteImage,
  resetImgList,
  resetMsg,
} from "../../../store/reducers/reducerMedia";
import { RootState } from "../../../store";
import WarningMsg from "../../Account/WarningMsg/WarningMsg";
import { EmptyModal, Button, CloseSquare } from "usertip-component-library";

const DeleteImageModal = ({
  imageTitle,
  mediaId,
  handleClose,
  isOpen,
}: {
  imageTitle: string;
  mediaId: string;
  handleClose: () => void;
  isOpen: boolean;
}) => {
  const msg = useSelector((state: RootState) => state.media.msg);
  const errorMsgs = useSelector(
    (state: RootState) => state.media.deleteImageMsg
  );
  const dispatch = useDispatch();

  const handleRemoveImage = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    /** If there are errorMsgs, means that there is draft/published walkthrough
     * that is using the image, so use confirmHardDelete
     */
    dispatch(deleteImage(mediaId));
  };

  const handleHardDeleteImage = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    /** If there are errorMsgs, means that there is draft/published walkthrough
     * that is using the image, so use confirmHardDelete
     */
    dispatch(confirmHardDeleteImage(mediaId));
  };

  useEffect(() => {
    if (msg === "deleteImgSuccess") {
      dispatch(resetImgList());
      handleClose();
      dispatch(resetMsg());
    }
  }, [msg]);

  // TODO: reset message when error is shown once

  useEffect(() => {
    dispatch(resetMsg());
  }, []);

  return (
    <EmptyModal isOpen={isOpen} onClose={handleClose} size="large">
      {msg === "deleteImgSuccess" && <SuccessSnackBar />}
      <CloseSquare
        className="absolute top-1 right-1 fill-default-neutral-100 w-5 h-5 cursor-pointer z-40"
        onClick={handleClose}
      />
      <div style={{ position: "relative" }} className="py-6">
        <div className="modalHeader">Delete Image</div>

        <div className="modalSubHeader">
          WARNING: You have selected <i>“Delete”</i> to remove{" "}
          <b>{`${imageTitle}`}</b>, please confirm to proceed or select cancel
          to return to the previous page.
        </div>
        {errorMsgs && errorMsgs.draft.length > 0 && (
          <div className="my-3">
            <div className="my-[6px] text-default-error">
              <div className="flex gap-1">
                {`These draft walkthroughs are using this image:`}
              </div>
            </div>
            {errorMsgs.draft.map((err, i) => (
              <WarningMsg msg={`${err}`} key={i} />
            ))}
          </div>
        )}
        {errorMsgs && errorMsgs.published.length > 0 && (
          <div className="my-3">
            <div className="my-[6px] text-default-error">
              <div className="flex gap-1">
                {`These published walkthroughs are using this image:`}
              </div>
            </div>
            {errorMsgs.published.map((err, i) => (
              <WarningMsg msg={`${err}`} key={i} />
            ))}
          </div>
        )}
        {!errorMsgs ? (
          <form
            noValidate
            autoComplete="off"
            onSubmit={handleRemoveImage}
            className="modalForm"
          >
            <div className="flex justify-end gap-4 w-1/2 ml-auto">
              <Button
                variant="primary"
                color="primary"
                size="fluid"
                text="Cancel"
                onClick={handleClose}
              />

              <Button variant="primary" size="fluid" text="Yes" color="primary">
                Yes
              </Button>
            </div>
          </form>
        ) : (
          <form
            noValidate
            autoComplete="off"
            onSubmit={handleHardDeleteImage}
            className="modalForm"
          >
            <div className="modalActionBtnBar">
              <Button
                variant="primary"
                color="primary"
                size="medium"
                text="Cancel"
                onClick={handleClose}
              />
              <Button
                variant="primary"
                color="primary"
                size="medium"
                text="Yes, Confirm Delete"
              />
            </div>
          </form>
        )}
      </div>
    </EmptyModal>
  );
};

function SuccessSnackBar() {
  const [open, setOpen] = useState(true);
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Image Deleted
        </Alert>
      </Snackbar>
    </>
  );
}

export default DeleteImageModal;
