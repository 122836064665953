import {
  AnalyticDataSet,
  StepDataSet,
} from "../../interfaces/interfaceAnalytic";

export const functionAdditionalStats = (
  dataSet: StepDataSet[],
  index: number
) => {
  /* Get All the selected step */
  const stepData = dataSet
    .map((d) => [...d.session_activities])
    .reduce((a, b) => a.concat(b), [])
    .filter((d) => d.index_position === index);

  if (stepData.length !== 0) {
    const stepDataComplete = stepData.filter((d) => d.status !== "seen");
    //get average
    const averageTimeFinish =
      stepData.reduce(
        //@ts-ignore
        (acc, curr, index) => {
          if (curr.status !== "seen") {
            return acc + curr.time_taken;
          } else {
            return acc;
          }
        },
        0
      ) / stepDataComplete.length;

    const sortedArray = stepDataComplete
      .slice()
      .sort((a, b) => a!.time_taken - b!.time_taken);

    /*get Median */
    const median = () => {
      let inputLength = stepDataComplete.length;
      let middleIndex = Math.floor(inputLength / 2);
      let oddLength = inputLength % 2 != 0;
      let median;
      if (oddLength) {
        // if array length is odd -> return element at middleIndex
        median = sortedArray[middleIndex].time_taken;
      } else {
        median =
          (sortedArray[middleIndex].time_taken +
            sortedArray[middleIndex - 1].time_taken) /
          2;
      }
      return median;
    };

    /* get user that spent longest time on step */
    const longestUserSpent = sortedArray.slice(-1).pop();

    /* get user that spent the shortost time on this step */
    const shortestUserSpent = sortedArray[0];

    /* get user drop off step details */
    let dropOffData;
    // if the first index drop off rate will always be 0
    if (index === 0) {
      dropOffData = {
        rate: 0,
        percentage: "0%",
      };
    } else {
      /* Get All the selected step */
      const prevStepData = dataSet
        .map((d) => [...d.session_activities])
        .reduce((a, b) => a.concat(b), [])
        .filter((d) => d.index_position === index - 1);

      const rate = prevStepData.length - stepData.length;
      dropOffData = {
        rate: rate,
        percentage:
          +((rate / prevStepData.length) * 100).toFixed(2).toString() + "%",
      };
    }
    return {
      averageTimeFinish: Math.round(averageTimeFinish),
      medianTimes: Math.round(median()),
      longestCompTimes: longestUserSpent?.time_taken,
      longestCompUser: longestUserSpent?._id,

      shortestCompUser: shortestUserSpent._id,
      shortestCompTimes: shortestUserSpent.time_taken,
      dropOffRate: dropOffData?.rate,
      dropOffPercentage: dropOffData?.percentage,
    };
  } else return null;
};
