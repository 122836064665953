import React from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../store";
import { changeBbThemeValue } from "../../../store/reducers/reducerTheme";
import FontSetting from "../../01-atoms/05-themeInputs/FontSetting";
import FontSizeSetting from "../../01-atoms/05-themeInputs/FontSizeSetting";
import FontWeightSetting from "../../01-atoms/05-themeInputs/FontWeightSetting";
import AlignmentSetting from "../../01-atoms/05-themeInputs/AlignmentSetting";
import LineHeightSetting from "../../01-atoms/05-themeInputs/LineHeightSetting";
import LetterSpacingSetting from "../../01-atoms/05-themeInputs/LetterSpacingSetting";
import SpacingSetting from "../../01-atoms/05-themeInputs/SpacingSetting";
import RadiusSetting from "../../01-atoms/05-themeInputs/RadiusSetting";
import ColorSetting from "../../01-atoms/05-themeInputs/ColorSetting";

interface Props {
  /** Prop for the type of bb component that edited */
  type: "modal" | "slideout" | "spotlight" | "tooltip";
  /** the style of BB that edited BuildingBlockType */
  style: any;
}

const BasicSettings = ({ type, style }: Props) => {
  const {
    label,
    fontFamily,
    fontSize,
    fontWeight,
    justifyContent,
    lineHeight,
    letterSpacing,
    color,
    margin,
    padding,
    backgroundColor,
    borderRadius,
  } = style;

  const dispatch = useDispatch<AppDispatch>();

  /** for handling font family dropdown */
  const fontFamilyHandler = (__: any, value: any) => {
    let style;

    switch (value) {
      case "Default Font":
        style = {
          fontFamily: "Roboto, sans-serif",
        };
        break;
      case "Roboto":
        style = { fontFamily: "Roboto, sans-serif" };
        break;
      case "Times New Roman":
        style = { fontFamily: "Times New Roman, serif" };
        break;
      case "Arial":
        style = { fontFamily: "Arial, sans-serif" };
        break;
    }

    dispatch(changeBbThemeValue({ type, label, style }));
  };

  /** for handling font size dropdown */
  const fontSizeHandler = (__: any, value: any) => {
    const style = { fontSize: Number(value) };
    dispatch(changeBbThemeValue({ type, label, style }));
  };

  /** for handling font weight toggle */
  const fontWeightHandler = (value: any) => {
    const style = { fontWeight: value };
    dispatch(changeBbThemeValue({ type, label, style }));
  };

  /** for handling alignment toggle */
  const alignmentHandler = (value: any) => {
    const style = { justifyContent: value };
    dispatch(changeBbThemeValue({ type, label, style }));
  };

  /** for handling line height dropdown */
  const lineHeightHandler = (__: any, value: any) => {
    if (value === "normal") {
      const style = { lineHeight: value };
      dispatch(changeBbThemeValue({ type, label, style }));
    } else {
      const style = { lineHeight: Number(value) };
      dispatch(changeBbThemeValue({ type, label, style }));
    }
  };

  /** for handling letter spacing input */
  const letterSpacingHandler = (value: number) => {
    const style = { letterSpacing: value };
    dispatch(changeBbThemeValue({ type, label, style }));
  };

  /** for handling font color toggle */
  const colorHandler = (value: any) => {
    const style = { color: value };
    dispatch(changeBbThemeValue({ type, label, style }));
  };

  /** for handling margin input */
  const marginHandler = (value: number) => {
    const style = { margin: value };
    dispatch(changeBbThemeValue({ type, label, style }));
  };

  /** for handling padding input */
  const paddingHandler = (value: number) => {
    const style = { padding: value };
    dispatch(changeBbThemeValue({ type, label, style }));
  };

  /** for handling border radius input */
  const borderRadiusHandler = (value: number) => {
    const style = { borderRadius: value };
    dispatch(changeBbThemeValue({ type, label, style }));
  };

  /** for handling border radius slider */
  const sliderBorderRadiusHandler = (__: any, value: number | number[]) => {
    const style = { borderRadius: value };
    dispatch(changeBbThemeValue({ type, label, style }));
  };

  /** For handling background color input */
  const backgroundColorHandler = (value: any) => {
    const style = { backgroundColor: value };
    dispatch(changeBbThemeValue({ type, label, style }));
  };

  /** For handling transparent checkbox */
  const transparentHandler = () => {
    let style;
    if (backgroundColor === "transparent") {
      style = { backgroundColor: "FFFFFF" };
    } else {
      style = { backgroundColor: "transparent" };
    }

    dispatch(changeBbThemeValue({ type, label, style }));
  };

  return (
    <div className="w-full h-full flex flex-col gap-3">
      <div className="w-full bg-default-primary-lightest py-2 px-5 text-sm font-light text-default-primary-light">
        Font
      </div>

      <div className="grid grid-cols-2 items-center gap-y-2 px-4">
        <FontSetting value={fontFamily} settingHandler={fontFamilyHandler} />

        <FontSizeSetting value={fontSize} settingHandler={fontSizeHandler} />

        <FontWeightSetting
          value={fontWeight}
          settingHandler={fontWeightHandler}
          label={label}
        />

        <AlignmentSetting
          value={justifyContent}
          settingHandler={alignmentHandler}
          label={label}
        />

        <LineHeightSetting
          value={lineHeight}
          settingHandler={lineHeightHandler}
        />

        <LetterSpacingSetting
          value={letterSpacing}
          settingHandler={letterSpacingHandler}
        />

        <ColorSetting
          label="Font Color"
          value={color}
          settingHandler={colorHandler}
          transparentOption={false}
        />
      </div>

      <div className="w-full bg-default-primary-lightest py-2 px-5 text-sm font-light text-default-primary-light">
        Spacing
      </div>

      <div className="grid grid-cols-2 items-center gap-y-2 px-4">
        <SpacingSetting
          label="Margin"
          value={margin}
          settingHandler={marginHandler}
          rightButton
        />

        <SpacingSetting
          label="Padding"
          value={padding}
          settingHandler={paddingHandler}
          rightButton
        />
      </div>

      <div className="w-full bg-default-primary-lightest py-2 px-5 text-sm font-light text-default-primary-light">
        Background
      </div>

      <div className="grid grid-cols-2 items-center gap-y-2 px-4">
        {label === "button" && (
          <RadiusSetting
            value={borderRadius}
            handlerSetting={borderRadiusHandler}
            sliderHandler={sliderBorderRadiusHandler}
          />
        )}

        <ColorSetting
          label="Background Color"
          value={backgroundColor}
          settingHandler={backgroundColorHandler}
          transparentOption
          transparentHandler={transparentHandler}
          name={label}
        />
      </div>
    </div>
  );
};

export default BasicSettings;
