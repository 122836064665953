import attachHTTPRequestHeaders from "../../utility/functions/attachHTTPRequestHeaders";
import { api } from "./_axios";

/*
    ===================================================================    
    API 2.0: API-Calls To Server
    ===================================================================
    1. api_p1 is default for all API 2.0 calls
    2. api_p2 is now only for /signIn route  
    ===================================================================    
    CALLS-GROUPS API
    ===================================================================
*/

// POST: Create a new group

export const callPOSTCreateNewGroup = async (org_id, group_name) => {
  const data = {
    org_id: org_id,
    name: group_name,
  };
  const config = attachHTTPRequestHeaders(true);
  return api
    .post(`/walkthrough/group/create`, data, config)
    .then((res) => res)
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const callDELETEExistingGroup = async (walkthrough_group_id) => {
  const config = attachHTTPRequestHeaders(true);
  return api
    .delete(`/walkthrough/group/delete/${walkthrough_group_id}`, config)
    .then((res) => res)
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const callGETRetrieveAllGroupWalkthroughsByOrg = async (org_id) => {
  const config = attachHTTPRequestHeaders(true);
  return api
    .get(`/walkthrough/group/getAllByOrg/${org_id}`, config)
    .then((res) => res)
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const callGETRetrieveGroupWalkthrough = async (walkthrough_group_id) => {
  const config = attachHTTPRequestHeaders(true);
  return api
    .get(`/walkthrough/group/${walkthrough_group_id}`, config)
    .then((res) => res)
    .catch((err) => {
      return Promise.reject(err);
    });
};
