// TODO: Consider nested in nested object naming. This flatten function can flatten objects that are one level deep,
// nested in nested objects may have unintended names
export const flattenObj = (ob: any, obj_name: string) => {
  // The object which contains the final result
  let result: any = {};
  // Only flatten the object based on obj_name (Eg: "meta")
  if (ob.hasOwnProperty(obj_name)) {
    // loop through the object "ob"

    for (const i in ob) {
      // We check the type of the i using
      // typeof() function and recursively
      // call the function again
      if (typeof ob[i] === "object" && !Array.isArray(ob[i])) {
        const temp = flattenObj(ob[i], obj_name);
        for (const j in temp) {
          // Store temp in result
          result[`${obj_name}_${j}`] = temp[j];
        }
      }

      // Else store ob[i] in result directly
      else {
        result[i] = ob[i];
      }
    }
    return result;
  } else {
    return ob;
  }
};
