import { getUnixTime } from "date-fns";
import {
  AnalyticDataSet,
  UserInteractivityProcDataSet,
} from "../../interfaces/interfaceAnalytic";

const getAverage = (start_time: Date, end_time: Date) => {
  return getUnixTime(end_time) - getUnixTime(start_time);
};

const groupUserInteraction = (dataSet: AnalyticDataSet[]) => {
  const reduceData = dataSet.reduce<UserInteractivityProcDataSet[]>(
    (acc, curr) => {
      // use org id as index finder
      const index = acc.findIndex((el: UserInteractivityProcDataSet) => {
        /** Check if roleid matches OR cognitoid matches */
        if (
          curr.cognito_id &&
          el.role === curr.role &&
          el.role_id === curr.cognito_id
        ) {
          return true;
        } else if (
          curr.role_id &&
          curr.role === "cs_viewer" &&
          el.role_id === curr.role_id
        ) {
          return true;
        }
        return false;
      });
      if (index === -1) {
        const data = {
          role_id: "",
          unique_id: "",
          email: "",
          role: "",
          avg_spent: 0,
          seen: 0,
          completed: 0,
          dismissed: 0,
          manual_launch: 0,
          automatic_launch: 0,
          repeat_views: 0,
          incomplete: 0,
        } as UserInteractivityProcDataSet;

        /** If role_id exists, set it as role_id. If cognito_id exists, set it as role_id
         * NOTE: role_id will be undefined if cognito_id exists in the curr data
         */
        data.role_id = curr.role_id ? curr.role_id : curr.cognito_id;

        // set role value
        data.role = curr.role;

        // set initial unique ID
        curr.unique_id && (data.unique_id = curr.unique_id);

        // set initial average time to array of number and will be procced later
        // if status seen mean walkthrough is incomplete so there's no end time
        if (curr.status !== "seen") {
          data.avg_spent = getAverage(
            new Date(curr.start_time),
            new Date(curr.end_time)
          );
        }
        // set initial Email
        curr.email && (data.email = curr.email);
        // set initial status
        switch (curr.status) {
          case "completed":
            data.completed = 1;
            data.seen = 1;
            break;
          case "dismissed":
            data.dismissed = 1;
            data.seen = 1;
            break;
          case "seen":
            data.seen = 1;
            data.incomplete = 1;
            break;
          default:
            break;
        }

        // set launch type
        switch (curr.launch_type) {
          case "manual":
            data.manual_launch = 1;
            break;
          case "automatic":
            data.automatic_launch = 1;
            break;
          default:
            break;
        }
        // set first time
        if (!curr.firstTime) {
          data.repeat_views = 1;
        }
        acc.push(data);
      } else {
        if (curr.status !== "seen") {
          acc[index].avg_spent =
            getAverage(new Date(curr.start_time), new Date(curr.end_time)) +
            acc[index].avg_spent;
        }

        switch (curr.status) {
          case "completed":
            acc[index].completed++;
            acc[index].seen++;
            break;
          case "dismissed":
            acc[index].dismissed++;
            acc[index].seen++;
            break;
          case "seen":
            acc[index].seen++;
            acc[index].incomplete++;
            break;
          default:
            break;
        }

        switch (curr.launch_type) {
          case "manual":
            acc[index].manual_launch++;
            break;
          case "automatic":
            acc[index].automatic_launch++;
            break;
          default:
            break;
        }

        if (!curr.firstTime) {
          acc[index].repeat_views++;
        }
      }
      return acc;
    },
    []
  );
  /* get data set total length without status seen
    later will be divided by average minute
  */
  const totalLength = dataSet.filter((d) => d.status !== "seen").length + 1;

  /* get total average each user by devide avg_spent by total Length */
  const reduceAvg = reduceData.map((d) => ({
    ...d,
    avg_spent: Math.floor(d.avg_spent / totalLength),
  }));

  return reduceAvg;
};

export default groupUserInteraction;
