import React from "react";
import ContainerBB from "./ContainerBB";
import { ModalThemeType } from "../../../interfaces/interfaceTheme";
interface Props {
  style: ModalThemeType;
}

const ModalBB = ({ style }: Props) => {
  const backgroundColor =
    style != undefined && style != null ? style.backgroundColor : null;
  const padding = style != undefined && style != null ? style.padding : null;
  const width = style != undefined && style != null ? style.width : null;
  const height = style != undefined && style != null ? style.height : null;
  const borderRadius =
    style != undefined && style != null ? style.borderRadius : null;

  // const fade = style != undefined && style != null ? style.fade : null;
  // const margin = style != undefined && style != null ? style.margin : null;

  const mainWidth =
    //@ts-ignore
    width!.unit === "auto" || width === null || width === ""
      ? "auto"
      : `${width?.value}${width?.unit}`;
  /**old data have value like height === "" */
  const mainHeight =
    //@ts-ignore
    height!.unit === "auto" || height === null || height === ""
      ? "auto"
      : `${height?.value}${height?.unit}`;
  const mainPadding = padding !== null ? padding + "px" : "18px";
  const mainBorderRadius =
    borderRadius !== null && borderRadius !== undefined
      ? borderRadius + "px"
      : "6px";

  return (
    <div
      style={{
        zIndex: 2,
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          backgroundColor: backgroundColor ? backgroundColor : "#FFFFFF",
          padding: mainPadding,
          // margin: (margin != undefined || margin != null) ? margin + 'px' : '0px',
          border: "2px solid #888", //TODO: future style
          width: mainWidth, //width
          height: mainHeight, //height
          display: "flex",
          // flexDirection: 'column',
          // alignItems: 'flex-start',
          // justifyContent: 'space-between',
          borderRadius: mainBorderRadius,
          pointerEvents: "all",
        }}
        className=""
      >
        <ContainerBB buildingBlockStyle={style.buildingBlockStyle} />
      </div>
    </div>
  );
};

export default ModalBB;
