import attachHTTPRequestHeaders from "../../utility/functions/attachHTTPRequestHeaders";
import { api } from "./_axios";

/*
    ===================================================================    
    CALLS-HOST ORGANIZATION API
    ===================================================================
*/

export const callGETHostOrgAndWalkthroughList = async (host_org_id) => {
  const config = attachHTTPRequestHeaders(true);
  return api
    .get(`org/getOrgAndWalkthroughList/${host_org_id}`, config)
    .then((res) => res)
    .catch((err) => err);
};

//get group of walkthroughs and their orgs
export const callGETGroupedOrgAndWalkthrough = async (host_org_id) => {
  console.log("calling callGETGroupedOrgAndWalkthrough");

  const config = attachHTTPRequestHeaders(true);
  return api
    .get(`org/getGroupedOrg/${host_org_id}`, config)
    .then((res) => res)
    .catch((err) => err);
};

//Post create / share walkthrough to guest org
export const callPostShareWalkthroughToGuest = async (
  org_id,
  guestOrgList,
  walkthroughList
) => {
  const renameGuestOrgKey = guestOrgList.map(
    ({ name: orgName, guest_org_id: org_id }) => ({
      orgName,
      org_id,
    })
  );

  const renameWalkthroughListKey = walkthroughList.map(
    ({ name: walkthrough_name, walkthrough_id }) => ({
      walkthrough_name,
      walkthrough_id,
    })
  );
  const walkthroughShare = {
    walkthroughShare: {
      guestOrg: renameGuestOrgKey,
      walkthrough: renameWalkthroughListKey,
    },
  };

  console.log(walkthroughShare);
  const config = attachHTTPRequestHeaders(true);

  return api
    .post(
      `/sharedWalkthrough/shareWalkthroughList/${org_id}`,
      walkthroughShare,
      config
    )
    .then((res) => res)
    .catch((err) => err);
};

// Delete edit shared walkthrough
export const callDeleteShareWalkthroughToGuest = async (
  host_org_id,
  guest_org_name,
  guest_org_id,
  walkthroughList
) => {
  const renameKey = walkthroughList.map(
    ({ walkthroughName: walkthrough_name, ...rest }) => ({
      walkthrough_name,
      ...rest,
    })
  );
  console.log(walkthroughList);
  const data = {
    deleteSharedWalkthrough: {
      orgName: guest_org_name,
      orgId: guest_org_id,
      walkthrough: renameKey,
    },
  };

  console.log({ data });
  const config = attachHTTPRequestHeaders(true);

  return api
    .delete(
      `sharedWalkthrough/deleteSharedWalkthrough/${host_org_id}`,
      data,
      config
    )
    .then((res) => res)
    .catch((err) => err);
};

export const callPostEditSharedWalkthrough = async (
  host_org_id,
  guest_org_name,
  guest_org_id,
  walkthrough
) => {
  const data = {
    editSharedWalkthrough: {
      guestOrgName: guest_org_name,
      guestOrgId: guest_org_id,
      walkthrough: walkthrough,
    },
  };

  const config = attachHTTPRequestHeaders(true);

  return api
    .post(
      `/sharedWalkthrough/editSharedWalkthrough/${host_org_id}`,
      data,
      config
    )
    .then((res) => res)
    .catch((err) => err);
};

export const callGetUserHostAccess = async (org_id) => {
  const config = attachHTTPRequestHeaders(true);

  return api
    .get(`/org/access/${org_id}`, config)
    .then((res) => res)
    .catch((err) => err);
};
