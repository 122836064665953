import React from "react";
import { SearchDropdown } from "usertip-component-library";
import { FontFamily } from "../../../interfaces/interfaceTheme";

interface Props {
  /** This prop is for selected dropdown value */
  value: FontFamily;
  /** This prop is for handling the dropdown */
  settingHandler: (id: any, value: any) => void;
}

const FontSetting = ({ value, settingHandler }: Props) => {
  const fontFamilyData: { id: string; value: string }[] = [
    { id: "1", value: "Default Font" },
    { id: "2", value: "Roboto" },
    { id: "3", value: "Times New Roman" },
    { id: "4", value: "Arial" },
  ];

  const fontFamilyTranslator = (value: FontFamily) => {
    if (value === "Roboto, sans-serif") {
      return "Roboto";
    } else if (value === "Times New Roman, serif") {
      return "Times New Roman";
    } else if (value === "Arial, sans-serif") {
      return "Arial";
    } else {
      return "Default Font";
    }
  };
  return (
    <>
      <h1 className="text-sm">Font:</h1>
      <SearchDropdown
        dataArray={fontFamilyData}
        selectedValue={{ value: fontFamilyTranslator(value) }}
        onClick={settingHandler}
        customWidth="w-full"
        scroll={false}
        search={false}
      />
    </>
  );
};

export default FontSetting;
