import React, { useState } from "react";

import { TextField } from "@mui/material";
import { Button } from "usertip-component-library";
import ComponentAlertSnackbar from "./ComponentAlertSnackbar";
import { EmptyModal } from "usertip-component-library";

const ComponentAddWalkthroughs = ({
  displayUserRole,
  orgID,

  dataSet,

  onAddBuilderWalkthrough,
  onAddNewViewerWalkthrough,

  showModalAdd,
  setShowModalAdd,
}) => {
  // eslint-disable-next-line
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState({ option: false, message: "" });

  const [showAlertSnackbar, setShowAlertSnackbar] = useState(false);
  const [confirmBtnDisabled, setConfirmBtnDisabled] = useState(true);

  const nameDefaultInfoText = "";
  // eslint-disable-next-line
  const nameDefaultInvalidWalkthroughNameText =
    "*Walkthrough name already exists";
  const nameDefaultInvalidFormatText =
    "*Walkthrough name should be more than 3 characters";

  // Walkthrough name field change handler.
  const nameChangeHandler = (e) => {
    const value = e.target.value.trim();

    // Only allow Walkthrough names more than 3 characters
    if (value.length >= 3) {
      setConfirmBtnDisabled(false);

      setName(value);
      resetNameErrorHandler();
    } else {
      setConfirmBtnDisabled(true);
      setWalkthroughNameErrorHandler(nameDefaultInvalidFormatText);
    }
  };

  // Set Walkthrough Name input field warning.
  const setWalkthroughNameErrorHandler = (message) => {
    setNameError({ option: true, message });
  };

  // Remove user email input field warning.
  const resetNameErrorHandler = () => {
    setNameError({ option: false, message: "" });
  };

  // Create walkthrough form submission handler
  const handleAddNewWalkthroughFormSubmission = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  // Modal close handler
  const handleHideModal = () => {
    setShowAlertSnackbar(false);
    resetNameErrorHandler();

    setShowModalAdd(false);
  };

  return (
    <EmptyModal
      isOpen={showModalAdd}
      onClose={handleHideModal}
      size="large"
      customClass="py-5"
    >
      <div className="modalHeader">Add a new Walkthrough</div>

      <div className="modalForm">
        <div className="modalInputTextfield">
          <TextField
            label="Enter the walkthrough name"
            autoFocus={true}
            onChange={nameChangeHandler}
            error={nameError.option}
            helperText={
              nameError.option ? nameError.message : nameDefaultInfoText
            }
            variant={"standard"}
            fullWidth
          />
        </div>

        <div className="flex justify-end gap-2">
          <Button
            variant="primary"
            color="primary"
            size="medium"
            text="Cancel"
            onClick={handleHideModal}
          />
          <Button
            onClick={handleAddNewWalkthroughFormSubmission}
            variant="primary"
            color="primary"
            size="medium"
            text="Confirm"
            disabled={confirmBtnDisabled}
          />
        </div>
      </div>

      <ComponentAlertSnackbar
        showAlertSnackbar={showAlertSnackbar}
        setShowAlertSnackbar={setShowAlertSnackbar}
        variant={"error"}
        title={`${displayUserRole} Walkthrough Name already exists. Please use another name.`}
      />
    </EmptyModal>
  );
};

export default ComponentAddWalkthroughs;
