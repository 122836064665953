import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import * as jose from "jose";
import { Button, UsertipHorizontalIcon } from "usertip-component-library";
import RegistrationByInviteForm from "../components/03-organisms/05-form/RegistrationByInviteForm";
import PageFooter from "../components/03-organisms/05-form/PageFooter";

const PageRegistrationByInvite = () => {
  let { inviteId, jwttoken } = useParams();
  const [jwtData, setJwtData] = useState<null | jose.JWTPayload>(null);
  const [jwtEmail, setJwtEmail] = useState<any>(null);

  const navigate = useNavigate();

  // Requires public key to decrypt
  let key = "secret";
  // jose: jwtVerify
  // REF: https://github.com/panva/jose/blob/main/docs/functions/jwt_verify.jwtVerify.md#readme
  // Converting key to required Uint8Array format
  let uint8arrayKey: Uint8Array = new TextEncoder().encode(key);

  useEffect(() => {
    if (jwttoken) {
      // Only execute verification when "jwtData" is null, else will end up with an endless loop.
      if (jwtData === null) {
        jose
          .jwtVerify(jwttoken, uint8arrayKey, {
            algorithms: ["HS384"],
            typ: "JWT",
          })
          .then((response) => {
            setJwtData(response.payload);
            setJwtEmail(response.payload.email);
          })
          .catch((err) => console.log(`err: ${err}`));
      }
    }
  }, [jwttoken, uint8arrayKey, jwtData]);

  // // Actively grab and verify existing url query
  // useEffect(() => {
  //   // console.log("---jwtData detected", jwtData, jwttoken);
  // }, [uint8arrayKey, jwtData]);

  return (
    <div className="h-min min-h-screen w-screen relative flex items-center justify-center">
      <div>
        <div className="absolute top-2 left-20">
          <UsertipHorizontalIcon />
        </div>
        <div className="flex flex-col gap-5 p-8 my-4">
          <div className="flex flex-col items-center gap-[0.625em]">
            <h1 className="text-5xl text-center font-medium text-default-neutral-100">
              Welcome to Usertip
            </h1>
            {jwtEmail !== null && (
              <p className="text-base font-medium text-default-neutral-60">
                You're Invited by {jwtData?.org_name}
              </p>
            )}
          </div>
          {/* TODO: Add loading for when JWTToken is being verified */}
          {jwtEmail === null ? (
            <div className="flex flex-col items-center justify-center gap-4">
              <p className="text-base font-medium text-default-neutral-60">
                Click this button to go to the Login Page
              </p>
              <Button
                color="primary"
                size="medium"
                text="Login Page"
                variant="primary"
                onClick={() => navigate("/login")}
              />
            </div>
          ) : (
            <RegistrationByInviteForm jwtData={jwtData} />
          )}
        </div>
      </div>
      <PageFooter />
    </div>
  );
};

export default PageRegistrationByInvite;
