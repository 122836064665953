import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { resetAlert } from "../store/reducers/reducerOrganization";
import { RootState } from "../store";
import AssistantControlSettings from "../components/AssistantControlSettings";
import AssistantPageManagementSettings from "../components/AssistantPageManagementSettings";
import ComponentAlertSnackbar from "../components/ComponentAlertSnackbar";

const PageAssistantSettings = () => {
  const [showAlertSnackbar, setShowAlertSnackbar] = useState(false);

  const { alert } = useSelector((state: RootState) => state.org);

  const dispatch = useDispatch();

  /** For show or unshow the alert */
  useEffect(() => {
    setShowAlertSnackbar(alert.active);
  }, [alert.active]);

  /** For reset alert on redux */
  useEffect(() => {
    if (!showAlertSnackbar) {
      dispatch(resetAlert());
    }
  }, [showAlertSnackbar]);

  return (
    <div className="pageBase">
      <div className="pageHeader">Assistant Settings</div>
      <div className="text-[#9CA3AF] mb-12">
        Adjust the assistant's placement, specify active pages, and designate
        inactive pages.
      </div>

      <div className="xl:w-[760px]">
        <AssistantControlSettings />
      </div>

      <div className="pageDivider-sm"></div>

      <div className="xl:w-[760px]">
        <AssistantPageManagementSettings />
      </div>

      <ComponentAlertSnackbar
        variant={alert.type as "error" | "success"}
        title={alert.message}
        showAlertSnackbar={showAlertSnackbar}
        setShowAlertSnackbar={setShowAlertSnackbar}
        hideAlert={alert.autoHideDuration}
      />
    </div>
  );
};

export default PageAssistantSettings;
