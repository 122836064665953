import { Button } from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { HostWalkthrough } from "../interfaces/interface Organization";
import {
  organizationGetSharedHostWalkthrough,
  organizationPostEditWalkthroughStatus,
} from "../store/reducers/reducerOrganization";
import ComponentChangeStatus from "./ComponentChangeStatus";
import { EmptyModal } from "usertip-component-library";

interface Props {
  showModalStatus: boolean;
  setShowModalStatus: React.Dispatch<React.SetStateAction<boolean>>;
  walkthroughList: HostWalkthrough[];
  userOrgID: string;
}

const ComponentChangeStatusHostWalkthrough = ({
  showModalStatus,
  setShowModalStatus,
  walkthroughList,
  userOrgID,
}: Props) => {
  const [processedData, setProcessedData] = useState<HostWalkthrough[]>();
  const dispatch = useDispatch();

  const handleHideStatus = () => {
    setProcessedData(undefined);
    setShowModalStatus(false);
  };

  const handleShareWalkthrough = async (
    e: React.FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault();
    // organizationPostEditWalkthroughStatus only updates list but does not return new values
    await dispatch(organizationPostEditWalkthroughStatus(processedData!));
    await dispatch(organizationGetSharedHostWalkthrough(userOrgID!));
    // TODO: dispatch retrieve the fresh list after update
    handleHideStatus();
  };
  return (
    <EmptyModal
      isOpen={showModalStatus}
      onClose={handleHideStatus}
      customClass="p-5"
      customWidthClass="w-[860px]"
    >
      <div className="modalHeader">Change Status</div>
      <div className="modalSubHeader">Show / Hide Host Walkthrough(s)</div>
      <form
        noValidate
        autoComplete="off"
        onSubmit={handleShareWalkthrough}
        className="modalForm"
      >
        <div className="inline-flex mb-8 gap-2">
          <ComponentChangeStatus
            walkthroughList={walkthroughList}
            setProccesedData={setProcessedData}
          />
        </div>
        <div className="modalActionBtnBar">
          <Button
            variant="contained"
            className="modalActionBtn"
            onClick={handleHideStatus}
          >
            Cancel
          </Button>
          <Button
            disabled={
              !processedData || processedData.length === 0 ? true : false
            }
            variant="contained"
            color="primary"
            className="modalActionBtn"
            type="submit"
          >
            Confirm
          </Button>
        </div>
      </form>
    </EmptyModal>
  );
};

export default ComponentChangeStatusHostWalkthrough;
