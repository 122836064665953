import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Snackbar,
} from "@mui/material";
import {
  Button as UsertipButton,
  EmptyModal,
  CloseSquare,
} from "usertip-component-library";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import React, { useEffect, useState } from "react";
import ComponentModal from "../../ComponentModal";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { resetUsedImage } from "../../../store/reducers/reducerMedia";

const ComponentBulkDeleteImage = ({
  isBulkDeleteMode,
  setBulkDeleteMode,
  confirmBulkDelete,
  mediaDeletedList,
}: {
  isBulkDeleteMode: boolean;
  setBulkDeleteMode: React.Dispatch<React.SetStateAction<boolean>>;
  confirmBulkDelete: (e: React.FormEvent<HTMLFormElement>) => void;
  mediaDeletedList: string[];
}) => {
  const [isBulkDeleteModal, setBulkDeleteModal] = useState(false);
  const imageInUse = useSelector((state: RootState) => state.media.imageInUse);

  return (
    <>
      {imageInUse && imageInUse.length > 0 && <UsedImageModal />}
      <div className="w-full flex">
        {isBulkDeleteModal && (
          <ModalDeleteBulk
            confirmBulkDelete={confirmBulkDelete}
            setBulkDeleteModal={setBulkDeleteModal}
            bulkDeleteModal={isBulkDeleteModal}
          />
        )}
        <div className="btn-group">
          {!isBulkDeleteMode && (
            <UsertipButton
              color="primary"
              size="medium"
              text="Bulk Delete"
              variant="primary"
              icon={<DeleteIcon fontSize="small" />}
              iconLeft={true}
              onClick={() => setBulkDeleteMode(true)}
              customClass="h-[38.5px]"
            />
          )}
          {isBulkDeleteMode && (
            <div className="flex gap-4">
              {mediaDeletedList.length > 0 && (
                <UsertipButton
                  color="secondary"
                  size="medium"
                  text="Confirm Delete"
                  variant="primary"
                  onClick={() => setBulkDeleteModal(true)}
                />
              )}
              <UsertipButton
                color="primary"
                size="medium"
                text="Cancel"
                variant="primary"
                onClick={() => {
                  setBulkDeleteMode(false);
                }}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ComponentBulkDeleteImage;

function ModalDeleteBulk({
  confirmBulkDelete,
  setBulkDeleteModal,
  bulkDeleteModal,
}: {
  confirmBulkDelete: (e: React.FormEvent<HTMLFormElement>) => void;
  setBulkDeleteModal: React.Dispatch<React.SetStateAction<boolean>>;
  bulkDeleteModal: boolean;
}) {
  return (
    <EmptyModal
      isOpen={bulkDeleteModal}
      onClose={() => setBulkDeleteModal(false)}
      size="large"
    >
      <CloseSquare
        className="absolute top-1 right-1 fill-default-neutral-100 w-5 h-5 cursor-pointer z-40"
        onClick={() => setBulkDeleteModal(false)}
      />
      <div className="py-6 relative">
        <div className="modalHeader">Delete Image</div>
        {/* <IconButton
          style={{ position: "absolute", top: "0", right: "0" }}
          onClick={() => setBulkDeleteModal(false)}
        >
          <CloseIcon />
        </IconButton> */}
        <div className="modalSubHeader">
          WARNING: You have selected to <i>“Bulk Delete”</i> image(s), please
          confirm to proceed or select cancel to return to the previous page.
        </div>
        {/* {errorMsgs &&
          errorMsgs.length > 0 &&
          errorMsgs.map((err, i) => <WarningMsg msg={err} key={i} />)} */}
        <form
          noValidate
          autoComplete="off"
          onSubmit={(e) => {
            confirmBulkDelete(e);
            setBulkDeleteModal(false);
          }}
          className="modalForm"
        >
          {/* <div className="modalActionBtnBar">
            <Button
              variant="contained"
              className="modalActionBtn"
              onClick={() => setBulkDeleteModal(false)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              className="modalActionBtn"
              type="submit"
            >
              Yes
            </Button>
          </div> */}
          <div className="flex justify-end gap-4 w-1/2 ml-auto ">
            <UsertipButton
              color="primary"
              size="fluid"
              text="Cancel"
              variant="primary"
              onClick={() => setBulkDeleteModal(false)}
            />
            <UsertipButton
              color="primary"
              size="fluid"
              text="Yes"
              variant="primary"
            />
          </div>
        </form>
      </div>
    </EmptyModal>
  );
}

function UsedImageModal() {
  const [open, setOpen] = useState(false);
  const imageInUse = useSelector((state: RootState) => state.media.imageInUse);

  useEffect(() => {
    if (imageInUse && imageInUse.length > 0) setOpen(true);
  }, [imageInUse]);

  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(resetUsedImage());
    setOpen(false);
  };
  return (
    <Dialog
      open={open}
      onClose={() => handleClose()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Some Images are in use and cannot be deleted."}
      </DialogTitle>
      <DialogContent>
        {imageInUse &&
          imageInUse.length > 0 &&
          imageInUse.map((d) => {
            // return d.walkthroughDetails.walkthrough_name !== undefined ? (
            //   <DialogContentText>
            //     {`Image ${d.name} being used in:${d.walkthroughDetails.walkthrough_name}`}
            //   </DialogContentText>
            // ) :
            return (
              <DialogContentText>
                {`Image ${d.name} is currently in use`}
              </DialogContentText>
            );
          })}
      </DialogContent>
    </Dialog>
  );
}
