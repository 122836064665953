import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ComponentOverviewDetailAnalytic from "../components/Analytic/Overview/ComponentOverviewDetailAnalytic";
import ComponentSelectDataSet from "../components/Analytic/Overview/ComponentSelectDataSet";
import ComponentAlertAppOverallSnackbar from "../components/ComponentAlertAppOverallSnackbar";
import ComponentLoading from "../components/ComponentLoading";
import ComponentPageHeaderLinkArray from "../components/ComponentPageHeaderLinkArray";
import ComponentPageHeaderLinksBackBtn from "../components/ComponentPageHeaderLinksBackBtn";
import { ViewerTeamSummary } from "../interfaces/interfaceTeams";
import { RootState } from "../store";
import {
  analyticsRetrieveWalkthroughSessionAndActivities,
  resetAlert,
} from "../store/reducers/reducerAnalytics";
import { teamGetViewerTeamsSummaryByOrg } from "../store/reducers/reducerTeam";
import { getSingleWalkthroughReq } from "../store/reducers/reducerWalkthrough";

interface WalkthroughVersion {
  published_version: number;
  published_walkthrough_id: string;
}

const PageDetailedAnalytic = () => {
  // state
  const { walkthroughID } = useParams();
  const dispatch = useDispatch();

  /** Get the active walkthrough data */
  const walkthrough = useSelector(
    (state: RootState) => state.walkthrough.activeWalkthrough
  );
  const isLoading = useSelector(
    (state: RootState) => state.analytics.appLoading
  );
  const alert = useSelector((state: RootState) => state.analytics.alert);
  /** Get the organization's id */
  const orgId = useSelector((state: RootState) => state.org.org_id);

  /** Retrive the active walkthrough's list of versions */
  const walkthroughVersions: WalkthroughVersion[] = useSelector(
    //@ts-ignore
    (state: RootState) => state.walkthrough.activeWalkthrough.versions
  );

  /** Retrive the organization's list of viewer teams */
  const viewerTeamSummary = useSelector(
    (state: RootState) => state.team.viewerTeamDataSummary
  ) as null | ViewerTeamSummary[];

  /** Selection: publishedWalkthroughId */
  const [selectionPublishedWalkthroughId, setSelectionPublishedWalkthroughId] =
    useState<string | null>(null);

  /** Get today's date */
  const today = new Date();

  /** Selection: startDate */
  const [selectionStartDate, setSelectionStartDate] = useState<Date>(
    new Date(new Date().setDate(today.getDate() - 7))
  ); //default 180 day pass

  /** Selection: endDate */
  const [selectionEndDate, setSelectionEndDate] = useState<Date>(new Date());

  /** Selection: viewerTeamId */
  const [selectionViewerTeam, setSelectionViewerTeam] = useState<string | null>(
    null
  );

  useEffect(() => {
    // check if walkthrough detail is empty call it again
    if (!walkthrough || Object.keys(walkthrough).length === 0) {
      //TODO: convert walkthrough reducer into ts
      //@ts-ignore
      dispatch(getSingleWalkthroughReq(walkthroughID));
    }
  }, []);

  /** DEFAULT: Get if viewerTeams have not been retrieved, get data from API */
  useEffect(() => {
    if (!viewerTeamSummary && orgId) {
      // @ts-ignore
      dispatch(teamGetViewerTeamsSummaryByOrg(orgId));
    }
  }, [orgId]);

  /** DEFAULT: Once walkthrough is loaded, set default to the latest walkthrough version */
  useEffect(() => {
    if (walkthroughVersions) {
      if (walkthroughVersions.length > 1) {
        let latestVersion = walkthroughVersions.reduce(function (
          prev,
          current
        ) {
          return prev.published_version > current.published_version
            ? prev
            : current;
        });
        setSelectionPublishedWalkthroughId(
          latestVersion.published_walkthrough_id
        );
      } else if (walkthroughVersions.length === 1) {
        setSelectionPublishedWalkthroughId(
          walkthroughVersions[0].published_walkthrough_id
        );
      } else {
        // if user has no walkthrough versions published for this walkthrough, set to empty string
        // TODO: create custom option if no published versions are detected
        setSelectionPublishedWalkthroughId("");
      }
    }
  }, [walkthroughVersions]);

  /** DEFAULT: Once viewer teams have been retrieved, set default to the ALL viewer team */
  useEffect(() => {
    if (viewerTeamSummary) {
      const defaultTeam = viewerTeamSummary.find((team) => team.name === "All");
      setSelectionViewerTeam(defaultTeam!.viewer_team_id);
    }
  }, [viewerTeamSummary]);

  /** Once the user has made a selection,
   * grab the walkthrough sessions and activities
   * based on the user's selection
   */
  useEffect(() => {
    if (
      selectionViewerTeam &&
      selectionStartDate &&
      selectionEndDate &&
      selectionPublishedWalkthroughId
    ) {
      const data = {
        viewerTeamId: selectionViewerTeam,
        startDate: selectionStartDate,
        endDate: selectionEndDate,
        publishedWalkthroughId: selectionPublishedWalkthroughId,
      };
      // @ts-ignore
      dispatch(analyticsRetrieveWalkthroughSessionAndActivities(data));
    }
  }, [
    selectionViewerTeam,
    selectionStartDate,
    selectionEndDate,
    selectionPublishedWalkthroughId,
  ]);

  return (
    <div className="pageBase">
      <ComponentLoading show={isLoading} />
      <ComponentPageHeaderLinksBackBtn
        btnURL={`/guides/walkthroughs`}
        btnLabel={"Your Walkthroughs"}
        btnDisabled={false}
      />
      <ComponentPageHeaderLinkArray
        linkArrayType={"Walkthroughs"}
        walkthroughID={walkthroughID!}
      />
      <div className="pageHeader">
        {/* @ts-ignore */}
        Analytics: {walkthrough?.name}
      </div>
      <ComponentOverviewDetailAnalytic />
      <h3 className="pageHeader1 mt-6 mb-3">Select Overall Dataset</h3>
      {viewerTeamSummary &&
        selectionViewerTeam &&
        selectionPublishedWalkthroughId && (
          <ComponentSelectDataSet
            walkthroughVersions={walkthroughVersions}
            viewerTeamSummary={viewerTeamSummary}
            /**Selection states and setStates */
            selectionStartDate={selectionStartDate}
            setSelectionStartDate={setSelectionStartDate}
            selectionEndDate={selectionEndDate}
            setSelectionEndDate={setSelectionEndDate}
            selectionViewerTeam={selectionViewerTeam}
            // @ts-ignore
            setSelectionViewerTeam={setSelectionViewerTeam}
            selectionPublishedWalkthroughId={selectionPublishedWalkthroughId}
            // @ts-ignore
            setSelectionPublishedWalkthroughId={
              setSelectionPublishedWalkthroughId
            }
          />
        )}
      {alert.type !== "" && alert.type !== undefined && (
        <ComponentAlertAppOverallSnackbar
          alert={alert}
          reset={() => dispatch(resetAlert())}
        />
      )}
    </div>
  );
};

export default PageDetailedAnalytic;
