import React from "react";

import { useDispatch, useSelector } from "react-redux";
import { EmptyModal, Button } from "usertip-component-library";

const ComponentRemoveUsers = ({
  onConfirmRemoveUserFromOrg,
  selectedUserOrgID,

  listType,
  viewingPermisionsType,

  selectedUserList,

  selectedWalkthroughViewingPermissionsList,

  showModalRemove,
  setShowModalRemove,

  removeMode,

  onDeleteOneIndvWalkthroughViewingPermission,
  onHandleBulkEditIndvWalkthroughPermission,

  setResetTableSelectAll,
}) => {
  const dispatch = useDispatch();
  const theIndex = useSelector((state) => state.org.theIndex);
  // console.log(theIndex, "theIndex");
  // Handle Removal of selected user after submission click
  const handleConfirmRemoveUserFromOrgFormSubmission = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (listType === "User") {
      if (removeMode === "single") {
        // Remove user from database on confirmation
        const orgData = {
          orgID: selectedUserOrgID,
          userID: selectedUserList[theIndex].role_id,
        };
        dispatch(onConfirmRemoveUserFromOrg(orgData));
        handleHideModal();
      } else if (removeMode === "bulk") {
        if (selectedUserList) {
          for (let i = 0; i < selectedUserList.length; i++) {
            // Remove each user from "selectedUserEmailList"
            const orgData = {
              orgID: selectedUserOrgID,
              userID: selectedUserList[i].role_id,
            };
            dispatch(onConfirmRemoveUserFromOrg(orgData));
          }

          // Resets the "Select All" control on the table.
          setResetTableSelectAll(true);
          handleHideModal();
        }
      }
    } else if (listType === "WalkthroughViewingPermissions") {
      // Handles Individual Walkthrough Viewing Permissions
      if (viewingPermisionsType === "Individual") {
        if (removeMode === "single") {
          // Remove one individual viewer permission from database on confirmation
          dispatch(
            onDeleteOneIndvWalkthroughViewingPermission(
              selectedWalkthroughViewingPermissionsList[0]
                .walkthrough_permission_id
            )
          );
          handleHideModal();
        } else if (removeMode === "bulk") {
          if (selectedWalkthroughViewingPermissionsList) {
            for (
              let i = 0;
              i < selectedWalkthroughViewingPermissionsList.length;
              i++
            ) {
              // Remove each user from "selectedUserEmailList"
              dispatch(
                onDeleteOneIndvWalkthroughViewingPermission(
                  selectedWalkthroughViewingPermissionsList[i]
                    .walkthrough_permission_id
                )
              );
            }

            // Resets the "Select All" control on the table.
            setResetTableSelectAll(true);
            handleHideModal();
          }
        }
      }
      // Handles Group Walkthrough Viewing Permissions
      else if (viewingPermisionsType === "Group") {
        if (removeMode === "single") {
          // Note: we are using bulk edit walkthrough permission to delete the single row
          // thus, we need to extract this user from the main list, and pass in the remaining users and the 2nd parameter
          // for onHandleBulkEditIndvWalkthroughPermission
          //TODO: do we need to create specific delete for single permission?

          // Remove one group viewer permission from database on confirmation
          onDeleteOneIndvWalkthroughViewingPermission(
            selectedWalkthroughViewingPermissionsList[0]
              .walkthrough_permission_id
          );
          handleHideModal();
        } else if (removeMode === "bulk") {
          if (selectedWalkthroughViewingPermissionsList) {
            // onHandleBulkEditIndvWalkthroughPermission(selectedWalkthroughViewingPermissionsList[0].walkthrough_permission_id, selectedWalkthroughViewingPermissionsList);
            for (
              let i = 0;
              i < selectedWalkthroughViewingPermissionsList.length;
              i++
            ) {
              // Remove each user from "selectedUserEmailList"
              dispatch(
                onDeleteOneIndvWalkthroughViewingPermission(
                  selectedWalkthroughViewingPermissionsList[i]
                    .walkthrough_permission_id
                )
              );
            }

            // Resets the "Select All" control on the table.
            setResetTableSelectAll(true);
            handleHideModal();
          }
        }
      }
    }
  };

  // Modal close handler
  const handleHideModal = () => {
    setShowModalRemove(false);
  };

  // Generate Single Btn
  const GenerateSingleBtn = ({ inputList, showRole }) => {
    // console.log(inputList);
    if (listType === "WalkthroughViewingPermissions") {
      return (
        <>
          <div className="modalSubHeader">
            WARNING: You have selected <i>“Delete”</i> to remove{" "}
            <b>
              {`${inputList[0] ? inputList[0].name : ""} (${
                inputList[0] ? inputList[0].role_id : ""
              })`}
              <span className="capitalize">{` ${
                showRole ? `[${inputList[0] ? inputList[0].role_id : ""}]` : ``
              }`}</span>
            </b>
            , please confirm to proceed or select cancel to return to the
            previous page.
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="modalSubHeader">
            WARNING: You have selected <i>“Delete”</i> to remove{" "}
            <b>
              {`${inputList[theIndex] ? inputList[theIndex].name : ""} (${
                inputList[theIndex].email
              })`}
              <span className="capitalize">{` ${
                showRole
                  ? `[${
                      inputList[theIndex] ? inputList[theIndex].role_id : ""
                    }]`
                  : ``
              }`}</span>
            </b>
            , please confirm to proceed or select cancel to return to the
            previous page.
          </div>
        </>
      );
    }
  };

  // Generate Bulk Btn
  const GenerateBulkBtn = ({ inputList, showRole }) => {
    if (listType === "WalkthroughViewingPermissions") {
      return (
        <>
          <div className="modalSubHeader">
            WARNING: You have selected to <i>“Bulk Delete”</i> the following
            user(s), please confirm to proceed or select cancel to return to the
            previous page.
          </div>

          <ol className="list-decimal list-inside mb-8">
            {inputList &&
              inputList.map((user) => (
                <li key={user.role_id}>
                  {
                    // If No Name was found for User
                    user.name === undefined ? (
                      <span>{``}</span>
                    ) : (
                      <span>{`${user.name ? user.name : ""} |`}</span>
                    )
                  }
                  {
                    // Display Role when needed
                    <span>{` ${user.email ? user.email + "|" : ""}`}</span>
                  }
                  {
                    // Display Role when needed
                    <span>{` ${user.role_id}|`}</span>
                  }
                  {
                    // Display Role when needed
                    <span className="capitalize">{` [${user.role}]|`}</span>
                  }
                </li>
              ))}
          </ol>
        </>
      );
    } else {
      return (
        <>
          <div className="modalSubHeader">
            WARNING: You have selected to <i>“Bulk Delete”</i> the following
            user(s), please confirm to proceed or select cancel to return to the
            previous page.
          </div>

          <ol className="list-decimal list-inside mb-8">
            {inputList &&
              inputList.map((user) => (
                <li key={user.email}>
                  {
                    // If No Name was found for User
                    user.name === undefined ? (
                      <span>{`${user.email}`}</span>
                    ) : (
                      <span>{`${user.name ? user.name : ""} (${
                        user.email
                      })`}</span>
                    )
                  }
                  {
                    // Display Role when needed
                    showRole && (
                      <span className="capitalize">{` [${user.role}]`}</span>
                    )
                  }
                </li>
              ))}
          </ol>
        </>
      );
    }
  };

  return (
    <EmptyModal
      isOpen={showModalRemove}
      onClose={handleHideModal}
      size="large"
      customClass="ut-py-5"
    >
      {
        // Process for Remove Users
        selectedUserList !== null && selectedUserList !== undefined && (
          <>
            <div className="modalHeader">
              {removeMode === "single"
                ? `Confirm Removal of User?`
                : removeMode === "bulk"
                ? `Confirm Removal of Bulk User(s)?`
                : ""}
            </div>

            {removeMode === "single" ? (
              <GenerateSingleBtn
                inputList={selectedUserList}
                showRole={false}
              />
            ) : removeMode === "bulk" ? (
              <GenerateBulkBtn inputList={selectedUserList} showRole={false} />
            ) : (
              ""
            )}
          </>
        )
      }

      {
        // Process for Remove Walkthrough Viewers
        selectedWalkthroughViewingPermissionsList !== null &&
          selectedWalkthroughViewingPermissionsList !== undefined && (
            <>
              <div className="modalHeader">
                {removeMode === "single"
                  ? `Confirm Removal of Viewing Permission?`
                  : removeMode === "bulk"
                  ? `Confirm Removal of Bulk Walkthrough Viewer(s)?`
                  : ""}
              </div>

              {removeMode === "single" ? (
                <GenerateSingleBtn
                  inputList={selectedWalkthroughViewingPermissionsList}
                  showRole={true}
                />
              ) : removeMode === "bulk" ? (
                <GenerateBulkBtn
                  inputList={selectedWalkthroughViewingPermissionsList}
                  showRole={true}
                />
              ) : (
                ""
              )}
            </>
          )
      }

      <form
        noValidate
        autoComplete="off"
        onSubmit={handleConfirmRemoveUserFromOrgFormSubmission}
        className="modalForm"
      >
        <div className="flex gap-2 w-1/3 ml-auto">
          <Button
            variant="primary"
            color="primary"
            size="fluid"
            text="Cancel"
            onClick={handleHideModal}
          >
            Cancel
          </Button>
          <Button variant="primary" color="primary" size="fluid" text="Yes">
            Yes
          </Button>
        </div>
      </form>
    </EmptyModal>
  );
};

export default ComponentRemoveUsers;
