import { InputLabel, TextField } from "@mui/material";
import { Button, InputField } from "usertip-component-library";
import React, { useState } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import GroupRuleForm from "../components/AddUserSegmentationRule/GroupRuleForm";
import { RuleGroup } from "../interfaces/interfaceSegmentation";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import { createUserSegmentation } from "../store/reducers/reducerUserSegmentation";
import ComponentLoading from "../components/ComponentLoading";
import { useNavigate } from "react-router-dom";

const PageAddSegmentation = () => {
  const navigate = useNavigate();
  /**Segment name */
  const [segmentName, setSegmentName] = useState("");
  const { org, api_key } = useSelector(
    (state: RootState) => state.user.userData
  );
  const loading = useSelector(
    (state: RootState) => state.userSegmentation.appLoading
  );
  /**List rule group */
  const [listRuleGroup, setListRuleGroup] = useState<{ groupIndex: number }[]>([
    { groupIndex: 0 },
  ]);

  /**List rule group seperated by groupIndex(AND)*/
  const [listRule, setListRule] = useState<RuleGroup[]>([]);

  const dispatch = useDispatch();

  /**Adding new rule group form(OR) everytime user click add */
  const onAddRuleGroupClick = (event: React.MouseEvent) => {
    const copyArray = listRuleGroup;

    setListRuleGroup([...listRuleGroup, { groupIndex: copyArray.length + 1 }]);
  };

  /**Removing rule group (OR) */
  const onRemoveGroupClick = (currGroupIndex: number) => {
    const removeRuleList = listRule.filter(
      (d) => d.groupIndex !== currGroupIndex
    );
    const removedRuleGroup = listRuleGroup.filter(
      (d) => d.groupIndex !== currGroupIndex
    );

    setListRule(removeRuleList);
    setListRuleGroup(removedRuleGroup);
  };

  const checkDisableButton = () => {
    for (const i of listRule) {
      return i.metaName.length === 0 || segmentName.length === 0 ? true : false;
    }
  };

  /**Change list rule so it fit the API params */
  const groupArray = () => {
    let group = [] as [RuleGroup[]] | [];

    //javascript array has a method foreach that enumerates keyvalue pairs.
    listRule.forEach((r) => {
      //if an array index by the value of id is not found, instantiate it.
      if (!group[r.groupIndex!]) {
        //result gets a new index of the value at id.
        group[r.groupIndex!] = [];
      }
      //push that whole object from api_array into that list
      group[r.groupIndex!].push(r);
    });

    /** Filter out rule groups that are undefined/empty */
    const _filteredGroup = group.filter((element) => {
      return element !== undefined;
    });

    /**Rename the key so it fit api paramaters */
    const result = _filteredGroup.map((data) => {
      return data.map((meta) => {
        if (meta.metaType === "boolean") {
          return {
            metadata: meta.metaName,
            type: meta.metaType,
            rule: meta.metaRule,
          };
        }
        return {
          metadata: meta.metaName,
          type: meta.metaType,
          rule: meta.metaRule,
          value: meta.metaValue,
        };
      });
    });

    return result;
  };

  /**Handler submit form */
  const handleAddNewGroupFormSubmission = async (
    e: React.FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault();
    const groupListRule = groupArray();

    const data = {
      segmentationName: segmentName,
      orgId: org,
      apiKey: api_key,
      segmentationRules: groupListRule,
    };

    await dispatch(createUserSegmentation(data));
    navigate("/segmentation");
  };

  return (
    <>
      <ComponentLoading show={loading} />
      <div className="pageBase">
        <div className="pageHeader">Create New Segmentation</div>
        <div className="pageSubHeader"></div>
      </div>
      <section id="add_segmentation form">
        <form onSubmit={handleAddNewGroupFormSubmission}>
          {/* Segment name form */}
          <div className="px-2 mb-6">
            <InputField
              variant="full"
              label="Segmentation Name"
              error={segmentName.length === 0}
              errorText="Segmentation name must not be empty"
              placeholder="Segmentation Name"
              helperText=""
              onChange={(e) => setSegmentName(e.target.value)}
              value={segmentName}
            />
          </div>
          {/* Group rule */}
          <GroupRuleForm
            listRule={listRule}
            groupIndex={1}
            setListRule={setListRule}
            onRemoveGroupClick={onRemoveGroupClick}
          />
          {listRuleGroup.length > 1 &&
            listRuleGroup
              .filter((d) => d.groupIndex !== 0)
              .map((d) => (
                <div key={d.groupIndex}>
                  {/* horizonatal line with text Inside */}
                  <div className="flex items-center my-8">
                    <div className="flex-grow bg bg-gray-300 h-0.5"></div>
                    <div className="flex-grow-0 mx-5 text-black">OR</div>
                    <div className="flex-grow bg bg-gray-300 h-0.5"></div>
                  </div>
                  <GroupRuleForm
                    listRule={listRule}
                    groupIndex={d.groupIndex}
                    setListRule={setListRule}
                    onRemoveGroupClick={onRemoveGroupClick}
                  />
                </div>
              ))}
          <div className="mt-6">
            <Button
              color="primary"
              size="medium"
              text="ADD RULE GROUP"
              variant="outlined"
              icon={<AddCircleIcon fontSize="small" />}
              iconRight
              onClick={(e) => {
                e.preventDefault();
                onAddRuleGroupClick(e);
              }}
              customClass="ut-header-link-button"
            />
          </div>

          <div className="modalActionBtnBar mt-20">
            <Button
              variant="primary"
              color="primary"
              size="medium"
              text="Cancel"
              onClick={() => {
                navigate("/segmentation");
              }}
            />
            <Button
              variant="primary"
              color="primary"
              size="medium"
              text="Confirm"
              disabled={checkDisableButton()!}
            />
          </div>
        </form>
      </section>
    </>
  );
};

export default PageAddSegmentation;
