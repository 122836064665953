import { Alert } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import sizeToBytes from "../../utility/functions/sizeToBytes";

type PlanList =
  | "media"
  | "monthlyUserActivity"
  | "publishedWalkthrough"
  | "sharedWalkthrough"
  | "walkthrough"
  | "autoLaunch";

const ComponentPlanLimitWarning = ({
  limitName,
  setLimitReach,
}: {
  /** Limit of the feature we want to check */
  limitName: PlanList;

  setLimitReach: React.Dispatch<React.SetStateAction<boolean>> | undefined;
}) => {
  /** limit of the usage */
  const orgPlanLimit = useSelector(
    (state: RootState) => state.org.org_plan?.plan_limit_details
  );
  /** current usage */
  const orgPlanUsage = useSelector(
    (state: RootState) => state.org.org_plan_usage
  );

  /**List of plan that already pass the limit */
  const [limitReachList, setLimitReachList] = useState<PlanList[]>([]);

  useEffect(() => {
    /**get plan limit and plan usage */
    if (orgPlanLimit && orgPlanUsage) {
      const limitPlanUsage = {
        media: orgPlanLimit?.media_data_storage,
        monthlyUserActivity: orgPlanLimit?.monthly_active_user,
        publishedWalkthrough: orgPlanLimit?.published_walkthroughs,
        sharedWalkthrough: orgPlanLimit?.shared_walkthroughs,
        walkthrough: orgPlanLimit?.walkthroughs,
        autoLaunch: orgPlanLimit?.auto_launch,
      };

      /**List of plan that already pass the limit */
      let limitList = [] as PlanList[];
      /**check if the plan usage is already reach the limit */
      Object.entries(orgPlanUsage).forEach(([key, value]) => {
        /** -1 mean unlimited usage */
        if (limitPlanUsage[key as PlanList] === -1) return;
        if (
          limitPlanUsage[key as PlanList] <= orgPlanUsage[key as PlanList] &&
          key !== "media"
        ) {
          /**add to the list of plan that already reach the limit */
          limitList.push(key as PlanList);
        }
        /**check media usage */
        /**media usage need to be converted into bytes first */
        if (key === "media") {
          const getMediaLimit = sizeToBytes(limitPlanUsage["media"]);
          const getMediaUsage = sizeToBytes(orgPlanUsage["media"]);

          if (getMediaUsage >= getMediaLimit) {
            /**add to the list of plan that already reach the limit */
            limitList.push(key as "media");
          }
        }
      });

      if (limitList.length > 0) {
        setLimitReachList(limitList);
        if (setLimitReach !== undefined) {
          //**For disabling button when plan limit reached */
          // setLimitReach(true);
          limitReachList.map((d) => {
            if (limitName.includes(d)) {
              setLimitReach(true);
            }
          });
        }
      }
    }
  }, [orgPlanLimit, orgPlanUsage]);

  const convertToReadable = (plan: PlanList) => {
    switch (plan) {
      case "media":
        return "Medias";
      case "monthlyUserActivity":
        return "Monthly User Activity";
      case "publishedWalkthrough":
        return "Published Walkthroughs";
      case "sharedWalkthrough":
        return "Shared Walkthroughs";
      case "walkthrough":
        return "Created Walkthroughs";
    }
  };

  return (
    <>
      {limitName &&
        limitReachList.length > 0 &&
        limitReachList.map(
          (d) =>
            limitName.includes(d) && (
              <div className="mb-5 w-fit">
                <Alert variant="outlined" severity="warning">
                  The number of <strong>{convertToReadable(d)} </strong>has
                  already reached the maximum limit.
                </Alert>
              </div>
            )
        )}
    </>
  );
};

export default ComponentPlanLimitWarning;
