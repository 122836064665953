import React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { CheckCircleOutline } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { abortChangePwd } from "../../../store/reducers/reducerAuth";
import { Button } from "usertip-component-library";
import { useNavigate } from "react-router-dom";

const PasswordChangeSuccsess = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onClickHandler = () => {
    dispatch(abortChangePwd());
    navigate("/");
  };

  return (
    <div className="m-auto w-full flex flex-col items-center justify-center gap-4">
      <div className="flex flex-col justify-center items-center">
        {/* <CheckCircleIcon color="primary" style={{ fontSize: 50 }} /> */}
        <h2 className="text-heading-m text-center text-default-neutral-100">
          Password Successfully Changed
        </h2>
      </div>
      <div className="w-28">
        <Button
          color="primary"
          size="fluid"
          text="Confirm"
          variant="primary"
          onClick={onClickHandler}
        />
      </div>
    </div>
  );
};

export default PasswordChangeSuccsess;
