import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

// import { Button } from "@mui/material";

// import AddBoxIcon from "@mui/icons-material/AddBox";

// import ComponentListWalkthroughsInTable from "./ComponentListWalkthroughsInTable";
import ComponentAddWalkthroughs from "./ComponentAddWalkthroughs";
// import ComponentFilterTable from "./ComponentFilterTable";
import NewPlanDetails from "./OrgPlan/NewPlanDetails";
import { Tags } from "usertip-component-library";

const ComponentListWalkthroughs = ({
  displayShowAddBtn,
  introMsg,
  dataSet,

  indicatorMsg,
}) => {
  const [filteredDataSet, setFilteredDataSet] = useState([]);
  //state from redux
  const orgID = useSelector((state) => state.user.userData.org);
  const walkthroughs = useSelector((state) => state.walkthrough.walkthroughs);

  const [showModalAdd, setShowModalAdd] = useState(false);

  const handleAddTeamClick = () => {
    // Open any Modal, as at any one time, only ONE MODAL will be pre-loaded and opened
    setShowModalAdd(true);
  };

  useEffect(() => {
    if (dataSet !== undefined) {
      setFilteredDataSet(dataSet);
    }
  }, [dataSet]);

  const walkthroughFilter = [
    {
      field: "publish", //status
      type: "Boolean",
      label: "Status",
    },
    {
      field: "name", //walkthrough
      type: "String",
      label: "Walkthrough",
    },

    {
      field: "launched",
      type: "Integer",
      label: "Launched",
    },
    {
      field: "completed",
      type: "Integer",
      label: "Completed",
    },
    {
      field: "dismissed",
      type: "Integer",
      label: "Dismissed",
    },
    {
      field: "createdAt",
      type: "Date",
      label: "Date Created",
    },
  ];

  return (
    <div className="">
      <div className="flex justify-between items-center">
        <div>
          <div className="flex gap-4 items-center">
            <div className="pageHeader">List of Walkthroughs</div>
            <div className="cursor-default">
              <Tags
                size="medium"
                variant="filled"
                text={`${walkthroughs.length} Walkthroughs`}
              />
            </div>
          </div>
          <div className="text-gray-400 text-base">{introMsg}</div>
        </div>

        <NewPlanDetails
          planList={["publishedWalkthrough", "monthlyUserActivity"]}
        />
        {/* <ComponentPlanLimitWarning
          planList={["publishedWalkthrough", "monthlyUserActivity"]}
        /> */}
      </div>

      {/* <div className="pageAddBtnBar">
        {
          // If displayShowAddBtn is true, show the button for adding new user role
          displayShowAddBtn && (
            <Button
              className="pageAddBtn"
              variant={"outlined"}
              aria-label={`Add Walkthrough`}
              startIcon={<AddBoxIcon />}
              onClick={() => handleAddTeamClick()}
              disableElevation
            >
              Add Walkthrough
            </Button>
          )
        }
      </div> */}

      {/* this is original filter */}
      {/* {dataSet && (
        <div className="mt-3">
          <ComponentFilterTable
            filters={walkthroughFilter}
            originalData={dataSet}
            setFilteredData={setFilteredDataSet}
          />
        </div>
      )} */}

      {/* this is original table */}
      {/* {
        // If dataSet is not empty, Display table with data, else Display IndicatorMsg
        filteredDataSet && filteredDataSet.length > 0 ? (
          <ComponentListWalkthroughsInTable
            dataSet={filteredDataSet} // Walkthroughs List
          />
        ) : (
          <div className="pageIndicatorMsg">{indicatorMsg}</div>
        )
      } */}

      <ComponentAddWalkthroughs
        orgID={orgID}
        dataSet={dataSet}
        showModalAdd={showModalAdd}
        setShowModalAdd={setShowModalAdd}
      />
    </div>
  );
};

export default ComponentListWalkthroughs;
