import { axios, axios_analytics } from "./axios";


/*
RETIRED API 1.0


// Get a user’s account information [GET]
export const callGetUserAccountInformation = async (userID) => {
  const token = localStorage.getItem("ut-token");
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `${token}`,
    },
  };
  return axios
    .get(`/user/${userID}`, config)
    .then((res) => res)
    .catch((err) => err);
};

// Update a user’s name [POST]
export const callUpdateUserName = async (userID, name) => {
  const data = {
    name,
  };

  const token = localStorage.getItem("ut-token");
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `${token}`,
    },
  };

  return axios
    .post(`/user/update/name/${userID}`, data, config)
    .then((res) => res)
    .catch((err) => err);
};

// Update a user’s phone no [POST]
export const callUpdateUserPhoneNumber = async (
  userID,
  { countryCode, number }
) => {
  const data = {
    phoneno: {
      intl: countryCode,
      value: number,
    },
  };

  const token = localStorage.getItem("ut-token");
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `${token}`,
    },
  };

  return axios
    .post(`/user/update/phoneno/${userID}`, data, config)
    .then((res) => res)
    .catch((err) => err);
};



// Get a organization information [GET]
export const callGetOrganizationInformation = async (orgID) => {
  const token = localStorage.getItem("ut-token");
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `${token}`,
    },
  };
  return axios
    .get(`/org/${orgID}`, config)
    .then((res) => res)
    .catch((err) => err);
};


// add an admin to an organization
export const callAddAdminToOrganization = async (orgID, userID) => {
  const data = {
    id: userID,
  };

  const token = localStorage.getItem("ut-token");
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `${token}`,
    },
  };

  return axios
    .post(`/org/addAdmin/${orgID}`, data, config)
    .then((res) => res)
    .catch((err) => err);
};


// add a builder to an organization
export const callAddBuilderToOrganization = async (orgID, userID) => {
  const data = {
    id: userID,
  };

  const token = localStorage.getItem("ut-token");
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `${token}`,
    },
  };

  return axios
    .post(`/org/addBuilder/${orgID}`, data, config)
    .then((res) => res)
    .catch((err) => err);
};


// add a viewer to an organization
export const callAddViewerToOrganization = async (orgID, userID) => {
  const data = {
    id: userID,
  };

  const token = localStorage.getItem("ut-token");
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `${token}`,
    },
  };

  return axios
    .post(`/org/addViewer/${orgID}`, data, config)
    .then((res) => res)
    .catch((err) => err);
};


// Remove a user from an organization
export const callRemoveUserFromOrganization = async (orgID, userID) => {
  const data = {
    id: userID,
  };

  const token = localStorage.getItem("ut-token");
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `${token}`,
    },
  };

  return axios
    .post(`/org/removeUser/${orgID}`, data, config)
    .then((res) => res)
    .catch((err) => err);
};




// Get tags of a single walkthrough [GET]
export const getTagsOfWalkthrough = async (walkthroughID) => {
  const token = localStorage.getItem("ut-token");
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `${token}`,
    },
  };
  return axios
    .get(`/walkthrough/tags/${walkthroughID}`, config)
    .then((res) => res)
    .catch((err) => err);
};







// Update a walkthrough’s general settings information [POST]
export const updateNonGeneralSettingsOfWalkthrough = async (
  walkthroughID,
  settings
) => {
  const token = localStorage.getItem("ut-token");
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `${token}`,
    },
  };
  return axios
    .post(`/walkthrough/settings/other/${walkthroughID}`, settings, config)
    .then((res) => res)
    .catch((err) => err);
};


// Get walkthroughs by user [GET]
export const getWalkthroughsByUser = async (user) => {
  const token = localStorage.getItem("ut-token");
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `${token}`,
    },
  };
  return axios
    .get(`/walkthrough/getWalkthroughs/${user}`, config)
    .then((res) => res)
    .catch((err) => err);
};


// Get summary of walkthroughs by user [GET]
export const getSummeryOfWalkthroughByUser = async (user) => {
  const token = localStorage.getItem("ut-token");
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `${token}`,
    },
  };
  return axios
    .get(`/walkthrough/getSummary/${user}`, config)
    .then((res) => res)
    .catch((err) => err);
};


// Publish walkthrough by id [POST]
export const callPublishWalkthrough = async (walkthroughID) => {
  const token = localStorage.getItem("ut-token");
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `${token}`,
    },
  };
  return axios
    .post(`/walkthrough/publish/${walkthroughID}`, config)
    .then((res) => res)
    .catch((err) => err);
};


// Unpublish walkthrough by id [POST]
export const callUnpublishWalkthrough = async (walkthroughID) => {
  const token = localStorage.getItem("ut-token");
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `${token}`,
    },
  };
  return axios
    .post(`/walkthrough/unpublish/${walkthroughID}`, config)
    .then((res) => res)
    .catch((err) => err);
};



//NEED RELOOK. HAVE ISSUES WITH FUNCTION'S VARIABLE REQUEST. API2.0 REQUEST FORMAT IS DIFFERENT FROM OLD API1.0 FORMAT BELOW
// Get a user's role from an organization
export const callGetUserRoleFromOrganization = async (userID) => {
  const token = localStorage.getItem('ut-token')
  const config = {
      headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'Authorization': `${token}`
      }
  }

  return axios.get(`/org/getRole/${userID}`, config)
      .then(res => res)
      .catch(err => err);
};






*/



//THIS IS API 1.0 API CALLS




// Get all information of one walkthrough [GET]
export const getAllInformationOfWalkthrough = async (walkthroughID) => {
  const token = localStorage.getItem("ut-token");
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `${token}`,
    },
  };
  return axios
    .get(`/walkthrough/${walkthroughID}`, config)
    .then((res) => res)
    .catch((err) => err);
};




// Create walkthroughs by user [POST]
export const createWalkthroughByUser = async (
  owner,
  id,
  name,
  host_location
) => {
  const token = localStorage.getItem("ut-token");
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `${token}`,
    },
  };

  const data = {
    owner: owner,
    walkthrough_id: id,
    name: name,
    components: [],
    page_url: host_location,
    views: 1,
    publish: false,
    complete: false,
  };

  return axios
    .post(`/walkthrough/add/`, data, config)
    .then((res) => res)
    .catch((err) => err);
};




// Update a user’s organization information [POST]
export const callUpdateUserOrganizationInformation = async (
  userID,
  { name, subGroup, role }
) => {
  const data = {
    org: {
      name,
      sub_group: subGroup,
      role,
    },
  };

  const token = localStorage.getItem("ut-token");
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `${token}`,
    },
  };

  return axios
    .post(`/user/update/org/${userID}`, data, config)
    .then((res) => res)
    .catch((err) => err);
};




//NEED RELOOK. HAVE ISSUES WITH FUNCTION'S VARIABLE REQUEST. API2.0 REQUEST FORMAT IS DIFFERENT FROM OLD API1.0 FORMAT BELOW
// Update a walkthrough’s general settings information [POST]
export const updateGeneralSettingsOfWalkthrough = async (
  walkthroughID,
  settings
) => {
  const token = localStorage.getItem("ut-token");
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `${token}`,
    },
  };
  return axios
    .post(`/walkthrough/settings/general/${walkthroughID}`, settings, config)
    .then((res) => res)
    .catch((err) => err);
};







// get a single walkthrough analytics summary
export const callGetSingleWalkthroughAnalyticsSummary = async (
  walkthroughID
) => {
  const token = localStorage.getItem("ut-token");
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `${token}`,
    },
  };

  return axios_analytics
    .get(`/analytics/${walkthroughID}`, config)
    .then((res) => res)
    .catch((err) => err);
};

// get analytics summary for all walkthroughs (for a user or within an organization)
export const callGetFullWalkthroughAnalyticsSummary = async (userID) => {
  const token = localStorage.getItem("ut-token");
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `${token}`,
    },
  };

  return axios_analytics
    .get(`/analytics/user/${userID}`, config)
    .then((res) => res)
    .catch((err) => err);
};

// get a summarized list of walkthrough sessions, listing unique users and breakdown of session by step
export const callGetWalkthroughDetailedSessions = async (
  userID,
  walkthroughID
) => {
  const data = {
    walkthrough_id: walkthroughID,
  };
  const token = localStorage.getItem("ut-token");
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `${token}`,
    },
  };

  return axios_analytics
    .post(`analytics/${userID}/walkthroughSessions`, data, config)
    .then((res) => res)
    .catch((err) => err);
};