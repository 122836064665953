import { StepProcDataSet } from "../../interfaces/interfaceAnalytic";
import * as d3 from "d3";

export const pieStepChart = (procDataSet: StepProcDataSet) => {
  const width = 300,
    height = 300,
    margin = 40;

  // The radius of the pieplot is half the width or half the height (smallest one). I subtract a bit of margin.
  const radius = Math.min(width, height) / 2 - margin;

  //svg
  const anchor = d3.create("div").attr("id", "stacked-chart-anchor");

  // append the svg object to the div

  const svg = d3
    .select(anchor.node())
    .append("svg")
    .attr("width", width)
    .attr("height", height)
    .style("display", "inline")
    .append("g")
    .attr("transform", `translate(${width / 2}, ${height / 2})`);

  //process the data
  const data = {
    "#9179F4": procDataSet.completed,
    "#CCCCD5": procDataSet.incomplete,
    "#FB8C00": procDataSet.dismissed,
  };
  // Compute the position of each group on the pie:
  const pie = d3.pie().value(function (d) {
    //@ts-ignore
    return d[1];
  });

  let arc = d3.arc();

  //@ts-ignore
  const data_ready = pie(Object.entries(data));
  // Now I know that group A goes from 0 degrees to x degrees and so on.

  let sizes = {
    innerRadius: 0,
    outerRadius: radius,
  };
  // shape helper to build arcs:
  const arcGenerator = d3
    .arc()
    .innerRadius(sizes.innerRadius)
    .outerRadius(sizes.outerRadius);

  let durations = {
    entryAnimation: 1000,
  };

  // Build the pie chart: Basically, each part of the pie is a path that we build using the arc function.
  const arcs = svg
    .selectAll("mySlices")
    .data(data_ready)
    //@ts-ignore
    .join("path")
    //@ts-ignore
    .attr("d", arcGenerator)
    //@ts-ignore
    .attr("fill", function (d) {
      //@ts-ignore
      return d.data[0];
    })
    .attr("stroke", "black")
    .style("stroke-width", "2px")
    .style("opacity", 1);

  let angleInterpolation = d3.interpolate(
    //@ts-ignore
    pie.startAngle()(),
    //@ts-ignore
    pie.endAngle()()
  );

  let innerRadiusInterpolation = d3.interpolate(0, sizes.innerRadius);
  let outerRadiusInterpolation = d3.interpolate(0, sizes.outerRadius);

  arcs
    .transition()
    .duration(durations.entryAnimation)
    //@ts-ignore
    .attrTween("d", (d: any) => {
      let originalEnd = d.endAngle;
      return (t: any) => {
        let currentAngle = angleInterpolation(t);
        if (currentAngle < d.startAngle) {
          return "";
        }

        d.endAngle = Math.min(currentAngle, originalEnd);

        return arc(d);
      };
    });

  d3.select(svg.node())
    .transition()
    .duration(durations.entryAnimation)
    .tween("arcRadii", () => {
      return (t: any) =>
        arc
          .innerRadius(innerRadiusInterpolation(t))
          .outerRadius(outerRadiusInterpolation(t));
    })
    .on("end", () => {
      // Now add the annotation. Use the centroid method to get the best coordinates
      svg
        .selectAll("mySlices")
        .data(data_ready)
        .join("text")
        .text(function (d) {
          //@ts-ignore
          if (d.data[1] === 0) {
            return "";
          } else {
            //@ts-ignore
            return d.data[1];
          }
        })
        .attr("transform", function (d) {
          //@ts-ignore
          return `translate(${arcGenerator.centroid(d)})`;
        })
        .style("text-anchor", "middle")
        .style("font-size", 17);
    });

  return anchor.node()!;
};
