import React, { useState } from "react";

import { 
  Table, TableContainer, Box, Paper, 
} from "@mui/material";

import ComponentViewTableHeader from "./ComponentViewTableHeader";
import ComponentViewTableBody from "./ComponentViewTableBody";

import ComponentListTablePagination from "./ComponentListTablePagination";


const ComponentViewTable = ({
    listType,
    displayUserRoleFull,

    headerCells,
    dataSet,
}) => {

    // sort by name will sort by capital letter first -> then alphabetical
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("");

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);


    if (displayUserRoleFull === "Builder") {
        dataSet = dataSet.builders;
    }
    else if (displayUserRoleFull === "Viewer") {
        dataSet = dataSet.viewers;
    }


    return (
        <>
            {
                dataSet.length > 0 ? (
                    <div style={{ width: "100%", marginTop: "15px", marginBottom: "10px" }}>
                        <Box sx={{ width: "100%" }}>
                            <Paper sx={{ width: "100%", mb: 5 }}>
                                
                                {/* Set up Container where Table of Data is being displayed */}
                                <TableContainer>
                                    <Table
                                        sx={{ minWidth: 550 }}
                                        size={"small"}
                                    >
                                        {/* Setting Header Row */}
                                        <ComponentViewTableHeader
                                            headerCells={headerCells}

                                            order={order} 
                                            setOrder={setOrder}
                                            orderBy={orderBy} 
                                            setOrderBy={setOrderBy}
                                        />


                                        <ComponentViewTableBody
                                            listType={listType}
                                            dataSet={dataSet}
                                                
                                            order={order} 
                                            orderBy={orderBy} 
                                            
                                            page={page}
                                            rowsPerPage={rowsPerPage}
                                        />
                                    </Table>
                                </TableContainer> 


                                {/* Bottom of Table Pagination */}
                                <ComponentListTablePagination
                                    dataSet={dataSet}

                                    page={page}
                                    setPage={setPage}

                                    rowsPerPage={rowsPerPage}
                                    setRowsPerPage={setRowsPerPage}
                                />
                            </Paper>
                        </Box>
                    </div>
                ) : (
                    <div className="">
                        <div className="text-center py-16 my-8 border-gray-300 border-t border-b">
                            No {displayUserRoleFull} found. Please add one!
                        </div>
                    </div>
                )
            }
        </>
    )
};

export default ComponentViewTable;