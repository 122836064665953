import React from "react";
import Background from "../../assets/images/curly-background-1.svg";

const BackgroundSwirlWrapper = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <div
      className="w-full backgroundBackdrop p-6"
      style={{ backgroundImage: `url(${Background})` }}
    >
      {children}
    </div>
  );
};

export default BackgroundSwirlWrapper;
