// Sort walkthroughs by name AZ
export const sortByNameAZ = (array) => {
    const updatedArray = [...array];
    updatedArray.sort((a, b) => {
        let nameA = a.name.toLowerCase().replace(/[^a-z]+/g, '');
        let nameB = b.name.toLowerCase().replace(/[^a-z]+/g, '');
        if(nameA < nameB) { return -1; }
        if(nameA > nameB) { return 1; }
        return 0;
    });

    return [...updatedArray];
};

// Sort walkthroughs by last updated
export const sortByLastUpdated = (array) => {
    const updatedArray = [...array];
    updatedArray.sort((a, b) => {
        let dateA = new Date(a.updatedAt);
        let dateB = new Date(b.updatedAt);
        return dateB - dateA;
    });

    return [...updatedArray];
};

// Sort walkthroughs by date of creation
export const sortByDateCreated = (array) => {
    const updatedArray = [...array];
    updatedArray.sort((a, b) => {
        let dateA = new Date(a.createdAt);
        let dateB = new Date(b.createdAt);
        return dateB - dateA;
    });

    return [...updatedArray];
};
