import React, { useState } from "react";

import { Button } from "usertip-component-library";

import AddBoxIcon from "@mui/icons-material/AddBox";

import ComponentAddWalkthroughsPermissions from "./ComponentAddWalkthroughsPermissions";
import ComponentListWalkthroughViewingPermissionsInTable from "./ComponentListWalkthroughViewingPermissionsInTable";
import TableWalkthroughsViewingPermission from "./Table/TableWalkthroughsViewingPermission";

const ComponentListWalkthroughViewingPermissions = ({
  viewingPermisionsType,

  introMsg,

  dataSet,
  permissionDataSet,

  indicatorMsg,

  orgID,
  walkthroughID,

  onDeleteOneIndvPermissions,
  onBulkUpdateIndvPermissions,

  onGroupBulkUpdateIndvPermissions,
}) => {
  const [showModalAdd, setShowModalAdd] = useState(false);

  const stateName = `Viewing Permissions`;

  const handleAddViewingPermissionsClick = () => {
    setShowModalAdd(true);
  };

  return (
    <div className="pageSettings">
      <div className="pageSettingsHeader">
        Manage {viewingPermisionsType} {stateName}
      </div>

      <div className="pageSettingsSubHeader">{introMsg}</div>

      {
        // If permissionDataSet is not empty, Display table with data, else Display IndicatorMsg
        permissionDataSet && permissionDataSet.length > 0 ? (
          <>
            {/* <ComponentListWalkthroughViewingPermissionsInTable
              viewingPermisionsType={viewingPermisionsType}
              dataSet={dataSet} // Walkthrough Users List
              permissionDataSet={permissionDataSet} // Walkthough Users Permissions List
              orgID={orgID}
              walkthroughID={walkthroughID}
              // onDeleteOneIndvWalkthroughViewingPermission={
              //     viewingPermisionsType === "Individual" ? onDeleteOneIndvPermissions :
              //     viewingPermisionsType === "Group" ? onGroupBulkUpdateIndvPermissions : ""
              // }

              onDeleteOneIndvWalkthroughViewingPermission={
                onDeleteOneIndvPermissions
              }
              onHandleBulkEditIndvWalkthroughPermission={
                viewingPermisionsType === "Individual"
                  ? onBulkUpdateIndvPermissions
                  : viewingPermisionsType === "Group"
                  ? onGroupBulkUpdateIndvPermissions
                  : ""
              }
            /> */}
            <TableWalkthroughsViewingPermission
              theDataSet={permissionDataSet}
              editData={dataSet}
              editPermissionData={permissionDataSet}
              walkthroughID={walkthroughID}
              onHandleBulkEditIndvWalkthroughPermission={
                viewingPermisionsType === "Individual"
                  ? onBulkUpdateIndvPermissions
                  : viewingPermisionsType === "Group"
                  ? onGroupBulkUpdateIndvPermissions
                  : ""
              }
            />
          </>
        ) : (
          <>
            <div className="pageIndicatorMsg">{indicatorMsg}</div>

            <div className="pageAddBtnBar flex justify-center -mt-6">
              <Button
                variant={"outlined"}
                color="primary"
                size="medium"
                text={`Add New ${stateName}`}
                icon={<AddBoxIcon fontSize="small" />}
                iconLeft={true}
                onClick={() => handleAddViewingPermissionsClick()}
              />
            </div>
          </>
        )
      }

      <div className="pageDivider" />

      <ComponentAddWalkthroughsPermissions
        dataSet={dataSet}
        walkthroughID={walkthroughID}
        showModalAdd={showModalAdd}
        setShowModalAdd={setShowModalAdd}
        handleViewerFormSubmission={
          viewingPermisionsType === "Individual"
            ? onBulkUpdateIndvPermissions
            : viewingPermisionsType === "Group"
            ? onGroupBulkUpdateIndvPermissions
            : ""
        }
        addState={stateName}
      />
    </div>
  );
};

export default ComponentListWalkthroughViewingPermissions;
