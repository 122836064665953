import { Box, FormControl, FormLabel, Popover } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import ComponentFilterDatePicker from "./ComponentFilterDatePicker";
import { formatDateForFilter } from "../utility/functions/formatDateForFilter";
import { convertDateToStandardFormat } from "./_functions/FunctionDate";
import { Popup, PopupItem } from "./02-molecules/05-Popup/Popup";
import {
  Button,
  Tags,
  PlusIcon,
  CloseSquare,
  InputField,
  InputNumber,
  Radio,
  Checkbox,
} from "usertip-component-library";

interface Props<T> {
  filters: { [key: string]: T }[]; // will be an array of objects
  originalData: { [key: string]: T }[]; // an array of objects, original list of data
  setFilteredData: any; // will be a react setState function
}

const ComponentFilterTable = ({
  filters,
  originalData,
  setFilteredData,
}: Props<any>) => {
  type FilteredData = { [key: string]: string | boolean | number | Date }[];

  const [filterList, setFilterList] = useState<
    { [key: string]: string | boolean | number | Date }[]
  >([]);

  useEffect(() => {
    // reset to original data if filterList is empty
    if (filterList.length === 0) {
      setFilteredData(originalData);
    } else {
      // this is for keep the filter when originalData is changed (for removing data while filtering)
      handleApplyFilters();
    }
  }, [filterList, originalData]);

  useEffect(() => {
    handleApplyFilters();
  }, [filterList]);

  const handleApplyFilters = () => {
    let filteredData: FilteredData = [...originalData];
    filterList.forEach((filterListItem: any) => {
      filteredData = filteredData?.filter((originalItem: any) => {
        switch (filterListItem.dataType) {
          case "String":
            // check if originalData has property called meta
            if (filterListItem.rule === "equal") {
              const originalText = originalItem[filterListItem.field]
                ? originalItem[filterListItem.field].toLowerCase()
                : null;
              const filterText = filterListItem.value.toLowerCase();
              return originalText ? filterText === originalText : false;
            } else if (filterListItem.rule === "contain") {
              const originalText = originalItem[filterListItem.field]
                ? originalItem[filterListItem.field].toLowerCase()
                : null;
              const filterText = filterListItem.value.toLowerCase();
              return originalText ? originalText.includes(filterText) : false;
            } else if (filterListItem.rule === "notEqual") {
              return (
                originalItem?.[filterListItem.field] !== filterListItem.value
              );
            } else if (filterListItem.rule === "isEmpty") {
              return (
                originalItem[filterListItem.field] === null ||
                originalItem[filterListItem.field] === undefined
              );
            } else if (filterListItem.rule === "isNotEmpty") {
              return originalItem[filterListItem.field];
            }
            break;
          case "Integer":
            if (filterListItem.rule === "equal") {
              return (
                filterListItem.value === originalItem[filterListItem.field]
              );
            } else if (filterListItem.rule === "notEqual") {
              return (
                originalItem[filterListItem.field] !== filterListItem.value
              );
            } else if (filterListItem.rule === "lessThan") {
              return filterListItem.value > originalItem[filterListItem.field];
            } else if (filterListItem.rule === "greaterThan") {
              return filterListItem.value < originalItem[filterListItem.field];
            } else if (filterListItem.rule === "isEmpty") {
              return (
                originalItem[filterListItem.field] === null ||
                originalItem[filterListItem.field] === undefined
              );
            } else if (filterListItem.rule === "isNotEmpty") {
              return originalItem[filterListItem.field];
            }

            break;
          case "Boolean":
            if (filterListItem.rule === "isTrue") {
              return originalItem[filterListItem.field] === true;
            } else if (filterListItem.rule === "isFalse") {
              return (
                originalItem[filterListItem.field] === false ||
                originalItem[filterListItem.field] === undefined ||
                originalItem[filterListItem.field] === null
              );
            }

            break;
          case "Date":
            if (originalItem.hasOwnProperty("meta")) {
              if (originalItem.meta.hasOwnProperty(filterListItem.field)) {
              }
            } else {
              if (filterListItem.rule === "since") {
                return (
                  formatDateForFilter(
                    new Date(originalItem[filterListItem.field])
                  ).getTime() ===
                  formatDateForFilter(
                    new Date(filterListItem.value.startDate)
                  ).getTime()
                );
              } else if (filterListItem.rule === "before") {
                return (
                  formatDateForFilter(
                    new Date(filterListItem.value.startDate)
                  ) >
                  formatDateForFilter(
                    new Date(originalItem[filterListItem.field])
                  )
                );
              } else if (filterListItem.rule === "between") {
                const date = formatDateForFilter(
                  new Date(originalItem[filterListItem.field])
                );
                return (
                  date >=
                    formatDateForFilter(
                      new Date(filterListItem.value.startDate)
                    ) &&
                  date <=
                    formatDateForFilter(new Date(filterListItem.value.endDate))
                );
              } else if (filterListItem.rule === "isEmpty") {
                return (
                  originalItem[filterListItem.field] === null ||
                  originalItem[filterListItem.field] === undefined
                );
              } else if (filterListItem.rule === "isNotEmpty") {
                return originalItem[filterListItem.field];
              }
            }
            break;
          case "GuideType":
            const isSnap = filterListItem.rule.includes("Snap");
            const isVideo = filterListItem.rule.includes("Video");
            const isWalkthrough = filterListItem.rule.includes("Walkthrough");

            if (filterListItem.rule.includes("Empty")) {
              return (
                originalItem[filterListItem.field].snap === false &&
                originalItem[filterListItem.field].video === false &&
                originalItem[filterListItem.field].walkthrough === false
              );
            }

            return (
              originalItem[filterListItem.field].snap === isSnap &&
              originalItem[filterListItem.field].video === isVideo &&
              originalItem[filterListItem.field].walkthrough === isWalkthrough
            );
          default:
            return originalItem;
        }
        return originalItem;
      });
    });
    setFilteredData([...filteredData!]);
  };

  const [openFilterList, setOpenFilterList] = useState(false);

  const handleFilterSelect = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setOpenFilterList(true);
  };

  const handleFilterClose = () => {
    setOpenFilterList(false);
  };

  const handleAddFilter = (filterObject: any) => {
    let newFilterList = filterList;
    newFilterList.push(filterObject);
    setFilterList([...newFilterList]);
    // close filter
    handleFilterClose();
  };

  const handleRemoveFilter = (index: Number) => {
    const filterListRemoved = filterList.filter((el: any, i: number) => {
      return i !== index;
    });
    setFilterList([...filterListRemoved]);
  };

  /** Refactoring the UI */
  const filterRef = useRef<HTMLDivElement>(null);
  let popupPos: number;

  useEffect(() => {
    /** This event listener is for close the popup when click other than popup */
    window.addEventListener("click", function () {
      setOpenFilterList(false);
    });
  }, []);

  /** This useEffect is for setting the popup position when it pops up */
  useEffect(() => {
    const firstChild = filterRef.current?.firstChild as HTMLDivElement;
    /** This is for get the width of Add Filter Button */
    const firstChildWidth = firstChild.getBoundingClientRect().width;
    popupPos = firstChildWidth + 4;

    /** This is for set Popup position when the add filter button clicked */
    if (openFilterList) {
      const popup = filterRef.current?.childNodes[1] as HTMLDivElement;
      popup.style.left = `${popupPos}px`;
    }
  }, [openFilterList]);

  return (
    <>
      <div
        className="flex flex-wrap gap-4 py-2 items-center relative my-6"
        ref={filterRef}
      >
        <div className="w-min" onClick={handleFilterSelect}>
          <Button
            text="Add Filter"
            iconLeft={true}
            icon={<PlusIcon />}
            size="small"
            variant="outlined"
            color="primary"
          />
        </div>

        {openFilterList && (
          <div className="w-min z-20 absolute top-0">
            <Popup type="filter">
              {filters.map((item: any) => {
                return (
                  <ComponentFilterOptionButton
                    key={item.field}
                    handleAddFilter={handleAddFilter}
                    field={item.field}
                    type={item.type}
                    label={item.label}
                  />
                );
              })}
            </Popup>
          </div>
        )}

        {filterList.map((filter: any, index: Number) => {
          let convertedValue;
          switch (filter.dataType) {
            case "GuideType":
              convertedValue = "";
              break;
            case "Boolean":
              convertedValue = "";
              break;
            case "Integer":
              if (filter.rule === "isEmpty" || filter.rule === "isNotEmpty") {
                convertedValue = "";
                break;
              }
              convertedValue = filter.value.toString();
              break;
            case "Date":
              if (filter.rule === "between") {
                convertedValue = `${convertDateToStandardFormat(
                  filter.value.startDate
                )} - ${convertDateToStandardFormat(filter.value.endDate)} `;
              } else {
                if (filter.rule === "isEmpty" || filter.rule === "isNotEmpty") {
                  convertedValue = "";
                  break;
                }
                convertedValue = convertDateToStandardFormat(
                  filter.value.startDate
                );
              }
              break;
            default:
              if (filter.rule === "isEmpty" || filter.rule === "isNotEmpty") {
                convertedValue = "";
                break;
              }
              convertedValue = filter.value;
              break;
          }

          return (
            <div key={convertedValue + index} className="cursor-default">
              <Tags
                size="large"
                text={`${filter.label.toLowerCase()}="${
                  convertedValue || filter.rule
                }"`}
                variant="filled"
                disable={false}
                clickable={false}
                icon={
                  <CloseSquare
                    className="fill-default-neutral-100 cursor-pointer"
                    onClick={() => {
                      handleRemoveFilter(index);
                    }}
                  />
                }
              />
            </div>
          );
        })}
      </div>
    </>
  );
};

interface ComponentFilterOptionButtonProps {
  handleAddFilter: any; // a set set state function to set the filter object
  field: String; // will be an array of objects
  type: String;
  label: String;
}

const ComponentFilterOptionButton = ({
  handleAddFilter,
  field,
  type,
  label,
}: ComponentFilterOptionButtonProps) => {
  const [openFilter, setOpenFilter] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const handleSubmitForm = (
    event: any,
    {
      field,
      dataType,
      rule,
      value,
      label,
    }: {
      field: String;
      dataType: String;
      rule: string;
      value: String | Number | Date | null | { startDate: Date; endDate: Date };
      label: string;
    }
  ) => {
    event.preventDefault();
    // based on the dataType --> make sure that the rules are correct
    // based on the dataType --> make sure that the format is Integer is Integer, String is String, Date is Date

    switch (dataType) {
      case "String":
        {
          // check if the rules are for string
          // check if the value is String
          if (
            (["equal", "contain", "notEqual", "isEmpty", "isNotEmpty"].includes(
              rule
            ) &&
              typeof value === "string") ||
            value === null
          ) {
            handleAddFilter({
              field: field,
              dataType: dataType,
              value: value,
              rule: rule,
              label: label,
            });
          } else {
            console.log(
              `rule or value is invalid. rule is ${rule}, value is ${value}`
            );
          }
        }
        break;
      case "Integer": {
        // check if the rules are for Integer
        // check if the value is Integer
        if (
          ([
            "equal",
            "notEqual",
            "lessThan",
            "greaterThan",
            "isEmpty",
            "isNotEmpty",
          ].includes(rule) &&
            typeof value === "number") ||
          value === null
        ) {
          handleAddFilter({
            field: field,
            dataType: dataType,
            value: value,
            rule: rule,
            label: label,
          });
        } else {
        }
        break;
      }
      case "Date":
        {
          // TODO: make sure the value is correct type
          if (
            ["since", "before", "between", "isEmpty", "isNotEmpty"].includes(
              rule
            )
          ) {
            handleAddFilter({
              field: field,
              dataType: dataType,
              value: value,
              rule: rule,
              label: label,
            });
          } else {
            console.log(
              `rule or value is invalid. rule is ${rule}, value is ${value}`
            );
          }
        }
        break;
      case "Boolean":
        {
          if (["isTrue", "isFalse"].includes(rule)) {
            handleAddFilter({
              field: field,
              dataType: dataType,
              value: value,
              rule: rule,
              label: label,
            });
          } else {
            console.log(
              `rule or value is invalid. rule is ${rule}, value is ${value}`
            );
          }
        }
        break;
      case "GuideType": {
        handleAddFilter({
          field: field,
          dataType: dataType,
          value: value,
          rule: rule,
          label: label,
        });
      }
    }
    handleFilterClose();
  };

  const renderFilterOptionsPopup = (dataType: String, field: String) => {
    switch (dataType) {
      case "String": {
        return (
          <ComponentFilterOptionStringPopup
            field={field}
            dataType={dataType}
            label={label}
            handleSubmit={handleSubmitForm}
            handleClose={handleFilterClose}
          />
        );
      }
      case "Integer": {
        return (
          <ComponentFilterOptionIntegerPopup
            field={field}
            dataType={dataType}
            label={label}
            handleSubmit={handleSubmitForm}
            handleClose={handleFilterClose}
          />
        );
      }
      case "Boolean": {
        return (
          <ComponentFilterOptionBoolean
            field={field}
            dataType={dataType}
            label={label}
            handleSubmit={handleSubmitForm}
            handleClose={handleFilterClose}
          />
        );
      }
      case "Date": {
        return (
          <ComponentOptionFilterDate
            field={field}
            dataType={dataType}
            label={label}
            handleSubmit={handleSubmitForm}
            handleClose={handleFilterClose}
          />
        );
      }
      case "GuideType": {
        return (
          <ComponentOptionGuideType
            field={field}
            dataType={dataType}
            label={label}
            handleSubmit={handleSubmitForm}
            handleClose={handleFilterClose}
          />
        );
      }
      default:
        return <p>Filter data type is invalid</p>;
    }
  };

  const handleFilterSelect = (event: React.MouseEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    setAnchorEl(target.parentElement!.parentElement);
    setOpenFilter(true);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
    setOpenFilter(false);
  };

  return (
    <>
      <PopupItem
        label={label.toString()}
        type="filter"
        onClick={handleFilterSelect}
      />
      {openFilter ? (
        <Popover
          onClick={(e) => {
            e.stopPropagation();
          }}
          id={`filter-${field}-popover`}
          open={true}
          anchorEl={anchorEl}
          onClose={handleFilterClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          sx={{
            margin: "6px",
          }}
        >
          <Box
            sx={{
              margin: "12px",
            }}
          >
            {renderFilterOptionsPopup(type, field)}
          </Box>
        </Popover>
      ) : null}
    </>
  );
};
// ComponentFilterOptionStringPopup
const ComponentFilterOptionStringPopup = ({
  field,
  dataType,
  label,
  handleSubmit,
  handleClose,
}: {
  field: String;
  dataType: String;
  label: String;
  handleSubmit: any;
  handleClose: any;
}) => {
  const [inputValue, setInputValue] = useState<any>("");
  const [option, setOption] = useState("contain");

  const selectOption = (value: string) => {
    setOption(value);
  };

  return (
    <>
      <form
        onSubmit={(event) => {
          handleSubmit(event, {
            field,
            dataType,
            rule: option,
            value: inputValue,
            label,
          });
        }}
      >
        <FormControl>
          <FormLabel id={`filter-${field}-form`}>{label}</FormLabel>
          <FormLabel id={`filter-${dataType}-form`}>{dataType}</FormLabel>
          <div className="flex flex-col gap-4 py-4 min-w-[300px]">
            <Radio
              name={`filter-${field}-radio-group`}
              id="contain"
              value="contain"
              selectedValue={option}
              onSelectedValue={selectOption}
              label="contains"
            />
            {option === "contain" ? (
              <InputField
                variant="text"
                onChange={(e) => setInputValue(e.target.value)}
                customWidth="ut-w-full"
                placeholder="Enter value here"
              />
            ) : null}
            {/* <Radio
              name={`filter-${field}-radio-group`}
              id="equal"
              value="equal"
              selectedValue={option}
              onSelectedValue={selectOption}
              label="is equal to"
            />
            {option === "equal" ? (
              <InputField
                variant="text"
                onChange={(e) => setInputValue(e.target.value)}
                customWidth="ut-w-full"
                placeholder="Enter value here"
              />
            ) : null} */}
            {/* <Radio
              name={`filter-${field}-radio-group`}
              id="notEqual"
              value="notEqual"
              selectedValue={option}
              onSelectedValue={selectOption}
              label="is not equal to"
            />
            {option === "notEqual" ? (
              <InputField
                variant="text"
                onChange={(e) => setInputValue(e.target.value)}
                customWidth="ut-w-full"
                placeholder="Enter value here"
              />
            ) : null} */}
            <Radio
              name={`filter-${field}-radio-group`}
              id="isEmpty"
              value="isEmpty"
              selectedValue={option}
              onSelectedValue={selectOption}
              label="is empty (has no value assigned)"
            />
            <Radio
              name={`filter-${field}-radio-group`}
              id="isNotEmpty"
              value="isNotEmpty"
              selectedValue={option}
              onSelectedValue={selectOption}
              label="is not empty (has a value)"
            />
          </div>
          <div className="flex justify-evenly">
            <Button
              text="CANCEL"
              color="primary"
              variant="outlined"
              size="medium"
              onClick={handleClose}
            />
            <Button
              text="APPLY FILTER"
              color="primary"
              variant="outlined"
              size="medium"
            />
          </div>
        </FormControl>
      </form>
    </>
  );
};

// ComponentFilterOptionStringPopup
const ComponentFilterOptionIntegerPopup = ({
  field,
  dataType,
  label,
  handleSubmit,
  handleClose,
}: {
  field: String;
  dataType: String;
  label: String;
  handleSubmit: any;
  handleClose: any;
}) => {
  const [option, setOption] = useState("equal");
  const [inputValue, setInputValue] = useState<number | null>(null);

  const selectOption = (value: string) => {
    setOption(value);
    setInputValue(null);
    if (value === "isEmpty") {
      setInputValue(null);
    } else if (value === "isNotEmpty") {
      setInputValue(1);
    }
  };

  return (
    <>
      <form
        onSubmit={(event) => {
          handleSubmit(event, {
            field,
            dataType,
            rule: option,
            value: inputValue,
            label,
          });
        }}
      >
        <FormControl>
          <FormLabel id={`filter-${field}-form`}>{label}</FormLabel>
          <FormLabel id={`filter-${dataType}-form`}>{dataType}</FormLabel>
          <div className="flex flex-col gap-4 py-4 min-w-[300px]">
            <Radio
              name={`filter-${field}-radio-group`}
              id="equal"
              value="equal"
              label="is equal to"
              selectedValue={option}
              onSelectedValue={selectOption}
            />
            {option === "equal" ? (
              <InputNumber
                onChange={(e) => setInputValue(+e)}
                value={inputValue!}
                minValue={0}
                width="full"
              />
            ) : null}
            <Radio
              name={`filter-${field}-radio-group`}
              id="notEqual"
              value="notEqual"
              label="is not equal to"
              selectedValue={option}
              onSelectedValue={selectOption}
            />
            {option === "notEqual" ? (
              <InputNumber
                onChange={(e) => setInputValue(+e)}
                value={inputValue!}
                minValue={0}
                width="full"
              />
            ) : null}

            <Radio
              name={`filter-${field}-radio-group`}
              id="lessThan"
              value="lessThan"
              label="is less than"
              selectedValue={option}
              onSelectedValue={selectOption}
            />
            {option === "lessThan" ? (
              <InputNumber
                onChange={(e) => setInputValue(+e)}
                value={inputValue!}
                minValue={0}
                width="full"
              />
            ) : null}

            <Radio
              name={`filter-${field}-radio-group`}
              id="greaterThan"
              value="greaterThan"
              label="is greater than"
              selectedValue={option}
              onSelectedValue={selectOption}
            />

            {option === "greaterThan" ? (
              <InputNumber
                onChange={(e) => setInputValue(+e)}
                value={inputValue!}
                minValue={0}
                width="full"
              />
            ) : null}
            <Radio
              name={`filter-${field}-radio-group`}
              id="isEmpty"
              value="isEmpty"
              label="is empty (has no value assigned)"
              selectedValue={option}
              onSelectedValue={selectOption}
            />
            <Radio
              name={`filter-${field}-radio-group`}
              id="isNotEmpty"
              value="isNotEmpty"
              label="is not empty (has a value)"
              selectedValue={option}
              onSelectedValue={selectOption}
            />
          </div>

          <div className="flex justify-evenly">
            <Button
              text="CANCEL"
              color="primary"
              variant="outlined"
              size="medium"
              onClick={handleClose}
            />
            <Button
              text="APPLY FILTER"
              color="primary"
              variant="outlined"
              size="medium"
            />
          </div>
        </FormControl>
      </form>
    </>
  );
};

// ComponentFilterOptionBoolean
const ComponentFilterOptionBoolean = ({
  field,
  dataType,
  label,
  handleSubmit,
  handleClose,
}: {
  field: String;
  dataType: String;
  label: String;
  handleSubmit: any;
  handleClose: any;
}) => {
  const [option, setOption] = useState("isTrue");
  const [inputValue, setInputValue] = useState<Boolean | null>(true);

  const onChangeHandler = (value: string) => {
    setOption(value);
    if (value === "isTrue") {
      setInputValue(true);
    } else {
      setInputValue(false);
    }
  };

  return (
    <form
      onSubmit={(event) => {
        handleSubmit(event, {
          field,
          dataType,
          rule: option,
          value: inputValue,
          label,
        });
      }}
    >
      <FormControl>
        <FormLabel id={`filter-${field}-form`}>{label}</FormLabel>
        <FormLabel id={`filter-${dataType}-form`}>{dataType}</FormLabel>
        <div className="flex flex-col gap-4 py-4 min-w-[300px]">
          <Radio
            name={`filter-${field}-radio-group`}
            id="isTrue"
            value="isTrue"
            selectedValue={option}
            onSelectedValue={onChangeHandler}
            label="is true"
          />
          <Radio
            name={`filter-${field}-radio-group`}
            id="isFalse"
            value="isFalse"
            selectedValue={option}
            onSelectedValue={onChangeHandler}
            label="is false"
          />
        </div>
        <div className="flex justify-evenly">
          <Button
            text="CANCEL"
            color="primary"
            variant="outlined"
            size="medium"
            onClick={handleClose}
          />
          <Button
            text="APPLY FILTER"
            color="primary"
            variant="outlined"
            size="medium"
          />
        </div>
      </FormControl>
    </form>
  );
};

const ComponentOptionFilterDate = ({
  field,
  dataType,
  label,
  handleSubmit,
  handleClose,
}: {
  field: String;
  dataType: String;
  label: String;
  handleSubmit: any;
  handleClose: any;
}) => {
  const [option, setOption] = useState("since");
  const [startDate, setStartDate] = useState<Date | null>(
    formatDateForFilter(new Date())
  );
  const [endDate, setEndDate] = useState<Date | null>(
    formatDateForFilter(new Date())
  );

  const onChangeHandler = (value: string) => {
    setOption(value);
    if (value === "isEmpty" || value === "isNotEmpty") {
      setStartDate(null);
      setEndDate(null);
    }
  };

  return (
    <form
      onSubmit={(event) => {
        handleSubmit(event, {
          field,
          dataType,
          rule: option,
          value: {
            startDate,
            endDate,
          },
          label,
        });
      }}
    >
      <FormControl>
        <FormLabel id={`filter-${field}-form`}>{label}</FormLabel>
        <FormLabel id={`filter-${dataType}-form`}>{dataType}</FormLabel>
        <div className="flex flex-col gap-4 py-4 min-w-[300px]">
          <Radio
            name={`filter-${field}-radio-group`}
            id="since"
            value="since"
            label="since"
            selectedValue={option}
            onSelectedValue={onChangeHandler}
          />
          {option === "since" && (
            <ComponentFilterDatePicker
              rule={"since"}
              startDate={startDate!}
              endDate={endDate!}
              setEndDate={setEndDate}
              setStartDate={setStartDate}
            />
          )}
          <Radio
            name={`filter-${field}-radio-group`}
            id="before"
            value="before"
            label="before"
            selectedValue={option}
            onSelectedValue={onChangeHandler}
          />
          {option === "before" && (
            <ComponentFilterDatePicker
              rule={"since"}
              startDate={startDate!}
              endDate={endDate!}
              setEndDate={setEndDate}
              setStartDate={setStartDate}
            />
          )}
          <Radio
            name={`filter-${field}-radio-group`}
            id="between"
            value="between"
            label="between"
            selectedValue={option}
            onSelectedValue={onChangeHandler}
          />
          {option === "between" && (
            <ComponentFilterDatePicker
              rule={"between"}
              startDate={startDate!}
              endDate={endDate!}
              setEndDate={setEndDate}
              setStartDate={setStartDate}
            />
          )}
          <Radio
            name={`filter-${field}-radio-group`}
            id="isEmpty"
            value="isEmpty"
            label="is empty (has no value assigned)"
            selectedValue={option}
            onSelectedValue={onChangeHandler}
          />
          <Radio
            name={`filter-${field}-radio-group`}
            id="isNotEmpty"
            value="isNotEmpty"
            label="is not empty (has a value)"
            selectedValue={option}
            onSelectedValue={onChangeHandler}
          />
        </div>

        <div className="flex justify-evenly">
          <Button
            text="CANCEL"
            color="primary"
            variant="outlined"
            size="medium"
            onClick={handleClose}
          />
          <Button
            text="APPLY FILTER"
            color="primary"
            variant="outlined"
            size="medium"
          />
        </div>
      </FormControl>
    </form>
  );
};

type GuideType = "Snap" | "Video" | "Walkthrough" | "Empty";

const ComponentOptionGuideType = ({
  field,
  dataType,
  label,
  handleSubmit,
  handleClose,
}: {
  field: String;
  dataType: String;
  label: String;
  handleSubmit: any;
  handleClose: any;
}) => {
  const [option, setOption] = useState<GuideType[]>([]);

  const OnChangeHanlder = (type: GuideType) => {
    const filter = (type: GuideType) => {
      return option.filter((val) => val !== type);
    };

    if (type !== "Empty") {
      option.includes(type)
        ? setOption(filter(type))
        : setOption(
            [...option, type].filter((val) => {
              return val !== "Empty";
            })
          );
    } else {
      option.includes(type) ? setOption(filter(type)) : setOption([type]);
    }
  };

  return (
    <form
      onSubmit={(event) => {
        handleSubmit(event, {
          field,
          dataType,
          rule: option,
          value: option,
          label,
        });
      }}
    >
      <FormControl>
        <FormLabel id={`filter-${field}-form`}>{label}</FormLabel>
        <FormLabel id={`filter-${dataType}-form`}>{dataType}</FormLabel>
        <div className="flex flex-col gap-4 py-4 min-w-[300px]">
          <Checkbox
            name={`filter-${field}-checkbox`}
            id="Snap"
            label="Snap"
            value="Snap"
            selectedValue={option.includes("Snap") ? "Snap" : ""}
            onChange={() => OnChangeHanlder("Snap")}
          />
          <Checkbox
            name={`filter-${field}-checkbox`}
            id="Video"
            label="Video"
            value="Video"
            selectedValue={option.includes("Video") ? "Video" : ""}
            onChange={() => OnChangeHanlder("Video")}
          />
          <Checkbox
            name={`filter-${field}-checkbox`}
            id="Walkthrough"
            label="Walkthrough"
            value="Walkthrough"
            selectedValue={option.includes("Walkthrough") ? "Walkthrough" : ""}
            onChange={() => OnChangeHanlder("Walkthrough")}
          />
          <Checkbox
            name={`filter-${field}-checkbox`}
            id="Empty"
            label="Empty"
            value="Empty"
            selectedValue={option.includes("Empty") ? "Empty" : ""}
            onChange={() => OnChangeHanlder("Empty")}
          />
        </div>

        <div className="flex justify-evenly">
          <Button
            text="CANCEL"
            color="primary"
            variant="outlined"
            size="medium"
            onClick={handleClose}
          />
          <Button
            text="APPLY FILTER"
            color="primary"
            variant="outlined"
            size="medium"
            disabled={option.length === 0}
          />
        </div>
      </FormControl>
    </form>
  );
};

export default ComponentFilterTable;
