import { compareAsc, formatISO, startOfWeek } from "date-fns";
import {
  AnalyticDataForChart,
  AnalyticDataSet,
} from "../../interfaces/interfaceAnalytic";
import { formatDateForFilter } from "../../utility/functions/formatDateForFilter";
import { checkSameWeek } from "./FunctionDate";

const getAverage = (listOFTime: number[]) => {
  return Math.round(
    listOFTime.reduce((acc, curr) => {
      return acc + curr;
    }, 0) / listOFTime.length
  );
};

const findMedian = (inputArray: number[]) => {
  let sortedArr = inputArray.slice().sort((a, b) => a - b);
  let inputLength = inputArray.length;
  let middleIndex = Math.floor(inputLength / 2);
  let oddLength = inputLength % 2 != 0;
  let median;
  if (oddLength) {
    // if array length is odd -> return element at middleIndex
    median = sortedArr[middleIndex];
  } else {
    median = (sortedArr[middleIndex] + sortedArr[middleIndex - 1]) / 2;
  }
  return median;
};

/**check if user have email and unique id then format it to it */
const getUserFormat = (email: string, unique_id: string) => {
  if (email && unique_id) {
    return `[email]: ${email}| [unique_ID]: ${unique_id}`;
  } else if (email) {
    return `[email]: ${email}`;
  } else if (unique_id) {
    return `[unique_id]: ${unique_id}`;
  } else {
    return "";
  }
};

/** check longest time spent */
const checkLongestTime = (currentTime: number, newTime: number) => {
  return currentTime > newTime ? currentTime : newTime;
};

/** check longest time spent */
const checkShortestTime = (currentTime: number, newTime: number) => {
  return currentTime < newTime ? currentTime : newTime;
};

const getRate = (index: number, data: AnalyticDataForChart[]) => {
  if (index === 0) {
    return 0;
  } else {
    return data[index - 1].seen - data[index].seen;
  }
};

const getPercentage = (index: number, data: AnalyticDataForChart[]) => {
  if (index === 0) {
    return "0%";
  } else {
    const rate = data[index - 1].seen - data[index].seen;
    const percentage =
      +((rate / data[index].seen) * 100).toFixed(2).toString() + "%";
    return percentage;
  }
};

export const functionGroupBy = (array: AnalyticDataSet[]) => {
  const reduced = array.reduce<AnalyticDataForChart[]>((acc, curr) => {
    const index = acc.findIndex((el) => el.date === curr.start_time);

    if (index === -1) {
      const data: AnalyticDataForChart = {
        date: curr.start_time,
        seen: 0,
        completed: 0,
        dismissed: 0,
        incomplete: 0,
        repeat: 0,
        firstTime: 0,
        manual: 0,
        automatic: 0,

        //additional dataSet
        averageTimeFinish: 0,
        medianTimes: 0,
        longestCompTimes: 0,
        longestComppUser: "",
        shortestCompTimes: null,
        shortestCompUser: "",
        dropOffRate: 0,
        dropOffPercentage: "",
        timeCollected: [],
      };

      if (curr.firstTime === true) {
        data.firstTime = 1;
        data.repeat = 0;
      } else if (curr.firstTime === null) {
        data.firstTime = 0;
        data.repeat = 0;
      } else {
        data.repeat = 1;
      }

      if (curr.launch_type === "automatic") {
        data.automatic = 1;
      } else if (curr.launch_type === null) {
        data.automatic = 0;
        data.manual = 0;
      } else {
        data.manual = 1;
      }

      switch (curr.status) {
        case "completed":
          data.seen = 1;
          data.completed = 1;
          break;
        case "dismissed":
          data.dismissed = 1;
          data.seen = 1;
          break;
        case "seen":
          data.incomplete = 1;
          data.seen = 1;
          break;
        default:
          break;
      }

      /* collect all the time taken and will procced for average & median */
      if (curr.time_taken) {
        data.timeCollected = [curr.time_taken];
      } else {
        data.timeCollected = [];
      }

      /**Block for longest time, shortest time completion */
      if (curr.time_taken) {
        data.longestCompTimes = curr.time_taken;
        data.longestComppUser = getUserFormat(curr.email, curr.unique_id);

        data.shortestCompTimes = curr.time_taken;
        data.shortestCompUser = getUserFormat(curr.email, curr.unique_id);
      }

      acc.push(data);
    } else {
      if (curr.firstTime === true) {
        // acc[index].firstTime++;
        acc[index].repeat = acc[index].repeat + 0;
      } else if (curr.firstTime === null) {
        // acc[index].firstTime = acc[index].firstTime + 0;
        acc[index].repeat = acc[index].repeat + 0;
      } else {
        acc[index].repeat++;
      }
      /* block to count walktthrough status launch */
      switch (curr.status) {
        case "completed":
          acc[index].completed++;
          acc[index].seen++;
          break;
        case "dismissed":
          acc[index].dismissed++;
          acc[index].seen++;
          break;
        case "seen": // if status is seen, means the walkthrough is seen + incomplete
          acc[index].incomplete++;
          acc[index].seen++;
          break;
        case null:
          acc[index].dismissed = acc[index].dismissed + 0;
          acc[index].seen = acc[index].seen + 0;
          acc[index].completed = acc[index].completed + 0;
          acc[index].incomplete = acc[index].incomplete + 0;
          break;
        default:
          break;
      }

      /* block to count automatic and manual launch */
      if (curr.launch_type === "automatic") {
        acc[index].automatic++;
      } else if (curr.launch_type === null) {
        acc[index].automatic = acc[index].automatic + 0;
        acc[index].manual = acc[index].manual + 0;
      } else {
        acc[index].manual++;
      }

      /** block to count timeCollected */
      if (curr.time_taken) {
        acc[index].timeCollected = [
          ...acc[index].timeCollected!,
          curr.time_taken,
        ];
      }

      /**Block to check user time spent */
      if (curr.time_taken) {
        acc[index].shortestCompTimes === null
          ? (acc[index].shortestCompTimes = curr.time_taken)
          : (acc[index].shortestCompTimes = acc[index].shortestCompTimes);

        acc[index].longestCompTimes = checkLongestTime(
          acc[index].longestCompTimes!,
          curr.time_taken
        );
        acc[index].longestComppUser = getUserFormat(curr.email, curr.unique_id);

        acc[index].shortestCompTimes = checkShortestTime(
          acc[index].shortestCompTimes!,
          curr.time_taken
        );
        acc[index].shortestCompUser = getUserFormat(curr.email, curr.unique_id);
      }
    }

    return acc;
  }, []);

  /** calculate average time, median, drop off rate */
  const calculateTime = reduced.map((d, i) => {
    return {
      ...d,
      averageTimeFinish: getAverage(d.timeCollected!),
      medianTimes: findMedian(d.timeCollected!),
      dropOffRate: getRate(i, reduced),
      dropOffPercentage: getPercentage(i, reduced),
    };
  });

  return calculateTime;
};

export const functionGroupByWeek = (array: AnalyticDataSet[]) => {
  const reduced = array.reduce<AnalyticDataForChart[]>((acc, curr) => {
    const index = acc.findIndex((el) =>
      checkSameWeek(new Date(el.date), new Date(curr.start_time))
    );

    if (index === -1) {
      const data = {
        date: startOfWeek(new Date(curr.start_time), {
          weekStartsOn: 0,
        }).toString(),
        seen: 0,
        completed: 0,
        dismissed: 0,
        incomplete: 0,
        repeat: 0,
        firstTime: 0,
        automatic: 0,
        manual: 0,
        //additional dataSet
        averageTimeFinish: 0,
        medianTimes: 0,
        longestCompTimes: 0,
        longestComppUser: "",
        shortestCompTimes: null as null | number,
        shortestCompUser: "",
        dropOffRate: 0,
        dropOffPercentage: "",
        timeCollected: [0],
      };

      if (curr.launch_type === "automatic") {
        data.automatic = 1;
      } else if (curr.launch_type === null) {
        data.automatic = 0;
        data.manual = 0;
      } else {
        data.manual = 1;
      }

      if (curr.firstTime === true) {
        data.firstTime = 1;
        data.repeat = 0;
      } else if (curr.firstTime === null) {
        // data.firstTime = 0;
        data.repeat = 0;
      } else {
        data.repeat = 1;
      }
      switch (curr.status) {
        case "completed":
          data.seen = 1;
          data.completed = 1;
          break;
        case "dismissed":
          data.dismissed = 1;
          data.seen = 1;
          break;
        case "seen":
          data.incomplete = 1;
          data.seen = 1;
          break;
        default:
          break;
      }
      /* collect all the time taken and will procced for average & median */
      if (curr.time_taken) {
        data.timeCollected = [curr.time_taken];
      } else {
        data.timeCollected = [];
      }

      /**Block for longest time, shortest time completion */
      if (curr.time_taken) {
        data.longestCompTimes = curr.time_taken;
        data.longestComppUser = getUserFormat(curr.email, curr.unique_id);

        data.shortestCompTimes = curr.time_taken;
        data.shortestCompUser = getUserFormat(curr.email, curr.unique_id);
      }

      //@ts-ignore
      acc.push(data);
    } else {
      if (curr.firstTime === true) {
        // acc[index].firstTime++;
        acc[index].repeat = acc[index].repeat + 0;
      } else if (curr.firstTime === null) {
        // acc[index].firstTime = acc[index].firstTime + 0;
        acc[index].repeat = acc[index].repeat + 0;
      } else {
        acc[index].repeat++;
      }
      switch (curr.status) {
        case "completed":
          acc[index].completed++;
          acc[index].seen++;
          break;
        case "dismissed":
          acc[index].dismissed++;
          acc[index].seen++;
          break;
        case "seen": // if status is seen, means the walkthrough is seen + incomplete
          acc[index].incomplete++;
          acc[index].seen++;
          break;
        case null:
          acc[index].dismissed = acc[index].dismissed + 0;
          acc[index].seen = acc[index].seen + 0;
          acc[index].completed = acc[index].completed + 0;
          acc[index].incomplete = acc[index].incomplete + 0;
          break;
      }

      /* block to count automatic and manual launch */
      if (curr.launch_type === "automatic") {
        acc[index].automatic++;
      } else if (curr.launch_type === null) {
        acc[index].automatic = acc[index].automatic + 0;
        acc[index].manual = acc[index].manual + 0;
      } else {
        acc[index].manual++;
      }

      /** block to count timeCollected */
      if (curr.time_taken) {
        acc[index].timeCollected = [
          ...acc[index].timeCollected!,
          curr.time_taken,
        ];
      }

      /**Block to check user time spent */
      if (curr.time_taken) {
        acc[index].shortestCompTimes === null
          ? (acc[index].shortestCompTimes = curr.time_taken)
          : (acc[index].shortestCompTimes = acc[index].shortestCompTimes);

        acc[index].longestCompTimes = checkLongestTime(
          acc[index].longestCompTimes!,
          curr.time_taken
        );
        acc[index].longestComppUser = getUserFormat(curr.email, curr.unique_id);

        acc[index].shortestCompTimes = checkShortestTime(
          acc[index].shortestCompTimes!,
          curr.time_taken
        );
        acc[index].shortestCompUser = getUserFormat(curr.email, curr.unique_id);
      }
    }

    return acc;
  }, []);

  /** calculate average time, median, drop off rate */
  const calculateTime = reduced.map((d, i) => {
    return {
      ...d,
      averageTimeFinish: getAverage(d.timeCollected!),
      medianTimes: findMedian(d.timeCollected!),
      dropOffRate: getRate(i, reduced),
      dropOffPercentage: getPercentage(i, reduced),
    };
  });

  return calculateTime;
};

export const generateEmptyAnalytic = (data: AnalyticDataSet[]) => {
  // change the status on date that doesnt have seen/completed to null
  const sortDate = data.sort((a, b) => {
    return compareAsc(new Date(a.start_time), new Date(b.start_time));
  });

  // @ts-ignore
  const makeEmptyDate = sortDate.reduce((acc, curr, currIndex) => {
    //@ts-ignore
    const index = acc.findIndex(
      (el) =>
        //@ts-ignore
        el.accDate ===
        //@ts-ignore
        formatDateForFilter(new Date(curr.start_time)).toString()
    );

    if (index === -1) {
      //@ts-ignore
      if (acc.length === 0) {
        // @ts-ignore
        acc.push({
          emptyAnalyticDates: [],
        });
        // @ts-ignore
        acc.push({
          accDate: curr.start_time,
        });
      } else {
        const days = [];
        for (
          const d = formatDateForFilter(
            //@ts-ignore
            new Date(acc[acc.length - 1].accDate)
          );
          d < formatDateForFilter(new Date(curr.start_time));
          d.setDate(d.getDate() + 1)
        ) {
          days.push({
            start_time: formatISO(new Date(d)),
            status: null,
            firstTime: null,
            launch_type: null,
          });
        }
        //@ts-ignore
        acc.push({
          accDate: curr.start_time,
        });
        //@ts-ignore'
        acc[0].emptyAnalyticDates = [...acc[0].emptyAnalyticDates, ...days];
      }
    } else {
      return acc;
    }
    return acc;
  }, []);
  //@ts-ignore
  const newDate = makeEmptyDate[0] ? makeEmptyDate[0].emptyAnalyticDates : [];
  return newDate;
};
