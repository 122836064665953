import attachHTTPRequestHeaders from "../../utility/functions/attachHTTPRequestHeaders";
import { api } from "./_axios";

/*
    ===================================================================    
    API 2.0: API-Calls To Server
    ===================================================================
    1. api_p1 is default for all API 2.0 calls
    2. api_p2 is now only for /signIn route  
    ===================================================================    
    CALLS-GROUP WALKTHROUGH API
    ===================================================================
*/

export const callPOSTCreateUpdateRemoveWalkthroughsFromGroupWalkthrough =
  async (walkthrough_group_id, list_of_walkthroughs) => {
    const data = {
      walkthroughs: list_of_walkthroughs,
    };
    const config = attachHTTPRequestHeaders(true);
    return api
      .post(`/walkthrough/group/update/${walkthrough_group_id}`, data, config)
      .then((res) => res)
      .catch((err) => {
        return Promise.reject(err);
      });
  };

export const callPOSTBulkRemoveWalkthroughsFromGroupWalkthrough = async (
  walkthrough_group_id,
  list_of_walkthroughs
) => {
  const data = {
    walkthroughs: list_of_walkthroughs,
  };
  const config = attachHTTPRequestHeaders(true);
  return api
    .post(
      `/walkthrough/group/removeWalkthroughs/${walkthrough_group_id}`,
      data,
      config
    )
    .then((res) => res)
    .catch((err) => {
      return Promise.reject(err);
    });
};
