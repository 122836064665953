import { AxiosResponse } from "axios";
import { api } from "./_axios";
import attachHTTPRequestHeaders from "../../utility/functions/attachHTTPRequestHeaders";
import {
  AddImageSnapManualPayload,
  AddSnapManualPayload,
  Snap,
} from "../../interfaces/interfaceGuide";
import { ResponseGetSingleImage } from "../../store/reducers/reducerMedia";

// GET: Get a single Guide document from API call
export const callGETRetrieveSingleGuideDocument = async (
  quickBuildId: string
) => {
  //FIXME: update request header FOR PRODUCTION
  const config = attachHTTPRequestHeaders(true);

  // payload : {
  //   quick_build_id: string;
  //   name: string;
  //   walkthrough?: Walkthrough;
  //   snap?: Snap;
  //   video_guide?: any; // TODO: Add video guide later
  // }

  return api
    .get(`/quickBuilder/${quickBuildId}`, config)
    .then((res) => res)
    .catch((err) => {
      return Promise.reject(err);
    });
};

// GET: Get a single SNAP Guide document from API call
export const callGETRetrieveSingleSnapGuide = async (snap_id: string) => {
  //FIXME: update request header FOR PRODUCTION
  const config = attachHTTPRequestHeaders(true);

  return api
    .get(`/quickBuilder/getSingleSnap/${snap_id}`, config)
    .then((res) => res)
    .catch((err) => {
      return Promise.reject(err);
    });
};

// TODO: add manual step
// POST: /quickBuilder/addSnapStepManual/:id
export const callPATCHAddSnapStepManual = async (
  snap_id: string,
  payload: AddSnapManualPayload
) => {
  const config = attachHTTPRequestHeaders(true);

  return api
    .patch(`/quickBuilder/addSnapStepManual/${snap_id}`, payload, config)
    .then((res) => res)
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const callPOSTAddImageSnapStepManual = async (
  snap_id: string,
  payload: AddImageSnapManualPayload
) => {
  const config = attachHTTPRequestHeaders(true);

  return api
    .post(`/quickBuilder/addImageSnapStepManual/${snap_id}`, payload, config)
    .then((res) => res)
    .catch((err) => {
      return Promise.reject(err);
    });
};

// GET: Get a single SNAP Guide document from API call
export const callPostUpdateSharingPermission = async (
  snap_id: string,
  option: string
) => {
  const config = attachHTTPRequestHeaders(true);

  const data = {
    option: option,
  };
  return api
    .post(`/quickBuilder/generateSnapShareLink/${snap_id}`, data, config)
    .then((res) => res)
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const callVerifySnapShareLink = async (
  shareId: string,
  snapId: string,
  isNeedToken: boolean
) => {
  const config = attachHTTPRequestHeaders(isNeedToken);

  return api
    .get(`/quickBuilder/verifySnapShareLink/${shareId}/${snapId}`, config)
    .then((res) => res)
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const callPutEditSnapStep = async (snapId: string, newStep: Snap) => {
  const config = attachHTTPRequestHeaders(true);

  const data = {
    name: newStep.name,
    new_content: newStep.snap_component,
  };

  return api
    .patch(`/quickBuilder/editSnap/${snapId}`, data, config)
    .then((res) => res)
    .catch((err) => {
      return Promise.reject(err);
    });
};

/**get presigned url for single image from snap bucket*/
export const GETSingleSnapImageCall = async (mediaId: string) => {
  const config = attachHTTPRequestHeaders(true);

  return api
    .get(`/quickBuilder/getSnapImage/${mediaId}`, config)
    .then((res: AxiosResponse<ResponseGetSingleImage>) => res);
};

type GuideData = {
  orgId: string;
  page: number;
  perPage: number;
};

export const callGETRetrieveAllGuide = async (guideData: GuideData) => {
  const config = attachHTTPRequestHeaders(true);

  const { orgId, page, perPage } = guideData;

  const data = {
    orgId: orgId,
    page: page,
    perPage: perPage,
  };

  return api
    .post(`/quickBuilder/getAllGuides/${orgId}`, data, config)
    .then((res) => res)
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const callGETRetrieveRestOfGuide = async (orgId: string) => {
  const config = attachHTTPRequestHeaders(true);

  return api
    .post(`/quickBuilder/getAllGuides/${orgId}`, {}, config)
    .then((res) => res)
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const callDeleteGuide = async (quickBuildId: string) => {
  const config = attachHTTPRequestHeaders(true);

  return api
    .delete(`/quickBuilder/deleteSingleGuide/${quickBuildId}`, config)
    .then((res) => res)
    .catch((err) => {
      return Promise.reject(err);
    });
};

/**rename guide */
export const callPutEditName = async (
  quickBuildId: string,
  newName: string
) => {
  const config = attachHTTPRequestHeaders(true);

  const data = {
    name: newName,
  };

  return api
    .patch(`/quickBuilder/${quickBuildId}`, data, config)
    .then((res) => res)
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const callDeleteSnapComponent = async (
  snapId: string,
  snapComponentId: string
) => {
  const config = attachHTTPRequestHeaders(true);

  return api
    .delete(
      `/quickBuilder/deleteSingleSnapComponent/${snapId}/${snapComponentId}`,
      config
    )
    .then((res) => res)
    .catch((err) => {
      return Promise.reject(err);
    });
};

/**Public snap */

export const callGetPublicSnap = async (snapId: string) => {
  const config = attachHTTPRequestHeaders(false);

  return api
    .get(`/quickBuilder/public/getSingleSnap/${snapId}`, config)
    .then((res) => res)
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const callGETFirstPageSnaps = async () => {
  const config = attachHTTPRequestHeaders(true);

  const data = {
    perPage: 25,
    page: 1,
  };

  return api
    .post("/quickBuilder/getAllSnaps", data, config)
    .then((res) => res)
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const callGETRestPageSnaps = async () => {
  const config = attachHTTPRequestHeaders(true);

  const data = {};

  return api
    .post("/quickBuilder/getAllSnaps", data, config)
    .then((res) => res)
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const callGetPublicSingleSnapImage = async (mediaId: string) => {
  const config = attachHTTPRequestHeaders(false);

  return api
    .get(`/quickBuilder/public/getSnapImage/${mediaId}`, config)
    .then((res: AxiosResponse<ResponseGetSingleImage>) => res)
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const callDeleteSingleSnap = async (snapId: string) => {
  const config = attachHTTPRequestHeaders(true);

  return api
    .delete(`quickBuilder/deleteSingleSnap/${snapId}`, config)
    .then((res) => res)
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const callPostPublishSnap = async (snapId: string) => {
  const config = attachHTTPRequestHeaders(true);

  return api
    .post(`quickBuilder/publishSnap/${snapId}`, {}, config)
    .then((res) => res)
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const callPostUnpublishSnap = async (snapId: string) => {
  const config = attachHTTPRequestHeaders(true);

  return api
    .post(`quickBuilder/unpublishSnap/${snapId}`, {}, config)
    .then((res) => res)
    .catch((err) => {
      return Promise.reject(err);
    });
};
