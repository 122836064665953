import {
  Button,
  Card,
  CardContent,
  Divider,
  MenuItem,
  Paper,
  Select,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { callPostSubscribe } from "../../axios/v2/callsPayment";
import { RootState } from "../../store";
import mapDateGetMonth from "../../utility/functions/mapDateGetMonth";

import { paymentGETInvoiceListByOrgId } from "../../store/reducers/reducerPayment";

const ComponentInvoiceList = () => {
  const [changePayment, setChangePayment] = React.useState(false);
  const [selectPaymentTier, setSelectPaymentTier] = React.useState<
    "FREE1" | "PAID1" | "PAID2"
  >("FREE1");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);
  const [showModalChangeSubscription, setShowModalChangeSubscription] =
    useState(false);

  const currentTier = localStorage.getItem("ut-planTier");

  const loggedInUserRole = useSelector(
    (state: RootState) => state.user.userData.role
  );

  const paymentInfo = useSelector(
    (state: RootState) => state.payment.plan_info
  );

  const orgId = useSelector((state: RootState) => state.org.org_id);

  const appLoading = useSelector(
    (state: RootState) => state.payment.appLoading
  );

  const invoiceList = useSelector(
    (state: RootState) => state.payment.payment_history
  );

  useEffect(() => {
    if (orgId) {
      dispatch(paymentGETInvoiceListByOrgId(orgId));
    }
  }, [orgId]);

  /** If apploading is true and invoiceList is null, means that invoice is being retrieved */
  const isInvoiceBeingRetrieved = appLoading && !invoiceList;

  /** Due date of the next invoice. */
  const [dueDate, setDueDate] = useState<Date | null>(null);

  /** Email where invoices are sent to. Default is the org owner's email. */
  const [invoiceEmail, setInvoiceEmail] = useState<string | null>(null);

  /** State to manage invoice amount details */
  const [invoiceAmount, setInvoiceAmount] = useState<number | null>(null);

  /** State to manage current org plan details */
  const [orgPlan, setOrgPlan] = useState<string | null>(null);

  return (
    <>
      <div className="pageHeader">Invoices</div>
      <div className="pageSettings">
        {
          !isInvoiceBeingRetrieved && invoiceList && invoiceList.length > 0 ? (
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Date</TableCell>
                    <TableCell align="left">Invoice Number</TableCell>
                    <TableCell align="left">Description</TableCell>
                    <TableCell align="right">Invoice Total</TableCell>
                    <TableCell align="right">Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {invoiceList.map((invoice, index) => {
                    console.log(invoice);
                    const paymentDate = new Date(invoice.payment_date);
                    return (
                      <TableRow
                        key={invoice.invoice_number}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {invoice.payment_date
                            ? `${paymentDate.getDate()} ${
                                mapDateGetMonth[paymentDate.getMonth()]
                              }, ${paymentDate.getFullYear()}`
                            : ""}
                        </TableCell>
                        <TableCell align="left">
                          {invoice.invoice_number}
                        </TableCell>
                        <TableCell align="left">
                          {invoice.description}
                        </TableCell>
                        <TableCell align="right">
                          {invoice.price
                            ? `$${invoice.price.toLocaleString("en-US")}`
                            : null}
                        </TableCell>
                        <TableCell align="right">
                          <>
                            <div>
                              {invoice.status === "complete"
                                ? "Paid"
                                : invoice.status.charAt(0).toUpperCase() +
                                  invoice.status.slice(1)}
                            </div>
                            {invoice.pdf_invoice_url && (
                              <a
                                href={invoice.pdf_invoice_url}
                                target="_blank"
                                rel="noopener"
                              >
                                View Invoice
                              </a>
                            )}
                          </>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <>
              <p className="pageIndicatorMsg l">No invoices found.</p>
            </>
          )
          // invoiceList.map((invoice, index) => {
          //   console.log(invoice);
          //   return <div>invoice item</div>;
          // })
        }

        {isInvoiceBeingRetrieved && (
          <>
            <Skeleton
              variant="rectangular"
              width={"auto"}
              height={30}
              sx={{ bgcolor: "#e5e5e5" }}
            />
            <Skeleton
              variant="rectangular"
              width={"auto"}
              height={30}
              sx={{ bgcolor: "#e5e5e5" }}
            />
            <Skeleton
              variant="rectangular"
              width={"auto"}
              height={30}
              sx={{ bgcolor: "#e5e5e5" }}
            />
          </>
        )}
      </div>
    </>
  );
};

export default ComponentInvoiceList;
