import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  DELETEBulkImages,
  DELETEHardDeleteSingleImage,
  DELETESingleImage,
  getImageListFromPresign,
  GETSingleImageCall,
  POSTGetListImg,
  POSTUploadImage,
  POSTUploadImagePresignedURL,
} from "../../axios/v2/callsMedia";

export interface DataUploadImg {
  /** Name of the file */
  name: string;
  /** Type of media */
  contentType: string;
  /** The file itself */
  imageFile: File;
  /** The org id that the file belongs to */
  orgId: string;
  /** THe file size, max is 30MB */
  size: string;
  /** THe email of the user who uploaded this file */
  email: string;
  /** The cognito id of the user who uploaded this file */
  cognitoId: string;
}

export interface PayloadUploadImg {
  imageId: string;
  s3PutObjectUrl: string;
}

export interface ResponseUploadImg {
  success: boolean;
  msg: string;
  payload: PayloadUploadImg;
}
interface MediaObjectList {
  name: string;
  mediaId: string;
  contentType: string;
  owner: string;
  ownerEmail: string;
  presignedUrl: string;
  dateuploaded: string;
  datemodified: string;
}

interface PayloadGetList {
  mediaObjectList: MediaObjectList[];
}
export interface ResponseGetList {
  msg: string;
  payload: PayloadGetList;
}

export interface ResponseGetSingleImage {
  msg: string;
  success: boolean;
  payload: {
    mediaObject: MediaObjectList;
  };
}
export interface DeleteImageResponse {
  msg: string;
  success: boolean;
  err: [
    walkthrough_id: {
      description: string;
      type: string;
      example: string;
    },
    building_blocks: {
      description: string;
      type: string;
      example: string;
    },
    walkthrough_name: {
      description: string;
      type: string;
      example: string;
    }
  ];
}

/** Draft Walkthroughs that are using a media id */
interface WalkthroughUseDoc {
  walkthrough_id: string;
  building_blocks: string[];
  walkthrough_name: string;
  _id: string;
  id: string;
}

/** Published Walkthroughs that are using a media id */
interface PublishedWalkthroughUseDoc {
  walkthrough_id: string;
  published_walkthrough_id: string;
  published_walkthrough_name: string;
}
/**Reduce thunk to upload image */
export const uploadImage = createAsyncThunk(
  "media/uploadImage",
  async (data: DataUploadImg, thunkAPI) => {
    const { name, contentType, cognitoId, email, imageFile, orgId, size } =
      data;
    try {
      /** Retrieve PresignedURL */
      const response = await POSTUploadImage({
        name,
        contentType,
        cognitoId,
        email,
        orgId,
        size,
      });
      if (response.data.payload) {
        const url = response.data.payload.s3PutObjectUrl;
        /** Use PresignedURL to upload image */
        const res = await POSTUploadImagePresignedURL(
          imageFile,
          url,
          contentType
        );
        if (res.ok) {
          return "uploadSuccess";
        }
      } else {
        throw response;
      }
    } catch (err: any) {
      console.log(err);
      if (err.response.data) {
        return thunkAPI.rejectWithValue(err.response.data);
      } else {
        return thunkAPI.rejectWithValue({
          msg: "Server error",
          success: false,
        });
      }
    }
  }
);

export const getImageList = createAsyncThunk(
  "media/getList",
  async (
    imgData: {
      page: number;
      limit: number;
      cognitoId: string;
      sortBy: "name" | "dateCreated";
      sort: number;
      name: string;
    },
    thunkAPI
  ) => {
    try {
      /**Get presigned url */
      const result = await POSTGetListImg(imgData);
      if (result.data.payload.mediaObjectList.length > 0) {
        /**The presigned url */
        const imgObjectList = result.data.payload.mediaObjectList;

        const data = await Promise.all(
          imgObjectList.map(async (imageMetaData) => {
            /**Get the image from presigned url */
            const imgFile = await getImageListFromPresign(
              imageMetaData.presignedUrl
            );

            const processedData = {
              /**Convert the image to URL  */
              img_url: URL.createObjectURL(imgFile),
              media_id: imageMetaData.mediaId,
              img_name: imageMetaData.name,
              owner_email: imageMetaData.ownerEmail,
              date_uploaded: imageMetaData.dateuploaded,
              date_modified: imageMetaData.datemodified,
            };
            return processedData;
          })
        );
        return data;
      } else {
        throw result;
      }
    } catch (error) {
      console.log(error);
    }
  }
);

export const GetSingleImage = createAsyncThunk(
  "media/getSingleImg",
  async (mediaId: string, thunkAPI) => {
    try {
      /**Get the presigned url */
      const response = await GETSingleImageCall(mediaId);
      if (response.data.payload.mediaObject) {
        const imageData = response.data.payload.mediaObject;
        /**Get the image from presigned url */
        const imgFile = await getImageListFromPresign(imageData.presignedUrl);

        /**Data is Image informations */
        const data: DetailedImgObj = {
          img_name: imageData.name,
          /**Convert the image to URL  */
          img_url: URL.createObjectURL(imgFile),
          media_id: imageData.mediaId,
          owner_email: imageData.ownerEmail,
          date_uploaded: imageData.dateuploaded,
          date_modified: imageData.datemodified,
        };
        return data;
      }
    } catch (error) {
      console.log(error);
    }
  }
);

export const getImageListScroll = createAsyncThunk(
  "media/getImageListScroll",
  async (
    imgData: {
      items: number;
      cognitoId: string;
      sortBy: "name" | "dateCreated";
      sort: number;
      name: string;
      limit: number;
    },
    thunkApi
  ) => {
    /**Default item is 6 list of images when the page first time showed */
    const defaultItemList = imgData.limit;

    const totalImages = defaultItemList + imgData.items;
    const pageNumber = totalImages / defaultItemList;

    /**Image params is parameters to get the presigned url */
    const imgParams = {
      /**Cognito id */
      cognitoId: imgData.cognitoId,
      /**limit is how many images we want to get from presigened url. limit increase by 6
       * everytime intersection observe triggered
       */
      limit: imgData.limit,
      page: pageNumber,
      sortBy: imgData.sortBy,
      sort: imgData.sort,
      name: imgData.name,
    };
    /**get presigned url */
    const result = await POSTGetListImg(imgParams);
    if (result.data.payload.mediaObjectList.length > 0) {
      /**Presigned url list */
      const resultListImage = result.data.payload.mediaObjectList;
      const getState = thunkApi.getState();
      /**state image list */
      //@ts-ignore
      const imageListState = getState.media.imageList;

      /**state for imageListScroll */
      //@ts-ignore
      const imageListScrollState = getState.media.imageListScroll;

      /**Combine both of the states */
      const currentListImage = [...imageListState, ...imageListScrollState];

      /**Filter the combined state to find the the differences. return the item that diffrent */
      const newItems: any = resultListImage.filter(
        (x) =>
          !currentListImage
            .map((y) => {
              return y.media_id;
            })
            .includes(x.mediaId)
      );
      /**Get presigned url */
      const data: any = await Promise.all(
        newItems.map(async (d: any) => {
          const imgFile = await getImageListFromPresign(d.presignedUrl);
          const processedData = {
            img_url: URL.createObjectURL(imgFile),
            media_id: d.mediaId,
            img_name: d.name,
            owner_email: d.ownerEmail,
            date_modified: d.datemodified,
            date_uploaded: d.dateuploaded,
          };
          return processedData;
        })
      );

      return data;
    } else {
      thunkApi.dispatch(removeObserver());
    }
  }
);

export const deleteImage = createAsyncThunk(
  "media/delete-image",
  async (mediaId: string, thunkAPI) => {
    try {
      const response = await DELETESingleImage(mediaId);
      if (response.data.msg === "Image sucessfully deleted!") {
        return "deleteImgSuccess";
      }
      throw response;
    } catch (error: any) {
      console.log("what is error", error.message);
      console.log("what is error status", error.payload);
      console.log("what is error data", error.response.data.msg);
      console.log("what is error data", error.response.data.err);
      const errorResponse = error.response.data;

      const errorResponseMsg = errorResponse ? errorResponse.msg : "";

      console.log("errorResponse", errorResponseMsg);

      if (errorResponse && errorResponseMsg === "Image still in use!") {
        console.log("yes here");
        const errorResponseData = errorResponse.err;
        console.log("errorResponseData", errorResponseData);

        const walkthroughNamesList = errorResponseData as {
          draft: WalkthroughUseDoc[];
          published: PublishedWalkthroughUseDoc[];
        };

        const draftWalkthroughNamesList = walkthroughNamesList.draft;

        const publishedWalkthroughNamesList = walkthroughNamesList.published;

        console.log("where");

        // var names: string = walkthroughNamesList.reduce((a, b) => {
        //   console.log("a", a);
        //   console.log("b", b);
        //   //@ts-ignore
        //   return a.walkthrough_name + ", " + b.walkthrough_name;
        // }, "");

        let draftNameList = [] as string[];

        draftWalkthroughNamesList.forEach((walkthrough) => {
          draftNameList.push(walkthrough.walkthrough_name);
        });

        let publishedNameList = [] as string[];

        publishedWalkthroughNamesList.forEach((walkthrough) => {
          publishedNameList.push(walkthrough.published_walkthrough_name);
        });

        return thunkAPI.rejectWithValue({
          draft: draftNameList,
          published: publishedNameList,
        });
      } else {
        return [error.data.msg];
      }
    }
  }
);

export const confirmHardDeleteImage = createAsyncThunk(
  "media/confirm-delete-image",
  async (mediaId: string, thunkAPI) => {
    try {
      const response = await DELETEHardDeleteSingleImage(mediaId);
      if (response.data.msg === "Image sucessfully deleted!") {
        return "deleteImgSuccess";
      }
      throw response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const bulkDeleteImage = createAsyncThunk(
  "media/bulk-delete-image",
  async (mediaId: string[], thunkAPI) => {
    try {
      const response = await DELETEBulkImages(mediaId);
      if (response.data.msg === "Bulk deletion successful!") {
        const usedImage = response.data.payload.filter(
          (data) => data.msg === "image still in use!"
        );
        const payload = {
          msg: "deleteImgSuccess",
          imageInUse: usedImage,
        };
        return payload;
      }
      throw response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

interface ImageObj {
  /** Unique id of the image, used to fetch the image from Backend */
  media_id: string;
  /** This is the actual image url that is hosted/given permission to retrieve */
  img_url: string;
  /** image name */
  img_name: string;
}

export interface DetailedImgObj extends ImageObj {
  /** This is the image owner's email */
  owner_email: string;
  /** Represent the date the image was uploaded. */
  date_uploaded: string;
  /** Represent the date the image was edited. */
  date_modified: string;
}

const initialState = {
  /**Loading state */
  appLoading: false,
  /**first 12 image list state */
  imageList: null as null | DetailedImgObj[],
  /**single image state */
  image: null as null | DetailedImgObj,
  /**loading state for single image */
  singleImgLoading: false,
  /**state for image list infinity scroll */
  imageListScroll: [] as DetailedImgObj[] | [],
  /**loading state for infinity scroll */
  imageListScrollLoading: false,
  msg: null as null | "uploadSuccess" | "deleteImgSuccess",
  /**Show delete image msg when failed to delete an image */
  deleteImageMsg: null as {
    draft: WalkthroughUseDoc[];
    published: PublishedWalkthroughUseDoc[];
  } | null,
  /**Show list of image in use when deleting images */
  imageInUse: null as
    | {
        name: string;
        mediaId: string;
        msg: string;
        walkthroughDetails: {
          walkthrough_id: string;
          building_blocks: string;
          walkthrough_name: string;
        };
      }[]
    | null,
  alert: {
    active: false,
    type: "",
    message: "",
    autoHideDuration: 6000,
  },
  /**state for observer if observer false stop the infinity scroll */
  observer: true,
};

const mediaSlice = createSlice({
  name: "media",
  initialState: initialState,
  reducers: {
    resetAlert: (state) => {
      state.alert = {
        active: false,
        type: "",
        message: "",
        autoHideDuration: 6000,
      };
    },
    resetMsg: (state) => {
      state.msg = null;
      state.deleteImageMsg = null;
    },
    resetSingleImage: (state) => {
      state.image = null;
    },
    removeObserver: (state) => {
      state.observer = false;
    },
    resetImgList: (state) => {
      state.imageList = null;
      state.imageListScroll = [];
      state.observer = true;
    },
    resetUsedImage: (state) => {
      state.imageInUse = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(uploadImage.pending, (state) => {
        state.appLoading = true;
      })

      .addCase(uploadImage.fulfilled, (state, action) => {
        state.appLoading = false;
        state.msg = action.payload as "uploadSuccess";
        state.alert = {
          active: true,
          type: "success",
          message: "Image upload successful",
          autoHideDuration: 6000,
        };
      })
      .addCase(uploadImage.rejected, (state, action) => {
        const { msg } = action.payload as { msg: string };
        state.alert = {
          active: true,
          type: "error",
          message: msg,
          autoHideDuration: 6000,
        };
        state.appLoading = false;
      })
      .addCase(getImageList.pending, (state) => {
        state.appLoading = true;
      })
      .addCase(getImageList.fulfilled, (state, action) => {
        state.appLoading = false;
        state.imageList = action.payload!;
      })
      .addCase(getImageList.rejected, (state) => {
        state.appLoading = false;
      })
      .addCase(GetSingleImage.pending, (state) => {
        state.singleImgLoading = true;
      })
      .addCase(GetSingleImage.fulfilled, (state, action) => {
        state.singleImgLoading = false;
        state.image = action.payload!;
      })
      .addCase(GetSingleImage.rejected, (state) => {
        state.singleImgLoading = false;
      })
      .addCase(getImageListScroll.pending, (state) => {
        state.imageListScrollLoading = true;
      })
      .addCase(
        getImageListScroll.fulfilled,
        (state, action: PayloadAction<ImageObj[]>) => {
          const { payload } = action;
          if (payload !== undefined) {
            //return non duplicate list
            const nonDuplicateItems = payload.filter(
              (x) =>
                !state.imageListScroll
                  .map((y) => y.media_id)
                  .includes(x.media_id)
            );
            //@ts-ignore
            state.imageListScroll = [
              ...state.imageListScroll,
              ...nonDuplicateItems,
            ];
          }
          state.imageListScrollLoading = false;
        }
      )
      .addCase(getImageListScroll.rejected, (state) => {
        state.imageListScrollLoading = false;
      })
      .addCase(deleteImage.pending, (state) => {
        state.appLoading = true;
      })
      .addCase(deleteImage.fulfilled, (state, action) => {
        state.appLoading = false;
        state.msg = action.payload! as "deleteImgSuccess";
        state.alert = {
          active: true,
          type: "success",
          message: "Delete Image successful",
          autoHideDuration: 6000,
        };
      })
      .addCase(deleteImage.rejected, (state, action) => {
        state.appLoading = false;
        //@ts-ignore
        state.deleteImageMsg = action.payload;
      })
      /** Confirm hard Delete */
      .addCase(confirmHardDeleteImage.pending, (state) => {
        state.appLoading = true;
      })
      .addCase(confirmHardDeleteImage.fulfilled, (state, action) => {
        state.appLoading = false;
        state.msg = action.payload! as "deleteImgSuccess";
        state.alert = {
          active: true,
          type: "success",
          message: "Delete Image successful",
          autoHideDuration: 6000,
        };
      })
      .addCase(confirmHardDeleteImage.rejected, (state, action) => {
        state.appLoading = false;
      })
      .addCase(bulkDeleteImage.pending, (state) => {
        state.appLoading = true;
      })
      .addCase(bulkDeleteImage.fulfilled, (state, action) => {
        console.log(action.payload, "action payload");
        state.appLoading = false;
        state.msg = action.payload.msg! as "deleteImgSuccess";
        state.imageInUse = action.payload.imageInUse;
        state.alert = {
          active: true,
          type: "success",
          message: "Delete Image successful",
          autoHideDuration: 6000,
        };
      })
      .addCase(bulkDeleteImage.rejected, (state, action) => {
        state.appLoading = false;
      });
  },
});

const { reducer, actions } = mediaSlice;
export const {
  resetAlert,
  resetMsg,
  resetSingleImage,
  removeObserver,
  resetImgList,
  resetUsedImage,
} = actions;
const mediaReducer = reducer;
export default mediaReducer;
