import React from "react";
import { UsertipHorizontalIcon } from "usertip-component-library";
import PageFooter from "../components/03-organisms/05-form/PageFooter";

const PageRegistrationCompleted = () => {
  return (
    <div className="w-screen h-min min-h-screen relative flex items-center justify-center">
      <div className="absolute top-2 left-20">
        <UsertipHorizontalIcon />
      </div>
      <div className="flex flex-col gap-20 p-8 my-4">
        <div className="flex flex-col items-center gap-[0.625em]">
          <h1 className="text-5xl font-medium text-default-neutral-100">
            Your Account Has Been Created
          </h1>
          <p className="text-lg font-medium text-default-neutral-60">
            You can login to your account{" "}
            <a
              href="/login"
              className="text-default-primary font-bold hover:no-underline hover:text-default-primary-light visited:text-default-primary"
            >
              here
            </a>
            .
          </p>
        </div>
      </div>
      <PageFooter />
    </div>
  );
};

export default PageRegistrationCompleted;
