import React, { useEffect, useState } from "react";
import ContainerBB from "./ContainerBB";
import {
  computePos,
  getInputPosition,
} from "../../_functions/SpotlightTooltipFunction";
import { SpotlightTooltipThemeType } from "../../../interfaces/interfaceTheme";

interface Props {
  style: SpotlightTooltipThemeType;
  target: any;
  container: any;
}

const SpotlightBB = ({ style, target: refTarget, container }: Props) => {
  const backgroundColor =
    style != undefined && style != null ? style.backgroundColor : null;
  // const fade = style != undefined && style != null ? style.fade : null;
  const fade = "";
  const spacing = style != undefined && style != null ? style.spacing : null;
  const padding = style != undefined && style != null ? style.padding : null;
  const ttipWidth = style != undefined && style != null ? style.width : null;
  const ttipHeight = style != undefined && style != null ? style.height : null;
  const borderRadius =
    style != undefined && style != null ? style.borderRadius : null;
  const ttipPosition = style!.position;

  // const scrollAnimation =
  //   style != undefined && style != null
  //     ? style.scrollAnimation
  //     : "ut-slideout-bottom";
  // const arrowPosition = style!.arrowPosition;

  const mainWidth =
    //@ts-ignore
    ttipWidth?.unit === "auto" || ttipWidth === null || ttipWidth === ""
      ? "auto"
      : `${ttipWidth?.value}${ttipWidth?.unit}`;
  const mainHeight =
    //@ts-ignore
    ttipHeight?.unit === "auto" || ttipHeight === null || ttipHeight === ""
      ? "auto"
      : `${ttipHeight?.value}${ttipHeight?.unit}`;
  const mainPadding = padding !== null ? padding + "px" : "12px";
  const mainBorderRadius =
    borderRadius !== null && borderRadius !== undefined
      ? borderRadius + "px"
      : "6px";

  const z_index = 2;

  //spotlight states
  const [topPos, setTopPos] = useState(0);
  const [leftPos, setLeftPos] = useState(0);
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);

  //tooltip
  const [tooltipLeft, setTooltipLeft] = useState("");
  const [tooltipRight, setTooltipRight] = useState("");
  const [tooltipTop, setTooltipTop] = useState("");
  const [tooltipBottom, setTooltipBottom] = useState("");
  const [tooltipTransform, setTooltipTransform] = useState("");
  const [tooltipClass, setTooltipClass] = useState(
    "ut--spotlight--bottom-left"
  );

  // const originalScrollY = window.scrollY;

  useEffect(() => {
    const divElement = refTarget.current;
    const contElement = container.current;

    if (divElement) {
      const originalBounds = divElement
        ? divElement.getBoundingClientRect()
        : {};

      const originalScrollY = window.scrollY;

      const settings = computePos(
        divElement,
        "spotlight",
        //@ts-ignore, should be correct as we make sure element is not null
        originalBounds,
        originalScrollY
      );

      const contBounds = contElement.getBoundingClientRect();

      const newLeftPos = originalBounds.left - contBounds.left;
      const newTopPost = originalBounds.top - contBounds.top;

      setTopPos(newTopPost);
      setLeftPos(newLeftPos);
      setHeight(divElement.offsetHeight);
      setWidth(divElement.offsetWidth);
      console.log(ttipPosition);

      let config = getInputPosition(settings, ttipPosition, spacing!);
      setTooltipLeft(config.left);
      setTooltipRight(config.right);
      setTooltipTop(config.top);
      setTooltipBottom(config.bottom);

      setTooltipTransform(config.transform);
      setTooltipClass(config.className);
    }
  }, [refTarget, container, style.position]);

  useEffect(() => {
    const debouncedHandleResize = () => {
      const divElement = refTarget.current;
      const contElement = container.current;

      if (divElement) {
        const originalBounds = divElement.getBoundingClientRect();
        const contBounds = contElement.getBoundingClientRect();

        const newLeftPos = originalBounds.left - contBounds.left;
        const newTopPost = originalBounds.top - contBounds.top;

        setTopPos(newTopPost);
        setLeftPos(newLeftPos);
      }
    };

    window.addEventListener("resize", debouncedHandleResize);

    return () => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
  }, []);

  return (
    <div
      style={{
        display: "flex",
        position: "absolute",
      }}
      className="w-full h-full"
    >
      <div
        className={`ut--spotlighted`}
        style={{
          position: "absolute",
          backgroundColor: "transparent",
          borderRadius: "3px",
          top: topPos + "px",
          left: leftPos + "px",
          height: height + "px",
          width: width + "px",
          boxShadow: fade
            ? "0 0 0 9999px " + fade
            : "0 0 0 9999px rgba(0, 0, 0, 0.6)",
          zIndex: z_index,
        }}
      >
        <div
          style={{
            backgroundColor: backgroundColor ? backgroundColor : "#FFFFFF",
            padding: mainPadding,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "space-between",
            borderRadius: mainBorderRadius,
            position: "absolute",
            left: tooltipLeft,
            right: tooltipRight,
            top: tooltipTop,
            bottom: tooltipBottom,
            transform: tooltipTransform,
            border: backgroundColor
              ? "1px solid " + backgroundColor
              : "1px solid #FFFFFF",
            width: mainWidth,
            height: mainHeight,
            pointerEvents: "all",
          }}
          className={"ut--spotlight ut--spotlight-main " + tooltipClass}
        >
          <ContainerBB buildingBlockStyle={style.buildingBlockStyle} />
        </div>
      </div>
    </div>
  );
};

export default SpotlightBB;
