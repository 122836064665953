import React, { useEffect, useState } from "react";
import Button from "../../UI/Button/Button";
import InputForm from "../../UI/Form/FormInput";
import ReportIcon from "@mui/icons-material/Report";
import { validateEmailFormat } from "../../../utility/functions/validation";
import { authForgotPassword } from "../../../store/reducers/reducerAuth";
import { useDispatch } from "react-redux";
import { InputField } from "usertip-component-library";
import { Button as UsertipButton } from "usertip-component-library";
import { useNavigate } from "react-router-dom";

interface Props {
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  AbortForgotPasswordHandler: () => void;
}

const EmailForm = ({ email, setEmail, AbortForgotPasswordHandler }: Props) => {
  const [isEmailValid, setIsEmailValid] = useState<boolean | null>(null);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    if (validateEmailFormat(email)) {
      setIsEmailValid(true);
    } else if (email.length === 0) {
      setIsEmailValid(null);
    } else {
      setIsEmailValid(false);
    }
  }, [email]);

  const onSubmitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(authForgotPassword(email));
  };

  return (
    <div className="flex justify-center">
      <form
        action="submit"
        onSubmit={onSubmitHandler}
        className="flex flex-col gap-4"
      >
        <InputField
          variant="full"
          helperText="Enter your email associated with your account to reset your password"
          placeholder="example@user-tip.com"
          label="Email"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          error={isEmailValid === null ? undefined : !isEmailValid}
          errorText="Please input valid email format"
        />
        <UsertipButton
          color="primary"
          size="fluid"
          variant="primary"
          text="Confirm"
          disabled={!isEmailValid}
        />
        <UsertipButton
          color="secondary"
          size="fluid"
          variant="primary"
          text="Cancel"
          onClick={() => {
            AbortForgotPasswordHandler();
            navigate("/");
          }}
        />
      </form>
    </div>
  );
};

export default EmailForm;
