import React from "react";
import { useSelector } from "react-redux";
import { Button } from "usertip-component-library";
import { UserDataForInvitationToOrg } from "../interfaces/interface Organization";
import { RootState } from "../store";

const PageInviteUserToDifferentOrgAsAdmin = ({
  jwtData,
  onSubmitOrg,
}: {
  jwtData: UserDataForInvitationToOrg;
  onSubmitOrg: () => void;
}) => {
  const currentOrgName = useSelector((state: RootState) => state.org.name);

  return (
    <>
      <section className="section-forgotpwd m-auto">
        <div className="m-auto w-full">
          <div className="flex flex-col justify-center items-center mb-14">
            <h2 className="text-heading-m text-center text-default-neutral-100 mb-3">
              You're invited to join {jwtData.invitedOrgName} as a{" "}
              {jwtData.invitedRole}
            </h2>
            <p className="text-default-error mt-3">
              WARNING: YOU ARE ALREADY PART OF {currentOrgName}
            </p>
            <p className="text-default-error mt-3">
              CLICKING JOIN WILL REMOVE YOU FROM {currentOrgName} AS{" "}
              {jwtData.existingRole?.toUpperCase()}
            </p>
            <div className="mt-14">
              <Button
                color="primary"
                size="medium"
                text="Confirm"
                variant="primary"
                onClick={() => onSubmitOrg()}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default PageInviteUserToDifferentOrgAsAdmin;
