import attachHTTPRequestHeaders from "../../utility/functions/attachHTTPRequestHeaders";
import { api } from "./_axios";

/*
    ===================================================================    
    API 2.0: API-Calls To Server
    ===================================================================
    1. api_p1 is default for all API 2.0 calls
    2. api_p2 is now only for /signIn route  
    ===================================================================    
    CALLS-WALKTHROUGH PERMISSIONS API
    ===================================================================
*/

export const callGETAllPermissionsFromSingleWalkthrough = async (
  walkthrough_id
) => {
  const config = attachHTTPRequestHeaders(true);

  return api
    .get(`/walkthrough/permission/all/${walkthrough_id}`, config)
    .then((res) => res)
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const callPOSTAddOrRemoveIndividualPermissionsFromSingleWalkthrough =
  async (walkthrough_id, users) => {
    const config = attachHTTPRequestHeaders(true);
    const data = {
      resource_id: walkthrough_id,
      resource_type: "walkthrough",
      users: users,
    };

    return api
      .post(`walkthrough/permission/viewing/individuals/update`, data, config)
      .then((res) => res)
      .catch((err) => {
        return Promise.reject(err);
      });
  };

export const callDELETEOneIndividualPermissionsFromSingleWalkthrough = async (
  walkthrough_permission_id
) => {
  const config = attachHTTPRequestHeaders(true);

  return api
    .delete(
      `walkthrough/permission/viewing/individuals/${walkthrough_permission_id}`,
      config
    )
    .then((res) => res)
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const callDELETEOneTeamPermissionsFromSingleWalkthrough = async (
  walkthrough_permission_id
) => {
  const config = attachHTTPRequestHeaders(true);

  return api
    .delete(
      `walkthrough/permission/viewing/team/${walkthrough_permission_id}`,
      config
    )
    .then((res) => res)
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const callPOSTAddOrRemoveTeamPermissionsFromSingleWalkthrough = async (
  walkthrough_id,
  teams
) => {
  const config = attachHTTPRequestHeaders(true);
  const data = {
    resource_id: walkthrough_id,
    resource_type: "walkthrough",
    teams: teams,
  };

  console.log(
    "callPOSTAddOrRemoveTeamPermissionsFromSingleWalkthrough data",
    data
  );

  return api
    .post(`walkthrough/permission/viewing/teams/update`, data, config)
    .then((res) => res)
    .catch((err) => {
      return Promise.reject(err);
    });
};
