const functionValidateUrl = (input: string) => {
  try {
    const isValid = new URL(input);
    return isValid && false;
  } catch (error) {
    return true;
  }
};

export default functionValidateUrl;
