import React, { useCallback } from "react";

import { Button } from "@mui/material";
import { Button as UsertipButton } from "usertip-component-library";

import SimCardDownload from "@mui/icons-material/SimCardDownload";

import { validateEmailFormat } from "../utility/functions/validation";

import { useDropzone } from "react-dropzone";

const ComponentAddUsersMassImport = ({
  setUsersEmails,

  handleHideModal,
  handleBackToAddUsersClick,

  setModalPageState,
  setShowAlertSnackbar,
  setShowAlertSnackbarMsg,

  downloadCSVFile,
}) => {
  // Define max row number cap
  const maxLimitRowCap = 300;

  const onDropFile = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((file) => {
        const reader = new FileReader();

        // Throws errors if File read fails
        reader.onabort = () => console.log("File reading was aborted");
        reader.onerror = () => console.log("File reading has failed");

        reader.onload = () => {
          // Reads file contents if load properly
          const data = reader.result;

          if (data) {
            // Split the dataset into an array for processing
            const dataArray = data.split("\n");
            // Checks if Total rows is more than "maxLimitRowCap" + "headerRow"
            if (dataArray.length <= maxLimitRowCap + 1) {
              // Removing special characters created via Excel-CSV creation.
              const colHeader = dataArray[0].split("ï»¿").join("");

              let isEmailsValidated = true;
              // Check if first row is "email" to verify input is correct
              if (colHeader === "email\r") {
                // Checks for duplicated entries via Set function
                if (new Set(dataArray).size !== dataArray.length) {
                  // Throw out Alert Snackbar error if File email rows contain duplicates
                  setShowAlertSnackbarMsg(
                    "Email duplicates detected in file. Please remove them & try again."
                  );
                  setShowAlertSnackbar(true);
                }
                // If Duplicates do not exist, continue on with processing
                else {
                  // Do a pre-validation loop
                  for (let i = 0; i < dataArray.length; i++) {
                    // Skip the header line
                    if (i !== 0) {
                      // Remove row tag "\r"
                      const dataLine = dataArray[i].split("\r").join("");
                      // Only if "dataLine" is not empty or does not contain just an "empty space", then proceed to check.
                      // Skips "dataLine" that is empty or contains just an "empty space"
                      if (dataLine.trim().length !== 0) {
                        // Send each email to validation process
                        const isValidated = validateEmailFormat(
                          dataLine.trim()
                        );
                        // If just one entry is not a valid email, reject entire list
                        if (!isValidated) {
                          isEmailsValidated = false;
                          break;
                        }
                      }
                    }
                  }

                  if (!isEmailsValidated) {
                    // If any one email within the list doesn't validate, reject entire file
                    setShowAlertSnackbarMsg(
                      "Wrong file contents format uploaded. Please try again."
                    );
                    setShowAlertSnackbar(true);
                  } else {
                    let preloadCSVUsersEmails = [];
                    for (let i = 0; i < dataArray.length; i++) {
                      if (i !== 0) {
                        // Remove row tag "\r"
                        const dataEmailLine = dataArray[i].split("\r").join("");

                        // Only allow "dataEmailLine" that is not empty or does not contain just an "empty space".
                        if (dataEmailLine.trim().length !== 0) {
                          // Add new email to array list "preloadCSVUsersEmails" if verified email is of valid format
                          // Enable the ability to add to on top of existing list of emails already added to chip array
                          preloadCSVUsersEmails.push({
                            key: i - 1,
                            userEmail: dataEmailLine,
                          });
                        }
                      }
                    }

                    // Push "preloadCSVUsersEmails" into "usersEmails"
                    setUsersEmails(preloadCSVUsersEmails);

                    // Move back to "AddUsers" ModalPageState
                    setModalPageState("AddUsers");
                  }
                }
              } else {
                // Throw out Alert Snackbar error if File column header format is wrong. Correct version: "email"
                setShowAlertSnackbarMsg(
                  "Wrong file column header format uploaded. Please try again."
                );
                setShowAlertSnackbar(true);
              }
            } else {
              // Throw out Alert Snackbar error if number of email rows within file exceeds "maxLimitRowCap"
              setShowAlertSnackbarMsg(
                `Number of email rows exceed ${maxLimitRowCap}. Please try again.`
              );
              setShowAlertSnackbar(true);
            }
          }
        };

        reader.readAsBinaryString(file);
      });
    },
    [
      setModalPageState,
      setShowAlertSnackbar,
      setShowAlertSnackbarMsg,
      setUsersEmails,
    ]
  );

  // react-dropzone
  // https://react-dropzone.js.org/#section-components
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: "text/csv, .csv, application/vnd.ms-excel",
    maxFiles: 1,
    onDrop: onDropFile,
  });

  return (
    <>
      <div className="modalHeader">Mass Import</div>

      <div className="modalSubHeader">
        Download our "Mass Upload Template" to fill up your bulk email entries
        and upload your entire list in one sweep.
      </div>

      <div className="my-3">
        <UsertipButton
          onClick={downloadCSVFile}
          color="primary"
          size="fluid"
          text="Download Template"
          variant="outlined"
          icon={<SimCardDownload />}
          iconLeft={true}
        />
      </div>

      <div
        {...getRootProps({
          className: `modalDropzone flex flex-col justify-center items-center bg-gray-100 text-gray-400 mt-3 mb-8 rounded-md border-2 border-dashed ${
            isDragAccept
              ? "border-green-500"
              : isDragReject
              ? "border-red-500"
              : isDragActive
              ? "border-blue-500"
              : "border-gray-300"
          }`,
        })}
      >
        <input {...getInputProps()} />
        <div className="text-sm text-center px-20 py-16">
          {isDragAccept && (
            <>
              <div className="pb-3">
                Seems like you have the right *.csv file format.
              </div>
            </>
          )}
          {isDragReject && (
            <>
              <div className="pb-3">
                The file format seems to be invalid for upload.
              </div>
            </>
          )}
          {!isDragActive && (
            <>
              <div className="pb-3">
                Drag & drop your Mass Import Template, or click to select your
                upload
              </div>
              <div className="text-xs italic">
                (Only *.csv file format with a maximum of {maxLimitRowCap} email
                rows will be accepted.)
              </div>
            </>
          )}
        </div>
      </div>

      <div className="modalActionBtnBar">
        <UsertipButton
          color="primary"
          size="medium"
          text="Cancel"
          variant="primary"
          onClick={handleHideModal}
        />
        <UsertipButton
          onClick={handleBackToAddUsersClick}
          color="primary"
          size="medium"
          text="Back to Add Users"
          variant="primary"
        />
      </div>
    </>
  );
};

export default ComponentAddUsersMassImport;
