import React from "react";

import { Backdrop, CircularProgress } from "@mui/material";

const ComponentLoading = ({ show }) => {
  // Loading spinner.
  return (
    <Backdrop className="backdrop" open={show} sx={{ zIndex: 99 }}>
      <CircularProgress sx={{ zIndex: "99" }} color="inherit" />
    </Backdrop>
  );
};

export default ComponentLoading;
