import React from "react";
import RegisterForm from "../components/03-organisms/05-form/RegisterForm";
import { UsertipHorizontalIcon } from "usertip-component-library";
import PageFooter from "../components/03-organisms/05-form/PageFooter";

const PageRegistration = () => {
  return (
    <div className="h-min min-h-screen w-screen relative flex items-center justify-center">
      <div className="absolute top-2 left-20">
        <UsertipHorizontalIcon />
      </div>
      <div className="flex flex-col gap-5 p-8 my-4">
        <div className="flex flex-col items-center gap-[0.625em]">
          <h1 className="text-5xl text-center font-medium text-default-neutral-100">
            Welcome to Usertip
          </h1>
          <p className="text-base font-medium text-default-neutral-60">
            Set up an account
          </p>
        </div>
        <RegisterForm />
      </div>
      <PageFooter />
    </div>
  );
};

export default PageRegistration;
