import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  callPOSTBulkRemoveWalkthroughsFromGroupWalkthrough,
  callPOSTCreateUpdateRemoveWalkthroughsFromGroupWalkthrough,
} from "../../axios/v2/callsGroupWalkthrough";
import {
  callGETAllPermissionsFromSingleGroupWalkthrough,
  callPOSTBulkAddOrRemoveIndividualPermissionsFromSingleGroupWalkthrough,
  callPOSTBulkAddOrRemoveTeamPermissionsFromSingleGroupWalkthrough,
} from "../../axios/v2/callsGroupWalkthroughPermissions";
import {
  callDELETEOneIndividualPermissionsFromSingleWalkthrough,
  callDELETEOneTeamPermissionsFromSingleWalkthrough,
} from "../../axios/v2/callsWalkthroughPermissions";
import * as actionTypes from "../actions/_actionTypes";

//action
// Update walkthroughs to group walkthrough
export const groupWalkthroughUpdateWalkthroughsToGroupWalkthrough =
  createAsyncThunk(
    "groupWalkthrough/updateWalkthroughToGroupWalkthrough",
    async (groupWalkthroughData, thunkApi) => {
      const { walkthrough_group_id, list_of_walkthroughs } =
        groupWalkthroughData;
      try {
        const result =
          await callPOSTCreateUpdateRemoveWalkthroughsFromGroupWalkthrough(
            walkthrough_group_id,
            list_of_walkthroughs
          );
        if (result && result.data) {
          const res = {
            replyMsg: result.data.msg,
            replyPayload: result.data.payload,
          };
          return res;
        }
      } catch (err) {
        const errStatus = err.response.status; // Integer
        const errPayload = err.response.data;
        const errMsg = errPayload.msg;

        console.log(errStatus);
        console.log(errPayload);
        console.log(errMsg);

        const res = {
          alert: {
            type: "error",
            message: err.toString(),
          },
        };
        return thunkApi.rejectWithValue(res);
      }
    }
  );

// remove one individual sharing permissions from single walkthorugh
export const groupDeleteOneIndvSharingPermissionFromSingleGroupWalkthrough =
  createAsyncThunk(
    "groupWalkthrough/deleteOneIndSharingPermissionFromsSingle",
    async (walkthrough_permissions_id, thunkApi) => {
      try {
        const res =
          await callDELETEOneIndividualPermissionsFromSingleWalkthrough(
            walkthrough_permissions_id
          );
        const result = res.data.payload;
        return result;
      } catch (err) {
        const result = {
          alert: {
            type: "error",
            message: err.toString(),
          },
        };
        return thunkApi.rejectWithValue(result);
      }
    }
  );

// Remove bulk walkthroughs from group walkthrough
export const groupWalkthroughBulkRemoveWalkthroughsFromGroupWalkthrough =
  createAsyncThunk(
    "groupWalkthrough/deleteOneIndSharingPermissionFromGroup",
    async (groupWalkthroughData, thunkApi) => {
      const { walkthrough_group_id, list_of_walkthroughs } =
        groupWalkthroughData;
      try {
        const result = await callPOSTBulkRemoveWalkthroughsFromGroupWalkthrough(
          walkthrough_group_id,
          list_of_walkthroughs
        );
        const res = {
          replyMsg: result.data.msg,
        };
        return res;
      } catch (err) {
        const errStatus = err.response.status; // Integer
        const errPayload = err.response.data;
        const errMsg = errPayload.msg;

        console.log(errStatus);
        console.log(errPayload);
        console.log(errMsg);

        const res = {
          alert: {
            type: "error",
            message: err.toString(),
          },
        };
        return thunkApi.rejectWithValue(res);
      }
    }
  );

// Get all sharing permissions for single group walkthrough
export const groupWalkthroughGetAllSharingPermissionsForSingleGroupWalkthrough =
  createAsyncThunk(
    "groupWalkthrough/getAllSharingPermissionForSingleGroup",
    async (walkthrough_group_id, thunkApi) => {
      console.log("fetching permission");
      try {
        const result = await callGETAllPermissionsFromSingleGroupWalkthrough(
          walkthrough_group_id
        );
        if (result && result.data) {
          const res = {
            replyMsg: result.data.msg,
            replyPayload: result.data.payload,
          };
          return res;
        }
      } catch (err) {
        const errStatus = err.response.status; // Integer
        const errPayload = err.response.data;
        const errMsg = errPayload.msg;

        console.log(errStatus);
        console.log(errPayload);
        console.log(errMsg);

        const res = {
          alert: {
            type: "error",
            message: err.toString(),
          },
        };
        return thunkApi.rejectWithValue(res);
      }
    }
  );

// Add bulk individual sharing permissions for group walkthrough
export const groupWalkthroughAddBulkIndividualSharingPermissionsForSingleGroupWalkthrough =
  createAsyncThunk(
    "groupWalkthrough/addBulkIndividulSharingPermission",
    async (walkthroughData, thunkApi) => {
      const { walkthroughID, users } = walkthroughData;
      try {
        const result =
          await callPOSTBulkAddOrRemoveIndividualPermissionsFromSingleGroupWalkthrough(
            walkthroughID,
            users
          );
        if (result && result.data) {
          const res = {
            replyMsg: result.data.msg,
            replyPayload: result.data.payload,
          };
          return res;
        }
      } catch (err) {
        const errStatus = err.response.status; // Integer
        const errPayload = err.response.data;
        const errMsg = errPayload.msg;

        console.log(errStatus);
        console.log(errPayload);
        console.log(errMsg);

        const res = {
          alert: {
            type: "error",
            message: err.toString(),
          },
        };
        return thunkApi.rejectWithValue(res);
      }
    }
  );

// remove one team sharing permissions from single walkthorugh
export const groupDeleteOneTeamSharingPermissionFromSingleGroupWalkthrough =
  createAsyncThunk(
    "groupWalkthrough/deleteOneTeamSharingPermission",
    async (walkthrough_permissions_id, thunkApi) => {
      try {
        const res = await callDELETEOneTeamPermissionsFromSingleWalkthrough(
          walkthrough_permissions_id
        );
        const result = res.data.payload;
        return result;
      } catch (err) {
        const errStatus = err.response.status; // Integer
        const errPayload = err.response.data;
        const errMsg = errPayload.msg;

        console.log(errStatus);
        console.log(errPayload);
        console.log(errMsg);

        const res = {
          alert: {
            type: "error",
            message: err.toString(),
          },
        };

        return thunkApi.rejectWithValue(res);
      }
    }
  );

// Add bulk team sharing permissions for group walkthrough
export const groupWalkthroughAddBulkTeamSharingPermissionsForSingleGroupWalkthrough =
  createAsyncThunk(
    "groupWalkthrough/addBulkTeamSharingPermission",
    async (groupData, thunkApi) => {
      const { walkthrough_group_id, teams } = groupData;
      try {
        const result =
          await callPOSTBulkAddOrRemoveTeamPermissionsFromSingleGroupWalkthrough(
            walkthrough_group_id,
            teams
          );
        if (result && result.data) {
          const res = {
            replyMsg: result.data.msg,
            replyPayload: result.data.payload,
          };
          return res;
        }
      } catch (err) {
        const errStatus = err.response.status; // Integer
        const errPayload = err.response.data;
        const errMsg = errPayload.msg;

        console.log(errStatus);
        console.log(errPayload);
        console.log(errMsg);

        const res = {
          alert: {
            type: "error",
            message: err.toString(),
          },
        };
        return thunkApi.rejectWithValue(res);
      }
    }
  );

const groupWalkthroughState = {
  updateWalkthroughsToGroupWalkthroughMsg: "",
  updateWalkthroughsToGroupWalkthrough: {},

  removeBulkWalkthroughsFromGroupWalkthroughMsg: "",
  removeBulkWalkthroughsFromGroupWalkthrough: {},

  groupWalkthroughSharingPermissionMsg: "",
  groupWalkthroughSharingPermission: {},

  resource_id: "",
  resource_type: "",
  resource_update_datetime: "",

  appLoading: false,
  alert: {
    active: false,
    type: "",
    message: "",
    autoHideDuration: 6000,
  },
};

// reducers
const groupWalkthroughSlice = createSlice({
  name: "groupWalkthrough",
  initialState: groupWalkthroughState,
  reducers: {
    resetUpdateWalkthroughMsg(state) {
      state.updateWalkthroughsToGroupWalkthroughMsg = "";
    },
    resetRemoveBulkWalkthrough(state) {
      state.removeBulkWalkthroughsFromGroupWalkthroughMsg = "";
    },
    resetGetAllSharingPermission(state) {
      state.groupWalkthroughSharingPermissionMsg = "";
    },
    resetAlert: (state) => {
      state.alert.active = false;
      state.alert.type = "";
      state.alert.message = "";
      state.alert.autoHideDuration = 6000;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        groupWalkthroughUpdateWalkthroughsToGroupWalkthrough.pending,
        (state) => {
          state.appLoading = true;
        }
      )
      .addCase(
        groupWalkthroughUpdateWalkthroughsToGroupWalkthrough.fulfilled,
        (state, action) => {
          state.updateWalkthroughsToGroupWalkthroughMsg =
            action.payload.replyMsg;
          state.updateWalkthroughsToGroupWalkthrough =
            action.payload.replyPayload;
          state.appLoading = false;
        }
      )
      .addCase(
        groupWalkthroughUpdateWalkthroughsToGroupWalkthrough.rejected,
        (state, action) => {
          const { type, message } = action.payload.alert;
          state.appLoading = false;
          state.alert.active = true;
          state.alert.type = type;
          state.alert.message = message;
          state.alert.autoHideDuration = action.payload.alert.autoHideDuration
            ? action.payload.alert.autoHideDuration
            : state.alert.autoHideDuration;
        }
      )

      .addCase(
        groupDeleteOneIndvSharingPermissionFromSingleGroupWalkthrough.pending,
        (state) => {
          state.appLoading = true;
        }
      )
      .addCase(
        groupDeleteOneIndvSharingPermissionFromSingleGroupWalkthrough.fulfilled,
        (state) => {
          state.appLoading = false;
          state.resource_update_datetime = new Date().toString();
        }
      )
      .addCase(
        groupDeleteOneIndvSharingPermissionFromSingleGroupWalkthrough.rejected,
        (state, action) => {
          const { type, message } = action.payload.alert;
          state.appLoading = false;
          state.alert.active = true;
          state.alert.type = type;
          state.alert.message = message;
          state.alert.autoHideDuration = action.payload.alert.autoHideDuration
            ? action.payload.alert.autoHideDuration
            : state.alert.autoHideDuration;
        }
      )

      .addCase(
        groupWalkthroughBulkRemoveWalkthroughsFromGroupWalkthrough.pending,
        (state) => {
          state.appLoading = true;
        }
      )
      .addCase(
        groupWalkthroughBulkRemoveWalkthroughsFromGroupWalkthrough.fulfilled,
        (state, action) => {
          state.appLoading = false;
          state.removeBulkWalkthroughsFromGroupWalkthroughMsg =
            action.payload.replyMsg;
        }
      )
      .addCase(
        groupWalkthroughBulkRemoveWalkthroughsFromGroupWalkthrough.rejected,
        (state, action) => {
          const { type, message } = action.payload.alert;
          state.appLoading = false;
          state.alert.active = true;
          state.alert.type = type;
          state.alert.message = message;
          state.alert.autoHideDuration = action.payload.alert.autoHideDuration
            ? action.payload.alert.autoHideDuration
            : state.alert.autoHideDuration;
        }
      )

      .addCase(
        groupWalkthroughGetAllSharingPermissionsForSingleGroupWalkthrough.pending,
        (state) => {
          state.appLoading = true;
        }
      )
      .addCase(
        groupWalkthroughGetAllSharingPermissionsForSingleGroupWalkthrough.fulfilled,
        (state, action) => {
          const payload = action.payload;
          state.groupWalkthroughSharingPermissionMsg = payload.replyMsg;
          state.groupWalkthroughSharingPermission.viewers_list = [
            ...payload.replyPayload.viewers_list,
          ];
          state.groupWalkthroughSharingPermission.viewer_teams_list = [
            ...payload.replyPayload.viewer_teams_list,
          ];
          state.resource_id = payload.resource_id;
          state.resource_type = payload.resource_type;
          state.appLoading = false;
        }
      )
      .addCase(
        groupWalkthroughGetAllSharingPermissionsForSingleGroupWalkthrough.rejected,
        (state, action) => {
          const { type, message } = action.payload.alert;
          state.appLoading = false;
          state.alert.active = true;
          state.alert.type = type;
          state.alert.message = message;
          state.alert.autoHideDuration = action.payload.alert.autoHideDuration
            ? action.payload.alert.autoHideDuration
            : state.alert.autoHideDuration;
        }
      )

      .addCase(
        groupWalkthroughAddBulkIndividualSharingPermissionsForSingleGroupWalkthrough.pending,
        (state) => {
          state.appLoading = true;
        }
      )
      .addCase(
        groupWalkthroughAddBulkIndividualSharingPermissionsForSingleGroupWalkthrough.fulfilled,
        (state, action) => {
          state.groupWalkthroughSharingPermissionMsg = action.payload.replyMsg;
          state.groupWalkthroughSharingPermission.viewers_list = [
            ...action.payload.replyPayload.viewers_list,
          ];
          state.resource_update_datetime = new Date().toString();
        }
      )
      .addCase(
        groupWalkthroughAddBulkIndividualSharingPermissionsForSingleGroupWalkthrough.rejected,
        (state, action) => {
          const { type, message } = action.payload.alert;
          state.appLoading = false;
          state.alert.active = true;
          state.alert.type = type;
          state.alert.message = message;
          state.alert.autoHideDuration = action.payload.alert.autoHideDuration
            ? action.payload.alert.autoHideDuration
            : state.alert.autoHideDuration;
        }
      )

      .addCase(
        groupDeleteOneTeamSharingPermissionFromSingleGroupWalkthrough.pending,
        (state) => {
          state.appLoading = true;
        }
      )
      .addCase(
        groupDeleteOneTeamSharingPermissionFromSingleGroupWalkthrough.fulfilled,
        (state) => {
          state.resource_update_datetime = new Date().toString();
        }
      )
      .addCase(
        groupDeleteOneTeamSharingPermissionFromSingleGroupWalkthrough.rejected,
        (state, action) => {
          const { type, message } = action.payload.alert;
          state.appLoading = false;
          state.alert.active = true;
          state.alert.type = type;
          state.alert.message = message;
          state.alert.autoHideDuration = action.payload.alert.autoHideDuration
            ? action.payload.alert.autoHideDuration
            : state.alert.autoHideDuration;
        }
      )

      .addCase(
        groupWalkthroughAddBulkTeamSharingPermissionsForSingleGroupWalkthrough.pending,
        (state) => {
          state.appLoading = true;
        }
      )
      .addCase(
        groupWalkthroughAddBulkTeamSharingPermissionsForSingleGroupWalkthrough.fulfilled,
        (state, action) => {
          state.appLoading = false;
          state.groupWalkthroughSharingPermissionMsg = action.payload.replyMsg;
          state.groupWalkthroughSharingPermission.viewer_teams_list = [
            ...action.payload.replyPayload.viewer_teams_list,
          ];
          state.resource_update_datetime = new Date().toString();
        }
      )
      .addCase(
        groupWalkthroughAddBulkTeamSharingPermissionsForSingleGroupWalkthrough.rejected,
        (state, action) => {
          const { type, message } = action.payload.alert;
          state.appLoading = false;
          state.alert.active = true;
          state.alert.type = type;
          state.alert.message = message;
          state.alert.autoHideDuration = action.payload.alert.autoHideDuration
            ? action.payload.alert.autoHideDuration
            : state.alert.autoHideDuration;
        }
      );
  },
});

const { reducer, actions } = groupWalkthroughSlice;

export const {
  resetUpdateWalkthroughMsg,
  resetRemoveBulkWalkthrough,
  resetGetAllSharingPermission,
  resetAlert,
} = actions;

const reducerGroupWalkthrough = reducer;

export default reducerGroupWalkthrough;
