import React from "react";
import BackgroundSwirlWrapper from "../01-atoms/BackgroundSwirlWrapper";

interface ComponentProps {
  /** The stripe checkout payment link */
  url: string;
}

const ComponentStripeCheckout = ({ url }: ComponentProps) => {
  return (
    <div className="bg-white w-full h-full rounded-md">
      <iframe className="w-full h-full" src={url}></iframe>
    </div>
  );
};

export default ComponentStripeCheckout;
