import { createSlice } from "@reduxjs/toolkit";

const loadingState = {
  appLoading: false,
};
const loadingSlice = createSlice({
  name: "loading",
  initialState: loadingState,
  reducers: {
    startLoading(state, action) {
      state.appLoading = action.payload;
    },
    stopLoading(state, action) {
      state.appLoading = action.payload;
    },
  },
});

export const { startLoading, stopLoading } = loadingSlice.actions;
const reducerLoading = loadingSlice.reducer;

export default reducerLoading;
