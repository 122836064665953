import { Select, MenuItem } from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import { Status, Tabs, TabsItem } from "usertip-component-library";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
// import { Select } from "usertip-component-library";

const EmbedPagePricingTable = () => {
  // State to show pricing values for month / year subscriptions
  const [subType, setSubType] = useState<"month" | "year">("month");

  const openRegistrationPage = () => {
    let registrationURL = "";
    switch (process.env.NODE_ENV) {
      case "development": {
        registrationURL = "http://localhost:3000/registration";
        break;
      }
      case "staging": {
        registrationURL =
          "https://staging.d2rlkjfr40q6bq.amplifyapp.com/registration";
        break;
      }
      case "production": {
        registrationURL = "https://app.usertip.com/registration";
        break;
      }
      default: {
        registrationURL = "https://app.usertip.com/registration";
        break;
      }
    }
    window.open(registrationURL, "_blank", "noreferrer");
  };

  const onClickContactUs = () => {
    // send Email when user click on "Let's Talk" Enterprise offer
    window.open("https://www.usertip.com/bookings", "_blank", "noreferrer");
  };

  /** Handle for click tabs */
  const handleTabsClick = useCallback((value: "month" | "year") => {
    setSubType(value);
  }, []);

  /** Children for tabs component for prevent UI Issues */
  const tabsChildren = useMemo(() => {
    return (
      <>
        <TabsItem
          variant="light"
          label="Monthly"
          onClick={() => handleTabsClick("month")}
          id="month"
        />
        <TabsItem
          variant="light"
          label="Yearly - 2 months free!"
          onClick={() => handleTabsClick("year")}
          id="year"
        />
      </>
    );
  }, [handleTabsClick]);

  return (
    <div className="flex flex-col w-full">
      <div
        className="ut--PricingTable is-fontFamily--NotoSans ut--is-whiteButtonText ut--is-lightColorBackground has-fourItems flex flex-col justify-center items-center"
        style={{
          // "--pt-color-primary: #7657f1; background-color: rgb(240, 240, 255)"
          // "--pt-color-primary": "#7657f1"
          color: "#7657f1",
          // backgroundColor: "rgb(240, 240, 255)",
          backgroundColor: "rgb(255, 255, 255)",
          borderRadius: "32px",
        }}
        data-testid="pricing-table-container"
      >
        <div className="flex flex-row self-center items-center w-auto py-2">
          <Tabs variant="light" name="tabs-pricing-type">
            {tabsChildren}
          </Tabs>
          {/* <div>
            <CurlyArrowIcon />
            <span className="ut--Text ut--Text-color--default ut--Text-fontSize--14 ut--Text-fontWeight--600">
              Get 2 months free!
            </span>
          </div> */}
        </div>

        <MainPriceContainer
          onClickContactUs={onClickContactUs}
          onClickSubscribe={openRegistrationPage}
          billingType={subType}
        />
      </div>
      <div
        className="flex flex-col justify-center items-start"
        style={{
          padding: "36px 30px",
          marginTop: "32px",
          color: "#7657f1",
          // backgroundColor: "rgb(240, 240, 255)",
          backgroundColor: "rgb(255, 255, 255)",
          borderRadius: "32px",
        }}
      >
        <div className="flex flex-col" style={{ color: "#1a1a1a" }}>
          <div className="flex flex-row w-full items-center">
            <span
              className="ut--Text ut--Text-fontSize--36 ut--Text-fontWeight--700"
              style={{ paddingRight: "6px" }}
            >
              Pay-as you go
            </span>
            <Status variants={"default"} text="Add-on" size="small" />
          </div>
          <span
            className="ut--Text ut--Text-fontSize--24 ut--Text-fontWeight--500"
            style={{ opacity: "0.5" }}
          >
            Only pay extra when more users interact with your Usertip
          </span>
        </div>

        {/* <label
          htmlFor="default-range"
          className="block mb-2 ut--Text ut--Text-fontSize--24 ut--Text-fontWeight--500"
        >
          Monthly Active Users (MAUs)
        </label> */}
        <PricingRangeSlider />
      </div>
    </div>
  );
};

export const MainPriceContainer = ({
  onClickContactUs,
  onClickSubscribe,
  billingType,
}: {
  onClickContactUs: () => void;
  onClickSubscribe: () => void;
  billingType: "month" | "year";
}) => {
  return (
    <>
      <div className="ut--PricingTable-grid">
        <EmbedPricingCard
          planTitle={"Micro Plan"}
          planDesc={`${
            billingType === "month" ? "" : "Paid yearly. "
          }For small teams looking to improve training.`}
          onClickPricingButton={onClickSubscribe}
          pricingBtnText={"Start 14-day trial"}
          price={billingType === "month" ? "USD 29" : "USD 24"}
          priceUnit={"per month"}
          featureList={
            <>
              <FeatureItem text="Unlimited Walkthroughs" />
              <FeatureItem text="Unlimited Published Walkthroughs" />
              <FeatureItem text="Unlimited Snaps" />
              <FeatureItem text="Unlimited Snaps Shared" />
              {/* <FeatureItem text="3 Allowed Domains" /> */}
              <FeatureItem
                text={`50 MAUs`}
                tooltip="Monthy Active Users are users who have logged-in to their Usertip account or unique users that are detected through our code snippet installation"
              />
              <FeatureItem
                text={`Unlimited Registered Users per Organization`}
              />
              <FeatureItem text={`Usertip watermark displayed`} />
              <FeatureItem text={`No access to Code Snippet integration`} />
              {/* <FeatureItem text={`Email Support within 15 working days`} /> */}
            </>
          }
        />
        <EmbedPricingCard
          planTitle={"Starter Plan"}
          planDesc={`${
            billingType === "month" ? "" : "Paid yearly. "
          }For departments or growing companies.`}
          onClickPricingButton={onClickSubscribe}
          pricingBtnText={"Start 14-day trial"}
          price={billingType === "month" ? "USD 99" : "USD 83"}
          priceUnit={"per month"}
          featureList={
            <>
              <FeatureItem text={`Everything in Micro, plus`} />
              <FeatureItem text={`1000 MAUs`} />
              <FeatureItem text={`User Segmentation`} />
              <FeatureItem text={`Viewer Teams`} />
              <FeatureItem text={`Usertip watermark displayed`} />
              <FeatureItem text={`Access to Code Snippet integration`} />
              {/* <FeatureItem text={`Email Support within 7 working days`} /> */}
            </>
          }
        />
        <EmbedPricingCard
          planTitle={"Business Plan"}
          planDesc={`${
            billingType === "month" ? "" : "Paid yearly. "
          }For businesses.`}
          onClickPricingButton={onClickSubscribe}
          pricingBtnText={"Start 14-day trial"}
          price={billingType === "month" ? "USD 299" : "USD 249"}
          priceUnit={"per month"}
          featureList={
            <>
              <FeatureItem text={`Everything in Starter, plus`} />
              <FeatureItem text={`5000 MAUs`} />
              <FeatureItem text={`Walkthrough Analytics`} />
              <FeatureItem text={`Usertip watermark removed`} />
              {/* <FeatureItem text={`Email Support within 1 working day`} /> */}
            </>
          }
        />
        <EmbedPricingCard
          planTitle={"Need a Custom Plan?"}
          planDesc={"Let us know what you need."}
          onClickPricingButton={onClickContactUs}
          pricingBtnText={"Let's Talk!"}
          price={""}
          priceUnit={""}
          featureList={
            <>
              <FeatureItem
                text={`Service Level Agreement`}
                tooltip="Service Level Agreement is a contract that outlines the terms and conditions of the project."
              />
              <FeatureItem text={`Custom Integrations`} />
              <FeatureItem text={`Custom Installation`} />
              <FeatureItem text={`Custom MAUs`} />
              <FeatureItem text={`Whitelabeling`} />
            </>
          }
        />
      </div>
    </>
  );
};

export const EmbedPricingCard = ({
  planTitle,
  planDesc,
  onClickPricingButton,
  pricingBtnText,
  price,
  priceUnit,
  featureList,
  disabled,
}: {
  planTitle: string;
  planDesc: string;
  onClickPricingButton: () => void;
  pricingBtnText: string;
  price: string;
  priceUnit: string;
  featureList: React.JSX.Element;
  disabled?: boolean;
}) => {
  return (
    <>
      <div
        className="ut--PriceColumn flex flex-col justify-start items-center"
        style={
          {
            // --pt-animate-in-order: 0;" data-testid="price-column
          }
        }
      >
        <div className="ut--PriceColumn-content flex flex-col justify-start items-center">
          <div className="ut--PriceColumn-aboutProduct flex flex-col">
            <div className="ut--PriceColumn-textAndProductImage flex">
              <div
                className="ut--PriceColumn-textContainerHeightAligner"
                style={{ height: "69px" }}
              >
                <div className="ut--PriceColumn-textContainer flex flex-col">
                  <div className="ut--PriceColumn-name flex">
                    <span className="ut--Text ut--Text-color--default ut--Text-fontSize--20 ut--Text-fontWeight--600">
                      {planTitle}
                    </span>
                  </div>
                  <span className="ut--PriceColumn-description ut--Text ut--Text-color--default ut--Text-fontSize--14">
                    {planDesc}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="ut--PriceColumn-priceAndButton flex flex-col">
            <div
              className="ut--PriceColumn-priceContainerHeightAligner"
              style={{
                // "height: 45px;"
                height: "45px",
              }}
            >
              <div className="ut--PriceColumn-priceContainer">
                <div className="flex spacing-4 flex-col items-start">
                  <div className="flex items-center">
                    <span className="ut--PriceColumn-price ut--Text ut--Text-color--default ut--Text-fontSize--36 ut--Text-fontWeight--700">
                      <span className="CurrencyAmount">{price}</span>
                    </span>
                    <span className="ut--PriceColumn-interval ut--Text ut--Text-color--default ut--Text-fontSize--13">
                      {priceUnit}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <PricingButton
              buttonText={pricingBtnText}
              onClick={() => {
                onClickPricingButton();
              }}
              disabled={disabled}
            />
          </div>
        </div>
        <div className="ut--PriceColumn-featureListContainer flex flex-col">
          <span className="ut--PriceColumn-featureListHeader ut--Text ut--Text-color--default ut--Text-fontSize--14">
            This includes:
          </span>
          <div className="ut--PriceColumn-featureList flex flex-col items-start">
            {featureList}
          </div>
        </div>
      </div>
    </>
  );
};

const PricingButton = ({
  buttonText,
  onClick,
  disabled,
}: {
  buttonText: string;
  onClick: any;
  disabled?: boolean;
}) => {
  return (
    <button
      className="ut--Button ut--PriceColumn-button ut--Button--primary ut--Button--lg"
      type="button"
      disabled={disabled ? disabled : false}
      style={{
        // "background-color: rgb(118, 87, 241); border-color: rgb(118, 87, 241);"
        backgroundColor: disabled ? "#919191" : "rgb(118, 87, 241)",
        borderColor: disabled ? "#919191" : "rgb(118, 87, 241)",
      }}
      onClick={onClick}
    >
      <div className="flex justify-center items-center">
        <span className="ut--Text ut--Text-color--default ut--Text-fontWeight--500 ut--Text--truncate">
          {disabled ? "Current Plan" : buttonText}
        </span>
      </div>
    </button>
  );
};

const PricingRangeSlider = () => {
  // const [value, setValue] = useState(0);
  // const MAX = 10;
  // const getBackgroundSize = () => {
  //   return { backgroundSize: `${(value * 100) / MAX}% 100%` };
  // };
  let min = 0;
  let max = 100;

  // const [value, setValue] = useState(50);

  // const handleChange = (event: any) => {
  //   setValue(event.target.value);
  // };

  const LOWER_LIMIT_MAU = 50;
  const UPPER_LIMIT_MAU = 10000;

  const MICRO_MIN_MAU = 50;
  const START_MIN_MAU = 1000;
  const BUSINESS_MIN_MAU = 5000;

  const getPlanMinMAU = (planType: string) => {
    if (planType === "micro") {
      return MICRO_MIN_MAU;
    } else if (planType === "starter") {
      return START_MIN_MAU;
    } else if (planType === "business") {
      return BUSINESS_MIN_MAU;
    }
  };

  const getAdjustedPrice = (mau: number, planType: string) => {
    // 50-100 - $0.50 per user
    // 101-1000 - $0.40 per user
    // 1001-5000 - $0.30 per user
    // 5001-100k - $0.15 per user
    // 100,001 & above - $0.05 per user

    switch (planType) {
      case "micro": {
        if (mau <= 50) return 0;
        if (mau > 50 && mau <= 200) return 0.5;
        if (mau > 200 && mau <= 2000) return 0.2;
        if (mau > 2000 && mau <= 5000) return 0.06;
        if (mau > 5000 && mau <= 100000) return 0.04;
        if (mau > 100000) return 0.01;
        return 0.5;
        // if (mau <= MICRO_MIN_MAU) return 0;
        // if (mau > MICRO_MIN_MAU && mau <= 50) return 1;
        // if (mau > 50 && mau <= 200) return 0.7;
        // if (mau > 200 && mau <= 500) return 0.5;
        // if (mau > 500 && mau <= 2500) return 0.06;
        // if (mau > 2500 && mau <= 10000) return 0.04;
        // if (mau > 100000) return 0.01;
        return 0.5;
        break;
      }
      case "starter": {
        if (mau <= 1000) return 0;
        if (mau > 1000 && mau <= 2000) return 0.2;
        if (mau > 2000 && mau <= 5000) return 0.06;
        if (mau > 5000 && mau <= 100000) return 0.04;
        if (mau > 100000) return 0.01;
        return 0.5;
        // if (mau <= START_MIN_MAU) return 0;
        // if (mau > 200 && mau <= 1000) return 0.4;
        // if (mau > 200 && mau <= 2500) return 0.04;
        // if (mau > START_MIN_MAU && mau <= 10000) return 0.05;
        // if (mau > 100000) return 0.02;
        // return 0.5;
        break;
      }
      case "business": {
        if (mau <= 5000) return 0;
        // if (mau > 2000 && mau <= 5000) return 0.08;
        if (mau > 5000 && mau <= 100000) return 0.04;
        if (mau > 100000) return 0.01;
        return 0.5;
        break;
      }

      default:
        return 0.5;
    }
  };

  const LOWEST_SLIDER_VAL = (LOWER_LIMIT_MAU / UPPER_LIMIT_MAU) * 100;

  const [sliderValue, setSliderValue] = useState(LOWEST_SLIDER_VAL);

  const handleSliderChange = (event: any) => {
    const newValue = event.target.value;
    setSliderValue(newValue);
    // handleChange(event);
  };

  const handleNewSliderChange = (value: any) => {
    // const newValue = event.target.value;
    setSliderValue(value);
    // handleChange(event);
  };

  const calculateBackground = () => {
    const percentage = ((sliderValue - min) / (max - min)) * 100;
    return `linear-gradient(90deg, #9179F4 ${percentage}%, #F1EEFE ${percentage}%)`;
  };

  const getMAUValue = () => {
    return Math.round((sliderValue / 100) * UPPER_LIMIT_MAU);
  };

  const [plan, setPlan] = useState("micro");

  const handleTabsClick = useCallback(
    (value: "micro" | "starter" | "business") => {
      setPlan(value);
    },
    []
  );

  const tabsChildren = useMemo(() => {
    return (
      <>
        <TabsItem
          variant="light"
          label="Micro"
          onClick={() => handleTabsClick("micro")}
          id="micro"
        />
        <TabsItem
          variant="light"
          label="Starter"
          onClick={() => handleTabsClick("starter")}
          id="starter"
        />
        <TabsItem
          variant="light"
          label="Business"
          onClick={() => handleTabsClick("business")}
          id="business"
        />
      </>
    );
  }, [handleTabsClick]);

  return (
    <>
      <div className="flex flex-row w-full border-b border-b-[#F1EEFE]">
        <Tabs variant="light" name="tabs-plan-type">
          {tabsChildren}
        </Tabs>
      </div>
      <label
        className="ut--Text ut--Text-color--default ut--Text-fontSize--20 ut--Text-fontWeight--600"
        style={{ color: "#1a1a1a", marginTop: "16px" }}
      >
        {getMAUValue() === UPPER_LIMIT_MAU
          ? `${UPPER_LIMIT_MAU}+`
          : getMAUValue()}{" "}
        MAUs
      </label>
      <label className="ut--Text-fontWeight--600" style={{ opacity: 0.8 }}>
        Additional $
        {getMAUValue() === UPPER_LIMIT_MAU
          ? `${
              getAdjustedPrice(getMAUValue(), plan) *
              (getMAUValue() - getPlanMinMAU(plan)!)
            }+`
          : (
              getAdjustedPrice(getMAUValue(), plan) *
              (getMAUValue() - getPlanMinMAU(plan)!)
            ).toFixed(2)}{" "}
        per month,{" "}
        {`$${
          getMAUValue() === UPPER_LIMIT_MAU
            ? getAdjustedPrice(getMAUValue() + 1, plan).toFixed(2)
            : getAdjustedPrice(getMAUValue(), plan).toFixed(2)
        } per MAU`}
      </label>
      <div className="w-full" style={{ marginTop: "6px" }}>
        <Slider
          marks={{
            [(MICRO_MIN_MAU / UPPER_LIMIT_MAU) * 100]: <span>50</span>,
            10: <span>1k</span>,
            [(2000 / UPPER_LIMIT_MAU) * 100]: <span>2k</span>,
            [(BUSINESS_MIN_MAU / UPPER_LIMIT_MAU) * 100]: <span>5k</span>,
            [100]: <span>10k</span>,
          }}
          step={0.1}
          value={sliderValue}
          onChange={handleNewSliderChange}
          styles={{
            handle: {
              background: "#ffffff",
              borderColor: "#7657f1",
              width: "16px",
              height: "16px",
              marginTop: "-4px",
            },
            track: { background: "#7657f1", padding: "4px" },
          }}
          dotStyle={{
            background: "#ffffff",
            borderColor: "#7657f1",
            width: "10px",
            height: "10px",
            bottom: "-5px",
          }}
          // activeDotStyle={{ boxShadow: "0 0 0 5px #7657f1" }}
          // trackStyle={{ background: "#7657f1" }}
        />
      </div>
    </>
  );
};

export const FeatureItem = ({
  text,
  tooltip,
}: {
  text: string;
  tooltip?: string;
}) => {
  return (
    <div className="ut--PriceColumn-feature flex items-center">
      <div className="ut--PriceColumn-checkContainer flex">
        <svg
          className="InlineSVG ut--Icon ut--PriceColumn-check ut--Icon--sm"
          focusable="false"
          fill="#16A34A"
          color="#16A34A"
          fill-opacity="0.5"
          height="16"
          viewBox="0 0 16 16"
          width="16"
        >
          <path
            d="m8 16c-4.418278 0-8-3.581722-8-8s3.581722-8 8-8 8 3.581722 8 8-3.581722 8-8 8zm3.0832728-11.00479172-4.0832728 4.09057816-1.79289322-1.79289322c-.39052429-.39052429-1.02368927-.39052429-1.41421356 0s-.39052429 1.02368927 0 1.41421356l2.5 2.50000002c.39052429.3905243 1.02368927.3905243 1.41421356 0l4.79037962-4.79768495c.3905243-.3905243.3905243-1.02368927 0-1.41421357-.3905243-.39052429-1.0236893-.39052429-1.4142136 0z"
            fill-rule="evenodd"
          ></path>
        </svg>
      </div>
      {tooltip ? (
        <div className="ut--PriceTooltip">
          <span className="ut--PriceColumn-featureText ut--Text ut--Text-color--default ut--Text-fontSize--14">
            {text}
          </span>
          <span className="ut--PriceTooltip-text ut--PriceTooltip-text-right ut--Text-fontSize--14">
            {tooltip}
          </span>
        </div>
      ) : (
        <span className="ut--PriceColumn-featureText ut--Text ut--Text-color--default ut--Text-fontSize--14">
          {text}
        </span>
      )}
    </div>
  );
};

const CurlyArrowIcon = ({ color }: { color?: string }) => {
  return (
    <>
      <svg
        width="100"
        height="33"
        viewBox="0 0 150 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ transform: "scale(-1,1)" }}
      >
        <path
          d="M134.759 35.9262C134.759 44.2804 91.6841 43.7618 86.2912 39.1829C74.8765 29.4912 92.9766 8.63581 101.234 3.93383C107.56 0.331117 115.297 1.36186 110.812 10.4472C103.055 26.1611 77.3782 40.1135 60.6207 42.0564C35.9089 44.9216 56.0707 18.4928 64.069 11.2135C66.6303 8.88245 74.226 1.9906 78.4368 3.35912C84.8231 5.43468 70.0081 20.4357 69.6245 20.7921C50.4241 38.6299 27.2746 45.7973 2 47.9951"
          stroke={color ? color : "#1E1E1E"}
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M142.432 30.7932C143.2 30.7453 143.732 31.5471 143.39 32.2364L137.993 43.0933C137.65 43.7826 136.69 43.8425 136.264 43.2011L129.56 33.0987C129.135 32.4573 129.563 31.5957 130.331 31.5478L142.432 30.7932Z"
          fill={color ? color : "#1E1E1E"}
        />
      </svg>
    </>
  );
};

export default EmbedPagePricingTable;
