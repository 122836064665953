import React from "react";
import { Container } from "@mui/material";
import BackgroundSwirlDecoration from "../../components/01-atoms/00-icons/BackgroundSwirlDecoration";

const PageHomeFullscreen = ({ children }: { children: any }) => {
  return (
    <>
      <div className="frameBody flex-grow">
        {/* <PageTopBar handleDrawerToggle={handleDrawerToggle} /> */}
        <main className="frameBodyContent">
          <Container maxWidth="xl">{children}</Container>
        </main>
      </div>
      <div
        className="fixed"
        style={{
          bottom: "0",
          transform: "scaleX(-1)",
          zIndex: -1,
        }}
      >
        <BackgroundSwirlDecoration scale={1.5} />
      </div>
      <div
        className="fixed"
        style={{
          right: "0",
          top: "0",
          transform: "rotate(180deg) scaleX(-1)",
          zIndex: -1,
        }}
      >
        <BackgroundSwirlDecoration scale={1.5} />
      </div>
    </>
  );
};

export default PageHomeFullscreen;
