import React from "react";
import PictureViewers from "../components/Templates/PictureViewer/PictureViewers";

const Test = () => {
  return (
    <div className="flex justify-center w-full">{/* <PictureViewers /> */}</div>
  );
};

export default Test;
