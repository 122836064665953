import { createSlice } from "@reduxjs/toolkit";

const modalState = {
  visibility: {
    createWalkthrough: false,
  },
};

const reducerSlice = createSlice({
  name: "modal",
  initialState: modalState,
  reducers: {
    setCreateWalkthroughModalShow(state, action) {
      state.visibility.createWalkthrough = action.payload
    } 
  },
});
const {actions, reducer} = reducerSlice
export const {setCreateWalkthroughModalShow} = actions
const reducerModal = reducer
export default reducerModal;
