import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import store from "../index";
import {
  callGetListOfOrganizationUserSegmentation,
  callPOSTCreateSegment,
  callPOSTAssignUserSegmentationToWalkthrough,
  callDeleteUserSegmentation,
  callPOSTUpdateUserSegment,
} from "../../axios/v2/callsUserSegment";
import { UserSegmentation } from "../../interfaces/interfaceSegmentation";

/** Fetch full list of user segmentation for an organization */
export const listAllUserSegmentation = createAsyncThunk<any, string>(
  "userSegmentation/listAllUserSegmentation",
  async (orgId, thunkApi) => {
    try {
      const response = (await callGetListOfOrganizationUserSegmentation(
        orgId
      )) as any;
      if (response.data) {
        const result = response.data.payload;
        return result;
      } else {
        store.dispatch(getError(response));
        throw response;
      }
    } catch (error: any) {
      console.log("error");
      console.log(error);
      const result = {
        alert: {
          type: "error",
          message: error.toString(),
        },
      };
      return thunkApi.rejectWithValue(result);
    }
  }
);

/** Delete user segmentation Reducer */
export const deleteUserSegmentation = createAsyncThunk<any, string>(
  "userSegmentation/deleteUserSegmentation",
  async (userSegmentationId, thunkApi) => {
    try {
      const response = (await callDeleteUserSegmentation(
        userSegmentationId
      )) as any;
      if (response.data) {
        const result = response.data.payload;
        return result;
      } else {
        store.dispatch(getError(response));
        throw response;
      }
    } catch (error: any) {
      console.log("error");
      console.log(error);
      const result = {
        alert: {
          type: "error",
          message: error.toString(),
        },
      };
      return thunkApi.rejectWithValue(result);
    }
  }
);

/** Assigns/update user segment for a walkthrough */
export const assignUserSegmentToWalkthrough = createAsyncThunk<
  any,
  { userSegmentationId: string | null; walkthroughId: string }
>(
  "userSegmentation/assignUserSegmentToWalkthrough",
  async (params, thunkApi) => {
    const { userSegmentationId, walkthroughId } = params;
    try {
      console.log("calling callPOSTAssignUserSegmentationToWalkthrough");
      const response = (await callPOSTAssignUserSegmentationToWalkthrough(
        userSegmentationId,
        walkthroughId
      )) as any;
      console.log("---response", response.data);
      if (response.data) {
        const result = response.data.payload;
        console.log(response.data.msg);
        return result;
      } else {
        store.dispatch(getError(response));
        throw response;
      }
    } catch (error: any) {
      console.log("error");
      console.log(error);
      const result = {
        alert: {
          type: "error",
          message: error.toString(),
        },
      };
      return thunkApi.rejectWithValue(result);
    }
  }
);

/**Thunk for creating new user segmentation */
export const createUserSegmentation = createAsyncThunk(
  "userSegmentation/create",
  async (params: any, thunkApi) => {
    try {
      const res = await callPOSTCreateSegment(params);
      if (res.data) {
        return res.data;
      }
      throw res.data;
    } catch (error: any) {
      console.log("error");
      console.log(error);
      const result = {
        alert: {
          type: "error",
          message: error.toString(),
        },
      };
      return thunkApi.rejectWithValue(result);
    }
  }
);

/**Thunk for updating existing user segmentation */
export const updateUserSegmentation = createAsyncThunk<
  any,
  { segmentationId: string; segmentData: any }
>("userSegmentation/update", async (params, thunkApi) => {
  try {
    const res = await callPOSTUpdateUserSegment(params);
    if (res.data) {
      return res.data;
    }
    throw res.data;
  } catch (error: any) {
    console.log("error");
    console.log(error);
    const result = {
      alert: {
        type: "error",
        message: error.toString(),
      },
    };
    return thunkApi.rejectWithValue(result);
  }
});

const userSegmentationState = {
  userSegmentationList: null as UserSegmentation[] | null,
  msg: null as string | null,
  error: "",
  appLoading: false,
  alert: {
    active: false,
    type: "",
    message: "",
    autoHideDuration: 6000,
  },
};

export const userSegmentationSlice = createSlice({
  name: "userSegmentation",
  initialState: userSegmentationState,
  reducers: {
    getError: (state, action) => {
      if (action.payload.errorMsg !== undefined) {
        console.log(
          `User Segmentation fetch error is: ${action.payload.errorMsg}`
        );
      }
      state.error = action.payload.errorMsg;
    },
    resetAlert: (state) => {
      state.alert.active = false;
      state.alert.type = "";
      state.alert.message = "";
      state.alert.autoHideDuration = 6000;
    },
  },
  extraReducers: (builder) => {
    builder
      /** listAllUserSegmentation */
      .addCase(listAllUserSegmentation.pending, (state) => {
        state.appLoading = true;
      })
      .addCase(listAllUserSegmentation.fulfilled, (state, action) => {
        const payload = action.payload;
        state.userSegmentationList = payload.userSegmentationList;
        state.appLoading = false;
      })
      .addCase(listAllUserSegmentation.rejected, (state) => {
        // TODO: error message state
        state.appLoading = false;
      })
      /** createUserSegmentation */
      .addCase(createUserSegmentation.pending, (state) => {
        state.appLoading = true;
      })
      .addCase(createUserSegmentation.fulfilled, (state) => {
        state.appLoading = false;
        state.msg = "createSegmentSuccess";
      })
      .addCase(createUserSegmentation.rejected, (state) => {
        state.appLoading = false;
      })
      /** assignUserSegmentToWalkthrough */
      .addCase(assignUserSegmentToWalkthrough.pending, (state) => {
        state.appLoading = true;
      })
      .addCase(assignUserSegmentToWalkthrough.fulfilled, (state, action) => {
        state.appLoading = false;
      })
      .addCase(assignUserSegmentToWalkthrough.rejected, (state) => {
        state.appLoading = false;
      })
      /**deleteUserSegmentation */
      .addCase(deleteUserSegmentation.pending, (state) => {
        state.appLoading = true;
      })
      .addCase(deleteUserSegmentation.fulfilled, (state, action) => {
        state.appLoading = false;
      })
      .addCase(deleteUserSegmentation.rejected, (state) => {
        state.appLoading = false;
      })
      /** updateUserSegmentation */
      .addCase(updateUserSegmentation.pending, (state) => {
        state.appLoading = true;
      })
      .addCase(updateUserSegmentation.fulfilled, (state, action) => {
        state.appLoading = false;
        state.msg = "updateSegmentSuccess";
      })
      .addCase(updateUserSegmentation.rejected, (state) => {
        state.appLoading = false;
      });
  },
});

const { reducer, actions } = userSegmentationSlice;

export const { getError, resetAlert } = actions;

const reducerUserSegmentation = reducer;

export default reducerUserSegmentation;
