import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { paymentPOSTActiveSubscriptionCancel } from "../../store/reducers/reducerPayment";
import { EmptyModal, Button } from "usertip-component-library";

const ComponentCancelSubscription = ({
  showModal,
  setShowModal,
}: {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const orgID = useSelector((state: RootState) => state.user.userData.org);
  const isCancelSuccess = useSelector(
    (state: RootState) => state.payment.cancelStatus
  );
  const dispatch = useDispatch();
  const onSubmitCancelPlan = () => {
    dispatch(paymentPOSTActiveSubscriptionCancel(orgID!));
  };

  //   useEffect(() => {
  //     if (isCancelSuccess) {
  //       setShowModal(false);
  //     }
  //   }, [isCancelSuccess]);
  if (isCancelSuccess) {
    return (
      <EmptyModal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        size="small"
        customClass="ut-py-4"
      >
        <div className="modalHeader">Cancel Subscription Success</div>
        <Button
          onClick={() => setShowModal(false)}
          variant="primary"
          size="medium"
          text="Back"
          color="primary"
        />
      </EmptyModal>
    );
  }
  return (
    <EmptyModal
      isOpen={showModal}
      onClose={() => setShowModal(false)}
      size="small"
      customClass="ut-py-4"
    >
      <div className="modalHeader">Cancel The Subscription?</div>
      <div className="flex gap-2 justify-end">
        <Button
          onClick={() => setShowModal(false)}
          variant="primary"
          size="medium"
          text="Back"
          color="primary"
        />
        <Button
          onClick={onSubmitCancelPlan}
          variant="primary"
          size="medium"
          text="Confirm"
          color="primary"
        />
      </div>
    </EmptyModal>
  );
};

export default ComponentCancelSubscription;
