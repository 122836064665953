import React from "react";

import { useSelector } from "react-redux";
import { RootState } from "../../../store/index";
import GlobalLoading from "../Loading/GlobalLoading";

const ComponentNotFound = () => {
  //Get all loading state from all reducers
  const authLoading = useSelector((state: RootState) => state.auth.appLoading);
  const orgLoading = useSelector((state: RootState) => state.org.appLoading);
  const teamLoading = useSelector((state: RootState) => state.team.appLoading);
  const groupWalkthroughLoading = useSelector(
    (state: RootState) => state.groupWalkthrough.appLoading
  );
  const walkthroughLoading = useSelector(
    (state: RootState) => state.walkthrough.appLoading
  );
  const groupLoading = useSelector(
    (state: RootState) => state.group.appLoading
  );
  const analyticsLoading = useSelector(
    (state: RootState) => state.analytics.appLoading
  );
  const userSegmentationLoading = useSelector(
    (state: RootState) => state.userSegmentation.appLoading
  );
  const mediaLoading = useSelector(
    (state: RootState) => state.media.appLoading
  );
  const paymentLoading = useSelector(
    (state: RootState) => state.payment.appLoading
  );

  return authLoading ||
    orgLoading ||
    teamLoading ||
    groupWalkthroughLoading ||
    walkthroughLoading ||
    groupLoading ||
    analyticsLoading ||
    userSegmentationLoading ||
    mediaLoading ||
    paymentLoading ? (
    <GlobalLoading />
  ) : (
    <div className="pageNotFoundHeader">Page Not Found!</div>
  );
};
export default ComponentNotFound;
