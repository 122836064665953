import React from "react";

import ComponentViewTeamsInTable from "./ComponentViewTeamsInTable";
import { EmptyModal, Button } from "usertip-component-library";

const ComponentViewTeams = ({
  displayUserRoleFull,

  selectedTeamList,

  showModalView,
  setShowModalView,

  viewMode,
}) => {
  const selectedTeam = selectedTeamList[0];
  const membersTeamListName = `${selectedTeam.name}`;

  // Modal close handler
  const handleHideModal = () => {
    setShowModalView(false);
  };

  return (
    <EmptyModal
      isOpen={showModalView}
      onClose={handleHideModal}
      customClass="ut-modal-lg-percentage"
      size="large"
    >
      <div className="modalHeader">
        {viewMode === "single" ? `View ${membersTeamListName}` : ""}
      </div>

      {viewMode === "single" && (
        <>
          <div className="modalSubHeader"></div>

          <ComponentViewTeamsInTable
            dataSet={selectedTeam}
            displayUserRoleFull={displayUserRoleFull}
          />
        </>
      )}

      <form noValidate autoComplete="off" className="modalForm">
        <div className="ml-auto">
          <Button
            variant="primary"
            color="primary"
            size="medium"
            text="Cancel"
            onClick={handleHideModal}
          />
        </div>
      </form>
    </EmptyModal>
  );
};

export default ComponentViewTeams;
