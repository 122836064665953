import React, { useEffect, useState } from "react";
import { RuleGroup } from "../../interfaces/interfaceSegmentation";
import ComponentRuleForm from "../AddUserSegmentationRule/ComponentRuleForm";
import { Button } from "usertip-component-library";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const GroupUpdateRuleForm = ({
  listRule,
  setListRule,
  groupIndex,
  onRemoveGroupClick,
}: {
  listRule: RuleGroup[];
  setListRule: React.Dispatch<React.SetStateAction<RuleGroup[]>>;
  groupIndex: number;
  onRemoveGroupClick: (currGroupIndex: number) => void;
}) => {
  const handleAddRule = () => {
    setListRule((prev) => [
      ...prev,
      {
        groupIndex: groupIndex,
        index: listRule.length,
        metaName: "",
        metaType: "string",
        metaRule: "contain",
        metaValue: "",
      },
    ]);
  };
  return (
    <>
      {/* rule form when user to choose add new rule (AND) */}
      {listRule.length > 0 &&
        listRule.map((d, i) => {
          // only map if groupIndex is the same with d,groupindex
          return (
            groupIndex === d.groupIndex && (
              <div className="my-5" key={`group-${groupIndex}-${d.index}`}>
                <ComponentRuleForm
                  groupIndex={groupIndex}
                  listRule={listRule}
                  index={d.index!}
                  setListRule={setListRule}
                  handleAddRule={handleAddRule}
                />
              </div>
            )
          );
        })}
      {/* dont add remove group button on the first form */}
      {groupIndex !== 0 && (
        <div className="mt-3">
          <Button
            color="secondary"
            size="medium"
            text="REMOVE RULE GROUPS"
            variant="outlined"
            icon={<HighlightOffIcon fontSize="small" />}
            iconRight
            customClass="ut-header-link-button"
            onClick={() => onRemoveGroupClick(groupIndex)}
          />
        </div>
      )}
    </>
  );
};

export default GroupUpdateRuleForm;
