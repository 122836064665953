import React from "react";

import { useNavigate } from "react-router-dom";

import { Button } from "usertip-component-library";

import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

interface Props {
  btnURL: any;
  btnLabel: string;
  btnDisabled: boolean;
}

const ComponentPageHeaderLinksBackBtn = ({
  btnURL,
  btnLabel,
  btnDisabled,
}: Props) => {
  let navigate = useNavigate();

  return (
    <div className="mb-8">
      <Button
        onClick={() => {
          navigate(btnURL);
        }}
        icon={<KeyboardBackspaceIcon fontSize="small" />}
        iconLeft
        disabled={btnDisabled}
        text={`Back to ${btnLabel}`.toUpperCase()}
        color="primary"
        size="medium"
        variant="outlined"
        customClass="ut-header-link-button"
      />
    </div>
  );
};

export default ComponentPageHeaderLinksBackBtn;
