import React from "react";
import image from "../../../data/imageList.json";

interface Props {
  children: React.ReactNode;
  containerRef: React.RefObject<HTMLDivElement>;
  width: number;
  height: number;
}
const MainPictureContainer = ({
  children,
  containerRef,
  width,
  height,
}: Props) => {
  return (
    <div
      className="bg-white rounded-lg image-viewer-container border-2 border-default-neutral-50"
      style={{ width: `${width}px`, height: `${height}px` }}
      ref={containerRef}
    >
      {children}
    </div>
  );
};

export default MainPictureContainer;
