import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  callDELETEExistingGroup,
  callGETRetrieveAllGroupWalkthroughsByOrg,
  callGETRetrieveGroupWalkthrough,
  callPOSTCreateNewGroup,
} from "../../axios/v2/callsGroup";
import { alertState } from "./reducerAlert";

// Actions
// Add new Group
export const groupAddNewGroup = createAsyncThunk(
  "group/addNewGroup",
  async (groupData, thunkApi) => {
    const { org_id, group_name } = groupData;
    try {
      const result = await callPOSTCreateNewGroup(org_id, group_name);
      if (result && result.data) {
        const res = {
          replyMsg: result.data.msg,
          replyPayload: result.data.payload,
        };
        return res;
      }
    } catch (err) {
      const errStatus = err.response.status; // Integer
      const errPayload = err.response.data;
      const errMsg = errPayload.msg;

      console.log(errStatus);
      console.log(errPayload);
      console.log(errMsg);

      const res = {
        alert: {
          type: "error",
          message: err.toString(),
        },
      };

      return thunkApi.rejectWithValue(res);
    }
  }
);

// Remove existing Group
export const groupRemoveExistingGroup = createAsyncThunk(
  "group/removeExistingGroup",
  async (walkthrough_group_id, thunkApi) => {
    try {
      const result = await callDELETEExistingGroup(walkthrough_group_id);
      if (result && result.data) {
        const res = {
          replyMsg: result.data.msg,
        };
        return res;
      }
    } catch (err) {
      const errStatus = err.response.status; // Integer
      const errPayload = err.response.data;
      const errMsg = errPayload.msg;

      console.log(errStatus);
      console.log(errPayload);
      console.log(errMsg);

      const res = {
        alert: {
          type: "error",
          message: err.toString(),
        },
      };

      return thunkApi.rejectWithValue(res);
    }
  }
);

// Get All Group Walkthroughs By Org
export const groupGetAllGroupWalkthroughsByOrg = createAsyncThunk(
  "group/getAllGroupWalkthroughsByOrg",
  async (org_id, thunkApi) => {
    try {
      const result = await callGETRetrieveAllGroupWalkthroughsByOrg(org_id);
      if (result && result.data) {
        const { msg, payload } = result.data;
        const res = {
          replyMsg: msg,
          replyPayload: payload,
        };
        return res;
      }
    } catch (err) {
      const errStatus = err.response.status; // Integer
      const errPayload = err.response.data;
      const errMsg = errPayload.msg;

      console.log(errStatus);
      console.log(errPayload);
      console.log(errMsg);

      const res = {
        alert: {
          type: "error",
          message: err.toString(),
        },
      };

      return thunkApi.rejectWithValue(res);
    }
  }
);

// Get Group Walkthrough
export const groupGetGroupWalkthrough = createAsyncThunk(
  "group/getGroupWalkthrough",
  async (walkthrough_group_id, thunkApi) => {
    try {
      const result = await callGETRetrieveGroupWalkthrough(
        walkthrough_group_id
      );
      if (result && result.data) {
        const res = {
          replyMsg: result.data.msg,
          replyPayload: result.data.payload,
        };
        return res;
      }
    } catch (err) {
      const errStatus = err.response.status; // Integer
      const errPayload = err.response.data;
      const errMsg = errPayload.msg;

      console.log(errStatus);
      console.log(errPayload);
      console.log(errMsg);

      const res = {
        alert: {
          type: "error",
          message: err.toString(),
        },
      };

      return thunkApi.rejectWithValue(res);
    }
  }
);

const groupState = {
  groupsMsg: "",
  groups: [],

  activeGroupMsg: "",
  activeGroup: null,

  addGroupMsg: "",
  addGroupPayload: {},

  removeGroupMsg: "",
  removeGroupPayload: {},

  appLoading: false,
  alert: alertState
};

const groupSlice = createSlice({
  name: "group",
  initialState: groupState,
  reducers: {
    resetAddNewGroupMsg: (state) => {
      state.addGroupMsg = "";
    },
    resetRemovingExistingGroupMsg: (state) => {
      state.removeGroupMsg = "";
    },
    resetAlert: (state) => {
      state.alert.active = false;
      state.alert.type = "";
      state.alert.message = "";
      state.alert.autoHideDuration = 6000;
    },
  },
  extraReducers: (builder) => {
    builder
      //reducer
      .addCase(groupAddNewGroup.pending, (state) => {
        state.appLoading = true;
      })
      .addCase(groupAddNewGroup.fulfilled, (state, action) => {
        state.addGroupMsg = action.payload.replyMsg;
        state.addGroupPayload = action.payload.replyPayload;
        state.appLoading = false;
      })
      .addCase(groupAddNewGroup.rejected, (state, action) => {
        const { type, message } = action.payload.alert;
        if (type !== undefined) {
          state.appLoading = false;
          state.alert.active = true;
          state.alert.type = type;
          state.alert.message = message;
          state.alert.autoHideDuration = action.payload.alert.autoHideDuration
            ? action.payload.alert.autoHideDuration
            : state.alert.autoHideDuration;
        }
      })

      .addCase(groupRemoveExistingGroup.pending, (state) => {
        state.appLoading = true;
      })
      .addCase(groupRemoveExistingGroup.fulfilled, (state, action) => {
        state.removeGroupMsg = action.payload.replyMsg;
        state.appLoading = false;
      })
      .addCase(groupRemoveExistingGroup.rejected, (state, action) => {
        const { type, message } = action.payload.alert;
        state.appLoading = false;
        state.alert.active = true;
        state.alert.type = type;
        state.alert.message = message;
        state.alert.autoHideDuration = action.payload.alert.autoHideDuration
          ? action.payload.alert.autoHideDuration
          : state.alert.autoHideDuration;
      })

      .addCase(groupGetAllGroupWalkthroughsByOrg.pending, (state) => {
        state.appLoading = true;
      })
      .addCase(groupGetAllGroupWalkthroughsByOrg.fulfilled, (state, action) => {
        const { replyMsg, replyPayload } = action.payload;
        const { walkthrough_groups } = replyPayload;
        state.groupsMsg = replyMsg;
        state.groups = [...walkthrough_groups];
        state.appLoading = false;
      })
      .addCase(groupGetAllGroupWalkthroughsByOrg.rejected, (state, action) => {
        const { type, message } = action.payload.alert;
        state.appLoading = false;
        state.alert.active = true;
        state.alert.type = type;
        state.alert.message = message;
        state.alert.autoHideDuration = action.payload.alert.autoHideDuration
          ? action.payload.alert.autoHideDuration
          : state.alert.autoHideDuration;
      })

      .addCase(groupGetGroupWalkthrough.pending, (state) => {
        state.appLoading = true;
      })
      .addCase(groupGetGroupWalkthrough.fulfilled, (state, action) => {
        state.activeGroupMsg = action.payload.replyMsg;
        state.activeGroup = action.payload.replyPayload;
        state.appLoading = false;
      })
      .addCase(groupGetGroupWalkthrough.rejected, (state, action) => {
        const { type, message } = action.payload.alert;
        state.appLoading = false;
        state.alert.active = true;
        state.alert.type = type;
        state.alert.message = message;
        state.alert.autoHideDuration = action.payload.alert.autoHideDuration
          ? action.payload.alert.autoHideDuration
          : state.alert.autoHideDuration;
      });
  },
});

const { reducer, actions } = groupSlice;
export const { resetAddNewGroupMsg, resetRemovingExistingGroupMsg } = actions;
const reducerGroup = reducer;


export default reducerGroup;
