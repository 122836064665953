import { Stack, ToggleButtonGroup } from "@mui/material";
import React from "react";
import CardHeader from "../../02-molecules/01-cards/CardHeader";
import GroupButtonChildren from "../../02-molecules/02-groupButton/GroupButtonChildren";
import GroupButtonContainer from "../../02-molecules/02-groupButton/GroupButtonContainer";
import ChangePaymentListItem from "../../02-molecules/03-ChangePaymentListItem/ChangePaymentListItem";

interface Props {
  onSubscriptionSelect: (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    value: string
  ) => void;
}

const ChangePaymentGroupButton = (props: Props) => {
  return (
    <GroupButtonContainer onChange={props.onSubscriptionSelect} size="large">
      <ChangePaymentListItem itemName="Free Plan" ButtonValue="FREE" />
      <ChangePaymentListItem itemName="Business Plan" ButtonValue="PAID1" />
      <ChangePaymentListItem
        itemName="Business Plus Plan"
        ButtonValue="PAID2"
      />
    </GroupButtonContainer>
  );
};

export default ChangePaymentGroupButton;
