import React, { useEffect, useRef, useState } from "react";
import { stackedBarWithLine } from "../../_chartGenerator/stackedBarWithLine";
import * as d3 from "d3";
import ComponentToggleLegend from "./ComponentToggleLegend";
import { formatDateForFilter } from "../../../utility/functions/formatDateForFilter";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import {
  functionGroupBy,
  functionGroupByWeek,
  generateEmptyAnalytic,
} from "../../_functions/FunctionGroupedBy";
import {
  AdditionalStatData,
  AnalyticDataForChart,
  AnalyticDataSet,
} from "../../../interfaces/interfaceAnalytic";
import { compareAsc, format } from "date-fns";
import ComponentOverviewSeenDismissed from "./ComponentOverviewSeenDismissed";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { convertDateToStandardFormat } from "../../_functions/FunctionDate";
import ComponentDashboard from "./MiniDetailedDashboard/ComponentDashboard";

const ComponentSeenDismissed = ({
  viewByDailyDisabled,
  selectionStartDate,
  selectionEndDate,
  selectionPublishedWalkthroughId,
}: {
  viewByDailyDisabled: string[];
  selectionPublishedWalkthroughId: string;
  selectionStartDate: Date;
  selectionEndDate: Date;
}) => {
  const [filterData, setFilterData] = useState<AnalyticDataForChart[]>();
  const [procDataSet, setProcDataSet] = useState<AnalyticDataSet[]>();
  const [dashboardData, setDashboardData] =
    useState<AnalyticDataForChart | null>(null);
  const [dashboardDataEmpty, setDashboardEmpty] = useState<string | null>(null);
  const [viewBy, setViewBy] = useState<"daily" | "weekly" | "monthly" | string>(
    "weekly"
  );
  const [selectedLegends, setSelectedLegends] = useState<string[]>([
    "completed",
    "dismissed",
    "incomplete",
    "launched",
  ]);
  const divRef = useRef<HTMLDivElement>(null);

  const walkthroughSessionData = useSelector(
    (state: RootState) => state.analytics.walkthroughSessions
  );

  // set dashboard data to null when changing date range/ published version / viewby
  useEffect(() => {
    setDashboardData(null);
    setDashboardEmpty(null);
  }, [
    viewBy,
    selectionEndDate,
    selectionEndDate,
    selectionPublishedWalkthroughId,
  ]);
  /** populate date that doesn't have seen/completed/dismissed */
  useEffect(() => {
    if (walkthroughSessionData) {
      const rawData = [...walkthroughSessionData];
      const dateStatusNull = [...rawData, ...generateEmptyAnalytic(rawData)];
      //@ts-ignore
      const sortDate = dateStatusNull.sort((a, b) => {
        return compareAsc(new Date(a.start_time), new Date(b.start_time));
      });
      setProcDataSet(sortDate);
    }
  }, [walkthroughSessionData]);

  useEffect(() => {
    /* filter data into look this:
    [
      {
        date: "26 June",
        seen: 8,
        dismissed: 3,
        completed: 4,
      },
    ]
  */
    if (procDataSet) {
      if (viewBy === "monthly") {
        //filter start date so it start from day 1 each month (eg: 1 May)
        const formatDateToMonth = (date: Date) => {
          return (
            "1 " +
            date.toLocaleString("en-us", {
              month: "long",
              year: "numeric",
            })
          );
        };
        const formatStartDate = procDataSet.map((d) => ({
          ...d,
          start_time: formatDateToMonth(new Date(d.start_time)),
        }));

        // filter the raw data each month
        setFilterData(functionGroupBy(formatStartDate));
      } else if (viewBy === "weekly") {
        setFilterData(functionGroupByWeek(procDataSet));
      } else {
        //format start date so it start from 00:00
        const formatStartDate = procDataSet.map((d) => ({
          ...d,
          start_time: formatDateForFilter(new Date(d.start_time)).toString(),
        }));
        // filter raw data each day
        setFilterData(functionGroupBy(formatStartDate));
      }
    }
  }, [viewBy, procDataSet]);

  const handleClickChart = (selectedBar: string) => {
    const month = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    /* dataSet for mini dashboard */
    if (filterData) {
      const findSelectedBar = filterData.find(
        (d) =>
          new Date(d.date).toISOString() === new Date(selectedBar).toISOString()
      );
      if (findSelectedBar !== undefined) {
        const selectedChart = { ...findSelectedBar };
        if (viewBy === "monthly") {
          const getMonthAndYear = (date: Date) => {
            return month[+date.getMonth()] + " " + date.getFullYear();
          };
          selectedChart.date = getMonthAndYear(new Date(selectedChart.date));
        } else {
          selectedChart.date = convertDateToStandardFormat(
            new Date(selectedChart.date)
          );
        }

        setDashboardData(selectedChart);
      } else if (findSelectedBar === undefined) {
        setDashboardData(null);
        setDashboardEmpty(new Date(selectedBar).toString());
      }
    }
  };

  useEffect(() => {
    if (filterData) {
      const chart = stackedBarWithLine({
        y1Label: "Completed",
        y2Label: "Dismissed",
        y3Label: "Incomplete",
        yLine: "Launched",
        y2Line: "Repeat Views",

        xValue: filterData.map((data) => new Date(data.date)),
        y1Value: filterData.map((data) => data.completed),
        y2Value: filterData.map((data) => data.dismissed),
        y3Value: filterData.map((data) => data?.incomplete),
        yLineValue: filterData.map((data) => data.seen),
        y2LineValue: filterData.map((data) => data.repeat),

        viewBy: viewBy,

        xScaleRange: [selectionStartDate, selectionEndDate],
      });

      //keep the chart to initial value
      if (chart) {
        // @ts-ignore
        chart.update({
          activeLegends: selectedLegends,
          format: viewBy,
          handleClickChart,
        });
        divRef.current?.appendChild(chart);
      }
    }

    const selectChart = d3.select(".chart");

    // cleanup element inside div every reload
    return () => {
      selectChart &&
        Object.keys(selectChart).length !== 0 &&
        selectChart.selectAll("*").remove();
    };
  }, [
    filterData,
    selectedLegends,
    viewBy,
    selectionEndDate,
    selectionStartDate,
  ]);

  // disabled daily when user select more than a week
  useEffect(() => {
    if (viewByDailyDisabled && viewByDailyDisabled.includes("daily")) {
      setViewBy("daily");
    } else {
      setViewBy("weekly");
    }
  }, [viewByDailyDisabled]);

  /* format date to DD/MM/YY */
  const formatDateForTitle = (date: Date) => {
    return format(date, "dd/MM/yyyy");
  };
  return (
    <div className="seen_dismissed">
      <div className="mt-6 mb-3 flex items-center">
        <h3 className="pageHeader1">Walkthrough Trend</h3>
        <span className="text-gray-400 font-normal">
          &nbsp;{" "}
          {`- ${formatDateForTitle(selectionStartDate)} to${" "}
          ${formatDateForTitle(selectionEndDate)}`}
        </span>
      </div>
      {filterData && <ComponentOverviewSeenDismissed dataSet={filterData} />}
      <div className="flex flex-col my-3 p-3 items-center">
        <div className="flex flex-col">
          <div className="view-selection">
            <FormControl>
              <InputLabel id="select-view-by">View By</InputLabel>
              <Select
                labelId="view-by-select-label"
                id="view-by-select"
                value={viewBy}
                defaultValue={"weekly"}
                label="View By"
                onChange={(e) => setViewBy(e.target.value)}
              >
                <MenuItem
                  disabled={
                    !viewByDailyDisabled.includes("daily") ? true : false
                  }
                  value={"daily"}
                >
                  Daily
                </MenuItem>
                <MenuItem
                  disabled={
                    !viewByDailyDisabled.includes("weekly") ? true : false
                  }
                  value={"weekly"}
                >
                  Weekly
                </MenuItem>
                <MenuItem
                  disabled={
                    !viewByDailyDisabled.includes("monthly") ? true : false
                  }
                  value={"monthly"}
                >
                  Monthly
                </MenuItem>
              </Select>
            </FormControl>
          </div>
          {/* Chart Goes here: */}
          <div className="chart" ref={divRef}></div>
        </div>
        <div>
          <ComponentToggleLegend
            setSelectedLegends={setSelectedLegends}
            selectedLegends={selectedLegends}
          />
        </div>
        {dashboardData !== null ? (
          <ComponentDashboard walkthroughDataSet={dashboardData} />
        ) : dashboardDataEmpty !== null ? (
          <h3 className="pageSettingsHeader font-bold mt-5">
            No Walkthrough Activity Found on [
            {convertDateToStandardFormat(dashboardDataEmpty)}]
          </h3>
        ) : null}
      </div>
    </div>
  );
};

export default ComponentSeenDismissed;
