import attachHTTPRequestHeaders from "../../utility/functions/attachHTTPRequestHeaders";
import { api } from "./_axios";

/*
    ===================================================================    
    CALLS-USER-SEGMENTATION API
    ===================================================================
*/

/** Retrieve a walkthrough's segmentation that was assigned by walkthrough_id */
export const callGETSingleWalkthroughUserSegmentation = async (
  walkthroughId: string
) => {
  const config = attachHTTPRequestHeaders(true);

  return api
    .get(`segmentation/user_segment/walkthrough/${walkthroughId}`, config)
    .then((res) => res)
    .catch((err) => err);
};

/** Retrieve the list of all user segmentation for an organization */
export const callGetListOfOrganizationUserSegmentation = async (
  orgId: string
) => {
  const config = attachHTTPRequestHeaders(true);

  return api
    .get(`segmentation/user_segment/list/${orgId}`, config)
    .then((res) => res)
    .catch((err) => err);
};

/** Retrieve the list of all user segmentation for an organization */
export const callDeleteUserSegmentation = async (
  userSegmentationId: string
) => {
  const config = attachHTTPRequestHeaders(true);

  return api
    .delete(`segmentation/user_segment/delete/${userSegmentationId}`, config)
    .then((res) => res)
    .catch((err) => err);
};

/** Assign a created user segmentation to a walkthrough */
export const callPOSTAssignUserSegmentationToWalkthrough = async (
  userSegmentationId: string | null,
  walkthroughId: string
) => {
  const config = attachHTTPRequestHeaders(true);

  const data = {
    userSegmentationId: userSegmentationId,
    walkthroughId: walkthroughId,
  };

  return api
    .post(`segmentation/user_segment/assign`, data, config)
    .then((res) => res)
    .catch((err) => err);
};

/** Create New User Segment */
export const callPOSTCreateSegment = async (params: any) => {
  const config = attachHTTPRequestHeaders(true);

  return api
    .post(`segmentation/user_segment/create`, params, config)
    .then((res) => res)
    .catch((err) => err);
};

/** Update existing User Segment */
export const callPOSTUpdateUserSegment = async ({
  segmentationId,
  segmentData,
}: {
  segmentationId: string;
  /** Object containing the User Segmentation data, including the rule groups */
  segmentData: any;
}) => {
  const config = attachHTTPRequestHeaders(true);

  return api
    .post(
      `segmentation/user_segment/update/${segmentationId}`,
      segmentData,
      config
    )
    .then((res) => res)
    .catch((err) => err);
};
