import React, { useEffect, useState } from "react";
import { AnalyticDataForChart } from "../../../interfaces/interfaceAnalytic";
import ComponentCard from "../ReusableComponents/ComponentCard";

const ComponentOverviewSeenDismissed = ({
  dataSet,
}: {
  dataSet: AnalyticDataForChart[];
}) => {
  interface OverviewData {
    seen: number;
    incomplete: number;
    dismissed: number;
    completed: number;
    firstTime: number;
    repeat: number;
    manual: number;
    automatic: number;
  }
  const [calculateData, setCalculateData] = useState<OverviewData>({
    seen: 0,
    incomplete: 0,
    dismissed: 0,
    completed: 0,
    firstTime: 0,
    repeat: 0,
    manual: 0,
    automatic: 0,
  });

  useEffect(() => {
    const countedData = dataSet.reduce<OverviewData>(
      (acc, curr) => {
        curr.completed && (acc.completed = acc.completed + curr.completed);
        curr.dismissed && (acc.dismissed = acc.dismissed + curr.dismissed);
        curr.incomplete && (acc.incomplete = acc.incomplete + curr.incomplete);
        curr.seen && (acc.seen = acc.seen + curr.seen);
        curr.repeat && (acc.repeat = acc.repeat + curr.repeat);
        curr.manual && (acc.manual = acc.manual + curr.manual);
        curr.automatic && (acc.automatic = acc.automatic + curr.automatic);
        return acc;
      },
      {
        seen: 0,
        incomplete: 0,
        dismissed: 0,
        completed: 0,
        firstTime: 0,
        repeat: 0,
        manual: 0,
        automatic: 0,
      }
    );
    setCalculateData(countedData);
  }, [dataSet]);

  //generate percen
  const generatePercentage = (a: number, b: number, c: number) => {
    let value = (a / (a + b + c)) * 100;
    let default_value = 0;

    /** If value is null, set it to a default_value of 0. */
    let result: string | number = value ? value : default_value;

    if (value > 0) {
      /** if value is more that zero, round to the nearest decimal */
      result = result.toFixed(1);
    } else {
      /** if value is equal to zero, do not add decimal */
      result = result.toFixed(0);
    }

    return result;
  };

  const { completed, dismissed, incomplete, seen, automatic, manual, repeat } =
    calculateData;

  return (
    <div className="overview mt-3 mb-3 flex justify-start gap-3 flex-wrap ">
      <ComponentCard
        cardType="normal"
        cardValue={`${seen.toString()} times`}
        cardText={"Launched"}
      />
      <ComponentCard
        cardType="percentage"
        percentage={generatePercentage(incomplete, dismissed, completed)}
        cardValue={incomplete.toString()}
        cardText={"Incomplete"}
      />
      <ComponentCard
        cardType="percentage"
        percentage={generatePercentage(dismissed, incomplete, completed)}
        cardValue={dismissed.toString()}
        cardText={"Dismissed"}
      />
      <ComponentCard
        cardType="percentage"
        percentage={generatePercentage(completed, incomplete, dismissed)}
        cardValue={completed.toString()}
        cardText={"Completed"}
      />
      <ComponentCard
        cardType="normal"
        cardValue={`${automatic.toString()} times`}
        cardText={"Automatic Launch"}
      />
      <ComponentCard
        cardType="normal"
        cardValue={`${manual.toString()} times`}
        cardText={"Manual Launch"}
      />
      <ComponentCard
        cardType="normal"
        cardValue={`${repeat.toString()} times`}
        cardText={"Repeat Launch"}
      />
    </div>
  );
};

export default ComponentOverviewSeenDismissed;
