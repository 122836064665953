import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ComponentAlertAppOverallSnackbar from "../components/ComponentAlertAppOverallSnackbar";

import ComponentListTeams from "../components/ComponentListTeams";
import ComponentLoading from "../components/ComponentLoading";
import {
  organizationGetCodeSnippetViewers,
  organizationGetOrganizationInfo,
} from "../store/reducers/reducerOrganization";
import {
  resetAlert,
  teamGetViewerTeamsByOrg,
} from "../store/reducers/reducerTeam";
import { RootState } from "../store";

const PageTeamViewer = () => {
  // State from redux
  const orgID = useSelector((state: RootState) => state.user.userData.org);
  // add owner
  const { admins, builders, viewerersCodeSnippet, viewers } = useSelector(
    (state: RootState) => state.org
  );

  const viewerTeams = useSelector(
    (state: RootState) => state.team.viewerTeamData
  );

  const removeViewerTeamMsg = useSelector(
    (state: RootState) => state.team.removeViewerTeamMsg
  );
  const addViewerTeamMsg = useSelector(
    (state: RootState) => state.team.addViewerTeamMsg
  );
  const isLoading = useSelector((state: RootState) => state.team.appLoading);
  const alert = useSelector((state: RootState) => state.team.alert);
  const [allTeamDataSet, setAllTeamDataSet] = useState<null | any[]>(null);
  const dispatch = useDispatch();
  useEffect(() => {
    // If user is logged in, who has a org in state.user.userData, initiate a getOrganizationInformation to pull data of existing organization to tally
    if (orgID && !allTeamDataSet) {
      dispatch(organizationGetOrganizationInfo(orgID));
      // dispatch(teamGetViewerTeamsByOrg(orgID));
      dispatch(organizationGetCodeSnippetViewers(orgID));
    }
  }, [dispatch, orgID]);

  useEffect(() => {
    if (!viewerTeams) {
      dispatch(teamGetViewerTeamsByOrg(orgID!));
    }
  }, [viewerTeams]);

  useEffect(() => {
    let adminsData = admins ? admins : [];
    let buildersData = builders ? builders : [];
    let viewersData = viewers ? viewers : [];
    let viewerCodeSnippetData = viewerersCodeSnippet
      ? viewerersCodeSnippet
      : [];
    if (adminsData && buildersData && viewersData && viewerCodeSnippetData) {
      /* Check if owner also in admins/builders/viewers list. if yes remove it. so it's not duplicated*/
      const addedRoleCSViewers = viewerCodeSnippetData.map((d: any) => {
        return {
          ...d,
          role: "cs_viewer",
        };
      });

      setAllTeamDataSet([
        ...adminsData,
        ...buildersData,
        ...viewersData,
        ...addedRoleCSViewers,
      ]);
    }
  }, [admins, builders, viewerersCodeSnippet, viewers]);

  //when remove builder team msg is updated (i.e. success, load new builder list)
  useEffect(() => {
    if (removeViewerTeamMsg.length > 0 || addViewerTeamMsg.length > 0) {
      dispatch(teamGetViewerTeamsByOrg(orgID!));
    }
  }, [removeViewerTeamMsg, addViewerTeamMsg, dispatch, orgID]);

  return (
    <div className="pageBase">
      {/*Builder list*/}
      <ComponentLoading show={isLoading} />
      {allTeamDataSet && (
        <ComponentListTeams
          displayUserRole={"Viewer"}
          displayUserRoleFull={"Viewer"}
          displayShowAddBtn={true}
          introMsg={
            "Manage who can view walkthroughs by putting them in teams."
          }
          dataSet={viewerTeams}
          teamDataSet={allTeamDataSet}
          indicatorMsg={"No viewer teams found. Please add one!"}
        />
      )}

      {alert.type !== "" && alert.type !== undefined && (
        <ComponentAlertAppOverallSnackbar
          alert={alert}
          reset={() => dispatch(resetAlert())}
        />
      )}
    </div>
  );
};

export default PageTeamViewer;
