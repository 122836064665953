import attachHTTPRequestHeaders from "../../utility/functions/attachHTTPRequestHeaders";
import { api } from "./_axios";

/*
    ===================================================================    
    CALLS-ANALYTICS API
    ===================================================================
*/

export const callPOSTRetrieveAnalyticsDataForSingleWalkthrough = async (
  walkthroughId: string,
  apiKey: string,
  orgId: string
) => {
  const config = attachHTTPRequestHeaders(true);

  const data = {
    walkthroughId: walkthroughId,
    orgId: orgId,
    apiKey: apiKey,
    firstTimeView: true,
    totalViews: true,
    avgTimeToFinish: true,
    totalManualLaunch: true,
    totalAutomaticLaunch: true,
    medianTime: true,
    totalRepeat: true,
    avgLaunch: true,
    longestTime: true,
    shortestTime: true,
  };
  return api
    .post(`analytic/singleWalkthrough/data`, data, config)
    .then((res) => res)
    .catch((err) => err);
};

/**
 * Get the walkthrough sessions and activities for a walkthrough,
 * within a date range, for specific viewer team
 * and for a specified published walkthrough id
 * @param viewerTeamId  Viewer team we want to check against
 * @param startDate     The date we want to start our search
 * @param endDate       The limit where we want to end our search
 * @param publishedWalkthroughId  The id of the published walkthrough version
 * @returns
 */
export const callPOSTRetrieveWalkthroughSessionAndActivity = async (
  viewerTeamId: string,
  startDate: string,
  endDate: string,
  publishedWalkthroughId: string
) => {
  const config = attachHTTPRequestHeaders(true);

  const data = {
    viewerTeamId: viewerTeamId,
    startDateRange: startDate,
    endDateRange: endDate,
    publishedWalkthroughId: publishedWalkthroughId,
  };
  return api
    .post(`analytic/session/get`, data, config)
    .then((res) => res)
    .catch((err) => err);
};
