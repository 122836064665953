import React, { useState } from "react";
import { HostWalkthrough } from "../interfaces/interface Organization";
import ComponentListTable from "./ComponentListTable";

interface Props {
  dataSet: HostWalkthrough[];
}

const ComponentListHostInTable = ({ dataSet }: Props) => {
  const [selectedWalkthroughList, setSelectedWalkthroughList] = useState([]);
  const [resetTableSelectAll, setResetTableSelectAll] = useState(false);

  // Sets the default HeaderCells
  let headerCells = [
    {
      id: "status",
      label: "Status",
      numeric: false,
      disablePadding: false,
    },
    {
      id: "name",
      label: "Walkthrough",
      numeric: false,
      disablePadding: false,
      flex: 1,
    },
    {
      id: "hostName",
      label: "Host Name",
      numeric: false,
      disablePadding: false,
      flex: 1,
    },
    {
      id: "date",
      label: "Date Created",
      numeric: false,
      disablePadding: false,
      flex: 1,
    },
    {
      id: "action",
      label: "Action",
      numeric: false,
      disablePadding: false,
      flex: 1,
    },
  ];
  return (
    //TODO: convert modular component to ts
    //@ts-ignore
    <ComponentListTable
      listType={"hostWalkthrough"}
      headerCells={headerCells}
      dataSet={dataSet}
      setSelectedWalkthroughList={setSelectedWalkthroughList}
      resetTableSelectAll={resetTableSelectAll}
      setResetTableSelectAll={setResetTableSelectAll}
    />
  );
};

export default ComponentListHostInTable;
