

// Gather only selected group objects from list for processing
export const retrieveBulkGroupObjects = (groupNameList, dataSet) => {
    let groupObjects = [];
    for (let i = 0; i < groupNameList.length; i++) {
        for (let j = 0; j < dataSet.length; j++) {
            if (groupNameList[i] === dataSet[j].name) {
                groupObjects.push(dataSet[j]);
                break;
            }
        }
    }

    return groupObjects;
};