import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import BackgroundSwirlWrapper from "../components/01-atoms/BackgroundSwirlWrapper";
import ComponentStripeCheckout from "../components/Payment/ComponentStripeCheckout";
import { RootState } from "../store";
import { resetCheckOutPage } from "../store/reducers/reducerPayment";
import PageNotFound from "./PageNotFound";

const PageCheckOut = () => {
  const checkOutLink = useSelector(
    (state: RootState) => state.payment.checkOutLink
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetCheckOutPage());
  }, []);

  if (checkOutLink) {
    return (
      <div style={{ width: "100%", height: "700px" }}>
        <BackgroundSwirlWrapper>
          <ComponentStripeCheckout url={checkOutLink} />
        </BackgroundSwirlWrapper>
      </div>
    );
  } else {
    return <PageNotFound />;
  }
};

export default PageCheckOut;
