import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { organizationChangeUserRole } from "../store/reducers/reducerOrganization";
import { EmptyModal, Button, Radio } from "usertip-component-library";

const ComponentMoveUserBetweenRoles = ({
  selectedUserList,
  currentRole,

  showModalMove,
  setShowModalMove,

  moveMode,

  setResetTableSelectAll,
}) => {
  // check the role of the list of users, using currentRole parameter
  const [currentSelectedRole, setCurrentSelectedRole] = useState(currentRole);
  const [confirmBtnDisabled, setConfirmBtnDisabled] = useState(true);
  const theIndex = useSelector((state) => state.org.theIndex);

  const dispatch = useDispatch();
  const handleMoveUserBetweenRolesFormSubmission = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (moveMode === "single") {
      // Move user between roles
      const org_id = selectedUserList[theIndex].org_id;
      const orgData = {
        org_id: org_id,
        new_role: currentSelectedRole,
        users: [selectedUserList[theIndex]],
      };
      dispatch(organizationChangeUserRole(orgData));
      handleHideModal();
    } else if (moveMode === "bulk") {
      if (selectedUserList) {
        for (let i = 0; i < selectedUserList.length; i++) {
          // TODO: SHAFIQ: Move each user between roles from "selectedUserEmailList"
        }
      }

      // Resets the "Select All" control on the table.
      setResetTableSelectAll(true);
      handleHideModal();
    }
  };

  // Modal close handler
  const handleHideModal = () => {
    setCurrentSelectedRole(selectedUserList[theIndex].role);
    setConfirmBtnDisabled(true);
    setShowModalMove(false);
  };

  // Handles when radio button choice is selected
  const handleRadioButtonSelectChange = (value) => {
    // Updates active selected radio button
    setCurrentSelectedRole(value);

    // Disable Confirm btn if selected role is the same
    if (value === selectedUserList[theIndex].role) {
      setConfirmBtnDisabled(true);
    } else {
      setConfirmBtnDisabled(false);
    }
  };

  return (
    <EmptyModal
      isOpen={showModalMove}
      onClose={handleHideModal}
      size="large"
      customClass="ut-py-5"
    >
      <div className="modalHeader">
        {moveMode === "single"
          ? `Confirm Move User Between Roles?`
          : moveMode === "bulk"
          ? `Confirm Move Bulk User(s) Between Roles?`
          : ""}
      </div>

      {moveMode === "single" && (
        <div className="modalSubHeader">
          Select a new Role option for{" "}
          <b>{`${
            selectedUserList[theIndex] ? selectedUserList[theIndex].name : ""
          } (${
            selectedUserList[theIndex] ? selectedUserList[theIndex].email : ""
          })`}</b>
          .
        </div>
      )}
      {moveMode === "bulk" && (
        <>
          <div className="modalSubHeader">
            Select a new Role option for the following Bulk movement of user(s):
          </div>

          <ol className="list-decimal list-inside mb-8">
            {selectedUserList &&
              selectedUserList.map((user) => (
                <li key={user.email}>{`${user.name} (${user.email})`}</li>
              ))}
          </ol>
        </>
      )}

      <form
        noValidate
        autoComplete="off"
        onSubmit={handleMoveUserBetweenRolesFormSubmission}
        className="modalForm"
      >
        <div className="flex flex-col gap-4">
          <Radio
            name="controlled-radio-buttons-group"
            id="admin"
            value="admin"
            label="Administrator"
            selectedValue={currentSelectedRole}
            onSelectedValue={handleRadioButtonSelectChange}
          />
          <Radio
            name="controlled-radio-buttons-group"
            id="builder"
            value="builder"
            label="Builder"
            selectedValue={currentSelectedRole}
            onSelectedValue={handleRadioButtonSelectChange}
          />
          <Radio
            name="controlled-radio-buttons-group"
            id="viewer"
            value="viewer"
            label="Viewer"
            selectedValue={currentSelectedRole}
            onSelectedValue={handleRadioButtonSelectChange}
          />
        </div>

        <div className="flex justify-end gap-2">
          <Button
            variant="primary"
            color="primary"
            size="medium"
            text="Cancel"
            onClick={handleHideModal}
          />
          <Button
            variant="primary"
            color="primary"
            size="medium"
            text="Confirm"
            disabled={confirmBtnDisabled}
          />
        </div>
      </form>
    </EmptyModal>
  );
};

export default ComponentMoveUserBetweenRoles;
