import React, { useEffect, useState } from "react";
// import imageDetail from "../../../data/imageList.json";
import PictureViewer from "../../03-organisms/05-pictureViewer/PictureViewer";
import { PictureGuideData } from "../../../interfaces/interfaceGuide";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
// import { addGuidePicture } from "../../../store/reducers/reducerGuide";
import { IconButton } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import DeleteIcon from "@mui/icons-material/Delete";
import { getImageListFromPresign } from "../../../axios/v2/callsMedia";
import { DetailedImgObj } from "../../../store/reducers/reducerMedia";
import { GETSingleSnapImageCall } from "../../../axios/v2/callsQuickBuilder";
import StepNameEditMode from "../../02-molecules/05-picture/StepNameEditMode";

interface Props {
  editMode: boolean;
  setEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  setPictureStep: React.Dispatch<
    React.SetStateAction<PictureGuideData[] | undefined>
  >;
  pictureStep: PictureGuideData[];
  snapName: string;
  setSnapName: React.Dispatch<React.SetStateAction<string>>;
  setIsSnapContentChanged: React.Dispatch<React.SetStateAction<boolean>>;
}

const PictureViewers = ({
  editMode,
  setEditMode,
  setPictureStep,
  pictureStep,
  setSnapName,
  snapName,
  setIsSnapContentChanged,
}: Props) => {
  const pictureGuideData = useSelector(
    (state: RootState) => state.guide.snapGuide
  );

  /** Useeffect for converting raw json data into picture data.)
   */
  useEffect(() => {
    // @ts-ignore
    const data: PictureGuideData[] = pictureGuideData
      ? pictureGuideData.snap_component.map(async (item, index) => {
          const getImage = await retrieveImageForStep(item.media_id!);

          if (item.content_type === "image" && !getImage) {
            throw new Error("Could not load the image");
          }

          return {
            ...(item.content_type === "image" &&
              getImage && {
                screen_capture_details: {
                  // image_url: item.screen_capture_details.image_url,
                  image_url: getImage!.img_url,
                  image_name: getImage!.img_name,
                },
              }),
            timeStamp: item.click_metadata.timestamp,
            textContent: item.description ? item.description : item.content,
            position: {
              x: item.click_metadata.position.x,
              y: item.click_metadata.position.y,
            },
            id: item.snap_component_id,
          };
        })
      : [];
    setPictureStep(data);
  }, []);

  const handleAddTagClick = (position: number) => {
    const today = Date.now();
    const newObject = {
      screen_capture_details: {
        image_url: "",
        image_name: "",
      },
      timeStamp: today,
      textContent: "Click here to edit text",
      position: {
        x: 0,
        y: 0,
      },
      id: today.toString(),
      status: "new_text",
    };
    const arrayCopy = [...pictureStep];
    arrayCopy.splice(position, 0, newObject);
    setPictureStep(arrayCopy);
  };

  const handleAddImage = (position: number) => {
    const today = Date.now();
    const newObject = {
      screen_capture_details: {
        image_url: "",
        image_name: "",
      },
      timeStamp: today,
      textContent: "Click here to edit text",
      position: {
        x: 0,
        y: 0,
      },
      id: today.toString(),
      status: "new_image",
    };
    const arrayCopy = [...pictureStep];
    arrayCopy.splice(position, 0, newObject);
    setPictureStep(arrayCopy);
  };

  /**handle edit step name state*/
  function onEditStepName(id: string, value: string) {
    setIsSnapContentChanged(true);
    const updatedStepName = pictureStep?.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          textContent: value,
        };
      }
      return item;
    });
    setPictureStep(updatedStepName);
  }

  const deleteHandler = (value: string) => {
    const filteredStep = pictureStep?.filter((item) => item.id !== value);
    setIsSnapContentChanged(true);
    setPictureStep(filteredStep);
  };

  const imageHandler = (componentId: string, imageUrl: string, data: any) => {
    const updatedStepName = pictureStep?.map((value) => {
      if (value.id === componentId) {
        if (data) {
          return {
            ...value,
            screen_capture_details: {
              image_name: data.name ? data.name : "",
              image_url: imageUrl,
            },
            imageData: data,
          };
        } else {
          const newData = { ...value };

          delete newData.imageData;
          delete newData.screen_capture_details;

          return newData;
        }
      }
      return value;
    });

    setPictureStep(updatedStepName);
  };

  return (
    <div className=" h-full px-20 py-3 flex flex-col gap-3">
      {pictureStep?.map(
        (
          {
            screen_capture_details,
            id,
            timestamp,
            position,
            textContent,
            status,
          },
          index
        ) => {
          return (
            <div
              className="justify-center items-center flex gap-2 flex-col"
              key={id ? id : index}
            >
              {/* only display plus icon when edit mode enable */}
              {editMode && (
                <div className="flex gap-5 items-center">
                  <IconButton
                    aria-label={"add-step"}
                    size={"medium"}
                    sx={{
                      color: "#C8BCF9",
                      padding: "0px",
                      "&:hover": {
                        color: "#9179F4",
                        backgroundColor: "transparent",
                      },
                    }}
                    onClick={() => handleAddTagClick(index)}
                    title="Add Text Step"
                  >
                    <AddCircleIcon fontSize={"small"} />
                  </IconButton>
                  <div
                    className="text-default-primary-lighter hover:text-default-primary-light cursor-pointer"
                    title="Add Image Step"
                    onClick={() => handleAddImage(index)}
                  >
                    <AddPhotoAlternateIcon fontSize="medium" />
                  </div>
                </div>
              )}
              <div className="flex items-center w-[682px]">
                <PictureViewer
                  imageUrl={screen_capture_details?.image_url}
                  key={id}
                  index={index + 1}
                  position={position}
                  editMode={editMode}
                  id={id}
                  elementText={textContent}
                  onEditStepName={onEditStepName}
                  status={status}
                  imageHandler={imageHandler}
                />
                {editMode && (
                  <div
                    className="ml-4 cursor-pointer text-default-error-50 hover:text-default-error-70"
                    title="Remove Step"
                    onClick={() => deleteHandler(id)}
                  >
                    <DeleteIcon />
                  </div>
                )}
              </div>
              {/* Only add icon button on the end of array */}
              {/* only display plus icon when edit mode enable */}
              {pictureGuideData &&
                editMode &&
                index === pictureStep.length - 1 && (
                  <div className="flex gap-5 items-center">
                    <IconButton
                      aria-label={"add-step"}
                      size={"medium"}
                      sx={{
                        color: "#C8BCF9",
                        padding: "0px",
                        "&:hover": {
                          color: "#9179F4",
                          backgroundColor: "transparent",
                        },
                      }}
                      onClick={() => handleAddTagClick(index + 1)}
                      title="Add Text Step"
                    >
                      <AddCircleIcon fontSize={"small"} />
                    </IconButton>
                    <div
                      className="text-default-primary-lighter hover:text-default-primary-light cursor-pointer"
                      title="Add Image Step"
                      onClick={() => handleAddImage(index + 1)}
                    >
                      <AddPhotoAlternateIcon fontSize="medium" />
                    </div>
                  </div>
                )}
            </div>
          );
        }
      )}
    </div>
  );
};

const retrieveImageForStep = async (image_id: string) => {
  /**Get the presigned url */
  const response = await GETSingleSnapImageCall(image_id);
  if (response.data.payload.mediaObject) {
    const imageData = response.data.payload.mediaObject;
    /**Get the image from presigned url */
    const imgFile = await getImageListFromPresign(imageData.presignedUrl);

    /**Data is Image informations */
    const data: DetailedImgObj = {
      img_name: imageData.name,
      /**Convert the image to URL  */
      img_url: URL.createObjectURL(imgFile),
      media_id: imageData.mediaId,
      owner_email: imageData.ownerEmail,
      date_uploaded: imageData.dateuploaded,
      date_modified: imageData.datemodified,
    };
    return data;
  } else {
    return null;
  }
};

export default PictureViewers;
