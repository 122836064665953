import { Input } from "@mui/material";
import React from "react";
import { InputField } from "usertip-component-library";

interface Props {
  value: string;
  setValue: (value: string) => void;
}

const StepNameEditMode = ({ setValue, value }: Props) => {
  return (
    <InputField
      variant="text"
      type="string"
      value={value}
      onChange={(e) => setValue(e.target.value)}
    />
  );
  // return <Input value={value} onChange={(e) => setValue(e.target.value)} />;
};

export default StepNameEditMode;
