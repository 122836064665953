import React, { useState } from "react";
import { Button, EmptyModal, Modal, Radio } from "usertip-component-library";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import { useNavigate } from "react-router-dom";
import { Walkthrough } from "../../../interfaces/interface Organization";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import Title from "../../01-atoms/01-typography/Title";
import LinkIcon from "@mui/icons-material/Link";
import ComponentAlertSnackbar from "../../ComponentAlertSnackbar";

interface Props {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  open: boolean;
  onConfirm: () => void;
  selectedValue: string;
  onSelectedValue: (value: string) => void;
  walkthrough_data: Walkthrough | null;
  snapId: string;
  guideId?: string;
  shareId?: string;
  option?: string;
}

const ModalSnapSharingSetting = ({
  setOpen,
  open,
  selectedValue,
  onSelectedValue,
  walkthrough_data,
  onConfirm,
  snapId,
  guideId,
  shareId,
  option,
}: Props) => {
  console.log(option, guideId, snapId, "snap");
  const shareLink = useSelector((state: RootState) => state.guide.activeLink);
  const navigation = useNavigate();

  //state for showing alert snackbar when url is copied
  const [showAlertSnackbar, setShowAlertSnackbar] = useState(false);

  //handler for radio button
  const onSelectedValueHandler = (value: string) => {
    onSelectedValue(value);
  };

  //handler for copying url
  const onCopyUrlClick = () => {
    if (shareId && guideId) {
      const publicUrl = `https://app.usertip.com/snap/public/${shareId}/${guideId}`;
      const privateUrl = `https://app.usertip.com/snap/private/${shareId}/${guideId}`;
      const generatedUrl = selectedValue === "public" ? publicUrl : privateUrl;
      navigator.clipboard.writeText(generatedUrl);
      setShowAlertSnackbar(true);
      setShowAlertSnackbar(true);
    }
  };

  //sharing permission radio modal content
  const ModalContent = () => {
    return (
      <div className="flex flex-col gap-3">
        <div>
          <Radio
            id="public"
            name="Public"
            label="Public"
            value="public"
            selectedValue={selectedValue}
            onSelectedValue={() => onSelectedValueHandler("public")}
          />
        </div>
        <Radio
          id="private"
          name="Private"
          label="Private"
          value="private"
          selectedValue={selectedValue}
          onSelectedValue={() => onSelectedValueHandler("private")}
        />
        <div>
          <Radio
            id="organization"
            name="Share to Organization"
            label="Share to Organization"
            value="organization"
            selectedValue={selectedValue}
            onSelectedValue={() => onSelectedValueHandler("organization")}
          />
        </div>
        {selectedValue === "private" && (
          <Button
            color="primary"
            size="small"
            variant="primary"
            iconLeft
            icon={<PersonAddAlt1Icon />}
            text=" Select permission"
            onClick={() => {
              navigation(
                `/guides/walkthroughs/${walkthrough_data?.walkthrough_id}/settings/viewing-permissions`
              );
            }}
          />
        )}
      </div>
    );
  };

  return (
    // modal for sharing settings
    <EmptyModal isOpen={open} size="large" onClose={() => setOpen(false)}>
      {/* header modal */}
      <div className="pt-6 px-6">
        <Title header="h1" size="xl" messageText="Sharing Settings" />
      </div>
      {/* Separator line */}
      <div
        className={
          "w-full ml-[-20px] absolute border-b border-default-neutral-30 "
        }
      ></div>
      {/* Modal Content */}
      <div className="px-6 mt-6">
        <ModalContent />

        {/* Modal Footer and buttons */}
        <div className="my-6 px-6 flex justify-between">
          <Button
            disabled={option !== selectedValue}
            color="primary"
            size="small"
            variant="primary"
            icon={<LinkIcon />}
            iconLeft
            text="Copy link"
            onClick={() => {
              onCopyUrlClick();
            }}
          />

          <div className="flex gap-2">
            <Button
              variant="primary"
              text="Confirm"
              onClick={() => {
                onConfirm();
              }}
              size="small"
              color="primary"
            />
            <Button
              variant="primary"
              text="Cancel"
              onClick={() => {
                setOpen(false);
              }}
              size="small"
              color="secondary"
            />
          </div>
        </div>
        {/* alert component */}
        <ComponentAlertSnackbar
          showAlertSnackbar={showAlertSnackbar}
          setShowAlertSnackbar={setShowAlertSnackbar}
          variant={"info"}
          title="Url Copied"
        />
      </div>
    </EmptyModal>
  );
};

export default ModalSnapSharingSetting;
