import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ComponentAlertAppOverallSnackbar from "../components/ComponentAlertAppOverallSnackbar";

import ComponentListUsers from "../components/ComponentListUsers";
import ComponentLoading from "../components/ComponentLoading";
import {
  organizationGetOrganizationInfo,
  resetAlert,
} from "../store/reducers/reducerOrganization";
import { RootState } from "../store";
import TableOrgAdmin from "../components/Table/TableOrgAdmin";

const PageOrganizationAdministrator = () => {
  const { userData } = useSelector((state: RootState) => state.user);
  const orgID = userData.org;
  const { admins, emailInviteMsg, appLoading, alert } = useSelector(
    (state: RootState) => state.org
  );

  const dispatch = useDispatch();

  useEffect(() => {
    // If user is logged in, who has a org in state.user.userData, initiate a getOrganizationInformation to pull data of existing organization to tally

    console.log("--- PageOrganizationAdministrator orgId", orgID);
    if (orgID) {
      dispatch(organizationGetOrganizationInfo(orgID));
    }
  }, [dispatch, orgID]);

  useEffect(() => {
    if (emailInviteMsg && orgID) {
      console.log(
        "--- PageOrganizationAdministrator orgId emailInviteMsg",
        orgID,
        emailInviteMsg
      );
      dispatch(organizationGetOrganizationInfo(orgID));
    }
  }, [emailInviteMsg, dispatch, orgID]);

  return (
    <div className="pageBase">
      <ComponentLoading show={appLoading} />
      {/*Admin list*/}
      {/* <ComponentListUsers
        displayUserRole={"Admin"}
        displayUserRoleFull={"Administrator"}
        displayShowAddBtn={true}
        introMsg={
          "Administrators are able to add builders and viewers to an organization"
        }
        dataSet={admins}
        indicatorMsg={"No administrators found. Please add one!"}
        metaDataTypes={null}
      /> */}
      {/* SOME BIG ERROR HERE AS PROBLEM SOURCE */}
      {alert.type !== "" &&
        alert.type !== undefined &&
        alert.type !== "success" && (
          <ComponentAlertAppOverallSnackbar
            alert={alert}
            reset={() => dispatch(resetAlert())}
          />
        )}
      {/* NEW TABLE COMPONENT */}
      <TableOrgAdmin />
    </div>
  );
};

export default PageOrganizationAdministrator;
