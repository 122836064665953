import React from "react";
import { useSelector } from "react-redux";
import ComponentInstallation from "../components/ComponentInstallation";
import ComponentInstallationSPA from "../components/ComponentInstallationSPA";
import ComponentInstallationWPA from "../components/ComponentInstallationWPA";
import { RootState } from "../store";
import { isUserAllowedAccess } from "../components/wrappers/ProtectedRoute";

const PageInstallationGuide = ({ articleType }: { articleType: string }) => {
  const api_key = useSelector(
    (state: RootState) => state.user.userData.api_key
  );
  const { can_trial, paid_status } = useSelector(
    (state: RootState) => state.org
  );
  const orgPlanInfo = useSelector((state: RootState) => state.org.org_plan);

  switch (articleType) {
    case "spa":
      return (
        <div id="spa">
          {isUserAllowedAccess(can_trial, paid_status) &&
          orgPlanInfo &&
          orgPlanInfo.plan_tier.name !== "MICRO_PLAN" ? (
            <>
              <ComponentInstallation
                api_key={api_key}
                title={"Installation for SPA websites"}
              >
                <ComponentInstallationSPA api_key={api_key} />
              </ComponentInstallation>
            </>
          ) : (
            <div>Subscribe to our Starter or Business Plan to get access</div>
          )}
        </div>
      );
    case "mpa":
      return (
        <div id="mpa">
          {isUserAllowedAccess(can_trial, paid_status) &&
          orgPlanInfo &&
          orgPlanInfo.plan_tier.name !== "MICRO_PLAN" ? (
            <>
              <ComponentInstallation
                api_key={api_key}
                title={"Installation for MPA websites"}
              >
                <ComponentInstallationWPA api_key={api_key} />
              </ComponentInstallation>
            </>
          ) : (
            <div>Subscribe to our Starter or Business Plan to get access</div>
          )}
        </div>
      );
    default:
      return <div>Page Error</div>;
      break;
  }
};

export default PageInstallationGuide;
