import React, { useEffect, useState } from "react";

import ComponentEditListGenerator from "./ComponentEditListGenerator";
import ComponentAlertSnackbar from "./ComponentAlertSnackbar";

import { not, union, numberOfChecked } from "./_functions/FunctionEditList";
import { useDispatch } from "react-redux";
import { EmptyModal, Button } from "usertip-component-library";

const ComponentAddWalkthroughsPermissions = ({
  dataSet,

  showModalAdd,
  setShowModalAdd,

  walkthroughID,

  handleViewerFormSubmission,
  handleViewerTeamFormSubmission,

  addState,
}) => {
  const [showAlertSnackbar, setShowAlertSnackbar] = useState(false);
  const [initialFirstDataLoad, setInitialFirstDataLoad] = useState("waiting");

  const membersListName = `All Users`;
  // Setting List-Store Variables
  const [checked, setChecked] = useState([]);

  // "permissionDataSet" will store Existing "Builders / Viewers" List
  // Left-Hand-Side List Data
  const [membersList, setMembersList] = useState([]);

  const dispatch = useDispatch();

  // Tracks changes in "permissionDataSet". Update when new change is available.
  useEffect(() => {
    if (initialFirstDataLoad === "waiting" && dataSet && dataSet.length > 0) {
      setMembersList([...dataSet]);

      setInitialFirstDataLoad("done");
    }
  }, [dataSet, initialFirstDataLoad]);

  const handleAddViewingPermissionsFormSubmission = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (addState === "Viewing Permissions") {
      const walkthroughData = {
        walkthrough_id: walkthroughID,
        users: checked,
      };
      dispatch(handleViewerFormSubmission(walkthroughData));
      setShowModalAdd(false);
    } else if (addState === "Viewer Teams Permissions") {
      const groupData = {
        walkthrough_id: walkthroughID,
        teams: checked,
      };
      dispatch(handleViewerTeamFormSubmission(groupData));
      setShowModalAdd(false);
    }
  };

  // Modal close handler
  const handleHideModal = () => {
    // Need to RESET all the original fields if "CONFIRM" Button Not Clicked
    setMembersList([...dataSet]);
    // RESET Snackbar as well
    setShowAlertSnackbar(false);

    setShowModalAdd(false);
  };

  return (
    <EmptyModal
      isOpen={showModalAdd}
      onClose={handleHideModal}
      size="large"
      customClass="ut-modal-lg-fix"
    >
      <div className="modalHeader">Add New {addState}</div>

      <div className="modalSubHeader"></div>

      <form
        noValidate
        autoComplete="off"
        onSubmit={handleAddViewingPermissionsFormSubmission}
        className="modalForm"
      >
        <div className="inline-flex mb-8">
          <ComponentEditListGenerator
            numberOfChecked={numberOfChecked}
            checked={checked}
            setChecked={setChecked}
            not={not}
            union={union}
            title={membersListName}
            members={membersList}
            displayDetails={"withRole"}
          />
        </div>

        <div className="flex justify-end gap-2">
          <Button
            variant="primary"
            color="primary"
            size="medium"
            text="Cancel"
            onClick={handleHideModal}
          />
          <Button
            variant="primary"
            color="primary"
            size="medium"
            text="Confirm"
          />
        </div>
      </form>

      <ComponentAlertSnackbar
        showAlertSnackbar={showAlertSnackbar}
        setShowAlertSnackbar={setShowAlertSnackbar}
        variant={"error"}
        title="Please make changes to Viewer Team Permissions List to Confirm."
      />
    </EmptyModal>
  );
};

export default ComponentAddWalkthroughsPermissions;
