import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ComponentAlertAppOverallSnackbar from "../components/ComponentAlertAppOverallSnackbar";
import ComponentLoading from "../components/ComponentLoading";
import { RootState } from "../store";
import { authChangePassword, resetAlert } from "../store/reducers/reducerAuth";
import PasswordForm from "../components/03-organisms/05-form/PasswordForm";
import { Button } from "usertip-component-library";

const PageAccountChangePassword = () => {
  const isLoading = useSelector((state: RootState) => state.auth.appLoading);
  const alert = useSelector((state: RootState) => state.auth.alert);
  const passChanged = useSelector(
    (state: RootState) => state.auth.isPasswordChanged
  );

  const dispatch = useDispatch();

  const [password, setPassword] = useState<string>("");
  const [oldPassword, setOldPassword] = useState<string>("");

  const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const pwdData = {
      previous_password: oldPassword,
      new_password: password,
    };
    dispatch(authChangePassword(pwdData));
  };

  return (
    <>
      <ComponentLoading show={isLoading} />
      <div className="mx-auto h-[95vh] flex flex-col justify-center items-center gap-10">
        {!passChanged && (
          <>
            <h1 className="text-5xl font-medium text-default-neutral-100 text-center">
              Change Your Password
            </h1>
            <PasswordForm
              type="change"
              onSubmitHandler={submitHandler}
              password={password}
              setPassword={setPassword}
              oldPassword={oldPassword}
              setOldPassword={setOldPassword}
            />
          </>
        )}
        {passChanged && (
          <>
            <h1 className="text-5xl font-medium text-default-neutral-100 text-center">
              Password Succesfully Changed
            </h1>
            <Button
              color="primary"
              variant="primary"
              size="medium"
              text="Back To Walkthroughs"
              onClick={() => {
                window.location.replace("/");
              }}
            />
          </>
        )}
      </div>
      {alert.type !== "" && alert.type !== undefined && (
        <ComponentAlertAppOverallSnackbar
          alert={alert}
          reset={() => dispatch(resetAlert())}
        />
      )}
    </>
  );
};

export default PageAccountChangePassword;
