import Axios from 'axios';

const prod = "https://fzik0rzclj.execute-api.ap-southeast-1.amazonaws.com/dev"
const analytics_api = "https://qqtxgm1mye.execute-api.ap-southeast-1.amazonaws.com/analytics/"

const apiV2_route1 = "https://fq588h7knf.execute-api.ap-southeast-2.amazonaws.com/dev/" //serverless.yml
const apiV2_route2 = "https://srg2kvlyt3.execute-api.ap-southeast-2.amazonaws.com/dev/" //serverless2.yml

export const axios = Axios.create({
    baseURL: prod
});

export const axios_analytics = Axios.create({
    baseURL: analytics_api
});

export const axios_apiV2_route1 = Axios.create({
    baseURL: apiV2_route1
});

export const axios_apiV2_route2 = Axios.create({
    baseURL: apiV2_route2
});