import { createTheme } from "@mui/material/styles";


// Change global styling properties here.
const theme = createTheme({
  // Color scheme and typography.
  palette: {
    primary: {
      light: "#a8d5ff",
      main: "#0085ff",
      dark: "#002884",
      contrastText: "#fff",
      lightGreen: "#8affb2",
      white: "#ffffff",
      grey: "#757575",
      orange: "#FFD698",
      error: "#ff5555",
      black: "#000000",
    },
    secondary: {
      light: "#ffd698", // light orange
      main: "#ff9d0a", // orange
      dark: "#ba000d",
      contrastText: "#000",
      lightGreen: "#8affb2",
      error: "#ff5555",
    },
    typography: {
      fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
      fontSize: 14,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
    },
  },
  // Sizing.
  sizing: {
    sidebar: {
      width: 240,
    },
  },
});

export default theme;
