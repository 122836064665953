// Authentication
export const REGISTRATION_INITIATED = "REGISTRATION_INITIATED";
export const REGISTRATION_USER_ALREADY_EXISTS =
  "REGISTRATION_USER_ALREADY_EXISTS";
export const REGISTRATION_SUCCESS = "REGISTRATION_SUCCESS";
export const REGISTRATION_ERROR = "REGISTRATION_ERROR";
export const REGISTRATION_NOT_VERIFIED = "REGISTRATION_NOT_VERIFIED";
export const BYINVITE_REGISTRATION_SUCCESS = "BYINVITE_REGISTRATION_SUCCESS";
export const BYINVITE_REGISTRATION_ERROR = "BYINVITE_REGISTRATION_ERROR";
export const SIGN_IN_SUCCESS = "SIGN_IN_SUCCESS";
export const SIGN_IN_ERROR = "SIGN_IN_ERROR";
export const SIGN_OUT_SUCCESS = "SIGN_OUT_SUCCESS";
export const RESET_PASSWORD_COGNITO = "RESET_PASSWORD_COGNITO";
export const AUTHENTICATED_USER_ACCOUNT = "AUTHENTICATED_USER_ACCOUNT";

// Loading
export const START_APP_LOADING = "START_APP_LOADING";
export const STOP_APP_LOADING = "STOP_APP_LOADING";

// Alert
export const SET_ALERT = "SET_ALERT";
export const RESET_ALERT = "RESET_ALERT";

// User account
export const SET_USER_DATA = "SET_USER_DATA";
export const GET_USER_ACCOUNT_INFO = "GET_USER_ACCOUNT_INFO";
export const UPDATE_USER_NAME = "UPDATE_USER_NAME";
export const UPDATE_USER_PHONE_NUMBER = "UPDATE_USER_PHONE_NUMBER";
export const UPDATE_USER_ORGANIZATIONAL_INFO =
  "UPDATE_USER_ORGANIZATIONAL_INFO";

// Team account
export const ADD_NEW_BUILDER_TEAM_SUCCESS = "ADD_NEW_BUILDER_TEAM_SUCCESS";
export const REMOVE_EXISTING_BUILDER_TEAM_SUCCESS =
  "REMOVE_EXISTING_BUILDER_TEAM_SUCCESS";
export const ADD_NEW_VIEWER_TEAM_SUCCESS = "ADD_NEW_VIEWER_TEAM_SUCCESS";
export const REMOVE_EXISTING_VIEWER_TEAM_SUCCESS =
  "REMOVE_EXISTING_VIEWER_TEAM_SUCCESS";

export const GET_BUILDER_TEAMS_BY_ORG = "GET_BUILDER_TEAMS_BY_ORG";
export const ADD_USERS_TO_BUILDER_TEAM_SUCCESS =
  "ADD_USERS_TO_BUILDER_TEAM_SUCCESS";
export const REMOVE_USERS_FROM_BUILDER_TEAM_SUCCESS =
  "REMOVE_USERS_FROM_BUILDER_TEAM_SUCCESS";
export const EDIT_MEMBERS_IN_BUILDER_TEAM_SUCCESS =
  "EDIT_MEMBERS_IN_BUILDER_TEAM_SUCCESS";

export const GET_VIEWER_TEAMS_BY_ORG = "GET_VIEWER_TEAMS_BY_ORG";
export const ADD_USERS_TO_VIEWER_TEAM_SUCCESS =
  "ADD_USERS_TO_VIEWER_TEAM_SUCCESS";
export const REMOVE_USERS_FROM_VIEWER_TEAM_SUCCESS =
  "REMOVE_USERS_FROM_VIEWER_TEAM_SUCCESS";
export const EDIT_MEMBERS_IN_VIEWER_TEAM_SUCCESS =
  "EDIT_MEMBERS_IN_VIEWER_TEAM_SUCCESS";

// Group
export const ADD_NEW_GROUP_SUCCESS = "ADD_NEW_GROUP_SUCCESS";
export const REMOVE_EXISTING_GROUP_SUCCESS = "REMOVE_EXISTING_GROUP_SUCCESS";

export const GET_ALL_GROUP_BY_ORG_SUCCESS = "GET_ALL_GROUP_BY_ORG_SUCCESS";
export const GET_GROUP_SUCCESS = "GET_GROUP_SUCCESS";

export const RESET_ADD_NEW_GROUP_MSG = "RESET_ADD_NEW_GROUP_MSG";
export const RESET_REMOVE_EXISTING_GROUP_MSG =
  "RESET_REMOVE_EXISTING_GROUP_MSG";

// Walkthrough
export const GET_WALKTHROUGHS = "GET_WALKTHROUGHS";
export const DELETE_ONE_WALKTHROUGH = "DELETE_ONE_WALKTHROUGH";
export const GET_WALKTHROUGHS_SUMMARY = "GET_WALKTHROUGHS_SUMMARY";
export const GET_SINGLE_WALKTHROUGH_DATA = "GET_SINGLE_WALKTHROUGH_DATA";
export const UPDATE_WALKTHROUGH_TAGS = "UPDATE_WALKTHROUGH_TAGS";
export const PUBLISH_WALKTHROUGH = "PUBLISH_WALKTHROUGH";
export const UNPUBLISH_WALKTHROUGH = "UNPUBLISH_WALKTHROUGH";
export const UPDATE_WALKTHROUGH_GENERAL_SETTINGS =
  "UPDATE_WALKTHROUGH_GENERAL_SETTINGS";
export const UPDATE_WALKTHROUGH_NON_GENERAL_SETTINGS =
  "UPDATE_WALKTHROUGH_NON_GENERAL_SETTINGS";
export const UPDATE_FILTERS = "UPDATE_FILTERS";
export const UPDATE_SORT = "UPDATE_SORT";
export const CREATE_WALKTHROUGH = "CREATE_WALKTHROUGH";
export const GET_ALL_SHARING_PERMISSIONS_SINGLE_WALKTHROUGH =
  "GET_ALL_SHARING_PERMISSIONS_SINGLE_WALKTHROUGH";
export const BULK_UPDATE_INDIVIDUAL_SHARING_PERMISSIONS_SINGLE_WALKTHROUGH =
  "BULK_UPDATE_INDIVIDUAL_SHARING_PERMISSIONS_SINGLE_WALKTHROUGH";
export const DELETE_ONE_INDIVIDUAL_SHARING_PERMISSIONS_SINGLE_WALKTHROUGH =
  "DELETE_ONE_INDIVIDUAL_SHARING_PERMISSIONS_SINGLE_WALKTHROUGH";

export const BULK_UPDATE_TEAM_SHARING_PERMISSIONS_SINGLE_WALKTHROUGH =
  "BULK_UPDATE_TEAM_SHARING_PERMISSIONS_SINGLE_WALKTHROUGH";
export const DELETE_ONE_TEAM_SHARING_PERMISSIONS_SINGLE_WALKTHROUGH =
  "DELETE_ONE_TEAM_SHARING_PERMISSIONS_SINGLE_WALKTHROUGH";

// Group Walkthroughs
export const UPDATE_WALKTHROUGHS_TO_GROUP_WALKTHROUGH_SUCCESS =
  "UPDATE_WALKTHROUGHS_TO_GROUP_WALKTHROUGH_SUCCESS";
export const REMOVE_BULK_WALKTHROUGHS_FROM_GROUP_WALKTHROUGH_SUCCESS =
  "REMOVE_BULK_WALKTHROUGHS_FROM_GROUP_WALKTHROUGH_SUCCESS";

export const GET_ALL_SHARING_PERMISSIONS_FOR_SINGLE_GROUP_WALKTHROUGH_SUCCESS =
  "GET_ALL_SHARING_PERMISSIONS_FOR_SINGLE_GROUP_WALKTHROUGH_SUCCESS";
export const DELETE_ONE_INDIVIDUAL_SHARING_PERMISSIONS_SINGLE_WALKTHROUGH_GROUP =
  "DELETE_ONE_INDIVIDUAL_SHARING_PERMISSIONS_SINGLE_WALKTHROUGH_GROUP";
export const ADD_BULK_INDIVIDUAL_SHARING_PERMISSIONS_FOR_GROUP_WALKTHROUGH_SUCCESS =
  "ADD_BULK_INDIVIDUAL_SHARING_PERMISSIONS_FOR_GROUP_WALKTHROUGH_SUCCESS";
export const DELETE_ONE_TEAM_SHARING_PERMISSIONS_SINGLE_WALKTHROUGH_GROUP =
  "DELETE_ONE_TEAM_SHARING_PERMISSIONS_SINGLE_WALKTHROUGH_GROUP";
export const ADD_BULK_TEAM_SHARING_PERMISSIONS_FOR_GROUP_WALKTHROUGH_SUCCESS =
  "ADD_BULK_TEAM_SHARING_PERMISSIONS_FOR_GROUP_WALKTHROUGH_SUCCESS";

export const RESET_UPDATE_WALKTHROUGHS_TO_GROUP_WALKTHROUGH_MSG =
  "RESET_UPDATE_WALKTHROUGHS_TO_GROUP_WALKTHROUGH_MSG";
export const RESET_REMOVE_BULK_WALKTHROUGHS_FROM_GROUP_WALKTHROUGH_MSG =
  "RESET_REMOVE_BULK_WALKTHROUGHS_FROM_GROUP_WALKTHROUGH_MSG";
export const RESET_GET_ALL_SHARING_PERMISSIONS_FOR_SINGLE_GROUP_WALKTHROUGH_MSG =
  "RESET_GET_ALL_SHARING_PERMISSIONS_FOR_SINGLE_GROUP_WALKTHROUGH_MSG";

// Notes actions
export const SET_SEARCH_KEYWORDS = "SET_SEARCH_KEYWORDS";

// Modal actions
export const SET_CREATE_WALKTHROUGH_MODAL_SHOW =
  "SET_CREATE_WALKTHROUGH_MODAL_SHOW";
export const SET_CHANGE_NAME_MODAL_SHOW = "SET_CHANGE_NAME_MODAL_SHOW";
export const SET_CHANGE_PASSWORD_MODAL_SHOW = "SET_CHANGE_PASSWORD_MODAL_SHOW";
export const SET_CHANGE_PHONE_NUMBER_MODAL_SHOW =
  "SET_CHANGE_PHONE_NUMBER_MODAL_SHOW";
export const SET_CHANGE_ORGANIZATIONAL_INFO_MODAL_SHOW =
  "SET_CHANGE_ORGANIZATIONAL_INFO_MODAL_SHOW";

// Organization
export const GET_ORGANIZATION_INFO = "GET_ORGANIZATION_INFO";
export const SEND_ORGANIZATION_INVITE_SUCCESS =
  "SEND_ORGANIZATION_INVITE_SUCCESS";
export const GET_ORGANIZATION_DATA_ERROR = "GET_ORGANIZATION_DATA_ERROR";
export const SEND_ORGANIZATION_INVITE_ERROR = "SEND_ORGANIZATION_INVITE_ERROR";
export const CHANGE_ROLE_OF_USERS_IN_ORGANIZATION =
  "CHANGE_ROLE_OF_USERS_IN_ORGANIZATION";

// Analytics
export const FETCH_ANALYTICS_DATA = "FETCH_ANALYTICS_DATA";
export const FILTER_ANALYTICS_DATA = "FILTER_ANALYTICS_DATA";
export const DELETE_ANALYTICS_DATA = "DELETE_ANALYTICS_DATA";
export const FETCH_DETAILED_ANALTICS_DATA = "FETCH_DETAILED_ANALTICS_DATA";

// Assistant
export const UPDATE_ASSISTANT_PUBLISH_SETTINGS =
  "UPDATE_ASSISTANT_PUBLISH_SETTINGS";
export const UPDATE_ASSISTANT_POSITION_SETTINGS =
  "UPDATE_ASSISTANT_POSITION_SETTINGS";
