import React from "react";
import PageTopBar from "../_PagePartials/PageTopBar";
import { Container, createTheme } from "@mui/material";
import { UserData } from "../../store/reducers/_reducerIndex";
import BackgroundSwirlDecoration from "../../components/01-atoms/00-icons/BackgroundSwirlDecoration";

/** This is the base page for viewers */

const PageHomeViewerBasePage = ({
  children,
  user,
}: {
  children: any;
  user: UserData;
}) => {
  // Sidebar open/close toggle handler.
  const handleDrawerToggle = () => {
    // Re-code when ready to use Toggle function.
  };

  return (
    <>
      <div className="frameBody flex-grow">
        <PageTopBar handleDrawerToggle={handleDrawerToggle} />

        <main className="frameBodyContent">
          <Container maxWidth="xl">{children}</Container>
        </main>
      </div>
      <div
        className="fixed"
        style={{
          bottom: "0",
          transform: "scaleX(-1)",
          zIndex: -1,
        }}
      >
        <BackgroundSwirlDecoration scale={1.5} />
      </div>
      <div
        className="fixed"
        style={{
          right: "0",
          top: "0",
          transform: "rotate(180deg) scaleX(-1)",
          zIndex: -1,
        }}
      >
        <BackgroundSwirlDecoration scale={1.5} />
      </div>
    </>
  );
};

export default PageHomeViewerBasePage;
