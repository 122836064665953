import * as actionTypes from "./_actionTypes";
import { AuthState, UserData } from "./_reducerIndex";
import store from "../index";
import { getUserInfo } from "./reducerUser";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import axios from "axios";

import {
  callPOSTChangePassword,
  callPOSTForgotPassword,
  callPOSTForgotPasswordConfirm,
  callPostGoogleSignIn,
  callPostlinkGoogleAccount,
  callPostRefreshCognitoToken,
  callPostRefreshGoogleToken,
  callPOSTRegisterByInviteUserToOrganization,
  callPostRegisterGoogleAccount,
  callPOSTRegisterSubscribeUserAccount,
  callPOSTRegisterUserAccount,
  callPOSTSignInUserAccount,
} from "../../axios/v2/callsAuth";
import {
  callPOSTRetrieveUserPermissionAccess,
  callPOSTRetrieveUserRoleFromOrganization,
} from "../../axios/v2/callsOrganization";
import { alertState } from "./reducerAlert";
import verifyPayload from "../../utility/functions/verifyPayload";
import { addBySecond } from "../../components/_functions/FunctionDate";
import { useNavigate } from "react-router-dom";
import { dispatch } from "d3";
import { setOrgPlanInformation } from "./reducerOrganization";
import { EXTENTION_BUILDER_ID, EXTENTION_LAUNCHER_ID } from "../../constants";
import { fromUnixTime } from "date-fns";
import { callGETRetrieveUserAccountInformation } from "../../axios/v2/callsUser";
import {
  authGoogleSignIn,
  RegisterGoogleNewUser,
} from "./reducerAuthUtils/authLinkGoogleAccount";
import { authGetUserInfo } from "./reducerAuthUtils/authGetUserInfo";
import { PaymentInfo } from "../../interfaces/interfacePaymentInfor";
import {
  paymentStatus,
  setPaymentInformation,
  temporaryPaymentAuth,
} from "./reducerPayment";

//interfaces
interface UserDataLogin {
  email: string;
  password: string;
  // can just add field from userdata
  loginAt: string;
  eventId: string;
}

// For analytics/tracking, SMU FYP ANALYTIPS CODE
interface CleanedUserData {
  email: string;
  // can just add field from userdata
  loginAt: string;
  eventId: string;
}

// Call function to run an Auth Check
export const onAuthCheck = () => {
  console.log("--- onAuthCheck");
  // ========= Check auth here =============
  //NOTE: need to convert from String to Object
  // when you set item into local storage it can only be stored as a string
  // so we need to use JSON.stringify to convert object to string
  // then when we retireve using getItem we will use JSON.parse to change it back to
  // an object
  const auth = JSON.parse(localStorage.getItem("auth")!);
  const user = JSON.parse(localStorage.getItem("ut-user")!);

  const utExpires = localStorage.getItem("ut-expires")!;

  //if token expires, log user out
  // format to second and subtract
  const expiryDateTime = new Date(utExpires).getTime() / 1000;
  const timeNow = new Date().getTime() / 1000;
  const calculateTimeout = +(expiryDateTime - timeNow).toFixed();
  // for testing
  // const calculateTimeout = +(expiryDateTime - expiryDateTime).toFixed();

  // console.log("time before expired(in second)", calculateTimeout);

  //TODO: Should remove expired token from local storage?
  const signInType = localStorage.getItem("ut-signInType");
  if (calculateTimeout <= 0 && utExpires != null) {
    /**Refresh aws token first before logout */
    if (signInType === "AWS") {
      store.dispatch(refreshCognitoToken());
      return;
    }
    /**Continue to logout */
    const authState = localStorage.getItem("auth");

    if (authState === "false") {
      if (window.chrome && window.chrome.runtime) {
        /**Send msg to logOut builder */
        chrome.runtime.sendMessage(
          EXTENTION_BUILDER_ID,
          { msg: "LOGOUT_REQUEST_FROM_WEB_APP" },
          (response) => {
            console.log("Response from builder extension: ", response);
          }
        );
      }

      if (window.chrome && window.chrome.runtime) {
        /**Send msg to logOut launcher */
        chrome.runtime.sendMessage(
          EXTENTION_LAUNCHER_ID,
          { msg: "LOGOUT_REQUEST_FROM_WEB_APP" },
          (response) => {
            console.log("Response from launcher extension: ", response);
          }
        );
      }

      // Redirects view to root path of app.
      alert("Your session has expired. Please login again.");
      store.dispatch(signOutSuccess());
      window.location.replace(window.location.origin);
    }
  }
  // If authenticated.
  if (auth && user) {
    // TODO: check if backend rejects an expired session, if backend rejects an expired session we will need the timeout code below
    // set timeout, to logout the user when their session is expired
    // need to convert calculateTimeout to milliseconds
    // window.setTimeout(() => {
    //   // ========= Log out call here =============
    //   localStorage.removeItem("ut-token");
    //   localStorage.removeItem("ut-expires");
    //   localStorage.removeItem("ut-refresh-token");
    //   localStorage.removeItem("ut-cognitoid");
    //   localStorage.removeItem("ut-email");
    //   localStorage.removeItem("ut-user");

    //   localStorage.setItem("auth", "false");

    //   // Redirects view to root path of app.
    //   alert("Your session has expired. Please login again.");
    //   store.dispatch(signOutSuccess());
    //   window.location.replace(window.location.origin);
    // }, calculateTimeout * 1000);

    store.dispatch(
      checkSignInStatus({
        auth: auth,
      })
    );
    store.dispatch(
      getUserInfo({
        user: user,
      })
    );
  }
  // If not authenticated.
  else if (!auth || auth === "false") {
    // If auth is null, convert to auth to false
    if (!auth) {
      localStorage.setItem("auth", "false");
      store.dispatch(
        signInError({
          errorMsg: null,
        })
      );
    }
    store.dispatch(signOutSuccess());
  } else {
    /** If there is an exception that is uncaught, sign out user */
    store.dispatch(signOutSuccess());
    console.error("Exception found, user logged out.");
  }
};

// Call function to execute Sign Out Existing User Account procedure
export const authSignOutUserAccount = () => {
  // ========= Log out call here =============
  localStorage.removeItem("ut-token");
  localStorage.removeItem("ut-expires");
  localStorage.removeItem("ut-refresh-token");
  localStorage.removeItem("ut-cognitoid");
  localStorage.removeItem("ut-email");
  localStorage.removeItem("ut-user");
  localStorage.removeItem("ut-accsessToken");
  localStorage.removeItem("ut-paymentTier");

  localStorage.setItem("auth", "false");
  if (window.chrome && window.chrome.runtime) {
    /**Send msg to logOut builder */
    chrome.runtime.sendMessage(
      EXTENTION_BUILDER_ID,
      { msg: "LOGOUT_REQUEST_FROM_WEB_APP" },
      (response) => {
        console.log("Response from builder extension: ", response);
      }
    );

    /**Send msg to logOut launcher */
    chrome.runtime.sendMessage(
      EXTENTION_LAUNCHER_ID,
      { msg: "LOGOUT_REQUEST_FROM_WEB_APP" },
      (response) => {
        console.log("Response from launcher extension: ", response);
      }
    );
  }

  // On success.
  store.dispatch(signOutSuccess());

  // Redirects view to root path of app.
  window.location.replace(window.location.origin);
};

export const signInRequest = createAsyncThunk(
  "auth/login",
  async (userData: UserDataLogin, thunkAPI) => {
    try {
      // // START OF SMU FYP ANALYTIPS CODE
      // const loginAt = new Date().toISOString();

      // const cleanedUserData: CleanedUserData = {
      //   email: userData.email,
      //   loginAt: userData.loginAt,
      //   eventId: userData.eventId,
      // }; // PLS ENSURE PASSWORD IS NOT SENT :') , we dont want password to be sent in a tracking payload

      // try {
      //   // Send user data to the webhook endpoint
      //   const response = await axios.post(
      //     "https://hook.eu2.make.com/nj7d7pgt8blh88yhtlq2ru3rk9emn4p8",
      //     cleanedUserData
      //   );
      //   console.log(cleanedUserData);
      //   // Handle response if needed
      //   console.log("Webhook response:", response.data);
      // } catch (error) {
      //   // Handle errors
      //   console.log("LOGINAT:", cleanedUserData.loginAt);
      //   console.error("Error sending data to webhook:", error);
      // }

      // // Utility function to generate a unique ID
      // function generateId(): string {
      //   // Generate a random number and convert it to base 36 (which includes letters and numbers)
      //   // Then remove the leading "0." and any characters after the decimal point
      //   return Math.random().toString(36).substring(2);
      // }

      // const eventId = generateId();
      // // END OF SMU FYP ANALYTIPS CODE

      // retrieve success USER LOGIN and user information
      const result = await callPOSTSignInUserAccount(
        userData.email,
        userData.password
      );

      if (!result.data.AuthenticationResult) throw result.data;

      const auth = result.data.AuthenticationResult;
      const user = result.data.user;
      const paymentDetails = result.data.plan_info as PaymentInfo;
      localStorage.setItem("ut-token", auth.IdToken); // set bearer token first
      localStorage.setItem("ut-accsessToken", auth.AccessToken);
      //set sign type to AWS
      localStorage.setItem("ut-signInType", "AWS");

      /**Temporary save payment information into redux
       * what it used for:
       * 1. limiting url when user already have subscription
       * 2. limiting url when user trial is expired
       */
      store.dispatch(setPaymentInformation({ payment_info: paymentDetails }));

      /**temporary store auth info for payment.
      What it used for:
      1. when user do payment we need auth id to verify the payment
      */
      store.dispatch(temporaryPaymentAuth(auth.IdToken));

      /**We store the subscription route under payment reducer: paymentStep */
      /**dispatch function name: paymentStatus() */
      /**Why: because we cant use windows.location.replace. if we use that method it will be redirected and redux will be reseted */
      /**paymentStep state will be handled accordingly on App.tsx*/

      /**Check subscribe and if not on trial */
      // if (
      //   paymentDetails.can_trial === false &&
      //   paymentDetails.plan_status !== "active" &&
      //   //edge case when older user dont have plan status and not on free tier
      //   //@ts-ignore
      //   paymentDetails.plan_tier !== "PAID1" &&
      //   //@ts-ignore
      //   paymentDetails.plan_tier !== "PAID2"
      // ) {
      //   store.dispatch(paymentStatus("trialFinish"));
      //   // window.location.replace("/trial-expired");
      //   return;
      // }

      /**Check if user still have trial */
      /**If user still have trial*/
      /**FIXME: to Shafiq  check this statement if you want to handle after user register*/
      // if (
      //   paymentDetails.can_trial === true &&
      //   paymentDetails.plan_status !== "active"
      // ) {
      //   store.dispatch(paymentStatus("trial"));
      //   return;
      // }

      /**Check user plan tier. if FREE1 mean dont have subscription or subs is canceled */
      //@ts-ignore
      // if (paymentDetails.plan_tier === "FREE1") {
      //   //redirect to billing page
      //   store.dispatch(paymentStatus("otherPlan"));
      // }
      // return result.data
      if (auth && user) {
        // Call callPOSTRetrieveUserRoleFromOrganization API-call function
        // If user has "org" field value that is not null
        if (user.org) {
          try {
            // retrieve user role information API
            const response = await callPOSTRetrieveUserPermissionAccess(
              user.org
            );
            // Abstract role for checking
            if (!response.data) throw response;

            const signed_payload = response.data.signed_payload;
            const verified_payload = await verifyPayload(signed_payload);

            // const payload = response.data.payload;
            const msg = response.data.msg;
            console.log(msg);
            /** Get user role from verified payload */
            const role = verified_payload ? verified_payload.role : null;
            /** Get user organization from verified payload */
            const getOrg = verified_payload.org;
            /** Get user access permissions from verified payload */
            const orgPlan = verified_payload.orgPlan;
            if (role) {
              // if (role === "viewer") {
              // // If user is viewer, deny sign in to webapp
              // console.log("USER IS viewer");
              // // If user is viewer, force-remove any existing auth token
              // localStorage.removeItem("ut-token");
              // localStorage.removeItem("ut-user");
              // localStorage.setItem("auth", "false");
              // thunkAPI.dispatch(
              //   signInError({
              //     errorMsg:
              //       "You are not authorized to sign in to the webapp.",
              //   })
              // );
              // } else {
              // If user is owner / admin / builder, be allowed to sign in to webapp
              console.log(`USER IS ${role}`);
              user.role = role;

              // Store AuthenticationResult & User details to localStorage
              localStorage.setItem("ut-token", auth.IdToken);
              // TODO: ut-expires should be datetime format, + auth.ExpiresIn value
              const expireIn = addBySecond(new Date(), auth.ExpiresIn);
              localStorage.setItem("ut-expires", expireIn);
              localStorage.setItem("ut-refresh-token", auth.RefreshToken);
              localStorage.setItem("ut-cognitoid", user.cognito_id);
              localStorage.setItem("ut-email", user.email);
              localStorage.setItem("auth", "true");
              localStorage.setItem("ut-user", JSON.stringify(user));

              // cognito data is used for refreshing token
              const refreshCognitoData = {
                AccessToken: auth.AccessToken,
                RefreshToken: auth.RefreshToken,
                IdToken: auth.IdToken,
              };

              localStorage.setItem(
                "ut-refresh-cognito-data",
                JSON.stringify(refreshCognitoData)
              );

              // Since SIGN_IN_SUCCESS is pre-injected, no need to update SIGN_IN data/status

              console.log(`Signing In User Account: ${userData.email}`);
              store.dispatch(setOrgPlanInformation({ orgPlan: orgPlan }));

              const result = {
                auth: auth,
                user: { ...user },
                // orgPlan: orgPlan,
              };
              window.history.pushState({}, "", "/");
              //sign in succses
              return result;
              // }
            }
          } catch (err: any) {
            console.log("376", err);
            if (err.response) {
              const errStatus = err.response.status; // Integer
              const errPayload = err.response.data;
              const errMsg = errPayload.msg;
              const errCode = err.response.data.code;

              // if (
              //   (errPayload.msg =
              //     "This user subscription is non active!" &&
              //     user.is_trial === false)
              // ) {
              //   //redirect to billing page
              //   window.location.replace("/trial-expired");
              //   return;
              // }

              // if ((errPayload.msg = "This user subscription is non active!")) {
              //   localStorage.setItem("ut-newUser", "true");
              //   console.log(user, "user");
              //   //redirect to billing page
              //   window.location.replace("/new-subscription");
              //   return;
              // }

              console.log(errStatus);
              console.log(errPayload);
              console.log(errMsg);

              if (
                errStatus === 400 &&
                errCode === "UserNotConfirmedException"
              ) {
                thunkAPI.dispatch(
                  registrationNotVerified({
                    errorMsg:
                      "User not verified. Please verify your account via the email sent.",
                  })
                );
              } else {
                const result = {
                  alert: {
                    type: "error",
                    message: errMsg,
                  },
                };
                return thunkAPI.rejectWithValue(result);
              }
            } else {
              const result = {
                alert: {
                  type: "error",
                  message: err.toString(),
                },
              };
              return thunkAPI.rejectWithValue(result);
            }
          }
        } else {
          // If the user does not belong to an organization, just sign them in
          // Store AuthenticationResult & User details to localStorage
          const expireIn = addBySecond(new Date(), auth.ExpiresIn);
          localStorage.setItem("ut-expires", expireIn);
          localStorage.setItem("ut-refresh-token", auth.RefreshToken);
          localStorage.setItem("ut-cognitoid", user.cognito_id);
          localStorage.setItem("ut-email", user.email);
          localStorage.setItem("auth", "true");
          localStorage.setItem("ut-user", JSON.stringify(user));

          const refreshCognitoData = {
            AccessToken: auth.AccessToken,
            RefreshToken: auth.RefreshToken,
            IdToken: auth.IdToken,
          };

          console.log(refreshCognitoData, "refreshCognitoData");
          localStorage.setItem(
            "ut-refresh-cognito-data",
            JSON.stringify(refreshCognitoData)
          );

          // Since SIGN_IN_SUCCESS is pre-injected, no need to update SIGN_IN data/status
          console.log(`Signing In User Account: ${userData.email}`);
          //sign in succses
          const result = {
            auth: auth,
            user: user,
          };
          window.location.replace("/");
          return result;
        }
      } else {
        console.log("error");
      }
    } catch (err: any) {
      if (err.response) {
        const errStatus = err.response.status; // Integer
        const errPayload = err.response.data;
        const errMsg = errPayload.msg;

        console.log(errStatus);
        console.log(errPayload);
        console.log(errMsg);

        if (errStatus === 400 && errPayload.code === "NotAuthorizedException") {
          thunkAPI.dispatch(
            signInError({
              errorMsg: "Wrong email or password. Please try again.",
            })
          );
        } else if (
          errStatus === 400 &&
          errPayload.code === "UserNotConfirmedException"
        ) {
          thunkAPI.dispatch(
            signInError({
              errorMsg:
                "User not verified. Please verify your account via the email sent.",
            })
          );
        } else {
          thunkAPI.dispatch(
            signInError({
              errorMsg: "Sign in error with server. Please try again.",
            })
          );
        }
      } else {
        const result = {
          alert: {
            type: "error",
            message: err.toString(),
          },
        };
        return thunkAPI.rejectWithValue(result);
      }
      // API error messages will only be captured at calls-API function. Thus, error captures will be exectued there. Errors captured here are only when reply throws a 500 error that results in an empty 'result' variable, which will throw to this error exception
      // Catches sign in errors thrown from server
    }
  }
);

export const refreshCognitoToken = createAsyncThunk(
  "auth/refreshCognitoToken",
  async (_, thunkAPI) => {
    try {
      const refreshToken = localStorage.getItem("ut-refresh-cognito-data");
      //convert back to object
      const refreshTokenData = JSON.parse(refreshToken!);

      const response = await callPostRefreshCognitoToken(refreshTokenData!);
      const result = response.data.tokens.AuthenticationResult;
      const expireIn = addBySecond(new Date(), result.ExpiresIn);
      localStorage.setItem("ut-token", result.IdToken);
      localStorage.setItem("ut-accsessToken", result.AccessToken);
      localStorage.setItem("ut-expires", expireIn);
      //@ts-ignore
      store.dispatch(onAuthCheck());
    } catch (error) {
      console.error(error);
    }
  }
);

/**Google sign in redux */
export const googleSignIn = createAsyncThunk(
  "auth/googleSignIn",
  async (googleToken: string, thunkApi) => {
    try {
      /**call google sign api */
      const result = await callPostGoogleSignIn(googleToken);

      if (result.data.success === true) {
        const res = result.data;
        /**authentication result */
        const authResult = res.AuthenticationResult;
        /**If user gmail is not found in db  register it to back end and get the db*/
        if (res.registered === false) {
          await RegisterGoogleNewUser(googleToken, authResult);
        }
        /**If user gmail found in db proced to sign in  */
        return await authGoogleSignIn({
          authResult,
          googleToken,
          thunkApi,
          store,
        });
      }
    } catch (error: any) {
      return thunkApi.rejectWithValue({
        alert: {
          type: "error",
          message: error.toString(),
        },
      });
    }
  }
);

/**Google refresh token */
export const googlegetRefreshToken = createAsyncThunk(
  "auth/googleRefreshToken",
  async (googleToken: string, thunkApi) => {
    try {
      /**call google sign api */
      const response = await callPostRefreshGoogleToken(googleToken);
      const result = response.data.tokens;

      localStorage.setItem("ut-refresh-token", result.RefreshToken);
      localStorage.setItem("ut-token", result.IdToken);
      localStorage.setItem("ut-accsesToken", result.AccessToken);
    } catch (error: any) {
      return thunkApi.rejectWithValue({
        alert: {
          type: "error",
          message: error.toString(),
        },
      });
    }
  }
);

export type RegisterFromGoogle = {
  email: string;
  name: string;
  cognitoId: string;
  orgName: string;
  token: string;
  expiresIn: number;
};

/**Async thunk for google register */
export const registerFromGoogle = createAsyncThunk(
  "auth/registerFromGoogle",
  async (data: RegisterFromGoogle, thunkApi) => {
    try {
      const responseRegister = await callPostRegisterGoogleAccount(data);
      if (responseRegister.data.success === true) {
        return authGetUserInfo({ data, thunkApi, store });
      }
    } catch (error: any) {
      /** If error remove local-storage auth values.
        RegisterGoogleNewUser sets "ut-signInType" & "ut-token" after a success.
      */
      localStorage.removeItem("ut-signInType");
      localStorage.removeItem("ut-token");

      return thunkApi.rejectWithValue({
        alert: {
          type: "error",
          message: error.toString(),
        },
      });
    }
  }
);

export const getUserPermissionAccess = createAsyncThunk(
  "auth/access",
  async (orgId: string, thunkAPI) => {
    try {
      // retrieve user role information API
      const response = await callPOSTRetrieveUserPermissionAccess(orgId);
      // Abstract role for checking
      if (!response.data) throw response;

      const signed_payload = response.data.signed_payload;
      const verified_payload = await verifyPayload(signed_payload);

      // const payload = response.data.payload;
      const msg = response.data.msg;
      /** Get user role from verified payload */
      const role = verified_payload ? verified_payload.role : null;
      /** Get user organization from verified payload */
      const getOrg = verified_payload.org;
      /** Get user access permissions from verified payload */
      const orgPlan = verified_payload.orgPlan;
      // If response is not null
      if (role) {
        store.dispatch(setOrgPlanInformation({ orgPlan: orgPlan }));
        return;
        // }
      }
    } catch (error) {
      console.error(error);
    }
  }
);

export const registerRequest = createAsyncThunk(
  "auth/register",
  async (
    userData: {
      email: string;
      password: string;
      name: string;
      orgName: string;
      // createdAt: string; // SMU FYP ANALYTIPS CODE
      timestamp: string; // SMU FYP ANALYTIPS CODE
      eventId: string; // SMU FYP ANALYTIPS CODE
    },
    thunkAPI
  ) => {
    console.log("sign up request");
    try {
      const response = await callPOSTRegisterSubscribeUserAccount(
        userData.email,
        userData.password,
        userData.name,
        userData.orgName
        // userData.createdAt
        // userData.timestamp, // SMU FYP ANALYTIPS CODE
        // userData.eventId // SMU FYP ANALYTIPS CODE
      );

      if (!response.data) throw "Error with sign up";

      /** Sign in user after successful Sign-up */
      store.dispatch(
        signInRequest({
          email: userData.email,
          password: userData.password,
          loginAt: userData.timestamp,
          eventId: userData.eventId,
        })
      );
      // retrieve success USER LOGIN and user information
      const result = await callPOSTSignInUserAccount(
        userData.email,
        userData.password
      );

      if (!result.data.AuthenticationResult) throw result.data;

      const signUpTimestamp = performance.now(); // SMU FYP ANALYTIPS CODE
      const auth = result.data.AuthenticationResult;
      const user = result.data.user;
      const paymentDetails = result.data.plan_info;
      localStorage.setItem("ut-token", auth.IdToken); // set bearer token first
      localStorage.setItem("ut-accsesToken", auth.AccessToken);

      localStorage.setItem("ut-planTier", paymentDetails.plan_tier);
      localStorage.setItem("ut-dueDate", paymentDetails.due_date);
      localStorage.setItem("ut-signInType", "AWS");
      localStorage.setItem("ut-newUser", "true");
    } catch (error: any) {
      let errMsg = error.data ? error.data.msg : "Error with user registration";
      console.error(error, errMsg);
      const result = {
        alert: {
          type: "error",
          message: errMsg,
        },
      };

      const errResponse = error?.response?.data?.msg;

      if (errResponse) {
        thunkAPI.dispatch(
          registrationError({
            errorMsg: errResponse,
          })
        );
      } else {
        thunkAPI.dispatch(
          registrationError({
            errorMsg: "You are not authorized to sign in to the webapp.",
          })
        );
      }

      return thunkAPI.rejectWithValue(result);
    }
  }
);

export const authRegisterationInitiated = () => {
  console.log("registration init");

  store.dispatch(registrationInit());
};

// Register new user role by Email Invite
export const authRegisterUserByEmailInvite = (userData: UserData) => {
  callPOSTRegisterByInviteUserToOrganization(
    userData.email,
    userData.password,
    userData.name,
    userData.jwtOrgId,
    userData.jwtRoleType,
    userData.jwtMFA
    // userData.countryCode,
    // userData.phoneNumber,
  )
    .then((response) => {
      const { code, payload } = response.data;

      if (payload) {
        console.log(
          `Successful Registation Of New User Account: ${userData.email}`
        );
        store.dispatch(byInviteRegistrationSuccsess());
      }
      // Catch if Error Message is thrown from server reply
      else if (code === "error") {
        store.dispatch(
          registrationByInviteError({
            errorMsg: response.data.msg,
          })
        );
      } else {
        store.dispatch(
          registrationByInviteError({
            errorMsg: "Invalid registration entry to server. Please try again.",
          })
        );
      }
    })
    .catch((err) => {
      if (err.response) {
        const errStatus = err.response.status; // Integer
        const errPayload = err.response.data;
        const errMsg = errPayload.msg;

        console.log(errStatus);
        console.log(errPayload);
        console.log(errMsg);

        // if (errStatus === 400 && errMsg === "An account with the given email already exists."){
        //   dispatch({
        //     type: actionTypes.REGISTRATION_USER_ALREADY_EXISTS
        //   });
        // }
      } else {
        //TODO: SET Alert
      }
    });
};

export const registerByEmailInviteRequest = createAsyncThunk(
  "auth/registerByInvite",
  async (userData: UserData, thunkAPI) => {
    try {
      const response = await callPOSTRegisterByInviteUserToOrganization(
        userData.email,
        userData.password,
        userData.name,
        userData.jwtOrgId,
        userData.jwtRoleType,
        userData.jwtMFA
      );
      const { code, payload } = response.data;

      if (payload) {
        console.log(
          `Successful Registation Of New User Account: ${userData.email}`
        );
        // // START OF SMU FYP ANALYTIPS CODE
        // try {
        //   let userDataWithTimestamp: UserData & { timestamp: string } = {
        //     ...userData, // Copy existing fields
        //     timestamp: new Date().toISOString(), // Add timestamp field
        //   };

        //   // Send user data to the webhook endpoint
        //   const response = await axios.post(
        //     "https://hook.eu2.make.com/lyfcfmmn301g1ppqa8ucauskj3cd9fzl",
        //     userDataWithTimestamp
        //   );
        //   // Handle response if needed
        //   console.log("Webhook response:", response.data);
        // } catch (error) {
        //   // Handle errors
        //   console.error("Error sending data to webhook:", error);
        // }
        // // END OF SMU FYP ANALYTIPS CODE

        thunkAPI.dispatch(byInviteRegistrationSuccsess());
      } else if (code === "error") {
        thunkAPI.dispatch(
          registrationByInviteError({
            errorMsg: response.data.msg,
          })
        );
      } else {
        thunkAPI.dispatch(
          registrationByInviteError({
            errorMsg: "Invalid registration entry to server. Please try again.",
          })
        );
      }
    } catch (err: any) {
      if (err.response) {
        const errStatus = err.response.status; // Integer
        const errPayload = err.response.data;
        const errMsg = errPayload.msg;

        console.log(errStatus);
        console.log(errPayload);
        console.log(errMsg);

        thunkAPI.dispatch(registrationError({ errorMsg: errPayload.err.msg }));

        throw errMsg;
      }
    }
  }
);

// Call function to execute a Password Reset procedure
export const authResetPassword = () => {
  //open reset password page
  // Note: Do not delete the url code commented below
  // const url =
  //   "https://auth.user-tip.com/forgotPassword?client_id=7id1fru2ppoeekvt8lvpn1omb7&response_type=code&scope=aws.cognito.signin.user.admin+email+openid&redirect_uri=https://app.user-tip.com/"; //API v1.0 UserPool

  // API v2.0 aws reset route link
  const url =
    "https://usertip-staging-1.auth.ap-southeast-1.amazoncognito.com/forgotPassword?client_id=4ue1ahmt6rvpef54ggoqdpgbm&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=https://app.usertip.com/";
  window.open(url, "_blank");

  // ========= Log out call here =============
  // On success.
  store.dispatch(signOutSuccess());

  // Redirects view to root path of app.
  window.location.replace(window.location.origin);
};

type PwdData = {
  previous_password: string;
  new_password: string;
};
/**Call function for changing password of current user */
export const authChangePassword = createAsyncThunk(
  "auth/changePassword",
  async (passwordData: PwdData, thunkAPI) => {
    const { previous_password, new_password } = passwordData;
    try {
      const response = await callPOSTChangePassword(
        previous_password,
        new_password
      );
      if (response.data.success === true) {
        return true;
      } else {
        throw response;
      }
    } catch (error: any) {
      console.log(error);

      const result = {
        alert: {
          type: "error",
          message: error.msg,
        },
      };
      return thunkAPI.rejectWithValue(result);
    }
  }
);

/** Call function to get verification code from email */
export const authForgotPassword = createAsyncThunk(
  "auth/forgotPassword",
  async (email: string, thunkAPI) => {
    try {
      const response = await callPOSTForgotPassword(email);
      if (response.data.success === true) {
        return "formCode";
      } else {
        console.log(response.data);
        throw response.data;
      }
    } catch (error: any) {
      console.log(error);

      const result = {
        alert: {
          type: "error",
          message: "Could not find the user email.",
        },
      };
      return thunkAPI.rejectWithValue(result);
    }
  }
);

type ChangePassData = {
  email: string;
  password: string;
  confirmation_code: string;
};
/** Calll function to change to new password */
export const authNewPassword = createAsyncThunk<any, ChangePassData>(
  "auth/newPassword",
  async (changePassData: ChangePassData, thunkAPI) => {
    const { email, password, confirmation_code } = changePassData;
    try {
      const response = await callPOSTForgotPasswordConfirm(
        email,
        password,
        confirmation_code
      );
      if (response.data.success === true) {
        return true;
      } else {
        console.log(response.data);
        throw response.data;
      }
    } catch (error: any) {
      //TODO: redirect to error msg
      console.log(error);
      return thunkAPI.rejectWithValue(true);
    }
  }
);

const parseStringToBoolean = (value: string) => {
  if (value === "true") {
    return true;
  } else {
    return false;
  }
};

/** Get storage auth */
const storageAuth = localStorage.getItem("auth");
/** Check auth value */
const storageIsAuthenticated =
  storageAuth !== null ? parseStringToBoolean(storageAuth) : false;

/**State for auth */
const authState: AuthState = {
  appLoading: false,
  //@ts-ignore
  alert: alertState,
  isAuthenticated: storageIsAuthenticated,
  isPasswordChanged: false,
  isRegistering: "invalid",
  forgotPwdSection: "formEmail",
  confirmationCode: null,
  errorSignIn: "",
  errorRegistration: "",
  errorByInviteRegistration: "",
  user: {
    mfa: null,
    is_deleted: null,
    _id: "",
    email: "",
    name: "",
    cognito_id: "",
    api_key: "",
    phoneno: "",
    createdAt: "",
    updatedAt: "",
    __v: 0,
    id: "",
  },
  auth: {},
};

const sliceAuth = createSlice({
  name: "auth",
  initialState: authState,
  reducers: {
    registrationInit: (state) => {
      state.isRegistering = "pending";
      state.errorRegistration = "";
    },
    registrationUserAlreadyExist: (state) => {
      state.isRegistering = "alreadyexists";
      state.errorRegistration =
        "*User email already exists. Please use an alternative email.";
    },
    registrationError: (state, action) => {
      state.isRegistering = "failed";
      state.errorRegistration = action.payload.errorMsg!;
    },
    registrationNotVerified: (state, action) => {
      if (action.payload.errorMsg !== undefined) {
        console.log(`Registration Error is: ${action.payload.errorMsg}`);
      }
      state.isRegistering = "notverified";
      state.errorRegistration = "";
    },
    byInviteRegistrationSuccsess: (state) => {
      state.isRegistering = "success";
      state.errorRegistration = "";
    },
    registrationByInviteError: (state, action) => {
      if (action.payload.errorMsg !== undefined) {
        console.log(
          `By Invite Registration Error is: ${action.payload.errorMsg}`
        );
      }
      state.errorByInviteRegistration = action.payload.errorMsg!;
    },
    checkSignInStatus: (state, action) => {
      state.isAuthenticated = true;
      state.errorSignIn = "";
      state.auth = action.payload.auth;
    },
    signInError: (state, action: { payload: { errorMsg: string | null } }) => {
      if (action.payload.errorMsg !== undefined) {
        console.log(`Signin Error is: ${action.payload.errorMsg}`);
      }
      state.isAuthenticated = false;
      state.errorSignIn = action.payload.errorMsg;
      state.appLoading = false;
    },
    signOutSuccess: (state) => {
      console.log("signOutSuccess");

      localStorage.removeItem("ut-token");
      localStorage.removeItem("ut-expires");
      localStorage.removeItem("ut-refresh-token");
      localStorage.removeItem("ut-accsesToken");
      localStorage.removeItem("ut-signInType");
      localStorage.removeItem("ut-cognitoid");
      localStorage.removeItem("ut-email");
      localStorage.removeItem("ut-user");
      localStorage.removeItem("ut-asst-email");
      localStorage.removeItem("ut-dueDate");
      localStorage.removeItem("ut-planTier");
      localStorage.removeItem("ut-refresh-cognito-data");

      localStorage.setItem("auth", "false");

      state.isAuthenticated = false;
      state.errorSignIn = "";
    },
    abortChangePwd: (state) => {
      state.confirmationCode = null;
      state.forgotPwdSection = "formEmail";
    },
    changeForgotPwdSection: (state, action) => {
      state.forgotPwdSection = "formNewPass";
      state.confirmationCode = action.payload;
    },
    signUpSuccess: (state) => {
      state.isRegistering = "success";
      state.errorRegistration = "";
    },
    resetAlert: (state) => {
      state.alert.active = false;
      state.alert.type = "";
      state.alert.message = "";
      state.alert.autoHideDuration = 6000;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(actionTypes.AUTHENTICATED_USER_ACCOUNT, (state, action) => ({
        ...state,
      }))
      //Sign in request
      .addCase(signInRequest.pending, (state, action) => {
        state.appLoading = true;
      })
      .addCase(signInRequest.fulfilled, (state, action: any) => {
        if (action.payload?.auth !== undefined) {
          state.isAuthenticated = true;
          state.errorSignIn = "";
          state.auth = action.payload.auth;
          state.appLoading = false;
        }
      })
      .addCase(signInRequest.rejected, (state, action: any) => {
        const { type, message } = action.payload.alert;
        state.appLoading = false;
        state.alert.active = true;
        state.alert.type = type;
        state.alert.message = message;
        state.alert.autoHideDuration = action.payload.alert.autoHideDuration
          ? action.payload.alert.autoHideDuration
          : state.alert.autoHideDuration;
      })
      //Google Sign in request
      .addCase(googleSignIn.pending, (state, action) => {
        state.appLoading = true;
      })
      .addCase(googleSignIn.fulfilled, (state, action: any) => {
        if (action.payload?.auth !== undefined) {
          state.isAuthenticated = true;
          state.errorSignIn = "";
          state.auth = action.payload.auth;
          state.appLoading = false;
        }
      })
      .addCase(googleSignIn.rejected, (state, action: any) => {
        const { type, message } = action.payload.alert;
        state.appLoading = false;
        state.alert.active = true;
        state.alert.type = type;
        state.alert.message = message;
        state.alert.autoHideDuration = action.payload.alert.autoHideDuration
          ? action.payload.alert.autoHideDuration
          : state.alert.autoHideDuration;
      })
      /** registerRequest redux controller */
      .addCase(registerRequest.pending, (state) => {
        state.appLoading = true;
      })
      .addCase(registerRequest.fulfilled, (state, action) => {
        // if (action.payload?.auth !== undefined) {
        //   state.isAuthenticated = true;
        //   state.errorSignIn = "";
        //   state.auth = action.payload.auth;
        //   state.appLoading = false;
        // }
      })
      .addCase(registerRequest.rejected, (state, action: any) => {
        const { type, message } = action.payload.alert;
        state.appLoading = false;
        state.alert.active = true;
        state.alert.type = type;
        state.alert.message = message;
        state.alert.autoHideDuration = action.payload.alert.autoHideDuration
          ? action.payload.alert.autoHideDuration
          : state.alert.autoHideDuration;
      })
      .addCase(registerFromGoogle.pending, (state) => {
        state.appLoading = true;
      })
      .addCase(registerFromGoogle.fulfilled, (state, action) => {
        if (action.payload?.auth !== undefined) {
          console.log("---registerFromGoogle checked");
          state.isAuthenticated = true;
          state.errorSignIn = "";
          state.auth = action.payload.auth;
          state.appLoading = false;
        }
      })
      .addCase(registerFromGoogle.rejected, (state, action: any) => {
        const { type, message } = action.payload.alert;
        state.appLoading = false;
        state.alert.active = true;
        state.alert.type = type;
        state.alert.message = message;
        state.alert.autoHideDuration = action.payload.alert.autoHideDuration
          ? action.payload.alert.autoHideDuration
          : state.alert.autoHideDuration;
      })
      .addCase(authChangePassword.pending, (state, action: any) => {
        state.appLoading = true;
      })
      .addCase(authChangePassword.fulfilled, (state, action: any) => {
        state.appLoading = false;
        state.isPasswordChanged = action.payload;
      })
      .addCase(authChangePassword.rejected, (state, action: any) => {
        const { type, message } = action.payload.alert;
        state.appLoading = false;
        state.alert.active = true;
        state.alert.type = type;
        state.alert.message = message;
        state.alert.autoHideDuration = action.payload.alert.autoHideDuration
          ? action.payload.alert.autoHideDuration
          : state.alert.autoHideDuration;
      })
      .addCase(authForgotPassword.pending, (state) => {
        state.appLoading = true;
      })
      .addCase(authForgotPassword.fulfilled, (state, action) => {
        state.appLoading = false;
        state.forgotPwdSection = action.payload;
      })
      .addCase(authForgotPassword.rejected, (state, action: any) => {
        console.log(action.payload);
        const { type, message } = action.payload.alert;
        state.appLoading = false;
        state.alert.active = true;
        state.alert.type = type;
        state.alert.message = message;
        state.alert.autoHideDuration = action.payload.alert.autoHideDuration
          ? action.payload.alert.autoHideDuration
          : state.alert.autoHideDuration;
      })
      .addCase(authNewPassword.pending, (state) => {
        state.appLoading = true;
      })
      .addCase(authNewPassword.fulfilled, (state) => {
        state.forgotPwdSection = "formSuccess";
        state.appLoading = false;
      })
      .addCase(authNewPassword.rejected, (state) => {
        console.log("case rejected");
        state.forgotPwdSection = "formInvalid";
        state.appLoading = false;
      })
      .addCase(registerByEmailInviteRequest.pending, (state) => {
        state.appLoading = true;
      })
      .addCase(registerByEmailInviteRequest.fulfilled, (state) => {
        state.appLoading = false;
      })
      .addCase(registerByEmailInviteRequest.rejected, (state) => {
        state.appLoading = false;
      })
      .addCase(refreshCognitoToken.pending, (state) => {
        state.appLoading = true;
      })
      .addCase(refreshCognitoToken.fulfilled, (state) => {
        state.appLoading = false;
      })
      .addCase(refreshCognitoToken.rejected, (state) => {
        state.appLoading = false;
        // ========= Log out call here =============
        localStorage.removeItem("ut-token");
        localStorage.removeItem("ut-expires");
        localStorage.removeItem("ut-refresh-token");
        localStorage.removeItem("ut-cognitoid");
        localStorage.removeItem("ut-email");
        localStorage.removeItem("ut-user");

        localStorage.setItem("auth", "false");
        state.alert = {
          active: true,
          type: "error",
          message: "Error refreshing token",
          autoHideDuration: 6000,
        };
      })
      .addCase(googlegetRefreshToken.pending, (state) => {
        state.appLoading = true;
      })
      .addCase(googlegetRefreshToken.fulfilled, (state) => {
        state.appLoading = false;
      })
      .addCase(googlegetRefreshToken.rejected, (state) => {
        state.appLoading = false;
        state.alert = {
          active: true,
          type: "error",
          message: "Error refreshing token",
          autoHideDuration: 6000,
        };
      })
      .addDefaultCase((state, action) => ({ ...state }));
  },
});

const { reducer, actions } = sliceAuth;

export const {
  registrationInit,
  registrationUserAlreadyExist,
  registrationError,
  registrationNotVerified,
  byInviteRegistrationSuccsess,
  registrationByInviteError,
  checkSignInStatus,
  signInError,
  signOutSuccess,
  resetAlert,
  abortChangePwd,
  changeForgotPwdSection,
  signUpSuccess,
} = actions;

const authReducer = reducer;

export default authReducer;
