// store creation
import { configureStore } from "@reduxjs/toolkit";

import authReducer from "./reducers/reducerAuth";
import reducerAlert from "./reducers/reducerAlert";
import reducerModal from "./reducers/reducerModal";
import reducerLoading from "./reducers/reducerLoading";
import userSlice from "./reducers/reducerUser";
import reducerTeam from "./reducers/reducerTeam";
import reducerGroup from "./reducers/reducerGroup";
import reducerWalkthrough from "./reducers/reducerWalkthrough";
import reducerGroupWalkthrough from "./reducers/reducerGroupWalkthrough";
import reducerOrg from "./reducers/reducerOrganization";
import reducerAnalytics from "./reducers/reducerAnalytics";
import reducerUserSegmentation from "./reducers/reducerUserSegmentation";
import mediaReducer from "./reducers/reducerMedia";
import paymentReducer from "./reducers/reducerPayment";
import guideReducer from "./reducers/reducerGuide";
import inviteQuickReducer from "./reducers/reducerInviteQuickAccess";
import themeReduce from "./reducers/reducerTheme";
import reducerNavigation from "./reducers/reducerNavigation";

const store = configureStore({
  reducer: {
    alert: reducerAlert,
    modal: reducerModal,
    loading: reducerLoading,
    auth: authReducer,
    team: reducerTeam,
    user: userSlice,
    //@ts-ignore
    group: reducerGroup,
    //@ts-ignore
    walkthrough: reducerWalkthrough,
    //@ts-ignore
    groupWalkthrough: reducerGroupWalkthrough,
    org: reducerOrg,
    //@ts-ignore
    analytics: reducerAnalytics,
    userSegmentation: reducerUserSegmentation,
    media: mediaReducer,
    payment: paymentReducer,
    guide: guideReducer,
    theme: themeReduce,
    inviteQuick: inviteQuickReducer,
    navigation: reducerNavigation,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
