import React from "react";
import WarningMessage from "../components/WarningMessage/WarningMessage";
import { Button } from "usertip-component-library";
import { useNavigate } from "react-router-dom";

interface Props {
  isLogoutButton?: boolean;
}

const PageTrialOver = ({ isLogoutButton }: Props) => {
  const navigate = useNavigate();
  return (
    <WarningMessage isLogoutButton={isLogoutButton}>
      <div className="w-ful h-full flex justify-center items-center flex-col">
        <h1 className="title-m">Your 14-days trial is over</h1>
        <p className="text-default-neutral-60 font-normal text-xl leading-5 ">
          Please upgrade your plan to continue using Usertip.
        </p>
        <div className="flex mt-6 gap-2">
          <Button
            color="info"
            size="medium"
            text="Upgrade Plan"
            variant="primary"
            onClick={() => {
              navigate("/billing/subscription");
            }}
          />
        </div>
      </div>
    </WarningMessage>
  );
};

export default PageTrialOver;
