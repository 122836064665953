import React, { useEffect, useRef, useState } from "react";
import StepContainer from "../../02-molecules/05-picture/StepContainer";
import StepName from "../../02-molecules/05-picture/StepName";
import StepNumber from "../../02-molecules/05-picture/StepNumber";
import MainPictureContainer from "../../02-molecules/05-picture/MainPictureContainer";
import PictureController from "../../02-molecules/05-picture/PictureController";
import StepNameEditMode from "../../02-molecules/05-picture/StepNameEditMode";
import ImageUpload from "../../02-molecules/07-ImageUpload/ImageUpload";

interface Props {
  imageUrl?: string;
  index: number;
  position?: {
    x?: number;
    y?: number;
  };
  editMode?: boolean;
  elementText?: string | null;
  id: string;
  onEditStepName?: (id: string, value: string) => void;
  status?: string;
  imageHandler?: (componentId: string, imageUrl: string, data: any) => void;
}

const PictureViewer = ({
  imageUrl,
  index,
  position,
  editMode,
  id,
  elementText,
  onEditStepName,
  status,
  imageHandler,
}: Props) => {
  /**state for displaying zooom in/zoom out icon whe hover into components */
  const [hover, setHover] = useState(false);
  /**state for scale */
  const [scale, setScale] = useState(3);
  /**state for string translate */
  const [translate, setTranslate] = useState(`translate(0, 0) scale(${scale})`);
  /**state for step name */
  const [stepName, setStepName] = useState("click here" as string);

  /**ref for image */
  const imageRef = useRef<HTMLImageElement>(null);
  /**ref for container */
  const containerRef = useRef<HTMLDivElement>(null);

  /**Default of scale */
  const scaleList = [1, 1.5, 2, 2.5, 3, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8];
  /**Default of image container size */
  const imgContainerSize = {
    width: 650,
    height: 400,
  };

  /**calculate position y and x into image container */
  function convertPositionIntoImageContainer(funcScale?: number) {
    console.log("---calculate image position");
    const newScale = funcScale ? funcScale : scale;

    if (!containerRef.current) return;
    /** container, refers to image container */
    const container = containerRef.current;
    const img = container.firstChild as HTMLImageElement;
    const divRect = container.getBoundingClientRect();

    const imgRect = img.getBoundingClientRect();

    if (!position) {
      return null;
    }

    if (!position.x) {
      return null;
    }

    if (!position.y) {
      return null;
    }

    /** --- OLD CALCULATION */
    const convertXPosition = (position.x - imgRect.left) / scale;
    const convertYPosition = (position.y - imgRect.top) / scale;

    let scaleConvert = (scale - 1) / 2;

    const centeredLeft = -convertXPosition + container.offsetTop;
    const centeredTop = -convertYPosition + container.offsetLeft;

    // const centeredLeft = convertXPosition - imgContainerSize.width / 2;
    // const centeredTop = convertYPosition - imgContainerSize.height / 2;

    /** --- OLD CALCULATION */

    /** --- NEW CALCULATION */
    const offsetTop = img.offsetTop;
    const offsetLeft = img.offsetLeft;

    const width = imgContainerSize.width;
    const height = imgContainerSize.height;

    // const xPos = -((position.x - offsetLeft) / scale - width / 2);
    // const yPos = -((position.y - offsetTop) / scale - height / 2);

    // const xPos = -(
    //   ((position.x - offsetLeft) / 2 - width / 2) *
    //   (newScale - 1)
    // );
    // const yPos = -(
    //   ((position.y - offsetTop) / 2 - height / 2) *
    //   (newScale - 1)
    // );

    /** --- NEW CALCULATION */

    /** Newest Calculation */
    let xPos: number = 0;
    let yPos: number = 0;

    const calc = img.naturalWidth / img.width;
    const convertedWidth = (img.naturalWidth / calc) * newScale;
    const convertedHeight = (img.naturalHeight / calc) * newScale;
    const newClickX =
      (((position.x * 25) / 100 + position.x) / calc) * newScale;
    const newClickY =
      (((position.y * 25) / 100 + position.y) / calc) * newScale;

    if (newClickX < convertedWidth / 2) {
      xPos = width / 2 - newClickX;
    } else {
      xPos = -(newClickX - width / 2);
    }

    if (newClickY < convertedHeight / 2) {
      yPos = height / 2 - newClickY;
    } else {
      yPos = -(newClickY - height / 2);
    }

    /** Newest Calculation */

    const xScale = scale - 1;

    setTsState({
      ...tsState,
      translate: {
        x: xPos,
        y: yPos,
      },
    });

    return {
      left: xPos,
      top: yPos,
    };
  }

  /**Function to get translate position */
  const getTranslatePosition = (scale: number) => {
    const tranfromPositon = convertPositionIntoImageContainer(scale);

    if (!tranfromPositon) return `translate(0, 0) scale(${scale})`;

    if (scale < 1.5) {
      //return image and container to original position
      return `translate(0, 0) scale(${scale})`;
    }

    return `translate(${tranfromPositon!.left}px, ${
      tranfromPositon!.top
    }px) scale(${scale})`;
    return `translate(0, 0) scale(${scale})`;
  };

  /**Function to get scale image */
  const getScaleImage = (zoomIn: boolean) => {
    //if zoom in
    if (zoomIn) {
      //return if scale is max
      if (scale === scaleList[scaleList.length - 1]) return;
      //set scale to next index in scaleList
      const nextScale = scaleList[scaleList.indexOf(scale) + 1];
      setScale(nextScale);
      return nextScale;
    }
    //if zoom out
    if (!zoomIn) {
      //return if scale is min
      if (scale === scaleList[0]) return;
      //set scale to previous index in scaleList
      const prevScale = scaleList[scaleList.indexOf(scale) - 1];
      setScale(prevScale);
      return prevScale;
    }

    return 0;
  };

  /**Function to zoom in onclick */
  const clickZoomInImage = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!imageRef.current) return;

    const image = imageRef.current;

    const scale = getScaleImage(true);
    const position = getTranslatePosition(scale!);
    setTranslate(position!);
  };

  /**Function to zoom out onclick */
  const clickZoomOutImage = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!imageRef.current) return;

    const image = imageRef.current;

    const scale = getScaleImage(false);
    const position = getTranslatePosition(scale!);
    setTranslate(position!);
  };

  /** Function for onLoad image */
  const onImageLoad = () => {
    if (!imageRef.current) return;
    const image = imageRef.current;
    const position = getTranslatePosition(scale);
    setTranslate(position!);
  };

  /**use effect for set initial step name */
  useEffect(() => {
    if (elementText === null || elementText === undefined) {
      setStepName("Click here");
    } else {
      setStepName(`${elementText}`);
    }
  }, [elementText]);

  /**use effect for set initial image position */
  const onChangeStepName = (value: string) => {
    setStepName(value);
    onEditStepName!(id, value);
  };

  /**Convert variable to state */
  const [panning, setPanning] = useState(false);

  /**Convert variable to state */
  const [mouseX, setMouseX] = useState(0);
  const [mouseY, setMouseY] = useState(0);
  const [mouseTX, setMouseTX] = useState(0);
  const [mouseTY, setMouseTY] = useState(0);

  const ts = {
    scale: 1,
    rotate: 0,
    translate: {
      x: 0,
      y: 0,
    },
  };
  /**convert ts into state */
  const [tsState, setTsState] = useState(ts);

  const onImgMouseDown = (e: React.MouseEvent<HTMLImageElement>) => {
    const img = imageRef.current;
    if (!img) return;

    e.preventDefault();
    setPanning(true);
    img.style.cursor = "grabbing";
    setMouseX(e.clientX);
    setMouseY(e.clientY);
    setMouseTX(tsState.translate.x);
    setMouseTY(tsState.translate.y);
  };

  const onImgMouseUp = (e: React.MouseEvent<HTMLImageElement>) => {
    const img = imageRef.current;
    if (!img) return;
    setPanning(false);
    img.style.cursor = "grab";
  };

  const onImgMouseDrag = (e: React.MouseEvent<HTMLImageElement>) => {
    const img = imageRef.current;
    if (!img) return;

    let rec = img.getBoundingClientRect();
    let xx = e.clientX - rec.x;
    let xy = e.clientY - rec.y;

    const x = e.clientX;
    const y = e.clientY;
    if (!panning) {
      return;
    }
    const translateX = mouseTX + (x - mouseX);
    const translateY = mouseTY + (y - mouseY);

    setTsState((prevState) => ({
      ...prevState,
      translate: {
        x: translateX,
        y: translateY,
      },
    }));
    const steps = `translate(${tsState.translate.x}px,${tsState.translate.y}px) scale(${scale}) rotate(${ts.rotate}deg) translate3d(0,0,0)`;
    setTranslate(steps);
  };

  return (
    <StepContainer>
      <div className="flex w-full items-center flex-row">
        <StepNumber stepNumber={index} />
        {!editMode ? (
          <StepName msgText={stepName} />
        ) : (
          <div className="ml-4">
            <StepNameEditMode setValue={onChangeStepName} value={stepName} />
          </div>
        )}
      </div>
      {status === "new_image" && (
        <ImageUpload id={id} imageHandler={imageHandler} />
      )}
      {imageUrl && status !== "new_image" && (
        <div
          className="mt-4"
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          <MainPictureContainer
            width={imgContainerSize.width}
            height={imgContainerSize.height}
            containerRef={containerRef}
          >
            <img
              ref={imageRef}
              src={imageUrl}
              alt=""
              style={{
                // translate to center
                transform: translate,
                transition: "transform .6s cubic-bezier(0.075, 0.82, 0.165, 1)",
              }}
              onMouseDown={onImgMouseDown}
              onMouseUp={onImgMouseUp}
              onMouseMove={onImgMouseDrag}
              onLoad={onImageLoad}
            />
            <PictureController
              clickOnZoomIn={clickZoomInImage}
              clickOnZoomOut={clickZoomOutImage}
              hover={hover}
            />
          </MainPictureContainer>
        </div>
      )}
    </StepContainer>
  );
};

export default PictureViewer;
