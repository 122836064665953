import { fromUnixTime } from "date-fns";
import { callPOSTRetrieveUserPermissionAccess } from "../../../axios/v2/callsOrganization";
import { callGETRetrieveUserAccountInformation } from "../../../axios/v2/callsUser";
import verifyPayload from "../../../utility/functions/verifyPayload";
import { setOrgPlanInformation } from "../reducerOrganization";

// Get user info after a google registration
export async function authGetUserInfo({ data, thunkApi, store }: any) {
  console.log("Google token post registration", data);

  localStorage.setItem("ut-token", data.token);
  //set sign type to google
  localStorage.setItem("ut-signInType", "Google");

  const getUser = await callGETRetrieveUserAccountInformation(data.cognitoId);
  /**If there's error found display alert */
  if (getUser.data.success === false) {
    /**If fail remove the token and sign in type */
    localStorage.removeItem("ut-google-register-user");
    localStorage.removeItem("ut-token");
    const result = {
      alert: {
        type: "error",
        message: "Failed to get user info",
      },
    };
    return thunkApi.rejectWithValue(result);
  }
  const user = getUser.data.payload.user;

  // retrieve user role information API
  const response = await callPOSTRetrieveUserPermissionAccess(user.org);
  // Abstract role for checking
  if (!response.data) throw response;

  const signed_payload = response.data.signed_payload;
  const verified_payload = await verifyPayload(signed_payload);

  /** Get user role from verified payload */
  const role = verified_payload ? verified_payload.role : null;
  /** Get user organization from verified payload */
  const getOrg = verified_payload.org;
  /** Get user access permissions from verified payload */
  const orgPlan = verified_payload.orgPlan;
  // Store AuthenticationResult & User details to localStorage
  user.role = role;
  store.dispatch(setOrgPlanInformation({ orgPlan: orgPlan }));

  const expireIn = fromUnixTime(data.expiresIn).toString();
  localStorage.setItem("ut-expires", expireIn);
  localStorage.setItem("ut-cognitoid", data.cognitoId);
  localStorage.setItem("ut-email", data.email);
  localStorage.setItem("auth", "true");
  localStorage.setItem("ut-user", JSON.stringify(user));
  localStorage.setItem("ut-token", data.token);
  //set sign type to google
  localStorage.setItem("ut-signInType", "Google");

  const auth = {
    accessToken: data.token,
    expireIn: data.expiresIn,
  };

  window.history.pushState({}, "", "/");
  return {
    auth: auth,
    user: user,
  };
}
