import React, { useEffect, useState } from "react";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { convertDateToStandardFormat } from "../_functions/FunctionDate";
import { Delete, Settings } from "@mui/icons-material";
import { Button, Status } from "usertip-component-library";
import { useSelector } from "react-redux";
import ComponentRemoveWalkthroughs from "../ComponentRemoveWalkthroughs";
import ComponentAddTags from "../ComponentAddTags";
import { useNavigate } from "react-router-dom";
import { Walkthrough } from "../../interfaces/interface Organization";

interface Props {
  walkthroughData: Walkthrough[];
}

export default function TableWalkthroughs({ walkthroughData }: Props) {
  //state from redux

  const [selectedRowIds, setSelectedRowIds] = useState([]);

  const handleSelectionModelChange = (selectionModel: any) => {
    // Use selectionModel to find the selected rows from the rows array
    const selectedRowObjects = selectionModel.map((rowId: any) =>
      walkthroughData.find((row: any) => row.id === rowId)
    );
    setSelectedRowIds(selectedRowObjects);
  };

  const [showModalRemove, setShowModalRemove] = useState(false);

  const [removeMode, setRemoveMode] = useState("");

  const [resetTableSelectAll, setResetTableSelectAll] = useState(false);

  useEffect(() => {
    !showModalRemove &&
      setTimeout(() => {
        setSelectedRowIds([]);
      }, 200);
  }, [showModalRemove]);

  const navigate = useNavigate();

  const columns: GridColDef[] = [
    {
      field: "publish",
      headerName: "Status",
      minWidth: 100,
      editable: false,
      valueFormatter: (params) => {
        return params.value ? "LIVE" : "DRAFT";
      },
      renderCell: (params) => {
        return (
          <span className="break-normal">
            {params.value ? (
              <Status text="LIVE" size="small" variants="confirmed" />
            ) : (
              <Status text="DRAFT" size="small" variants="pending" />
            )}
          </span>
        );
      },
    },
    {
      field: "name",
      headerName: "Walkthroughs",
      flex: 0.9,
      minWidth: 150,
      editable: false,
    },
    {
      field: "launched",
      headerName: "Launched",
      minWidth: 100,
      editable: false,
      align: "right",
      headerAlign: "right",
    },
    {
      field: "completed",
      headerName: "Completed",
      minWidth: 100,
      editable: false,
      align: "right",
      headerAlign: "right",
    },
    {
      field: "dismissed",
      headerName: "Dismissed",
      minWidth: 100,
      editable: false,
      align: "right",
      headerAlign: "right",
    },
    {
      field: "createdAt",
      headerName: "Date Created",
      minWidth: 150,
      editable: false,
      valueFormatter: (params) => convertDateToStandardFormat(params.value),
    },
    {
      field: "tags",
      headerName: "Tags",
      minWidth: 132,
      editable: false,
      align: "left",
      renderCell: (params) => (
        <ComponentAddTags
          inputWalkthroughTags={params.row.tags}
          walkthroughID={params.row.walkthrough_id}
        />
      ),
    },
    {
      field: "action",
      headerName: "Action(s)",
      minWidth: 110,
      renderCell: (params) => (
        <div className="flex flex-row gap-3" title="Walkthrough Settings">
          <div
            className="cursor-pointer text-neutral-500"
            onClick={() =>
              navigate(
                `/guides/walkthroughs/${params.row.walkthrough_id}/settings/general`
              )
            }
          >
            <Settings />
          </div>
          <div
            className="cursor-pointer text-neutral-500"
            onClick={(e) => {
              setShowModalRemove(true);
              setRemoveMode("single");
              console.log(params.row);
            }}
            title="Delete Walkthrough"
          >
            <Delete />
          </div>
        </div>
      ),
    },
  ];

  // START OF SMU FYP ANALYTIPS CODE
  const sendDataToHookDotCom = (dataToSend: any[]) => {
    // Prepare the fetch request options
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // Add any other headers if required
      },
      body: JSON.stringify(dataToSend),
    };

    // Send the data to hook.com
    // fetch(
    //   "https://hook.eu2.make.com/qmxxpih8h219m4ex7y7uuwdaw77w6l9n",
    //   requestOptions
    // )
    //   .then((response) => {
    //     if (!response.ok) {
    //       throw new Error("Network response was not ok");
    //     }
    //     return response.json();
    //   })
    //   .then((data) => {
    //     console.log("Data sent successfully to hook.com:", data);
    //   })
    //   .catch((error) => {
    //     console.error("There was a problem sending data to hook.com:", error);
    //   });
  };

  // useEffect(() => {
  //   // Send data to hook.com every day (every 24 hours)
  //   const interval = setInterval(() => {
  //     sendDataToHookDotCom(walkthroughs);
  //   }, 24 * 60 * 60 * 1000); // 24 hours in milliseconds

  //   // Execute sendDataToHookDotCom immediately when the component mounts
  //   sendDataToHookDotCom(walkthroughs);

  //   // Cleanup the interval on component unmount
  //   return () => clearInterval(interval);
  // }, [walkthroughs]);

  useEffect(() => {
    sendDataToHookDotCom(walkthroughData);
  }, []); // This effect will run once when the component mounts
  // END OF SMU FYP ANALYTIPS CODE

  return (
    <div>
      <div className="bg-default-primary rounded-tr-[20px] rounded-tl-[20px] flex flex-row justify-between px-6 py-4 items-center text-white">
        <div className="text-base font-bold">
          {selectedRowIds.length > 0 ? (
            <span>{selectedRowIds.length} selected</span>
          ) : (
            <span>Manage Walkthroughs</span>
          )}
        </div>
        <div
          className={` flex-row gap-2 items-center border-whi ${
            selectedRowIds.length > 0 ? "flex" : "hidden"
          }`}
        >
          <Button
            text="Delete Walkthroughs"
            iconLeft
            icon={<Delete />}
            size="small"
            variant="outlined"
            color="neutral"
            onClick={() => {
              setShowModalRemove(true);
              setRemoveMode("bulk");
            }}
            customClass={"ut-btn-org"}
          />
        </div>
      </div>
      <div className="bg-white">
        <DataGrid
          rows={walkthroughData || []}
          columns={columns}
          autoHeight
          checkboxSelection
          selectionModel={selectedRowIds.map((row: any) => row.id)}
          onSelectionModelChange={handleSelectionModelChange}
          initialState={{
            pagination: {
              page: 0,
              pageSize: 25,
            },
          }}
          rowsPerPageOptions={[25, 50, 100]}
          sx={{ borderEndEndRadius: 20, borderEndStartRadius: 20 }}
        />
      </div>

      <ComponentRemoveWalkthroughs
        // selectedUserOrgID={orgID}
        selectedWalkthroughList={selectedRowIds || ""}
        showModalRemove={showModalRemove}
        setShowModalRemove={setShowModalRemove}
        removeMode={removeMode}
        setResetTableSelectAll={setResetTableSelectAll}
      />
    </div>
  );
}
