import { Visibility, VisibilityOff } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { InputField, Button } from "usertip-component-library";

interface Props {
  type: "forgot" | "change";
  /** This prop is for set the old password input value (useState) for change variant only*/
  oldPassword?: string;
  /** This prop is for change the old password input value (useState) for change variant only*/
  setOldPassword?: React.Dispatch<React.SetStateAction<string>>;
  /** This prop is for set the password input value (useState) */
  password: string;
  /** This prop is for change the password input value (useState) */
  setPassword: React.Dispatch<React.SetStateAction<string>>;
  /** This prop is for cancel onClick */
  AbortForgotPasswordHandler?: () => void;
  /** This prop is for form submitHandler */
  onSubmitHandler: (e: React.FormEvent<HTMLFormElement>) => void;
}

const PasswordForm = ({
  type,
  oldPassword,
  setOldPassword,
  password,
  setPassword,
  AbortForgotPasswordHandler,
  onSubmitHandler,
}: Props) => {
  /** This state is for showing text for the password input  */
  const [oldPasswordVisibility, setOldPasswordVisibility] =
    useState<boolean>(false);
  const [passwordVisibility, setPasswordVisibility] = useState<boolean>(false);
  const [confirmVisibility, setConfirmVisibility] = useState<boolean>(false);

  /** This state is for value of the input and change the value  */
  const [confirmValue, setConfirmValue] = useState<string>("");

  /** This is for check if the input is valid or not */
  const [oldPasswordIsValid, setOldPasswordIsValid] = useState<boolean | null>(
    null
  );
  const [passwordIsValid, setPasswordIsValid] = useState<boolean | null>(null);
  const [confirmIsValid, setConfirmIsValid] = useState<boolean | null>(null);

  /** This state is for showing password error, the array will pas to the input field */
  const [passwordError, setPasswordError] = useState<string[]>([]);

  const navigate = useNavigate();

  /** This function for validating the password */
  const validatePassword = (password: string) => {
    const containMinChar = password.length >= 8;
    const containSpecialChar = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(
      password
    );
    const containCaps = /[A-Z]/.test(password);
    const containNumber = /[0-9]/.test(password);

    const isValid =
      containMinChar && containSpecialChar && containCaps && containNumber;

    let errorMessage: string[] = [];

    if (!containMinChar) {
      errorMessage.push("Requires at least 8 characters");
    }
    if (!containSpecialChar) {
      errorMessage.push("Requires to have at least one special character");
    }
    if (!containCaps) {
      errorMessage.push("Requires to have at least one capital letter");
    }
    if (!containNumber) {
      errorMessage.push("Requires to have at least one number");
    }

    return { isValid, errorMessage };
  };

  /** This useEffect is for validating the password and confirm password input */
  useEffect(() => {
    /** This is for validating the old password input, only run when the type is "change" */
    if (type === "change") {
      if (oldPassword?.length === 0) {
        setOldPasswordIsValid(null);
      } else {
        setOldPasswordIsValid(true);
      }
    } else {
      setOldPasswordIsValid(true);
    }

    /** This is for validating the password input */
    if (password.length === 0) {
      setPasswordIsValid(null);
    } else {
      const checkPassword = validatePassword(password);

      setPasswordIsValid(checkPassword.isValid);
      setPasswordError(checkPassword.errorMessage);
    }

    /** This is for validating the confirm password input */
    if (confirmValue.length === 0) {
      setConfirmIsValid(null);
    } else {
      setConfirmIsValid(confirmValue === password);
    }
  }, [oldPassword, password, confirmValue]);

  /** Handler for old password */
  const oldPasswordHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (setOldPassword) {
      setOldPassword(e.target.value);
    }
  };

  /** Handler for password */
  const passwordHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  /** Handler for confirm password */
  const confirmHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmValue(e.target.value);
  };

  return (
    <div className="flex justify-center">
      <form
        action="submit"
        className="flex flex-col gap-4"
        onSubmit={onSubmitHandler}
      >
        {type === "change" && (
          <InputField
            variant="full"
            helperText=""
            placeholder="Enter your password"
            label="Old Password"
            iconRight={true}
            type={oldPasswordVisibility ? "text" : "password"}
            icon={
              <div
                className="text-sm text-default-neutral-60 cursor-pointer"
                onClick={() => {
                  setOldPasswordVisibility(!oldPasswordVisibility);
                }}
              >
                {oldPasswordVisibility ? <Visibility /> : <VisibilityOff />}
              </div>
            }
            value={oldPassword}
            onChange={oldPasswordHandler}
          />
        )}
        <InputField
          variant="full"
          helperText=""
          placeholder="Enter your new password"
          label="New Password"
          errorText={passwordError}
          iconRight={true}
          type={passwordVisibility ? "text" : "password"}
          icon={
            <div
              className="text-sm text-default-neutral-60 cursor-pointer"
              onClick={() => {
                setPasswordVisibility(!passwordVisibility);
              }}
            >
              {passwordVisibility ? <Visibility /> : <VisibilityOff />}
            </div>
          }
          value={password}
          onChange={passwordHandler}
          error={passwordIsValid === null ? undefined : !passwordIsValid}
        />
        <InputField
          variant="full"
          helperText=""
          placeholder="Confirm your new password"
          label="Confirm New Password"
          errorText="Password does not match"
          iconRight={true}
          type={confirmVisibility ? "text" : "password"}
          icon={
            <div
              className="text-sm text-default-neutral-60 cursor-pointer"
              onClick={() => {
                setConfirmVisibility(!confirmVisibility);
              }}
            >
              {confirmVisibility ? <Visibility /> : <VisibilityOff />}
            </div>
          }
          value={confirmValue}
          onChange={confirmHandler}
          error={confirmIsValid === null ? undefined : !confirmIsValid}
        />
        <Button
          color="primary"
          size="fluid"
          variant="primary"
          text="Confirm"
          disabled={!passwordIsValid || !confirmIsValid || !oldPasswordIsValid}
        />
        <Button
          color="secondary"
          size="fluid"
          variant="primary"
          text="Cancel"
          onClick={() => {
            if (AbortForgotPasswordHandler) {
              AbortForgotPasswordHandler();
            }
            navigate("/");
          }}
        />
      </form>
    </div>
  );
};

export default PasswordForm;
