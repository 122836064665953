import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  callGETInvoiceListByOrgId,
  callPostSubscribe,
  callPostSubscriptionCancel,
  callPOSTUpdateInvoiceEmail,
} from "../../axios/v2/callsPayment";
import { PaymentInfo } from "../../interfaces/interfacePaymentInfor";
import store from "..";

//TODO: Change payment tier to the newest one
export type PaymentTier =
  | "FREE1"
  | "FREE2"
  | "FREE3"
  | "PAID1"
  | "PAID2"
  | "STARTUP"
  | "CUSTOM"
  | "ENTERPRISE"
  | "FREE_UNLIMITED"
  | "MICRO_PLAN"
  | "STARTER_PLAN"
  | "BUSINESS_PLAN";

export const PLAN_CHANGE_CODE = "PLAN_CHANGE";

export const postSubscribe = createAsyncThunk<
  any,
  { tier: PaymentTier; sub_type: "BILLING_MONTHLY" | "BILLING_YEARLY" }
>(
  "payment/subscribe",
  async (
    data: { tier: PaymentTier; sub_type: "BILLING_MONTHLY" | "BILLING_YEARLY" },
    thunkApi
  ) => {
    try {
      console.log("---call postSubscribe", data.tier);

      const tier = data.tier;

      if (
        tier !== "MICRO_PLAN" &&
        tier !== "STARTER_PLAN" &&
        tier !== "BUSINESS_PLAN"
      ) {
        console.error("Subscription tier does not exist. Check with admin.");
        return;
      }
      /**call api for subscribe payment */
      const response = await callPostSubscribe(tier, data.sub_type);
      if (response.data.success === true) {
        localStorage.removeItem("ut-paymentTier");

        // Scenario 1: User was unsubscribed, API returns payment_link
        if (response.data.payload && response.data.payload.payment_link) {
          return response.data.payload.payment_link as string;
        }

        // Scenario 2: User alr subscribed, API does not return payment_link -> redirect to Billing Page
        // User could be upgrading or downgrading their plan
        return PLAN_CHANGE_CODE;
        /**return payment link from api call */
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      console.log(error);
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const paymentPOSTActiveSubscriptionCancel = createAsyncThunk(
  "payment/subscribe_cancel",
  async (orgId: string, thunkApi) => {
    try {
      const response = await callPostSubscriptionCancel(orgId);
      if (response.data.success === true) {
        return true;
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      console.log(error);
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const paymentPOSTUpdateInvoiceEmail = createAsyncThunk(
  "payment/updateInvoiceEmail",
  async (data: { orgID: string; email: string }, thunkApi) => {
    try {
      const { orgID, email } = data;
      await callPOSTUpdateInvoiceEmail(orgID, email);

      return { email: email };
    } catch (err) {
      console.log("error");
      console.log(err);
      return thunkApi.rejectWithValue(err);
    }
  }
);

/** Function to retrieve invoice list and store in redux */
export const paymentGETInvoiceListByOrgId = createAsyncThunk(
  "payment/getInvoiceList",
  async (orgID: string, thunkApi) => {
    try {
      const response = await callGETInvoiceListByOrgId(orgID);

      const result = response.data.payload;
      return result;
    } catch (err) {
      console.log("error");
      console.log(err);
      return thunkApi.rejectWithValue(err);
    }
  }
);

const paymentState = {
  msg: null as string | null,
  error: "",
  appLoading: false,
  checkOutLink: null as string | null,
  cancelStatus: false,
  isCheckOut: false,
  alert: {
    active: false,
    type: "",
    message: "",
    autoHideDuration: 6000,
  },
  plan_info: null as PaymentInfo | null,
  /** TODO:typescript for invoice payment history from stripe */
  payment_history: null as any[] | null,
  temporaryAuth: null as string | null,
  paymentStep: null as null | "trial" | "otherPlan" | "trialFinish",
};

const paymentSlice = createSlice({
  name: "payment",
  initialState: paymentState,
  reducers: {
    resetAlert(state) {
      state.alert = {
        active: false,
        type: "",
        message: "",
        autoHideDuration: 6000,
      };
    },
    /** set payment information, called from auth or get payment info */
    setPaymentInformation: (
      state,
      action: {
        type: string;
        payload: {
          payment_info: PaymentInfo;
        };
      }
    ) => {
      state.plan_info = action.payload.payment_info;
    },
    resetCheckOutPage: (state) => {
      state.isCheckOut = false;
      state.checkOutLink = null;
    },
    temporaryPaymentAuth: (state, { payload }) => {
      state.temporaryAuth = payload;
    },
    paymentStatus: (state, { payload }) => {
      state.paymentStep = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(postSubscribe.pending, (state, action) => {
      state.appLoading = true;
    });
    builder.addCase(postSubscribe.fulfilled, (state, action) => {
      state.appLoading = false;
      // If payload is not a link from stripe, set isCheckout to false
      state.isCheckOut = action.payload == PLAN_CHANGE_CODE ? false : true;
      state.temporaryAuth = null;
      state.checkOutLink = action.payload;
    });
    builder.addCase(postSubscribe.rejected, (state, action) => {
      state.appLoading = false;
      //TODO: Add error handling
      state.error = action.error.message as string;
    });
    /** paymentPOSTActiveSubscriptionCancel */
    builder.addCase(
      paymentPOSTActiveSubscriptionCancel.pending,
      (state, action) => {
        state.appLoading = true;
      }
    );
    builder.addCase(
      paymentPOSTActiveSubscriptionCancel.fulfilled,
      (state, action) => {
        state.appLoading = false;
        state.cancelStatus = action.payload;
      }
    );
    builder.addCase(
      paymentPOSTActiveSubscriptionCancel.rejected,
      (state, action) => {
        state.appLoading = false;
        //TODO: Add error handling
        state.error = action.error.message as string;
      }
    );
    /**paymentPOSTUpdateInvoiceEmail */
    builder.addCase(paymentPOSTUpdateInvoiceEmail.pending, (state, action) => {
      state.appLoading = true;
    });
    builder.addCase(
      paymentPOSTUpdateInvoiceEmail.fulfilled,
      (state, action) => {
        const invoice_email = action.payload.email;
        state.appLoading = false;
        state.plan_info!.invoice_email = invoice_email;
      }
    );
    builder.addCase(paymentPOSTUpdateInvoiceEmail.rejected, (state, action) => {
      state.appLoading = false;
      //TODO: Add error handling
      state.error = action.error.message as string;
    });
    /** paymentGETInvoiceListByOrgId CASES */
    builder.addCase(paymentGETInvoiceListByOrgId.pending, (state, action) => {
      state.appLoading = true;
    });
    builder.addCase(paymentGETInvoiceListByOrgId.fulfilled, (state, action) => {
      state.payment_history = action.payload.payment_history;
      state.appLoading = false;
    });
    builder.addCase(paymentGETInvoiceListByOrgId.rejected, (state, action) => {
      state.appLoading = false;
      //TODO: Add error handling
      state.error = action.error.message as string;
    });
  },
});

export const {
  resetAlert,
  setPaymentInformation,
  resetCheckOutPage,
  temporaryPaymentAuth,
  paymentStatus,
} = paymentSlice.actions;

const paymentReducer = paymentSlice.reducer;
export default paymentReducer;
