import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  assignUserSegmentToWalkthrough,
  listAllUserSegmentation,
} from "../store/reducers/reducerUserSegmentation";
import { RootState } from "../store";
import { UserSegmentation } from "../interfaces/interfaceSegmentation";
import { SearchDropdown } from "usertip-component-library";

const ComponentSelectUserSegmentForWalkthrough = ({
  walkthroughId,
  userSegmentation,
}: {
  walkthroughId: string;
  userSegmentation: UserSegmentation | null;
}) => {
  const [dataArray, setDataArray] = useState<{ id: string; value: string }[]>(
    []
  );
  const dispatch = useDispatch();

  /** Org Id */
  const orgId = useSelector((state: RootState) => state.org.org_id);

  console.log("check selected segment", userSegmentation);

  const segmentationList = useSelector(
    (state: RootState) => state.userSegmentation.userSegmentationList
  );

  /** Selected User Segmentation state */
  const [selectedSegment, setSelectedSegment] = useState<{
    id: string;
    value: string;
  } | null>(null);

  /** Retrive list of user segmentation */
  useEffect(() => {
    if (orgId) {
      dispatch(listAllUserSegmentation(orgId));
    }
  }, [orgId]);

  useEffect(() => {
    if (userSegmentation) {
      setSelectedSegment({
        id: userSegmentation.segmentation_id,
        value: userSegmentation.segmentation_name,
      });
    } else {
      setSelectedSegment({ id: "1", value: "None" });
    }
  }, [userSegmentation]);

  useEffect(() => {
    if (segmentationList) {
      const data = segmentationList.map((item) => {
        return {
          value: item.segmentation_name,
          id: item.segmentation_id,
        };
      });

      setDataArray([{ value: "None", id: "1" }, ...data]);
    } else {
      setDataArray([{ value: "None", id: "1" }]);
    }
  }, [segmentationList]);

  const changeHandler = (id: string, value: string) => {
    setSelectedSegment({ id: id, value: value });
    if (id === "1" && orgId && value === "None") {
      /** Send null userSegmentationId if user selects "None" option for user segmentation */
      console.log("dispatching null segment");
      dispatch(
        assignUserSegmentToWalkthrough({
          walkthroughId: walkthroughId,
          userSegmentationId: null,
        })
      );
    } else {
      /** Assign the user segment (that is created) to a walkthrough */
      dispatch(
        assignUserSegmentToWalkthrough({
          walkthroughId: walkthroughId,
          userSegmentationId: id,
        })
      );
    }
  };

  return (
    <div className="pageSettings">
      <div className="pageSettingsHeader">
        Manage This Walkthrough's User Segmentation
      </div>

      <div className="pageSettingsSubHeader">{`You can further filter out who is able to view this walkthrough by selecting a user segment that you created.`}</div>
      <SearchDropdown
        dataArray={dataArray}
        selectedValue={selectedSegment}
        customWidth="ut-w-full"
        onClick={changeHandler}
      />
    </div>
  );
};

export default ComponentSelectUserSegmentForWalkthrough;
