import { SelectChangeEvent } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// import { callPostSubscribe } from "../../axios/v2/callsPayment";
import { RootState } from "../../store";
import {
  PLAN_CHANGE_CODE,
  postSubscribe,
  resetCheckOutPage,
} from "../../store/reducers/reducerPayment";
import ChangePaymentGroupButton from "../03-organisms/02-groupButton/ChangePaymentGroupButton";
import ComponentModal from "../ComponentModal";
import { EmptyModal, Button } from "usertip-component-library";

const ComponentChangeSubscriptionPlan = ({
  showModal,
  setShowModal,
  currentPlanType,
}: {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  currentPlanType: string;
}) => {
  const checkOutLink = useSelector(
    (state: RootState) => state.payment.checkOutLink
  );
  const isCheckOutReady = useSelector(
    (state: RootState) => state.payment.isCheckOut
  );
  const [paymentTierState, setPaymentTierState] = useState<string>();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  /**Change it to current used plan type */
  useEffect(() => {
    setPaymentTierState(currentPlanType);
  }, [currentPlanType]);

  useEffect(() => {
    if (isCheckOutReady && checkOutLink) {
      if (checkOutLink == PLAN_CHANGE_CODE) {
        dispatch(resetCheckOutPage());
        navigate("/");
      } else {
        dispatch(resetCheckOutPage());
        window.location.replace(checkOutLink);
      }
    }
  }, [isCheckOutReady, checkOutLink]);

  type PlanTierName = "PAID1" | "PAID2";

  const paymentTierChangeHandler = (e: SelectChangeEvent<string>) => {
    const value = e.target.value as PlanTierName;
    setPaymentTierState(value);
  };

  const convertPlanTierCodeToValue = (tier: string) => {
    switch (tier) {
      case "FREE1": {
        return "Free Plan";
      }
      case "FREE2": {
        return "Custom Free Plan";
      }
      case "FREE3": {
        return "Free Plan";
      }
      case "PAID1": {
        return "Business Lite Plan";
      }
      case "PAID2": {
        return "Business Premium Plan";
      }
      case "STARTUP": {
        return "Startup Plan";
      }
      case "CUSTOM": {
        return "Custom Business Plan";
      }
      case "ENTERPRISE": {
        return "Enterprise";
      }
    }
  };

  const onSelectSubscriptionPlan = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    value: string
  ) => {
    setPaymentTierState(value);
  };
  const onSubmitSubscriptionPlan = () => {
    dispatch(
      //@ts-ignore
      postSubscribe({ tier: paymentTierState!, sub_type: "BILLING_MONTHLY" })
    );
  };
  return (
    <EmptyModal
      isOpen={showModal}
      onClose={() => setShowModal(false)}
      size="large"
      customClass="py-5"
    >
      <div className="modalHeader">Select Payment Tier</div>

      <div className="modalForm">
        {/* <Select
          labelId="payment-tier-select"
          data-form="payment-tier"
          value={paymentTierState}
          onChange={paymentTierChangeHandler}
          variant={"standard"}
          sx={{
            width: "100%",
          }}
        >
          {currentPlanType === "FREE1" && (
            <MenuItem value={"FREE1"}>
              {convertPlanTierCodeToValue("FREE1")}
            </MenuItem>
          )}
          {/* <MenuItem value={"free2"}>{"Free 2"}</MenuItem>
          <MenuItem value={"free3"}>{"Free 3"}</MenuItem> */}
        {/* <MenuItem value={"PAID1"}>
            {convertPlanTierCodeToValue("PAID1")}
          </MenuItem>
          <MenuItem value={"PAID2"}>
            {convertPlanTierCodeToValue("PAID2")}
          </MenuItem>
        </Select>
         */}
        <ChangePaymentGroupButton
          onSubscriptionSelect={onSelectSubscriptionPlan}
        />

        <div className="flex justify-end gap-2 mt-4">
          <Button
            onClick={() => setShowModal(false)}
            variant="primary"
            size="medium"
            text="Cancel"
            color="primary"
          />

          <Button
            //disable button when no change
            disabled={paymentTierState === currentPlanType}
            onClick={onSubmitSubscriptionPlan}
            variant="primary"
            size="medium"
            text="Update"
            color="primary"
          />
        </div>
      </div>
    </EmptyModal>
  );
};

export default ComponentChangeSubscriptionPlan;
