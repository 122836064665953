import { Button } from "usertip-component-library";
import React, { useEffect, useState } from "react";
import { RuleGroup } from "../../interfaces/interfaceSegmentation";
import ComponentRuleForm from "./ComponentRuleForm";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const GroupRuleForm = ({
  listRule,
  setListRule,
  groupIndex,
  onRemoveGroupClick,
}: {
  listRule: RuleGroup[];
  setListRule: React.Dispatch<React.SetStateAction<RuleGroup[]>>;
  groupIndex: number;
  onRemoveGroupClick: (curr: number) => void;
}) => {
  const handleAddRule = () => {
    setListRule((prev) => [
      ...prev,
      {
        groupIndex: groupIndex,
        index: listRule.length,
        metaName: "",
        metaType: "string",
        metaRule: "contain",
        metaValue: "",
      },
    ]);
  };

  return (
    <>
      {/* default ruleForm */}
      <ComponentRuleForm
        groupIndex={groupIndex}
        index={0}
        listRule={listRule}
        setListRule={setListRule}
        handleAddRule={handleAddRule}
      />
      {/* rule form when user to choose add new rule (AND) */}
      {listRule &&
        listRule.length > 1 &&
        listRule
          .filter((d, i) => d.index !== 0)
          .map((d, i) => {
            // only map if groupIndex is the same with d,groupindex
            return (
              d.groupIndex === groupIndex && (
                <div className="my-5" key={`group-${groupIndex}-${d.index}`}>
                  <ComponentRuleForm
                    groupIndex={groupIndex}
                    listRule={listRule}
                    index={d.index!}
                    setListRule={setListRule}
                    handleAddRule={handleAddRule}
                  />
                </div>
              )
            );
          })}
      {/* dont add remove group button on the first form */}
      {groupIndex !== 1 && (
        <div className="mt-3">
          <Button
            color="secondary"
            size="medium"
            text="REMOVE RULE GROUP"
            variant="outlined"
            icon={<HighlightOffIcon fontSize="small" />}
            iconRight
            customClass="ut-header-link-button"
            onClick={() => onRemoveGroupClick(groupIndex)}
          />
        </div>
      )}
    </>
  );
};

export default GroupRuleForm;
