

// Gather only cognito_id of all users from list for processing
export const retrieveUsersCognitoIDFromList = (teamList) => {
    let cognitoIDList = [];
    for (let i = 0; i < teamList.length; i++) {
        cognitoIDList.push(teamList[i].cognito_id)
    }

    return cognitoIDList;
};


// Gather only selected team object from list for processing
export const retrieveTeamObject = (teamList, teamName) => {
    let teamObject = [];
    for (let i = 0; i < teamList.length; i++) {
        if (teamList[i].name === teamName) {
            teamObject.push(teamList[i]);
            break;
        }
    }

    return teamObject;
};


// Gather only selected team objects from list for processing
export const retrieveBulkTeamObjects = (teamNameList, dataSet) => {
    let teamObjects = [];
    for (let i = 0; i < teamNameList.length; i++) {
        for (let j = 0; j < dataSet.length; j++) {
            if (teamNameList[i] === dataSet[j]._id) {
                teamObjects.push(dataSet[j]);
                break;
            }
        }
    }

    return teamObjects;
};