import React, { useState } from "react";
import TableGuidePermissions from "../components/Table/TableGuidePermissions";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import ComponentLoading from "../components/ComponentLoading";
import ComponentFilterTable from "../components/ComponentFilterTable";
import { Walkthrough } from "../interfaces/interface Organization";

const PageGuidePermissions = () => {
  const { appLoading: isLoading, walkthroughs } = useSelector(
    (state: RootState) => state.walkthrough
  );

  const [filteredWalkthroughs, setFilteredWalkthroughs] = useState<
    Walkthrough[]
  >([]);

  const walktroughFilter = [
    {
      field: "name",
      type: "String",
      label: "Walktrough",
    },
    {
      field: "date_created",
      type: "Date",
      label: "Date Created",
    },
  ];

  return (
    <>
      <ComponentLoading show={isLoading} />
      <div className="pageBase">
        <div className="pageHeader">List of Walkthroughs Permissions</div>
        <div className="text-[#9CA3AF]">
          View the list of walkthrough permissions and quickly edit them.
        </div>
      </div>
      {walkthroughs && (
        <ComponentFilterTable
          filters={walktroughFilter}
          originalData={walkthroughs}
          setFilteredData={setFilteredWalkthroughs}
        />
      )}
      <TableGuidePermissions walkthroughs={filteredWalkthroughs} />
    </>
  );
};

export default PageGuidePermissions;
