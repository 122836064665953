import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  AddSnapManualPayload,
  PictureGuideData,
  Snap,
  SnapComponent,
  SnapItems,
} from "../../interfaces/interfaceGuide";
import {
  GETSingleSnapImageCall,
  callDeleteGuide,
  callDeleteSingleSnap,
  callDeleteSnapComponent,
  callGETFirstPageSnaps,
  callGETRestPageSnaps,
  callGETRetrieveAllGuide,
  callGETRetrieveRestOfGuide,
  callGETRetrieveSingleGuideDocument,
  callGETRetrieveSingleSnapGuide,
  callGetPublicSingleSnapImage,
  callGetPublicSnap,
  callPATCHAddSnapStepManual,
  callPOSTAddImageSnapStepManual,
  callPostPublishSnap,
  callPostUnpublishSnap,
  callPostUpdateSharingPermission,
  callPutEditName,
  callPutEditSnapStep,
  callVerifySnapShareLink,
} from "../../axios/v2/callsQuickBuilder";
import { Walkthrough } from "../../interfaces/interface Organization";
import { AxiosError } from "axios";
import axios from "axios";
import {
  GETSingleImageCall,
  POSTUploadImagePresignedURL,
  getImageListFromPresign,
} from "../../axios/v2/callsMedia";

interface ActiveGuideData {
  name: null | string;
  quick_build_id: null | string;
  walkthrough: null | Walkthrough;
  shareUrl?: null | string;
  snap: null | {
    /** Unique id for the snap document. */ snap_id: string;
    /** The owner of the snap document. */
    owner: string;
    /** Name of the snap document. */
    name: string;
    /** Organization ID that the object belong to. */
    org_id: string;
    /** Unique api_key that is created for organizations. */
    api_key: string;
    /** ID for the relevant quick builder document */
    quick_build_id?: string;
    sharing_id?: string;
    guide_id?: string;
    option?: string;
    is_deleted?: boolean;
  };
  video_guide: null | any; // TODO: add the types for video guide
}

export interface AllGuideData {
  click_metadata: any[];
  createdAt: string;
  guideType: { walkthrough: boolean; snap: boolean; video: boolean };
  id: string;
  is_deleted: boolean;
  name: string;
  org_id: string;
  quick_build_id: string;
  snap_id: string | undefined;
  walkthrough_id: string | undefined;
  video_guide_id: string | undefined;
}

// request for single guide, that can have one of walkthrough, snap or video guide
export const getSingleGuide = createAsyncThunk<any, string>(
  "guide/getSingleGuide",
  async (guideID, thunkApi) => {
    try {
      const res = (await callGETRetrieveSingleGuideDocument(guideID)) as any;
      const result = res.data.payload;
      return result;
    } catch (err: any) {
      if (err.response) {
        const errStatus = err.response.status; // Integer
        const errPayload = err.response.data;
        const errMsg = errPayload.msg;

        console.log(errStatus);
        console.log(errPayload);
        console.log(errMsg);
      }
      const result = {
        alert: {
          type: "error",
          message: err.toString(),
        },
      };
      return thunkApi.rejectWithValue(result);
    }
  }
);
/**function to retrieve image from media id */
async function retrieveImageForStep(mediaId: string) {
  /**Get the presigned url */
  const response = await GETSingleSnapImageCall(mediaId);
  if (response.data.payload.mediaObject) {
    const imageData = response.data.payload.mediaObject;
    /**Get the image from presigned url */
    const imgFile = await getImageListFromPresign(imageData.presignedUrl);
    return URL.createObjectURL(imgFile);
  }
}

/**Function to retrieve public snap image using media id */
async function retrievePublicSnapImage(mediaId: string) {
  /**Get the presigned url */
  const response = await callGetPublicSingleSnapImage(mediaId);
  if (response.data.payload.mediaObject) {
    const imageData = response.data.payload.mediaObject;
    /**Get the image from presigned url */
    const imgFile = await getImageListFromPresign(imageData.presignedUrl);
    return URL.createObjectURL(imgFile);
  }
}
// request for get single snap guide
export const getSingleSnapGuide = createAsyncThunk<any, string>(
  "guide/getSingleSnapGuide",
  async (snapID, thunkApi) => {
    console.log("---getSingleSnapGuide");
    try {
      const res = (await callGETRetrieveSingleSnapGuide(snapID)) as any;
      const result = res.data.payload;

      return result;
    } catch (err: any) {
      if (err.response) {
        const errStatus = err.response.status; // Integer
        const errPayload = err.response.data;
        const errMsg = errPayload.msg;

        console.log(errStatus);
        console.log(errPayload);
        console.log(errMsg);
      }
      const result = {
        alert: {
          type: "error",
          message: err.toString(),
        },
      };
      return thunkApi.rejectWithValue(result);
    }
  }
);

export const getSnapImage = createAsyncThunk<any, string>(
  "guide/getSnapImage",
  async (mediaId, thunkApi) => {
    try {
      console.log("---getSnapImage");
      const img = await retrieveImageForStep(mediaId);
      return { img, mediaId };
    } catch (err: any) {
      if (err.response) {
        const errStatus = err.response.status; // Integer
        const errPayload = err.response.data;
        const errMsg = errPayload.msg;

        console.log(errStatus);
        console.log(errPayload);
        console.log(errMsg);
      }
      const result = {
        alert: {
          type: "error",
          message: err.toString(),
        },
      };
      return thunkApi.rejectWithValue(result);
    }
  }
);

type sharePermissionData = {
  snapId: string;
  option: string;
};

export const postUpdateSnapSharingPermission = createAsyncThunk<any, any>(
  "guide/updateSharingPermission",
  async (snapData: sharePermissionData, thunkApi) => {
    try {
      const res = (await callPostUpdateSharingPermission(
        snapData.snapId,
        snapData.option
      )) as any;
      const result = res.data.payload;
      const shareLink = result.sharing_link.split("/");
      // const parts = url.split("/");
      const share_id = shareLink[shareLink.length - 2];
      const guide_id = shareLink[shareLink.length - 1];

      return {
        share_id,
        guide_id,
        option: snapData.option,
      };
    } catch (err: any) {
      if (err.response) {
        const errStatus = err.response.status; // Integer
        const errPayload = err.response.data;
        const errMsg = errPayload.msg;

        console.log(errStatus);
        console.log(errPayload);
        console.log(errMsg);
      }
      const result = {
        alert: {
          type: "error",
          message: err.toString(),
        },
      };
      return thunkApi.rejectWithValue(result);
    }
  }
);

export const editSnapStep = createAsyncThunk<any, any>(
  "guide/editSnapStep",
  async (newStep: Snap, thunkApi) => {
    try {
      const res = (await callPutEditSnapStep(newStep.snap_id, newStep)) as any;
      const result = res.data.payload.updated_snap_document;

      return result;
    } catch (err: any) {
      if (err.response) {
        const errStatus = err.response.status; // Integer
        const errPayload = err.response.data;
        const errMsg = errPayload.msg;

        console.log(errStatus);
        console.log(errPayload);
        console.log(errMsg);
      }
      const result = {
        alert: {
          type: "error",
          message: err.toString(),
        },
      };
      return thunkApi.rejectWithValue(result);
    }
  }
);

export const addSnapManual = createAsyncThunk<any, any>(
  "guide/addSnapManual",
  async (
    { id, payload }: { id: string; payload: AddSnapManualPayload },
    thunkApi
  ) => {
    try {
      const res = await callPATCHAddSnapStepManual(id, payload);
      const result = res.data.payload.snap_doc;

      return { result, body: payload };
    } catch (err: any) {
      if (err.response) {
        const errStatus = err.response.status; // Integer
        const errPayload = err.response.data;
        const errMsg = errPayload.msg;

        console.log(errStatus);
        console.log(errPayload);
        console.log(errMsg);
      }
      const result = {
        alert: {
          type: "error",
          message: err.toString(),
        },
      };
      return thunkApi.rejectWithValue(result);
    }
  }
);

export const addImageSnapManual = createAsyncThunk<any, any>(
  "guide/addImageSnapManual",
  async ({ id, payload }: { id: string; payload: any }, thunkApi) => {
    try {
      const {
        contentType,
        description,
        step,
        name,
        orgId,
        cognitoId,
        email,
        imageFile,
      } = payload;

      const imageData = {
        content_type: "image",
        description,
        step,
        name,
        orgId,
        cognitoId,
        email,
      };

      const res = await callPOSTAddImageSnapStepManual(id, imageData);

      const url = res.data.payload.s3PutObjectUrl;

      const uploadPresignedUrlres = await POSTUploadImagePresignedURL(
        imageFile,
        url,
        contentType
      );

      // console.log("CEK INI DULU", uploadPresignedUrlres);

      if (uploadPresignedUrlres.ok) {
        return "uploadSuccess";
      }
    } catch (err: any) {
      if (err.response) {
        const errStatus = err.response.status; // Integer
        const errPayload = err.response.data;
        const errMsg = errPayload.msg;

        console.log(errStatus);
        console.log(errPayload);
        console.log(errMsg);
      }
      const result = {
        alert: {
          type: "error",
          message: err.toString(),
        },
      };
      return thunkApi.rejectWithValue(result);
    }
  }
);

export const getGuides = createAsyncThunk(
  "guide/getAllGuide",
  async (orgId: string, thunkApi) => {
    try {
      const guideData = {
        orgId: orgId,
        page: 1,
        perPage: 25,
      };
      const res = await callGETRetrieveAllGuide(guideData);
      const result = res.data.payload;
      console.log("---getAllGuide result", result);
      return result;
    } catch (err: any) {
      if (err.response) {
        const errStatus = err.response.status; // Integer
        const errPayload = err.response.data;
        const errMsg = errPayload.msg;

        console.log(errStatus);
        console.log(errPayload);
        console.log(errMsg);
      }
      const result = {
        alert: {
          type: "error",
          message: err.toString(),
        },
      };
      return thunkApi.rejectWithValue(result);
    }
  }
);

export const getRestOfGuide = createAsyncThunk(
  "guide/getRestOfGuide",
  async (orgId: string, thunkApi) => {
    try {
      const res = await callGETRetrieveRestOfGuide(orgId);
      const result = res.data.payload;
      console.log("---getAllGuide result", result);
      return result;
    } catch (err: any) {
      if (err.response) {
        const errStatus = err.response.status; // Integer
        const errPayload = err.response.data;
        const errMsg = errPayload.msg;

        console.log(errStatus);
        console.log(errPayload);
        console.log(errMsg);
      }
      const result = {
        alert: {
          type: "error",
          message: err.toString(),
        },
      };
      return thunkApi.rejectWithValue(result);
    }
  }
);

export const deleteGuide = createAsyncThunk(
  "guide/deleteGuide",
  async (quickBuildId: string, thunkApi) => {
    try {
      const res = await callDeleteGuide(quickBuildId);
      const result = res.data.payload;
      console.log("---deleteGuide result", result);
      return quickBuildId;
    } catch (err: any) {
      if (err.response) {
        const errStatus = err.response.status; // Integer
        const errPayload = err.response.data;
        const errMsg = errPayload.msg;

        console.log(errStatus);
        console.log(errPayload);
        console.log(errMsg);
      }
      const result = {
        alert: {
          type: "error",
          message: err.toString(),
        },
      };
      return thunkApi.rejectWithValue(result);
    }
  }
);

type renameGuideData = {
  newGuideName: string;
  guideId: string;
};
export const renameGuide = createAsyncThunk(
  "guide/renameGuide",
  async (renameGuideData: renameGuideData, thunkApi) => {
    const { newGuideName, guideId } = renameGuideData;

    try {
      const res = await callPutEditName(guideId, newGuideName);
      const result = res.data.payload;
      console.log("---renameGuide result", result);
      return result;
    } catch (error) {
      const err = error as AxiosError;
      if (err.response) {
        const errStatus = err.response.status; // Integer
        const errPayload = err.response.data;
        const errMsg = errPayload.msg;

        console.log(errStatus);
        console.log(errPayload);
        console.log(errMsg);
      }
      const result = {
        alert: {
          type: "error",
          message: err.toString(),
        },
      };
      return thunkApi.rejectWithValue(result);
    }
  }
);

type verifySnapShareLink = {
  shareId: string;
  snapId: string;
  isNeedToken: boolean;
};

/**Public api reducer */
export const verifySnapShareLink = createAsyncThunk(
  "guide/verifySnapShareLink",
  async (verifySnapShareLink: verifySnapShareLink, thunkApi) => {
    const { shareId, snapId, isNeedToken } = verifySnapShareLink;

    try {
      const res = await callVerifySnapShareLink(shareId, snapId, isNeedToken);
      const result = res.data;
      return result;
    } catch (err: any) {
      if (err.response) {
        const errStatus = err.response.status; // Integer
        const errPayload = err.response.data;
        const errMsg = errPayload.msg;

        console.log(errStatus);
        console.log(errPayload);
        console.log(errMsg);
      }
      const result = {
        alert: {
          type: "error",
          message: err.toString(),
        },
      };
      return thunkApi.rejectWithValue(result);
    }
  }
);

export const deleteSnapComponent = createAsyncThunk(
  "guides/deleteSnapComponent",
  async (
    { snapId, snapComponentId }: { snapId: string; snapComponentId: string },
    thunkApi
  ) => {
    try {
      const res = await callDeleteSnapComponent(snapId, snapComponentId);
      const result = res.data.payload;
      console.log("---deleteSnapComponent result", result);
      return snapComponentId;
    } catch (err: any) {
      if (err.response) {
        const errStatus = err.response.status; // Integer
        const errPayload = err.response.data;
        const errMsg = errPayload.msg;

        console.log(errStatus);
        console.log(errPayload);
        console.log(errMsg);
      }
      const result = {
        alert: {
          type: "error",
          message: err.toString(),
        },
      };
      return thunkApi.rejectWithValue(result);
    }
  }
);

/**Get public snap   */
export const getPublicSingleSnap = createAsyncThunk(
  "guide/getPublicSingleSnapInfo",
  async (snapId: string, thunkApi) => {
    try {
      const res = await callGetPublicSnap(snapId);
      const result = res!.data.payload;
      const snapComponent = result.snapDoc.snap_component;

      const snapComponentWithImage = await Promise.all(
        snapComponent.map(async (step: SnapComponent) => {
          if (step.content_type === "image") {
            const img = await retrievePublicSnapImage(step.media_id!);
            return { ...step, media_url: img };
          } else {
            return step;
          }
        })
      );

      const snapDoc = {
        ...result,
        snapDoc: { ...result.snapDoc, snap_component: snapComponentWithImage },
      };
      return snapDoc;
    } catch (err: any) {
      if (err.response) {
        const errStatus = err.response.status; // Integer
        const errPayload = err.response.data;
        const errMsg = errPayload.msg;

        console.log(errStatus);
        console.log(errPayload);
        console.log(errMsg);
      }
      const result = {
        alert: {
          type: "error",
          message: err.toString(),
        },
      };
      return thunkApi.rejectWithValue(result);
    }
  }
);

export const getFirstPageSnaps = createAsyncThunk(
  "guides/getAllSnaps",
  async (__, thunkApi) => {
    try {
      const res = await callGETFirstPageSnaps();
      const result = res.data.payload;
      console.log("---getAllSnaps result", result);
      return { snapDocs: result.snap_docs, snapCount: result.snapCount };
    } catch (err: any) {
      if (err.response) {
        const errStatus = err.response.status; // Integer
        const errPayload = err.response.data;
        const errMsg = errPayload.msg;

        console.log(errStatus);
        console.log(errPayload);
        console.log(errMsg);
      }
      const result = {
        alert: {
          type: "error",
          message: err.toString(),
        },
      };
      return thunkApi.rejectWithValue(result);
    }
  }
);

export const getRestPageSnaps = createAsyncThunk(
  "guides/getRestSnaps",
  async (__, thunkApi) => {
    try {
      const res = await callGETRestPageSnaps();
      const result = res.data.payload;
      console.log("---getAllSnaps result", result);
      return result.snap_docs;
    } catch (err: any) {
      if (err.response) {
        const errStatus = err.response.status; // Integer
        const errPayload = err.response.data;
        const errMsg = errPayload.msg;

        console.log(errStatus);
        console.log(errPayload);
        console.log(errMsg);
      }
      const result = {
        alert: {
          type: "error",
          message: err.toString(),
        },
      };
      return thunkApi.rejectWithValue(result);
    }
  }
);
export const deleteSingleSnap = createAsyncThunk(
  "guides/deleteSingleSnap",
  async (snapId: string, thunkApi) => {
    try {
      const res = await callDeleteSingleSnap(snapId);
      const result = res.data;
      console.log("---deleteSnap result", result);
      return snapId;
    } catch (err: any) {
      if (err.response) {
        const errStatus = err.response.status; // Integer
        const errPayload = err.response.data;
        const errMsg = errPayload.msg;

        console.log(errStatus);
        console.log(errPayload);
        console.log(errMsg);
      }
      const result = {
        alert: {
          type: "error",
          message: err.toString(),
        },
      };
      return thunkApi.rejectWithValue(result);
    }
  }
);

export const publishSnap = createAsyncThunk(
  "guides/publishSnap",
  async (snapId: string, thunkApi) => {
    try {
      const res = await callPostPublishSnap(snapId);
      const result = res.data;

      return result.payload.snap;
    } catch (err: any) {
      if (err.response) {
        const errStatus = err.response.status; // Integer
        const errPayload = err.response.data;
        const errMsg = errPayload.msg;

        console.log(errStatus);
        console.log(errPayload);
        console.log(errMsg);
      }
      const result = {
        alert: {
          type: "error",
          message: err.toString(),
        },
      };
      return thunkApi.rejectWithValue(result);
    }
  }
);

export const unpublishSnap = createAsyncThunk(
  "guides/unpublishSnap",
  async (snapId: string, thunkApi) => {
    try {
      const res = await callPostUnpublishSnap(snapId);
      const result = res.data;

      return result.payload.snap;
    } catch (err: any) {
      if (err.response) {
        const errStatus = err.response.status; // Integer
        const errPayload = err.response.data;
        const errMsg = errPayload.msg;

        console.log(errStatus);
        console.log(errPayload);
        console.log(errMsg);
      }
      const result = {
        alert: {
          type: "error",
          message: err.toString(),
        },
      };
      return thunkApi.rejectWithValue(result);
    }
  }
);

const initialState = {
  appLoading: false,
  activeGuideData: {
    name: null,
    quick_build_id: null,
    walkthrough: null,
    snap: null,
    video_guide: null, // TODO: add the types for video guide
  } as ActiveGuideData,
  snapGuide: null as null | Snap,
  isPublicSnap: false,
  snapLoading: false, // This is indicator to load snap image
  activeLink: null as null | string,
  alert: {
    active: false,
    type: "",
    message: "",
    autoHideDuration: 6000,
  } as null | {
    active: boolean;
    type: string;
    message: string;
    autoHideDuration: number;
  },
  allGuide: null as null | AllGuideData[],
  changeId: false, // This is indicator to change picture guide id
  allSnaps: [] as SnapItems[],
  totalGuides: 0,
  totalSnaps: 0,
};

const guideSlice = createSlice({
  name: "guide",
  initialState: initialState,
  reducers: {
    setGuidesLoading: (state, action) => {
      state.appLoading = action.payload;
    },
    resetAlert: (state) => {
      state.alert = null;
    },
    // addSnapPicture: (state, action) => {
    //   state.snapGuide = [...state.snapGuide, ...action.payload];
    // },
    addNewSnapStep: (state, action) => {
      state.snapGuide = action.payload;
    },
    snapFinishLoading: (state) => {
      state.snapLoading = false;
      state.appLoading = false;
    },
    resetSnapGuide: (state) => {
      state.snapGuide = null;
    },
    resetChangeId: (state) => {
      state.changeId = false;
    },
  },
  extraReducers: (builder) => {
    builder
      // reducer for get single guide, that can have one of walkthrough, snap or video guide
      .addCase(getSingleGuide.pending, (state) => {
        state.appLoading = true;
      })
      .addCase(getSingleGuide.fulfilled, (state, action) => {
        const {
          quick_build_id,
          name,
          walkthrough,
          snap,
          video_guide,
        }: ActiveGuideData = action.payload;
        state.activeGuideData = {
          quick_build_id,
          name,
          walkthrough,
          snap,
          video_guide,
        };
        const newDate = new Date().toString();
        state.appLoading = false;
      })
      .addCase(getSingleGuide.rejected, (state, action) => {
        const payload = action.payload as any;
        const { type, message } = payload;
        state.appLoading = false;
        state.alert = {
          active: true,
          type: type,
          message: message,
          autoHideDuration: payload.alert.autoHideDuration
            ? payload.alert.autoHideDuration
            : 6000,
        };
        state.alert.active = true;
        state.alert.type = type;
        state.alert.message = message;
        state.alert.autoHideDuration = payload.alert.autoHideDuration
          ? payload.alert.autoHideDuration
          : state.alert.autoHideDuration;
      })

      // reducer for getSingleSnapGuide
      .addCase(getSingleSnapGuide.pending, (state) => {
        state.appLoading = true;
      })
      .addCase(getSingleSnapGuide.fulfilled, (state, action) => {
        const { snapDoc }: { snapDoc: Snap } = action.payload;
        state.snapGuide = snapDoc;
        state.snapLoading = true;
        state.appLoading = false;
      })
      .addCase(getSingleSnapGuide.rejected, (state, action) => {
        const payload = action.payload as any;
        const { type, message } = payload;
        state.appLoading = false;
        state.alert = {
          active: true,
          type: type,
          message: message,
          autoHideDuration: payload.alert.autoHideDuration
            ? payload.alert.autoHideDuration
            : 6000,
        };
        state.alert.active = true;
        state.alert.type = type;
        state.alert.message = message;
        state.alert.autoHideDuration = payload.alert.autoHideDuration
          ? payload.alert.autoHideDuration
          : state.alert.autoHideDuration;
      })

      // reducer for getSnapImage
      .addCase(getSnapImage.pending, (state) => {
        state.appLoading = true;
      })
      .addCase(getSnapImage.fulfilled, (state, action) => {
        const { img, mediaId } = action.payload;
        console.log("---getSnapImage result", action.payload);

        const updatedSnapComponent = state.snapGuide!.snap_component.map(
          (step: SnapComponent) => {
            if (step.media_id === mediaId) {
              return { ...step, media_url: img };
            } else {
              return step;
            }
          }
        );

        const updatedSnap = {
          ...state.snapGuide!,
          snap_component: updatedSnapComponent,
        };

        state.snapGuide = updatedSnap;
      })
      .addCase(getSnapImage.rejected, (state, action) => {
        const payload = action.payload as any;
        const { type, message } = payload;
        state.alert = {
          active: true,
          type: type,
          message: message,
          autoHideDuration: payload.alert.autoHideDuration
            ? payload.alert.autoHideDuration
            : 6000,
        };
        state.alert.active = true;
        state.alert.type = type;
        state.alert.message = message;
        state.alert.autoHideDuration = payload.alert.autoHideDuration
          ? payload.alert.autoHideDuration
          : state.alert.autoHideDuration;
      })

      .addCase(postUpdateSnapSharingPermission.pending, (state) => {
        state.appLoading = true;
      })
      .addCase(postUpdateSnapSharingPermission.fulfilled, (state, action) => {
        if (state.activeGuideData.snap !== null) {
          state.activeGuideData.snap.guide_id = action.payload.guide_id;
          state.activeGuideData.snap.sharing_id = action.payload.share_id;
          state.activeGuideData.snap.option = action.payload.option;
        }
        state.appLoading = false;
      })
      .addCase(postUpdateSnapSharingPermission.rejected, (state, action) => {
        const payload = action.payload as any;
        const { type, message } = payload;
        state.appLoading = false;
        state.alert = {
          active: true,
          type: type,
          message: message,
          autoHideDuration: payload.alert.autoHideDuration
            ? payload.alert.autoHideDuration
            : 6000,
        };
        state.alert.active = true;
        state.alert.type = type;
        state.alert.message = message;
        state.alert.autoHideDuration = payload.alert.autoHideDuration
          ? payload.alert.autoHideDuration
          : state.alert.autoHideDuration;
      })
      .addCase(editSnapStep.pending, (state) => {
        state.appLoading = true;
      })
      .addCase(editSnapStep.fulfilled, (state, action) => {
        const { snapDoc }: { snapDoc: Snap } = action.payload;
        state.snapGuide = action.payload;
        // state.snapLoading = true;
        state.appLoading = false;
        state.changeId = true;
      })
      .addCase(editSnapStep.rejected, (state, action) => {
        const payload = action.payload as any;
        const { type, message } = payload;
        state.appLoading = false;
        state.alert = {
          active: true,
          type: type,
          message: message,
          autoHideDuration: payload.alert.autoHideDuration
            ? payload.alert.autoHideDuration
            : 6000,
        };
        state.alert.active = true;
        state.alert.type = type;
        state.alert.message = message;
        state.alert.autoHideDuration = payload.alert.autoHideDuration
          ? payload.alert.autoHideDuration
          : state.alert.autoHideDuration;
      })
      .addCase(getGuides.fulfilled, (state, action) => {
        const allGuide = action.payload.quick_build_guides;
        const allGuideStatus = action.payload.quickBuildStatus;

        for (const iterator of allGuide) {
          delete iterator._id;
          delete iterator.__v;
        }

        const newAllGuide = allGuide.map((val: any) => {
          const status = allGuideStatus.find(
            (status: any) => status.quick_builder_id === val.quick_build_id
          );

          const guideType = {
            walkthrough: false,
            snap: false,
            video: false,
          };

          if (val.walkthrough_id && !status.walkthrough.is_deleted) {
            guideType.walkthrough = true;
          }

          if (val.snap_id && !status.snap.is_deleted) {
            guideType.snap = true;
          }

          if (val.video_guide_id) {
            guideType.video = true;
          }

          return { ...val, guideType };
        });

        state.allGuide = newAllGuide;
        state.totalGuides = action.payload.quickBuildCount;
        state.appLoading = false;
      })
      .addCase(getGuides.pending, (state) => {
        state.appLoading = true;
      })
      .addCase(getGuides.rejected, (state, action) => {
        const payload = action.payload as any;
        const { type, message } = payload.alert;
        const alert = {
          active: true,
          type: type,
          message: message,
          autoHideDuration: payload.alert.autoHideDuration
            ? payload.alert.autoHideDuration
            : 6000,
        };

        state.appLoading = false;
        state.alert = alert;
      })
      .addCase(getRestOfGuide.fulfilled, (state, action) => {
        const allGuide = action.payload.quick_build_guides;
        const allGuideStatus = action.payload.quickBuildStatus;

        for (const iterator of allGuide) {
          delete iterator._id;
          delete iterator.__v;
        }

        const newAllGuide = allGuide.map((val: any) => {
          const status = allGuideStatus.find(
            (status: any) => status.quick_builder_id === val.quick_build_id
          );

          const guideType = {
            walkthrough: false,
            snap: false,
            video: false,
          };

          if (val.walkthrough_id && !status.walkthrough.is_deleted) {
            guideType.walkthrough = true;
          }

          if (val.snap_id && !status.snap.is_deleted) {
            guideType.snap = true;
          }

          if (val.video_guide_id) {
            guideType.video = true;
          }

          return { ...val, guideType };
        });

        /**filter out guide that are already in all guide state */
        const newAllGuideFiltered = newAllGuide.filter((val: any) => {
          return (
            state.allGuide?.findIndex((guide) => {
              return guide.quick_build_id === val.quick_build_id;
            }) === -1
          );
        });

        const updatedAllGuide = [
          ...(state.allGuide ? state.allGuide : []),
          ...newAllGuideFiltered,
        ];

        state.allGuide = updatedAllGuide;
      })

      .addCase(deleteGuide.fulfilled, (state, action) => {
        if (state.allGuide !== null) {
          state.allGuide = state.allGuide?.filter((val) => {
            return val.quick_build_id !== action.payload;
          });
        }
        state.appLoading = false;
      })
      .addCase(deleteGuide.pending, (state) => {
        state.appLoading = true;
      })
      .addCase(deleteGuide.rejected, (state, action) => {
        const payload = action.payload as any;

        const { type, message } = payload.alert;
        const alert = {
          active: true,
          type: type,
          message: message,
          autoHideDuration: payload.alert.autoHideDuration
            ? payload.alert.autoHideDuration
            : 6000,
        };

        state.appLoading = false;
        state.alert = alert;
      })
      .addCase(renameGuide.pending, (state) => {
        state.appLoading = true;
      })
      .addCase(renameGuide.fulfilled, (state, action) => {
        const newAllGuide = state.allGuide?.map((val) => {
          if (val.quick_build_id === action.payload.quick_build_id) {
            val.name = action.payload.name;
          }
          return val;
        });

        state.allGuide = newAllGuide!;

        state.activeGuideData.name = action.payload.name;
        state.appLoading = false;
      })
      .addCase(renameGuide.rejected, (state, action) => {
        const payload = action.payload as any;

        const { type, message } = payload.alert;
        const alert = {
          active: true,
          type: type,
          message: message,
          autoHideDuration: payload.alert.autoHideDuration
            ? payload.alert.autoHideDuration
            : 6000,
        };

        state.appLoading = false;
        state.alert = alert;
      })

      // reducer for add snap item
      .addCase(addSnapManual.pending, (state, action) => {
        state.appLoading = true;
      })
      .addCase(addSnapManual.fulfilled, (state, action) => {
        console.log("---add-snap-manual-fulfilled", action.payload);

        const { body, result } = action.payload;

        /** Create new snap item component */
        const newSnapItem = {
          _id: result.snap_component[body.step],
          snap_id: result.snap_id,
          snap_component_id: result.snap_component[body.step],
          content: body.content,
          description: body.description,
          content_type: body.content_type,
          createdAt: result.createdAt,
          updatedAt: result.updatedAt,
          "--v": result.__v,
          id: result.snap_component[body.step],
        } as unknown as SnapComponent;

        const newSnapComponent = state.snapGuide?.snap_component;

        /** Add new snap item component to snap component array */
        newSnapComponent?.splice(body.step, 0, newSnapItem);

        const newSnapGuide = {
          ...state.snapGuide,
          snap_component: newSnapComponent,
        };

        state.appLoading = false;
        state.snapGuide = newSnapGuide as Snap;
      })
      .addCase(addSnapManual.rejected, (state, action) => {
        const payload = action.payload as any;

        const { type, message } = payload.alert;
        const alert = {
          active: true,
          type: type,
          message: message,
          autoHideDuration: payload.alert.autoHideDuration
            ? payload.alert.autoHideDuration
            : 6000,
        };

        state.appLoading = false;
        state.alert = alert;
      })
      /**Reducer for public snap */
      .addCase(verifySnapShareLink.pending, (state) => {
        state.appLoading = true;
      })
      .addCase(verifySnapShareLink.fulfilled, (state, action) => {
        state.isPublicSnap = action.payload.success;
        state.appLoading = false;
      })
      .addCase(verifySnapShareLink.rejected, (state, action) => {
        const payload = action.payload as any;

        const { type, message } = payload.alert;
        const alert = {
          active: true,
          type: type,
          message: message,
          autoHideDuration: payload.alert.autoHideDuration
            ? payload.alert.autoHideDuration
            : 6000,
        };

        state.appLoading = false;
        state.alert = alert;
      })
      // reducer for delete snap component
      .addCase(deleteSnapComponent.pending, (state) => {
        state.appLoading = true;
      })
      .addCase(deleteSnapComponent.fulfilled, (state, action) => {
        const oldData = state.snapGuide?.snap_component;

        if (oldData) {
          const newData = oldData.filter((val) => {
            return val.snap_component_id !== action.payload;
          });

          state.snapGuide = {
            ...state.snapGuide!,
            snap_component: newData,
          };
        }

        state.appLoading = false;
      })
      .addCase(deleteSnapComponent.rejected, (state, action) => {
        const payload = action.payload as any;

        const { type, message } = payload.alert;
        const alert = {
          active: true,
          type: type,
          message: message,
          autoHideDuration: payload.alert.autoHideDuration
            ? payload.alert.autoHideDuration
            : 6000,
        };

        state.appLoading = false;
        state.alert = alert;
      })
      .addCase(getPublicSingleSnap.pending, (state) => {
        state.appLoading = true;
      })
      .addCase(getPublicSingleSnap.fulfilled, (state, action) => {
        const { snapDoc }: { snapDoc: Snap } = action.payload;
        state.snapGuide = snapDoc;

        state.appLoading = false;
      })
      .addCase(getPublicSingleSnap.rejected, (state, action) => {
        const payload = action.payload as any;
        const { type, message } = payload;
        const alert = {
          active: true,
          type: type,
          message: message,
          autoHideDuration: payload.alert.autoHideDuration
            ? payload.alert.autoHideDuration
            : 6000,
        };

        state.appLoading = false;
        state.alert = alert;
      })
      // reducer for add snap image manual
      .addCase(addImageSnapManual.pending, (state, action) => {
        state.appLoading = true;
      })
      .addCase(addImageSnapManual.fulfilled, (state, action) => {
        console.log("---add-snap-manual-fulfilled", action.payload);
        state.appLoading = false;
      })
      .addCase(addImageSnapManual.rejected, (state, action) => {
        const payload = action.payload as any;

        const { type, message } = payload.alert;

        const alert = {
          active: true,
          type: type,
          message: message,
          autoHideDuration: payload.alert.autoHideDuration
            ? payload.alert.autoHideDuration
            : 6000,
        };

        state.appLoading = false;
        state.alert = alert;
        state.appLoading = false;
      })
      // Reducer for get all snaps
      .addCase(getFirstPageSnaps.pending, (state, action) => {
        state.appLoading = true;
      })
      .addCase(getFirstPageSnaps.fulfilled, (state, action) => {
        console.log("--get-all-snaps-fullfiled", action.payload);
        const { snapDocs, snapCount } = action.payload;
        const newSnaps = action.payload.snapDocs.map((val: SnapItems) => {
          return { ...val, id: val.snap_id };
        });
        state.allSnaps = newSnaps.reverse();
        state.totalSnaps = action.payload.snapCount;
        state.appLoading = false;
      })
      .addCase(getFirstPageSnaps.rejected, (state, action) => {
        const payload = action.payload as any;

        const { type, message } = payload.alert;
        const alert = {
          active: true,
          type: type,
          message: message,
          autoHideDuration: payload.alert.autoHideDuration
            ? payload.alert.autoHideDuration
            : 6000,
        };

        state.appLoading = false;
        state.alert = alert;
      })
      .addCase(getRestPageSnaps.fulfilled, (state, action) => {
        const newSnaps = action.payload.map((val: SnapItems) => {
          return { ...val, id: val.snap_id };
        });
        state.allSnaps = newSnaps.reverse();
      })
      // reducer for delete single snap
      .addCase(deleteSingleSnap.pending, (state) => {
        state.appLoading = true;
      })
      .addCase(deleteSingleSnap.fulfilled, (state, action) => {
        state.allSnaps = state.allSnaps?.filter((val) => {
          return val.snap_id !== action.payload;
        });

        state.appLoading = false;
      })
      .addCase(deleteSingleSnap.rejected, (state, action) => {
        const payload = action.payload as any;

        const { type, message } = payload.alert;
        const alert = {
          active: true,
          type: type,
          message: message,
          autoHideDuration: payload.alert.autoHideDuration
            ? payload.alert.autoHideDuration
            : 6000,
        };

        state.appLoading = false;
        state.alert = alert;
      })
      // reducer for publish snap
      .addCase(publishSnap.pending, (state) => {
        state.appLoading = true;
      })
      .addCase(publishSnap.fulfilled, (state, action) => {
        console.log("publish-snap-fulfilled", action.payload);

        const newAllSnaps = state.allSnaps.map((val) => {
          if (val.snap_id === action.payload.snap_id) {
            return {
              ...val,
              publish: action.payload.publish,
            };
          }
          return val;
        });

        state.allSnaps = newAllSnaps;
        state.snapGuide!.publish = action.payload.publish;
        state.appLoading = false;
      })
      .addCase(publishSnap.rejected, (state, action) => {
        const payload = action.payload as any;

        const { type, message } = payload.alert;
        const alert = {
          active: true,
          type: type,
          message: message,
          autoHideDuration: payload.alert.autoHideDuration
            ? payload.alert.autoHideDuration
            : 6000,
        };

        state.appLoading = false;
        state.alert = alert;
      })
      // reducer for unpublish snap
      .addCase(unpublishSnap.pending, (state) => {
        state.appLoading = true;
      })
      .addCase(unpublishSnap.fulfilled, (state, action) => {
        console.log("unpublish-snap-fulfilled", action.payload);

        const newAllSnaps = state.allSnaps.map((val) => {
          if (val.snap_id === action.payload.snap_id) {
            return {
              ...val,
              publish: action.payload.publish,
            };
          }
          return val;
        });

        state.allSnaps = newAllSnaps;
        state.snapGuide!.publish = action.payload.publish;
        state.appLoading = false;
      })
      .addCase(unpublishSnap.rejected, (state, action) => {
        const payload = action.payload as any;

        const { type, message } = payload.alert;
        const alert = {
          active: true,
          type: type,
          message: message,
          autoHideDuration: payload.alert.autoHideDuration
            ? payload.alert.autoHideDuration
            : 6000,
        };

        state.appLoading = false;
        state.alert = alert;
      });
  },
});

const { reducer, actions } = guideSlice;

const guideReducer = reducer;
export const {
  resetAlert,
  addNewSnapStep,
  snapFinishLoading,
  resetSnapGuide,
  setGuidesLoading,
  resetChangeId,
} = actions;
export default guideReducer;
