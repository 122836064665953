import React from "react";

import { Button } from "@mui/material";

import ComponentModal from "./ComponentModal";
import { useDispatch } from "react-redux";
import {
  organizationDeleteSharedWalkthrough,
  organizationGetGroupedOrgAndWalkthroughs,
} from "../store/reducers/reducerOrganization";
import { EmptyModal } from "usertip-component-library";

const ComponentRemoveSharedWalkthrough = ({
  selectedWalkthroughList,

  showModalRemove,
  setShowModalRemove,

  removeMode,

  setResetTableSelectAll,
  hostOrgID,
  dataSet,
}) => {
  const dispatch = useDispatch();
  // state from redux
  // Handle Removal of selected walkthroughs after submission click
  const handleConfirmRemoveWalkthroughsFormSubmission = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    // Handles Removal submission where appropriate
    if (removeMode === "single") {
      const orgData = {
        host_org_id: hostOrgID,
        guest_org_name: dataSet.guest_org_name,
        guest_org_id: dataSet.guest_org_id,
        walkthroughList: selectedWalkthroughList,
      };

      await dispatch(organizationDeleteSharedWalkthrough(orgData));
      await dispatch(organizationGetGroupedOrgAndWalkthroughs(hostOrgID));

      handleHideModal();
    } else if (removeMode === "bulk") {
      if (selectedWalkthroughList) {
        console.log(selectedWalkthroughList);
        const orgData = {
          host_org_id: hostOrgID,
          guest_org_name: dataSet.guest_org_name,
          guest_org_id: dataSet.guest_org_id,
          walkthroughList: selectedWalkthroughList,
        };

        await dispatch(organizationDeleteSharedWalkthrough(orgData));
        await dispatch(organizationGetGroupedOrgAndWalkthroughs(hostOrgID));
        console.log("deleted");

        // Resets the "Select All" control on the table.
        setResetTableSelectAll(true);
        handleHideModal();
      }
    }
  };

  // Modal close handler
  const handleHideModal = () => {
    setShowModalRemove(false);
  };

  return (
    <EmptyModal
      isOpen={showModalRemove}
      onClose={handleHideModal}
      size="large"
      customClass="py-5"
    >
      <div className="modalHeader">
        {removeMode === "single"
          ? `Confirm Removal of Walkthrough?`
          : removeMode === "bulk"
          ? `Confirm Removal of Multiple Walkthrough(s)?`
          : ""}
      </div>

      {removeMode === "single" && (
        <>
          <div className="modalSubHeader">
            WARNING: You have selected <i>“Delete”</i> to remove{" "}
            <b>{`${selectedWalkthroughList[0].walkthroughName}`}</b>, please
            confirm to proceed or select cancel to return to the previous page.
          </div>
        </>
      )}
      {removeMode === "bulk" && (
        <>
          <div className="modalSubHeader">
            WARNING: You have selected to <i>“Bulk Delete”</i> the following
            walkthrough(s), please confirm to proceed or select cancel to return
            to the previous page.
          </div>

          <ol className="list-decimal list-inside mb-8">
            {selectedWalkthroughList &&
              selectedWalkthroughList.map((walkthrough) => (
                <li
                  key={walkthrough.walkthrough_id}
                >{`${walkthrough.walkthroughName}`}</li>
              ))}
          </ol>
        </>
      )}

      <form
        noValidate
        autoComplete="off"
        onSubmit={handleConfirmRemoveWalkthroughsFormSubmission}
        className="modalForm"
      >
        <div className="modalActionBtnBar">
          <Button
            variant="contained"
            className="modalActionBtn"
            onClick={handleHideModal}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            className="modalActionBtn"
            type="submit"
          >
            Yes
          </Button>
        </div>
      </form>
    </EmptyModal>
  );
};

export default ComponentRemoveSharedWalkthrough;
