import React from "react";
import { SpotlightPositionPanel } from "usertip-component-library";

interface Props {
  /** Prop for set value of the input (selected value) */
  value: any;
  /** Prop for handling the input  */
  settingHandler: (value: any) => void;
}

const SpotlightTooltipPositionSetting = ({ value, settingHandler }: Props) => {
  return (
    <>
      <h1 className="text-sm self-start">Spotlight Tooltip Position:</h1>
      <div className="flex-1 ml-auto">
        <SpotlightPositionPanel value={value} onChange={settingHandler} />
      </div>
    </>
  );
};

export default SpotlightTooltipPositionSetting;
