import { Box } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { AnalyticDataForChart } from "../../../../interfaces/interfaceAnalytic";
import { pieStepChart } from "../../../_chartGenerator/pieStepChart";
import * as d3 from "d3";
import { pieWalkthroughChart } from "../../../_chartGenerator/pieWalkthroughChart";
import ComponentDashboardOverview from "./ComponentDashboardOverview";

const ComponentDashboard = ({
  walkthroughDataSet,
}: {
  walkthroughDataSet: AnalyticDataForChart;
}) => {
  const divRef = useRef<HTMLDivElement>(null);
  const elRef = useRef<HTMLDivElement>(null);

  const convertTime = (time: number) => {
    let seconds = Math.floor(time / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);
    let days = Math.floor(hours / 24);
    seconds = seconds % 60;
    minutes = minutes % 60;
    hours = hours % 24;

    let timeString = "";
    if (days > 0) {
      timeString += `${days}d`;
    }
    if (hours > 0) {
      timeString += `${hours}h`;
    }
    if (minutes > 0) {
      timeString += `${minutes}m`;
    }
    if (seconds > 0) {
      timeString += `${seconds}s`;
    }

    return timeString;
  };

  /* adding the pie chart */
  useEffect(() => {
    const chart = pieWalkthroughChart(walkthroughDataSet);
    const selectChart = d3.select(".dashboard_chart_walkthrough");
    divRef.current?.append(chart);

    //remove and create new chart everytime reload
    return () => {
      selectChart &&
        Object.keys(selectChart).length !== 0 &&
        selectChart.selectAll("*").remove();
    };
  });

  useEffect(() => {
    // When the chart ger rendered scroll into elRef
    if (divRef) {
      elRef.current?.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  });
  return (
    <>
      <h3 className="pageSettingsHeader font-bold mt-5">
        Overview for - [{walkthroughDataSet.date}]
      </h3>

      <div className="flex items-center justify-around text-center" ref={elRef}>
        {/* chart here */}
        <div
          className="chart dashboard_chart_walkthrough w-1/2 text-right"
          ref={divRef}
        ></div>
        <ComponentDashboardOverview
          walkthroughDataSet={walkthroughDataSet}
          convertTime={convertTime}
        />
      </div>
    </>
  );
};

export default ComponentDashboard;
