import React from "react";

import { Toolbar } from "@mui/material";

import ComponentListTableBtnBulk from "./ComponentListTableBtnBulk";

const ComponentListTableToolbar = ({
  listType,
  displayUserRoleFull,
  dataSet,

  setSelectedUserList,
  setSelectedTeamList,
  setSelectedWalkthroughList,

  setSelectedGroupsList,
  setSelectedGroupWalkthroughsList,

  setSelectedWalkthroughViewingPermissionsList,
  setSelectedWalkthroughViewerTeamPermissionsList,

  setShowModalRemove,
  setRemoveMode,
  setShowModalMove,
  setMoveMode,

  setShowModalEdit,
  setEditMode,

  selectedRows,
  numOfRowsSelected,
}) => {
  // TODO: check state, why dataset is undefined at the start of the load
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numOfRowsSelected > 0 && {
          backgroundColor: "#F3F4F6",
        }),
      }}
    >
      {
        // If rows are clicked / checkboxes are selected, Display "numberOfRowsSelected". Else, Display default Table Header Msg
        numOfRowsSelected > 0 ? (
          <div className="flex-1">{numOfRowsSelected} selected</div>
        ) : (
          <div className="flex-1 text-base pl-1">
            <h6>
              {listType === "User"
                ? `Total ${dataSet.length} ${displayUserRoleFull}${
                    dataSet.length > 1 ? "s" : ""
                  }`
                : listType === "Team"
                ? `Total ${dataSet.length} Team${dataSet.length > 1 ? "s" : ""}`
                : listType === "Group"
                ? `Total ${dataSet.length} Group${
                    dataSet.length > 1 ? "s" : ""
                  }`
                : listType === "Walkthrough"
                ? `Total ${dataSet.length} Walkthrough${
                    dataSet.length > 1 ? "s" : ""
                  }`
                : listType === "GroupWalkthrough"
                ? `Total ${dataSet.length} Walkthrough${
                    dataSet.length > 1 ? "s" : ""
                  }`
                : listType === "WalkthroughViewingPermissions"
                ? `Total ${dataSet.length} User${dataSet.length > 1 ? "s" : ""}`
                : listType === "WalkthroughViewerTeamPermissions"
                ? `Total ${dataSet.length} Viewer Team${
                    dataSet.length > 1 ? "s" : ""
                  }`
                : ""}
            </h6>
          </div>
        )
      }

      {
        // Show "Bulk Controls" on the right of Toolbar Top. Appear only when rows are selected
        numOfRowsSelected > 0 ? (
          <>
            {listType === "User" &&
              displayUserRoleFull !== "Viewer Code Snippet" && (
                <>
                  <ComponentListTableBtnBulk
                    btnType={"Move"}
                    listType={listType}
                    dataSet={dataSet}
                    selectedRows={selectedRows}
                    setSelectedUserList={setSelectedUserList}
                    setShowModalMove={setShowModalMove}
                    setMoveMode={setMoveMode}
                  />
                  <ComponentListTableBtnBulk
                    btnType={"Delete"}
                    listType={listType}
                    dataSet={dataSet}
                    selectedRows={selectedRows}
                    setSelectedUserList={setSelectedUserList}
                    setShowModalRemove={setShowModalRemove}
                    setRemoveMode={setRemoveMode}
                  />
                </>
              )}
            {listType === "Team" && (
              <>
                <ComponentListTableBtnBulk
                  btnType={"Delete"}
                  listType={listType}
                  dataSet={dataSet}
                  selectedRows={selectedRows}
                  setSelectedTeamList={setSelectedTeamList}
                  setShowModalRemove={setShowModalRemove}
                  setRemoveMode={setRemoveMode}
                />
              </>
            )}
            {listType === "Group" && (
              <>
                <ComponentListTableBtnBulk
                  btnType={"Delete"}
                  listType={listType}
                  dataSet={dataSet}
                  selectedRows={selectedRows}
                  setSelectedGroupsList={setSelectedGroupsList}
                  setShowModalRemove={setShowModalRemove}
                  setRemoveMode={setRemoveMode}
                />
              </>
            )}
            {listType === "Walkthrough" && (
              <>
                <ComponentListTableBtnBulk
                  btnType={"Delete"}
                  listType={listType}
                  dataSet={dataSet}
                  selectedRows={selectedRows}
                  setSelectedWalkthroughList={setSelectedWalkthroughList}
                  setShowModalRemove={setShowModalRemove}
                  setRemoveMode={setRemoveMode}
                />
              </>
            )}
            {listType === "GroupWalkthrough" && (
              <>
                <ComponentListTableBtnBulk
                  btnType={"Delete"}
                  listType={listType}
                  dataSet={dataSet}
                  selectedRows={selectedRows}
                  setSelectedGroupWalkthroughsList={
                    setSelectedGroupWalkthroughsList
                  }
                  setShowModalRemove={setShowModalRemove}
                  setRemoveMode={setRemoveMode}
                />
                <ComponentListTableBtnBulk
                  btnType={"Edit"}
                  listType={listType}
                  dataSet={dataSet}
                  selectedRows={selectedRows}
                  setSelectedGroupWalkthroughsList={
                    setSelectedGroupWalkthroughsList
                  }
                  setShowModalEdit={setShowModalEdit}
                  setEditMode={setEditMode}
                  selectMode={"All"}
                />
              </>
            )}

            {listType === "WalkthroughViewingPermissions" && (
              <>
                <ComponentListTableBtnBulk
                  btnType={"Edit"}
                  listType={listType}
                  dataSet={dataSet}
                  selectedRows={selectedRows}
                  setSelectedWalkthroughViewingPermissionsList={
                    setSelectedWalkthroughViewingPermissionsList
                  }
                  setShowModalEdit={setShowModalEdit}
                  setEditMode={setEditMode}
                />
                <ComponentListTableBtnBulk
                  btnType={"Delete"}
                  listType={listType}
                  dataSet={dataSet}
                  selectedRows={selectedRows}
                  setSelectedWalkthroughViewingPermissionsList={
                    setSelectedWalkthroughViewingPermissionsList
                  }
                  setShowModalRemove={setShowModalRemove}
                  setRemoveMode={setRemoveMode}
                />
              </>
            )}
            {listType === "WalkthroughViewerTeamPermissions" && (
              <>
                <ComponentListTableBtnBulk
                  btnType={"Edit"}
                  listType={listType}
                  dataSet={dataSet}
                  selectedRows={selectedRows}
                  setSelectedWalkthroughViewingPermissionsList={
                    setSelectedWalkthroughViewingPermissionsList
                  }
                  setShowModalEdit={setShowModalEdit}
                  setEditMode={setEditMode}
                />
                <ComponentListTableBtnBulk
                  btnType={"Delete"}
                  listType={listType}
                  dataSet={dataSet}
                  selectedRows={selectedRows}
                  setSelectedWalkthroughViewerTeamPermissionsList={
                    setSelectedWalkthroughViewerTeamPermissionsList
                  }
                  setShowModalRemove={setShowModalRemove}
                  setRemoveMode={setRemoveMode}
                />
              </>
            )}
            {listType === "editSharedWalkthrough" && (
              <>
                <ComponentListTableBtnBulk
                  btnType={"Delete"}
                  listType={listType}
                  dataSet={dataSet}
                  selectedRows={selectedRows}
                  setSelectedWalkthroughList={setSelectedWalkthroughList}
                  setShowModalRemove={setShowModalRemove}
                  setRemoveMode={setRemoveMode}
                />
              </>
            )}
          </>
        ) : (
          // For Displays on Toolbar when None is Selected
          <>
            {listType === "WalkthroughViewingPermissions" && (
              <>
                <ComponentListTableBtnBulk
                  btnType={"Edit"}
                  listType={listType}
                  dataSet={dataSet}
                  selectedRows={selectedRows}
                  setSelectedWalkthroughViewingPermissionsList={
                    setSelectedWalkthroughViewingPermissionsList
                  }
                  setShowModalEdit={setShowModalEdit}
                  setEditMode={setEditMode}
                  selectMode={"All"}
                />
              </>
            )}
            {listType === "WalkthroughViewerTeamPermissions" && (
              <>
                <ComponentListTableBtnBulk
                  btnType={"Edit"}
                  listType={listType}
                  dataSet={dataSet}
                  selectedRows={selectedRows}
                  setSelectedWalkthroughViewingPermissionsList={
                    setSelectedWalkthroughViewingPermissionsList
                  }
                  setShowModalEdit={setShowModalEdit}
                  setEditMode={setEditMode}
                  selectMode={"All"}
                />
              </>
            )}

            {listType === "GroupWalkthrough" && (
              <>
                <ComponentListTableBtnBulk
                  btnType={"Edit"}
                  listType={listType}
                  dataSet={dataSet}
                  selectedRows={selectedRows}
                  setSelectedGroupWalkthroughsList={
                    setSelectedGroupWalkthroughsList
                  }
                  setShowModalEdit={setShowModalEdit}
                  setEditMode={setEditMode}
                  selectMode={"All"}
                />
              </>
            )}
          </>
        )
      }
    </Toolbar>
  );
};

export default ComponentListTableToolbar;
