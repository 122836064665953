import React, { useEffect, useState } from "react";
import PictureViewers from "../components/Templates/PictureViewer/PictureViewers";
import DefaultHeader from "../components/03-organisms/04-pageHeader/DefaultHeader";
import { RootState } from "../store";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getSingleWalkthroughReq } from "../store/reducers/reducerWalkthrough";
import {
  Button,
  EditIcon,
  ShareIcon,
  Status,
  Tags,
} from "usertip-component-library";
import {
  PictureGuideData,
  Snap,
  SnapComponent,
} from "../interfaces/interfaceGuide";
import {
  addImageSnapManual,
  addNewSnapStep,
  addSnapManual,
  deleteSnapComponent,
  editSnapStep,
  getSingleGuide,
  getSingleSnapGuide,
  getSnapImage,
  postUpdateSnapSharingPermission,
  publishSnap,
  resetChangeId,
  snapFinishLoading,
  unpublishSnap,
} from "../store/reducers/reducerGuide";
import {
  GETSingleImageCall,
  getImageListFromPresign,
} from "../axios/v2/callsMedia";
import { ResponseGetSingleImage } from "../store/reducers/reducerMedia";
import GlobalLoading from "../components/UI/Loading/GlobalLoading";
import ComponentPageHeaderLinksBackBtn from "../components/ComponentPageHeaderLinksBackBtn";
import StepNameEditMode from "../components/02-molecules/05-picture/StepNameEditMode";
import ModalSnapSharingSetting from "../components/03-organisms/07-sharingSnap/ModalSnapSharingSetting";

const PageSnapGuide = () => {
  const { snapID } = useParams();

  const [editMode, setEditMode] = useState(false);
  const [pictureStep, setPictureStep] = useState<PictureGuideData[]>();
  const [stepBeforeEdit, setStepBeforeEdit] = useState<PictureGuideData[]>();
  const [snapName, setSnapName] = useState("");
  const [launch, setLaunch] = useState(true);
  const [isSnapContentChanged, setIsSnapContentChanged] = useState(false);
  const [showModalShare, setShowModalShare] = useState(false);
  const [sharingSnapSelectedValue, setSharingSnapSelectedValue] = useState("");

  const snap = useSelector((state: RootState) => state.guide.snapGuide);
  const { snapLoading, changeId, appLoading } = useSelector(
    (state: RootState) => state.guide
  );

  const dispatch = useDispatch();

  /** (First useEffect run ton this page) */
  useEffect(() => {
    // Grab the Walkthrough details based on "walkthrough_id", and then Grab the first instance of the results out of the Array to reply as a Walkthrough Object
    if (snapID) {
      /**Dispatch walkthrough name */
      dispatch(getSingleSnapGuide(snapID));
    }
  }, [dispatch, snapID]);

  /** This useEffect is to get the snap image (Second useEffect Launch on this page) */
  useEffect(() => {
    if (snapLoading) {
      const component = snap!.snap_component;

      // get the media_id of all snap_component for call to get the image
      if (component.length > 0) {
        component.forEach((step, index) => {
          if (step.content_type === "image") {
            dispatch(getSnapImage(step.media_id!));
          }

          // Set the loading to false after the last image is retrieved
          if (index === component.length - 1) {
            dispatch(snapFinishLoading());
          }
        });
      } else {
        // Set the loading to false if there's no snap component
        dispatch(snapFinishLoading());
      }
    }
  }, [snapLoading]);

  /** this useEffect will running after all image have been retrieved run (Third useEffect Launch on this page) */
  useEffect(() => {
    /** If snap data has been retrieved from API, convert it to PictureGuideData[] */
    if (snap && !snapLoading && launch) {
      let pictureStepList: PictureGuideData[] = [];

      setSnapName(snap.name);
      snap.snap_component.forEach((step) => {
        if (step.content_type === "image") {
          if (step.click_metadata) {
            const data: PictureGuideData = {
              ...(step.click_metadata.position && {
                position: {
                  x: step.click_metadata.position.x,
                  y: step.click_metadata.position.y,
                },
              }),
              id: step.snap_component_id,
              timestamp: step.click_metadata.timestamp,
              ...(step.description &&
                step.media_id && {
                  screen_capture_details: {
                    image_name: step.description,
                    image_url: step.media_url!,
                  },
                }),
              textContent: step.description ? step.description : step.content,
            };

            pictureStepList.push(data);
          } else {
            const data = {
              id: step.snap_component_id,
              textContent: step.description ? step.description : step.content,
              screen_capture_details: {
                image_name: step.description!,
                image_url: step.media_url!,
              },
            };

            pictureStepList.push(data);
          }
        } else {
          const data = {
            id: step.snap_component_id,
            textContent: step.description ? step.description : step.content,
          };
          pictureStepList.push(data);
        }
      });

      setPictureStep(pictureStepList);
    }
  }, [snap, snapLoading, launch]);

  /** This useEffect is for changing new pictureStep id accordance with new snap_component_id that created */
  useEffect(() => {
    if (snap && changeId) {
      const newIdChangeId = pictureStep?.map((val, index) => {
        if (val.id !== snap.snap_component[index].snap_component_id) {
          return {
            ...val,
            id: snap.snap_component[index].snap_component_id,
          };
        } else {
          return val;
        }
      });

      dispatch(resetChangeId());
      setPictureStep(newIdChangeId);
    }
  }, [snap, changeId]);

  /**enable edit mode */
  function onClickEdit() {
    setEditMode(true);
    setStepBeforeEdit(pictureStep);
  }

  /** handler to close edit mode and cancel all of the changes */
  function onCancelHandler() {
    setPictureStep(stepBeforeEdit);
    setSnapName(snap!.name);
    setStepBeforeEdit([]);
    setIsSnapContentChanged(false);
    setEditMode(false);
  }

  /** Indicator to disable save button */
  const saveIsDisabled = snap
    ? snap!.name === snapName &&
      pictureStep?.length === snap!.snap_component.length &&
      !isSnapContentChanged
    : false;

  /**save edit function */
  async function onClickSaveEdit() {
    setLaunch(false);
    /** Check if user delete step and get the step that user delete */
    for (const step of snap!.snap_component) {
      const existingObj = pictureStep!.find(
        (newStep) => newStep.id === step.snap_component_id
      );

      if (!existingObj) {
        await dispatch(
          deleteSnapComponent({
            snapId: step.snap_id,
            snapComponentId: step.snap_component_id,
          })
        );
      }
    }

    /**  Check if user add new snap step and Get the step that user add */
    for (const [index, step] of pictureStep!.entries()) {
      const newObject = snap!.snap_component.find(
        (newStep) => newStep.snap_component_id === step.id
      );

      if (!newObject) {
        /** This condition to differentiate betwween new image and new text */
        if (step.status === "new_image" && step.imageData) {
          const data = {
            ...step.imageData,
            description: step.textContent,
            step: index,
          };

          const body = {
            id: snap!.snap_id,
            payload: data,
          };

          await dispatch(addImageSnapManual(body));
        } else {
          const data = {
            content: step.textContent,
            description: step.textContent,
            content_type: "text",
            step: index,
          };

          const body = {
            id: snap!.snap_id,
            payload: data,
          };

          await dispatch(addSnapManual(body));
        }
      }
    }

    /**For get the new value of snap that edited */
    const modifiedComponentId = pictureStep!.map((step) => {
      const existingObj = snap!.snap_component.find(
        (newStep) => newStep.snap_component_id === step.id
      );

      if (existingObj) {
        return {
          ...existingObj,
          description: step.textContent,
          media_url: undefined,
        };
      }
    });

    /** Filter the undefined value on the array */
    const componentForUpdate = modifiedComponentId.filter(
      (val) => val !== undefined
    );

    const modifiedSnap: Snap = {
      ...snap,
      name: snapName,
      //@ts-ignore
      snap_component: componentForUpdate,
    };

    dispatch(editSnapStep(modifiedSnap));

    /** This is to remove the status of the new image after click save */
    const newPictureStep = pictureStep?.map((val) => {
      if (val.status === "new_image") {
        const data = val;
        delete data.status;
        return data;
      } else {
        return val;
      }
    });

    setPictureStep(newPictureStep);

    setEditMode(false);
    setStepBeforeEdit([]);
    setIsSnapContentChanged(false);
  }

  // use the useLocation hook to access the current URL
  const location = useLocation();

  // determine if the "/edit" parameter is present in the URL
  const isEditPage = location.pathname.includes("/edit");

  const navigate = useNavigate();

  // get quick build id to get quick build option that used to generate share link
  const getQuickBuildId = snap && snap.quick_build_id;

  useEffect(() => {
    if (getQuickBuildId) {
      dispatch(getSingleGuide(getQuickBuildId));
    }
  }, [getQuickBuildId]);

  const walkthroughData = useSelector(
    (state: RootState) => state.guide.activeGuideData.walkthrough
  );

  // to get snap data (guide_id, option, quick build id, sharing id)
  const snapData = useSelector(
    (state: RootState) => state.guide.activeGuideData.snap
  );

  const onConfirmShareOption = () => {
    const sharingSnapData = {
      snapId: snapID,
      option: snapData?.option,
    };

    if (sharingSnapData) {
      dispatch(postUpdateSnapSharingPermission(sharingSnapData));
    }
  };

  const publishSnapHandler = () => {
    dispatch(publishSnap(snap!.snap_id));
  };

  const unpublishSnapHandler = () => {
    dispatch(unpublishSnap(snap!.snap_id));
  };

  return (
    <div className="pageBase">
      <GlobalLoading />
      <ComponentPageHeaderLinksBackBtn
        btnURL={-1}
        btnLabel={"Your Guides"}
        btnDisabled={false}
      />
      {!isEditPage && (
        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-4">
            <DefaultHeader
              headerTitle={`${snap ? snap.name : "Untitled Guides"}`}
            />
            {snap && (
              <Status
                variants={snap.publish ? "confirmed" : "pending"}
                size="small"
                text={snap.publish ? "LIVE" : "DRAFT"}
              />
            )}
          </div>
          <p className="text-default-primary">
            Picture Guide for: {snap ? snap.name : "Untitled Guides"}
          </p>
        </div>
      )}
      {isEditPage && (
        <div className="flex flex-col gap-2">
          <DefaultHeader headerTitle={"Snap Editor"} />
          <p className="text-[#9CA3AF]">
            Modify the Snap name, add new Snaps Document, and update existing
            Snap here.
          </p>
        </div>
      )}
      {isEditPage && (
        <div className="mx-auto mb-4 mt-14 flex items-center justify-center">
          <div className="flex items-center justify-between w-[682px]">
            {!editMode && (
              <h1 className="text-2xl font-bold">
                {snap ? snap.name : "Untitled Guides"}
              </h1>
            )}
            {!editMode && (
              <Button
                color="primary"
                size="medium"
                variant="primary"
                text="Edit"
                onClick={onClickEdit}
                icon={<EditIcon />}
                iconLeft={true}
              />
            )}
            {editMode && (
              <StepNameEditMode
                setValue={(e) => setSnapName(e)}
                value={snapName}
              />
            )}
            {editMode && (
              <div className="flex gap-4">
                <Button
                  color="secondary"
                  size="medium"
                  variant="primary"
                  text="Cancel"
                  onClick={onCancelHandler}
                />
                <Button
                  color="primary"
                  size="medium"
                  variant="primary"
                  text="Save"
                  onClick={onClickSaveEdit}
                  disabled={saveIsDisabled}
                />
              </div>
            )}
          </div>
        </div>
      )}
      {!isEditPage && (
        <div className="pt-4 flex flex-row gap-4 items-center">
          {snap && (
            <Button
              color="primary"
              size="medium"
              text={snap.publish ? "Unpublish" : "Publish"}
              variant="primary"
              onClick={snap.publish ? unpublishSnapHandler : publishSnapHandler}
              disabled={appLoading}
            />
          )}
          <Button
            color="primary"
            size="medium"
            variant="primary"
            iconOnly
            icon={<ShareIcon />}
            text=""
            onClick={() => setShowModalShare(true)}
          />

          <Button
            color="primary"
            size="medium"
            variant="primary"
            iconOnly
            icon={<EditIcon />}
            text=""
            onClick={() => navigate(`/guides/snaps/${snapID}/edit`)}
          />
        </div>
      )}

      <div className="flex justify-center w-full mt-4">
        <PictureViewers
          editMode={editMode}
          setEditMode={setEditMode}
          pictureStep={pictureStep!}
          setPictureStep={setPictureStep}
          snapName={snapName}
          setSnapName={setSnapName}
          setIsSnapContentChanged={setIsSnapContentChanged}
        />
      </div>

      <ModalSnapSharingSetting
        open={showModalShare}
        setOpen={setShowModalShare}
        selectedValue={sharingSnapSelectedValue}
        onSelectedValue={setSharingSnapSelectedValue}
        onConfirm={onConfirmShareOption}
        walkthrough_data={walkthroughData}
        // @ts-ignore
        snapId={snapID}
        guideId={snapData?.guide_id!}
        // @ts-ignore
        shareId={snapData?.sharing_id ? snapData?.sharing_id : null}
        option={snapData?.option}
      />
    </div>
  );
};

export default PageSnapGuide;
