import React, { useState, useEffect } from "react";

import { FormControl } from "@mui/material";
import {
  Button,
  InputField,
  SearchDropdown,
  Radio,
} from "usertip-component-library";
import { useSelector, useDispatch } from "react-redux";

import { getWalkthroughsSummary } from "../store/reducers/reducerWalkthrough";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

const ComponentSettingsWalkthroughPostAction = ({
  walkthrough,
  updateSettings,
  walkthroughsSummary,
}) => {
  const dispatch = useDispatch();

  const [walkthroughPostActionOriginal, setWalkthroughPostActionOriginal] =
    useState("");
  const [walkthroughPostAction, setWalkthroughPostAction] = useState("");
  const [
    walkthroughPostActionRedirectURL,
    setWalkthroughPostActionRedirectURL,
  ] = useState("");

  const [
    walkthroughPostActionLaunchAnother,
    setWalkthroughPostActionLaunchAnother,
  ] = useState(null);
  const [dataArray, setDataArray] = useState([]);

  const [updateSettingsRequired, setUpdatedSettingsRequired] = useState(false);

  const user = useSelector((state) => state.user.userData);

  useEffect(() => {
    dispatch(getWalkthroughsSummary(user.cognito_id));
  }, []);

  // Tracks "walkthrough" and updates Form Data when new data exists
  useEffect(() => {
    if (walkthrough.settings) {
      const postActionSettings = walkthrough.settings.post_action;

      // Manaully check & update "walkthroughPostAction" radio btn on which one to set to true
      if (postActionSettings.redirect.option) {
        setWalkthroughPostAction("redirect");
        setWalkthroughPostActionOriginal("redirect");
      } else if (postActionSettings.launch_another.option) {
        setWalkthroughPostAction("launch_another");
        setWalkthroughPostActionOriginal("launch_another");
      } else if (postActionSettings.do_nothing.option) {
        setWalkthroughPostAction("do_nothing");
        setWalkthroughPostActionOriginal("do_nothing");
      }

      // Reset Post Action Settings: Redirect URL based off Server Data
      setWalkthroughPostActionRedirectURL(postActionSettings.redirect.url);

      setWalkthroughPostActionLaunchAnother({
        id: postActionSettings.launch_another.id,
        value: postActionSettings.launch_another.name,
      });

      setUpdatedSettingsRequired(false);
    }
  }, [walkthrough]);

  // Tracks "walkthroughsSummary" and updates Form Data when new data exists
  useEffect(() => {
    console.log("---walkthroughsSummary", walkthroughsSummary);
    if (walkthroughsSummary !== null && walkthroughsSummary.length > 0) {
      // Pre-filter WalkthroughsSummary to remove current "walkthrough" from existing list
      setDataArray(
        walkthroughsSummary.map((val) => {
          return {
            id: val.walkthrough_id,
            value: val.name,
          };
        })
      );
    }
  }, [walkthrough.walkthrough_id, walkthroughsSummary]);

  // Walkthrough: Update Walkthrough Post Action field
  const handleUpdateWalkthroughPostAction = (value) => {
    setWalkthroughPostAction(value);

    if (value !== walkthroughPostActionOriginal) {
      setUpdatedSettingsRequired(true);
    } else {
      setUpdatedSettingsRequired(false);
    }
  };

  // Walkthrough: Update Exising Redirect Textfield Contents
  const handleUpdateRedirectInputTextfield = (e) => {
    setWalkthroughPostActionRedirectURL(e.target.value);

    if (
      e.target.value.length > 0 &&
      e.target.value.trim() !==
        walkthrough.settings.post_action.redirect.url.trim()
    ) {
      setUpdatedSettingsRequired(true);
    } else {
      setUpdatedSettingsRequired(false);
    }
  };

  // Walkthrough: Update Exising Launch Another Select Contents
  const handleUpdateLaunchAnotherSelectDropdownList = (id, value) => {
    setWalkthroughPostActionLaunchAnother({
      id: id,
      value: value,
    });
    setUpdatedSettingsRequired(true);
  };

  // Walkthrough: Handles Launch Settings Submission Form
  const handleLaunchSettingsSubmissionForm = (e) => {
    e.preventDefault();

    if (updateSettingsRequired) {
      // Resetting Radio Button Selected Option Detail, to align with Server API format
      let optionValuePostAction = false;
      let optionValueLaunchAnother = false;
      let optionValueDoNothing = false;

      if (walkthroughPostAction === "redirect") {
        optionValuePostAction = true;
      } else if (walkthroughPostAction === "launch_another") {
        optionValueLaunchAnother = true;
      } else if (walkthroughPostAction === "do_nothing") {
        optionValueDoNothing = true;
      }

      const newSettingsUpdate = {
        ...walkthrough.settings,
        post_action: {
          redirect: {
            option: optionValuePostAction,
            url: walkthroughPostActionRedirectURL,
          },
          launch_another: {
            option: optionValueLaunchAnother,
            id: walkthroughPostActionLaunchAnother.id,
            name: walkthroughPostActionLaunchAnother.value,
          },
          do_nothing: {
            option: optionValueDoNothing,
          },
        },
      };

      // Inject Update of Launch Settings
      const walkthroughData = {
        walkthroughID: walkthrough.walkthrough_id,
        settings: newSettingsUpdate,
      };
      dispatch(updateSettings(walkthroughData));
    }
  };

  const [collapse, setCollapse] = useState(true);

  return (
    <>
      {collapse ? (
        <div className="pageForm flex flex-row gap-2">
          <span
            className="-ml-8 cursor-pointer"
            onClick={() => setCollapse(!collapse)}
          >
            {collapse ? <AddCircleOutlineIcon /> : <RemoveCircleOutlineIcon />}
          </span>
          <div className="pageSettingsHeader">Post-Action Settings</div>
        </div>
      ) : (
        <div className="">
          <form className="pageForm">
            <div className="flex flex-row gap-2">
              <span
                className="-ml-8 cursor-pointer"
                onClick={() => setCollapse(!collapse)}
              >
                {collapse ? (
                  <AddCircleOutlineIcon />
                ) : (
                  <RemoveCircleOutlineIcon />
                )}
              </span>
              <div className="pageSettingsHeader">Post-Action Settings</div>
            </div>

            <div className="pageSettingsSubHeader"></div>

            <div className="pageSettingSection">
              <FormControl component="fieldset">
                <div className="pageSettingsSectionHeader">
                  What should we do when this flow has completed?
                </div>

                <div className="flex flex-col gap-4 mt-2">
                  <Radio
                    name="walkthrough-post-action-settings"
                    id="redirect"
                    label="Redirect to a different page"
                    value="redirect"
                    onSelectedValue={handleUpdateWalkthroughPostAction}
                    selectedValue={walkthroughPostAction}
                  />
                  <div className="ml-6">
                    <InputField
                      variant="text"
                      placeholder={"https://www.example.com"}
                      value={walkthroughPostActionRedirectURL}
                      onChange={(e) => handleUpdateRedirectInputTextfield(e)}
                      disabled={
                        walkthroughPostAction === "redirect" ? false : true
                      }
                    />
                  </div>
                  <Radio
                    name="walkthrough-post-action-settings"
                    id="launch_another"
                    label="Launch another Usertip Walkthrough"
                    value="launch_another"
                    onSelectedValue={handleUpdateWalkthroughPostAction}
                    selectedValue={walkthroughPostAction}
                  />
                  <div className="ml-6">
                    <SearchDropdown
                      selectedValue={walkthroughPostActionLaunchAnother}
                      dataArray={dataArray}
                      onClick={handleUpdateLaunchAnotherSelectDropdownList}
                      emptyLabel="No Walkthroughs Found"
                      scroll
                      placeholder="Select Walkthrough"
                      disabled={
                        walkthroughPostAction === "launch_another"
                          ? false
                          : true
                      }
                    />
                  </div>
                  <Radio
                    name="walkthrough-post-action-settings"
                    id="do_nothing"
                    label="Do Nothing"
                    value="do_nothing"
                    onSelectedValue={handleUpdateWalkthroughPostAction}
                    selectedValue={walkthroughPostAction}
                  />
                </div>
              </FormControl>
            </div>

            <div>
              <div className="ml-auto w-[5.625rem]">
                <Button
                  color="primary"
                  size="fluid"
                  variant="primary"
                  text="Save"
                  disabled={updateSettingsRequired === false ? true : false}
                  onClick={handleLaunchSettingsSubmissionForm}
                />
              </div>
            </div>
          </form>

          <div className="pageDivider" />
        </div>
      )}
    </>
  );
};

export default ComponentSettingsWalkthroughPostAction;
