import React from "react";
import ComponentNotFound from "../components/UI/PageNotFound/ComponentNotFound";

const PageNotFound = () => {
  return (
    <>
      <ComponentNotFound />
    </>
  );
};

export default PageNotFound;
