import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import {
  abortChangePwd,
  authNewPassword,
} from "../../../store/reducers/reducerAuth";
import CodeForm from "./CodeForm";
import EmailForm from "./EmailForm";
import PasswordChangeFailed from "./PasswordChangeFailed";
import PasswordChangeSuccsess from "./PasswordChangeSuccsess";
import PasswordForm from "../../03-organisms/05-form/PasswordForm";

const ComponentAccountForgotPassword = () => {
  const [email, setEmail] = useState<string>("");
  const [code, setCode] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const pageSection = useSelector(
    (state: RootState) => state.auth.forgotPwdSection
  );
  const confirmCode = useSelector(
    (state: RootState) => state.auth.confirmationCode
  );

  const dispatch = useDispatch();

  const AbortForgotPasswordHandler = () => {
    dispatch(abortChangePwd());
  };

  const handleNewPassword = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const passData = {
      email: email,
      password: password,
      confirmation_code: confirmCode!,
    };
    dispatch(authNewPassword(passData));
  };

  switch (pageSection) {
    case "formEmail":
      return (
        <EmailForm
          email={email}
          setEmail={setEmail}
          AbortForgotPasswordHandler={AbortForgotPasswordHandler}
        />
      );
    case "formCode":
      return (
        <CodeForm
          code={code}
          setCode={setCode}
          AbortForgotPasswordHandler={AbortForgotPasswordHandler}
        />
      );
    case "formNewPass":
      return (
        <PasswordForm
          type="forgot"
          password={password}
          setPassword={setPassword}
          AbortForgotPasswordHandler={AbortForgotPasswordHandler}
          onSubmitHandler={handleNewPassword}
        />
      );
    case "formSuccess":
      return <PasswordChangeSuccsess />;
    case "formInvalid":
      return <PasswordChangeFailed />;
    default:
      return <div></div>;
  }
};

export default ComponentAccountForgotPassword;
