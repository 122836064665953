import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Tabs, TabsItem } from "usertip-component-library";
import ThemeEditorPanel from "../components/03-organisms/08-themeEditorPanel/ThemeEditorPanel";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../store";
import { getTheme, resetAlert } from "../store/reducers/reducerTheme";
import ComponentLoading from "../components/ComponentLoading";
import ComponentAlertSnackbar from "../components/ComponentAlertSnackbar";

const PageThemeEditor = () => {
  /** This state for set the component to show in the theme editor panel. */
  const [component, setComponent] = useState("modal");
  /** This state for showing alert when succes or failed fetching */
  const [showAlertSnackbar, setShowAlertSnackbar] = useState<boolean>(false);

  const {
    modalTheme,
    slideoutTheme,
    spotlightTheme,
    tooltipTheme,
    appLoading,
    alert,
  } = useSelector((state: RootState) => state.theme);
  const { org_id } = useSelector((state: RootState) => state.org);

  const dispatch = useDispatch<AppDispatch>();

  /** This useEffect for fetch the theme when user move to this page */
  useEffect(() => {
    if (org_id) {
      dispatch(getTheme(org_id));
    }
  }, [org_id]);

  /** This useEffect for handling the alert */
  useEffect(() => {
    if (alert.active) {
      setShowAlertSnackbar(alert.active);
    }
  }, [alert.active]);

  /** This useEffect for reset the alert when alert state closed */
  useEffect(() => {
    if (!showAlertSnackbar) {
      dispatch(resetAlert());
    }
  }, [showAlertSnackbar]);

  /** Function to handler tabs when it clicked */
  const handleTabsClick = useCallback(
    (value: "modal" | "slideout" | "spotlight" | "tooltip") => {
      setComponent(value);
    },
    []
  );

  /** Children of the Tabs component to prevent UI issues */
  const tabsChildren = useMemo(() => {
    return (
      <>
        <TabsItem
          variant="lightest"
          label="Modal"
          onClick={() => handleTabsClick("modal")}
        />
        <TabsItem
          variant="lightest"
          label="Slideout"
          onClick={() => handleTabsClick("slideout")}
        />
        <TabsItem
          variant="lightest"
          label="Spotlight"
          onClick={() => handleTabsClick("spotlight")}
        />
        <TabsItem
          variant="lightest"
          label="Tooltip"
          onClick={() => handleTabsClick("tooltip")}
        />
      </>
    );
  }, [handleTabsClick]);

  return (
    <div>
      <div className="pageBase">
        <div className="pageHeader">Theme Editor</div>
        <div className="text-[#9CA3AF]">
          Apply and Change Theme Walkthrough for Step Component here.
        </div>
        <div className="flex flex-row w-full border-b border-b-[#F1EEFE] my-8">
          <Tabs variant="lightest" name="tabs-theme">
            {tabsChildren}
          </Tabs>
        </div>
      </div>
      {component === "modal" && (
        <ThemeEditorPanel type="modal" style={modalTheme} />
      )}
      {component === "slideout" && (
        <ThemeEditorPanel type="slideout" style={slideoutTheme} />
      )}
      {component === "spotlight" && (
        <ThemeEditorPanel type="spotlight" style={spotlightTheme} />
      )}
      {component === "tooltip" && (
        <ThemeEditorPanel type="tooltip" style={tooltipTheme} />
      )}

      <ComponentLoading show={appLoading} />

      <ComponentAlertSnackbar
        variant={alert.type === "success" ? "success" : "error"}
        title={alert.message}
        showAlertSnackbar={showAlertSnackbar}
        setShowAlertSnackbar={setShowAlertSnackbar}
        hideAlert={alert.autoHideDuration}
      />
    </div>
  );
};

export default PageThemeEditor;
