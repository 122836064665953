import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ComponentListUsers from "../components/ComponentListUsers";
import ComponentLoading from "../components/ComponentLoading";
import { flattenObj } from "../components/_functions/FunctionFlattenObj";
import { RootState } from "../store";
import { organizationGetCodeSnippetViewers } from "../store/reducers/reducerOrganization";
import TableOrgCodeSnippetViewer from "../components/Table/TableOrgCodeSnippetViewer";

const PageOrganizationCodeSnippetViewer = () => {
  const [flattenDataSet, setFlattenDataSet] = useState<[any] | null | []>(null);

  const { appLoading, org_id, viewerersCodeSnippet } = useSelector(
    (state: RootState) => state.org
  );
  const dispatch = useDispatch();

  console.log(viewerersCodeSnippet);

  useEffect(() => {
    if (org_id) {
      dispatch(organizationGetCodeSnippetViewers(org_id));
    }
  }, [org_id, dispatch]);

  useEffect(() => {
    // flatten the object
    viewerersCodeSnippet &&
      setFlattenDataSet(
        viewerersCodeSnippet.map((data: any) => {
          return flattenObj(data, "meta");
        })
      );
  }, [viewerersCodeSnippet]);
  // TODO: backend will create API to check metadata, it will return the meta data fields and types
  // Will be retrieved from backend and stored inside reducer
  const metaDataTypes = {};

  return (
    <div className="pageBase">
      <ComponentLoading show={appLoading} />
      {/* <ComponentListUsers
        displayUserRole={"Viewer Code Snippet"}
        displayUserRoleFull={"Viewer Code Snippet"}
        displayShowAddBtn={false}
        introMsg={""}
        dataSet={
          flattenDataSet && flattenDataSet.length !== 0
            ? flattenDataSet
            : undefined
        }
        indicatorMsg={"No Code Snippet Viewers found. Please add one!"}
        metaDataTypes={metaDataTypes}
      /> */}
      {/* NEW TABLE COMPONENT */}
      <TableOrgCodeSnippetViewer />
    </div>
  );
};

export default PageOrganizationCodeSnippetViewer;
