import React, { useState, useEffect } from "react";

import { Button, TextField, Select, MenuItem } from "@mui/material";
import { InputField, Button as UtButton } from "usertip-component-library";

import countryCodes from "../data/countryCodes.json";
import { UserData } from "../store/reducers/_reducerIndex";

interface Props {
  user: UserData;
}

const ComponentSettingsAccountInfoPersonal = ({ user }: Props) => {
  const [originalAccountName, setOriginalAccountName] = useState("");
  const [
    originalAccountPhoneNumberPrefix,
    setOriginalAccountPhoneNumberPrefix,
  ] = useState("");
  const [originalAccountPhoneNumber, setOriginalAccountPhoneNumber] =
    useState("");

  const [accountName, setAccountName] = useState("");
  const [accountPhoneNumberPrefix, setAccountPhoneNumberPrefix] = useState("");
  const [accountPhoneNumber, setAccountPhoneNumber] = useState("");

  const [enableAccountName, setEnableAccountName] = useState(false);
  const [enableAccountPhoneNumber, setEnableAccountPhoneNumber] =
    useState(false);

  const [updateRequiredAccountName, setUpdatedRequiredAccountName] =
    useState(false);
  const [
    updateRequiredAccountPhoneNumber,
    setUpdatedRequiredAccountPhoneNumber,
  ] = useState(false);

  const [countryCodeDate, setCountryCodeData] = useState([]);

  // Tracks "user" and updates Form Data when new data exists
  useEffect(() => {
    if (user) {
      if (user.name) {
        setOriginalAccountName(user.name);
        setAccountName(user.name);

        // As long user has a name, inject CountryCodes into Select Dropdown, so that User can still add their number thereafter
        // Sort countryCodes in proper order before display
        setCountryCodeData(
          //@ts-ignore
          [...countryCodes].sort(function (a, b) {
            //@ts-ignore
            return a.dialCode - b.dialCode;
          })
        );
      }
      if (user.phoneno) {
        //@ts-ignore
        setOriginalAccountPhoneNumberPrefix(user.phoneno.intl);
        //@ts-ignore
        setOriginalAccountPhoneNumber(user.phoneno.value);

        //@ts-ignore
        setAccountPhoneNumberPrefix(user.phoneno.intl);
        //@ts-ignore
        setAccountPhoneNumber(user.phoneno.value);
      }
    }
  }, [user]);

  // Account: Update Name field
  const handleUpdateAccountName = (e: any) => {
    setAccountName(e.target.value);

    if (
      e.target.value.length > 0 &&
      e.target.value.trim() !== user.name.trim()
    ) {
      setUpdatedRequiredAccountName(true);
    } else {
      setUpdatedRequiredAccountName(false);
    }
  };

  // Account: Update Phone Number field
  const handleUpdateAccountPhoneNumber = (e: any) => {
    setAccountPhoneNumber(e.target.value);

    if (e.target.value.length > 0 && e.target.value !== user.phoneno) {
      setUpdatedRequiredAccountPhoneNumber(true);
    } else {
      setUpdatedRequiredAccountPhoneNumber(false);
    }
  };

  // Account: Save Name field to API
  const handleSaveAccountName = (e: any) => {
    e.preventDefault();
  };

  // Account: Save Phone Number field to API
  const handleSaveAccountPhoneNumber = (e: any) => {
    e.preventDefault();
  };

  // Account: Update Phone Number Prefix
  const handleUpdatePhoneNumberPrefixSelectDropdownList = (e: any) => {
    setAccountPhoneNumberPrefix(e.target.value);
  };

  return (
    <div className="">
      <form className="pageForm">
        <div className="pageSettingsHeader">Personal Information</div>

        <div className="pageSettingsSubHeader"></div>

        <div className="pageSettingSection flex">
          <div className="flex-1">
            <div className=" relative flex-1 ml-0 mr-2">
              <InputField
                variant="full"
                customWidth="ut-w-full"
                onChange={(e) => handleUpdateAccountName(e)}
                disabled={enableAccountName === false ? true : false}
                label="Name"
                value={accountName}
                placeholder=""
                helperText=""
              />

              <div className="pageSettingsInputChangeActionBar absolute -top-0.5 right-0 uppercase">
                <div className={enableAccountName ? "hidden" : "block"}>
                  <UtButton
                    color="primary"
                    size="medium"
                    text="CHANGE"
                    variant="outlined"
                    customClass="ut-settings-button"
                    onClick={(e) => {
                      e.preventDefault();
                      setEnableAccountName(true);
                    }}
                  />
                </div>

                <div
                  className={`flex ${enableAccountName ? "block" : "hidden"}`}
                >
                  <UtButton
                    color="primary"
                    size="medium"
                    text="CANCEL"
                    variant="outlined"
                    customClass="ut-settings-button"
                    onClick={(e) => {
                      e.preventDefault();
                      // Resets Name to original
                      setAccountName(originalAccountName);
                      setEnableAccountName(false);
                      setUpdatedRequiredAccountName(false);
                    }}
                  />
                  <UtButton
                    color="primary"
                    size="medium"
                    text="SAVE"
                    variant="outlined"
                    customClass="ut-settings-button"
                    disabled={updateRequiredAccountName ? false : true}
                    onClick={(e) => handleSaveAccountName(e)}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="flex-grow" />

          <div className="flex-1"></div>
        </div>

        {/* <div className="pageSettingSection flex">
          <div className="flex-1">
            <div className="pageSettingsInputTextfield relative flex-1 ml-0 mr-2">
              <div className="pageSettingsInputTextfieldHeader">
                Phone Number
              </div>

              <div className="flex mt-6">
                <div className="pageSettingsSelectDropdown ml-0 mr-3 mb-0 h-10">
                  <Select
                    variant={"standard"}
                    value={accountPhoneNumberPrefix}
                    onChange={(e) =>
                      handleUpdatePhoneNumberPrefixSelectDropdownList(e)
                    }
                    disabled={enableAccountPhoneNumber === false ? true : false}
                    sx={{
                      width: "96px",
                      height: "46px",
                    }}
                  >
                    {countryCodeDate &&
                      countryCodeDate.map((code) => {
                        return (
                          <MenuItem key={code.isoCode} value={code.dialCode}>
                            {code.dialCode}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </div>

                <div className="pageSettingsInputTextfieldPhoneNumber">
                  <TextField
                    type={"text"}
                    variant={"standard"}
                    size={"small"}
                    value={accountPhoneNumber}
                    onChange={(e) => handleUpdateAccountPhoneNumber(e)}
                    disabled={enableAccountPhoneNumber === false ? true : false}
                    fullWidth
                  />
                </div>
              </div>

              <div className="pageSettingsInputChangeActionBar absolute -top-0.5 right-0 text-xs uppercase">
                <div className={enableAccountPhoneNumber ? "hidden" : "block"}>
                  <Button
                    variant={"text"}
                    size={"small"}
                    onClick={() => setEnableAccountPhoneNumber(true)}
                    sx={{
                      padding: "2px 8px",
                    }}
                  >
                    Change
                  </Button>
                </div>

                <div
                  className={`flex ${
                    enableAccountPhoneNumber ? "block" : "hidden"
                  }`}
                >
                  <Button
                    variant={"text"}
                    size={"small"}
                    onClick={(e) => {
                      e.preventDefault();
                      // Reset Phone Number to original
                      setAccountPhoneNumberPrefix(
                        originalAccountPhoneNumberPrefix
                      );
                      setAccountPhoneNumber(originalAccountPhoneNumber);
                      setEnableAccountPhoneNumber(false);
                    }}
                    sx={{
                      padding: "2px 8px",
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant={"text"}
                    size={"small"}
                    onClick={(e) => handleSaveAccountPhoneNumber(e)}
                    sx={{
                      padding: "2px 8px",
                    }}
                    disabled={updateRequiredAccountPhoneNumber ? false : true}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </div>
          </div>

          <div className="flex-grow" />

          <div className="flex-1"></div>
        </div> */}
      </form>

      <div className="pageDivider" />
    </div>
  );
};

export default ComponentSettingsAccountInfoPersonal;
