import React from "react";
import { SnapItems } from "../interfaces/interfaceGuide";
import { useDispatch } from "react-redux";
import { Button, EmptyModal } from "usertip-component-library";
import { deleteSingleSnap } from "../store/reducers/reducerGuide";

interface Props {
  isOpen: boolean;
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
  selectedRemoveData?: any[];
  setSelectedRemoveData: React.Dispatch<React.SetStateAction<SnapItems[]>>;
  singleRemoveData: SnapItems | null;
  setSingleRemoveData: React.Dispatch<React.SetStateAction<SnapItems | null>>;
}

const ComponentRemoveSnap = ({
  isOpen,
  onClose,
  setSelectedRemoveData,
  setSingleRemoveData,
  singleRemoveData,
  selectedRemoveData,
}: Props) => {
  const dispatch = useDispatch();

  /** this is handler for close the remove guide modal */
  const handleHideModal = () => {
    onClose(false);
    setSelectedRemoveData([]);
    setSingleRemoveData(null);
  };

  /** this is handler for removing the guides */
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (singleRemoveData) {
      /** checking if there's single guides data (for remove one guides) */
      dispatch(deleteSingleSnap(singleRemoveData.snap_id));
    } else {
      /** if there's no single guides data, it means the modal opened with bulk remove button one */
      if (selectedRemoveData) {
        /** check if there's data on the selected row data array */
        for (const iterator of selectedRemoveData) {
          const snap_id = iterator.snap_id;
          dispatch(deleteSingleSnap(snap_id));
        }
      }
    }

    /** close the modal */
    handleHideModal();
  };

  return (
    <EmptyModal
      isOpen={isOpen}
      onClose={handleHideModal}
      size="large"
      customClass="ut-py-5"
    >
      <div className="modalHeader">Confirm Removal of Snap?</div>

      {singleRemoveData ? (
        <>
          <div className="modalSubHeader">
            WARNING: You have selected <i>“Delete”</i> to remove{" "}
            <b>{singleRemoveData ? `${singleRemoveData.name}` : undefined}</b>,
            please confirm to proceed or select cancel to return to the previous
            page.
          </div>
        </>
      ) : (
        <>
          <div className="modalSubHeader">
            WARNING: You have selected to <i>“Bulk Delete”</i> the following
            snap(s), please confirm to proceed or select cancel to return to the
            previous page.
          </div>

          <ol className="list-decimal list-inside mb-8">
            {selectedRemoveData &&
              selectedRemoveData.map((snap) => (
                <li key={snap.name ? snap.name : ""}>
                  {`${snap.name ? snap.name : ""}`}
                </li>
              ))}
          </ol>
        </>
      )}

      <form
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit}
        className="modalForm"
      >
        <div className="flex gap-2 w-1/3 ml-auto">
          <Button
            variant="primary"
            color="primary"
            size="fluid"
            text="Cancel"
            onClick={handleHideModal}
          />
          <Button variant="primary" color="primary" size="fluid" text="Yes" />
        </div>
      </form>
    </EmptyModal>
  );
};

export default ComponentRemoveSnap;
