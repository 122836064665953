import { Button } from "usertip-component-library";
import React, { useState } from "react";
import AddBoxIcon from "@mui/icons-material/AddBox";
import ComponentSegmentationList from "../components/UserSegmentationList/ComponentSegmentationList";
import ComponentLoading from "../components/ComponentLoading";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { useNavigate } from "react-router-dom";
import ComponentFilterTable from "../components/ComponentFilterTable";
import { UserSegmentation } from "../interfaces/interfaceSegmentation";

const PageUserSegmentation = () => {
  const [filteredSegmentations, setFilteredSegmentations] = useState<
    UserSegmentation[]
  >([]);

  const loading = useSelector(
    (state: RootState) => state.userSegmentation.appLoading
  );
  const userSegmentationList = useSelector((state: RootState) => {
    return state.userSegmentation
      ? state.userSegmentation.userSegmentationList!
      : [];
  });

  const segmentationFilter = [
    {
      field: "segmentation_name",
      type: "String",
      label: "Segmentation",
    },
    {
      field: "createdAt",
      type: "Date",
      label: "Date Created",
    },
    {
      field: "updatedAt",
      type: "Date",
      label: "Date Updated",
    },
  ];

  return (
    <>
      <ComponentLoading show={loading} />
      <div className="pageBase">
        <div className="pageHeader">List of User Segmentation</div>
        <div className="text-[#9CA3AF]">Manage user segmentation here</div>
        {userSegmentationList && (
          <ComponentFilterTable
            filters={segmentationFilter}
            originalData={userSegmentationList}
            setFilteredData={setFilteredSegmentations}
          />
        )}
        <section id="Segment_list">
          <ComponentSegmentationList
            userSegmentationList={filteredSegmentations}
          />
        </section>
      </div>
    </>
  );
};

export default PageUserSegmentation;
