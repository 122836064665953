import React from "react";
import { Avatar, Button } from "usertip-component-library";
interface Props {
  /** This is for set user name */
  name: string;
  /** This is for set user email */
  email: string;
  /** This is for set avatar active or not */
  active: boolean;
  /** This is for set function when avatar is on click */
  avatarOnClick?: () => void;
  /** This is for set function when button is on click */
  buttonOnClick?: () => void;
}

const NavigationProfile = ({
  name,
  email,
  active,
  avatarOnClick,
  buttonOnClick,
}: Props) => {
  let styleClass: string = "";

  /** This is for set style for the avatar background by active or not */
  if (active) {
    styleClass = "bg-default-primary-lighter";
  } else {
    styleClass = "hover:bg-default-primary-lightest";
  }

  return (
    <div className="flex justify-between items-center mt-auto pr-0">
      <div
        className={`cursor-pointer flex-1 pl-3 py-2 ${styleClass}`}
        onClick={(e) => {
          e.stopPropagation();
          if (avatarOnClick) {
            avatarOnClick();
          }
        }}
      >
        <Avatar
          email={email}
          colors="primary"
          name={name}
          profile="initials"
          label={true}
        />
      </div>
      <div
        onClick={(e) => {
          e.stopPropagation();
          if (buttonOnClick) {
            buttonOnClick();
          }
        }}
      >
        <Button
          size="small"
          color="primary"
          text="Need help?"
          variant="outlined"
        />
      </div>
    </div>
  );
};

export default NavigationProfile;
