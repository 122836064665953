// Alert
export const SET_ALERT = "SET_ALERT";
export const RESET_ALERT = "RESET_ALERT";

// User account
export const SET_USER_DATA = "SET_USER_DATA";
export const GET_USER_ACCOUNT_INFO = "GET_USER_ACCOUNT_INFO";
export const UPDATE_USER_NAME = "UPDATE_USER_NAME";
export const UPDATE_USER_PHONE_NUMBER = "UPDATE_USER_PHONE_NUMBER";
export const UPDATE_USER_ORGANIZATIONAL_INFO =
  "UPDATE_USER_ORGANIZATIONAL_INFO";

// Loading
export const START_APP_LOADING = "START_APP_LOADING";
export const STOP_APP_LOADING = "STOP_APP_LOADING";


// Authentication
export const REGISTRATION_INITIATED = "REGISTRATION_INITIATED";
export const REGISTRATION_USER_ALREADY_EXISTS =
  "REGISTRATION_USER_ALREADY_EXISTS";
export const REGISTRATION_SUCCESS = "REGISTRATION_SUCCESS";
export const REGISTRATION_ERROR = "REGISTRATION_ERROR";
export const REGISTRATION_NOT_VERIFIED = "REGISTRATION_NOT_VERIFIED";
export const BYINVITE_REGISTRATION_SUCCESS = "BYINVITE_REGISTRATION_SUCCESS";
export const BYINVITE_REGISTRATION_ERROR = "BYINVITE_REGISTRATION_ERROR";
export const SIGN_IN_SUCCESS = "SIGN_IN_SUCCESS";
export const SIGN_IN_ERROR = "SIGN_IN_ERROR";
export const SIGN_OUT_SUCCESS = "SIGN_OUT_SUCCESS";
export const RESET_PASSWORD_COGNITO = "RESET_PASSWORD_COGNITO";
export const AUTHENTICATED_USER_ACCOUNT = "AUTHENTICATED_USER_ACCOUNT";