import React, { useState, useEffect } from "react";

import { InputField, Button as UtButton } from "usertip-component-library";
import { useNavigate } from "react-router-dom";
import { UserData } from "../store/reducers/_reducerIndex";

interface Props {
  user: UserData;
}

const ComponentSettingsAccountInfoAccount = ({ user }: Props) => {
  const navigate = useNavigate();
  const [accountEmail, setAccountEmail] = useState("");

  // Tracks "user" and updates Form Data when new data exists
  useEffect(() => {
    if (user) {
      if (user.email) {
        setAccountEmail(user.email);
      }
    }
  }, [user]);

  return (
    <div className="mt-5">
      <form className="pageForm">
        <div className="pageSettingsHeader">Account Information</div>

        <div className="pageSettingsSubHeader"></div>

        <div className="pageSettingSection flex">
          <div className="flex-1">
            <InputField
              variant="full"
              customWidth="ut-w-full"
              value={accountEmail}
              disabled={true}
              label="Email"
              helperText=""
            />
          </div>

          <div className="flex-grow" />

          <div className="flex-1"></div>
        </div>

        <div className="pageSettingSection flex">
          <div className="flex-1">
            <div className="pageSettingsInputTextfield relative flex-1 ml-0 mr-2">
              <div className="pageSettingsInputTextfieldHeader ml-[14px]">
                Password
              </div>

              <div className="pageSettingsInputChangeActionBar absolute -top-0.5 right-0 uppercase">
                <UtButton
                  color="primary"
                  size="medium"
                  text="CHANGE"
                  variant="outlined"
                  customClass="ut-settings-button"
                  onClick={() => navigate("/account/settings/change-password")}
                />
              </div>
            </div>
          </div>

          <div className="flex-grow" />

          <div className="flex-1"></div>
        </div>
      </form>

      <div className="pageDivider" />
    </div>
  );
};

export default ComponentSettingsAccountInfoAccount;
