import React from "react";
import { useDispatch } from "react-redux";
import { abortChangePwd } from "../../../store/reducers/reducerAuth";
import Button from "../../UI/Button/Button";
import { useNavigate } from "react-router-dom";
import { Button as UsertipButton } from "usertip-component-library";

const PasswordChangeFailed = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onClickHandler = () => {
    dispatch(abortChangePwd());
    navigate("/");
  };

  return (
    <div className="m-auto w-full flex flex-col items-center justify-center gap-4">
      <h2 className="text-heading-m text-center text-default-neutral-100">
        Change password failed, please try again or{" "}
        <a
          target="_blank"
          className="text-default-primary hover:no-underline hover:text-default-primary-light"
          href="mailto:support@usertip.com"
        >
          contact support
        </a>
      </h2>

      <div className="w-28">
        <UsertipButton
          color="primary"
          size="fluid"
          text="Confirm"
          variant="primary"
          onClick={onClickHandler}
        />
      </div>
    </div>
  );
};

export default PasswordChangeFailed;
