import React from "react";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";

interface Props {
  className?: string | undefined;
  onChange: (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    value: string
  ) => void;
  children: React.ReactNode;
  size: "small" | "medium" | "large";
}

const GroupButtonContainer = (props: Props) => {
  return (
    <ToggleButtonGroup
      size={props.size}
      exclusive={true}
      aria-label="Large sizes"
      onChange={props.onChange}
    >
      {props.children}
    </ToggleButtonGroup>
  );
};

export default GroupButtonContainer;
