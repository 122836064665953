import React, { useState } from "react";

import { Button } from "@mui/material";
import { Button as UsertipButton } from "usertip-component-library";

import AddBoxIcon from "@mui/icons-material/AddBox";

import ComponentAddWalkthroughsPermissions from "./ComponentAddWalkthroughsPermissions";
import ComponentListWalkthroughViewerTeamsPermissionsInTable from "./ComponentListWalkthroughViewerTeamsPermissionsInTable";
import TableWalkthroughsViewingPermissionTeams from "./Table/TableWalkthroughsViewingPermissionTeams";

const ComponentListWalkthroughViewerTeamsPermissions = ({
  viewerTeamType,

  introMsg,

  dataSet,
  permissionDataSet,

  indicatorMsg,

  walkthroughID,
  orgID,

  onDeleteOneTeamPermissions,
  onBulkUpdateViewerTeamPermission,

  onGroupBulkUpdateViewerTeamPermission,
}) => {
  const [showModalAdd, setShowModalAdd] = useState(false);

  const stateName = `Viewer Teams Permissions`;

  const handleAddViewerTeamsPermissionsClick = () => {
    setShowModalAdd(true);
  };
  console.log(dataSet);
  console.log(permissionDataSet);

  return (
    <div className="pageSettings">
      <div className="pageSettingsHeader">
        Manage {viewerTeamType} {stateName}
      </div>

      <div className="pageSettingsSubHeader">{introMsg}</div>

      {
        // If dataSet is not empty, Display table with data, else Display IndicatorMsg
        permissionDataSet && permissionDataSet.length > 0 ? (
          <>
            {/* <ComponentListWalkthroughViewerTeamsPermissionsInTable
              viewerTeamType={viewerTeamType}
              dataSet={dataSet} // Walkthrough Viewers List
              permissionDataSet={permissionDataSet} // Walkthrough Viewers Team Permissions List
              orgID={orgID}
              walkthroughID={walkthroughID}
              // onDeleteOneTeamPermissions={
              //     viewerTeamType === "Individual" ? onDeleteOneTeamPermissions :
              //     viewerTeamType === "Group" ? onGroupBulkUpdateViewerTeamPermission : ""
              // }
              onDeleteOneTeamPermissions={onDeleteOneTeamPermissions}
              onHandleBulkEditTeamWalkthroughPermission={
                viewerTeamType === "Individual"
                  ? onBulkUpdateViewerTeamPermission
                  : viewerTeamType === "Group"
                  ? onGroupBulkUpdateViewerTeamPermission
                  : ""
              }
            /> */}
            <TableWalkthroughsViewingPermissionTeams
              theDataSet={permissionDataSet}
              editData={dataSet}
              editPermissionData={permissionDataSet}
              walkthroughID={walkthroughID}
              onHandleBulkEditTeamWalkthroughPermission={
                viewerTeamType === "Individual"
                  ? onBulkUpdateViewerTeamPermission
                  : viewerTeamType === "Group"
                  ? onGroupBulkUpdateViewerTeamPermission
                  : ""
              }
            />
          </>
        ) : (
          <>
            <div className="pageIndicatorMsg">{indicatorMsg}</div>

            <div className="pageAddBtnBar flex justify-center -mt-6">
              {/* <Button
                className="pageAddBtn"
                variant={"outlined"}
                aria-label={`Add New ${stateName}`}
                startIcon={<AddBoxIcon />}
                onClick={() => handleAddViewerTeamsPermissionsClick()}
                disableElevation
              >
                Add New {stateName}
              </Button> */}
              <UsertipButton
                variant="outlined"
                color="primary"
                onClick={() => handleAddViewerTeamsPermissionsClick()}
                icon={<AddBoxIcon fontSize="small" />}
                iconLeft
                text={`Add New ${stateName}`}
              />
            </div>
          </>
        )
      }

      <div className="pageDivider" />

      <ComponentAddWalkthroughsPermissions
        dataSet={dataSet}
        walkthroughID={walkthroughID}
        showModalAdd={showModalAdd}
        setShowModalAdd={setShowModalAdd}
        handleViewerTeamFormSubmission={
          viewerTeamType === "Individual"
            ? onBulkUpdateViewerTeamPermission
            : viewerTeamType === "Group"
            ? onGroupBulkUpdateViewerTeamPermission
            : ""
        }
        addState={stateName}
      />
    </div>
  );
};

export default ComponentListWalkthroughViewerTeamsPermissions;
