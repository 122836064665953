import { Box } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import {
  AdditionalStatData,
  StepProcDataSet,
} from "../../../../interfaces/interfaceAnalytic";
import { pieStepChart } from "../../../_chartGenerator/pieStepChart";
import * as d3 from "d3";

const ComponentDashboard = ({
  dashboardData,
  stepDataSet,
  additionalData,
}: {
  dashboardData: string;
  stepDataSet: StepProcDataSet[];
  additionalData: AdditionalStatData;
}) => {
  const [selectedDataSet, setSelectedDataSet] =
    useState<null | StepProcDataSet>(null);

  const divRef = useRef<HTMLDivElement>(null);
  const elRef = useRef<HTMLDivElement>(null);

  /* selecting which data step being used */
  useEffect(() => {
    /* get index procDataSet by find last string on dashboard data - 1 */
    const getIndex = +dashboardData.slice(-1) - 1;

    setSelectedDataSet(stepDataSet[getIndex]);
  }, [dashboardData]);

  const convertTime = (time: number) => {
    let seconds = Math.floor(time / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);
    let days = Math.floor(hours / 24);
    seconds = seconds % 60;
    minutes = minutes % 60;
    hours = hours % 24;

    // const hours = parseInt(splitHourMinuteSeconds[0]);
    // const minutes = parseInt(splitHourMinuteSeconds[1]);
    // const seconds = parseInt(splitHourMinuteSeconds[2]);

    let timeString = "";
    if (days > 0) {
      timeString += `${days}d`;
    }
    if (hours > 0) {
      timeString += `${hours}h`;
    }
    if (minutes > 0) {
      timeString += `${minutes}m`;
    }
    if (!seconds && seconds >= 0) {
      timeString += `${seconds}s`;
    }

    return timeString;
  };

  /* adding the pie chart */
  useEffect(() => {
    if (selectedDataSet) {
      const chart = pieStepChart(selectedDataSet);
      const selectChart = d3.select(".dashboard_chart");
      divRef.current?.append(chart);

      //remove and create new chart everytime reload
      return () => {
        selectChart &&
          Object.keys(selectChart).length !== 0 &&
          selectChart.selectAll("*").remove();
      };
    }
  });

  useEffect(() => {
    // When the chart ger rendered scroll into elRef
    if (divRef) {
      elRef.current?.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  });
  return (
    <>
      <h3 className="pageSettingsHeader font-bold mt-5">
        Overview for - [{dashboardData}]
      </h3>

      <div className="flex items-center justify-center text-center" ref={elRef}>
        {/* chart here */}
        <div
          className="chart dashboard_chart w-1/2 text-right"
          ref={divRef}
        ></div>
        {/* overview here */}
        {selectedDataSet && (
          <div className="overview-detail text-center page-analytic-subHeader w-1/2">
            <p className="font-bold mb-2 step text-left text-zinc-700">
              Total Launched: {selectedDataSet.seen} times
            </p>
            <div className="mb-2 flex gap-2  items-center">
              <span className="text-[#9179F4]">
                <Box
                  className="border-2 justify-center border-zinc-900 mx-0.5"
                  sx={{
                    backgroundColor: "#9179F4",
                    width: "1.2em",
                    height: "1.2em",
                  }}
                />
              </span>{" "}
              {selectedDataSet.completed} Completed
            </div>
            <div className="mb-2 flex gap-2 items-center">
              <span className="text-[#CCCCD5]">
                <Box
                  className="border-2 border-zinc-900 mx-0.5"
                  sx={{
                    backgroundColor: "#CCCCD5",
                    width: "1.2em",
                    height: "1.2em",
                  }}
                />
              </span>{" "}
              {selectedDataSet.incomplete} Incomplete
            </div>
            <div className="mb-2 flex gap-2 items-center">
              <span className="text-[#FB8C00]">
                <Box
                  className="border-2 border-zinc-900 mx-0.5"
                  sx={{
                    backgroundColor: "#FB8C00",
                    width: "1.2em",
                    height: "1.2em",
                  }}
                />
              </span>{" "}
              {selectedDataSet.dismissed} Dismissed
            </div>
            <p className="font-bold mb-2 step text-left text-zinc-700">
              Additional Stats
            </p>
            <section className="additional_stats text-left">
              <div>
                Average Times to Finish:{" "}
                {convertTime(additionalData.averageTimeFinish)}
              </div>
              <div>Median Times: {convertTime(additionalData.medianTimes)}</div>
              <div>
                Longest Completion Times:{" "}
                {convertTime(additionalData.longestCompTimes)}.
              </div>
              <div>
                Shortest Completion Times:{" "}
                {convertTime(additionalData.shortestCompTimes)}.
              </div>
              <div>
                Drop off from previous step: {additionalData.dropOffRate} times,
                ({additionalData.dropOffPercentage})
              </div>
            </section>
          </div>
        )}
      </div>
    </>
  );
};

export default ComponentDashboard;
