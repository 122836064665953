import React, { Component, useCallback, useEffect, useState } from "react";
import {
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation,
  BrowserRouter,
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { createTheme } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";

import PageNotFound from "./pages/PageNotFound";

import PageLogin from "./pages/PageLogin";
import PageLoginNotVerified from "./pages/PageLoginNotVerified";
import PageRegistration from "./pages/PageRegistration";
import PageRegistrationCompleted from "./pages/PageRegistrationCompleted";
import PageRegistrationByInvite from "./pages/PageRegistrationByInvite";

import PageDashboard from "./pages/PageDashboard";

import PageAccountSettings from "./pages/PageAccountSettings";
import PageBillingSettings from "./pages/PageBillingSettings";

// import PageWalkthroughs from "./pages/PageWalkthroughs";
import PageWalkthroughSettings from "./pages/PageWalkthroughSettings";
import PageWalkthroughViewingPermissions from "./pages/PageWalkthroughViewingPermissions";

import PageGroupWalkthroughs from "./pages/PageGroupWalkthroughs";
import PageGroupWalkthroughsList from "./pages/PageGroupWalkthroughsList";
import PageGroupWalkthroughSettings from "./pages/PageGroupWalkthroughSettings";
import PageGroupWalkthroughViewingPermissions from "./pages/PageGroupWalkthroughViewingPermissions";
import PageGroupWalkthroughsViewingPermissions from "./pages/PageGroupWalkthroughsViewingPermissions";

import PageAssistantSettings from "./pages/PageAssistantSettings";

// import PageWalkthroughAnalytics from "./pages/PageWalkthroughAnalytics/PageWalkthroughAnalytics";

// import PageDetailedAnalytics from "./pages/PageDetailedAnalytics/PageDetailedAnalytics";

import PageOrganizationAdministrator from "./pages/PageOrganizationAdministrator";
import PageOrganizationBuilder from "./pages/PageOrganizationBuilder";
import PageOrganizationViewer from "./pages/PageOrganizationViewer";
import PageTeamViewer from "./pages/PageTeamViewer";

// import { useTheme } from "@mui/styles";

/**@ts-ignore */
import resolveConfig from "tailwindcss/resolveConfig";
import tailwindConfigModule from "./tailwind-mui-integration.config.js";
import {
  onAuthCheck,
  getUserPermissionAccess,
} from "./store/reducers/reducerAuth";
import {
  getWalkthroughs,
  getWalkthroughsSummary,
} from "./store/reducers/reducerWalkthrough";
import {
  organizationGetAccses,
  organizationGetGroupedOrgAndWalkthroughs,
  organizationGetOrganizationInfo,
  organizationGetSharedHostWalkthrough,
} from "./store/reducers/reducerOrganization";
import PageSharedWalkthrough from "./pages/PageSharedWalkthrough";
import PageSharedWalkthroughEditList from "./pages/PageSharedWalkthroughEditList";
import PageHostWalkthrough from "./pages/PageHostWalkthrough";
import PageInstallationGuide from "./pages/PageInstallationGuide";
import BarChart from "./components/Chart/BarChart";
import PageOrganizationCodeSnippetViewer from "./pages/PageOrganizationCodeSnippetViewer";
import PageAnalyticMetaData from "./pages/PageAnalyticMetaData";
import PageDetailedAnalytic from "./pages/PageDetailedAnalytic";
import PageAccountChangePassword from "./pages/PageAccountChangePassword";
import PageForgotPassword from "./pages/PageForgotPassword";
import PageUserSegmentation from "./pages/PageUserSegmentation";
import PageAddSegmentation from "./pages/PageAddSegmentation";
import PageUpdateUserSegmentation from "./pages/PageUpdateUserSegmentation";
import PageMediaManagerImage from "./pages/PageMediaManagerImage";
import ComponentPayment from "./components/Payment/ComponentPayment";
import PageOnBoarding from "./pages/PageOnBoarding";
import PageInviteUserToDIffOrg from "./pages/PageInviteUserToDIffOrg";
import PageUserCreateNewOrg from "./pages/PageUserCreateNewOrg";
import PageCheckOut from "./pages/PageCheckOut";
import { EXTENTION_LAUNCHER_ID, EXTENTION_BUILDER_ID } from "./constants";
import { RootState } from "./store";
import ProtectedRoute, {
  isUserAllowedAccess,
} from "./components/wrappers/ProtectedRoute";
import PageRegisterCreateNewOrg from "./pages/PageRegisterCreateNewOrg";
import Test from "./pages/Test";
import PageGuidesOverview from "./pages/PageGuidesOverview";
import PageVideoWalkthrough from "./pages/PageVideoWalkthrough";

/** GTM global variables */
import TagManager from "./utility/functions/GA4TagManager";
import PageSnapGuide from "./pages/PageSnapGuide";

import PageOrganization from "./pages/PageOrganization";
import PageGuidePermissions from "./pages/PageGuidePermissions";
import PageAllGuide from "./pages/PageAllGuide";
import PageOnBoardingInvitation from "./pages/PageOnBoardingInvitation";
import PageThemeEditor from "./pages/PageThemeEditor";
import PagePublicSnapGuide from "./pages/PagePublicSnapGuide";
import PageSharePrivateSnap from "./pages/PageSharePrivateSnap";
import PageAllSnapGuide from "./pages/PageAllSnapGuide";
import PageBilling from "./pages/PageBilling";
import PageTrialOver from "./pages/PageTrialOver";
import PageRenewSubscription from "./pages/PageRenewSubscription";
import EmbedPagePricingTable from "./pages/EmbedPagePricingTable";
import PageWalkthroughs from "./pages/PageWalkthroughs";

/** Plugin to use tailwindConfig with MUI */
// @ts-ignore
const tailwindConfig = resolveConfig(tailwindConfigModule);

//
/**
 * DO NOT DELETE PATH CONSTANTS BELOW
 *
 * Constants are used to ensure no path changes affect code that uses the path
 */
// Constant for embed pricing page
export const EMBED_PRICING_PAGE_PATH = "/usertip-embed-marketing-pricing-table";
// Constant for page when user is still finishing registration and onboarding
export const ONBOARDING_FLOW_PAGE_PATH = "/onboarding/invite";

const App = () => {
  /** Setup MUI to use our tailwind colors */
  const theme = createTheme({
    palette: {
      primary: {
        // @ts-ignore
        main: tailwindConfig.theme.colors.default.primary,
      },
      secondary: {
        // @ts-ignore
        main: tailwindConfig.theme.colors.default.secondary,
      },
      error: {
        // @ts-ignore
        main: tailwindConfig.theme.colors.default.error,
      },
      warning: {
        // @ts-ignore
        main: tailwindConfig.theme.colors.default.warning,
      },
      info: {
        // @ts-ignore
        main: tailwindConfig.theme.colors.default.info,
      },
      success: {
        // @ts-ignore
        main: tailwindConfig.theme.colors.default.success,
      },
      grey: {
        /**@ts-ignore */
        main: tailwindConfig.theme.colors.default.neutral,
      },
    },
  });

  // const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

  console.log();

  // state from/ for redux
  const { userData } = useSelector((state: RootState) => state.user);

  /** START OF FYP ANALYTIPS CODE */
  useEffect(() => {
    console.log("userData:", userData);
    // Check if userData is not null before sending the request
    if (userData.email !== "") {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Add any other headers if required
        },
        body: JSON.stringify(userData),
      };

      // fetch(
      //   "https://hook.eu2.make.com/ws0mduhfmvhthsr4jq5rppax18moq582",
      //   requestOptions
      // )
      //   .then((response) => {
      //     if (!response.ok) {
      //       throw new Error("Network response was not ok");
      //     }
      //     return response.json();
      //   })
      //   .then((data) => {
      //     console.log("UserData sent successfully:", data);
      //   })
      //   .catch((error) => {
      //     console.error("There was a problem sending userdata:", error);
      //     console.log(requestOptions.body);
      //   });
    }
  }, [userData]);

  /** END OF FYP ANALYTIPS CODE */

  const { isAuthenticated, isRegistering } = useSelector(
    (state: RootState) => state.auth
  );

  /** Constant to check if we need to wait for user auth check to finish loading */
  const isAuthVerificationLoading = useSelector(
    (state: RootState) => state.auth.appLoading
  );

  /** <--- GTM states ---> */
  let isGTMDataSet = false;

  /**
   * Summary:
   * 1. If user is logged in, initialize GTM with cognito_id
   * 2. If user is not logged in, initialize GTM (DEFAULT)
   *
   * How it works:
   * 1. On a page that is suppose to be logged in, userData useEffect will trigger TWICE
   *    - First time, userData is null
   *    - Second time, userData is not null
   *    Hence, we delay the DEFAULT initialization of GTM by 100ms
   *    to allow the initialize GTM with cognito_id to trigger first
   *
   * 2. On a page that is suppose to be logged out, userData useEffect will trigger ONCE
   *    - userData is null
   *    Initialize GTM with cognito_id will not be triggered,
   *    and 100ms will lapse to trigger DEFAULT initialization of GTM
   *
   * Better solution (if possible):
   * Solution 1: On a page that is suppose to be logged in, userData useEffect will trigger ONCE
   * Solution 2: Hardcode DEFAULT initialization to trigger for the pages that are suppose to be logged out
   */
  useEffect(() => {
    const cognito_id = userData.cognito_id;
    if (typeof window !== undefined) {
      // console.log("init GTM");
      /** If data layer is not added, add data layer */
      if (!isGTMDataSet) {
        /** If user is logged in, initialize GTM with cognito_id  */
        if (cognito_id) {
          const tagManagerArgs = {
            dataLayer: {
              user_cognito_id: cognito_id,
            },
            user_cognito_id: cognito_id,
          };
          TagManager.initialize(tagManagerArgs);
        }

        /** If user is not logged in, initialize GTM */
        let delayInitialize = async () => {
          setTimeout(() => {
            TagManager.initialize({});
          }, 100);
        };
        delayInitialize();

        isGTMDataSet = true;
      }
    }
  }, [userData]);

  /** <--- GTM states END ---> */

  const org = useSelector((state: RootState) => state.org);

  /** State to check if user is onboarded */
  const isUserOnboarded = userData.is_onboarded;

  /** List of limit of access for features */
  const org_feature_plan = useSelector(
    (state: RootState) => state.org.org_plan?.org_feature_plan
  );

  const { viewer_team, basic_analytic, guest_organization, user_segmentation } =
    org_feature_plan
      ? org_feature_plan
      : {
          viewer_team: null,
          basic_analytic: null,
          guest_organization: null,
          user_segmentation: null,
        };

  //ut auth status from local storage
  const localStorageAuth = localStorage.getItem("auth");
  const localStorageUser = JSON.parse(localStorage.getItem("ut-user")!);
  const dispatch = useDispatch();
  // const [mobileOpen, setMobileOpen] = useState(false);
  const [preloadDataOnLogin, setPreloadDataOnLogin] = useState("waiting");

  /**Check if url from extension login */
  const checkUrlSearch = location.search;

  /**save to local storage just in case user is already logged */
  useEffect(() => {
    if (checkUrlSearch === "?extension-launcher=true") {
      localStorage.setItem("ut-loginExtensionLauncher", "true");
    }

    if (checkUrlSearch === "?extension-builder=true") {
      localStorage.setItem("ut-loginExtensionBuilder", "true");
    }
  }, [checkUrlSearch]);

  const [disableSideBar, setDisableSideBar] = useState(false);

  // Initialize app.
  const initApp = useCallback(
    (user) => {
      if (user.cognito_id && user.cognito_id.length > 0) {
        dispatch(getUserPermissionAccess(user.org));
        dispatch(getWalkthroughs(user.cognito_id));
        // dispatch(getWalkthroughsSummary(user.cognito_id));

        /** INITIALIZE USERTIP CODE SNIPPET HERE **/
        let config = {};
        // add additional meta data of visitor to record
        let visitorMetaData = {
          unique_id: user.cognito_id,
          email: user.email,
          //visitor unique id, must be unique in client db
          //user email
          //user role or title
          //first name
          //last name
          //department
        };

        // if the page is not on embed pricing page, initialize Usertip
        //@ts-ignore
        if (window.Usertip) {
          /**@ts-ignore */
          window.Usertip.init(config, visitorMetaData);
        }
        /** END INITIALIZE USERTIP CODE SNIPPET HERE **/

        if (!org.name) {
          // console.log(user.org);
          if (user.org && user.org.length > 0) {
            // console.log('fetching org info')
            dispatch(getUserPermissionAccess(user.org));
            dispatch(organizationGetOrganizationInfo(user.org));
          } else {
            console.log("user does not belong to an org");
          }
        }
      }
    },
    [dispatch, org]
  );

  // Initialize app with content if user is authenticated.
  useEffect(() => {
    if (isAuthenticated && userData && preloadDataOnLogin === "waiting") {
      // Only preload initial data Once.
      setPreloadDataOnLogin("run");
    }
  }, [userData, isAuthenticated, preloadDataOnLogin]);

  useEffect(() => {
    if (isAuthenticated && userData && preloadDataOnLogin === "run") {
      // Call initialize app method.
      onAuthCheck();
      if (userData.cognito_id && userData.cognito_id.length > 0) {
        initApp(userData);
        setPreloadDataOnLogin("done");
      }
    }
  }, [userData, isAuthenticated, preloadDataOnLogin, initApp]);

  const newUser = localStorage.getItem("ut-newUser");

  // Check authentication if not set and show dashboard if registered.
  useEffect(() => {
    // console.log(`app.js: isauthenticated: ${isAuthenticated}`);
    // console.log(`app.js: isregistering: ${isRegistering}`);
    // console.log(`app.js: localStorageAuth: ${localStorageAuth}`);
    // console.log(`app.js: USER: ${JSON.stringify(user)}`)
    if (
      isAuthenticated &&
      !isAuthVerificationLoading &&
      location.pathname === "/" &&
      !location.search &&
      userData.role !== "viewer"
    ) {
      navigate("/guides/all");
    }
    // Redirect to "/registration/completed" if user is registering & is successful.
    else if (!isAuthenticated && isRegistering === "success") {
      navigate("/registration/completed");
    } else if (!isAuthenticated && isRegistering === "notverified") {
      navigate("/login/notverified");
    } else if (!isAuthenticated && !newUser) {
      // Check authentication on app load, just in case it is null
      onAuthCheck();
      // navigate("/");
    }
  }, [
    isAuthenticated,
    isAuthVerificationLoading,
    userData,
    location.pathname,
    isRegistering,
    navigate,
  ]);

  // check if user is host/guest
  useEffect(() => {
    if (isAuthenticated && !isAuthVerificationLoading && org.org_id) {
      dispatch(organizationGetAccses(org.org_id));
    }
  }, [isAuthenticated, isAuthVerificationLoading, org.org_id, dispatch]);

  useEffect(() => {
    // function to check if the page should have sidebar or not
    function checkUrlList() {
      // list of pages that should not have sidebar
      const pageChangePwdURL = "/account/settings/change-password";
      const pageInviteUserAsViewerURL = "/inviteToNewOrgAsViewer";
      const pageInviteUserAsBuilderAdmin = "/inviteToNewOrgAsAdminOrBuilder";
      const pageInviteUserAsAdminToDiffURL =
        "/inviteToDifferentOrgAsAdminOrBuilder";
      const pageUserCreateNewOrgURL = "/userCreateNewOrg";
      const pageCheckOut = "/checkout";

      const currentPageURL = window.location.pathname;

      return [
        pageChangePwdURL,
        pageInviteUserAsViewerURL,
        pageInviteUserAsAdminToDiffURL,
        pageInviteUserAsBuilderAdmin,
        pageUserCreateNewOrgURL,
        pageCheckOut,
      ].includes(currentPageURL);
    }

    const checkUrlWithParams = (match: string) => {
      const currentPageURL = window.location.pathname;

      return currentPageURL.startsWith(match);
    };

    //disable side bar when url match with list above
    if (checkUrlList() || checkUrlWithParams("/invite/join/")) {
      setDisableSideBar(true);
    } else {
      setDisableSideBar(false);
    }
  }, [window.location.pathname]);

  const paymentTier = localStorage.getItem("ut-paymentTier");
  /**Redirect to onBoarding page if the user */
  useEffect(() => {
    if (isUserOnboarded !== null) {
      if (
        isAuthenticated &&
        !isAuthVerificationLoading &&
        !isUserOnboarded &&
        paymentTier === "free1"
      ) {
        if (window.location.pathname !== "/onboarding") {
          navigate(ONBOARDING_FLOW_PAGE_PATH);
        }
      }
    }
  }, [
    isAuthenticated,
    isAuthVerificationLoading,
    paymentTier,
    isUserOnboarded,
  ]);

  // /**Redirect to payment page if user choose paid payment tier */
  // useEffect(() => {
  //   const paymentTier = localStorage.getItem("ut-paymentTier");
  //   if (
  //     isAuthenticated &&
  //     paymentTier !== "free" &&
  //     paymentTier !== undefined
  //   ) {
  //     navigate("/checkout");
  //   }
  // }, [isAuthenticated]);

  // use effect for redirecting to register to different org if user previously not logged in
  useEffect(() => {
    if (localStorage.getItem("ut_inviteToken")) {
      const inviteToken = localStorage.getItem("ut_inviteToken");
      const inviteIdentifier = localStorage.getItem("ut_identifier");
      navigate(`/invite/join/${inviteIdentifier}/${inviteToken}`);
    }
    return () => {
      localStorage.removeItem("ut_inviteToken");
      localStorage.removeItem("ut_identifier");
    };
  }, []);

  //viewer user always redirect to /account/settings/
  useEffect(() => {
    if (
      isAuthenticated &&
      !isAuthVerificationLoading &&
      location.pathname === "/" &&
      userData.role === "viewer"
    ) {
      navigate("/account/settings/");
    }
  }, [isAuthenticated, isAuthVerificationLoading, location.pathname]);

  /**use efffect for checking if user already on boarding */
  useEffect(() => {
    if (
      userData.is_onboarded === false &&
      userData.role !== "viewer" &&
      isAuthenticated &&
      !isAuthVerificationLoading
    ) {
      if (window.location.pathname !== "/onboarding") {
        navigate(ONBOARDING_FLOW_PAGE_PATH);
      }
    }
  }, [userData, isAuthVerificationLoading, isAuthenticated]);

  /**useEffect for redirect after succesfully login to walkthrough */
  useEffect(() => {
    if (
      isAuthenticated &&
      !isAuthVerificationLoading &&
      (location.pathname === "/login" || location.pathname === "/registration")
    ) {
      navigate("/");
    }
  }, [isAuthenticated, location.pathname]);

  /**Get item for launcherLogin */
  const loginFromLauncherExtension = localStorage.getItem(
    "ut-loginExtensionLauncher"
  );

  const loginFromBuilderExtension = localStorage.getItem(
    "ut-loginExtensionBuilder"
  );

  /**Login from launcher extension */
  useEffect(() => {
    /**Prepare auth data for login */
    const extensionDataAuth = {
      IdToken: localStorage.getItem("ut-token"),
      ExpiresIn: localStorage.getItem("ut-expires"),
      AccessToken: localStorage.getItem("ut-accsessToken"),
      RefreshToken: localStorage.getItem("ut-refresh-token"),
      "SignIn-Type": localStorage.getItem("ut-signInType")
        ? localStorage.getItem("ut-signInType")
        : "AWS",
    };
    /**send auth data to launcher */
    if (
      isAuthenticated &&
      !isAuthVerificationLoading &&
      localStorageAuth &&
      loginFromLauncherExtension === "true" &&
      window.chrome &&
      window.chrome.runtime
    ) {
      chrome.runtime.sendMessage(
        process.env.REACT_APP_LAUNCHER_EXTENSION_ID,
        {
          msg: "SENDING_AUTH_FROM_WEB_APP",
          user: localStorageUser,
          auth: extensionDataAuth,
        },
        (response) => {
          console.log("Response from launcher extension: ", response);
          console.log("Launcher login succesfully");
          //remove the item from local storage
          localStorage.removeItem("ut-loginExtensionLauncher");
        }
      );
    }

    /**login from builder extension */
    if (
      isAuthenticated &&
      !isAuthVerificationLoading &&
      localStorageAuth === "true" &&
      loginFromBuilderExtension === "true" &&
      userData.role &&
      userData.role !== "builder" &&
      window.chrome &&
      window.chrome.runtime
    ) {
      chrome.runtime.sendMessage(
        process.env.REACT_APP_BUILDER_EXTENSION_ID,
        {
          msg: "SENDING_AUTH_FROM_WEB_APP",
          user: localStorageUser,
          auth: extensionDataAuth,
        },
        (response) => {
          console.log("Response from builder extension: ", response);
          console.log("Builder login succesfully");
          //remove the item from local storage
          localStorage.removeItem("ut-loginExtensionBuilder");
        }
      );
    }

    /**Send auth data to builder */
  }, [
    isAuthenticated,
    isAuthVerificationLoading,
    loginFromLauncherExtension,
    checkUrlSearch,
    loginFromBuilderExtension,
    userData,
  ]);

  /**Check if storage has ut-google-register-user */
  const googleRegisterUser = localStorage.getItem("ut-google-register-user");

  /**This is route handler for payment */
  const { paymentStep, temporaryAuth } = useSelector(
    (state: RootState) => state.payment
  );

  const { can_trial, paid_status } = useSelector(
    (state: RootState) => state.org
  );

  //TODO: tmrw test other url
  useEffect(() => {
    // if (paymentStep === "trial") {
    //   navigate("/new-subscription");
    // }
    // if (paymentStep === "otherPlan") {
    //   navigate("/renew-subscribe");
    // }
    if (!can_trial && paid_status === "trialing") {
      // navigate("/trial-expired");
    }
  }, [can_trial, paid_status]);

  return (
    <ThemeProvider theme={theme}>
      <div className="flex">
        <Routes>
          {/* PRICING TABLE EMBED */}
          <Route
            path={EMBED_PRICING_PAGE_PATH}
            element={<EmbedPagePricingTable />}
          />

          {/* Route for testing page */}
          <Route
            path="/getting-started/create-new-walkthrough"
            element={
              <ProtectedRoute
                localStorageAuth={localStorageAuth}
                user={userData}
                location={location}
              >
                <h1>How to create new walkthrough</h1>
              </ProtectedRoute>
            }
          />
          <Route
            path="/getting-started/invite-member-to-org"
            element={
              <ProtectedRoute
                localStorageAuth={localStorageAuth}
                user={userData}
                location={location}
              >
                <h1>How to invite to new org</h1>
              </ProtectedRoute>
            }
          />
          <Route path="/test" element={<Test />} />
          {/** PROTECTED ROUTES START */}
          <Route
            path="/"
            element={
              <ProtectedRoute
                localStorageAuth={localStorageAuth}
                user={userData}
                location={location}
              >
                <PageWalkthroughs />
              </ProtectedRoute>
            }
          />
          <Route
            path={"/account/settings/change-password"}
            element={
              <ProtectedRoute
                localStorageAuth={localStorageAuth}
                user={userData}
                location={location}
              >
                <PageAccountChangePassword />
              </ProtectedRoute>
            }
          />
          {/* Route for inviting user as viewer to new org */}
          {isAuthenticated && !isAuthVerificationLoading && (
            <Route
              path={"/invite/join/:identifier/:token"}
              element={
                <ProtectedRoute
                  localStorageAuth={localStorageAuth}
                  user={userData}
                  location={location}
                >
                  <PageInviteUserToDIffOrg isLoggedIn={true} />
                </ProtectedRoute>
              }
            />
          )}
          {/* Route for guide overview */}
          {isAuthenticated && !isAuthVerificationLoading && (
            <>
              <Route
                path={"/guides/all/:guideID"}
                element={
                  <ProtectedRoute
                    localStorageAuth={localStorageAuth}
                    user={userData}
                    location={location}
                  >
                    <PageGuidesOverview />
                  </ProtectedRoute>
                }
              />
              {/* Route for picture guide  */}
              <Route
                //TODO: change walkthrough id to guide id later
                path={"/guides/walkthroughs/:walkthroughID/settings/general"}
                element={
                  <ProtectedRoute
                    localStorageAuth={localStorageAuth}
                    user={userData}
                    location={location}
                  >
                    <PageWalkthroughSettings />
                  </ProtectedRoute>
                }
              />
              {/* Route for video guide  */}
              {/**
                 <Route
                //TODO: change walkthrough id to guide id later
                path={"/guides/videoguide/:videoId"}
                element={
                  <ProtectedRoute
                    localStorageAuth={localStorageAuth}
                    user={userData}
                    location={location}
                  >
                    <PageVideoWalkthrough />
                  </ProtectedRoute>
                }
              />
                 */}

              {/* TODO: Pages for list all SNAP GUIDES  */}
              <Route
                path={"/guides/snaps"}
                element={
                  <ProtectedRoute
                    localStorageAuth={localStorageAuth}
                    user={userData}
                    location={location}
                  >
                    <PageAllSnapGuide />
                  </ProtectedRoute>
                }
              />

              {/* Route for picture guide  */}
              <Route
                path={"/guides/snaps/:snapID"}
                element={
                  <ProtectedRoute
                    localStorageAuth={localStorageAuth}
                    user={userData}
                    location={location}
                  >
                    <PageSnapGuide />
                  </ProtectedRoute>
                }
              />
              <Route
                path={"/guides/snaps/:snapID/edit"}
                element={
                  <ProtectedRoute
                    localStorageAuth={localStorageAuth}
                    user={userData}
                    location={location}
                  >
                    <PageSnapGuide />
                  </ProtectedRoute>
                }
              />
              {/* Route for public snap share  */}
              {/* // https://app.usertip.com/quickBuilder/share/:publicId/:snapId */}
              <Route
                path={"/guides/share/:shareID/:snapID"}
                element={
                  <ProtectedRoute
                    localStorageAuth={localStorageAuth}
                    user={userData}
                    location={location}
                  >
                    <PageSnapGuide />
                  </ProtectedRoute>
                }
              />
            </>
          )}

          {/* <Route
              path="/inviteToNewOrgAsAdminOrBuilder"
              element={<PageInviteUserToBuilderAdmin />}
            /> */}
          {/* Route for inviting existing user to different org as admin/builder */}
          {/* <Route
              path="/inviteToDifferentOrgAsAdminOrBuilder"
              element={<PageInviteUserToDifferentOrgAsAdmin />}
            /> */}
          {/* Route for user without organization to create new one */}
          {/* TODO: only allowed when user doesnt have org */}
          {googleRegisterUser && (
            <Route
              path="/new-register-create-new-org"
              element={<PageRegisterCreateNewOrg />}
            />
          )}
          <Route
            path="/userCreateNewOrg"
            element={
              <ProtectedRoute
                localStorageAuth={localStorageAuth}
                user={userData}
                location={location}
              >
                <PageUserCreateNewOrg />
              </ProtectedRoute>
            }
          />
          <Route
            path="/checkout"
            element={
              <ProtectedRoute
                localStorageAuth={localStorageAuth}
                user={userData}
                location={location}
              >
                <PageCheckOut />
              </ProtectedRoute>
            }
          />

          {userData.role === "viewer" && (
            <>
              <Route
                /**@ts-ignore */
                exact
                path="/"
                render={() => <Navigate to="/account/settings" />}
              />
              <Route
                path={"/invite/join/:identifier/:token"}
                element={
                  <ProtectedRoute
                    localStorageAuth={localStorageAuth}
                    user={userData}
                    location={location}
                  >
                    <PageInviteUserToDIffOrg isLoggedIn={true} />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/account/settings"
                element={
                  <ProtectedRoute
                    localStorageAuth={localStorageAuth}
                    user={userData}
                    location={location}
                  >
                    <PageAccountSettings />
                  </ProtectedRoute>
                }
              />
              <Route path={"*"} element={<PageNotFound />} />
            </>
          )}

          {disableSideBar === false && userData.role !== "viewer" && (
            <>
              <Route
                /**@ts-ignore */
                exact
                path="/"
                /**@ts-ignore */
                element={
                  <ProtectedRoute
                    localStorageAuth={localStorageAuth}
                    user={userData}
                    location={location}
                  >
                    <PageWalkthroughs />
                  </ProtectedRoute>
                }
              />

              {/* ROUTES: WALKTHROUGHS */}
              <Route
                path="/guides/walkthroughs/:walkthroughID/settings/viewing-permissions"
                element={
                  <ProtectedRoute
                    localStorageAuth={localStorageAuth}
                    user={userData}
                    location={location}
                  >
                    <PageWalkthroughViewingPermissions />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/guides/walkthroughs/:walkthroughID/settings/general"
                element={
                  <ProtectedRoute
                    localStorageAuth={localStorageAuth}
                    user={userData}
                    location={location}
                  >
                    <PageWalkthroughSettings />
                  </ProtectedRoute>
                }
              />
              {/* If basic analytics feature is allowed, show Page */}
              {basic_analytic && (
                <Route
                  path="/guides/walkthroughs/:walkthroughID/analytics"
                  element={
                    <ProtectedRoute
                      localStorageAuth={localStorageAuth}
                      user={userData}
                      location={location}
                    >
                      <PageDetailedAnalytic />
                    </ProtectedRoute>
                  }
                />
              )}

              {/* TODO: WALKTHROUGH GROUPS NOT READ FOR PROD */}
              {/* <Route
                      path="/guides/walkthroughs/groups/:walkthroughID/settings/viewing-permissions"
                      element={<PageGroupWalkthroughViewingPermissions />}
                    />

                    <Route
                      path="/guides/walkthroughs/groups/:walkthroughID/settings/general"
                      element={<PageGroupWalkthroughSettings />}
                    />

                    <Route
                      path="/guides/walkthroughs/groups/:walkthroughID/viewing-permissions"
                      element={<PageGroupWalkthroughsViewingPermissions />}
                    />

                    <Route
                      path="/guides/walkthroughs/groups/:walkthroughID/list"
                      element={<PageGroupWalkthroughsList />}
                    />

                    <Route
                      path="/guides/walkthroughs/groups"
                      element={<PageGroupWalkthroughs />}
                    /> */}

              <Route
                path="/guides/walkthroughs"
                element={
                  <ProtectedRoute
                    localStorageAuth={localStorageAuth}
                    user={userData}
                    location={location}
                  >
                    <PageWalkthroughs />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/dashboard"
                element={
                  <ProtectedRoute
                    localStorageAuth={localStorageAuth}
                    user={userData}
                    location={location}
                  >
                    <PageDashboard />
                  </ProtectedRoute>
                }
              />

              {/* ROUTES: USER ACCOUNT */}
              <Route
                path="/account/settings"
                element={
                  <ProtectedRoute
                    localStorageAuth={localStorageAuth}
                    user={userData}
                    location={location}
                  >
                    <PageAccountSettings />
                  </ProtectedRoute>
                }
              />

              {/* ROUTES: USER ACCOUNT */}
              <Route
                path="/billing"
                element={
                  <ProtectedRoute
                    localStorageAuth={localStorageAuth}
                    user={userData}
                    location={location}
                  >
                    <PageBillingSettings />
                  </ProtectedRoute>
                }
              />
              <Route
                path={"/billing/subscription"}
                element={
                  <ProtectedRoute
                    localStorageAuth={localStorageAuth}
                    user={userData}
                    location={location}
                  >
                    <PageBilling />
                  </ProtectedRoute>
                }
              />

              {/* ROUTES: ORGANIZATION */}
              <Route
                path="/organization"
                element={
                  <ProtectedRoute
                    localStorageAuth={localStorageAuth}
                    user={userData}
                    location={location}
                  >
                    <PageOrganization />
                  </ProtectedRoute>
                }
              />

              {/* ROUTES Guest Organization walkthroughs*/}
              {/* Allow feature for guest_organization feature */}
              {guest_organization && org.isHost === true && (
                <>
                  <Route
                    path="/guest-organization/walkthrough-shared"
                    element={
                      <ProtectedRoute
                        localStorageAuth={localStorageAuth}
                        user={userData}
                        location={location}
                      >
                        <PageSharedWalkthrough />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/guest-organization/walkthrough-shared/:orgID/list"
                    element={
                      <ProtectedRoute
                        localStorageAuth={localStorageAuth}
                        user={userData}
                        location={location}
                      >
                        <PageSharedWalkthroughEditList />
                      </ProtectedRoute>
                    }
                  />
                </>
              )}

              {/* Route for host organization walkthrough */}
              {org.isGuest === true && (
                <Route
                  path="/guides/walkthroughs/host-walkthroughs"
                  element={
                    <ProtectedRoute
                      localStorageAuth={localStorageAuth}
                      user={userData}
                      location={location}
                    >
                      <PageHostWalkthrough />
                    </ProtectedRoute>
                  }
                />
              )}
              {/* ROUTES: TEAMS */}
              {/* TODO: rework purpose of team builders, estimated to be in 2023 */}
              {/* <Route
                      path="/teams/builders"
                      element={<PageTeamBuilder />}
                    /> */}
              {/* If viewer team feature is allowed, show viewer team page */}
              {viewer_team && (
                <Route
                  path="/teams/viewers"
                  element={
                    <ProtectedRoute
                      localStorageAuth={localStorageAuth}
                      user={userData}
                      location={location}
                    >
                      <PageTeamViewer />
                    </ProtectedRoute>
                  }
                />
              )}
              {/* Route: Segmentation */}
              {/* Feature limit for user_segmentation feature */}
              {user_segmentation && (
                <Route
                  path="/segmentation"
                  element={
                    <ProtectedRoute
                      localStorageAuth={localStorageAuth}
                      user={userData}
                      location={location}
                    >
                      <PageUserSegmentation />
                    </ProtectedRoute>
                  }
                />
              )}
              {/* Feature limit for user_segmentation feature */}
              {user_segmentation && (
                <Route
                  path="/segmentation/create"
                  element={
                    <ProtectedRoute
                      localStorageAuth={localStorageAuth}
                      user={userData}
                      location={location}
                    >
                      <PageAddSegmentation />
                    </ProtectedRoute>
                  }
                />
              )}
              {/* Feature limit for user_segmentation feature */}
              {user_segmentation && (
                <Route
                  path="/segmentation/:segmentationID/edit"
                  element={
                    <ProtectedRoute
                      localStorageAuth={localStorageAuth}
                      user={userData}
                      location={location}
                    >
                      <PageUpdateUserSegmentation />
                    </ProtectedRoute>
                  }
                />
              )}
              {/* Route: Media manager */}
              <Route
                /**@ts-ignore */
                exact
                path="/media-manager"
                render={() => <Navigate to="/media-manager/images" />}
              />
              <Route
                path="/media-manager/images"
                element={
                  <ProtectedRoute
                    localStorageAuth={localStorageAuth}
                    user={userData}
                    location={location}
                  >
                    <PageMediaManagerImage />
                  </ProtectedRoute>
                }
              />
              {/* ROUTES: Usertip Assistant */}
              <Route
                path="/assistant/settings"
                element={
                  <ProtectedRoute
                    localStorageAuth={localStorageAuth}
                    user={userData}
                    location={location}
                  >
                    <PageAssistantSettings />
                  </ProtectedRoute>
                }
              />
              {/* ROUTES Installation Guide */}
              <Route
                path="/code-snippet-installation/spa"
                element={
                  <ProtectedRoute
                    localStorageAuth={localStorageAuth}
                    user={userData}
                    location={location}
                  >
                    <PageInstallationGuide articleType="spa" />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/code-snippet-installation/mpa"
                element={
                  <ProtectedRoute
                    localStorageAuth={localStorageAuth}
                    user={userData}
                    location={location}
                  >
                    <PageInstallationGuide articleType="mpa" />
                  </ProtectedRoute>
                }
              />
              {/* ROUTE Analytics */}
              {/* <Route
                      path="/analytic/meta-data"
                      element={<PageAnalyticMetaData />}
                    />
                    <Route
                      path="/analytic/dashboard"
                      element={<p>Page in progress</p>}
                    /> */}
              {/* <Route path="/test-chart" element={<BarChart />} /> */}
              {/* Single detailed walkthrough analytics */}
              {/* Overall walkthrough analytics */}
              {/* <Route path="/analytics" element={<PageWalkthroughAnalytics />} /> */}

              {/* ROUTES: Page on board */}
              <Route
                path="/onboarding"
                element={
                  <ProtectedRoute
                    localStorageAuth={localStorageAuth}
                    user={userData}
                    location={location}
                  >
                    <PageOnBoarding />
                  </ProtectedRoute>
                }
              />
              <Route
                path={ONBOARDING_FLOW_PAGE_PATH}
                element={
                  <ProtectedRoute
                    localStorageAuth={localStorageAuth}
                    user={userData}
                    location={location}
                  >
                    <PageOnBoardingInvitation />
                  </ProtectedRoute>
                }
              />
              {/* ROUTES: Invite user to new org */}
              <Route
                path="/invite/join/:identifier/:token"
                element={
                  <ProtectedRoute
                    localStorageAuth={localStorageAuth}
                    user={userData}
                    location={location}
                  >
                    <PageInviteUserToDIffOrg isLoggedIn={false} />
                  </ProtectedRoute>
                }
              />
              {/* ROUTES: Guide Permissions */}
              <Route
                path="/guide-permissions"
                element={
                  <ProtectedRoute
                    localStorageAuth={localStorageAuth}
                    user={userData}
                    location={location}
                  >
                    <PageGuidePermissions />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/guides/all"
                element={
                  <ProtectedRoute
                    localStorageAuth={localStorageAuth}
                    user={userData}
                    location={location}
                  >
                    <PageAllGuide />
                  </ProtectedRoute>
                }
              />

              {/* ROUTES: Theme Editor */}
              <Route
                path="/theme"
                element={
                  <ProtectedRoute
                    localStorageAuth={localStorageAuth}
                    user={userData}
                    location={location}
                  >
                    <PageThemeEditor />
                  </ProtectedRoute>
                }
              />

              {/* Route for share  snap */}
              <Route
                path="/snap/public/:shareId/:guideId/"
                element={<PagePublicSnapGuide />}
              />
              <Route
                path="/snap/private/:shareId/:guideId/"
                element={<PageSharePrivateSnap />}
              />
              <Route path={"*"} element={<PageNotFound />} />
            </>
          )}

          {/** PROTECTED ROUTES END */}

          {/** UNPROTECTED ROUTES START */}
          {!isAuthenticated && (
            <>
              <Route path="/login" element={<PageLogin />} />
              <Route
                path="/login?extension-builder=true"
                element={<PageLogin />}
              />
              <Route
                path="/login?extension-launcher=true"
                element={<PageLogin />}
              />

              <Route path="/registration" element={<PageRegistration />} />

              {!isUserAllowedAccess(can_trial, paid_status) && (
                <Route path="/trial-expired" element={<PageTrialOver />} />
              )}

              {/* {paymentStep === "otherPlan" && (
                <Route
                  path="/renew-subscribe"
                  element={<PageRenewSubscription />}
                />
              )} */}

              {/* <Route
                path="/registration/register-as-viewer"
                element={<PageUserRegisterToViewer />}
              /> */}
              <Route
                path="/registration/:paymentTier"
                element={<PageRegistration />}
              />
              <Route
                path="/snap/public/:shareId/:guideId/"
                element={<PagePublicSnapGuide />}
              />
              <Route
                path="/invite/registration/:inviteId/:jwttoken"
                element={<PageRegistrationByInvite />}
              />
              <Route path="/forgot-password" element={<PageForgotPassword />} />
              <Route
                path="/registration-complete"
                element={<PageRegistrationCompleted />}
              />
            </>
          )}
          {/* Show PageLoginNotVerified Page only when isRegistering === "notverified" */}
          {!isAuthenticated && (
            <>
              {isRegistering === "notverified" && (
                <Route
                  path="/login/notverified"
                  element={<PageLoginNotVerified />}
                />
              )}
              {/* Show PageRegistrationCompleted Page only when isRegistering === "success" */}
              {isRegistering === "success" && (
                <Route
                  path="/registration/completed"
                  element={<PageRegistrationCompleted />}
                />
              )}
              {localStorageAuth === "false" &&
                (isRegistering === "invalid" ||
                  isRegistering === "alreadyexists" ||
                  isRegistering === "pending" ||
                  isRegistering === "failed") && (
                  <>
                    <Route path="/" element={<PageLogin />} />
                    <Route path="/login" element={<PageLogin />} />
                    <Route
                      path="/login?extension-builder=true"
                      element={<PageLogin />}
                    />
                    <Route
                      path="/login?extension-launcher=true"
                      element={<PageLogin />}
                    />

                    <Route
                      path="/registration"
                      element={<PageRegistration />}
                    />
                    {/* <Route
                path="/registration/register-as-viewer"
                element={<PageUserRegisterToViewer />}
              /> */}
                    <Route
                      path="/registration/:paymentTier"
                      element={<PageRegistration />}
                    />
                    <Route
                      path="/invite/registration/:inviteId/:jwttoken"
                      element={<PageRegistrationByInvite />}
                    />
                    <Route
                      path="/forgot-password"
                      element={<PageForgotPassword />}
                    />
                    <Route
                      path="/registration-complete"
                      element={<PageRegistrationCompleted />}
                    />
                    <Route
                      path="/quickbuilder/:shareId/:snapId"
                      element={<PagePublicSnapGuide />}
                    />
                    {/* TEMPORARY */}
                    {/* NOTE: Backend currently returns a link which 
            routes to /invite/signUp not /invite/registration */}
                    {/* <Route path="/invite/signUp/:inviteId/:jwttoken" element={PageRegistrationByInvite} /> */}

                    <Route path="/pagenotfound" element={<PageNotFound />} />
                    {/* When user not login and try to access random url show page not found */}
                    <Route path="*" element={<PageNotFound />} />
                  </>
                )}
            </>
          )}
          {/** UNPROTECTED ROUTES END */}
        </Routes>
      </div>
    </ThemeProvider>
  );
};

export default App;
