import React, { useEffect, useState } from "react";
import { Button, InputField } from "usertip-component-library";
import { VisibilityOff, Visibility } from "@mui/icons-material/";
import { validateEmailFormat } from "../../../utility/functions/validation";
import GoogleSignIn from "../../GoogleSignIn/GoogleSignIn";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store";
import { signInRequest } from "../../../store/reducers/reducerAuth";
import { useLocation, useNavigate } from "react-router-dom";

const LoginForm = () => {
  const [emailValue, setEmailValue] = useState<string>("");
  const [passwordValue, setPasswordValue] = useState<string>("");

  const [loginAt, setLoginAt] = useState(""); // SMU FYP ANALYTIPS PARAMETER
  const [eventId, seteventIdValue] = useState(""); // SMU FYP ANALYTIPS PARAMETER

  const [emailIsError, setEmailIseError] = useState<string>("");
  const [passwordIsError, setPasswordIsError] = useState<string>("");
  const [formIsError, setFormIsError] = useState<string>("");

  const [passwordVisibility, setPasswordVisibility] = useState<boolean>(false);

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();

  const isAuthenticated = useSelector(
    (state: RootState) => state.auth.isAuthenticated,
    shallowEqual
  );

  const errorSignIn = useSelector(
    (state: RootState) => state.auth.errorSignIn,
    shallowEqual
  );

  const loading = useSelector((state: RootState) => state.auth.appLoading);

  const { state } = location as { state: { from: string | undefined } };

  /** This is the handler when the login button is pressed / form is submitted */
  const submitHandler = (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    /** This is for check if the email is valid or not */
    const emailIsValidated = validateEmailFormat(emailValue.toLowerCase());

    /** This is for set error if email is invalid, empty and password is empty (gonna return so the code below this if won't run)*/
    if (!emailValue || !passwordValue || !emailIsValidated) {
      if (!emailValue) {
        setEmailIseError("Email is Required");
      } else if (!emailIsValidated) {
        setEmailIseError("Invalid Email");
      }

      if (!passwordValue) {
        setPasswordIsError("Password is Required");
      }

      return;
    }

    /** Set user input to an object */
    const userData = {
      email: emailValue.toLowerCase(),
      password: passwordValue,
      loginAt: loginAt,
      eventId: eventId,
    };

    /** signin reducer */
    dispatch(signInRequest(userData));

    /** This is for set error if email or password is wrong */
    if (!isAuthenticated && errorSignIn) {
      setFormIsError(errorSignIn);
    } else {
      if (state) {
        const from = state.from;
        // Only re-navigate if the user was redirected to login page and successfully authenticates, else will infinite loop
        if (from) {
          // in auth callback logic, once authenticated navigate (redirect) back
          // to the route originally being accessed.
          console.log("---navigate to prev page");
          // navigate(from, { replace: true });
        }
      } else {
        // Push to Clean URL, else in one login use-case will end up remaining as /login
        console.log("---navigate to root");
      }
    }
  };

  /** This is for set error if email or password is wrong in the first time*/
  useEffect(() => {
    if (!isAuthenticated && errorSignIn) {
      setFormIsError(errorSignIn);
    }
  }, [isAuthenticated, errorSignIn]);

  /** This is  for email input onChange handler */
  const emailHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (emailIsError) {
      setEmailIseError("");
    }

    if (formIsError) {
      setFormIsError("");
    }
    setEmailValue(e.target.value.toLowerCase());
  };

  /** This is  for password input onChange handler */
  const passwordHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (passwordIsError) {
      setPasswordIsError("");
    }

    if (formIsError) {
      setFormIsError("");
    }

    setPasswordValue(e.target.value);
  };

  return (
    <div>
      <form
        className="flex flex-col items-center gap-7 relative"
        onSubmit={submitHandler}
      >
        {formIsError && (
          <p className="text-center font-medium text-default-secondary absolute top-[-2.5em] left-0 right-0">
            {formIsError}
          </p>
        )}
        <InputField
          variant="full"
          placeholder="example@usertip.com"
          label="Email"
          type="email"
          helperText="*Required"
          value={emailValue}
          onChange={emailHandler}
          error={emailIsError ? true : false}
          errorText={emailIsError}
        />
        <InputField
          variant="full"
          placeholder="Enter your password"
          label="Password"
          type={!passwordVisibility ? "password" : "text"}
          helperText="*Required"
          iconRight={true}
          value={passwordValue}
          onChange={passwordHandler}
          icon={
            <div
              className="text-sm text-default-neutral-60 cursor-pointer"
              onClick={() => setPasswordVisibility(!passwordVisibility)}
            >
              {!passwordVisibility ? <VisibilityOff /> : <Visibility />}
            </div>
          }
          errorText={passwordIsError}
          error={passwordIsError ? true : false}
        />
        <div className="w-1/2">
          <Button
            color="primary"
            size="fluid"
            text={loading ? "Loading..." : "Login"}
            variant="primary"
            disabled={loading}
          />
        </div>
      </form>
      <div className="mt-4 flex flex-col items-center gap-4">
        <div className="flex flex-col items-center gap-2">
          <div className="flex gap-1">
            <p className="text-default-neutral-60 font-medium text-base">
              Don't have an account?
            </p>
            <a
              href="/registration"
              className="text-default-primary font-medium text-base underline active:text-default-primary visited:text-default-primary"
            >
              Sign Up for a Trial!
            </a>
          </div>
          <a
            href="/forgot-password"
            className="text-default-primary font-medium text-base underline active:text-default-primary visited:text-default-primary"
          >
            Forgot Password?
          </a>
        </div>
        <div className="flex items-center gap-3">
          <div className="h-[1px] w-44 bg-default-neutral-50"></div>
          <p className="text-default-neutral-60 font-medium text-base">Or</p>
          <div className="h-[1px] w-44 bg-default-neutral-50"></div>
        </div>
        <GoogleSignIn />
      </div>
    </div>
  );
};

export default LoginForm;
