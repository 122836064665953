import { Slider } from "@mui/material";
import React from "react";
import { InputNumber } from "usertip-component-library";

interface Props {
  /** Prop for value of the input */
  value: number;
  /** Prop for handling number input */
  handlerSetting: (value: number) => void;
  /** Prop for handling slider input */
  sliderHandler: (event: any, value: number | number[]) => void;
}

const RadiusSetting = ({ value, handlerSetting, sliderHandler }: Props) => {
  return (
    <>
      <h1 className="text-sm">Corner Radius:</h1>
      <InputNumber
        value={value}
        onChange={handlerSetting}
        width="full"
        adornment="px"
        maxValue={40}
        minValue={0}
        variant="outlined"
      />

      <div className="col-start-2">
        <Slider
          id="mainBorderRadiusSliderValue"
          value={value}
          onChange={sliderHandler}
          onChangeCommitted={sliderHandler}
          step={5}
          marks
          max={40}
        />
      </div>
    </>
  );
};

export default RadiusSetting;
