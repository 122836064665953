import React from 'react';

import TransferWithinAStationIcon from "@mui/icons-material/TransferWithinAStation";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SettingsIcon from '@mui/icons-material/Settings';
import GroupIcon from "@mui/icons-material/Group";
import ListAltIcon from '@mui/icons-material/ListAlt';


const ComponentListTableBtnToolTipIcon = ({inputBtnType}) => {

    if (inputBtnType === "Move") {
        return (<TransferWithinAStationIcon />);
    }
    else if (inputBtnType === "Delete") {
        return (<DeleteIcon />);
    }
    else if (inputBtnType === "Edit") {
        return (<EditIcon/>);
    }
  
    else if (inputBtnType === "View") {
        return (<VisibilityIcon />);
    }
    else if (inputBtnType === "Settings") {
        return (<SettingsIcon />);
    }
    else if (inputBtnType === "ViewingPermissions") {
        return (<GroupIcon />);
    }
    else if (inputBtnType === "GroupViewingPermissions") {
        return (<GroupIcon />);
    }
    else if (inputBtnType === "GroupWalkthroughList") {
        return (<ListAltIcon />);
    }
};

export default ComponentListTableBtnToolTipIcon;