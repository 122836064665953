import React, { useState } from "react";

import {
  Button,
  CloseSquare,
  InputField,
  Tags,
  Tooltip,
} from "usertip-component-library";

import AddIcon from "@mui/icons-material/Add";
import { Info } from "@mui/icons-material";
import functionValidateUrl from "./_functions/FunctionValidateUrl";

interface Props {
  allowedPagesList: string[];
  setAllowedPagesList: React.Dispatch<React.SetStateAction<string[]>>;
}

const ComponentSettingsAssistantAllowedPages = ({
  allowedPagesList,
  setAllowedPagesList,
}: Props) => {
  const [inputValue, setInputValue] = useState("");

  /** Handle to add allowedPages */
  const handleAddAllowedPages = () => {
    const values = [...allowedPagesList];
    values.push(inputValue);
    setAllowedPagesList(values);
    setInputValue("");
  };

  /** Handle deleting allowedPages */
  const handleRemoveAllowedPages = (index: number) => {
    const values = [...allowedPagesList];
    values.splice(index, 1);
    setAllowedPagesList(values);
  };

  return (
    <div>
      <div className="flex justify-between gap-12">
        <div className="flex">
          <p className="text-base leading-8 whitespace-nowrap">Allowed Pages</p>
          <Tooltip
            width="larger"
            position="top-left"
            content="This is a list of pages where the assistant is ONLY allowed to display.
        You can use Query URLs to ignore certain parts of the URL."
            color="primary"
          >
            <div className="scale-75 text-default-primary">
              <Info fontSize="small" />
            </div>
          </Tooltip>
          <p className="text-base leading-8">:</p>
        </div>
        <div className="flex gap-x-1 gap-y-[6px] flex-wrap justify-end mt-1">
          {allowedPagesList.map((val, index) => {
            return (
              <Tags
                size="medium"
                text={val!}
                variant="filled"
                key={`${val}-${index}`}
                icon={
                  <CloseSquare
                    className="fill-default-neutral-100 cursor-pointer"
                    onClick={() => {
                      handleRemoveAllowedPages(index);
                    }}
                  />
                }
              />
            );
          })}
        </div>
      </div>

      <div className="flex gap-4 mt-4">
        <InputField
          variant="full"
          placeholder={"https://www.example.com"}
          helperText="Insert valid URL and click add button"
          error={inputValue.length > 0 && functionValidateUrl(inputValue)}
          errorText="Value entered is not a valid URL."
          onChange={(e) => setInputValue(e.target.value)}
          value={inputValue}
        />
        <Tooltip
          width="medium"
          position="top"
          content="Add Allowed Page"
          color="primary"
        >
          <Button
            color="primary"
            size="small"
            text=""
            iconOnly
            variant="primary"
            onClick={(e) => {
              e.preventDefault();
              handleAddAllowedPages();
            }}
            icon={<AddIcon />}
            disabled={functionValidateUrl(inputValue)}
          />
        </Tooltip>
      </div>
    </div>
  );
};

export default ComponentSettingsAssistantAllowedPages;
