import { Alert, Box } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import {
  bulkDeleteImage,
  getImageList,
  resetImgList,
} from "../../../store/reducers/reducerMedia";
import ComponentBulkDeleteImage from "./ComponentBulkDeleteImage";
import ComponentFilterSearch from "./ComponentFilterSearch";
import ImagesCard from "./ImagesCard";
import InfinityScrollImageList from "./InfinityScrollImageList";
import { convertDateToStandardFormat } from "../../_functions/FunctionDate";
import ImageCardContainer from "../../03-organisms/04-ImageCardContainer/ImageCardContainer";
import { Button } from "usertip-component-library";

interface Props {
  isLimitReach: boolean;
  setUploadImgModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const ComponentImageList = ({ isLimitReach, setUploadImgModal }: Props) => {
  /** Change how many image in 1 col here */
  const imagePerCol = 4;
  const gridStyle = {
    gridTemplateColumns: `repeat(${imagePerCol}, minmax(0, 1fr))`,
  };
  const IMAGE_LIST_LIMIT = imagePerCol * 2;
  const LIMIT_PAGE = 1;

  const [text, setText] = useState("");
  const [sortBy, setSortBy] = useState<"dateCreated" | "name">("dateCreated");
  const [sortOrder, setSortOrder] = useState<1 | -1>(-1);

  const [bulkDeleteModal, setBulkDeleteModal] = useState(false);

  /**Delete image states */
  const [isBulkDeleteMode, setBulkDeleteMode] = useState(false);
  const [mediaIdDeletedList, setMediaIdDeletedList] = useState<string[]>([]);

  const dispatch = useDispatch();
  const cognitoId = useSelector(
    (state: RootState) => state.user.userData.cognito_id
  );

  const imageList = useSelector((state: RootState) => state.media.imageList);

  // get list of images. Limit images to 6
  useEffect(() => {
    console.log("---imageList changed", imageList);
    if (cognitoId && !imageList) {
      dispatch(
        getImageList({
          limit: IMAGE_LIST_LIMIT,
          page: LIMIT_PAGE,
          cognitoId,
          name: text,
          sortBy,
          sort: sortOrder,
        })
      );
    }
  }, [imageList, cognitoId]);

  function onFilterHandler(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    //reset image list state before filtering image
    dispatch(resetImgList());
    dispatch(
      getImageList({
        limit: IMAGE_LIST_LIMIT,
        page: LIMIT_PAGE,
        cognitoId,
        name: text,
        sortBy,
        sort: sortOrder,
      })
    );
  }

  const handleDeleteBulkImage = (
    event: React.ChangeEvent<HTMLInputElement>,
    mediaId: string
  ) => {
    const addItem = (item: string) => {
      if (!mediaIdDeletedList.includes(item)) {
        const newList = [...mediaIdDeletedList, item];
        setMediaIdDeletedList(newList);
      }
    };

    const removeItem = (item: string) => {
      const newList = mediaIdDeletedList.filter((i) => i !== item);
      setMediaIdDeletedList(newList);
    };

    if ((event.target as HTMLInputElement).checked) {
      addItem(mediaId);
    } else {
      removeItem(mediaId);
    }
  };

  const confirmBulkDelete = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(bulkDeleteImage(mediaIdDeletedList));
    setBulkDeleteMode(false);
    setMediaIdDeletedList([]);
    dispatch(
      getImageList({
        limit: IMAGE_LIST_LIMIT,
        page: LIMIT_PAGE,
        cognitoId,
        name: text,
        sortBy,
        sort: sortOrder,
      })
    );
  };

  return (
    <section id="media-image-list" className="w-full">
      {/* Filter image component */}
      <div className="flex items-center justify-between">
        <ComponentFilterSearch
          onFilterHandler={onFilterHandler}
          setText={setText}
          setSortBy={setSortBy}
          setSortOrder={setSortOrder}
        />

        <div className="flex items-center gap-4">
          <div>
            {!isBulkDeleteMode && (
              <Button
                color="primary"
                size="medium"
                text="Upload Image"
                variant="primary"
                disabled={isLimitReach}
                onClick={() => setUploadImgModal(true)}
              />
            )}
          </div>
          {/* If image list exist render the cards */}
          {/* Bulk delete component */}
          {imageList && imageList.length > 1 && (
            <ComponentBulkDeleteImage
              isBulkDeleteMode={isBulkDeleteMode}
              setBulkDeleteMode={setBulkDeleteMode}
              confirmBulkDelete={confirmBulkDelete}
              mediaDeletedList={mediaIdDeletedList}
            />
          )}
        </div>
      </div>
      {isBulkDeleteMode && (
        <Alert sx={{ marginTop: "1rem" }} severity="warning">
          Choose images to remove
        </Alert>
      )}
      {imageList && (
        <Box
          className="py-6 overflow-x-auto rounded-md"
          sx={{
            width: "100%",
          }}
        >
          {/* display image list as grid 4x4 */}
          <div className="w-full grid auto-rows-auto gap-4" style={gridStyle}>
            {imageList.map((val) => {
              return (
                <ImageCardContainer
                  isBulkDeleteMode={isBulkDeleteMode}
                  handleDeleteBulkImage={handleDeleteBulkImage}
                  key={val.media_id}
                  date_modified={val.date_modified}
                  date_uploaded={val.date_uploaded}
                  img_name={val.img_name}
                  img_url={val.img_url}
                  media_id={val.media_id}
                  owner_email={val.owner_email}
                />
              );
            })}
          </div>
          {/* only add infiniy scroll when image list is more than default (12) */}
          {imageList.length === IMAGE_LIST_LIMIT && (
            <InfinityScrollImageList
              sort={sortOrder}
              sortBy={sortBy}
              name={text}
              isBulkDeleteMode={isBulkDeleteMode}
              imagePerCol={imagePerCol}
              handleDeleteBulkImage={handleDeleteBulkImage}
            />
          )}
        </Box>
      )}
    </section>
  );
};

export default ComponentImageList;
