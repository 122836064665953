import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "usertip-component-library";
import { RootState } from "../store";
import ComponentSettingsAssistantStatus from "./ComponentSettingsAssistantStatus";
import {
  resetAlert,
  updateAssistantPositionSettings,
  updateAssistantPublishSettings,
} from "../store/reducers/reducerOrganization";
import ComponentSettingsAssistantUX from "./ComponentSettingsAssistantUX";

const AssistantControlSettings = () => {
  /** Status State */
  const [originalAssistantPublishStatus, setOriginalAssistantPublishStatus] =
    useState(false);
  const [assistantPublishStatus, setAssistantPublishStatus] = useState(false);

  /** Location State */
  const [
    originalAssistantDisplayLocation,
    setOriginalAssistantDisplayLocation,
  ] = useState<{ value: string } | null>(null);
  const [assistantDisplayLocation, setAssistantDisplayLocation] = useState<{
    value: string;
  } | null>(null);

  const org = useSelector((state: RootState) => state.org);
  const { position, publish } = useSelector(
    (state: RootState) => state.org.assistant
  );

  /** Indicator to disable button */
  const buttonDisabled =
    assistantDisplayLocation?.value ===
      originalAssistantDisplayLocation?.value &&
    assistantPublishStatus === originalAssistantPublishStatus;

  const dispatch = useDispatch();

  // Tracks value of position and publish to updates Form Data when new data exists
  useEffect(() => {
    if (position !== undefined && position !== null) {
      setAssistantDisplayLocation({
        value: positionParser(position)!,
      });

      setOriginalAssistantDisplayLocation({
        value: positionParser(position)!,
      });
    }

    if (publish !== undefined && publish !== null) {
      setOriginalAssistantPublishStatus(publish);
      setAssistantPublishStatus(publish);
    }
  }, [position, publish]);

  /** value parser for usertip select component library  */
  const positionParser = (position: string) => {
    switch (position) {
      case "center_right":
        return "Right - Center";
      case "center_left":
        return "Left - Center";
      case "bottom_right":
        return "Right - Bottom";
      case "bottom_left":
        return "Left - Bottom";
      case "Right - Center":
        return "center_right";
      case "Left - Center":
        return "center_left";
      case "Right - Bottom":
        return "bottom_right";
      case "Left - Bottom":
        return "bottom_left";
    }
  };

  // Usertip Assistant: Save activate assistant field to API
  const handleSaveActiveAssistant = () => {
    const orgData = {
      org_id: org.org_id!,
      publish: assistantPublishStatus,
    };

    dispatch(updateAssistantPublishSettings(orgData));
  };

  // Usertip Assistant: Save Display Position field to API
  const handleSaveAssistantDisplayPosition = () => {
    const position_data = positionParser(assistantDisplayLocation!.value);

    const orgData = {
      org_id: org.org_id!,
      position: position_data!,
    };

    dispatch(updateAssistantPositionSettings(orgData));
  };

  /** Handler for submit button */
  const handleSubmit = () => {
    dispatch(resetAlert());

    if (assistantPublishStatus !== originalAssistantPublishStatus) {
      handleSaveActiveAssistant();
    }

    if (
      assistantDisplayLocation?.value !==
      originalAssistantDisplayLocation?.value
    ) {
      handleSaveAssistantDisplayPosition();
    }
  };

  /** Handler for cancel button */
  const cancelButtonHandler = () => {
    setAssistantPublishStatus(originalAssistantPublishStatus);
    setAssistantDisplayLocation(originalAssistantDisplayLocation);
  };

  return (
    <div className="flex flex-col gap-6 ">
      <h1 className="font-semibold text-lg">
        Usertip Assistant Control Setting
      </h1>
      <div className="flex flex-col gap-4">
        <ComponentSettingsAssistantStatus
          assistantPublishStatus={assistantPublishStatus}
          setAssistantPublishStatus={setAssistantPublishStatus}
        />
        <ComponentSettingsAssistantUX
          assistantDisplayLocation={assistantDisplayLocation}
          setAssistantDisplayLocation={setAssistantDisplayLocation}
        />
      </div>
      <div className="ml-auto flex gap-2">
        {!buttonDisabled && (
          <Button
            color="secondary"
            size="medium"
            text="Cancel"
            variant="primary"
            customClass="!px-7 !py-2"
            onClick={cancelButtonHandler}
          />
        )}
        <Button
          color="primary"
          size="medium"
          text="Save"
          variant="primary"
          customClass="!px-7 !py-2"
          disabled={buttonDisabled}
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
};

export default AssistantControlSettings;
