// Make a copy of "Builders / Viewers", then remove them if they are duplicated Members already on MembersTeam List
export const filterDuplicatedMembersByCognitoID = (
  teamDataSet,
  memberTeamDataSet
) => {
  let membersDataSet = [];

  if (
    teamDataSet &&
    teamDataSet !== undefined &&
    memberTeamDataSet &&
    memberTeamDataSet !== undefined
  ) {
    for (let i = 0; i < teamDataSet.length; i++) {
      const hasMatch = memberTeamDataSet.some(
        (teamData) => teamData.cognito_id === teamDataSet[i].cognito_id
      );

      if (!hasMatch) {
        // if false means user is not in the right side list
        // we push the data to left side aka membersDataSet
        membersDataSet.push(teamDataSet[i]);
      }
    }
  }

  return membersDataSet;
};
export const filterDuplicatedMembersByEmail = (
  teamDataSet,
  memberTeamDataSet
) => {
  let membersDataSet = [];

  if (
    teamDataSet &&
    teamDataSet !== undefined &&
    memberTeamDataSet &&
    memberTeamDataSet !== undefined
  ) {
    for (let i = 0; i < teamDataSet.length; i++) {
      const hasMatch = memberTeamDataSet.some(
        (teamData) => teamData.email === teamDataSet[i].email
      );

      if (!hasMatch) {
        // if false means user is not in the right side list
        // we push the data to left side aka membersDataSet
        membersDataSet.push(teamDataSet[i]);
      }
    }
  }

  return membersDataSet;
};
export const filterDuplicatedMembersByName = (
  teamDataSet,
  memberTeamDataSet
) => {
  let membersDataSet = [];

  if (
    teamDataSet &&
    teamDataSet !== undefined &&
    memberTeamDataSet &&
    memberTeamDataSet !== undefined
  ) {
    for (let i = 0; i < teamDataSet.length; i++) {
      const hasMatch = memberTeamDataSet.some(
        (teamData) => teamData.name === teamDataSet[i].name
      );

      if (!hasMatch) {
        // if false means user is not in the right side list
        // we push the data to left side aka membersDataSet
        membersDataSet.push(teamDataSet[i]);
      }
    }
  }

  return membersDataSet;
};
export const filterDuplicatedMembersByRoleID = (
  teamDataSet,
  memberTeamDataSet
) => {
  let membersDataSet = [];

  if (
    teamDataSet &&
    teamDataSet !== undefined &&
    memberTeamDataSet &&
    memberTeamDataSet !== undefined
  ) {
    for (let i = 0; i < teamDataSet.length; i++) {
      const hasMatch = memberTeamDataSet.some(
        (teamData) => teamData.role_id === teamDataSet[i].role_id
      );
      if (!hasMatch) {
        //check if user have same email. Owner could have different role_id
        const isSameEmail = memberTeamDataSet.some(
          (teamData) => teamData.email === teamDataSet[i].email
        );
        if (!isSameEmail) {
          // if false means user is not in the right side list
          // we push the data to left side aka membersDataSet
          membersDataSet.push(teamDataSet[i]);
        }
      }
    }
  }

  return membersDataSet;
};

// List Functions to Compile / Add / Remove Members to List
export const not = (leftList, rightList) => {
  return leftList.filter((value) => rightList.indexOf(value) === -1);
};
export const intersection = (leftList, rightList) => {
  return leftList.filter((value) => rightList.indexOf(value) !== -1);
};
export const union = (leftList, rightList) => {
  return [...leftList, ...not(rightList, leftList)];
};

export const numberOfChecked = (checked, members) =>
  intersection(checked, members).length;

// Processes ADD New Members List
export const checkMembersToAdd = (memberTeamList, memberTeamDataSet) => {
  // Identifies members to ADD
  // Use the "memberTeamDataSet" list as Referecne to ADD new Members
  let membersToAdd = [...memberTeamList];
  for (let i = 0; i < membersToAdd.length; i++) {
    for (let j = 0; j < memberTeamDataSet.length; j++) {
      if (
        membersToAdd[i] !== undefined &&
        membersToAdd[i].cognito_id === memberTeamDataSet[j].cognito_id
      ) {
        // Removes Members Already On Previously-Loaded MembersTeam List
        membersToAdd.splice(i, 1);
      }
    }
  }

  return membersToAdd;
};
// Processes REMOVE Existing Members List
export const checkMembersToRemove = (membersList, membersDataSet) => {
  // Identifies members to REMOVE
  // Use the "membersDataSet" list as Referecne to REMOVE existing Members
  let membersToRemove = [...membersList];
  for (let i = 0; i < membersToRemove.length; i++) {
    for (let j = 0; j < membersDataSet.length; j++) {
      if (
        membersToRemove[i] !== undefined &&
        membersToRemove[i].cognito_id === membersDataSet[j].cognito_id
      ) {
        // Removes Members Already On Previously-Loaded Members List
        membersToRemove.splice(i, 1);
      }
    }
  }

  return membersToRemove;
};
