import React from "react";

interface Props {
  /** The current Value of the item. */
  currentValue: number;
  /** The total value of the item. */
  totalValue: number;
  /** (Optional) The label for the progress bar */
  label?: string;
  /** The size of the progress bar. */
  size?: "small" | "default";
}

/** Line Bar Progress Re-usable Component */
const LineBarProgressComponent = ({
  label,
  currentValue,
  totalValue,
  size = "default",
}: Props) => {
  const percentage = Math.floor((currentValue / totalValue) * 100);

  return (
    <div
      className={`flex flex-col ${size === "small" ? "pt-1" : "py-4"} w-full`}
    >
      {/* progress container */}
      <div className="flex w-full items-center text-base">
        {/* Line wrapper for outer and innter */}
        <div className="flex-auto rounded-md overflow-hidden">
          {/* Outer line component, which represents total value */}
          <div
            style={{ height: size === "small" ? "6px" : "8px" }}
            className="rounded-md bg-neutral-200"
          >
            {/* Inner line component, which is the currentValue */}
            <div
              style={{
                height: size === "small" ? "6px" : "8px",
                width: `${percentage}%`,
              }}
              className="bg-default-primary rounded-md"
            ></div>
          </div>
        </div>
      </div>
      {/* Label */}
      {label && label.length > 0 && (
        <div className="ml-auto">
          <span className="text-neutral-600">{label}</span>
        </div>
      )}
    </div>
  );
};

export default LineBarProgressComponent;
