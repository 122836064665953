import React from "react";
import { useDispatch, useSelector } from "react-redux";
import ComponentAccountForgotPassword from "../components/Account/ForgotPassword/ComponentAccountForgotPassword";
import ComponentAlertAppOverallSnackbar from "../components/ComponentAlertAppOverallSnackbar";
import ComponentLoading from "../components/ComponentLoading";
import { RootState } from "../store";
import { resetAlert } from "../store/reducers/reducerAuth";
import { UsertipHorizontalIcon } from "usertip-component-library";
import PageFooter from "../components/03-organisms/05-form/PageFooter";

const PageForgotPassword = () => {
  const isLoading = useSelector((state: RootState) => state.auth.appLoading);
  const alert = useSelector((state: RootState) => state.auth.alert);
  const pageSection = useSelector(
    (state: RootState) => state.auth.forgotPwdSection
  );
  const dispatch = useDispatch();

  return (
    <>
      <ComponentLoading show={isLoading} />

      <div className="w-screen h-min min-h-screen relative flex items-center justify-center">
        <div className="absolute top-2 left-20">
          <UsertipHorizontalIcon />
        </div>
        <div className="flex flex-col gap-11 p-8 my-4">
          {pageSection !== "formInvalid" && pageSection !== "formSuccess" && (
            <h1 className="text-5xl font-medium text-default-neutral-100 text-center">
              {pageSection === "formNewPass"
                ? "New Password"
                : "Forgot Password"}
            </h1>
          )}

          <ComponentAccountForgotPassword />
        </div>
        <PageFooter />
      </div>

      {alert.type !== "" && alert.type !== undefined && (
        <ComponentAlertAppOverallSnackbar
          alert={alert}
          reset={() => dispatch(resetAlert())}
        />
      )}
    </>
  );
};

export default PageForgotPassword;
