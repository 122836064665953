import React from "react";
import ComponentCodeBlock from "./ComponentCodeBlock";

interface Props {
  api_key: string;
  children: React.ReactNode;
  title: string;
}
const ComponentInstallation = ({ api_key, children, title }: Props) => {
  return (
    <article className="page-installation mb-8">
      <h2 className="text-2xl font-bold">{title}</h2>
      <br />
      <h5 className="text-lg font-medium">Your api key is:</h5>
      <ComponentCodeBlock codeBlock={api_key} />
      <br />
      {children}
    </article>
  );
};

export default ComponentInstallation;
