import React from "react";

import { TableCell } from "@mui/material";

import ComponentListTableBtnSingle from "./ComponentListTableBtnSingle";
import { useSelector } from "react-redux";

const ComponentListTableBodyActionBtns = ({
  listType,
  displayUserRoleFull,

  dataSet,
  dataObject,

  setSelectedUserList,
  setSelectedTeamList,
  setSelectedWalkthroughList,

  setSelectedGroupsList,
  setSelectedGroupWalkthroughsList,

  setSelectedWalkthroughViewingPermissionsList,
  setSelectedWalkthroughViewerTeamPermissionsList,

  setShowModalRemove,
  setRemoveMode,
  setShowModalMove,
  setMoveMode,

  setShowModalEdit,
  setEditMode,
  setShowModalView,
  setViewMode,

  setShowAlertSnackbar,

  row,
}) => {
  const loggedInUserRole = useSelector((state) => state.user.userData.role);
  return (
    <>
      {listType === "sharedWalkthrough" ||
      listType === "editSharedWalkthrough" ? (
        <TableCell align="left">
          {listType === "sharedWalkthrough" && (
            <ComponentListTableBtnSingle
              btnType={"Edit"}
              listType={listType}
              dataSet={dataSet}
              row={row}
            />
          )}
          {listType === "editSharedWalkthrough" && (
            <ComponentListTableBtnSingle
              btnType={"Delete"}
              listType={listType}
              dataSet={dataSet}
              row={row}
              dataObject={dataObject}
              setSelectedWalkthroughList={setSelectedWalkthroughList}
              setRemoveMode={setRemoveMode}
              setShowModalRemove={setShowModalRemove}
            />
          )}
        </TableCell>
      ) : (
        <TableCell
          align="right"
          className={
            displayUserRoleFull === "Viewer Code Snippet"
              ? "sticky-end-single-column"
              : ""
          }
        >
          {
            // If user role is "owner", Display "Owner". Else, Display "Action" Controls
            row.role === "owner" && listType === "User" ? (
              `Owner`
            ) : // If "listType" is "Team" or "owWalkthroughViewerTeamner" & "row.name" is "All", Display "".
            // Else, Display "Action" Controls
            listType === "WalkthroughViewerTeamPermissions" &&
              row.name === "All" ? (
              ``
            ) : listType === "Team" && row.name === "All" ? (
              <>
                <ComponentListTableBtnSingle
                  btnType={"View"}
                  listType={listType}
                  dataSet={dataSet}
                  row={row}
                  setSelectedTeamList={setSelectedTeamList}
                  setShowModalView={setShowModalView}
                  setViewMode={setViewMode}
                />
              </>
            ) : (
              <>
                {
                  // Passes in "setSelectedUserList"
                  listType === "User" && loggedInUserRole === "owner" && (
                    <>
                      <ComponentListTableBtnSingle
                        btnType={"Move"}
                        listType={listType}
                        dataSet={dataSet}
                        row={row}
                        setSelectedUserList={setSelectedUserList}
                        setShowModalMove={setShowModalMove}
                        setMoveMode={setMoveMode}
                        setShowAlertSnackbar={setShowAlertSnackbar}
                      />
                      <ComponentListTableBtnSingle
                        btnType={"Delete"}
                        listType={listType}
                        dataSet={dataSet}
                        row={row}
                        setSelectedUserList={setSelectedUserList}
                        setShowModalRemove={setShowModalRemove}
                        setRemoveMode={setRemoveMode}
                        setShowAlertSnackbar={setShowAlertSnackbar}
                      />
                    </>
                  )
                }
                {
                  // Passes in "setSelectedUserList"
                  listType === "User" && loggedInUserRole === "admin" && (
                    <>
                      <ComponentListTableBtnSingle
                        btnType={"Move"}
                        listType={listType}
                        dataSet={dataSet}
                        row={row}
                        setSelectedUserList={setSelectedUserList}
                        setShowModalMove={setShowModalMove}
                        setMoveMode={setMoveMode}
                        setShowAlertSnackbar={setShowAlertSnackbar}
                      />
                      <ComponentListTableBtnSingle
                        btnType={"Delete"}
                        listType={listType}
                        dataSet={dataSet}
                        row={row}
                        setSelectedUserList={setSelectedUserList}
                        setShowModalRemove={setShowModalRemove}
                        setRemoveMode={setRemoveMode}
                        setShowAlertSnackbar={setShowAlertSnackbar}
                      />
                    </>
                  )
                }
                {
                  // Passes in "setSelectedUserList"
                  listType === "User" &&
                    loggedInUserRole === "builder" &&
                    displayUserRoleFull === "Viewer" && (
                      <>
                        <ComponentListTableBtnSingle
                          btnType={"Delete"}
                          listType={listType}
                          dataSet={dataSet}
                          row={row}
                          setSelectedUserList={setSelectedUserList}
                          setShowModalRemove={setShowModalRemove}
                          setRemoveMode={setRemoveMode}
                          setShowAlertSnackbar={setShowAlertSnackbar}
                        />
                      </>
                    )
                }
                {
                  // Passes in "setSelectedTeamList"
                  listType === "Team" && (
                    <>
                      <ComponentListTableBtnSingle
                        btnType={"View"}
                        listType={listType}
                        dataSet={dataSet}
                        row={row}
                        setSelectedTeamList={setSelectedTeamList}
                        setShowModalView={setShowModalView}
                        setViewMode={setViewMode}
                      />
                      <ComponentListTableBtnSingle
                        btnType={"Edit"}
                        listType={listType}
                        dataSet={dataSet}
                        row={row}
                        setSelectedTeamList={setSelectedTeamList}
                        setShowModalEdit={setShowModalEdit}
                        setEditMode={setEditMode}
                      />
                      <ComponentListTableBtnSingle
                        btnType={"Delete"}
                        listType={listType}
                        dataSet={dataSet}
                        row={row}
                        setSelectedTeamList={setSelectedTeamList}
                        setShowModalRemove={setShowModalRemove}
                        setRemoveMode={setRemoveMode}
                        setShowAlertSnackbar={setShowAlertSnackbar}
                        setSelectedWalkthroughList={setSelectedWalkthroughList}
                      />
                    </>
                  )
                }
                {
                  // Passes in "setSelectedGroupsList"
                  listType === "Group" && (
                    <>
                      <ComponentListTableBtnSingle
                        btnType={"GroupViewingPermissions"}
                        listType={listType}
                        dataSet={dataSet}
                        row={row}
                        setSelectedGroupsList={setSelectedGroupsList}
                      />
                      <ComponentListTableBtnSingle
                        btnType={"GroupWalkthroughList"}
                        listType={listType}
                        dataSet={dataSet}
                        row={row}
                        setSelectedGroupsList={setSelectedGroupsList}
                      />
                      <ComponentListTableBtnSingle
                        btnType={"Delete"}
                        listType={listType}
                        dataSet={dataSet}
                        row={row}
                        setSelectedGroupsList={setSelectedGroupsList}
                        setShowModalRemove={setShowModalRemove}
                        setRemoveMode={setRemoveMode}
                        setShowAlertSnackbar={setShowAlertSnackbar}
                      />
                    </>
                  )
                }
                {
                  // Passes in "setSelectedWalkthroughList"
                  listType === "Walkthrough" && (
                    <>
                      <ComponentListTableBtnSingle
                        btnType={"Settings"}
                        listType={listType}
                        dataSet={dataSet}
                        row={row}
                        setSelectedWalkthroughList={setSelectedWalkthroughList}
                      />
                      <ComponentListTableBtnSingle
                        btnType={"Edit"}
                        listType={listType}
                        dataSet={dataSet}
                        row={row}
                        setSelectedWalkthroughList={setSelectedWalkthroughList}
                      />
                      <ComponentListTableBtnSingle
                        btnType={"Delete"}
                        listType={listType}
                        dataSet={dataSet}
                        row={row}
                        setSelectedWalkthroughList={setSelectedWalkthroughList}
                        setShowModalRemove={setShowModalRemove}
                        setRemoveMode={setRemoveMode}
                        setShowAlertSnackbar={setShowAlertSnackbar}
                      />
                    </>
                  )
                }
                {
                  // Passes in "setSelectedGroupWalkthroughsList"
                  listType === "GroupWalkthrough" && (
                    <>
                      <ComponentListTableBtnSingle
                        btnType={"Settings"}
                        listType={listType}
                        dataSet={dataSet}
                        row={row}
                        setSelectedGroupWalkthroughsList={
                          setSelectedGroupWalkthroughsList
                        }
                      />
                      <ComponentListTableBtnSingle
                        btnType={"Edit"}
                        listType={listType}
                        dataSet={dataSet}
                        row={row}
                        setSelectedGroupWalkthroughsList={
                          setSelectedGroupWalkthroughsList
                        }
                      />
                      <ComponentListTableBtnSingle
                        btnType={"Delete"}
                        listType={listType}
                        dataSet={dataSet}
                        row={row}
                        setSelectedGroupWalkthroughsList={
                          setSelectedGroupWalkthroughsList
                        }
                        setShowModalRemove={setShowModalRemove}
                        setRemoveMode={setRemoveMode}
                        setShowAlertSnackbar={setShowAlertSnackbar}
                      />
                    </>
                  )
                }

                {
                  // Passes in "setSelectedWalkthroughViewingPermissionsList"
                  listType === "WalkthroughViewingPermissions" && (
                    <>
                      <ComponentListTableBtnSingle
                        btnType={"Delete"}
                        listType={listType}
                        dataSet={dataSet}
                        row={row}
                        setSelectedWalkthroughViewingPermissionsList={
                          setSelectedWalkthroughViewingPermissionsList
                        }
                        setShowModalRemove={setShowModalRemove}
                        setRemoveMode={setRemoveMode}
                      />
                    </>
                  )
                }
                {
                  // Passes in "setSelectedWalkthroughViewerTeamPermissionsList"
                  listType === "WalkthroughViewerTeamPermissions" && (
                    <>
                      <ComponentListTableBtnSingle
                        btnType={"Delete"}
                        listType={listType}
                        dataSet={dataSet}
                        row={row}
                        setSelectedWalkthroughViewerTeamPermissionsList={
                          setSelectedWalkthroughViewerTeamPermissionsList
                        }
                        setShowModalRemove={setShowModalRemove}
                        setRemoveMode={setRemoveMode}
                      />
                    </>
                  )
                }
              </>
            )
          }
        </TableCell>
      )}
    </>
  );
};

export default ComponentListTableBodyActionBtns;
