import Axios from "axios";

// const route_1 = "https://fq588h7knf.execute-api.ap-southeast-2.amazonaws.com/dev/" //serverless.yml
// const route_2 = "https://srg2kvlyt3.execute-api.ap-southeast-2.amazonaws.com/dev/" //serverless2.yml

// const staging = "https://api-staging.user-tip.com/"
// const prod = "https://api.user-tip.com/"

// TODO: use process.env or equivalent to toggle between
// prod and staging apis

const dev = "http://localhost:8080";
const staging = "https://api-staging.usertip.com";
// const staging = "https://b860-182-253-55-32.ngrok-free.app";
const prod = "https://api.usertip.com";

export let baseUrl;

switch (process.env.NODE_ENV) {
  case "development":
    baseUrl = dev;
    break;
  case "staging":
    baseUrl = staging;
    break;
  case "production":
    baseUrl = prod;
    break;
  default:
    console.log("node env is", process.env.NODE_ENV);
    baseUrl = staging;
    break;
}
// if the env is stagging
if (process.env.REACT_APP_API_ENDPOINT) {
  baseUrl = staging;
}

export const api = Axios.create({
  baseURL: baseUrl,
});
