import React, { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { Button, EditIcon, PlusIcon, Tags } from "usertip-component-library";
import { AddBox, Delete, Visibility } from "@mui/icons-material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { convertDateToStandardFormat } from "../_functions/FunctionDate";
import ComponentViewTeams from "../ComponentViewTeams";
import {
  teamAddUsersToBuilderTeam,
  teamAddUsersToViewerTeam,
  teamEditMembersBuilderTeam,
  teamEditMembersViewerTeam,
  teamRemoveExistingBuilderTeam,
  teamRemoveExistingViewerTeam,
  teamRemoveUsersFromBuilderTeam,
  teamRemoveUsersFromViewerTeam,
} from "../../store/reducers/reducerTeam";
import ComponentEditTeams from "../ComponentEditTeams";
import ComponentRemoveTeams from "../ComponentRemoveTeams";

interface Props {
  teamData: any[];
  allTeamData: any[];
  handleAddTeam: () => void;
}

const TableTeamList = ({ teamData, allTeamData, handleAddTeam }: Props) => {
  const [showModalRemove, setShowModalRemove] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showModalView, setShowModalView] = useState(false);
  const [showModalBulkDelete, setShowModalBulkDelete] = useState(false);
  const [clickedRow, setClickedRow] = useState<any[]>([]);
  const [selectedTeamData, setselectedTeamData] = useState<any[]>([]);

  /** This is for select guides using checkbox */
  const handleSelectionModelChange = (selectionModel: any) => {
    // Use selectionModel to find the selected rows from the rows array
    const selectedRowObjects = selectionModel.map((rowId: any) =>
      teamData!.find((row: any) => row.id === rowId)
    );
    setselectedTeamData(selectedRowObjects);
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Team",
      flex: 1,
      minWidth: 150,
      editable: false,
    },
    {
      field: "createdAt",
      headerName: "Date Created",
      flex: 1,
      minWidth: 150,
      editable: false,
      valueFormatter: (params) => convertDateToStandardFormat(params.value),
    },
    {
      field: "updatedAt",
      headerName: "Last Edited Date",
      flex: 1,
      minWidth: 150,
      editable: false,
      valueFormatter: (params) => convertDateToStandardFormat(params.value),
    },
    {
      field: "action",
      headerName: "Action(s)",
      minWidth: 120,
      align: "left",
      renderCell: (params) => {
        return (
          <div className="flex flex-row gap-3">
            <div
              className="cursor-pointer text-neutral-500"
              onClick={(e) => {
                e.stopPropagation();
                setClickedRow([params.row]);
                setShowModalView(true);
              }}
              title="View Team"
            >
              <Visibility />
            </div>
            {params.row.id !== 1 && (
              <>
                <div
                  className="cursor-pointer text-neutral-500"
                  onClick={(e) => {
                    e.stopPropagation();
                    setClickedRow([params.row]);
                    setShowModalEdit(true);
                  }}
                  title="Edit Team Member"
                >
                  <EditIcon fill="#808096" />
                </div>
                <div
                  className="cursor-pointer text-neutral-500"
                  onClick={(e) => {
                    e.stopPropagation();
                    setClickedRow([params.row]);
                    setShowModalRemove(true);
                  }}
                  title="Delete Team"
                >
                  <Delete />
                </div>
              </>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <div className="bg-default-primary rounded-tr-[20px] rounded-tl-[20px] flex flex-row justify-between px-6 py-4 items-center text-white">
        <div className="text-base font-bold">
          {selectedTeamData.length > 0 ? (
            <span>{selectedTeamData.length} selected</span>
          ) : (
            <span>Manage Teams</span>
          )}
        </div>
        <Button
          color="primary"
          size="small"
          variant="outlined"
          text={`Add Viewer Team`}
          icon={<AddBox fontSize="small" />}
          iconLeft={true}
          customClass="ut-btn-org"
          onClick={handleAddTeam}
        />
        <div
          className={` flex-row gap-2 items-center border-whi ${
            selectedTeamData.length > 0 ? "flex" : "hidden"
          }`}
        >
          <Button
            text="Bulk Delete"
            iconLeft
            icon={<Delete />}
            size="small"
            variant="outlined"
            color="neutral"
            onClick={() => {
              setShowModalBulkDelete(true);
            }}
            customClass={"ut-btn-org"}
          />
        </div>
      </div>
      <div className="bg-white">
        <DataGrid
          rows={teamData || []}
          columns={columns}
          autoHeight
          checkboxSelection
          selectionModel={selectedTeamData.map((row: any) => row.id)}
          onSelectionModelChange={handleSelectionModelChange}
          initialState={{
            sorting: {
              sortModel: [{ field: "name", sort: "asc" }],
            },
          }}
          sx={{ borderEndEndRadius: 20, borderEndStartRadius: 20 }}
        />
      </div>
      {clickedRow.length > 0 && showModalView && (
        <ComponentViewTeams
          displayUserRoleFull={"Viewer"}
          selectedTeamList={clickedRow}
          showModalView={showModalView}
          setShowModalView={setShowModalView}
          viewMode={"single"}
        />
      )}

      {clickedRow.length > 0 && showModalEdit && (
        <ComponentEditTeams
          displayUserRoleFull={"Viewer"}
          selectedTeamList={clickedRow}
          teamDataSet={allTeamData}
          onAddUsersToBuilderTeam={teamAddUsersToBuilderTeam}
          onRemoveUsersFromBuilderTeam={teamRemoveUsersFromBuilderTeam}
          onEditMembersInBuilderTeam={teamEditMembersBuilderTeam}
          onAddUsersToViewerTeam={teamAddUsersToViewerTeam}
          onRemoveUsersFromViewerTeam={teamRemoveUsersFromViewerTeam}
          onEditMembersInViewerTeam={teamEditMembersViewerTeam}
          showModalEdit={showModalEdit}
          setShowModalEdit={setShowModalEdit}
          editMode={"single"}
        />
      )}

      {clickedRow.length > 0 && showModalRemove && (
        <ComponentRemoveTeams
          displayUserRoleFull={"Viewer"}
          listType={"Teams"}
          selectedTeamList={clickedRow}
          onRemoveBuilderTeam={teamRemoveExistingBuilderTeam}
          onRemoveViewerTeam={teamRemoveExistingViewerTeam}
          showModalRemove={showModalRemove}
          setShowModalRemove={setShowModalRemove}
          removeMode={"single"}
          onDeleteOneTeamPermissions={undefined}
          selectedWalkthroughViewerTeamPermissionsList={undefined}
          setResetTableSelectAll={undefined}
          viewerTeamType={""}
        />
      )}

      {selectedTeamData.length > 0 && showModalBulkDelete && (
        <ComponentRemoveTeams
          displayUserRoleFull={"Viewer"}
          listType={"Teams"}
          selectedTeamList={selectedTeamData}
          onRemoveBuilderTeam={teamRemoveExistingBuilderTeam}
          onRemoveViewerTeam={teamRemoveExistingViewerTeam}
          showModalRemove={showModalBulkDelete}
          setShowModalRemove={setShowModalBulkDelete}
          removeMode={"bulk"}
          onDeleteOneTeamPermissions={undefined}
          selectedWalkthroughViewerTeamPermissionsList={undefined}
          setResetTableSelectAll={undefined}
          viewerTeamType={""}
        />
      )}
    </div>
  );
};

export default TableTeamList;
