import React from "react";
import DatePicker from "react-datepicker";

interface Props {
  startDate: Date;
  setStartDate: React.Dispatch<React.SetStateAction<Date | null>>;
  endDate: Date;
  setEndDate: React.Dispatch<React.SetStateAction<Date | null>>;
  rule: String;
}

const ComponentFilterDatePicker = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  rule,
}: Props) => {
  const onChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setStartDate(start!);
    setEndDate(end!);
  };
  switch (rule) {
    case "since":
      return (
        <div className="date-input-container">
          {/* @ts-ignore */}
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date!)}
          />
        </div>
      );
    case "before":
      return (
        <div className="date-input-container">
          {/* @ts-ignore */}
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date!)}
          />
        </div>
      );
    case "between":
      return (
        <div className="date-input-container">
          {/* @ts-ignore */}
          <DatePicker
            selected={startDate}
            onChange={onChange!}
            startDate={startDate}
            endDate={endDate}
            selectsRange
          />
        </div>
      );
    default:
      return <></>;
      break;
  }
};

export default ComponentFilterDatePicker;
