import React, { useState } from "react";
import { DataGrid, GridColDef, enUS } from "@mui/x-data-grid";
import { convertDateToStandardFormat } from "../_functions/FunctionDate";
import { Delete, TransferWithinAStation } from "@mui/icons-material";
import { Button } from "usertip-component-library";
import { useSelector } from "react-redux";
import ComponentAddUsers from "../ComponentAddUsers";
import {
  organizationPostInviteUserToOrganization,
  removeUserFromOrganization,
} from "../../store/reducers/reducerOrganization";
import { RootState } from "../../store";
import ComponentMoveUserBetweenRoles from "../ComponentMoveUserBetweenRoles";
import ComponentRemoveUsers from "../ComponentRemoveUsers";

export default function TableOrgCodeSnippetViewer() {
  const [showModalMove, setShowModalMove] = useState(false);
  const [showModalRemove, setShowModalRemove] = useState(false);
  // const [removeMode, setRemoveMode] = useState("");
  const [resetTableSelectAll, setResetTableSelectAll] = useState(false);

  const columns: GridColDef[] = [
    {
      field: "role_id",
      headerName: "role_id",
      flex: 0.35,
      minWidth: 173,
      editable: false,
    },
    {
      field: "domain",
      headerName: "domain",
      flex: 0.35,
      minWidth: 173,
      editable: false,
    },
    {
      field: "email",
      headerName: "email",
      flex: 0.9,
      minWidth: 280,
      editable: false,
    },
    {
      field: "unique_id",
      headerName: "unique_id",
      flex: 0.35,
      minWidth: 173,
      editable: false,
    },
    {
      field: "role",
      headerName: "role",
      flex: 0.35,
      minWidth: 173,
      editable: false,
    },
    {
      field: "createdAt",
      headerName: "createdAt",
      minWidth: 130,
      editable: false,
      valueFormatter: (params) => convertDateToStandardFormat(params.value),
    },
    {
      field: "updatedAt",
      headerName: "updatedAt",
      minWidth: 130,
      editable: false,
      valueFormatter: (params) => convertDateToStandardFormat(params.value),
    },
    {
      field: "action",
      headerName: "Action(s)",
      minWidth: 132,
      renderCell: () => <div className="flex flex-row"></div>,
    },
  ];

  const { appLoading, org_id, viewerersCodeSnippet } = useSelector(
    (state: RootState) => state.org
  );
  const viewerCodeSnippetArray = viewerersCodeSnippet || [];
  console.log(viewerCodeSnippetArray);

  //state from redux
  const orgID = useSelector((state: any) => state.user.userData.org);
  const loggedInUserRole = useSelector(
    (state: any) => state.user.userData.role
  );

  // const [showModalAdd, setShowModalAdd] = useState(false);
  // const [preloadModalState, setPreloadModalState] = useState("AddUsers");

  // const handleAddUserRoleClick = () => {
  //   // Open any Modal, as at any one time, only ONE MODAL will be pre-loaded and opened
  //   setPreloadModalState("AddUsers");
  //   setShowModalAdd(true);
  // };

  // const handleMassImportClick = () => {
  //   setPreloadModalState("MassImport");
  //   setShowModalAdd(true);
  // };

  const customLocalization = {
    ...enUS,
    noRowsLabel: "No code snippet viewers found. Please add one!",
  };

  return (
    <div>
      <div className="bg-default-primary rounded-tr-2xl rounded-tl-2xl flex flex-row justify-between px-6 py-4 items-center text-white">
        <div className="text-base font-bold">Manage Code Snippet Viewer</div>
        <div className="flex flex-row gap-2 items-center border-whi">
          {/* button here */}
        </div>
      </div>
      <DataGrid
        rows={viewerCodeSnippetArray}
        columns={columns}
        autoHeight
        checkboxSelection
        localeText={customLocalization}
        initialState={{
          pagination: {
            page: 0,
            pageSize: 25,
          },
        }}
        rowsPerPageOptions={[25, 50, 100]}
        sx={{ borderEndEndRadius: 16, borderEndStartRadius: 16 }}
      />
      {/* <ComponentAddUsers
        preloadModalState={preloadModalState}
        orgID={orgID}
        showModalAdd={showModalAdd}
        setShowModalAdd={setShowModalAdd}
        onInviteUserToOrg={organizationPostInviteUserToOrganization}
        displayUserRole={"Viewer Code Snippet"}
      /> */}
      <ComponentMoveUserBetweenRoles
        selectedUserList={viewerCodeSnippetArray}
        currentRole={""}
        showModalMove={showModalMove}
        setShowModalMove={setShowModalMove}
        moveMode={"single"}
        setResetTableSelectAll={false}
      />
      {/* move mode mode is single and bulk, check ComponentMoveUserBetweenRoles.js */}

      <ComponentRemoveUsers
        listType={"User"}
        selectedUserOrgID={orgID}
        selectedUserList={viewerCodeSnippetArray}
        onConfirmRemoveUserFromOrg={removeUserFromOrganization}
        showModalRemove={showModalRemove}
        setShowModalRemove={setShowModalRemove}
        removeMode={"single"}
        setResetTableSelectAll={setResetTableSelectAll}
        viewingPermisionsType={undefined}
        selectedWalkthroughViewingPermissionsList={undefined}
        onDeleteOneIndvWalkthroughViewingPermission={undefined}
        onHandleBulkEditIndvWalkthroughPermission={undefined}
      />
      {/* remove mode mode is single and bulk, check ComponentRemoveUsers.js */}
    </div>
  );
}
