import React, { useState } from "react";
import { GuestWalkthrough } from "../interfaces/interface Organization";
import ComponentListTable from "./ComponentListTable";

const ComponentListSharedWalkthroughsInTable = ({
  dataSet,
}: {
  dataSet: GuestWalkthrough[];
}) => {
  const [selectedWalkthroughList, setSelectedWalkthroughList] = useState([]);
  const [resetTableSelectAll, setResetTableSelectAll] = useState(false);

  // Sets the default HeaderCells
  let headerCells = [
    {
      id: "guest_name",
      label: "Guest",
      numeric: false,
      disablePadding: false,
    },
    {
      id: "date_created",
      label: "Data Created",
      numeric: false,
      disablePadding: false,
      flex: 1,
    },
    {
      id: "Last Edited",
      label: "Last Edited",
      numeric: false,
      disablePadding: false,
      flex: 1,
    },
    {
      id: "action",
      label: "Action",
      numeric: false,
      disablePadding: false,
      flex: 1,
    },
  ];
  return (
    //@ts-ignore
    <ComponentListTable
      listType={"sharedWalkthrough"}
      headerCells={headerCells}
      dataSet={dataSet}
      setSelectedWalkthroughList={setSelectedWalkthroughList}
      resetTableSelectAll={resetTableSelectAll}
      setResetTableSelectAll={setResetTableSelectAll}
    />
  );
};

export default ComponentListSharedWalkthroughsInTable;
