import React from "react";
import {
  AlignCenterIcon,
  AlignLeftIcon,
  AlignRightIcon,
  Toggle,
  ToggleItem,
} from "usertip-component-library";

interface Props {
  /** Prop for set the name of the input (to grouping Alignment input) */
  label: string;
  /** Prop for set the value of the input */
  value: string;
  /** Prop for handling the input */
  settingHandler: (value: any) => void;
}

const AlignmentSetting = ({ label, value, settingHandler }: Props) => {
  return (
    <>
      <h1 className="text-sm">Alignment:</h1>
      <Toggle name={`${label}-text-alignment`} width="full">
        <ToggleItem
          id="left"
          label="left"
          icon={<AlignLeftIcon className="w-5" />}
          value="flex-start"
          selectedValue={value}
          onSelectedValue={settingHandler}
        />
        <ToggleItem
          id="center"
          label="center"
          icon={<AlignCenterIcon className="w-5" />}
          value="center"
          selectedValue={value}
          onSelectedValue={settingHandler}
        />
        <ToggleItem
          id="right"
          label="right"
          icon={<AlignRightIcon className="w-5" />}
          value="flex-end"
          selectedValue={value}
          onSelectedValue={settingHandler}
        />
      </Toggle>
    </>
  );
};

export default AlignmentSetting;
