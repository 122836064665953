import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  BuildingBlockType,
  ModalThemeType,
  SlideoutThemeType,
  SpotlightTooltipThemeType,
  ThemeReducerState,
} from "../../interfaces/interfaceTheme";

import {
  callsGETRetrieveTheme,
  callsPOSTAddUpdateTheme,
  callsPOSTApplyTheme,
  callsPOSTApplyThemeToPublished,
  callsPOSTApplyThemeToUnpublished,
} from "../../axios/v2/callsTheme";

/** Unused style is style that can't be change */
const unusedModalStyle = {
  display: "flex",
  fade: "",
  flexDirection: "column",
  margin: 0,
};

const unusedSlideoutStyle = {
  animationSpeed: "",
  display: "flex",
  fade: "",
  flexDirection: "column",
  margin: 12,
};

const unusedSpotlightStyle = {
  animationSpeed: "",
  arrowPosition: "",
  fade: "",
  scrollAnimation: "",
};

const unusedTooltipStyle = {
  animationSpeed: "",
  arrowPosition: "",
  fade: "",
  scrollAnimation: "",
};

const unusedBbStyle = {
  alignItems: "",
  fontType: "",
  textAlign: "",
};
/** Unused style END */

/** Default modal and slidout building block style when user don't have theme */
const initialModalSlideoutBB: BuildingBlockType[] = [
  {
    label: "title",
    fontFamily: "",
    fontSize: 30,
    fontWeight: 400,
    justifyContent: "flex-start",
    lineHeight: "normal",
    letterSpacing: 0,
    color: "#000000",
    margin: 6,
    padding: 12,
    backgroundColor: "transparent",
    ...unusedBbStyle,
  },
  {
    label: "subtitle",
    fontFamily: "",
    fontSize: 14,
    fontWeight: 400,
    justifyContent: "flex-start",
    lineHeight: "normal",
    letterSpacing: 0,
    color: "#000000",
    margin: 6,
    padding: 6,
    backgroundColor: "transparent",
    ...unusedBbStyle,
  },
  {
    label: "button",
    fontFamily: "",
    fontSize: 16,
    fontWeight: 300,
    justifyContent: "center",
    lineHeight: "normal",
    letterSpacing: 0,
    color: "#000000",
    margin: 6,
    padding: 6,
    borderRadius: 6,
    backgroundColor: "#BDC1C6",
    ...unusedBbStyle,
  },
];

/** Default spotlight and tooltip building block style when user don't have theme */
const initialSpotlightTooltipBB: BuildingBlockType[] = [
  {
    label: "subtitle",
    fontFamily: "",
    fontSize: 16,
    fontWeight: 400,
    justifyContent: "flex-start",
    lineHeight: "normal",
    letterSpacing: 0,
    color: "#000000",
    margin: 6,
    padding: 12,
    backgroundColor: "transparent",
    ...unusedBbStyle,
  },
  {
    label: "button",
    fontFamily: "",
    fontSize: 16,
    fontWeight: 300,
    justifyContent: "center",
    lineHeight: "normal",
    letterSpacing: 0,
    color: "#000000",
    margin: 6,
    padding: 6,
    borderRadius: 6,
    backgroundColor: "#BDC1C6",
    ...unusedBbStyle,
  },
];

/** Default modal style when user don't have modal theme */
const initialModalTheme: ModalThemeType = {
  type: "modal",
  padding: 18,
  height: {
    value: 0,
    unit: "auto",
  },
  width: {
    value: 500,
    unit: "px",
  },
  borderRadius: 6,
  backgroundColor: "#F2F3F4",
  ...unusedModalStyle,
  buildingBlockStyle: initialModalSlideoutBB,
};

/** Default slideout style when user don't have slideout theme */
const initialSlideoutTheme: SlideoutThemeType = {
  type: "slideout",
  padding: 20,
  height: {
    value: 0,
    unit: "auto",
  },
  width: {
    value: 0,
    unit: "auto",
  },
  borderRadius: 6,
  backgroundColor: "#F2F3F4",
  position: "center",
  animation: "ut-slideout-bottom",
  ...unusedSlideoutStyle,
  buildingBlockStyle: initialModalSlideoutBB,
};

/** Default spotlight style when user don't have spotlight theme */
const initialSpotlightTheme: SpotlightTooltipThemeType = {
  type: "spotlight",
  padding: 12,
  height: {
    value: 0,
    unit: "auto",
  },
  width: {
    value: 0,
    unit: "auto",
  },
  borderRadius: 6,
  backgroundColor: "#FFFFFF",
  position: "bottom-left",
  spacing: 20,
  ...unusedSpotlightStyle,
  buildingBlockStyle: initialSpotlightTooltipBB,
};

/** Default tooltip style when user don't have tooltip theme */
const initialTooltipTheme: SpotlightTooltipThemeType = {
  type: "tooltip",
  padding: 12,
  height: {
    value: 0,
    unit: "auto",
  },
  width: {
    value: 0,
    unit: "auto",
  },
  borderRadius: 6,
  backgroundColor: "#FFFFFF",
  position: "bottom-left",
  spacing: 20,
  ...unusedTooltipStyle,
  buildingBlockStyle: initialSpotlightTooltipBB,
};

const initialState: ThemeReducerState = {
  /** modal theme that user will see at the webapp (theme that will change for save to the database) */
  modalTheme: initialModalTheme,
  /** slideout theme that user will see at the webapp (theme that will change for save to the database) */
  slideoutTheme: initialSlideoutTheme,
  /** spotlight theme that user will see at the webapp (theme that will change for save to the database) */
  spotlightTheme: initialSpotlightTheme,
  /** tooltip theme that user will see at the webapp (theme that will change for save to the database) */
  tooltipTheme: initialTooltipTheme,
  /** modal theme on database */
  dbModal: null,
  /** sildeout theme on database */
  dbSlideout: null,
  /** spotlight theme on database */
  dbSpotlight: null,
  /** tooltip theme on database */
  dbTooltip: null,
  /** indicator if modal theme changed */
  modalChange: false,
  /** indicator if slideout theme changed */
  slideoutChange: false,
  /** indicator if spotlight theme changed */
  spotlightChange: false,
  /** indicator if tooltip theme changed */
  tooltipChange: false,
  /** loading indicator */
  appLoading: false,
  /** alert for error handling or success handling */
  alert: {
    active: false,
    type: "",
    message: "",
    autoHideDuration: 6000,
  },
  /** Indicator for save and apply theme */
  themeIndicator: "",
};

/** UNUSED ASYNC THUNK == START == */
export const applyTheme = createAsyncThunk(
  "theme/applyTheme",
  async (data: { orgId: string; type: any }, thunkApi) => {
    try {
      const { orgId, type } = data;

      await callsPOSTApplyTheme(orgId, type);
    } catch (error: any) {
      const response = error.response.data;

      return thunkApi.rejectWithValue(response);
    }
  }
);

export const updateAndApplyTheme = createAsyncThunk(
  "theme/updateAndApplyTheme",
  async (data: { orgId: string; theme: any }, thunkApi) => {
    try {
      const { orgId, theme } = data;

      let newData = {};

      if (theme.type === "modal") {
        newData = { modal: theme };
      } else if (theme.type === "slideout") {
        newData = { slideout: theme };
      } else if (theme.type === "spotlight") {
        newData = { spotlight: theme };
      } else if (theme.type === "tooltip") {
        newData = { tooltip: theme };
      }

      const res = await callsPOSTAddUpdateTheme(orgId, newData);
      const payload = res.data.payload;

      if (res.status === 200) {
        await callsPOSTApplyTheme(orgId, theme.type);

        return { ...payload.theme, type: theme.type };
      } else {
        const response = res.data;
        return thunkApi.rejectWithValue(response);
      }
    } catch (error: any) {
      const response = error.response.data;

      return thunkApi.rejectWithValue(response);
    }
  }
);
/** UNUSED ASYNC THUNK == END == */

export const getTheme = createAsyncThunk(
  "theme/getTheme",
  async (orgId: string, thunkApi) => {
    try {
      const res = await callsGETRetrieveTheme(orgId);
      const payload = res.data.payload;

      return payload.theme;
    } catch (error: any) {
      const response = error.response.data;

      return thunkApi.rejectWithValue(response);
    }
  }
);

export const addOrUpdateTheme = createAsyncThunk(
  "theme/addOrUpdateTheme",
  async (data: { orgId: string; theme: any }, thunkApi) => {
    try {
      const { orgId, theme } = data;

      let newData = {};

      if (theme.type === "modal") {
        newData = { modal: theme };
      } else if (theme.type === "slideout") {
        newData = { slideout: theme };
      } else if (theme.type === "spotlight") {
        newData = { spotlight: theme };
      } else if (theme.type === "tooltip") {
        newData = { tooltip: theme };
      }

      const res = await callsPOSTAddUpdateTheme(orgId, newData);
      const payload = res.data.payload;

      return { ...payload.theme, type: theme.type };
    } catch (error: any) {
      const response = error.response.data;

      return thunkApi.rejectWithValue(response);
    }
  }
);

export const applyThemeToPublished = createAsyncThunk(
  "theme/applyThemeToPublished",
  async (data: { orgId: string; type: any }, thunkApi) => {
    try {
      const { orgId, type } = data;

      await callsPOSTApplyThemeToPublished(orgId, type);
    } catch (error: any) {
      const response = error.response.data;

      return thunkApi.rejectWithValue(response);
    }
  }
);

export const applyThemeToUnpublished = createAsyncThunk(
  "theme/applyThemeToUnpublished",
  async (data: { orgId: string; type: any }, thunkApi) => {
    try {
      const { orgId, type } = data;

      await callsPOSTApplyThemeToUnpublished(orgId, type);
    } catch (error: any) {
      const response = error.response.data;

      return thunkApi.rejectWithValue(response);
    }
  }
);

const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    changeThemeValue: (state, action) => {
      const { type, style } = action.payload;

      /** Change base theme based on type of the component */
      switch (type) {
        case "modal":
          const newModalValue = { ...state.modalTheme };
          state.modalTheme = { ...newModalValue, ...style };
          state.modalChange = true;
          break;

        case "slideout":
          const newSlideoutValue = { ...state.slideoutTheme };
          state.slideoutTheme = { ...newSlideoutValue, ...style };
          state.slideoutChange = true;
          break;

        case "spotlight":
          const newSpotlight = { ...state.spotlightTheme };
          state.spotlightTheme = { ...newSpotlight, ...style };
          state.spotlightChange = true;
          break;

        case "tooltip":
          const newTooltip = { ...state.tooltipTheme };
          state.tooltipTheme = { ...newTooltip, ...style };
          state.tooltipChange = true;
          break;
      }
    },
    changeBbThemeValue: (state, action) => {
      const { type, label, style } = action.payload;

      /** Change building block theme based on type of the component */
      switch (type) {
        case "modal":
          const newModalValue = { ...state.modalTheme };
          const modalBuildingBlock = [...newModalValue.buildingBlockStyle];

          /** Change the building block style based on label */
          const newModalBbStyle = modalBuildingBlock.map((val) => {
            if (val.label === label) {
              return { ...val, ...style };
            } else {
              return val;
            }
          });

          state.modalTheme = {
            ...newModalValue,
            buildingBlockStyle: newModalBbStyle as BuildingBlockType[],
          };

          state.modalChange = true;

          break;

        case "slideout":
          const newSlideoutValue = { ...state.slideoutTheme };
          const slideoutBuildingBlock = [
            ...newSlideoutValue.buildingBlockStyle,
          ];

          /** Change the building block style based on label */
          const newSlideoutBbStyle = slideoutBuildingBlock.map((val) => {
            if (val.label === label) {
              return { ...val, ...style };
            } else {
              return val;
            }
          });

          state.slideoutTheme = {
            ...newSlideoutValue,
            buildingBlockStyle: newSlideoutBbStyle as BuildingBlockType[],
          };

          state.slideoutChange = true;

          break;

        case "spotlight":
          const newSpotlightValue = { ...state.spotlightTheme };
          const spotlightBuildingBlock = [
            ...newSpotlightValue.buildingBlockStyle,
          ];

          /** Change the building block style based on label */
          const newSpotlightBbStyle = spotlightBuildingBlock.map((val) => {
            if (val.label === label) {
              return { ...val, ...style };
            } else {
              return val;
            }
          });

          state.spotlightTheme = {
            ...newSpotlightValue,
            buildingBlockStyle: newSpotlightBbStyle as BuildingBlockType[],
          };
          state.spotlightChange = true;

          break;

        case "tooltip":
          const newTooltipValue = { ...state.tooltipTheme };
          const tooltipBuildingBlock = [...newTooltipValue.buildingBlockStyle];

          /** Change the building block style based on label */
          const newTooltipBbStyle = tooltipBuildingBlock.map((val) => {
            if (val.label === label) {
              return { ...val, ...style };
            } else {
              return val;
            }
          });

          state.tooltipTheme = {
            ...newTooltipValue,
            buildingBlockStyle: newTooltipBbStyle as BuildingBlockType[],
          };
          state.tooltipChange = true;

          break;
      }
    },
    cancelChange: (state, action) => {
      const type = action.payload;

      /** Cancel current change based on type */
      switch (type) {
        case "modal":
          state.modalTheme = state.dbModal ? state.dbModal : initialModalTheme;
          state.modalChange = false;
          break;

        case "slideout":
          state.slideoutTheme = state.dbSlideout
            ? state.dbSlideout
            : initialSlideoutTheme;
          state.slideoutChange = false;
          break;

        case "spotlight":
          state.spotlightTheme = state.dbSpotlight
            ? state.dbSpotlight
            : initialSpotlightTheme;
          state.spotlightChange = false;
          break;

        case "tooltip":
          state.tooltipTheme = state.dbTooltip
            ? state.dbTooltip
            : initialTooltipTheme;
          state.tooltipChange = false;
          break;
      }
    },
    resetAlert: (state) => {
      state.alert = {
        active: false,
        type: "",
        message: "",
        autoHideDuration: 6000,
      };
    },
    resetThemeIndicator: (state) => {
      state.themeIndicator = "";
    },
  },
  extraReducers: (builder) => {
    /** Get theme reducers */
    builder.addCase(getTheme.pending, (state) => {
      state.appLoading = true;
    });
    builder.addCase(getTheme.fulfilled, (state, action) => {
      const { modal, slideout, spotlight, tooltip } = action.payload;

      if (modal) {
        const modalBB = modal.buildingBlockStyle;

        const newBB: BuildingBlockType[] = [];

        // This is for set default value for BB if there's BB with empty string
        modalBB.forEach((value: BuildingBlockType, index: number) => {
          // This is for preventing BB with null or undefined value
          const updatedBB = {
            ...initialModalSlideoutBB[index],
            ...value,
          } as any;

          // This is for set default value into BB
          for (const key in updatedBB) {
            if (
              updatedBB[key] === undefined ||
              updatedBB[key] === null ||
              updatedBB[key] === ""
            ) {
              updatedBB[key] =
                initialModalSlideoutBB[index][key as keyof BuildingBlockType];
            }
          }

          newBB.push(updatedBB);
        });

        // This is for prevent padding value === 0, and set it to the default value if it happens
        if (modal.padding === 0) {
          modal.padding = initialModalTheme.padding;
        }

        state.modalTheme = {
          ...initialModalTheme,
          ...modal,
          buildingBlockStyle: newBB,
        };

        state.dbModal = {
          ...initialModalTheme,
          ...modal,
          buildingBlockStyle: newBB,
        };
      }

      if (slideout) {
        const slideoutBB = slideout.buildingBlockStyle;

        const newBB: BuildingBlockType[] = [];

        // This is for set default value for BB if there's BB with empty string
        slideoutBB.forEach((value: BuildingBlockType, index: number) => {
          // This is for preventing BB with null or undefined value
          const updatedBB = {
            ...initialModalSlideoutBB[index],
            ...value,
          } as any;

          // This is for set default value into BB
          for (const key in updatedBB) {
            if (
              updatedBB[key] === undefined ||
              updatedBB[key] === null ||
              updatedBB[key] === ""
            ) {
              updatedBB[key] =
                initialModalSlideoutBB[index][key as keyof BuildingBlockType];
            }
          }

          newBB.push(updatedBB);
        });

        // this is for prevent slideout position have empty string
        if (!slideout.position) {
          slideout.position = initialSlideoutTheme.position;
        }

        state.slideoutTheme = {
          ...initialSlideoutTheme,
          ...slideout,
          buildingBlockStyle: newBB,
        };
        state.dbSlideout = {
          ...initialSlideoutTheme,
          ...slideout,
          buildingBlockStyle: newBB,
        };
      }

      if (spotlight) {
        const spotlightBB = spotlight.buildingBlockStyle;

        const newBB: BuildingBlockType[] = [];

        // This is for set default value for BB if there's BB with empty string
        spotlightBB.forEach((value: BuildingBlockType, index: number) => {
          // This is for preventing BB with null or undefined value
          const updatedBB = {
            ...initialSpotlightTooltipBB[index],
            ...value,
          } as any;

          // This is for set default value into BB
          for (const key in updatedBB) {
            if (
              updatedBB[key] === undefined ||
              updatedBB[key] === null ||
              updatedBB[key] === ""
            ) {
              updatedBB[key] =
                initialSpotlightTooltipBB[index][
                  key as keyof BuildingBlockType
                ];
            }
          }

          newBB.push(updatedBB);
        });

        // This is for prevent spotlight width have wrong type
        if (
          !spotlight.width.hasOwnProperty("value") ||
          !spotlight.width.hasOwnProperty("width")
        ) {
          spotlight.width = initialSpotlightTheme.width;
        }

        state.spotlightTheme = {
          ...initialSpotlightTheme,
          ...spotlight,
          buildingBlockStyle: newBB,
        };
        state.dbSpotlight = {
          ...initialSpotlightTheme,
          ...spotlight,
          buildingBlockStyle: newBB,
        };
      }

      if (tooltip) {
        const tooltipBB = tooltip.buildingBlockStyle;

        const newBB: BuildingBlockType[] = [];

        // This is for set default value for BB if there's BB with empty string
        tooltipBB.forEach((value: BuildingBlockType, index: number) => {
          // This is for preventing BB with null or undefined value
          const updatedBB = {
            ...initialSpotlightTooltipBB[index],
            ...value,
          } as any;

          // This is for set default value into BB
          for (const key in updatedBB) {
            if (
              updatedBB[key] === undefined ||
              updatedBB[key] === null ||
              updatedBB[key] === ""
            ) {
              updatedBB[key] =
                initialSpotlightTooltipBB[index][
                  key as keyof BuildingBlockType
                ];
            }
          }

          newBB.push(updatedBB);
        });

        // This is for prevent tooltip width have wrong type
        if (
          !tooltip.width.hasOwnProperty("value") ||
          !tooltip.width.hasOwnProperty("width")
        ) {
          tooltip.width = initialTooltipTheme.width;
        }

        state.tooltipTheme = {
          ...initialTooltipTheme,
          ...tooltip,
          buildingBlockStyle: newBB,
        };
        state.dbTooltip = {
          ...initialTooltipTheme,
          ...tooltip,
          buildingBlockStyle: newBB,
        };
      }

      state.appLoading = false;
    });
    builder.addCase(getTheme.rejected, (state, action) => {
      // let alert;

      // if (!action.payload) {
      //   alert = {
      //     active: true,
      //     type: "false",
      //     message: "Server error!",
      //     autoHideDuration: 6000,
      //   };

      //   state.appLoading = false;
      //   state.alert = alert;
      // } else {
      //   const { msg } = action.payload as { msg: string; success: boolean };

      //   alert = {
      //     active: true,
      //     type: "false",
      //     message: msg ? msg : "Server error!",
      //     autoHideDuration: 6000,
      //   };
      // }

      // state.alert = alert;
      state.appLoading = false;
    });

    /** Save theme reducers */
    builder.addCase(addOrUpdateTheme.pending, (state, action) => {
      state.appLoading = true;
      state.themeIndicator = "";
    });
    builder.addCase(addOrUpdateTheme.fulfilled, (state, action) => {
      const { modal, slideout, spotlight, tooltip, type } = action.payload;

      /** update theme state based on type */
      if (modal && type === "modal") {
        state.modalTheme = modal;
        state.dbModal = modal;
        state.modalChange = false;
      }

      if (slideout && type === "slideout") {
        state.slideoutTheme = slideout;
        state.dbSlideout = slideout;
        state.slideoutChange = false;
      }

      if (spotlight && type === "spotlight") {
        state.spotlightTheme = spotlight;
        state.dbSpotlight = spotlight;
        state.spotlightChange = false;
      }

      if (tooltip && type === "tooltip") {
        state.tooltipTheme = tooltip;
        state.dbTooltip = tooltip;
        state.tooltipChange = false;
      }

      state.appLoading = false;
      state.themeIndicator = "saved";
    });
    builder.addCase(addOrUpdateTheme.rejected, (state, action) => {
      let alert;

      if (!action.payload) {
        alert = {
          active: true,
          type: "false",
          message: "Server error!",
          autoHideDuration: 6000,
        };

        state.appLoading = false;
        state.alert = alert;
      } else {
        const { msg } = action.payload as { msg: string; success: boolean };

        alert = {
          active: true,
          type: "false",
          message: msg,
          autoHideDuration: 6000,
        };
      }

      state.appLoading = false;
      state.alert = alert;
      state.themeIndicator = "error";
    });

    /** apply theme reducers (UNUSED) */
    builder.addCase(applyTheme.pending, (state) => {
      state.appLoading = true;
    });
    builder.addCase(applyTheme.fulfilled, (state, action) => {
      state.appLoading = false;

      const alert = {
        active: true,
        type: "success",
        message: "Theme applied!",
        autoHideDuration: 6000,
      };

      state.alert = alert;
    });
    builder.addCase(applyTheme.rejected, (state, action) => {
      let alert;

      if (!action.payload) {
        alert = {
          active: true,
          type: "false",
          message: "Server error!",
          autoHideDuration: 6000,
        };
      } else {
        const { msg } = action.payload as { msg: string; success: boolean };

        alert = {
          active: true,
          type: "false",
          message: msg,
          autoHideDuration: 6000,
        };
      }

      state.appLoading = false;
      state.alert = alert;
    });

    /** Save and apply theme reducers (UNUSED) */
    builder.addCase(updateAndApplyTheme.pending, (state) => {
      state.appLoading = true;
    });
    builder.addCase(updateAndApplyTheme.fulfilled, (state, action) => {
      const { modal, slideout, spotlight, tooltip, type } = action.payload;

      /** update theme state based on type */
      if (modal && type === "modal") {
        state.modalTheme = modal;
        state.dbModal = modal;
        state.modalChange = false;
      }

      if (slideout && type === "slideout") {
        state.slideoutTheme = slideout;
        state.dbSlideout = slideout;
        state.slideoutChange = false;
      }

      if (spotlight && type === "spotlight") {
        state.spotlightTheme = spotlight;
        state.dbSpotlight = spotlight;
        state.spotlightChange = false;
      }

      if (tooltip && type === "tooltip") {
        state.tooltipTheme = tooltip;
        state.dbTooltip = tooltip;
        state.tooltipChange = false;
      }

      state.appLoading = false;

      const alert = {
        active: true,
        type: "success",
        message: "Theme saved and applied!",
        autoHideDuration: 6000,
      };

      state.alert = alert;
    });
    builder.addCase(updateAndApplyTheme.rejected, (state, action) => {
      let alert;

      if (!action.payload) {
        alert = {
          active: true,
          type: "false",
          message: "Server error!",
          autoHideDuration: 6000,
        };
      } else {
        const { msg } = action.payload as { msg: string; success: boolean };

        alert = {
          active: true,
          type: "false",
          message: msg,
          autoHideDuration: 6000,
        };
      }

      state.appLoading = false;
      state.alert = alert;
    });

    /** apply theme to unpublished walkthrough */
    builder.addCase(applyThemeToUnpublished.pending, (state) => {
      state.appLoading = true;
      state.themeIndicator = "";
    });
    builder.addCase(applyThemeToUnpublished.fulfilled, (state, action) => {
      state.appLoading = false;

      const alert = {
        active: true,
        type: "success",
        message: "Theme applied to unpublished walkthrough!",
        autoHideDuration: 6000,
      };

      state.alert = alert;
      state.themeIndicator = "applied";
    });
    builder.addCase(applyThemeToUnpublished.rejected, (state, action) => {
      let alert;

      if (!action.payload) {
        alert = {
          active: true,
          type: "false",
          message: "Server error!",
          autoHideDuration: 6000,
        };
      } else {
        const { msg } = action.payload as { msg: string; success: boolean };

        alert = {
          active: true,
          type: "false",
          message: msg,
          autoHideDuration: 6000,
        };
      }

      state.appLoading = false;
      state.alert = alert;
      state.themeIndicator = "error";
    });

    /** apply theme to published walkthrough */
    builder.addCase(applyThemeToPublished.pending, (state) => {
      state.appLoading = true;
    });
    builder.addCase(applyThemeToPublished.fulfilled, (state, action) => {
      state.appLoading = false;

      const alert = {
        active: true,
        type: "success",
        message: "Theme applied to published walkthrough!",
        autoHideDuration: 6000,
      };

      state.alert = alert;
    });
    builder.addCase(applyThemeToPublished.rejected, (state, action) => {
      let alert;

      if (!action.payload) {
        alert = {
          active: true,
          type: "false",
          message: "Server error!",
          autoHideDuration: 6000,
        };
      } else {
        const { msg } = action.payload as { msg: string; success: boolean };

        alert = {
          active: true,
          type: "false",
          message: msg,
          autoHideDuration: 6000,
        };
      }

      state.appLoading = false;
      state.alert = alert;
    });
  },
});

export default themeSlice.reducer;
export const {
  changeThemeValue,
  changeBbThemeValue,
  cancelChange,
  resetAlert,
  resetThemeIndicator,
} = themeSlice.actions;
