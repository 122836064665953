import React, { useEffect, useState, useMemo } from "react";

import ComponentEditListGenerator from "./ComponentEditListGenerator";
import ComponentEditListBtnArray from "./ComponentEditListBtnArray";
import ComponentAlertSnackbar from "./ComponentAlertSnackbar";

import {
  filterDuplicatedMembersByEmail,
  not,
  intersection,
  union,
  numberOfChecked,
  checkMembersToAdd,
  checkMembersToRemove,
} from "./_functions/FunctionEditList";
import { useDispatch } from "react-redux";
import { EmptyModal, Button } from "usertip-component-library";

const ComponentEditWalkthroughsViewingPermissions = ({
  dataSet,
  permissionDataSet,

  walkthroughID,
  onHandleBulkEditIndvWalkthroughPermission,

  showModalEdit,
  setShowModalEdit,

  editMode,
}) => {
  const [showAlertSnackbar, setShowAlertSnackbar] = useState(false);
  const [selectedWalkthroughID, setSelectedWalkthroughID] = useState("");

  const membersListName = `All Users`;
  const membersTeamListName = `Selected Users`;

  // Setting List-Store Variables
  const [checked, setChecked] = useState([]);
  const [memberTeamDataSet, setMemberTeamDataSet] = useState([]);

  // After identifying the respective list, Populate them into the Team List.
  // Right-Hand-Side List Data
  const [memberTeamList, setMemberTeamList] = useState([]);

  // Make a copy of "All Users", then remove them if they are duplicated Members already on MembersTeam List
  let membersDataSet = useMemo(
    () => filterDuplicatedMembersByEmail(dataSet, permissionDataSet),
    [permissionDataSet, dataSet]
  );

  // "permissionDataSet" will store Existing "Builders / Viewers" List
  // Left-Hand-Side List Data
  const [membersList, setMembersList] = useState([]);

  const dispatch = useDispatch();

  // Tracks changes in "membersDataSet". Update when new change is available.
  useEffect(() => {
    // Only update "membersList" when "membersDataSet" is being filtered & has new data changes.
    if (membersDataSet) {
      setMembersList(membersDataSet);
    }
  }, [membersDataSet]);

  // Tracks changes in "permissionDataSet". Update when new change is available.
  useEffect(() => {
    const checkSelectedWalkthroughID = walkthroughID;
    // Updates WalkthroughID when changed
    if (checkSelectedWalkthroughID !== selectedWalkthroughID) {
      setSelectedWalkthroughID(walkthroughID);
    }

    // Updates data to Modal view for Left + Right Lists
    if (permissionDataSet && permissionDataSet !== undefined) {
      setMemberTeamDataSet([...permissionDataSet]);
      setMemberTeamList([...permissionDataSet]);
    }
    // If "permissionDataSet" is undefined, set lists to empty to avoid error being thrown
    else {
      setMemberTeamDataSet([]);
      setMemberTeamList([]);
    }
  }, [membersDataSet, permissionDataSet, selectedWalkthroughID, walkthroughID]);

  const handleEditTeamsFormSubmission = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (editMode === "bulk") {
      // Due to API structure, Compare differences between existing & new, then separate the data for processes seaparately for ADD + REMOVE
      //
      // When New Listings are confirmed:
      // 1. Update "membersList" to existing "Builders / Viewers" List
      // 2. Update "memberTeamList" to existing "Builder Team / Viewer Team" List
      // FIXME: data comparison is wrong
      const membersToAdd = checkMembersToAdd(memberTeamList, memberTeamDataSet);
      const membersToRemove = checkMembersToRemove(membersList, membersDataSet);

      // // Checks if Both Add + Remove Lists have Changed
      // if (membersToAdd.length === 0 && membersToRemove.length === 0) {
      //     setShowAlertSnackbar(true);
      // }
      // else {
      //     // if memberTeam list on right hand side has been updated, call server API
      //     // Push the following updates to the Server API
      // }
      const walkthroughData = {
        walkthrough_id: walkthroughID,
        users: memberTeamList,
      };
      dispatch(onHandleBulkEditIndvWalkthroughPermission(walkthroughData));
      handleHideModal();
    }
  };

  // Modal close handler
  const handleHideModal = () => {
    // Need to RESET all the original fields if "CONFIRM" Button Not Clicked
    setMemberTeamList(memberTeamDataSet);
    setMembersList(membersDataSet);
    // RESET Snackbar as well
    setShowAlertSnackbar(false);

    setShowModalEdit(false);
  };

  return (
    <EmptyModal
      isOpen={showModalEdit}
      onClose={handleHideModal}
      customClass="ut-modal-lg-percentage"
      size="large"
    >
      <div className="modalHeader">
        {editMode === "bulk" ? `Edit Viewing Permissions` : ""}
      </div>

      {editMode === "bulk" && (
        <div className="modalSubHeader">
          Edit <i>Users(s)</i> Permissions for <b>{`${membersTeamListName}`}</b>
          .
        </div>
      )}

      <form
        noValidate
        autoComplete="off"
        onSubmit={handleEditTeamsFormSubmission}
        className="modalForm"
      >
        <div className="inline-flex mb-8">
          <ComponentEditListGenerator
            numberOfChecked={numberOfChecked}
            checked={checked}
            setChecked={setChecked}
            not={not}
            union={union}
            title={membersListName}
            members={membersList}
            displayDetails={"withRole"}
          />

          <ComponentEditListBtnArray
            checked={checked}
            setChecked={setChecked}
            membersList={membersList}
            memberTeamList={memberTeamList}
            setMembersList={setMembersList}
            setMemberTeamList={setMemberTeamList}
            not={not}
            intersection={intersection}
          />

          <ComponentEditListGenerator
            numberOfChecked={numberOfChecked}
            checked={checked}
            setChecked={setChecked}
            not={not}
            union={union}
            title={`${membersTeamListName}`}
            members={memberTeamList}
            displayDetails={"withRole"}
          />
        </div>

        <div className="modalActionBtnBar">
          <Button
            variant="primary"
            color="primary"
            size="medium"
            text="Cancel"
            onClick={handleHideModal}
          />

          <Button
            variant="primary"
            color="primary"
            size="medium"
            text="Confirm"
          />
        </div>
      </form>

      <ComponentAlertSnackbar
        showAlertSnackbar={showAlertSnackbar}
        setShowAlertSnackbar={setShowAlertSnackbar}
        variant={"error"}
        title="Please make changes to Permissions List to Confirm."
      />
    </EmptyModal>
  );
};

export default ComponentEditWalkthroughsViewingPermissions;
