import React from "react";

import { Stack, Divider } from "@mui/material";

import SettingsIcon from "@mui/icons-material/Settings";
import GroupIcon from "@mui/icons-material/Group";
import TranslateIcon from "@mui/icons-material/Translate";
import ListAltIcon from "@mui/icons-material/ListAlt";
import InsightsIcon from "@mui/icons-material/Insights";

import ComponentPageHeaderLinksBtn from "./ComponentPageHeaderLinksBtn";

type linkArrayType =
  | "Walkthroughs"
  | "GroupIndividualWalkthroughs"
  | "GroupWalkthroughs";

interface Props {
  linkArrayType: linkArrayType;
  walkthroughID: string;
  headerLinksList: { label: string; disabled: boolean }[];
}

const ComponentPageHeaderLinks = ({
  linkArrayType,
  walkthroughID,
  headerLinksList,
}: Props) => {
  const generateHeaderLinksBtnIcon = (btnLabel: string) => {
    switch (btnLabel) {
      case "Settings":
        return <SettingsIcon fontSize="small" />;
      case "Viewing Permissions":
        return <GroupIcon fontSize="small" />;
      case "Translate":
        return <TranslateIcon fontSize="small" />;
      case "Analytics":
        return <InsightsIcon fontSize="small" />;
      case "Group Walkthrough List":
        return <ListAltIcon fontSize="small" />;
      case "Group Viewing Permissions":
        return <GroupIcon fontSize="small" />;

      default:
        return null;
    }
  };

  const generateHeaderLinksBtnURL = (
    linkArrayType: linkArrayType,
    btnLabel: string,
    walkthroughID: string
  ) => {
    if (linkArrayType === "Walkthroughs") {
      switch (btnLabel) {
        case "Settings":
          return `/guides/walkthroughs/${walkthroughID}/settings/general`;
        case "Viewing Permissions":
          return `/guides/walkthroughs/${walkthroughID}/settings/viewing-permissions`;
        case "Translate":
          return `/guides/walkthroughs/${walkthroughID}/settings/translate`;
        case "Analytics":
          return `/guides/walkthroughs/${walkthroughID}/analytics`;

        default:
          return null;
      }
    } else if (linkArrayType === "GroupIndividualWalkthroughs") {
      switch (btnLabel) {
        case "Settings":
          return `/guides/walkthroughs/groups/${walkthroughID}/settings/general`;
        case "Viewing Permissions":
          return `/guides/walkthroughs/groups/${walkthroughID}/settings/viewing-permissions`;
        case "Translate":
          return `/guides/walkthroughs/groups/${walkthroughID}/settings/translate`;

        default:
          return null;
      }
    } else if (linkArrayType === "GroupWalkthroughs") {
      switch (btnLabel) {
        case "Group Walkthrough List":
          return `/guides/walkthroughs/groups/${walkthroughID}/list`;
        case "Group Viewing Permissions":
          return `/guides/walkthroughs/groups/${walkthroughID}/viewing-permissions`;

        default:
          return null;
      }
    }
  };

  return (
    <Stack
      direction={"row"}
      divider={<Divider orientation="vertical" flexItem />}
      spacing={1}
      sx={{
        marginBottom: 3,
      }}
    >
      {headerLinksList &&
        headerLinksList.map((link) => (
          <ComponentPageHeaderLinksBtn
            key={link.label}
            btnURL={generateHeaderLinksBtnURL(
              linkArrayType,
              link.label,
              walkthroughID
            )}
            btnIcon={generateHeaderLinksBtnIcon(link.label)!}
            btnLabel={link.label}
            btnDisabled={link.disabled}
          />
        ))}
    </Stack>
  );
};

export default ComponentPageHeaderLinks;
