import React from "react";

const PageFooter = () => {
  const list = [
    { id: 1, label: "usertip.com", link: "https://www.usertip.com" },
    { id: 2, label: "Support", link: "mailto:support@usertip.com" },
    { id: 3, label: "Pricing", link: "https://www.usertip.com/pricing" },
    {
      id: 4,
      label: "Terms",
      link: "https://www.usertip.com/terms-and-conditions",
    },
    { id: 5, label: "Privacy", link: "https://www.usertip.com/privacy-policy" },
    {
      id: 6,
      label: "Digital Adoption Privacy",
      link: "https://www.usertip.com/digital-adoption-platform-privacy-policy",
    },
  ];

  return (
    <div className="absolute bottom-0 flex flex-wrap justify-center gap-2 text-xs text-default-neutral-60 py-3">
      {list.map((val, index) => {
        if (index === list.length - 1) {
          return (
            <a
              href={val.link}
              key={val.id}
              target="_blank"
              className="no-underline active:text-default-neutral-60 visited:text-default-neutral-60 hover:no-underline"
            >
              {val.label}
            </a>
          );
        }

        return (
          <React.Fragment key={val.id}>
            <a
              href={val.link}
              target="_blank"
              className="no-underline active:text-default-neutral-60 visited:text-default-neutral-60 hover:no-underline"
            >
              {val.label}
            </a>
            <p>•</p>
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default PageFooter;
