import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, EmptyModal, InputField } from "usertip-component-library";
import { renameGuide } from "../store/reducers/reducerGuide";

interface Props {
  isOpen: boolean;
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
  guide: { guideName: string; guideId: string };
  setGuide: React.Dispatch<
    React.SetStateAction<{ guideName: string; guideId: string }>
  >;
}

const ComponentEditGuidesName = ({
  isOpen,
  onClose,
  guide,
  setGuide,
}: Props) => {
  const [oldName, setOldName] = useState("");
  const [newGuide, setNewGuide] = useState<{
    guideName: string;
    guideId: string;
  }>({ guideName: "", guideId: "" });

  const dispatch = useDispatch();

  useEffect(() => {
    setOldName(guide.guideName);
    setNewGuide(guide);
  }, []);

  const handleHideModal = () => {
    setGuide({ guideName: "", guideId: "" });
    onClose(false);
  };

  const handleChange = (e: any) => {
    setNewGuide({ ...guide, guideName: e.target.value });
  };

  const handleSubmitModal = () => {
    const renameData = {
      newGuideName: newGuide.guideName,
      guideId: newGuide.guideId,
    };

    dispatch(renameGuide(renameData));
    handleHideModal();
  };

  return (
    <EmptyModal
      size="large"
      customClass="ut-py-5"
      isOpen={isOpen}
      onClose={handleHideModal}
    >
      <div className="modalHeader">Change Guide Name</div>
      <div className="modalSubHeader">
        Update the guide name by entering the new name and clicking 'Save'.
      </div>

      <div className="modalForm">
        <div className="modalInputTextfield">
          <InputField
            variant="text"
            label="Enter new guide name"
            placeholder="Enter new guide name"
            onChange={handleChange}
            customWidth="ut-w-full"
            autoFocus={true}
            value={newGuide.guideName}
          />
        </div>

        <div className="flex justify-end gap-2">
          <Button
            color="secondary"
            variant="primary"
            size="medium"
            text="Cancel"
            onClick={handleHideModal}
          />
          <Button
            onClick={handleSubmitModal}
            size="medium"
            text="Confirm"
            variant="primary"
            color="primary"
            disabled={oldName === newGuide.guideName}
          />
        </div>
      </div>
    </EmptyModal>
  );
};

export default ComponentEditGuidesName;
