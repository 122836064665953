import React, { useEffect, useState } from "react";
import ComponentLoading from "../components/ComponentLoading";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import TableAllGuides from "../components/Table/TableAllGuides";
import { Tags } from "usertip-component-library";
import {
  AllGuideData,
  getGuides,
  getRestOfGuide,
  resetAlert,
} from "../store/reducers/reducerGuide";
import ComponentFilterTable from "../components/ComponentFilterTable";
import "react-datepicker/dist/react-datepicker.css";
import ComponentAlertAppOverallSnackbar from "../components/ComponentAlertAppOverallSnackbar";

const PageAllGuide = () => {
  const [filteredGuide, setFilteredGuide] = useState<AllGuideData[]>([]);

  const isLoading = useSelector((state: RootState) => state.guide.appLoading);

  const { allGuide, alert, totalGuides } = useSelector(
    (state: RootState) => state.guide
  );
  const { org } = useSelector((state: RootState) => state.user.userData);

  const dispatch = useDispatch();

  /** This filter list for the guides */
  const guideFilter = [
    {
      field: "name",
      type: "String",
      label: "Guides",
    },
    {
      field: "guideType",
      type: "GuideType",
      label: "Guide Type",
    },
    {
      field: "createdAt",
      type: "Date",
      label: "Date Created",
    },
  ];

  /** This is for fetching all guide */
  useEffect(() => {
    if (org) {
      dispatch(getGuides(org));
    }
  }, [org]);

  console.log(totalGuides);

  useEffect(() => {
    if (allGuide && org) {
      if (allGuide.length < totalGuides) {
        dispatch(getRestOfGuide(org));
      }
    }
  }, [allGuide]);

  return (
    <>
      <ComponentLoading show={isLoading} />
      <div className="pageBase">
        <div className="flex gap-4 items-center">
          <div className="pageHeader">List of Guides</div>
          <div className="cursor-default">
            <Tags
              size="medium"
              text={`${allGuide?.length} Guides`}
              variant="filled"
            />
          </div>
        </div>
        <div className="text-[#9CA3AF] ">View and manage guides here.</div>
      </div>
      {allGuide && (
        <ComponentFilterTable
          filters={guideFilter}
          originalData={allGuide}
          setFilteredData={setFilteredGuide}
        />
      )}
      <TableAllGuides guideData={filteredGuide} />

      {alert !== null && alert.type !== "" && alert.type !== undefined && (
        <ComponentAlertAppOverallSnackbar
          alert={alert}
          reset={() => dispatch(resetAlert())}
        />
      )}
    </>
  );
};

export default PageAllGuide;
