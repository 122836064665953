import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import AddBoxIcon from "@mui/icons-material/AddBox";
import ComponentEditListSharedWalkthroughsInTable from "./ComponentEditListSharedWalkthroughsInTable";
import ComponentEditShareWalkhtoughs from "./ComponentEditShareWalkhtoughs";
import { useDispatch, useSelector } from "react-redux";
import { organizationGetGroupedOrgAndWalkthroughs } from "../store/reducers/reducerOrganization";
import {
  GuestWalkthrough,
  HostWalkthrough,
  IndividualOrg,
  WalkthroughList,
} from "../interfaces/interface Organization";
import { RootState } from "../store";

interface Props {
  dataSet: IndividualOrg;
  displayShowEditButton: boolean;
  introMsg: string;
  indicatorMsg: string;
  hostOrgID: string;
  hostWalkthroughs: WalkthroughList;
}
const ComponentEditListWalkthroughs = ({
  dataSet,
  displayShowEditButton,
  introMsg,
  indicatorMsg,
  hostOrgID,
  hostWalkthroughs,
}: Props) => {
  const dispatch = useDispatch();
  const [showModalAdd, setShowModalAdd] = useState(false);

  const sharedWalkthroughs = useSelector(
    (state: RootState) => state.org.sharedWalkthroughs
  ) as GuestWalkthrough[];

  useEffect(() => {
    dispatch(organizationGetGroupedOrgAndWalkthroughs(hostOrgID));
  }, [hostOrgID, dispatch]);
  const handleEditSharedWalkthrough = () => {
    setShowModalAdd(true);
  };
  return (
    dataSet !== undefined && (
      <div className="pageBase">
        <div className="pageHeader">
          List of {dataSet.guest_org_name} Walkthroughs
        </div>

        <div className="pageSubHeader">{introMsg}</div>

        <div className="pageAddBtnBar">
          {
            // If displayShowAddBtn is true, show the button for adding new shared walkthrough
            displayShowEditButton && (
              <Button
                className="pageAddBtn"
                variant={"outlined"}
                aria-label={`Edit Walkthrough`}
                startIcon={<AddBoxIcon />}
                onClick={() => handleEditSharedWalkthrough()}
                disableElevation
              >
                Edit Walkthrough
              </Button>
            )
          }
        </div>

        {
          // If dataSet is not empty, Display table with data, else Display IndicatorMsg
          dataSet.sharedWalkthroughList.length > 0 ? (
            <ComponentEditListSharedWalkthroughsInTable
              dataSet={dataSet}
              hostOrgID={hostOrgID}
            />
          ) : (
            <div className="pageIndicatorMsg">{indicatorMsg}</div>
          )
        }
        <div className="pageDivider" />
        <ComponentEditShareWalkhtoughs
          // dataset is for overall shared walkthhrough from host organization
          dataSet={
            //@ts-ignore
            !sharedWalkthroughs && sharedWalkthroughs.length < 1
              ? null
              : sharedWalkthroughs
          }
          showModalAdd={showModalAdd}
          setShowModalAdd={setShowModalAdd}
          //selected row is walkthrough that already belongs to guest organization
          selectedRow={dataSet.sharedWalkthroughList}
          hostOrgID={hostOrgID}
          hostWalkthroughs={hostWalkthroughs.walkthrough}
        />
      </div>
    )
  );
};

export default ComponentEditListWalkthroughs;
