import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import React, { useEffect, useState } from "react";
import ComponentAddUsersMassImport from "../ComponentAddUsersMassImport";
import { saveAs } from "file-saver";
import { USERTIP_STATIC_URL } from "../../constants";
import ComponentAlertSnackbar from "../ComponentAlertSnackbar";
import { useDispatch, useSelector } from "react-redux";
import ComponentAddUsersEmailsToChips from "./ComponentAddUsersEmailsToChipsInviteBtn";
import { organizationPostInviteUserToOrganization } from "../../store/reducers/reducerOrganization";
import { setIsEmpty } from "../../store/reducers/reducerInviteQuickAccess";

interface ModalProps {
  close?: () => void;
}

const InviteMembersModal: React.FC<ModalProps> = ({ close }) => {
  // to select role (admin, builder, viewer)
  const [selectedValue, setSelectedValue] = useState("");

  const dispatch = useDispatch();

  // to disable confirm button on invite member modal if the role not selected
  useEffect(() => {
    // When selectedValue changes, dispatch the action to set isEmpty
    dispatch(setIsEmpty(selectedValue === ""));
  }, [selectedValue]);

  // to handle value of selected role
  const handleRadioChange = (event: any) => {
    setSelectedValue(event.target.value);
  };

  console.log(selectedValue);

  // mass import
  const [showMassImport, setShowMassImport] = useState(false);

  const handleMassImportClick = () => {
    setShowMassImport(true);
  };

  const [showAlertSnackbar, setShowAlertSnackbar] = useState(false);
  const [showAlertSnackbarMsg, setShowAlertSnackbarMsg] = useState("");

  const downloadCSVFile = () => {
    saveAs(
      `${USERTIP_STATIC_URL}/csv/mass_import_template.csv`,
      "mass_import_template.csv"
    );
  };

  // add user
  const orgID = useSelector((state: any) => state.user.userData.org);
  // const orgID = "qwerttyy";
  const [usersEmails, setUsersEmails] = useState([]);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState({ option: false, message: "" });

  // Reset helps to realign keys to count from 0 to length of array, else it will throw erros when an email is deleted in between the array and a new one is added
  const resetUsersEmailsKeys = () => {
    for (let i = 0; i < usersEmails.length; i++) {
      // @ts-ignore
      usersEmails[i].key = i;
    }
  };

  // Remove user email input field warning.
  const resetEmailErrorHandler = () => {
    setEmailError({ option: false, message: "" });
  };

  const resetEmailHandler = () => {
    setEmail("");
  };

  return (
    <div className="ut-modal-overlay">
      {!showMassImport ? (
        <div className="bg-white w-[512px] rounded-[10px] px-5">
          <div className="py-[25px] flex flex-row justify-between ">
            <span className="font-bold text-2xl">Add new members</span>
            <span
              className="cursor-pointer font-bold text-lg text-[#808096]"
              onClick={close}
            >
              x
            </span>
          </div>
          <span className="border-b border-[#d3d3d3] block w-[512px] transform -translate-x-5"></span>
          <div className="py-[25px]">
            <RadioGroup
              aria-label={"invite-member-role"}
              name={"invite-member-role"}
              value={selectedValue}
              onChange={handleRadioChange}
            >
              <FormControlLabel
                control={<Radio />}
                label={"Admin"}
                value={"Admin"}
              />
              <FormControlLabel
                control={<Radio />}
                label={"Builder"}
                value={"Builder"}
              />
              <FormControlLabel
                control={<Radio />}
                label={"Viewer"}
                value={"Viewer"}
              />
            </RadioGroup>
          </div>
          <div className="py-4">
            <ComponentAddUsersEmailsToChips
              orgID={orgID}
              onInviteUserToOrg={organizationPostInviteUserToOrganization}
              displayUserRole={selectedValue}
              usersEmails={usersEmails}
              setUsersEmails={setUsersEmails}
              email={email}
              setEmail={setEmail}
              emailError={emailError}
              setEmailError={setEmailError}
              handleHideModal={close}
              handleMassImportClick={handleMassImportClick}
              resetUsersEmailsKeys={resetUsersEmailsKeys}
              resetEmailErrorHandler={resetEmailErrorHandler}
              resetEmailHandler={resetEmailHandler}
            />
          </div>
        </div>
      ) : (
        <div className="bg-white w-[512px] rounded-[10px] px-5 py-5">
          <ComponentAddUsersMassImport
            setUsersEmails={setUsersEmails}
            handleHideModal={close}
            handleBackToAddUsersClick={() => setShowMassImport(false)}
            setModalPageState="MassImport"
            setShowAlertSnackbar={setShowAlertSnackbar}
            setShowAlertSnackbarMsg={setShowAlertSnackbarMsg}
            // @ts-ignore
            showAlertSnackbarMsg={showAlertSnackbarMsg}
            downloadCSVFile={downloadCSVFile}
          />
        </div>
      )}
      <ComponentAlertSnackbar
        showAlertSnackbar={showAlertSnackbar}
        setShowAlertSnackbar={setShowAlertSnackbar}
        variant={"error"}
        title={showAlertSnackbarMsg}
      />
    </div>
  );
};

export default InviteMembersModal;
