import React from "react";

import {
  TableHead,
  TableCell,
  TableRow,
  TableSortLabel,
  Checkbox,
} from "@mui/material";

const ComponentListTableHeader = ({
  listType,

  headerCells,
  dataSet,

  setSelectedRows,
  numOfRowsSelected,
  setNumOfRowsSelected,
  totalRowCount,

  order,
  setOrder,
  orderBy,
  setOrderBy,
}) => {
  // Handles the different types of Sort-Orders to be update for processing
  const handleReSortRows = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // Handles the "Select All Rows Checkbox" click
  const handleSelectAllRowsClick = (event) => {
    // If "Parent Checkbox" is checked
    if (event.target.checked) {
      // Map a copy of "dataSet" array and push it into "selectedRows", to activate the checkboxes
      let newlySelected = null;

      // Collate the different "keys" of the DataTable for different "listType" of DataTables displayed
      if (listType === "User") {
        // Checks if current "dataSet" includes "owner"
        if (dataSet.map((user) => user.role).includes("owner")) {
          // eslint-disable-next-line
          newlySelected = dataSet
            .map((newlySelectedRow) => {
              if (newlySelectedRow.role !== "owner") {
                return newlySelectedRow.role_id;
              } else {
                return undefined;
              }
            })
            .filter((clearUndefined) => clearUndefined !== undefined); // Removes "undefined" objs from array
        } else {
          // Handle "dataSet" handling as per normal
          newlySelected = dataSet.map(
            (newlySelectedRow) => newlySelectedRow.role_id
          );
        }
      } else if (listType === "Team") {
        newlySelected = dataSet.map((newlySelectedRow) => newlySelectedRow._id);
      } else if (listType === "Group") {
        newlySelected = dataSet.map(
          (newlySelectedRow) => newlySelectedRow.name
        );
      } else if (listType === "Walkthrough") {
        newlySelected = dataSet.map(
          (newlySelectedRow) => newlySelectedRow.walkthrough_id
        );
      } else if (listType === "GroupWalkthrough") {
        newlySelected = dataSet.map(
          (newlySelectedRow) => newlySelectedRow.name
        );
      } else if (listType === "WalkthroughViewingPermissions") {
        newlySelected = dataSet.map(
          (newlySelectedRow) => newlySelectedRow.role_id
        );
      } else if (listType === "WalkthroughViewerTeamPermissions") {
        newlySelected = dataSet.map(
          (newlySelectedRow) => newlySelectedRow.name
        );
      } else if (listType === "editSharedWalkthrough") {
        newlySelected = dataSet.map(
          (newlySelectedRow) => newlySelectedRow.walkthrough_id
        );
      } else if (listType === "editSharedWalkthrough") {
        newlySelected = dataSet.map(
          (newlySelectedRow) => newlySelectedRow.walkthrough_id
        );
      }
      // Update the "keys" list to "selectedRows"
      setSelectedRows(newlySelected);
      // Update total count of the "selectedRows" so that number will be displayed
      setNumOfRowsSelected(newlySelected.length);
    }
    // Else, reset to empty
    else {
      setSelectedRows([]);
      setNumOfRowsSelected(0);
    }
  };

  return (
    <TableHead>
      <TableRow>
        {/* hide checkbox */}
        {listType !== "sharedWalkthrough" && listType !== "hostWalkthrough" && (
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={
                numOfRowsSelected > 0 && numOfRowsSelected < totalRowCount
              }
              checked={totalRowCount > 0 && numOfRowsSelected === totalRowCount}
              onChange={handleSelectAllRowsClick}
              inputProps={{ "aria-label": "select-all-users" }}
            />
          </TableCell>
        )}

        {
          // Generate all the Header Cells with Sortable Controls
          headerCells.map((headerCell, headerIndex) => (
            <TableCell
              key={headerIndex}
              align={headerCell.numeric ? "right" : "left"}
              padding={headerCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headerCell.id ? order : false}
              className={
                headerCell.isSticky ? "sticky-end-single-column-header" : ""
              }
              // for cypress test
              datatype={headerCell.id}
            >
              {
                // Display Header with "Sorting Arrow" for all Headers, except "Action", "Tags", "Plugin" & "Code Snippet"
                headerCell.id !== "action" &&
                headerCell.id !== "tags" &&
                headerCell.id !== "plugin" &&
                headerCell.id !== "code_snippet" ? (
                  <TableSortLabel
                    active={orderBy === headerCell.id}
                    direction={orderBy === headerCell.id ? order : "asc"}
                    onClick={(event) => handleReSortRows(event, headerCell.id)}
                  >
                    {headerCell.label}
                    {orderBy === headerCell.id ? (
                      <span className="hidden">
                        {order === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </span>
                    ) : null}
                  </TableSortLabel>
                ) : (
                  <>{headerCell.label}</>
                )
              }
            </TableCell>
          ))
        }
      </TableRow>
    </TableHead>
  );
};

export default ComponentListTableHeader;
