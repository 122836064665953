import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ComponentAlertAppOverallSnackbar from "../components/ComponentAlertAppOverallSnackbar";
import ComponentEditListWalkthroughs from "../components/ComponentEditListWalkthroughs";
import ComponentLoading from "../components/ComponentLoading";
import { IndividualOrg } from "../interfaces/interface Organization";
import { RootState } from "../store";
import {
  organizationGetGroupedOrgAndWalkthroughs,
  organizationGetHostWalkthrough,
  resetAlert,
} from "../store/reducers/reducerOrganization";

const PageSharedWalkthroughEditList = () => {
  const guestID = window.location.pathname.split("/")[3];

  const [currentWalktrough, setCurrentWalkthrough] = useState<
    undefined | IndividualOrg
  >();

  const hostOrgID = useSelector((state: RootState) => state.user.userData.org);
  const sharedWalkthroughs = useSelector(
    (state: RootState) => state.org.sharedWalkthroughs
  );
  const hostWalkthroughs = useSelector(
    (state: RootState) => state.org.hostWalkthroughs
  );

  const isLoading = useSelector((state: RootState) => state.org.appLoading);
  const alert = useSelector((state: RootState) => state.org.alert);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(organizationGetGroupedOrgAndWalkthroughs(hostOrgID!));
    dispatch(organizationGetHostWalkthrough(hostOrgID!));
  }, [dispatch, hostOrgID]);

  useEffect(() => {
    if (sharedWalkthroughs.length > 0) {
      const findWalkthrough = sharedWalkthroughs.find(
        (arr) => arr.guest_org_id === guestID
      );
      //@ts-ignore
      setCurrentWalkthrough(findWalkthrough);
    }
  }, [sharedWalkthroughs, guestID]);

  return (
    <>
      <ComponentLoading show={isLoading} />
      <div className="pageBase">
        {/*@ts-ignore */}
        <ComponentEditListWalkthroughs
          displayShowEditButton={true}
          introMsg={"View and manage shared walkthroughs here"}
          indicatorMsg={"No walkthroughs found. Please add one!"}
          dataSet={currentWalktrough!}
          hostOrgID={hostOrgID!}
          hostWalkthroughs={hostWalkthroughs!}
        />
      </div>
      {alert.type !== "" && alert.type !== undefined && (
        <ComponentAlertAppOverallSnackbar
          alert={alert}
          reset={() => dispatch(resetAlert())}
        />
      )}
    </>
  );
};

export default PageSharedWalkthroughEditList;
