import React, { useState, useEffect } from "react";
import { connect, shallowEqual, useDispatch, useSelector } from "react-redux";

import * as actions from "../store/actions/_actionIndex";

import { FormControl } from "@mui/material";
import {
  Button,
  InputField,
  Tooltip,
  Radio as UtRadio,
} from "usertip-component-library";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import ComponentPlanLimitWarning from "./OrgPlan/ComponentPlanLimitWarning";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

const ComponentSettingsWalkthroughLaunchAction = ({
  walkthrough,
  updateSettings,

  onDispatchErrorMsg,
  // lastSettingsUpdated,
}) => {
  //state from redux
  const lastSettingsUpdated = useSelector(
    (state) => state.walkthrough.resource_update_datetime,
    shallowEqual
  );

  const [walkthroughTargetAction, setWalkthroughTargetAction] = useState("");
  const [walkthroughTargetDomainList, setWalkthroughTargetDomainList] =
    useState([{ url: null, showURLError: true }]);

  // const [originalWalkthroughTargetDomainList, setOriginalWalkthroughTargetDomainList] = useState([]);
  // const originalWalkthroughTargetDomainList = walkthrough.settings ? [...walkthrough.settings.launch.page_show.domains] : []

  const [walkthroughLaunchAction, setWalkthroughLaunchAction] = useState("");
  const [walkthroughFrequencyAction, setWalkthroughFrequencyAction] =
    useState("");

  const [updateSettingsRequired, setUpdatedSettingsRequired] = useState(false);

  const [limitReach, setLimitReach] = useState(false);

  const isValidUrl = (url) => {
    // eslint-disable-next-line
    return (
      url.match(
        /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
      ) !== null
    );
  };

  // Tracks "walkthrough" and updates Form Data when new data exists
  useEffect(() => {
    if (walkthrough.settings) {
      // console.log('walkthrough.settings.launch',walkthrough.settings.launch)
      const launchSettings = walkthrough.settings.launch;

      // Reset Target Action Settings based off Server Data
      setWalkthroughTargetAction(launchSettings.page_show.option);
      // setOriginalWalkthroughTargetDomainList([...launchSettings.page_show.domains]);
      // console.log('launchSettings.page_show.domains.length',launchSettings.page_show.domains.length)
      if (launchSettings.page_show.domains.length > 0) {
        // console.log([...launchSettings.page_show.domains])
        setWalkthroughTargetDomainList([...launchSettings.page_show.domains]);
      }

      // Reset Launch Action Settings based off Server Data
      setWalkthroughLaunchAction(launchSettings.launch_action.option);
      // Reset Frequency Action Settings based off Server Data
      setWalkthroughFrequencyAction(launchSettings.frequency.option);

      setUpdatedSettingsRequired(false);
    }
  }, [walkthrough.settings, lastSettingsUpdated]);

  useEffect(() => {
    // console.log('walkthroughTargetDomainList', walkthroughTargetDomainList)
  }, [walkthroughTargetDomainList, walkthroughTargetDomainList.length]);

  // Walkthrough: Update Walkthrough Target Input field
  const handleUpdateWalkthroughTargetPageURL = (value) => {
    setWalkthroughTargetAction(value);

    setUpdatedSettingsRequired(true);
  };

  // Walkthrough: Add New Domain Textfield
  const handleAddDomainInputTextfield = () => {
    const values = [...walkthroughTargetDomainList];
    // Inject a new Textfield entry & update to "walkthroughTargetDomainList"
    values.push({ url: null, showURLError: true });
    setWalkthroughTargetDomainList(values);
  };
  // Walkthrough: Update Exising Domain Textfield Contents
  const handleUpdateDomainInputTextfield = (e, index) => {
    const values = [...walkthroughTargetDomainList];
    // Update contents of selected Textfield entry & update to "walkthroughTargetDomainList"
    values[index].url = e.target.value;
    // Setup error checking check
    values[index].showURLError = false;
    setWalkthroughTargetDomainList(values);

    // values[index].showURLError = true;
    // Check if a min of 8-char is filled up and must fit "https://"
    if (
      values[index].url.length > 0 &&
      values[index].url.length <= 8 &&
      !isValidUrl(values[index].url)
    ) {
      values[index].showURLError = true;
    } else {
      // All for saving to happen
      setUpdatedSettingsRequired(true);
    }
  };
  // Walkthrough: Remove Existing Domain Textfield
  const handleRemoveDomainInputTextfield = (index) => {
    const values = [...walkthroughTargetDomainList];
    // Remove selected Textfield & update to "walkthroughTargetDomainList"
    values.splice(index, 1);
    setWalkthroughTargetDomainList(values);
  };

  // // Walkthrough: Update Walkthrough Launch Action field
  // const handleUpdateWalkthroughLaunchAction = (e) => {
  //     const value = e.target.value;
  //     setWalkthroughLaunchAction(value);

  //     setUpdatedSettingsRequired(true);
  // };

  // Walkthrough: Update Walkthrough Frequency field
  const handleUpdateWalkthroughFrequency = (value) => {
    setWalkthroughFrequencyAction(value);

    setUpdatedSettingsRequired(true);
  };

  const dispatch = useDispatch();

  // Walkthrough: Handles Launch Settings Submission Form
  const handleLaunchSettingsSubmissionForm = (e) => {
    e.preventDefault();

    if (updateSettingsRequired) {
      // Force trim of DomainList in the event User might add spaces at the front or back of the input
      // console.log(walkthroughTargetDomainList)

      if (walkthroughTargetAction === "domains") {
        // first check if domain has null value
        let hasEmptyDomainIssue = walkthroughTargetDomainList.some(
          (domain) => !domain.url
        );

        if (hasEmptyDomainIssue) {
          const error = {
            message: "Please ensure that you typed in a domain.",
            type: "error",
            autoHideDuration: 10000,
          };
          onDispatchErrorMsg(error);
        }

        let hasInvalidUrl = walkthroughTargetDomainList.some(
          (domain) => !isValidUrl(domain.url)
        );

        if (hasInvalidUrl) {
          const error = {
            message: "Please ensure that you typed in a valid url.",
            type: "error",
            autoHideDuration: 10000,
          };
          onDispatchErrorMsg(error);
        }

        if (!hasEmptyDomainIssue && !hasInvalidUrl) {
          const trimmedDomainList = walkthroughTargetDomainList
            .map((domain) => {
              if (domain) {
                if (domain.url) {
                  return { url: domain.url.trim() };
                }
              }

              return undefined;
            })
            .filter((clearUndefined) => clearUndefined !== undefined);

          const newSettingsUpdate = {
            ...walkthrough.settings,
            launch: {
              page_show: {
                option: walkthroughTargetAction,
                domains:
                  trimmedDomainList.length > 0 ? [...trimmedDomainList] : [],
              },
              launch_action: {
                option: walkthroughLaunchAction,
                element: {},
              },
              frequency: {
                option: walkthroughFrequencyAction,
              },
            },
          };

          // Inject Update of Launch Settings
          const walkthroughData = {
            walkthroughID: walkthrough.walkthrough_id,
            settings: newSettingsUpdate,
          };
          dispatch(updateSettings(walkthroughData));
        }
      } else {
        // if target page walkthroughTarget Action

        // put in the orginal walkthrough target domain list
        // as "domains" was not selected
        // console.log("originalWalkthroughTargetDomainList", originalWalkthroughTargetDomainList)

        // TODO: MINOR BUG. why is empty input replaced in original domain list state?
        const newSettingsUpdate = {
          ...walkthrough.settings,
          launch: {
            page_show: {
              option: walkthroughTargetAction,
              domains: [...walkthrough.settings.launch.page_show.domains],
            },
            launch_action: {
              option: walkthroughLaunchAction,
              element: {},
            },
            frequency: {
              option: walkthroughFrequencyAction,
            },
          },
        };

        // Inject Update of Launch Settings
        const walkthroughData = {
          walkthroughID: walkthrough.walkthrough_id,
          settings: newSettingsUpdate,
        };
        dispatch(updateSettings(walkthroughData));
      }
    }
  };

  const [collapse, setCollapse] = useState(true);

  return (
    <>
      {collapse ? (
        <div className="pageForm flex flex-row gap-2">
          <span
            className="-ml-8 cursor-pointer"
            onClick={() => setCollapse(!collapse)}
          >
            {collapse ? <AddCircleOutlineIcon /> : <RemoveCircleOutlineIcon />}
          </span>
          <div className="pageSettingsHeader">Launch Settings</div>
        </div>
      ) : (
        <div className="">
          <form className="pageForm">
            <div className="flex flex-row gap-2">
              <span
                className="-ml-8 cursor-pointer"
                onClick={() => setCollapse(!collapse)}
              >
                {collapse ? (
                  <AddCircleOutlineIcon />
                ) : (
                  <RemoveCircleOutlineIcon />
                )}
              </span>
              <div className="pageSettingsHeader">Launch Settings</div>
            </div>

            <div className="pageSettingsSubHeader"></div>

            <div className="pageSettingSection">
              <FormControl component="fieldset">
                <div className="pageSettingsSectionHeader">
                  On which pages should the walkthrough show?
                </div>

                <div className="flex flex-col gap-4 mt-2">
                  <UtRadio
                    name="walkthrough-launch-settings-target"
                    id="target_page"
                    label="Only show on target page url"
                    value="target_page"
                    selectedValue={walkthroughTargetAction}
                    onSelectedValue={handleUpdateWalkthroughTargetPageURL}
                  />

                  <UtRadio
                    name="walkthrough-launch-settings-target"
                    id="domains"
                    label="Show on the following domains:"
                    value="domains"
                    selectedValue={walkthroughTargetAction}
                    onSelectedValue={handleUpdateWalkthroughTargetPageURL}
                  />

                  <div>
                    {
                      // Display Add Domain Textfields
                      walkthroughTargetDomainList.map((domain, index) => {
                        return (
                          <div key={`${domain}-${index}`}>
                            <div className="ml-6 mb-3 relative inline-flex">
                              <InputField
                                variant="full"
                                disabled={
                                  walkthroughTargetAction === "domains"
                                    ? false
                                    : true
                                }
                                placeholder={"https://www.example.com"}
                                value={domain.url !== null ? domain.url : ""}
                                onChange={(e) =>
                                  handleUpdateDomainInputTextfield(e, index)
                                }
                                error={
                                  domain.showURLError &&
                                  walkthroughTargetAction === "domains"
                                }
                                errorText="Domain entered is not a valid URL."
                                helperText=""
                              />

                              {
                                // Generates Add / Remove Textfield Btns
                                // Display both "x" & "+" when "walkthroughTargetDomainList" is more than one textfield
                                // Hide "x" when "walkthroughTargetDomainList" only have one textfield remaining
                              }

                              <div className="ml-3 mt-1 flex gap-2">
                                <Tooltip
                                  width="auto"
                                  position="top"
                                  content={
                                    walkthroughTargetAction === "domains"
                                      ? "Add Domain"
                                      : ""
                                  }
                                  color="primary"
                                >
                                  <Button
                                    color="primary"
                                    size="small"
                                    text=""
                                    iconOnly
                                    variant="primary"
                                    disabled={
                                      walkthroughTargetAction === "domains"
                                        ? false
                                        : true
                                    }
                                    onClick={(e) => {
                                      e.preventDefault();
                                      handleAddDomainInputTextfield();
                                    }}
                                    icon={<AddIcon />}
                                  />
                                </Tooltip>

                                {walkthroughTargetDomainList.length > 1 &&
                                  index >= 0 && (
                                    <Tooltip
                                      width="medium"
                                      position="top"
                                      content={
                                        walkthroughTargetAction === "domains"
                                          ? "Remove Domain"
                                          : ""
                                      }
                                      color="primary"
                                    >
                                      <Button
                                        color="primary"
                                        size="small"
                                        text=""
                                        iconOnly
                                        variant="outlined"
                                        disabled={
                                          walkthroughTargetAction === "domains"
                                            ? false
                                            : true
                                        }
                                        onClick={(e) => {
                                          e.preventDefault();
                                          handleRemoveDomainInputTextfield(
                                            index
                                          );
                                        }}
                                        icon={<CloseIcon />}
                                      />
                                    </Tooltip>
                                  )}
                              </div>
                            </div>
                          </div>
                        );
                      })
                    }
                  </div>
                </div>
              </FormControl>
            </div>

            {/* <div className="pageSettingSection">
                <FormControl component="fieldset">
                    <div className="pageSettingsSectionHeader">
                        How should this walkthrough launch?
                    </div>

                    <RadioGroup
                        aria-label={"walkthrough-launch-settings-launch"}
                        name={"walkthrough-launch-settings-launch"}
                        
                        value={walkthroughLaunchAction}
                        onChange={(e) => handleUpdateWalkthroughLaunchAction(e)}
                    >
                        <FormControlLabel 
                            control={<Radio />} 

                            label={"When the target page loads"}
                            value={"page_load"}
                        />
                        <FormControlLabel 
                            control={<Radio />} 

                            label={"When a user clicks an element"}
                            value={"elem_click"}
                        >
                        </FormControlLabel>
                    </RadioGroup>
                </FormControl>
            </div> */}

            <div className="pageSettingSection">
              <FormControl component="fieldset">
                <div className="pageSettingsSectionHeader">
                  How often should we show this walkthrough?
                </div>
                <ComponentPlanLimitWarning
                  limitName="autoLaunch"
                  setLimitReach={setLimitReach}
                />

                <div className="flex flex-col gap-4 mt-2">
                  <UtRadio
                    name="walkthrough-launch-settings-frequency"
                    id="show_once"
                    value="show_once"
                    label="Show once"
                    selectedValue={walkthroughFrequencyAction}
                    onSelectedValue={handleUpdateWalkthroughFrequency}
                  />

                  <UtRadio
                    name="walkthrough-launch-settings-frequency"
                    id="show_everytime"
                    value="show_everytime"
                    label="Show everytime"
                    selectedValue={walkthroughFrequencyAction}
                    onSelectedValue={handleUpdateWalkthroughFrequency}
                  />

                  <div className="text-sm text-gray-400">
                    Walkthroughs have a 1 minute launch buffer between automatic
                    launches after a user has completed/dismissed a walkthrough.
                  </div>
                  <UtRadio
                    name="walkthrough-launch-settings-frequency"
                    id="show_never"
                    value="show_never"
                    label="Never show"
                    selectedValue={walkthroughFrequencyAction}
                    onSelectedValue={handleUpdateWalkthroughFrequency}
                  />
                </div>
              </FormControl>
            </div>

            <div>
              <div className="ml-auto w-[5.625rem]">
                <Button
                  color="primary"
                  size="fluid"
                  variant="primary"
                  text="Save"
                  disabled={updateSettingsRequired === false ? true : false}
                  onClick={handleLaunchSettingsSubmissionForm}
                />
              </div>
            </div>
          </form>

          <div className="pageDivider" />
        </div>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    onDispatchErrorMsg: (errorMsg) => dispatch(actions.setAlert(errorMsg)),
  };
};
export default connect(mapDispatchToProps)(
  ComponentSettingsWalkthroughLaunchAction
);
