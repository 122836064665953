import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Tabs, TabsItem } from "usertip-component-library";
import PageOrganizationAdministrator from "./PageOrganizationAdministrator";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import PageOrganizationBuilder from "./PageOrganizationBuilder";
import PageOrganizationViewer from "./PageOrganizationViewer";
import PageOrganizationCodeSnippetViewer from "./PageOrganizationCodeSnippetViewer";
import { organizationGetCodeSnippetViewers } from "../store/reducers/reducerOrganization";
import { setTabsOrgSuccess } from "../store/reducers/reducerInviteQuickAccess";

const PageOrganization = () => {
  const [activeTab, setActiveTab] = useState<number>(2);
  const { admins } = useSelector((state: RootState) => state.org);
  const { builders } = useSelector((state: RootState) => state.org);
  const { viewers } = useSelector((state: RootState) => state.org);
  const { org_id, viewerersCodeSnippet, name } = useSelector(
    (state: RootState) => state.org
  );

  const dispatch = useDispatch();
  useEffect(() => {
    if (org_id) {
      dispatch(organizationGetCodeSnippetViewers(org_id));
    }
  }, [org_id, dispatch]);

  const adminLength = admins?.length;
  const builderLength = builders?.length;
  const viewerLength = viewers?.length;
  const viewerCodeSnipperLength = viewerersCodeSnippet?.length;

  useEffect(() => {
    // setTimeout(() => {
    document.getElementById("tabs")?.click();
    // }, 100);
  }, [adminLength]);

  // ENABLE THIS IF THE INVITE BUTTON ON NAVIGATION IS ACTIVATED
  // const tabsOrgSuccess = useSelector(
  //   (state: any) => state.inviteQuick.tabsOrgSuccess
  // );

  // // to select tabs after adding new member by using invite member button on sidebar
  // useEffect(() => {
  //   setActiveTab(tabsOrgSuccess);
  //   setTimeout(() => {
  //     document.getElementById(`tabs${tabsOrgSuccess}`)?.click();
  //   }, 500);
  // }, [tabsOrgSuccess]);
  // // console.log("---TABS NUM", tabsOrgSuccess, activeTab);

  /** Function to handler tabs when it clicked */
  const handleTabsClick = useCallback((value: number) => {
    setActiveTab(value);
  }, []);

  /** Children of the Tabs component to prevent UI issues */
  const tabsChildren = useMemo(() => {
    return (
      <>
        <TabsItem
          variant="lightest"
          label="Admin"
          notification={adminLength ? adminLength : 0}
          onClick={() => handleTabsClick(0)}
          id="tabs"
        />
        <TabsItem
          variant="lightest"
          label="Builder"
          notification={builderLength ? builderLength : 0}
          onClick={() => handleTabsClick(1)}
          id="tabs1"
        />
        <TabsItem
          variant="lightest"
          label="Viewer"
          notification={viewerLength ? viewerLength : 0}
          onClick={() => handleTabsClick(2)}
          id="tabs2"
        />
        <TabsItem
          variant="lightest"
          label="Code Snippet Viewers"
          notification={viewerCodeSnipperLength ? viewerCodeSnipperLength : 0}
          onClick={() => handleTabsClick(3)}
          id="tabs"
        />
      </>
    );
  }, [handleTabsClick]);

  return (
    <>
      <div className="pageBase">
        <div className="w-full pageHeader">{name ? name : "Organizations"}</div>

        <div className="text-[#9CA3AF]">
          Manage the members of your organization.
        </div>
      </div>
      <div className="flex flex-row w-full border-b border-b-[#F1EEFE] my-8">
        <Tabs variant="lightest" name="tabs-org">
          {tabsChildren}
        </Tabs>
      </div>
      <div>
        {activeTab == 0 && <PageOrganizationAdministrator />}
        {activeTab == 1 && <PageOrganizationBuilder />}
        {activeTab == 2 && <PageOrganizationViewer />}
        {activeTab == 3 && <PageOrganizationCodeSnippetViewer />}
      </div>
    </>
  );
};

export default PageOrganization;
