import React from "react";

const Logo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="43"
      height="42"
      viewBox="0 0 43 42"
      fill="none"
    >
      <g filter="url(#filter0_d_37164_218)">
        <rect
          x="4.5"
          y="4"
          width="32"
          height="32"
          rx="4.51977"
          fill="url(#paint0_linear_37164_218)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.2597 27.4975C25.045 28.4651 26.8212 27.5432 27.0894 25.3616L28.4585 14.4084C28.7638 12.026 26.9599 10.6843 24.7213 11.6244L14.4346 15.9601C12.3809 16.8272 11.9739 18.7623 13.417 20.1862C13.5558 20.0767 13.7223 19.9763 13.9258 19.8941L22.8804 16.1244C24.0182 15.6497 24.7583 16.1974 24.601 17.4023L23.4077 26.9407C23.3799 27.1507 23.3337 27.3423 23.2597 27.4975Z"
          fill="url(#paint1_linear_37164_218)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.0828 17.4572L23.8894 26.9957C23.8617 27.2604 23.7969 27.4977 23.7044 27.6985C23.8709 27.7624 24.0374 27.808 24.2039 27.8354C24.2964 27.5981 24.3705 27.3425 24.4075 27.0504L25.6008 17.5119C25.8506 15.5404 24.3427 14.4268 22.4926 15.2026L13.5287 18.9815C13.2604 19.1002 13.0199 19.2371 12.8164 19.3923C12.8904 19.5383 12.9829 19.6843 13.0939 19.8304C13.2697 19.6935 13.4825 19.5657 13.7322 19.4562L22.6868 15.6864C24.1669 15.0657 25.277 15.8872 25.0828 17.4572Z"
          fill="url(#paint2_linear_37164_218)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.4351 15.9604L24.805 12.501C26.5349 11.926 27.6727 12.7749 27.5987 14.573L27.0992 25.3711L28.4683 14.4178C28.6163 13.2312 28.237 12.3002 27.5432 11.7799C26.8494 11.2597 25.8318 11.1684 24.7218 11.6339L14.4351 15.9604Z"
          fill="url(#paint3_linear_37164_218)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.3373 16.6722L13.7323 19.4562C12.2522 20.0769 12.0765 21.4369 13.3623 22.3953L14.2319 23.0434C15.6472 24.0657 16.933 23.947 17.6453 22.2949C18.0061 21.446 18.0616 20.6245 18.2189 19.7939C18.4779 18.516 19.1162 17.2838 20.3373 16.6722Z"
          fill="url(#paint4_linear_37164_218)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.8488 21.9019C18.3761 21.2173 19.3659 21.0804 20.0597 21.5915C20.7535 22.1118 20.8923 23.0885 20.365 23.7731C20.328 23.8187 20.291 23.8643 20.254 23.9009C20.92 23.4262 21.6323 23.1615 22.3169 22.8238C23.4917 22.2396 24.5093 21.2903 24.7683 19.9668L25.0828 17.4658C25.1846 16.6717 24.9533 16.0784 24.5185 15.7498C24.0838 15.4212 23.4362 15.3756 22.6962 15.6859L20.3372 16.6717C19.1162 17.2741 18.4779 18.5064 18.2281 19.7843C18.0801 20.5327 18.0246 21.2812 17.7471 22.0388C17.7748 21.9932 17.8118 21.9475 17.8488 21.9019Z"
          fill="url(#paint5_linear_37164_218)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.5183 15.7498C24.0835 15.4212 23.4359 15.3756 22.6959 15.6859L21.3361 16.2518C19.3194 17.2285 18.8939 19.4648 20.67 20.7883C22.4461 22.1118 24.509 21.0895 24.8975 18.8989L25.0733 17.4567C25.1751 16.6717 24.953 16.0693 24.5183 15.7498Z"
          fill="url(#paint6_linear_37164_218)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.6204 23.29C15.8877 24.0293 17.007 23.792 17.6546 22.2951C18.0153 21.4462 18.0708 20.6247 18.2281 19.7941C18.4779 18.5162 19.1162 17.2839 20.3373 16.6815L20.0412 16.8002C18.8109 17.3205 18.1541 18.4888 17.9228 19.7302C17.7563 20.5608 17.7008 21.3823 17.3678 22.1673C16.822 23.4269 15.8599 23.9472 14.6204 23.29Z"
          fill="url(#paint7_linear_37164_218)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.7683 19.9673L23.8895 26.9956C23.6952 28.5747 22.4094 29.1133 21.1328 28.1549L20.2632 27.5068C18.8756 26.448 18.6351 25.1975 20.0504 24.0656C20.772 23.4906 21.5675 23.2076 22.3261 22.8243C23.4917 22.2401 24.5185 21.2908 24.7683 19.9673Z"
          fill="url(#paint8_linear_37164_218)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.9022 27.2147C18.8384 26.2198 18.7551 25.0971 20.041 24.0656C20.7625 23.4906 21.5581 23.2076 22.3166 22.8243C23.4914 22.2401 24.509 21.2908 24.768 19.9673L24.731 20.2776C24.5645 21.5829 23.6117 22.5322 22.4646 23.0981C21.6968 23.4815 20.9198 23.7644 20.2445 24.303C19.1621 25.1701 18.9216 26.2198 19.9022 27.2147Z"
          fill="url(#paint9_linear_37164_218)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.7686 19.9674L24.8981 18.8994C24.5095 21.0901 22.4466 22.1032 20.6705 20.7889C18.8944 19.4653 19.3199 17.229 21.3366 16.2524L20.3375 16.6723C18.5059 17.8589 18.1821 20.1956 20.06 21.5921C21.9286 22.9795 24.121 22.0211 24.7686 19.9674Z"
          fill="url(#paint10_linear_37164_218)"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_37164_218"
          x="0.5"
          y="0"
          width="42"
          height="42"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="1" dy="1" />
          <feGaussianBlur stdDeviation="2.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.462745 0 0 0 0 0.341176 0 0 0 0 0.945098 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_37164_218"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_37164_218"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_37164_218"
          x1="4.5"
          y1="20"
          x2="55.922"
          y2="20"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7657F1" />
          <stop offset="0.421875" stopColor="#9179F4" />
          <stop offset="1" stopColor="#E4DDFC" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_37164_218"
          x1="15.3525"
          y1="28.2147"
          x2="31.6322"
          y2="4.57446"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0026BF" />
          <stop offset="0.5074" stopColor="#662AED" />
          <stop offset="1" stopColor="#AE24FE" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_37164_218"
          x1="17.9845"
          y1="24.3639"
          x2="34.264"
          y2="0.724124"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0026BF" />
          <stop offset="0.5074" stopColor="#662AED" />
          <stop offset="1" stopColor="#AE24FE" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_37164_218"
          x1="13.3996"
          y1="31.0806"
          x2="29.6791"
          y2="7.44076"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0026BF" />
          <stop offset="0.5074" stopColor="#662AED" />
          <stop offset="1" stopColor="#AE24FE" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_37164_218"
          x1="14.7902"
          y1="23.9525"
          x2="17.1997"
          y2="16.0168"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFD824" />
          <stop offset="1" stopColor="#FC5453" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_37164_218"
          x1="18.501"
          y1="23.5019"
          x2="25.674"
          y2="14.1546"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFD824" />
          <stop offset="1" stopColor="#FC5453" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_37164_218"
          x1="16.6656"
          y1="25.8077"
          x2="23.8387"
          y2="16.4604"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFD824" />
          <stop offset="1" stopColor="#FC5453" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_37164_218"
          x1="16.5698"
          y1="22.9037"
          x2="18.9794"
          y2="14.968"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFD824" />
          <stop offset="1" stopColor="#FC5453" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_37164_218"
          x1="19.4778"
          y1="27.6908"
          x2="27.6576"
          y2="21.1179"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFD824" />
          <stop offset="1" stopColor="#FC5453" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_37164_218"
          x1="20.1958"
          y1="25.2678"
          x2="28.3755"
          y2="18.6949"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFD824" />
          <stop offset="1" stopColor="#FC5453" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_37164_218"
          x1="17.5634"
          y1="24.6841"
          x2="24.7364"
          y2="15.3369"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFD824" />
          <stop offset="1" stopColor="#FC5453" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Logo;
