import { InputLabel } from "@mui/material";
import {
  Button,
  InputField,
  InputNumber,
  SearchDropdown,
  Tooltip,
} from "usertip-component-library";
import React, { forwardRef, useEffect, useState } from "react";
import {
  MetaBoolean,
  MetaDate,
  MetaInteger,
  MetaString,
  RuleGroup,
} from "../../interfaces/interfaceSegmentation";
import RuleSelect from "./RuleSelect";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import DatePicker from "react-datepicker";
import { formatDateForFilter } from "../../utility/functions/formatDateForFilter";
import { parseISO } from "date-fns";

const ComponentRuleForm = ({
  index,
  setListRule,
  listRule,
  handleAddRule,
  groupIndex,
}: {
  groupIndex: number;
  index: number;
  setListRule: React.Dispatch<React.SetStateAction<RuleGroup[]>>;
  listRule: RuleGroup[];
  handleAddRule: () => void;
}) => {
  const [metaName, setMetaName] = useState("");
  const [metaType, setMetaType] = useState<{
    value: "string" | "boolean" | "date" | "number";
  }>({ value: "string" });
  const [metaRule, setMetaRule] = useState<RuleGroup["metaRule"]>("contain");
  const [metaValue, setMetaValue] = useState<RuleGroup["metaValue"]>("");

  useEffect(() => {
    const metaRuleItem = listRule.find(
      (d) => d.groupIndex === groupIndex && d.index === index
    );
    if (metaRuleItem) {
      if (metaRuleItem.metaName) {
        setMetaName(metaRuleItem.metaName);
      }
      if (metaRuleItem.metaType) {
        setMetaType({ value: metaRuleItem.metaType });
      }
      if (metaRuleItem.metaRule) {
        setMetaRule(metaRuleItem.metaRule);
      }

      if (metaRuleItem.metaValue) {
        setMetaValue(metaRuleItem.metaValue);
      }
    }
  }, []);

  /**eddit or add new state everytime user done typing */
  const handleOnBlur = () => {
    /* Find the index of the existing object in the array  */
    const idx = listRule.findIndex((obj) => {
      return obj.index === index && obj.groupIndex === groupIndex;
    });

    /**If new form data append it to list rule */
    if (idx === -1) {
      //@ts-ignore
      setListRule((prev) => [
        ...prev,
        {
          groupIndex,
          index: index,
          metaName,
          metaType: metaType.value,
          metaRule,
          metaValue,
        },
      ]);
    } else {
      const modifyRule = listRule.map((d) => {
        if (d.index === index && d.groupIndex === groupIndex) {
          let _trueValue = metaValue;
          if (metaType.value === "date") {
            if (_trueValue && Array.isArray(_trueValue)) {
              /** If already has a value, and metaType is Date, and the metaValue is [Date, Date] */
              const _startDate = _trueValue[0];
              const _endDate = _trueValue[1];

              /** Date object must be in ISOString
               * This is because, when sent in a JSON as POST Request,
               * the Date object is not sent properly.
               */
              _trueValue = [_startDate, _endDate];
            } else if (_trueValue && typeof _trueValue === "string") {
              /** If already has a value, and metaType is Date, and the metaValue is Date */
              const _startDate = _trueValue;

              /** Date object must be in ISOString
               * This is because, when sent in a JSON as POST Request,
               * the Date object is not sent properly.
               */
              _trueValue = _startDate;
            }
          }

          return {
            ...d,
            groupIndex,
            index,
            metaName,
            metaType: metaType.value,
            metaRule,
            metaValue: _trueValue,
          };
        } else {
          return d;
        }
      });

      //@ts-ignore
      setListRule(modifyRule);
    }
  };

  /* remove current rule based on index */
  const removeRule = () => {
    setListRule((prev) => prev.filter((d) => d.index !== index));
  };

  /*onChange handler for updating state select element */
  useEffect(() => {
    handleOnBlur();
  }, [metaType, metaRule, metaValue, metaName]);

  // Check if add button active
  const checkAddButtonActive = () => {
    if (metaName.length > 0) {
      return false;
    }
    return true;
  };
  return (
    <>
      <section className="flex w-full justify-between items-start">
        {/* meta data name form  */}
        <div className="px-2 w-60">
          <InputField
            variant="full"
            customWidth="ut-w-full"
            value={metaName}
            placeholder={"Meta Data Name"}
            onChange={(e) => setMetaName(e.target.value)}
            error={metaName.length === 0}
            errorText="Meta data name must not be empty"
            helperText=""
            label="Meta Data Name"
          />
        </div>
        {/* meta data type */}
        <div className="px-2 w-36">
          <SearchDropdown
            dataArray={[
              { id: "1", value: "string" },
              { id: "2", value: "boolean" },
              { id: "3", value: "date" },
              { id: "4", value: "number" },
            ]}
            selectedValue={metaType}
            onClick={(__, value) => {
              setMetaType({ value } as {
                value: "string" | "boolean" | "date" | "number";
              });
            }}
            label="Meta Data Type"
            customWidth="ut-w-full"
          />
        </div>
        {/* meta Data rules */}
        <div className="px-2 w-52">
          <RuleSelect
            metaRule={metaRule}
            metaType={metaType}
            setRule={setMetaRule}
            setMetaValue={setMetaValue}
          />
        </div>
        {/* meta data value form  */}
        <ComponentRuleValue
          rule={metaRule}
          metaType={metaType}
          metaValue={metaValue}
          setMetaValue={setMetaValue}
          // handleOnBlur={handleOnBlur}
        />

        <div className="mt-6 flex gap-2">
          <Tooltip
            width="auto"
            position="bottom"
            content={checkAddButtonActive() ? "" : "Add new rule"}
            color="primary"
          >
            <Button
              color="primary"
              size="small"
              text=""
              iconOnly
              variant="primary"
              onClick={(e) => {
                e.preventDefault();
                handleAddRule();
              }}
              disabled={checkAddButtonActive()}
              icon={<AddIcon />}
            />
          </Tooltip>

          <Tooltip
            width="auto"
            position="top"
            content={index === 0 ? "" : "Remove rule"}
            color="primary"
          >
            <Button
              color="primary"
              size="small"
              text=""
              iconOnly
              variant="outlined"
              onClick={(e) => {
                e.preventDefault();
                removeRule();
              }}
              disabled={index === 0}
              icon={<CloseIcon />}
            />
          </Tooltip>
        </div>
      </section>
    </>
  );
};

/** Copmonent to generate the correct input field depending on the type selected */
const ComponentRuleValue = ({
  rule,
  // handleOnBlur,
  metaValue,
  setMetaValue,
  metaType,
}: {
  rule: string;
  // handleOnBlur: () => void;
  metaValue: RuleGroup["metaValue"];
  setMetaValue: (value: React.SetStateAction<RuleGroup["metaValue"]>) => void;
  metaType: { value: "string" | "boolean" | "date" | "number" };
}) => {
  switch (metaType.value) {
    case "string": {
      return (
        <ComponentRuleStringInput
          value={metaValue as MetaString["metaValue"]}
          setValue={
            setMetaValue as (
              value: React.SetStateAction<MetaString["metaValue"]>
            ) => void
          }
          rule={rule as MetaString["metaRule"]}
          // handleOnBlur={handleOnBlur}
        />
      );
    }
    case "number": {
      return (
        <ComponentRuleIntegerInput
          value={metaValue as MetaInteger["metaValue"]}
          setValue={
            setMetaValue as (
              value: React.SetStateAction<MetaInteger["metaValue"]>
            ) => void
          }
          rule={rule as MetaInteger["metaRule"]}
          // handleOnBlur={handleOnBlur}
        />
      );
    }
    case "boolean": {
      return (
        <ComponentRuleBooleanInput
          value={metaValue ? (metaValue as MetaBoolean["metaValue"]) : true}
          setValue={
            setMetaValue as (
              value: React.SetStateAction<MetaBoolean["metaValue"]>
            ) => void
          }
          rule={rule as MetaBoolean["metaRule"]}
          // handleOnBlur={handleOnBlur}
        />
      );
    }
    case "date": {
      return (
        <ComponentRuleDatePicker
          value={metaValue as MetaDate["metaValue"]}
          setValue={
            setMetaValue as (
              value: React.SetStateAction<MetaDate["metaValue"]>
            ) => void
          }
          rule={rule}
        />
      );
    }
    default: {
      return <div>Error loading component</div>;
    }
  }
};

const ComponentRuleStringInput = ({
  value,
  setValue,
  rule,
}: // handleOnBlur,
{
  value: MetaString["metaValue"];
  setValue: React.Dispatch<React.SetStateAction<MetaString["metaValue"]>>;
  rule: MetaString["metaRule"];
  // handleOnBlur: () => void;
}) => {
  useEffect(() => {
    const potentialDateValue = parseISO(value!).valueOf();

    if (!isNaN(potentialDateValue)) {
      setValue("");
    }
  }, []);
  const isDisabled = rule === "isEmpty" || rule === "isNotEmpty" ? true : false;

  return (
    <>
      <div className="w-60">
        <InputField
          variant="full"
          placeholder={"Meta Data Value"}
          helperText=""
          label="Meta Data Value"
          onChange={(e) => setValue(e.target.value)}
          value={value ? value : ""}
          disabled={isDisabled}
          customWidth="ut-w-full"
        />
      </div>
    </>
  );
};

const ComponentRuleIntegerInput = ({
  value,
  setValue,
  rule,
}: // handleOnBlur,
{
  value: MetaInteger["metaValue"];
  setValue: React.Dispatch<React.SetStateAction<MetaInteger["metaValue"]>>;
  rule: MetaInteger["metaRule"];
  // handleOnBlur: () => void;
}) => {
  const isDisabled = rule === "isEmpty" || rule === "isNotEmpty" ? true : false;

  return (
    <>
      <div className="w-60">
        <InputNumber
          onChange={(e) => setValue(e)}
          value={+value!}
          width="full"
          disabled={isDisabled}
          label="Meta Data Value"
        />
      </div>
    </>
  );
};

const ComponentRuleBooleanInput = ({
  value,
  setValue,
  rule,
}: // handleOnBlur,
{
  value: MetaBoolean["metaValue"];
  setValue: React.Dispatch<React.SetStateAction<MetaBoolean["metaValue"]>>;
  rule: MetaBoolean["metaRule"];
  // handleOnBlur: () => void;
}) => {
  const isDisabled = rule === "isEmpty" ? true : false;

  return (
    <>
      <div className="w-60 invisible">
        <SearchDropdown
          dataArray={[
            { id: "1", value: "true" },
            { id: "2", value: "false" },
          ]}
          selectedValue={{ value: `${value}` }}
          onClick={(value) => {
            if (value === "true") {
              setValue(true);
            } else {
              setValue(false);
            }
          }}
          disabled={isDisabled}
          label="Meta Data Value"
          placeholder="Meta Data Value"
          customWidth="ut-w-full"
        />
      </div>
    </>
  );
};

const ComponentRuleDatePicker = ({
  value,
  setValue,
  rule,
}: {
  value: MetaDate["metaValue"];
  setValue: React.Dispatch<React.SetStateAction<MetaDate["metaValue"]>>;
  rule: string | "since" | "before" | "between" | "isEmpty" | "isNotEmpty";
}) => {
  const [displayValue, setDisplayValue] = useState();

  /** Date picker state, to handle null values */
  const [startDate, setStartDate] = useState<Date | null>(
    formatDateForFilter(new Date())
  );
  const [endDate, setEndDate] = useState<Date>(formatDateForFilter(new Date()));

  /** UseEffect to handle existing values that have been passed down */
  useEffect(() => {
    /** This function handles a string conversion to Date.
     * If the String is not a Date string, it will return today's Date
     */
    const handleDateString = (value: string) => {
      /** Convert and store a string that is potentially an ISO String
       * Convert to string object to DATE using parseISO
       * If valueOf converted date is NaN, means it is not a valid date ISOString
       */
      const potentialDateValue = parseISO(value).valueOf();

      if (!isNaN(potentialDateValue)) {
        /** If value is an ISO string, parse it and add to updatedMeta */
        return value;
      } else {
        /** If not ISO string, then add normally */
        return new Date().toISOString();
      }
    };

    if (value && Array.isArray(value)) {
      /** If already has a value, and metaType is Date, and the metaValue is [Date, Date] */
      const _startDate = handleDateString(value[0]);
      const _endDate = handleDateString(value[1]);
      setValue([_startDate, _endDate]);
      setStartDate(formatDateForFilter(new Date(_startDate)));
      setEndDate(formatDateForFilter(new Date(_endDate)));
    } else if (value && typeof value === "string") {
      /** If already has a value, and metaType is Date, and the metaValue is Date */
      const _startDate = handleDateString(value);
      setValue(_startDate);
      setStartDate(formatDateForFilter(new Date(_startDate)));
    }
  }, [value]);

  /** Function to handle change for single date rule */
  const onChangeSingleDate = (date: Date) => {
    setStartDate(date);
    /** Convert Date object to ISOString */
    setValue(date.toISOString());
  };

  /** Function to handle change for between dates */
  const onChangeBetweenDates = (dates: [Date, Date]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    /** Convert Date object to ISOString */
    setValue([start.toISOString(), end.toISOString()]);
  };

  const isDisabled = rule === "isEmpty" || rule === "isNotEmpty" ? true : false;

  const DatePickerCustomInput = forwardRef(
    (
      { value, onClick }: { value: any; onClick: any },
      ref: React.ForwardedRef<HTMLDivElement>
    ) => (
      <div
        className={`date-input-container ${
          isDisabled ? "meta-value-disabled" : ""
        }`}
        onClick={isDisabled ? () => {} : onClick}
        ref={ref}
      >
        {value}
      </div>
    )
  );
  switch (rule) {
    case "since":
      return (
        <div className="w-60">
          <InputLabel htmlFor="component-simple">Meta Data Value</InputLabel>
          <div id="component-meta-data-value-date">
            {/* @ts-ignore */}
            <DatePicker
              selected={startDate}
              onChange={onChangeSingleDate}
              //@ts-ignore
              customInput={<DatePickerCustomInput />}
            />
          </div>
        </div>
      );
    case "before":
      return (
        <div className="w-60">
          <InputLabel htmlFor="component-simple">Meta Data Value</InputLabel>
          <div className="">
            {/* @ts-ignore */}
            <DatePicker
              selected={startDate}
              onChange={onChangeSingleDate}
              //@ts-ignore
              customInput={<DatePickerCustomInput />}
            />
          </div>
        </div>
      );
    case "between":
      return (
        <div className="w-60">
          <InputLabel htmlFor="component-simple">Meta Data Value</InputLabel>
          <div className="">
            {/* @ts-ignore */}
            <DatePicker
              selected={startDate}
              onChange={onChangeBetweenDates}
              //@ts-ignore
              customInput={<DatePickerCustomInput />}
              startDate={startDate}
              endDate={endDate}
              selectsRange
            />
          </div>
        </div>
      );
    default:
      return (
        <div className="w-60">
          <InputLabel htmlFor="component-simple">Meta Data Value</InputLabel>
          <div className="">
            {/* @ts-ignore */}
            <DatePicker
              selected={startDate}
              onChange={onChangeSingleDate}
              //@ts-ignore
              customInput={<DatePickerCustomInput />}
            />
          </div>
        </div>
      );
  }
};

export default ComponentRuleForm;
