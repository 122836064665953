import { Box } from "@mui/material";
import React from "react";
import { AnalyticDataForChart } from "../../../../interfaces/interfaceAnalytic";

const ComponentDashboardOverview = ({
  walkthroughDataSet,
  convertTime,
}: {
  walkthroughDataSet: AnalyticDataForChart;
  convertTime: (time: number) => string;
}) => {
  return (
    <>
      {/* overview here */}
      <div className="overview-detail text-center page-analytic-subHeader w-1/2">
        <p className="font-bold mb-2 step text-left text-zinc-700">
          Total Launched: {walkthroughDataSet.seen} times
        </p>

        <div className="mb-2 flex gap-2 items-center">
          <span className="text-[#9179F4]">
            <Box
              className="border-2 justify-center border-zinc-900 mx-0.5"
              sx={{
                backgroundColor: "#9179F4",
                width: "1.2em",
                height: "1.2em",
              }}
            />
          </span>{" "}
          {walkthroughDataSet.completed} Completed
        </div>
        <div className="mb-2 flex gap-2 items-center">
          <span className="text-[#CCCCD5]">
            <Box
              className="border-2 border-zinc-900 mx-0.5"
              sx={{
                backgroundColor: "#CCCCD5",
                width: "1.2em",
                height: "1.2em",
              }}
            />
          </span>{" "}
          {walkthroughDataSet.incomplete} Incomplete
        </div>
        <div className="mb-2 flex gap-2 items-center">
          <span className="text-[#FB8C00]">
            <Box
              className="border-2 border-zinc-900 mx-0.5"
              sx={{
                backgroundColor: "#FB8C00",
                width: "1.2em",
                height: "1.2em",
              }}
            />
          </span>{" "}
          {walkthroughDataSet.dismissed} Dismissed
        </div>
        <p className="font-bold mb-2 step text-left text-zinc-700">
          Additional Stats
        </p>
        <section className="additional_stats text-left">
          <div>
            Average Times to Finish:{" "}
            {convertTime(walkthroughDataSet.averageTimeFinish!)}
          </div>
          <div>
            Median Times: {convertTime(walkthroughDataSet.medianTimes!)}
          </div>
          <div>
            Longest Completion Times:{" "}
            {convertTime(walkthroughDataSet.longestCompTimes!)}
            {/* . completed by{" "}
            {walkthroughDataSet.longestComppUser} */}
          </div>
          <div>
            Shortest Completion Times:{" "}
            {convertTime(walkthroughDataSet.shortestCompTimes!)}
            {/* . completed by{" "}
            {walkthroughDataSet.shortestCompUser}. */}
          </div>
        </section>
      </div>
    </>
  );
};

export default ComponentDashboardOverview;
