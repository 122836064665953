import { InputLabel, MenuItem, TextField } from "@mui/material";
import React, { useEffect } from "react";
import {
  MetaBoolean,
  MetaDate,
  MetaInteger,
  MetaString,
  RuleGroup,
} from "../../interfaces/interfaceSegmentation";
import { SearchDropdown } from "usertip-component-library";
import { id } from "date-fns/locale";

const RuleSelect = ({
  metaType,
  setRule,
  metaRule,
  setMetaValue,
}: {
  metaType: { value: "string" | "boolean" | "date" | "number" };
  setRule: React.Dispatch<React.SetStateAction<RuleGroup["metaRule"]>>;
  metaRule: RuleGroup["metaRule"];
  setMetaValue: (value: React.SetStateAction<RuleGroup["metaValue"]>) => void;
}) => {
  useEffect(() => {
    if (metaType.value === "boolean") {
      setRule("isTrue");
    }
    if (metaType.value === "date") {
      setRule("since");
    }
  }, [metaType]);

  const handleEmptySelect = () => {
    setMetaValue(null);
  };

  const stringValueParser = (value: string) => {
    switch (value) {
      case "contain":
        return "Contains";
      case "equal":
        return "is Equal To";
      case "notEqual":
        return "is not Equal To";
      case "isEmpty":
        return "is Empty";
      case "isNotEmpty":
        return "is not Empty";
    }
  };

  const stringDropdownHandler = (id: string) => {
    if (id === "1") {
      setRule("contain");
    } else if (id === "2") {
      setRule("equal");
    } else if (id === "3") {
      setRule("notEqual");
    } else if (id === "4") {
      setRule("isEmpty");
    } else if (id === "5") {
      setRule("isNotEmpty");
    }
  };

  const numberValueParser = (value: string) => {
    switch (value) {
      case "equal":
        return "is Equal To";
      case "notEqual":
        return "is not Equal To";
      case "equalAndGreaterThan":
        return "Equal or Greater than";
      case "equalAndLessThan":
        return "Equal or Less than";
      case "isEmpty":
        return "is Empty";
      case "isNotEmpty":
        return "is not Empty";
    }
  };

  const numberDropdownHandler = (id: string) => {
    if (id === "1") {
      setRule("equal");
    } else if (id === "2") {
      setRule("notEqual");
    } else if (id === "3") {
      setRule("equalAndLessThan");
    } else if (id === "4") {
      setRule("equalAndGreaterThan");
    } else if (id === "5") {
      setRule("isEmpty");
    } else if (id === "6") {
      setRule("isNotEmpty");
    }
  };

  const booleanValueParser = (value: string) => {
    switch (value) {
      case "isTrue":
        return "is True";
      case "isFalse":
        return "is False";
      case "isEmpty":
        return "is Empty";
    }
  };

  const booleanDropdownHandler = (id: string) => {
    if (id === "1") {
      setRule("isTrue");
    } else if (id === "2") {
      setRule("isFalse");
    } else if (id === "3") {
      setRule("isEmpty");
    }
  };

  const dateValueParser = (value: string) => {
    switch (value) {
      case "since":
        return "Since";
      case "before":
        return "is Before";
      case "between":
        return "is Between";
      case "isEmpty":
        return "is Empty";
      case "isNotEmpty":
        return "is Not Empty";
    }
  };

  const dateDropdownHandler = (id: string) => {
    if (id === "1") {
      setRule("since");
    } else if (id === "2") {
      setRule("before");
    } else if (id === "3") {
      setRule("between");
    } else if (id === "4") {
      setRule("isEmpty");
    } else if (id === "5") {
      setRule("isNotEmpty");
    }
  };

  switch (metaType.value) {
    case "string":
      if (
        !["contain", "equal", "notEqual", "isEmpty", "isNotEmpty"].includes(
          metaRule as MetaString["metaRule"]
        )
      ) {
        /** If no matching set default rule value */
        setRule("contain");
      }
      return (
        <div className="w-[150px]">
          <SearchDropdown
            dataArray={[
              { id: "1", value: "Contains" },
              { id: "2", value: "is Equal To" },
              { id: "3", value: "is not Equal To" },
              { id: "4", value: "is Empty" },
              { id: "5", value: "is not Empty" },
            ]}
            selectedValue={{ value: stringValueParser(metaRule)! }}
            onClick={stringDropdownHandler}
            customWidth="ut-w-full"
            label="Rule"
          />
        </div>
      );
      break;
    case "number":
      if (
        ![
          "equal",
          "notEqual",
          "equalAndGreaterThan",
          "equalAndLessThan",
          // "between",
          "isEmpty",
          "isNotEmpty",
        ].includes(metaRule as MetaInteger["metaRule"])
      ) {
        /** If no matching set default rule value */
        setRule("equal");
      }
      return (
        <div className="w-[200px]">
          <SearchDropdown
            dataArray={[
              { id: "1", value: "is Equal To" },
              { id: "2", value: "is not Equal To" },
              { id: "3", value: "Equal or Less than" },
              { id: "4", value: "Equal or Greater than" },
              { id: "5", value: "is Empty" },
              { id: "6", value: "is Not Empty" },
            ]}
            onClick={numberDropdownHandler}
            selectedValue={{ value: numberValueParser(metaRule)! }}
            label="Rule"
            customWidth="ut-w-full"
          />
        </div>
      );
      break;
    case "boolean":
      if (
        !["isTrue", "isFalse", "isEmpty"].includes(
          metaRule as MetaBoolean["metaRule"]
        )
      ) {
        /** If no matching set default rule value */
        setRule("isTrue");
      }
      return (
        <div className="w-[150px]">
          <SearchDropdown
            dataArray={[
              { id: "1", value: "is True" },
              { id: "2", value: "is False" },
              { id: "3", value: "is Empty" },
            ]}
            onClick={booleanDropdownHandler}
            selectedValue={{ value: booleanValueParser(metaRule)! }}
            label="Rule"
            customWidth="ut-w-full"
          />
        </div>
      );
      break;
    case "date":
      if (
        !["since", "before", "between", "isEmpty", "isNotEmpty"].includes(
          metaRule as MetaDate["metaRule"]
        )
      ) {
        /** If no matching set default rule value */
        setRule("since");
      }
      return (
        <div className="w-[150px]">
          <SearchDropdown
            dataArray={[
              { id: "1", value: "Since" },
              { id: "2", value: "is Before" },
              { id: "3", value: "is Between" },
              { id: "4", value: "is Empty" },
              { id: "5", value: "is Not Empty" },
            ]}
            onClick={dateDropdownHandler}
            selectedValue={{ value: dateValueParser(metaRule)! }}
            label="Rule"
            customWidth="ut-w-full"
          />
        </div>
      );
      break;
    default:
      return <></>;
      break;
  }
};

export default RuleSelect;
