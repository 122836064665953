import React from "react";
import Header from "../../02-molecules/04-header/Header";
import SubHeader from "../../02-molecules/04-header/SubHeader";
import { Tags } from "usertip-component-library";

interface Props {
  headerTitle: string;
  subHeaderTitle?: string;
  className?: string;
  tagList?: string[];
}
const DefaultHeader = (props: Props) => {
  return (
    <div>
      <Header title={props.headerTitle} />
      {props.subHeaderTitle && <SubHeader title={props.subHeaderTitle} />}
      {props.tagList && (
        <div className="mt-4 mb-12 flex flex-wrap gap-2">
          {props.tagList.map((tag) => (
            <Tags
              key={tag}
              clickable
              size="medium"
              text={tag}
              variant="outlined"
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default DefaultHeader;
