import { Alert, Snackbar } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ComponentLoading from "../components/ComponentLoading";
import ComponentImageList from "../components/MediaManager/MediaManagerImages/ComponentImageList";
import ComponentMediaManagerUploadImage from "../components/MediaManager/MediaManagerImages/ComponentMediaManagerUploadImage";
import { EmptyModal } from "usertip-component-library";
import { RootState } from "../store";
import NewPlanDetails from "../components/OrgPlan/NewPlanDetails";
import ComponentAlertSnackbar from "../components/ComponentAlertSnackbar";
import { resetAlert } from "../store/reducers/reducerMedia";

const PageMediaManagerImage = () => {
  const [uploadImgModal, setUploadImgModal] = useState<boolean>(false);
  const [isLimitReach, setLimitReach] = useState<boolean>(false);
  const [isWaitingUploadLoading, setIsWaitingUploadLoading] = useState(false);
  const [showAlertSnackbar, setShowAlertSnackbar] = useState<boolean>(false);

  const { appLoading: isLoading, alert } = useSelector(
    (state: RootState) => state.media
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (alert.active) {
      setShowAlertSnackbar(true);
    }
  }, [alert.active]);

  useEffect(() => {
    if (!showAlertSnackbar) {
      dispatch(resetAlert());
    }
  }, [showAlertSnackbar]);

  return (
    <main className="w-full" id="page-media-manager">
      {/* Show loading bar */}
      <ComponentLoading show={isLoading ? isLoading : isWaitingUploadLoading} />
      <section
        id="media-manager-header"
        className="flex items-center justify-between pageBase mb-12"
      >
        <div>
          <div className="pageHeader">Media Images</div>
          <div className="text-[#9CA3AF] ">
            Upload and manage your media images here
          </div>
        </div>
        <NewPlanDetails planList={["media"]} />
      </section>
      {/* Pop up for uploading image */}
      {uploadImgModal && (
        <div id="modal-upload-image">
          <EmptyModal
            isOpen={uploadImgModal}
            onClose={() => setUploadImgModal(false)}
            customWidthClass="w-auto p-10"
          >
            <ComponentMediaManagerUploadImage
              setUploadImgModal={setUploadImgModal}
              setIsWaitingUploadLoading={setIsWaitingUploadLoading}
            />
          </EmptyModal>
        </div>
      )}

      {/* Image list section */}
      <ComponentImageList
        isLimitReach={isLimitReach}
        setUploadImgModal={setUploadImgModal}
      />
      {/** For showing alert */}
      <ComponentAlertSnackbar
        variant={alert.type as "error" | "success"}
        title={alert.message}
        showAlertSnackbar={showAlertSnackbar}
        setShowAlertSnackbar={setShowAlertSnackbar}
        hideAlert={alert.autoHideDuration}
      />
    </main>
  );
};
function SuccessSnackBar() {
  const [open, setOpen] = useState(true);
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Image Succesfully uploaded
        </Alert>
      </Snackbar>
    </>
  );
}
export default PageMediaManagerImage;
