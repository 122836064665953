import React from "react";

interface Props {
  codeBlock: string;
}

const ComponentCodeBlock = ({ codeBlock }: Props) => {
  return (
    <div className="w-full flex justify-center flex-col">
      <button
        className="bg-default-primary w-max h-max px-3 py-1 text-neutral-50 hover:bg-default-primary-light active:bg-default-primary-darker active:translate-y-1"
        onClick={() => {
          codeBlock && navigator.clipboard.writeText(codeBlock);
          alert("Code Copied");
        }}
      >
        Copy Code
      </button>
      <pre>
        <code>{codeBlock}</code>
      </pre>
    </div>
  );
};

export default ComponentCodeBlock;
