import attachHTTPRequestHeaders from "../../utility/functions/attachHTTPRequestHeaders";
import { api } from "./_axios";

/*
    ===================================================================    
    API 2.0: API-Calls To Server
    ===================================================================
    1. api_p1 is default for all API 2.0 calls
    2. api_p2 is now only for /signIn route  
    ===================================================================    
    CALLS-USER API
    ===================================================================
*/

// DELETE: Delete user by cognito_id to API call
export const callDELETERemoveUserAccount = async (user_id) => {
  const config = attachHTTPRequestHeaders(true);
  return api
    .delete(`/user/delete/${user_id}`, config)
    .then((res) => res)
    .catch((err) => {
      return Promise.reject(err);
    });
};

// GET: Get existing user information from API call
export const callGETRetrieveUserAccountInformation = async (user_id) => {
  const config = attachHTTPRequestHeaders(true);
  return api
    .get(`/user/${user_id}`, config)
    .then((res) => res)
    .catch((err) => {
      return Promise.reject(err);
    });
};

// POST: Update user's name to API call
export const callPOSTUpdateUserName = async (user_id, name) => {
  const data = {
    name: name,
  };

  const config = attachHTTPRequestHeaders(true);
  return api
    .post(`/user/update/name/${user_id}`, data, config)
    .then((res) => res)
    .catch((err) => {
      return Promise.reject(err);
    });
};

// POST: Update user' phone number to API call
export const callPOSTUpdateUserPhoneNumber = async (
  user_id,
  countryCode,
  phoneNumber
) => {
  const data = {
    phoneno: {
      intl: countryCode,
      value: phoneNumber,
    },
  };

  const config = attachHTTPRequestHeaders(true);
  return api
    .post(`/user/update/name/${user_id}`, data, config)
    .then((res) => res)
    .catch((err) => {
      return Promise.reject(err);
    });
};

// POST: Update user' MFA settings to API call
export const callPOSTUpdateUserMFASettings = async (user_id, mfa) => {
  const data = {
    mfa: mfa,
  };

  const config = attachHTTPRequestHeaders(true);
  return api
    .post(`/user/update/mfa/${user_id}`, data, config)
    .then((res) => res)
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const callPostUpdateUserOnBoard = async (cognitoId) => {
  const config = attachHTTPRequestHeaders(true);
  return api
    .post(`/user/complete_onboarding/${cognitoId}`, {}, config)
    .then((res) => res)
    .catch((err) => {
      return Promise.reject(err);
    });
};
