// Function to convert readable string bytes to number
const sizeToBytes = (size: string): number => {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (!sizes.includes(size)) {
    size = `${size} GB`;
  }
  const splited = size.split(" ");

  const num = Number(splited[0]);
  const unit = splited[1];
  const index = sizes.indexOf(unit);

  return 1024 ** index * num;
};

export default sizeToBytes;
