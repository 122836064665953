import React from "react";

const DismissBB = () => {
  return (
    <div>
      <div
        role="button"
        aria-label="Leave this Guide?"
        data-ut-hovertip="Dismiss this Walkthrough?"
        data-ut-hovertip-pos="left"
        className="absolute top-6 right-6 block w-6 h-6 z-[1] float-left text-[#868686]"
        tabIndex={0}
      >
        <span
          data-ut-icon="close-icon"
          data-ut-type="ut-dismiss-btn"
          role="alert"
          aria-hidden="false"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className=""
            viewBox="0 0 32 32"
          >
            <path d="M25.313 8.563l-7.438 7.438 7.438 7.438-1.875 1.875L16 17.876l-7.438 7.438-1.875-1.875 7.438-7.438-7.438-7.438 1.875-1.875L16 14.126l7.438-7.438z"></path>
          </svg>
        </span>
      </div>
    </div>
  );
};

export default DismissBB;
