import attachHTTPRequestHeaders from "../../utility/functions/attachHTTPRequestHeaders";
import { api } from "./_axios";

/*
    ===================================================================    
    API 2.0: API-Calls To Server
    ===================================================================
    1. api_p1 is default for all API 2.0 calls
    2. api_p2 is now only for /signIn route  
    ===================================================================    
    CALLS-ORGANIZATION API
    ===================================================================
*/

// POST: Create a new organization
export const callPOSTCreateNewOrganization = async (owner, name) => {
  const data = {
    owner: owner,
  };

  // Since name is optional, if they are not null or the length > 0, we add name as a field after the checks
  if (name) {
    if (name.length > 0) {
      data.name = name;
    }
  }
  const config = attachHTTPRequestHeaders(true);
  return api
    .post(`/org/create`, data, config)
    .then((res) => res)
    .catch((err) => err);
};

// GET: Get an organization information from API call
export const callGETRetrieveAnOrganizationInformation = async (org_id) => {
  // console.log(`callGETRetrieveAnOrganizationInformation: ${org_id}`)

  const config = attachHTTPRequestHeaders(true);
  return api
    .get(`/org/${org_id}`, config)
    .then((res) => res)
    .catch((err) => err);
};

// POST: Add new admin to an organization
export const callPOSTAddAdminToOrganization = async (org_id, email) => {
  const data = {
    id: email.toLowerCase(),
  };
  const config = attachHTTPRequestHeaders(true);
  return api
    .post(`/org/add/${org_id}`, data, config)
    .then((res) => res)
    .catch((err) => err);
};

// POST: Add new builder to an organization
export const callPOSTAddBuilderToOrganization = async (org_id, user_id) => {
  const data = {
    id: user_id,
  };
  const config = attachHTTPRequestHeaders(true);
  return api
    .post(`/org/addBuilder/${org_id}`, data, config)
    .then((res) => res)
    .catch((err) => err);
};

// POST: Add new viewer to an organization
export const callPOSTAddViewerToOrganization = async (
  org_id,
  user_id,
  pluginCheck,
  codeSnippetCheck
) => {
  const data = {
    id: user_id,
  };

  // Since plugin and code_snippet are optional, if they are not null or the length > 0, we add name as a field after the checks
  if (pluginCheck !== null && codeSnippetCheck !== null) {
    data.plugin = pluginCheck;
    data.code_snippet = codeSnippetCheck;
  }
  const config = attachHTTPRequestHeaders(true);
  return api
    .post(`/org/addViewer/${org_id}`, data, config)
    .then((res) => res)
    .catch((err) => err);
};

// POST: Remove a user from an organization
export const callPOSTRemoveUserFromOrganization = async (org_id, role_id) => {
  const data = {
    role_id: role_id,
  };
  const config = attachHTTPRequestHeaders(true);
  return api
    .post(`/org/removeUser/${org_id}`, data, config)
    .then((res) => res)
    .catch((err) => err);
};

// POST: Update the role for a list of users
export const callPOSTChangeUserRoleInOrganization = async (
  org_id,
  new_role,
  users
) => {
  const data = {
    new_role: new_role,
    users: users,
  };
  const config = attachHTTPRequestHeaders(true);
  return api
    .post(`/org/changeRole/${org_id}`, data, config)
    .then((res) => res)
    .catch((err) => err);
};

console.log("---test commmit");

// FIXME: DEPRECATED API
// POST: Retrieve a user's role from an organization
// NOTE: changed parameter of email to cognito_id
export const callPOSTRetrieveUserRoleFromOrganization = async (
  org_id,
  cognito_id
) => {
  console.log("callPOSTRetrieveUserRoleFromOrganization");
  const data = {
    id: cognito_id,
  };
  const config = attachHTTPRequestHeaders(true);
  return api
    .post(`/org/checkUser/${org_id}`, data, config)
    .then((res) => res)
    .catch((err) => err);
};

// POST: Retrieve a user's permission access using org id
export const callPOSTRetrieveUserPermissionAccess = async (org_id) => {
  console.log("callPOSTRetrieveUserPermissionAccess");
  const data = {};
  const config = attachHTTPRequestHeaders(true);
  return api
    .post(`/user/checkUser/access/${org_id}`, data, config)
    .then((res) => res)
    .catch((err) => {
      return err;
    });
};

// USING PATH2
// POST: Invite a user to organization & add by role
export const callPOSTInviteUserToOrganization = async (
  org_id,
  emails,
  role_type
) => {
  const data = {
    org_id: org_id,
    emails: emails,
    role_type: role_type,
  };
  const config = attachHTTPRequestHeaders(true);
  return api
    .post(`/org/invite`, data, config)
    .then((res) => res)
    .catch((err) => {
      return Promise.reject(err);
    });
};

// Usertip Assistant API

// POST: Update usertip assistant publish status
export const callPOSTUpdateAssistantPublish = async (org_id, publish) => {
  const data = {
    publish: publish,
  };
  const config = attachHTTPRequestHeaders(true);
  return api
    .post(`/assistant/publish/${org_id}`, data, config)
    .then((res) => res)
    .catch((err) => {
      return Promise.reject(err);
    });
};

// POST: Update Usertip Assistant position settings
export const callPOSTUpdateAssistantPosition = async (org_id, position) => {
  const data = {
    position: position,
  };
  const config = attachHTTPRequestHeaders(true);
  return api
    .post(`/assistant/position/${org_id}`, data, config)
    .then((res) => res)
    .catch((err) => {
      return Promise.reject(err);
    });
};

// POST: Update Usertip Assistant list of allowed pages to be displayed
export const callPOSTUpdateAssistantAllowedPages = async (
  org_id,
  allowedPages
) => {
  const data = {
    allowedPages: allowedPages,
  };
  const config = attachHTTPRequestHeaders(true);
  return api
    .post(`/assistant/allowedPages/${org_id}`, data, config)
    .then((res) => res)
    .catch((err) => {
      return Promise.reject(err);
    });
};

// POST: Update Usertip Assistant list of excluded pages
export const callPOSTUpdateAssistantExlcludedPages = async (
  org_id,
  excludedPages
) => {
  const data = {
    excludedPages: excludedPages,
  };
  const config = attachHTTPRequestHeaders(true);
  return api
    .post(`/assistant/excludedPages/${org_id}`, data, config)
    .then((res) => res)
    .catch((err) => {
      return Promise.reject(err);
    });
};

// GET: Get an organization information from API call
export const callGetViewerCodeSnippet = async (org_id) => {
  // console.log(`callGETRetrieveAnOrganizationInformation: ${org_id}`)

  const config = attachHTTPRequestHeaders(true);
  return api
    .get(`/org/${org_id}/codesnippetViewers`, config)
    .then((res) => res)
    .catch((err) => err);
};

export const callPostConfirmJoinOrganization = async (data) => {
  const config = attachHTTPRequestHeaders(true);
  return api
    .post(`/org/join/existing/confirm`, data, config)
    .then((res) => res)
    .catch((err) => err);
};
