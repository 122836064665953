import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../store";
import {
  organizationGetHostWalkthrough,
  organizationPostShareWalkthroughToGuest,
} from "../store/reducers/reducerOrganization";
import ComponentEditSharedOrgList from "./ComponentEditSharedOrgList";
import ComponentEditSharedWalkthrough from "./ComponentEditSharedWalkthrough";
import ComponentModal from "./ComponentModal";
import { EmptyModal } from "usertip-component-library";
interface Props {
  showModalAdd: boolean;
  setShowModalAdd: React.Dispatch<React.SetStateAction<boolean>>;
  orgID: string;
}
const ComponentShareWalkthroughs = ({
  showModalAdd,
  setShowModalAdd,
  orgID,
}: Props) => {
  const [checked, setChecked] = useState([]);
  const [checkedWalkthrough, setCheckedWalkthrough] = useState([]);
  const hostWalkthroughs = useSelector(
    (state: RootState) => state.org.hostWalkthroughs
  );
  const guestList = useSelector((state: RootState) => state.org.guests);
  const dispatch = useDispatch();

  // get list of walkthrough and guest orf
  useEffect(() => {
    dispatch(organizationGetHostWalkthrough(orgID));
  }, [dispatch, orgID]);

  // Modal close handler
  const handleHideModal = () => {
    setShowModalAdd(false);
    setChecked([]);
    setCheckedWalkthrough([]);
  };
  const handleShareWalkthrough = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const sharedWalkthrough = {
      guestOrgList: checked,
      walkthroughList: checkedWalkthrough,
      host_org_id: orgID,
    };

    dispatch(organizationPostShareWalkthroughToGuest(sharedWalkthrough));
    handleHideModal();
  };

  return (
    <EmptyModal
      isOpen={showModalAdd}
      onClose={handleHideModal}
      size="large"
      customWidthClass="w-[860px] p-5"
    >
      <div className="modalHeader">Share Walkthrough</div>

      <div className="modalSubHeader">
        Share walkthrough to other organization
      </div>
      <form
        noValidate
        autoComplete="off"
        //@ts-ignore
        onSubmit={handleShareWalkthrough}
        className="modalForm"
      >
        <div className="inline-flex mb-8 gap-2">
          <ComponentEditSharedOrgList
            checked={checked}
            setChecked={setChecked}
            dataSet={guestList.guest}
          />
          <ComponentEditSharedWalkthrough
            dataSet={hostWalkthroughs.walkthrough}
            checked={checkedWalkthrough}
            setChecked={setCheckedWalkthrough}
          />
        </div>
        <div className="modalActionBtnBar">
          <Button
            variant="contained"
            className="modalActionBtn"
            onClick={handleHideModal}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            className="modalActionBtn"
            type="submit"
            disabled={
              checked.length === 0 && checkedWalkthrough.length === 0
                ? true
                : false
            }
          >
            Confirm
          </Button>
        </div>
      </form>
    </EmptyModal>
  );
};

export default ComponentShareWalkthroughs;
