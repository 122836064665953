import { Box } from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UserSegmentation } from "../../interfaces/interfaceSegmentation";
import { RootState } from "../../store";
import {
  deleteUserSegmentation,
  listAllUserSegmentation,
} from "../../store/reducers/reducerUserSegmentation";
import AddBoxIcon from "@mui/icons-material/AddBox";
import ComponentAlertSnackbar from "../ComponentAlertSnackbar";
import ComponentListTableBtnSingle from "../ComponentListTableBtnSingle";
import { convertDateToStandardFormat } from "../_functions/FunctionDate";
import ComponentRemoveUserSegmentation from "./ComponentRemoveUserSegmentation";
import { useNavigate } from "react-router-dom";
import { Button, Button as UsertipButton } from "usertip-component-library";

interface Props {
  userSegmentationList: UserSegmentation[];
}

const ComponentSegmentationList = ({ userSegmentationList }: Props) => {
  const navigate = useNavigate();
  // const userSegmentationList = useSelector((state: RootState) => {
  //   return state.userSegmentation
  //     ? state.userSegmentation.userSegmentationList!
  //     : [];
  // });

  const orgID = useSelector((state: RootState) => state.user.userData.org);

  const dispatch = useDispatch();

  /** Store selected User Segment rows state here */
  const [selectedRows, setSelectedRows] = useState<UserSegmentation[]>([]);

  /** State to manage if we need to reset the selected Rows */
  const [resetSelectAllRows, setResetSelectAllRows] = useState(false);

  /** Confirmation modal state */
  const [showModalRemove, setShowModalRemove] = useState(false);

  /** Alert snackbar state */
  const [showAlertSnackbar, setShowAlertSnackbar] = useState(false);

  /** State to manage whether remove mode is a single/bulk items */
  const [removeMode, setRemoveMode] = useState<"single" | "bulk" | "">("");

  useEffect(() => {
    dispatch(listAllUserSegmentation(orgID!));
  }, []);

  /** Reset selection to [] after executing function to remove segment(s) */
  useEffect(() => {
    if (resetSelectAllRows) {
      // Resets the "Selected Rows" within the current Table
      setSelectedRows([]);
      // Resets the control
      setResetSelectAllRows(false);
    }
  }, [resetSelectAllRows, setResetSelectAllRows]);

  useEffect(() => {
    console.log("---SEE WHAT IS SELECTED ROWS");
    console.log(selectedRows);
  }, [selectedRows]);

  const columns: GridColDef[] = [
    {
      field: "segmentation_name",
      headerName: "Name",
      width: 300,
      flex: 1,
    },
    {
      field: "createdAt",
      headerName: "Date Created",
      width: 150,
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        `${convertDateToStandardFormat(params.row.createdAt)}`,
    },
    {
      field: "updatedAt",
      headerName: "Date Updated",
      width: 150,
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        `${convertDateToStandardFormat(params.row.updatedAt)}`,
    },
    {
      field: "action",
      headerName: "Action(s)",
      headerAlign: "right",
      flex: 1,
      align: "right",
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<any>) => (
        <div>
          <ComponentListTableBtnSingle
            btnType={"Edit"}
            listType={"editUserSegment"}
            dataSet={userSegmentationList}
            row={params.row}
          />
          <ComponentListTableBtnSingle
            btnType={"Delete"}
            listType={"deleteUserSegment"}
            dataSet={userSegmentationList}
            row={params.row}
            setSelectedUserSegment={setSelectedRows}
            setRemoveMode={setRemoveMode}
            setShowAlertSnackbar={setShowAlertSnackbar}
            setShowModalRemove={setShowModalRemove}
          />
        </div>
      ),
    },
  ];

  return (
    <>
      {userSegmentationList && userSegmentationList.length > 0 && (
        <Box sx={{ marginTop: "1rem" }}>
          <div className="bg-default-primary rounded-tr-2xl rounded-tl-2xl flex flex-row justify-between px-6 py-4 items-center text-white">
            <div className="text-base font-bold">Manage User Segments</div>
            {/* <div className="flex flex-row gap-2 items-center border-whi">
              <Button
                text="Add New Segment"
                iconLeft
                icon={<AddBoxIcon />}
                size="small"
                variant="outlined"
                color="neutral"
                onClick={() => navigate("/segmentation/users/create")}
                customClass={"ut-btn-org"}
              />
            </div> */}
          </div>
          <DataGrid
            rows={userSegmentationList}
            columns={columns}
            initialState={{
              pagination: {
                page: 0,
                pageSize: 25,
              },
            }}
            rowsPerPageOptions={[25, 50, 100]}
            sx={{ borderEndEndRadius: 20, borderEndStartRadius: 20 }}
            autoHeight
            onSelectionModelChange={(ids) => {
              console.log("selection model");
              console.log(ids);
            }}
          />
          {
            /** Remove User Segmentation Modal */
            userSegmentationList.length > 0 && (
              <>
                {/* Pre-loading Remove Group Modal */}
                <ComponentRemoveUserSegmentation
                  selectedUserSegmentationList={selectedRows}
                  //TODO: reducer remove grouped user segment
                  onConfirmRemoveUserSegmentation={deleteUserSegmentation}
                  showModalRemove={showModalRemove}
                  setShowModalRemove={setShowModalRemove}
                  removeMode={removeMode}
                  setResetTableSelectAll={setResetSelectAllRows}
                />
              </>
            )
          }

          <ComponentAlertSnackbar
            showAlertSnackbar={showAlertSnackbar}
            setShowAlertSnackbar={setShowAlertSnackbar}
            variant={"error"}
            title="Error trying to delete User Segment"
          />
        </Box>
      )}
    </>
  );
};

export default ComponentSegmentationList;
