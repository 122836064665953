import React, { useState } from "react";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { convertDateToStandardFormat } from "../_functions/FunctionDate";
import { Status, EditIcon } from "usertip-component-library";
import { useSelector } from "react-redux";
import ComponentAddTags from "../ComponentAddTags";
import { useNavigate } from "react-router-dom";
import { Walkthrough } from "../../interfaces/interface Organization";

interface Props {
  walkthroughs: Walkthrough[];
}

export default function TableGuidePermissions({ walkthroughs }: Props) {
  //state from redux
  // const walkthroughs = useSelector(
  //   (state: any) => state.walkthrough.walkthroughs
  // );

  const [selectedRowIds, setSelectedRowIds] = useState([]);

  const handleSelectionModelChange = (selectionModel: any) => {
    // Use selectionModel to find the selected rows from the rows array
    const selectedRowObjects = selectionModel.map((rowId: any) =>
      walkthroughs.find((row: any) => row.id === rowId)
    );
    setSelectedRowIds(selectedRowObjects);
  };

  const navigate = useNavigate();

  const columns: GridColDef[] = [
    {
      field: "publish",
      headerName: "Status",
      minWidth: 100,
      editable: false,
      valueFormatter: (params) => {
        return params.value ? "LIVE" : "DRAFT";
      },
      renderCell: (params) => {
        return (
          <span className="break-normal">
            {params.value ? (
              <Status text="LIVE" size="small" variants="confirmed" />
            ) : (
              <Status text="DRAFT" size="small" variants="pending" />
            )}
          </span>
        );
      },
    },
    {
      field: "name",
      headerName: "Walkthroughs",
      flex: 0.9,
      minWidth: 150,
      editable: false,
    },
    {
      field: "createdAt",
      headerName: "Date Created",
      minWidth: 150,
      editable: false,
      valueFormatter: (params) => convertDateToStandardFormat(params.value),
    },
    {
      field: "tags",
      headerName: "Tags",
      minWidth: 132,
      editable: false,
      align: "left",
      renderCell: (params) => (
        <ComponentAddTags
          inputWalkthroughTags={params.row.tags}
          walkthroughID={params.row.walkthrough_id}
        />
      ),
    },
    {
      field: "action",
      headerName: "Action(s)",
      minWidth: 110,
      renderCell: (params) => (
        <div className="flex mx-auto flex-row gap-3" title="Edit Permissions">
          <div
            className="cursor-pointer text-neutral-500"
            onClick={() =>
              navigate(
                `/guides/walkthroughs/${params.row.walkthrough_id}/settings/viewing-permissions`
              )
            }
          >
            {/* @ts-ignore */}
            <EditIcon fill="#808096" />
          </div>
        </div>
      ),
    },
  ];

  return (
    <div>
      <div className="bg-default-primary rounded-tr-[20px] rounded-tl-[20px] flex flex-row justify-between px-6 py-4 items-center text-white">
        <div className="text-base font-bold">
          {selectedRowIds.length > 0 ? (
            <span>{selectedRowIds.length} selected</span>
          ) : (
            <span>Manage Walkthroughs Permissions</span>
          )}
        </div>
      </div>
      <div className="bg-white">
        <DataGrid
          rows={walkthroughs || []}
          columns={columns}
          autoHeight
          checkboxSelection
          selectionModel={selectedRowIds.map((row: any) => row.id)}
          onSelectionModelChange={handleSelectionModelChange}
          initialState={{
            pagination: {
              page: 0,
              pageSize: 25,
            },
          }}
          rowsPerPageOptions={[25, 50, 100]}
          sx={{ borderEndEndRadius: 20, borderEndStartRadius: 20 }}
        />
      </div>
    </div>
  );
}
