import React from "react";
import { Checkbox, ColorPicker, InputColor } from "usertip-component-library";

interface Props {
  /** Prop for set label of the input */
  label: string;
  /** Prop for set value of the input */
  value: string;
  /** Prop for set name of the checkbox (should different to other checkbox) */
  name?: string;
  /** Prop for handling the input */
  settingHandler: (value: string) => void;
  /** Prop for showing checkbox for transparent option */
  transparentOption: boolean;
  /** Prop for handling checkbox for transparent option */
  transparentHandler?: () => void;
}

const ColorSetting = ({
  label,
  value,
  name,
  settingHandler,
  transparentOption,
  transparentHandler,
}: Props) => {
  return (
    <>
      <h1 className="text-sm">{label}:</h1>
      <div className="flex-1 flex gap-1">
        <div className="flex-1">
          <InputColor
            value={value === "transparent" ? "" : value}
            onChange={value === "transparent" ? () => {} : settingHandler}
            disabled={value === "transparent"}
            width="full"
            variant="outlined"
          />
        </div>

        <ColorPicker value={value} onChange={settingHandler} />
      </div>

      {transparentOption && (
        <div className="col-start-2">
          <Checkbox
            id={`${name}-transparent`}
            name={`${name}-transparent`}
            label="Transparent"
            value="transparent"
            selectedValue={value}
            onChange={transparentHandler}
          />
        </div>
      )}
    </>
  );
};

export default ColorSetting;
