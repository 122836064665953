import React from "react";
import { useNavigate } from "react-router-dom";

import { Box, Tooltip } from "@mui/material";

import { GridActionsCellItem } from "@mui/x-data-grid";

import ComponentListTableBtnToolTipIcon from "./ComponentListTableBtnToolTipIcon";

import ComponentEditWalkthroughs from "./ComponentEditWalkthroughs";

import { retrieveUserDetailsFromEmailList } from "./_functions/FunctionUser";
import { retrieveTeamObject } from "./_functions/FunctionTeam";
import {
  retrieveSharedWalkthoughtObject,
  retrieveWalkthoughtObject,
} from "./_functions/FunctionWalkthrough";
import { UserSegmentation } from "../interfaces/interfaceSegmentation";

interface Props {
  btnType: string;
  listType: string;
  dataSet: any[];
  dataObject?: any;

  className?: string;

  setSelectedUserList?: React.Dispatch<React.SetStateAction<any>>;
  setSelectedTeamList?: React.Dispatch<React.SetStateAction<any>>;

  setSelectedWalkthroughList?: React.Dispatch<React.SetStateAction<any>>;

  setSelectedGroupsList?: React.Dispatch<React.SetStateAction<any>>;
  setSelectedGroupWalkthroughsList?: React.Dispatch<React.SetStateAction<any>>;

  setSelectedWalkthroughViewingPermissionsList?: React.Dispatch<
    React.SetStateAction<any>
  >;
  setSelectedWalkthroughViewerTeamPermissionsList?: React.Dispatch<
    React.SetStateAction<any>
  >;

  setSelectedUserSegment?: React.Dispatch<
    React.SetStateAction<UserSegmentation[]>
  >;

  setShowModalRemove?: React.Dispatch<React.SetStateAction<boolean>>;
  setRemoveMode?: React.Dispatch<React.SetStateAction<"single" | "bulk" | "">>;
  setShowModalMove?: React.Dispatch<React.SetStateAction<any>>;
  setMoveMode?: React.Dispatch<React.SetStateAction<any>>;

  setShowModalEdit?: React.Dispatch<React.SetStateAction<any>>;
  setEditMode?: React.Dispatch<React.SetStateAction<any>>;
  setShowModalView?: React.Dispatch<React.SetStateAction<any>>;
  setViewMode?: React.Dispatch<React.SetStateAction<any>>;

  setShowAlertSnackbar?: React.Dispatch<React.SetStateAction<boolean>>;

  row: any;
}

const ComponentListTableBtnSingle = ({
  btnType,
  listType,
  dataSet,
  dataObject,

  className,

  setSelectedUserList,
  setSelectedTeamList,

  setSelectedWalkthroughList,

  setSelectedGroupsList,
  setSelectedGroupWalkthroughsList,

  setSelectedWalkthroughViewingPermissionsList,
  setSelectedWalkthroughViewerTeamPermissionsList,

  setSelectedUserSegment,

  setShowModalRemove,
  setRemoveMode,
  setShowModalMove,
  setMoveMode,

  setShowModalEdit,
  setEditMode,
  setShowModalView,
  setViewMode,

  setShowAlertSnackbar,
  row,
}: Props) => {
  const navigate = useNavigate();

  // User
  const handleMoveUserClick = (userUniqueId: string) => {
    // Push selected user into "selectedUserList" array for processes
    setSelectedUserList!(
      retrieveUserDetailsFromEmailList(userUniqueId, dataSet)
    );
    // Notify mode of Move
    setMoveMode!("single");
    setShowModalMove!(true);
  };
  const handleRemoveUserClick = (userUniqueId: string) => {
    // Push selected user into "selectedUserList" array for processes
    setSelectedUserList!(
      retrieveUserDetailsFromEmailList(userUniqueId, dataSet)
    );
    // Notify mode of Removal
    setRemoveMode!("single");
    setShowModalRemove!(true);
  };

  // Team
  const handleViewTeamClick = (teamName: string) => {
    // Push selected team into "selectedTeamList" array for processes
    setSelectedTeamList!(retrieveTeamObject(dataSet, teamName));

    // Notify mode of View
    setViewMode!("single");
    setShowModalView!(true);
  };
  const handleEditTeamClick = (teamName: string) => {
    // Push selected team into "selectedTeamList" array for processes
    setSelectedTeamList!(retrieveTeamObject(dataSet, teamName));

    // Notify mode of Edit
    setEditMode!("single");
    setShowModalEdit!(true);
  };
  const handleRemoveTeamClick = (teamName: string) => {
    // Push selected team into "selectedTeamList" array for processes
    setSelectedTeamList!(retrieveTeamObject(dataSet, teamName));

    // Notify mode of Removal
    setRemoveMode!("single");
    setShowModalRemove!(true);
  };

  // Group
  const handleGroupViewingPermissionsGroupClick = (walkthroughName: string) => {
    // Grab out group walkthrough data for immediate prep for push to URL
    const walkthroughData = retrieveWalkthoughtObject(dataSet, walkthroughName);
    const groupWalkthroughID = walkthroughData[0].walkthrough_group_id;

    // Set a Parent-WalkthroughID for tracking of history-path.
    localStorage.setItem("parent-walkthroughID", groupWalkthroughID);
    // Push "walkthroughID" to URL
    navigate(
      `/guides/walkthroughs/groups/${groupWalkthroughID}/viewing-permissions`
    );
  };
  const handleGroupWalkthroughListGroupClick = (walkthroughName: string) => {
    // Grab out group walkthrough data for immediate prep for push to URL
    const walkthroughData = retrieveWalkthoughtObject(dataSet, walkthroughName);
    const groupWalkthroughID = walkthroughData[0].walkthrough_group_id;

    // Set a Parent-WalkthroughID for tracking of history-path.
    localStorage.setItem("parent-walkthroughID", groupWalkthroughID);
    // Push "walkthroughID" to URL
    navigate(`/guides/walkthroughs/groups/${groupWalkthroughID}/list`);
  };
  const handleRemoveGroupClick = (walkthroughName: string) => {
    // Push selected walkthrough into "setSelectedGroupsList" array for processes
    setSelectedGroupsList!(retrieveWalkthoughtObject(dataSet, walkthroughName));

    // Notify mode of Removal
    setRemoveMode!("single");
    setShowModalRemove!(true);
  };

  // Walkthrough
  const handleSettingsWalkthroughClick = (walkthroughName: string) => {
    // Grab out walkthrough data for immediate prep for push to URL
    const walkthroughData = retrieveWalkthoughtObject(dataSet, walkthroughName);
    const walkthroughID = walkthroughData[0].walkthrough_id;

    // Push "walkthroughID" to URL
    navigate(`/guides/walkthroughs/${walkthroughID}/settings/general`);
  };
  const handleEditWalkthroughClick = (walkthroughName: string) => {
    // Grab out walkthrough data for immediate prep for Chrome Tab Launch
    const walkthroughData = retrieveWalkthoughtObject(dataSet, walkthroughName);

    return (
      // FIXME: Resolve typescript
      // @ts-ignore
      <ComponentEditWalkthroughs selectedWalkthroughList={walkthroughData} />
    );
  };
  const handleRemoveWalkthroughClick = (walkthroughName: string) => {
    // Push selected walkthrough into "selectedWalkthroughList" array for processes
    setSelectedWalkthroughList!(
      retrieveWalkthoughtObject(dataSet, walkthroughName)
    );

    // Notify mode of Removal
    setRemoveMode!("single");
    setShowModalRemove!(true);
  };

  // Group Walkthrough
  const handleSettingsGroupWalkthroughClick = (walkthroughName: string) => {
    // Grab out walkthrough data for immediate prep for push to URL
    const walkthroughData = retrieveWalkthoughtObject(dataSet, walkthroughName);
    const walkthroughID = walkthroughData[0].walkthrough_id;

    // Push "walkthroughID" to URL
    navigate(`/guides/walkthroughs/groups/${walkthroughID}/settings/general`);
  };
  const handleEditGroupWalkthroughClick = (walkthroughName: string) => {
    // Grab out walkthrough data for immediate prep for push to URL
    const walkthroughData = retrieveWalkthoughtObject(dataSet, walkthroughName);
    const walkthroughID = walkthroughData[0].walkthrough_id;

    // Push "walkthroughID" to URL
    navigate(`/guides/walkthroughs/groups/${walkthroughID}/list`);
  };
  const handleRemoveGroupWalkthroughClick = (walkthroughName: string) => {
    // Push selected walkthrough into "selectedWalkthroughList" array for processes
    setSelectedGroupWalkthroughsList!(
      retrieveWalkthoughtObject(dataSet, walkthroughName)
    );

    // Notify mode of Removal
    setRemoveMode!("single");
    setShowModalRemove!(true);
  };

  // Walkthrough Viewing Permissions
  const handleRemoveWalkthroughViewingPermissionsClick = (
    WalkthroughViewingPermissionsEmail: string
  ) => {
    // Push selected walkthrough into "selectedWalkthroughViewingPermissionsList" array for processes
    setSelectedWalkthroughViewingPermissionsList!(
      retrieveUserDetailsFromEmailList(
        WalkthroughViewingPermissionsEmail,
        dataSet
      )
    );

    // Notify mode of Removal
    setRemoveMode!("single");
    setShowModalRemove!(true);
  };

  // Walkthrough Viewer Team Permissions
  const handleRemoveWalkthroughViewerTeamPermissionsClick = (
    walkthroughViewerTeamName: string
  ) => {
    // Push selected walkthrough into "selectedWalkthroughViewerTeamPermissionsList" array for processes
    setSelectedWalkthroughViewerTeamPermissionsList!(
      retrieveTeamObject(dataSet, walkthroughViewerTeamName)
    );

    // Notify mode of Removal
    setRemoveMode!("single");
    setShowModalRemove!(true);
  };

  // Shared walkthrough edit list
  const handleEditSharedWalkthroughList = (orgID: string) => {
    navigate(`/guest-organization/walkthrough-shared/${orgID}/list`);
  };

  const handleRemoveGuestWalkthroughList = (guest_org_id: string) => {
    // Push selected walkthrough into "selectedWalkthroughViewerTeamPermissionsList" array for processes
    setSelectedWalkthroughList!(
      retrieveSharedWalkthoughtObject(dataSet, guest_org_id)
    );

    // Notify mode of Removal
    setRemoveMode!("single");
    setShowModalRemove!(true);
  };

  /** Handle Edit User Segmentation */
  const handleEditUserSegment = (userSegmentation: UserSegmentation) => {
    // navigate(`/guest-organization/walkthrough-shared/${orgID}/list`);
    console.log("navigate to edit user segmentation page");
    navigate(`/segmentation/${userSegmentation.segmentation_id}/edit`);
  };

  /** Handle Delete User Segmentation */
  const handleDeleteUserSegment = (userSegmentation: UserSegmentation) => {
    // Push selected walkthrough into "selectedWalkthroughViewerTeamPermissionsList" array for processes
    const newList: UserSegmentation[] = [];
    newList.push(userSegmentation);
    setSelectedUserSegment!(newList);

    // Notify mode of Removal
    setRemoveMode!("single");
    setShowModalRemove!(true);
  };

  return (
    // code to hide Edit Walkthrough button, which is supposed to open Builder chrome extenstion
    // functionality has not been created yet for this icon
    (btnType !== "Edit" && listType === "Walkthrough") ||
      (btnType !== "Edit" && listType === "GroupWalkthrough") ||
      (listType !== "GroupWalkthrough" && listType !== "Walkthrough") ? (
      // @ts-ignore
      <GridActionsCellItem
        icon={
          <Tooltip
            title={`
                        ${
                          btnType === "Edit" && listType === "Team"
                            ? `${btnType} Members`
                            : btnType === "Settings" &&
                              listType === "Walkthrough"
                            ? "Walkthrough Settings"
                            : btnType === "GroupViewingPermissions" &&
                              listType === "Group"
                            ? "Group Viewing Permissions"
                            : btnType === "GroupWalkthroughList" &&
                              listType === "Group"
                            ? "Group Walkthrough List"
                            : btnType === "Delete" && listType === "Group"
                            ? "Delete Group"
                            : btnType === "Settings" &&
                              listType === "GroupWalkthrough"
                            ? "Walkthrough Settings"
                            : btnType === "Edit" &&
                              listType === "GroupWalkthrough"
                            ? "Edit Walkthrough"
                            : btnType === "Delete" &&
                              listType === "GroupWalkthrough"
                            ? "Delete Walkthrough"
                            : btnType === "Delete" &&
                              listType === "GroupWalkthrough"
                            ? "Delete Walkthrough"
                            : btnType === "Delete" &&
                              listType === "GroupWalkthrough"
                            ? "Delete Walkthrough"
                            : btnType === "Edit" &&
                              listType === "sharedWalkthrough"
                            ? "Edit Shared List Walkthrough"
                            : btnType === "Delete" &&
                              listType === "editSharedWalkthrough"
                            ? "Remove Walkthrough individually"
                            : btnType === "Edit" &&
                              listType === "editUserSegment"
                            ? "Edit User Segment"
                            : btnType === "Delete" &&
                              listType === "deleteUserSegment"
                            ? "Delete this User Segment"
                            : `${btnType} ${listType}`
                        }
                    `}
          >
            <Box>
              {/* FIXME: Resolve typescript issues
              @ts-ignore */}
              <ComponentListTableBtnToolTipIcon inputBtnType={btnType} />
            </Box>
          </Tooltip>
        }
        label={`${btnType} ${listType}`}
        onClick={(e: any) => {
          e.preventDefault();
          e.stopPropagation(); // Very impot. Stops initial load infinity looping
          // Serve as "Backup" code, in case Action Controls are shown for "owner"
          if (
            row.role === "owner" &&
            listType !== "WalkthroughViewingPermissions"
          ) {
            setShowAlertSnackbar!(true);
          } else {
            if (listType === "User") {
              if (btnType === "Move") {
                handleMoveUserClick(row.role_id);
              } else if (btnType === "Delete") {
                handleRemoveUserClick(row.role_id);
              }
            } else if (listType === "Team") {
              if (btnType === "View") {
                handleViewTeamClick(row.name);
              } else if (btnType === "Edit") {
                handleEditTeamClick(row.name);
              } else if (btnType === "Delete") {
                handleRemoveTeamClick(row.name);
              }
            } else if (listType === "Group") {
              if (btnType === "GroupViewingPermissions") {
                handleGroupViewingPermissionsGroupClick(row.name);
              } else if (btnType === "GroupWalkthroughList") {
                handleGroupWalkthroughListGroupClick(row.name);
              } else if (btnType === "Delete") {
                handleRemoveGroupClick(row.name);
              }
            } else if (listType === "Walkthrough") {
              if (btnType === "Settings") {
                handleSettingsWalkthroughClick(row.name);
              } else if (btnType === "Edit") {
                handleEditWalkthroughClick(row.name);
              } else if (btnType === "Delete") {
                handleRemoveWalkthroughClick(row.name);
              }
            } else if (listType === "GroupWalkthrough") {
              if (btnType === "Settings") {
                handleSettingsGroupWalkthroughClick(row.name);
              } else if (btnType === "Edit") {
                handleEditGroupWalkthroughClick(row.name);
              } else if (btnType === "Delete") {
                handleRemoveGroupWalkthroughClick(row.name);
              }
            } else if (listType === "WalkthroughViewingPermissions") {
              if (btnType === "Delete") {
                handleRemoveWalkthroughViewingPermissionsClick(row.role_id);
              }
            } else if (listType === "WalkthroughViewerTeamPermissions") {
              if (btnType === "Delete") {
                handleRemoveWalkthroughViewerTeamPermissionsClick(row.name);
              }
            } else if (listType === "sharedWalkthrough") {
              if (btnType === "Edit") {
                handleEditSharedWalkthroughList(row.guest_org_id);
              }
            } else if (listType === "editSharedWalkthrough") {
              if (btnType === "Delete") {
                handleRemoveGuestWalkthroughList(row.walkthrough_id);
              }
            } else if (listType === "editUserSegment") {
              if (btnType === "Edit") {
                handleEditUserSegment(row);
              }
            } else if (listType === "deleteUserSegment") {
              if (btnType === "Delete") {
                handleDeleteUserSegment(row);
              }
            }
          }
        }}
      />
    ) : null
  );
};

export default ComponentListTableBtnSingle;
