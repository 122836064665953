import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../store";

import ComponentPageHeaderLinks from "./ComponentPageHeaderLinks";

const ComponentPageHeaderLinkArray = ({
  linkArrayType,
  walkthroughID,
}: {
  linkArrayType: string;
  walkthroughID: string;
}) => {
  const basicAnalytic = useSelector(
    (state: RootState) => state.org.org_plan?.org_feature_plan.basic_analytic
  );
  const multiLangugage = useSelector(
    (state: RootState) => state.org.org_plan?.org_feature_plan.multi_langugage
  );

  const generetaWalkthroighLinkArray = () => {
    const linkArray = [
      { label: "Settings", disabled: false },
      { label: "Viewing Permissions", disabled: false },
    ];

    if (basicAnalytic) {
      linkArray.push({ label: "Analytics", disabled: false });
    }

    if (multiLangugage) {
      linkArray.push({ label: "Translate", disabled: true });
    }

    return linkArray;
  };

  return (
    <>
      {linkArrayType === "Walkthroughs" && (
        <ComponentPageHeaderLinks
          linkArrayType={linkArrayType}
          walkthroughID={walkthroughID}
          headerLinksList={generetaWalkthroighLinkArray()}
        />
      )}
      {linkArrayType === "GroupIndividualWalkthroughs" && (
        <ComponentPageHeaderLinks
          linkArrayType={linkArrayType}
          walkthroughID={walkthroughID}
          headerLinksList={[
            { label: "Settings", disabled: false },
            { label: "Viewing Permissions", disabled: false },
            { label: "Translate", disabled: true },
          ]}
        />
      )}
      {linkArrayType === "GroupWalkthroughs" && (
        <ComponentPageHeaderLinks
          linkArrayType={linkArrayType}
          walkthroughID={walkthroughID}
          headerLinksList={[
            { label: "Group Walkthrough List", disabled: false },
            { label: "Group Viewing Permissions", disabled: false },
          ]}
        />
      )}
    </>
  );
};

export default ComponentPageHeaderLinkArray;
