import {
  Button,
  Card,
  CardContent,
  Divider,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box } from "victory";
import { OrgPlan, OrgPlanUsage } from "../../interfaces/interface Organization";
import { RootState } from "../../store";
import bytesToSize from "../../utility/functions/bytesToSize";
import sizeToBytes from "../../utility/functions/sizeToBytes";
import LineBarProgressComponent from "../01-atoms/LineBarProgressComponent";

type planList = (
  | "all"
  | "media"
  | "monthlyUserActivity"
  | "publishedWalkthrough"
  | "sharedWalkthrough"
  | "walkthrough"
  | "autoLaunch"
)[];

const ComponentPlanDetails = ({
  planList,
  noMarginBottom,
}: {
  planList: "all" | planList;
  noMarginBottom?: boolean;
}) => {
  /** Get org plan from reducer */
  const orgPlan = useSelector((state: RootState) => state.org.org_plan);

  /** Get org plan usage data from reducer */
  const orgPlanUsage = useSelector(
    (state: RootState) => state.org.org_plan_usage
  );

  /** State for plan limit details */
  const [planLimitDetails, setPlanLimitDetails] = useState<
    null | OrgPlan["plan_limit_details"]
  >(null);

  /** State for plan usage details */
  const [planUsageDetails, setPlanUsageDetails] = useState<null | OrgPlanUsage>(
    null
  );

  /** Width of the grid cell containing the card */
  const gridCellWidth = "350px";

  /** Size of the information card */
  const cardWidth = "300px";

  /** useEffect to handle changes to orgPlan in reducer */
  useEffect(() => {
    if (orgPlan) {
      setPlanLimitDetails(orgPlan.plan_limit_details);
    }
  }, [orgPlan]);

  /** useEffect to handle changes to orgPlanUsage in reducer */
  useEffect(() => {
    if (orgPlanUsage && planList === "all") {
      setPlanUsageDetails(orgPlanUsage);
    }
    if (orgPlanUsage && planList !== "all" && planList.length > 0) {
      const filteredObj = Object.keys(orgPlanUsage)
        .filter((key) =>
          planList.includes(
            key as
              | "walkthrough"
              | "media"
              | "sharedWalkthrough"
              | "publishedWalkthrough"
              | "monthlyUserActivity"
              | "autoLaunch"
              | "all"
          )
        )
        .reduce((newObj, key) => {
          //@ts-ignore
          newObj[key] = orgPlanUsage[key];
          return newObj;
        }, {});
      //@ts-ignore
      setPlanUsageDetails(filteredObj);
    }
  }, [orgPlanUsage]);

  /** Function to convert the limit field name to a readable text for the end user. */
  const convertFieldNameToText = (fieldName: string) => {
    switch (fieldName) {
      case "price": {
        return "Price";
      }
      case "walkthroughs": {
        return "Number of created walkthroughs";
      }
      case "shared_walkthroughs": {
        return "Number of shared walkthroughs";
      }
      case "published_walkthroughs": {
        return "Number of published walkthroughs";
      }
      case "domains": {
        return "Domains detected";
      }
      case "monthly_active_user": {
        return "Monthly active users";
      }
      case "auto_launch": {
        return "Maximum number of auto-launch walkthroughs";
      }
      case "media_data_storage": {
        return "Total Media Storage";
      }
      case "campaign": {
        return "Number of Campaigns";
      }
      default: {
        return null;
      }
    }
  };

  /** Function to map the fieldname of OrgPlanUsage to OrgPlan.plan_limit_details */
  const mapUsageFieldNameToPlanLimitFieldName = (fieldName: string) => {
    switch (fieldName) {
      case "walkthrough": {
        return "walkthroughs";
      }
      case "sharedWalkthrough": {
        return "shared_walkthroughs";
      }
      case "publishedWalkthrough": {
        return "published_walkthroughs";
      }
      case "monthlyUserActivity": {
        return "monthly_active_user";
      }
      case "media": {
        return "media_data_storage";
      }
      case "autoLaunch": {
        return "auto_launch";
      }
      default: {
        return null;
      }
    }
  };

  return (
    <div
      className="pageSettings grid justify-start"
      style={{
        gridTemplateColumns: `repeat(auto-fill, ${gridCellWidth})`,
      }}
    >
      {planUsageDetails &&
        planLimitDetails &&
        Object.entries(planUsageDetails).map(([key, value], index) => {
          /** Loop through planUsageDetails, as this has the usage values of the org */

          /** Map the field name of planUsageDetails to planLimitDetails */
          const mapFieldToName = mapUsageFieldNameToPlanLimitFieldName(key);

          if (!mapFieldToName) {
            return;
          }

          /** Convert field name to readable User text */
          const fieldToText = convertFieldNameToText(mapFieldToName);

          /** Store the actual usage value in a constant */
          const usageDetailValue = value;
          /** Variable to store any converted usage value, e.g. "1 KB" to 1000, etc */
          let usageCount = usageDetailValue;

          /** Store the actual plan limit value */
          const planLimitDetailValue = planLimitDetails[mapFieldToName];
          /** Variable to store any converted usage value, e.g. "1 KB" to 1000, etc */
          let limitCount = planLimitDetailValue;

          let unit = "";

          if (key === "media") {
            usageCount = sizeToBytes(usageCount);
            limitCount = sizeToBytes(limitCount as string);
            // unit = ` ${bytesToSize(limitCount).split(" ")[1]}`;
          }

          /** Return null if limit count is zero,
           * we don't need to display usage and limits
           * if the limit is already zero
           */
          if (limitCount === 0 || limitCount === undefined) {
            return;
          }

          return (
            fieldToText && (
              <Paper
                key={key}
                className={`w-fit ${noMarginBottom ? "" : "mb-5"}`}
              >
                <div
                  className="flex flex-col p-6 items-start h-full"
                  style={{
                    width: cardWidth,
                  }}
                >
                  <Typography
                    sx={{ fontSize: "1.125rem" }}
                    color="neutral-black"
                    gutterBottom
                  >
                    {fieldToText}
                  </Typography>
                  <div className="mt-auto w-full">
                    <LineBarProgressComponent
                      currentValue={usageCount}
                      /** If limitCount is -1, the variable is unlimited
                       * but we still want to tell the user how much is the usage
                       */
                      totalValue={
                        (limitCount as number) >= 0
                          ? (limitCount as number)
                          : 999999999999
                      }
                      /** If limitCount is -1, means Unlimited */
                      label={`${usageDetailValue}/${
                        (limitCount as number) < 0
                          ? "Unlimited"
                          : planLimitDetailValue + unit
                      }`}
                    />
                  </div>
                </div>
              </Paper>
            )
          );
        })}
    </div>
  );
};

export default ComponentPlanDetails;
