/** Retrieve HTTP Request Headers to attach to API call.
 *
 * It takes one parameter, auth: boolean.
 *
 * If auth is true, we will attach the Authorization & SignIn-Type headers.
 *
 * If auth is false, we attach  the default headers.
 */
const attachHTTPRequestHeaders = (auth: boolean) => {
  let reqHeader;
  if (auth) {
    const token = localStorage.getItem("ut-token");
    const signInType = localStorage.getItem("ut-signInType")
      ? localStorage.getItem("ut-signInType")
      : "AWS";

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `${token}`,
        "SignIn-Type": `${signInType}`,
        // "ngrok-skip-browser-warning": true,
      },
    };

    reqHeader = config;
  } else {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        // "ngrok-skip-browser-warning": true,
      },
    };

    reqHeader = config;
  }

  if (!reqHeader) {
    throw new Error("Failed to attach HTTP Request Header");
  }

  return reqHeader;
};

export default attachHTTPRequestHeaders;
