import React, { useEffect, useState } from "react";
import {
  Button,
  EmptyModal,
  UsertipHorizontalIcon,
} from "usertip-component-library";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import { useNavigate } from "react-router-dom";
import {
  InviteToOrg,
  organizationPostInviteUserToOrganization,
  resetAlert,
} from "../store/reducers/reducerOrganization";

import TagsTextField from "../components/02-molecules/06-TagsTextField/TagsTextField";
import ComponentAlertSnackbar from "../components/ComponentAlertSnackbar";

const PageOnBoardingInvitation = () => {
  /** State for email each role */
  const [adminEmails, setAdminEmails] = useState<any[]>([]);
  const [builderEmails, setBuilderEmails] = useState<any[]>([]);
  const [viewerEmails, setViewerEmails] = useState<any[]>([]);

  /** State for combine all role email become one state */
  const [allEmail, setAllEmail] = useState<any[]>([]);

  /** State for showing modal */
  const [confirmModalIsOpen, setConfirmModalIsOpen] = useState<boolean>(false);
  const [skipModalIsOpen, setSkipModalIsOpen] = useState<boolean>(false);

  /** State for showing alert */
  const [showAlertSnackbar, setShowAlertSnackbar] = useState<boolean>(false);

  const {
    org: orgID,
    role,
    is_onboarded,
  } = useSelector((state: RootState) => state.user.userData);

  const { alert } = useSelector((state: RootState) => state.org);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  /** This useEffect is to direct non owner role to /onboarding page */
  useEffect(() => {
    if (role && role !== "owner" && role !== "admin") {
      navigate("/onboarding");
    }
  }, [role]);

  /** This useEffect for set all role email become one, so if there's same email will show the error */
  useEffect(() => {
    setAllEmail([...adminEmails, ...builderEmails, ...viewerEmails]);
  }, [adminEmails, builderEmails, viewerEmails]);

  /** This useEffect for handling the alert */
  useEffect(() => {
    if (alert.active) {
      setShowAlertSnackbar(alert.active);
    }
  }, [alert.active]);

  /** This useEffect for reset the alert when alert state closed */
  useEffect(() => {
    if (!showAlertSnackbar) {
      dispatch(resetAlert());
    }
  }, [showAlertSnackbar]);

  /** Function for skip the invitation onboarding */
  const skipInvitationOnboarding = () => {
    navigate("/onboarding");
  };

  /** Function for push data to database */
  const finishOnBoarding = () => {
    if (adminEmails.length > 0) {
      const finalAdminEmails = adminEmails.map((email: any) => email.userEmail);

      const orgData: InviteToOrg = {
        orgID: orgID!,
        emails: finalAdminEmails as [],
        role: "admin",
      };

      dispatch(organizationPostInviteUserToOrganization(orgData));
    }

    if (builderEmails.length > 0) {
      const finalBuilderEmails = builderEmails.map(
        (email: any) => email.userEmail
      );

      const orgData: InviteToOrg = {
        orgID: orgID!,
        emails: finalBuilderEmails as [],
        role: "builder",
      };

      dispatch(organizationPostInviteUserToOrganization(orgData));
    }

    if (viewerEmails.length > 0) {
      const finalViewerEmails = viewerEmails.map(
        (email: any) => email.userEmail
      );

      const orgData: InviteToOrg = {
        orgID: orgID!,
        emails: finalViewerEmails as [],
        role: "viewer",
      };

      dispatch(organizationPostInviteUserToOrganization(orgData));
    }

    /** To Continue to next onboarding when user status isn't onboard */
    if (!is_onboarded) {
      skipInvitationOnboarding();
    }

    setAdminEmails([]);
    setBuilderEmails([]);
    setViewerEmails([]);
  };

  return (
    <>
      {!is_onboarded && (
        <div className="absolute top-2 left-20">
          <UsertipHorizontalIcon />
        </div>
      )}
      <div className="flex flex-col p-12 gap-8">
        <h1 className="font-bold text-default-neutral-100 text-3xl text-center">
          Invite Member to Your Organization
        </h1>
        <TagsTextField
          title="Admins"
          description="Administrators are responsible for efficiently managing the organization, encompassing tasks such as managing members, teams, and walkthroughs."
          usersEmails={adminEmails}
          setUsersEmails={setAdminEmails}
          allEmail={allEmail}
        />
        <TagsTextField
          title="Builders"
          description="Builders are responsible for efficiently managing the organization, encompassing tasks such as managing members, teams, and walkthroughs."
          usersEmails={builderEmails}
          setUsersEmails={setBuilderEmails}
          allEmail={allEmail}
        />
        <TagsTextField
          title="Viewers"
          description="Viewers are responsible for accessing and viewing published walkthroughs. This role is suitable for users who primarily are focused on viewing content."
          usersEmails={viewerEmails}
          setUsersEmails={setViewerEmails}
          allEmail={allEmail}
        />

        {!is_onboarded && (
          <div className="ml-auto flex gap-4">
            <Button
              color="secondary"
              size="medium"
              variant="primary"
              text="Skip For Now"
              onClick={() => setSkipModalIsOpen(true)}
              disabled={allEmail.length > 0}
            />
            <Button
              color="primary"
              size="medium"
              variant="primary"
              text="Finish Setup"
              onClick={() => setConfirmModalIsOpen(true)}
              disabled={allEmail.length === 0}
            />
          </div>
        )}

        {is_onboarded && (
          <div className="ml-auto flex gap-4">
            <Button
              color="secondary"
              size="medium"
              variant="primary"
              text="Back to Onboarding Guide"
              onClick={() => navigate("/onboarding")}
            />
            <Button
              color="primary"
              size="medium"
              variant="primary"
              text="Invite Members"
              onClick={finishOnBoarding}
              disabled={allEmail.length === 0}
            />
          </div>
        )}
      </div>

      <EmptyModal
        isOpen={confirmModalIsOpen}
        onClose={() => {}}
        customClass="ut-px-5 ut-py-6"
        size="large"
      >
        <div className="flex flex-col gap-1">
          <h3 className="text-default-neutral-70">
            Have you finished setting up the members of your organization?
          </h3>
          <p className="text-xs text-default-primary">
            You can still configure your organization by clicking the
            'Organization' button in the navigation.
          </p>
        </div>

        <div className="ml-auto flex gap-4 mt-4">
          <div className="w-16">
            <Button
              color="secondary"
              size="fluid-small"
              variant="primary"
              text="No"
              onClick={() => setConfirmModalIsOpen(false)}
            />
          </div>
          <div className="w-16">
            <Button
              color="primary"
              size="fluid-small"
              variant="primary"
              text="Yes"
              onClick={finishOnBoarding}
            />
          </div>
        </div>
      </EmptyModal>

      <EmptyModal
        isOpen={skipModalIsOpen}
        onClose={() => {}}
        customClass="ut-px-5 ut-py-6"
        size="large"
      >
        <div className="flex flex-col gap-1">
          <h3 className="text-default-neutral-70">
            Are you sure want to skip this configuration?
          </h3>
          <p className="text-xs text-default-primary">
            You can still configure your organization by clicking the
            'Organization' button in the navigation.
          </p>
        </div>

        <div className="ml-auto flex gap-4 mt-4">
          <div className="w-16">
            <Button
              color="secondary"
              size="fluid-small"
              variant="primary"
              text="No"
              onClick={() => setSkipModalIsOpen(false)}
            />
          </div>
          <div className="w-16">
            <Button
              color="primary"
              size="fluid-small"
              variant="primary"
              text="Yes"
              onClick={skipInvitationOnboarding}
            />
          </div>
        </div>
      </EmptyModal>

      {is_onboarded && (
        <ComponentAlertSnackbar
          variant={alert.type === "success" ? "success" : "error"}
          title={alert.message}
          showAlertSnackbar={showAlertSnackbar}
          setShowAlertSnackbar={setShowAlertSnackbar}
          hideAlert={alert.autoHideDuration}
        />
      )}
    </>
  );
};

export default PageOnBoardingInvitation;
