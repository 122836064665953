import attachHTTPRequestHeaders from "../../utility/functions/attachHTTPRequestHeaders";
import { api } from "./_axios";

/*
    ===================================================================    
    API 2.0: API-Calls To Server
    ===================================================================
    1. api_p1 is default for all API 2.0 calls
    2. api_p2 is now only for /signIn route  
    ===================================================================    
    CALLS-TEAM API
    ===================================================================
*/

// POST: Create a new builder team
export const callPOSTCreateNewBuilderTeam = async (org_id, org_name) => {
  //NOTE: org_name and org_id are both required

  const data = {
    org_id: org_id,
    name: org_name,
  };
  const config = attachHTTPRequestHeaders(true);
  return api
    .post(`/teams/builder/create`, data, config)
    .then((res) => res)
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const callDELETEExistingBuilderTeam = async (builder_team_id) => {
  const config = attachHTTPRequestHeaders(true);
  return api
    .delete(`/teams/builder/${builder_team_id}`, config)
    .then((res) => res)
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const callPOSTEditMembersBuilderTeam = async (
  builder_team_id,
  users
) => {
  //NOTE: org_name and org_id are both required

  const data = {
    users: users,
  };
  const config = attachHTTPRequestHeaders(true);
  return api
    .post(`/teams/builder/editMembers/${builder_team_id}`, data, config)
    .then((res) => res)
    .catch((err) => {
      return Promise.reject(err);
    });
};

// GET: Get a builder team by organization from API call
export const callGETRetrieveBuilderTeamByOrganization = async (org_id) => {
  const config = attachHTTPRequestHeaders(true);
  return api
    .get(`/teams/builder/org/${org_id}`, config)
    .then((res) => res)
    .catch((err) => {
      return Promise.reject(err);
    });
};

// POST: Add a list of user/users to a builder team
export const callPOSTAddUsersToBuilderTeam = async (
  builderTeam_id,
  listOfUsers_id
) => {
  //NOTE: listOfUsers_id should be an array of cognito_ids, not emails etc

  const data = {
    users: listOfUsers_id,
  };
  const config = attachHTTPRequestHeaders(true);
  return api
    .post(`/teams/builder/addUser/${builderTeam_id}`, data, config)
    .then((res) => res)
    .catch((err) => {
      return Promise.reject(err);
    });
};

// POST: Remove a list of user/users from a builder team

//NOTE:
// currently there is no error message for users that have been
// failed to remove from the team if they are not in the team
// in the first place.
// It will still reply "Users have been removed from the builder team!"

export const callPOSTRemoveUsersFromBuilderTeam = async (
  builderTeam_id,
  listOfUsers_id
) => {
  const data = {
    users: listOfUsers_id,
  };
  const config = attachHTTPRequestHeaders(true);
  return api
    .post(`/teams/builder/removeUser/${builderTeam_id}`, data, config)
    .then((res) => res)
    .catch((err) => {
      return Promise.reject(err);
    });
};

// POST: Create a new viewer team
export const callPOSTCreateNewViewerTeam = async (org_id, org_name) => {
  const data = {
    org_id: org_id,
    name: org_name,
  };
  const config = attachHTTPRequestHeaders(true);
  return api
    .post(`/teams/viewer/create`, data, config)
    .then((res) => res)
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const callDELETEExistingViewerTeam = async (viewer_team_id) => {
  const config = attachHTTPRequestHeaders(true);
  return api
    .delete(`/teams/viewer/${viewer_team_id}`, config)
    .then((res) => res)
    .catch((err) => {
      return Promise.reject(err);
    });
};

// GET: Get a viewer team by organization from API call
export const callGETRetrieveViewerTeamByOrganization = async (org_id) => {
  const config = attachHTTPRequestHeaders(true);
  return api
    .get(`/teams/viewer/org/${org_id}`, config)
    .then((res) => res)
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const callGETRetrieveSummaryOfViewerTeamByOrganization = async (
  org_id
) => {
  const config = attachHTTPRequestHeaders(true);
  return api
    .get(`/teams/viewer/org/summary/${org_id}`, config)
    .then((res) => res)
    .catch((err) => {
      return Promise.reject(err);
    });
};

// POST: Add a list of user/users to a viewer team
export const callPOSTAddUsersToViewerTeam = async (
  viewerTeam_id,
  listOfUsers_id
) => {
  const data = {
    users: listOfUsers_id,
  };
  const config = attachHTTPRequestHeaders(true);
  return api
    .post(`/teams/viewer/addUser/${viewerTeam_id}`, data, config)
    .then((res) => res)
    .catch((err) => {
      return Promise.reject(err);
    });
};

// POST: Remove a list of user/users from a viewer team

//NOTE:
// currently there is no error message for users that have been
// failed to remove from the team if they are not in the team
// in the first place.
// It will still reply "Users have been removed from the viewer team!"

export const callPOSTRemoveUsersFromViewerTeam = async (
  viewerTeam_id,
  listOfUsers_id
) => {
  const data = {
    users: listOfUsers_id,
  };
  const config = attachHTTPRequestHeaders(true);
  return api
    .post(`/teams/viewer/removeUser/${viewerTeam_id}`, data, config)
    .then((res) => res)
    .catch((err) => {
      return Promise.reject(err);
    });
};

// edit members within a viewer team
export const callPOSTEditMembersViewerTeam = async (viewer_team_id, users) => {
  //NOTE: org_name and org_id are both required

  const data = {
    users: users,
  };
  const config = attachHTTPRequestHeaders(true);
  return api
    .post(`/teams/viewer/editMembers/${viewer_team_id}`, data, config)
    .then((res) => res)
    .catch((err) => {
      return Promise.reject(err);
    });
};
