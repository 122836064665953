import React from "react";

import { useNavigate } from "react-router-dom";

import { Button } from "usertip-component-library";

interface Props {
  btnURL: any;
  btnIcon: React.JSX.Element;
  btnLabel: string;
  btnDisabled?: boolean;
}

const ComponentPageHeaderLinksBtn = ({
  btnURL,
  btnIcon,
  btnLabel,
  btnDisabled,
}: Props) => {
  const navigate = useNavigate();

  return (
    <Button
      color="primary"
      size="medium"
      //@ts-ignore
      text={
        btnLabel !== "Analytics" ? (
          btnLabel.toUpperCase()
        ) : (
          <>
            {btnLabel.toUpperCase()}{" "}
            <sup className="text-[10px] text-default-primary-light p-1">
              BETA
            </sup>
          </>
        )
      }
      variant="outlined"
      icon={btnIcon}
      iconLeft
      onClick={() => {
        navigate(btnURL);
      }}
      customClass="ut-header-link-button"
    />
  );
};

export default ComponentPageHeaderLinksBtn;
