import React, { useState, useEffect } from "react";

import ComponentAlertSnackbar from "./ComponentAlertSnackbar";
import ComponentAddUsersEmailsToChips from "./ComponentAddUsersEmailsToChips";
import ComponentAddUsersMassImport from "./ComponentAddUsersMassImport";
import { EmptyModal } from "usertip-component-library";

import { saveAs } from "file-saver";
import { USERTIP_STATIC_URL } from "../constants";

const ComponentAddUsers = ({
  preloadModalState,

  orgID,
  onInviteUserToOrg,
  displayUserRole,

  showModalAdd,
  setShowModalAdd,
}) => {
  const [modalPageState, setModalPageState] = useState("");

  const [usersEmails, setUsersEmails] = useState([]);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState({ option: false, message: "" });

  const [showAlertSnackbar, setShowAlertSnackbar] = useState(false);
  const [showAlertSnackbarMsg, setShowAlertSnackbarMsg] = useState("");

  // Tracks "preloadModalState" to load the right page into modal onload.
  useEffect(() => {
    if (preloadModalState !== undefined) {
      // If "preloadModalState" is defined, set to the pre-designated state
      setModalPageState(preloadModalState);
    } else {
      // Setting default to preload "AddUsers"
      setModalPageState("AddUsers");
    }
  }, [preloadModalState]);

  // file-saver
  // https://www.npmjs.com/package/file-saver
  const downloadCSVFile = () => {
    saveAs(
      `${USERTIP_STATIC_URL}/csv/mass_import_template.csv`,
      "mass_import_template.csv"
    );
  };

  // Remove user email input field warning.
  const resetEmailErrorHandler = () => {
    setEmailError({ option: false, message: "" });
  };

  // Remove user email input field warning.
  const resetEmailHandler = () => {
    setEmail("");
  };

  // Modal close handler
  const handleHideModal = () => {
    resetEmailErrorHandler();
    resetEmailHandler();
    // Clears pre-keyed in emails into list
    setUsersEmails([]);

    setModalPageState(preloadModalState);
    setShowAlertSnackbar(false);
    setShowModalAdd(false);
  };

  // Reset helps to realign keys to count from 0 to length of array, else it will throw erros when an email is deleted in between the array and a new one is added
  const resetUsersEmailsKeys = () => {
    for (let i = 0; i < usersEmails.length; i++) {
      usersEmails[i].key = i;
    }
  };

  const handleMassImportClick = () => {
    setModalPageState("MassImport");
  };

  const handleBackToAddUsersClick = () => {
    setModalPageState("AddUsers");
  };

  return (
    <EmptyModal isOpen={showModalAdd} onClose={handleHideModal} size="large">
      <div className="py-5">
        {modalPageState === "AddUsers" && (
          <ComponentAddUsersEmailsToChips
            orgID={orgID}
            onInviteUserToOrg={onInviteUserToOrg}
            displayUserRole={displayUserRole}
            usersEmails={usersEmails}
            setUsersEmails={setUsersEmails}
            email={email}
            setEmail={setEmail}
            emailError={emailError}
            setEmailError={setEmailError}
            handleHideModal={handleHideModal}
            handleMassImportClick={handleMassImportClick}
            resetUsersEmailsKeys={resetUsersEmailsKeys}
            resetEmailErrorHandler={resetEmailErrorHandler}
            resetEmailHandler={resetEmailHandler}
          />
        )}

        {modalPageState === "MassImport" && (
          <ComponentAddUsersMassImport
            setUsersEmails={setUsersEmails}
            handleHideModal={handleHideModal}
            handleBackToAddUsersClick={handleBackToAddUsersClick}
            setModalPageState={setModalPageState}
            setShowAlertSnackbar={setShowAlertSnackbar}
            setShowAlertSnackbarMsg={setShowAlertSnackbarMsg}
            showAlertSnackbarMsg={showAlertSnackbarMsg}
            downloadCSVFile={downloadCSVFile}
          />
        )}

        <ComponentAlertSnackbar
          showAlertSnackbar={showAlertSnackbar}
          setShowAlertSnackbar={setShowAlertSnackbar}
          variant={"error"}
          title={showAlertSnackbarMsg}
        />
      </div>
    </EmptyModal>
  );
};

export default ComponentAddUsers;
