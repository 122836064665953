import React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { UserDataForInvitationToOrg } from "../../interfaces/interface Organization";
import { Button } from "usertip-component-library";
import { useNavigate } from "react-router-dom";
const ComponentRegisterViewerSuccess = ({
  registerType,
  jwtData,
}: {
  registerType: "existingUserRegister" | "newUserRegister";
  jwtData: UserDataForInvitationToOrg;
}) => {
  const HeaderText =
    registerType === "newUserRegister"
      ? "Account Succesfully Registered"
      : "Successfully Invited";

  const navigate = useNavigate();

  return (
    <>
      <section className="m-auto">
        <div className="m-auto w-full">
          <div className="flex flex-col justify-center items-center mb-14">
            <CheckCircleIcon color="primary" style={{ fontSize: 100 }} />
            <h2 className="text-heading-m text-center text-default-neutral-100 mb-3">
              {HeaderText}
            </h2>
            <div className="mb-8 text-body-l text-default-neutral-100">
              <p className="mb-2">
                Congratulations! You have successfully registered as a{" "}
                {jwtData!.invitedRole} to {jwtData!.invitedOrgName}
              </p>
              <p>
                Please check your email for the installation guide and launcher
                download link.
              </p>
            </div>
          </div>
          <div className="mt-14">
            <Button
              color="primary"
              size="fluid"
              text="Back to Home Page"
              variant="primary"
              onClick={() => navigate("/")}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default ComponentRegisterViewerSuccess;
