import { Button } from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  GuestWalkthrough,
  HostWalkthrough,
} from "../interfaces/interface Organization";
import {
  EditSharedWalkthrough,
  organizationGetGroupedOrgAndWalkthroughs,
  organizationGetHostWalkthrough,
  organizationPostEditSharedWalkthrough,
} from "../store/reducers/reducerOrganization";
import ComponenentEditSingleSharedWalkthrough from "./ComponenentEditSingleSharedWalkthrough";
import { EmptyModal } from "usertip-component-library";

interface Props {
  dataSet: GuestWalkthrough[] | null;
  showModalAdd: boolean;
  setShowModalAdd: React.Dispatch<React.SetStateAction<boolean>>;
  selectedRow: { walkthroughName: string; walkthrough_id: string }[];
  hostOrgID: string;
  hostWalkthroughs: HostWalkthrough[];
}

const ComponentEditShareWalkhtoughs = ({
  dataSet,
  showModalAdd,
  setShowModalAdd,
  selectedRow,
  hostOrgID,
  hostWalkthroughs,
}: Props) => {
  const [checkedWalkthrough, setCheckedWalkthrough] = useState<
    EditSharedWalkthrough[]
  >([]);

  const dispatch = useDispatch();

  const handleHideModal = () => {
    setShowModalAdd(false);
  };

  const handleShareWalkthrough = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    const editedWalkthrough = {
      hostOrgID: hostOrgID,
      //@ts-ignore
      guestOrgName: dataSet[0].guest_org_name,
      //@ts-ignore
      guestOrgId: dataSet[0].guest_org_id,
      walkthroughList: checkedWalkthrough,
    };
    //@ts-ignore
    await dispatch(organizationPostEditSharedWalkthrough(editedWalkthrough));

    // automatically reload the pages
    await dispatch(organizationGetGroupedOrgAndWalkthroughs(hostOrgID!));
    handleHideModal();
  };
  return (
    <EmptyModal
      isOpen={showModalAdd}
      onClose={handleHideModal}
      size="large"
      customClass="p-5"
      customWidthClass="w-[860px]"
    >
      <div className="modalHeader">Edit Walkthrough List</div>

      <div className="modalSubHeader"></div>
      <form
        noValidate
        autoComplete="off"
        onSubmit={handleShareWalkthrough}
        className="modalForm"
      >
        <div className="inline-flex mb-8 gap-2">
          <ComponenentEditSingleSharedWalkthrough
            dataSet={hostWalkthroughs}
            checked={checkedWalkthrough}
            setChecked={setCheckedWalkthrough}
            selectedRow={selectedRow}
          />
        </div>
        <div className="modalActionBtnBar">
          <Button
            variant="contained"
            className="modalActionBtn"
            onClick={handleHideModal}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            className="modalActionBtn"
            type="submit"
          >
            Confirm
          </Button>
        </div>
      </form>
    </EmptyModal>
  );
};

export default ComponentEditShareWalkhtoughs;
