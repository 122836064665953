import React, { useEffect, useState } from "react";
import { Button, InputField } from "usertip-component-library";
import GoogleSignIn from "../../GoogleSignIn/GoogleSignIn";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { validateEmailFormat } from "../../../utility/functions/validation";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store";
import {
  authRegisterationInitiated,
  registerRequest,
} from "../../../store/reducers/reducerAuth";
import { useParams } from "react-router-dom";

const RegisterForm = () => {
  const [emailValue, setEmailValue] = useState<string>("");
  const [passwordValue, setPasswordValue] = useState<string>("");
  const [confirmValue, setConfirmValue] = useState<string>("");
  const [nameValue, setNameValue] = useState<string>("");
  const [orgValue, setOrgValue] = useState<string>("");

  const [emailIsError, setEmailIsError] = useState<string>("");
  const [passwordIsError, setPasswordIsError] = useState<string | string[]>("");
  const [confirmIsError, setConfirmIsError] = useState<string>("");
  const [nameIsError, setNameIsError] = useState<string>("");
  const [orgIsError, setOrgIsError] = useState<string>("");
  const [formIsError, setFormIsError] = useState<string>("");

  const [passwordVisibility, setPasswordVisibility] = useState<boolean>(false);
  const [confirmVisibility, setConfirmVisibility] = useState<boolean>(false);

  const [createdAt, setCreatedAt] = useState(""); // SMU FYP ANALYTIPS PARAMETER
  const [timestampValue, setTimestampValue] = useState(""); // SMU FYP ANALYTIPS PARAMETER
  const [eventIdValue, seteventIdValue] = useState(""); // SMU FYP ANALYTIPS PARAMETER

  const dispatch = useDispatch<AppDispatch>();

  const { paymentTier } = useParams<{ paymentTier: string }>();

  const isAuthenticated = useSelector(
    (state: RootState) => state.auth.isAuthenticated,
    shallowEqual
  );
  const isRegistering = useSelector(
    (state: RootState) => state.auth.isRegistering,
    shallowEqual
  );
  const errorRegistration = useSelector(
    (state: RootState) => state.auth.errorRegistration,
    shallowEqual
  );

  const loading = useSelector((state: RootState) => state.auth.appLoading);

  const saveTierToStorage = (tierName: string) => {
    localStorage.setItem("ut-paymentTier", tierName);
  };

  /** This is for set payment-tier to localstorage */
  useEffect(() => {
    //Save tier to local storage
    if (paymentTier === undefined) {
      localStorage.setItem("ut-paymentTier", "free1");
    }

    /**If user land on marketing page and selecting free tier */
    if (paymentTier === "free1") {
      saveTierToStorage("free1");
    }

    if (paymentTier === "business") {
      saveTierToStorage("PAID1");
    }

    if (paymentTier === "business-plus") {
      saveTierToStorage("PAID2");
    }

    if (paymentTier === "startup") {
      // setPaymentTierState("paid2");
      saveTierToStorage("paidStartup");
    }
  }, []);

  /** This is for validating the password length, contain uppercase, contain number, contain special char */
  const validatePassword = (password: string) => {
    const containMinChar = password.length >= 8;
    const containSpecialChar = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(
      password
    );
    const containCaps = /[A-Z]/.test(password);
    const containNumber = /[0-9]/.test(password);

    const isValid =
      containMinChar && containSpecialChar && containCaps && containNumber;

    let errorMessage: string[] = [];

    if (!containMinChar) {
      errorMessage.push("Requires at least 8 characters");
    }
    if (!containSpecialChar) {
      errorMessage.push("Requires to have at least one special character");
    }
    if (!containCaps) {
      errorMessage.push("Requires to have at least one capital letter");
    }
    if (!containNumber) {
      errorMessage.push("Requires to have at least one number");
    }

    return { isValid, errorMessage };
  };

  /** This is handler for submit the form */
  const submitHandler = async (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    /** Check the email valid or not */
    const emailIsValidated = validateEmailFormat(emailValue.toLowerCase());
    /** Check the password valid or not */
    const password = validatePassword(passwordValue);
    /** Check if confirm password isValid or not */
    const confirmIsValidated = confirmValue === passwordValue;

    /** Reset form error when submit */
    setFormIsError("");

    /** This is for reset the confirm password error when valid*/
    if (confirmIsValidated) {
      setConfirmIsError("");
    }

    /** This is for validation */
    const errorCondition =
      !emailValue ||
      !orgValue ||
      !passwordValue ||
      !confirmValue ||
      !nameValue ||
      !emailIsValidated ||
      !password.isValid ||
      !confirmIsValidated;

    /** This is for changing error state */
    if (errorCondition) {
      if (!emailValue) {
        setEmailIsError("Email required");
      } else if (!emailIsValidated) {
        setEmailIsError("Invalid Email");
      }

      if (!passwordValue) {
        setPasswordIsError("Password required");
      } else if (!password.isValid) {
        setPasswordIsError(password.errorMessage);
      }

      if (!confirmValue) {
        setConfirmIsError("Confirm Password required");
      } else if (!confirmIsValidated) {
        setConfirmIsError("Password does not match");
      }

      if (!nameValue) {
        setNameIsError("Name required");
      }

      if (!orgValue) {
        setOrgIsError("Organization Name required");
      }

      return;
    }

    /** object that going to posted to the server */
    const newUser = {
      email: emailValue,
      password: passwordValue,
      name: nameValue,
      orgName: orgValue,
      timestamp: timestampValue,
      eventId: eventIdValue,
    };

    /** Reducer */
    await dispatch(registerRequest(newUser));

    /** Check if signup error or not */
    if (!isAuthenticated && errorRegistration) {
      setFormIsError(errorRegistration);
    } else {
      /** This is for set the payment-tier to localstorage  */
      // NOTE: DEPRECATED PRICING
      // if (paymentTier === "free1") {
      //   saveTierToStorage("free1");
      // }
      // if (paymentTier === "free2") {
      //   saveTierToStorage("free2");
      // }
      // if (paymentTier === "free3") {
      //   saveTierToStorage("free3");
      // }

      // if (paymentTier === "lite") {
      //   saveTierToStorage("PAID1");
      // }

      // if (paymentTier === "premium") {
      //   saveTierToStorage("PAID2");
      // }

      // if (paymentTier === "startup") {
      //   saveTierToStorage("paidStartup");
      // }

      // if (paymentTier === "custom") {
      //   saveTierToStorage("paidEnterprise");
      // }
      // NOTE: DEPRECATED PRICING END

      setFormIsError("");
    }
  };

  /** This is for show signup error for the first time */
  useEffect(() => {
    if (!isAuthenticated && isRegistering === "invalid") {
      // onLoad of Page, change isRegistering from "invalid" / "alreadyexists" to "pending" to represent a user is going through the Registration process
      authRegisterationInitiated();
    } else if (!isAuthenticated && isRegistering === "alreadyexists") {
      setFormIsError(errorRegistration);
    } else if (!isAuthenticated && errorRegistration) {
      setFormIsError(errorRegistration);
    }
  }, [isAuthenticated, isRegistering, errorRegistration]);

  /** This is for email input onChange handler */
  const emailHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmailValue(e.target.value.toLowerCase());
    setEmailIsError("");
  };

  /** This is for password input onChange handler */
  const passwordHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordValue(e.target.value);
    setPasswordIsError("");
  };

  /** This is for confirm password input onChange handler */
  const confirmHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmValue(e.target.value);
    setConfirmIsError("");
  };

  /** This is for name input onChange handler */
  const nameHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNameValue(e.target.value);
    setNameIsError("");
  };

  /** This is for organization name input onChange handler */
  const orgHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOrgValue(e.target.value);
    setOrgIsError("");
  };

  return (
    <div className="text-sm">
      <form
        action=""
        className="flex flex-col items-center gap-2 relative"
        onSubmit={submitHandler}
      >
        {formIsError && (
          <p className="text-center font-medium text-default-secondary absolute top-[-1.5em] left-0 right-0">
            {formIsError}
          </p>
        )}
        <InputField
          variant="full"
          helperText="*Required"
          label="Email"
          type="email"
          placeholder="example@user-tip.com"
          value={emailValue}
          onChange={emailHandler}
          error={emailIsError ? true : false}
          errorText={emailIsError}
        />
        <InputField
          variant="full"
          helperText="*Required"
          label="Password"
          type={passwordVisibility ? "text" : "password"}
          placeholder="Enter your password"
          value={passwordValue}
          onChange={passwordHandler}
          error={passwordIsError ? true : false}
          errorText={passwordIsError}
          iconRight={true}
          icon={
            <div
              className="text-sm text-default-neutral-60 cursor-pointer"
              onClick={() => setPasswordVisibility(!passwordVisibility)}
            >
              {!passwordVisibility ? <VisibilityOff /> : <Visibility />}
            </div>
          }
        />
        <InputField
          variant="full"
          helperText="*Required"
          label="Confirm Password"
          type={confirmVisibility ? "text" : "password"}
          placeholder="Confirm your password"
          value={confirmValue}
          onChange={confirmHandler}
          error={confirmIsError ? true : false}
          errorText={confirmIsError}
          iconRight={true}
          icon={
            <div
              className="text-sm text-default-neutral-60 cursor-pointer"
              onClick={() => setConfirmVisibility(!confirmVisibility)}
            >
              {!confirmVisibility ? <VisibilityOff /> : <Visibility />}
            </div>
          }
        />
        <InputField
          variant="full"
          label="Name"
          type="text"
          helperText="*Required"
          placeholder="Enter your fullname"
          value={nameValue}
          onChange={nameHandler}
          error={nameIsError ? true : false}
          errorText={nameIsError}
        />
        <InputField
          variant="full"
          helperText="*Required"
          label="Organization Name"
          type="text"
          placeholder="Enter your organization name"
          value={orgValue}
          onChange={orgHandler}
          error={orgIsError ? true : false}
          errorText={orgIsError}
        />
        <div className="w-1/2">
          <Button
            color="primary"
            size="fluid"
            text="Sign Up"
            variant="primary"
            disabled={loading}
          />
        </div>
      </form>
      <div className="mt-2 flex flex-col items-center gap-2">
        <div className="flex gap-1">
          <p className="text-default-neutral-60 font-medium text-base">
            Already have an account?
          </p>
          <a
            href="/login"
            className="text-default-primary font-medium text-base underline active:text-default-primary visited:text-default-primary"
          >
            Login
          </a>
        </div>
        <div className="flex items-center gap-3">
          <div className="h-[1px] w-44 bg-default-neutral-50"></div>
          <p className="text-default-neutral-60 font-medium text-base">Or</p>
          <div className="h-[1px] w-44 bg-default-neutral-50"></div>
        </div>
        <GoogleSignIn type="signup" />
      </div>
    </div>
  );
};

export default RegisterForm;
