const getHtmlPageMarginOffset = () => {
  let htmlMarginOffset = {
    left: 0,
    top: 0,
  };

  /** make sure the browser window and document objects exist */
  if (window && document) {
    /** Make sure that the documentElement, which should be the <html> tag, exists */
    if (document.documentElement) {
      const htmlRootStyles = window.getComputedStyle(document.documentElement);
      const htmlRootMarginLeft = htmlRootStyles.marginLeft;
      const htmlRootMarginTop = htmlRootStyles.marginTop;

      /** Calculate offset left and add it to htmlMarginOffset */
      if (typeof htmlRootMarginLeft === "string") {
        const calcMarginLeft = parseInt(
          htmlRootMarginLeft.replaceAll("px", "")
        );
        htmlMarginOffset.left = calcMarginLeft;
      } else if (typeof htmlRootMarginLeft === "number") {
        const calcMarginLeft = htmlRootMarginLeft;
        htmlMarginOffset.left = calcMarginLeft;
      }

      /** Calculate offset top and add it to htmlMarginOffset */
      if (typeof htmlRootMarginTop === "string") {
        const calcMarginTop = parseInt(htmlRootMarginTop.replaceAll("px", ""));
        htmlMarginOffset.top = calcMarginTop;
      } else if (typeof htmlRootMarginTop === "number") {
        const calcMarginTop = htmlRootMarginTop;
        htmlMarginOffset.top = calcMarginTop;
      }
    }
  }

  return htmlMarginOffset;
};

const computePos = (
  elem: Element,
  stepType:
    | "modal"
    | "slideout"
    | "spotlight"
    | "modalImage"
    | "slideoutImage"
    | "spotlightImage"
    | "tooltip"
    | "tooltipImage",
  originalBounds: DOMRect,
  originalScrollY: number
) => {
  if (!originalBounds) {
    return {
      height: 0,
      width: 0,
      top: 0,
      left: 0,
    };
  }

  let bounds = elem.getBoundingClientRect();

  //original position of element from the top of the document
  const originalPosY = originalBounds.top + originalScrollY;

  //NEW position of element from the top of the document
  const newPosY = bounds.top + window.scrollY;

  // const isSticky = originalPosY != newPosY ? true : false

  //TODO: HOW TO HANDLE PAGES THAT RESIZE ON A CERTAIN SCROLL
  const isSticky =
    originalBounds.top == bounds.top
      ? true //if originalBounds.top and current bounds.top is exactly the same after scroll, means it's a sticky element
      : newPosY != originalPosY
      ? newPosY <= originalPosY - 20 && newPosY >= originalPosY + 20
        ? true // if newPosY is in a range that is slight above/below the originalPosY, it's a psuedo sticky element
        : false
      : false;

  let padding =
    stepType === "spotlight" || stepType === "spotlightImage" ? 20 : 0;

  console.log("---check padding of step", padding);

  let height = bounds.height + padding;
  let width = bounds.width + padding;

  let htmlRootMarginOffset = getHtmlPageMarginOffset();

  // let xPos = bounds.left - htmlRootMarginOffset.left;
  // let yPos = bounds.top - htmlRootMarginOffset.top;

  let xPos = bounds.left;
  let yPos = bounds.top;

  if (isSticky) {
    // console.log("elem is sticky");
    yPos += window.scrollY;
    // xPos += window.scrollX
  } else {
    // console.log("elem is not sticky");
    yPos += window.scrollY;
  }

  const settings = {
    height: height,
    width: width,
    top: yPos - padding / 2,
    left: xPos - padding / 2,
  };

  return settings;
};

const getInputPosition = (settings: any, position: string, spacing: number) => {
  let config = {
    left: "",
    right: "",
    top: "",
    bottom: "",
    transform: "",
    className: "",
  };

  switch (position) {
    case "top-left": {
      //left 0%
      //bottom 125%
      //no transform
      config.className = "ut--spotlight--top-left";
      config.left = "0%";
      config.right = "";
      config.top = "";
      config.bottom = settings.height + spacing + "px";
      config.transform = "";
      break;
    }
    case "top": {
      //left 50%
      //bottom 125%
      //translateX(-50%)
      config.className = "ut--spotlight--top";
      config.left = "50%";
      config.right = "";
      config.top = "";
      config.bottom = settings.height + spacing + "px";
      config.transform = "translateX(-50%)";
      break;
    }
    case "top-right": {
      //right 0%
      //bottom 125%
      //no transform
      config.className = "ut--spotlight--top-right";
      config.left = "";
      config.right = "0%";
      config.top = "";
      config.bottom = settings.height + spacing + "px";
      config.transform = "";
      break;
    }
    case "top-right-corner": {
      //right 0%
      //bottom 0%
      //translate(calc(100% + 20px),0%)
      config.className = "ut--spotlight--top-right-corner";
      config.left = "";
      config.right = "0%";
      config.top = "";
      config.bottom = settings.height + spacing + "px";
      config.transform = "translate(calc(100%),0%)";
      break;
    }
    case "top-left-corner": {
      //left 0%
      //bottom 0%
      //translate(calc(-100% - 20px),-50%)
      //spacing 20px
      config.className = "ut--spotlight--top-left-corner";
      config.left = "0%";
      config.right = "";
      config.top = "";
      config.bottom = settings.height + spacing + "px";
      config.transform = "translate(calc(-100%),0%)";
      break;
    }
    case "left-top-aligned": {
      //left 0%
      //top 0%
      //translate(calc(-100% - 20px),0%)
      //spacing 20px
      config.className = "ut--spotlight--left-top-aligned";
      config.left = "0%";
      config.right = "";
      config.top = "0%";
      config.bottom = "";
      config.transform = "translate(calc(-100% - " + spacing + "px),0%)";
      break;
    }
    case "left-center": {
      //left 0%
      //top 50%
      //translate(calc(-100% - 20px),-50%)
      //spacing 20px
      config.className = "ut--spotlight--left-center";
      config.left = "0%";
      config.right = "";
      config.top = "50%";
      config.bottom = "";
      config.transform = "translate(calc(-100% - " + spacing + "px),-50%)";
      break;
    }
    case "left-bottom-aligned": {
      //left 0%
      //bottom 0%
      //translate(calc(-100% - 20px),-50%)
      //spacing 20px
      config.className = "ut--spotlight--left-bottom-aligned";
      config.left = "0%";
      config.right = "";
      config.top = "";
      config.bottom = "0%";
      config.transform = "translate(calc(-100% - " + spacing + "px),0%)";
      break;
    }
    case "right-top-aligned": {
      //right 0%
      //top 0%
      //translate(calc(100% + 20px),0%)
      config.className = "ut--spotlight--right-top-aligned";
      config.left = "";
      config.right = "0%";
      config.top = "0%";
      config.bottom = "";
      config.transform = "translate(calc(100% + " + spacing + "px),0%)";
      break;
    }
    case "right-center": {
      //right 0%
      //top 50%
      //translate(calc(100% + 20px),-50%)
      config.className = "ut--spotlight--right-center";
      config.left = "";
      config.right = "0%";
      config.top = "50%";
      config.bottom = "";
      config.transform = "translate(calc(100% + " + spacing + "px),-50%)";
      break;
    }
    case "right-bottom-aligned": {
      //right 0%
      //bottom 0%
      //translate(calc(100% + 20px),0%)
      config.className = "ut--spotlight--right-bottom-aligned";
      config.left = "";
      config.right = "0%";
      config.top = "";
      config.bottom = "0%";
      config.transform = "translate(calc(100% + " + spacing + "px),0%)";
      break;
    }
    /* OLD CSS CONFIG */
    case "center-left": {
      //left 0%
      //top 50%
      //translate(calc(-100% - 20px),-50%)
      //spacing 20px
      config.className = "ut--spotlight--center-left";
      config.left = "0%";
      config.right = "";
      config.top = "50%";
      config.bottom = "";
      config.transform = "translate(calc(-100% - " + spacing + "px),-50%)";
      break;
    }
    /* OLD CSS CONFIG */
    case "center-right": {
      //right 0%
      //top 50%
      //translate(calc(100% + 20px),-50%)
      config.className = "ut--spotlight--center-right";
      config.left = "";
      config.right = "0%";
      config.top = "50%";
      config.bottom = "";
      config.transform = "translate(calc(100% + " + spacing + "px),-50%)";
      break;
    }
    case "bottom-left": {
      //left 0%
      //top 125%
      config.className = "ut--spotlight--bottom-left";
      config.left = "0%";
      // config.left = (-settings.width/2) + 'px'
      config.right = "";
      config.top = settings.height + spacing + "px";
      config.bottom = "";
      config.transform = "";
      break;
    }
    case "bottom": {
      //left 0%
      //top 125%
      //translateX(50%)
      config.className = "ut--spotlight--bottom";
      config.left = "50%";
      config.right = "";
      config.top = settings.height + spacing + "px";
      config.bottom = "";
      config.transform = "translateX(-50%)";
      break;
    }
    case "bottom-right": {
      //right 0%
      //top 125%
      config.className = "ut--spotlight--bottom-right";
      config.left = "";
      config.right = "0%";
      config.top = settings.height + spacing + "px";
      config.bottom = "";
      config.transform = "";
      break;
    }
    case "bottom-left-corner": {
      //left 0%
      //top 0%
      //translate(calc(-100% - 20px),0%)
      //spacing 20px
      config.className = "ut--spotlight--bottom-left-corner";
      config.left = "0%";
      config.right = "";
      config.top = settings.height + spacing + "px";
      config.bottom = "";
      config.transform = "translate(calc(-100%),0%)";
      break;
    }
    case "bottom-right-corner": {
      //right 0%
      //top 0%
      //translate(calc(100% + 20px),0%)
      config.className = "ut--spotlight--bottom-right-corner";
      config.left = "";
      config.right = "0%";
      config.top = settings.height + spacing + "px";
      config.bottom = "";
      config.transform = "translate(calc(100%),0%)";
      break;
    }
    default:
      //left 0%
      //top 125%
      config.className = "ut--spotlight--bottom-left";
      config.left = "0%";
      config.right = "";
      config.top = settings.height + spacing + "px";
      config.bottom = "";
      config.transform = "";
      throw "ERROR: position is not set";
  }

  return config;
};

export { computePos, getInputPosition };
