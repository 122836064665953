// Gather only selected walkthrough object from list for processing
export const retrieveWalkthoughtObject = (walkthroughList, walkthroughName) => {
  let walkthroughObject = [];

  for (let i = 0; i < walkthroughList.length; i++) {
    if (walkthroughList[i].name === walkthroughName) {
      walkthroughObject.push(walkthroughList[i]);
      break;
    }
  }

  return walkthroughObject;
};

// Gather only selected walkthrough objects from list for processing
export const retrieveBulkWalkthroughObjects = (
  walkthroughNameList,
  dataSet
) => {
  let walkthroughObjects = [];
  for (let i = 0; i < walkthroughNameList.length; i++) {
    for (let j = 0; j < dataSet.length; j++) {
      if (walkthroughNameList[i] === dataSet[j].walkthrough_id) {
        walkthroughObjects.push(dataSet[j]);
        break;
      }
    }
  }
  return walkthroughObjects;
};

// Gather only selected walkthrough object from list for processing
export const retrieveSharedWalkthoughtObject = (
  walkthroughList,
  guest_org_id
) => {
  let walkthroughObject = [];

  for (let i = 0; i < walkthroughList.length; i++) {
    if (walkthroughList[i].walkthrough_id === guest_org_id) {
      walkthroughObject.push(walkthroughList[i]);
      break;
    }
  }

  return walkthroughObject;
};

// Gather only selected walkthrough objects from list for processing
export const retrieveBulkSharedWalkthroughObjects = (
  walkthroughNameList,
  dataSet
) => {
  let walkthroughObjects = [];
  for (let i = 0; i < walkthroughNameList.length; i++) {
    for (let j = 0; j < dataSet.length; j++) {
      if (walkthroughNameList[i] === dataSet[j].walkthrough_id) {
        walkthroughObjects.push(dataSet[j]);
        break;
      }
    }
  }
  return walkthroughObjects;
};

export const filterDuplicatedWalkthroughsByWalkthroughID = (
  walkthroughsDataSet,
  selectedWalkthroughsDataSet
) => {
  let filteredWalkthroughsDataSet = [];

  if (
    walkthroughsDataSet &&
    walkthroughsDataSet !== undefined &&
    selectedWalkthroughsDataSet &&
    selectedWalkthroughsDataSet !== undefined
  ) {
    for (let i = 0; i < walkthroughsDataSet.length; i++) {
      const hasMatch = selectedWalkthroughsDataSet.some(
        (walkthroughData) =>
          walkthroughData.walkthrough_id ===
          walkthroughsDataSet[i].walkthrough_id
      );

      if (!hasMatch) {
        // if false means user is not in the right side list
        // we push the data to left side aka membersDataSet
        filteredWalkthroughsDataSet.push(walkthroughsDataSet[i]);
      }
    }
  }

  return filteredWalkthroughsDataSet;
};
