import React from "react";
import { connect, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  AppBar,
  Toolbar,
  IconButton,
  MenuItem,
  Menu,
  Tooltip, // Badge,
} from "@mui/material";

import AddBoxIcon from "@mui/icons-material/AddBox";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import ReceiptIcon from "@mui/icons-material/Receipt";
// import NotificationsIcon from "@mui/icons-material/Notifications";
// import MoreIcon from "@mui/icons-material/MoreVert";

import * as actions from "../../store/actions/_actionIndex";
import { authSignOutUserAccount } from "../../store/reducers/reducerAuth";

const PageTopBar = ({ handleDrawerToggle, onShowCreateWalkthroughModal }) => {
  // Profile menu anchor element reference.
  const [profileMenuAnchorEl, setProfileMenuAnchorEl] = React.useState(null);
  // mobile nav menu anchor element reference.
  // eslint-disable-next-line
  const [mobileNavMenuAnchorEl, setMobileNavMenuAnchorEl] =
    React.useState(null);
  const user = useSelector((state) => state.user.userData);

  const navigate = useNavigate();

  const isProfileMenuOpen = Boolean(profileMenuAnchorEl);
  // const isMobileNavMenuOpen = Boolean(mobileNavMenuAnchorEl);

  // // Create new walkthrough option click handler.
  // const handleCreateNewWalkthroughClick = () => {
  //     onShowCreateWalkthroughModal();
  //     handleMobileNavMenuClose();
  // };

  // // Nav dropdown mobile menu open handler
  // const handleMobileNavMenuOpen = (event) => {
  //     setMobileNavMenuAnchorEl(event.currentTarget);
  // };

  // Profile dropdown menu open handler
  const handleProfileMenuOpen = (event) => {
    setProfileMenuAnchorEl(event.currentTarget);
  };

  // Profile dropdown menu close handler
  const handleMobileNavMenuClose = () => {
    setMobileNavMenuAnchorEl(null);
  };

  // Profile dropdown menu close handler
  const handlerProfileMenuClose = () => {
    setProfileMenuAnchorEl(null);
    handleMobileNavMenuClose();
  };

  // Click handler for account settings option in profile dropdown menu.
  const accountSettingsOptionClickHandler = () => {
    navigate(`/account/settings`);
    handlerProfileMenuClose();
  };

  // Click handler for logout option in profile dropdown menu.
  const logoutOptionClickHandler = () => {
    authSignOutUserAccount();
    handlerProfileMenuClose();
  };

  const handleClickBillingOption = () => {
    navigate(`/billing`);
    handlerProfileMenuClose();
  };

  // // Notifications count.
  // const notificationsCount = 0;

  // // Notification icon with badge.
  // const notificationIconWithBadge = (
  //     <Badge badgeContent={notificationsCount} color="secondary">
  //         <NotificationsIcon/>
  //     </Badge>
  // );

  // Profile dropdown menu ID
  const profileMenuID = "profile-menu";
  // Profile dropdown menu
  const profileMenu = (
    <Menu
      anchorEl={profileMenuAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      keepMounted
      id={profileMenuID}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isProfileMenuOpen}
      onClose={handlerProfileMenuClose}
    >
      <MenuItem onClick={accountSettingsOptionClickHandler}>
        Account Settings
      </MenuItem>
      <MenuItem onClick={logoutOptionClickHandler}>Logout</MenuItem>
    </Menu>
  );

  // // Nav menu ID for dropdown in mobile.
  // const navMenuMobileID = "nav-menu";
  // // Nav links as dropdown menu in mobile.
  // const navMenuMobile = (
  //     <>
  //         <div className="topbarSectionMobile">
  //             <IconButton
  //                 aria-label="show more"
  //                 aria-controls={navMenuMobileID}
  //                 aria-haspopup="true"
  //                 onClick={handleMobileNavMenuOpen}
  //                 color="inherit"
  //             >
  //                 <MoreIcon />
  //             </IconButton>
  //         </div>
  //         <Menu
  //             anchorEl={mobileNavMenuAnchorEl}
  //             anchorOrigin={{ vertical: "top", horizontal: "right" }}
  //             id={navMenuMobileID}
  //             keepMounted
  //             transformOrigin={{ vertical: "top", horizontal: "right" }}
  //             open={isMobileNavMenuOpen}
  //             onClose={handleMobileNavMenuClose}
  //         >
  //             <MenuItem onClick={handleCreateNewWalkthroughClick}>
  //                 <IconButton aria-label="create new walkthrough" color="inherit" className="topbarSectionMobileNavMenuIconBtn">
  //                     <AddBoxIcon/>
  //                 </IconButton>
  //                 <p className="topbarSectionMobileNavMenuText">Create new walkthrough</p>
  //             </MenuItem>
  //             {/* <MenuItem>
  //                 <IconButton aria-label="show 0 new notifications" color="inherit" className="topbarSectionMobileNavMenuIconBtn">
  //                     { notificationIconWithBadge }
  //                 </IconButton>
  //                 <p className="topbarSectionMobileNavMenuText">Notifications</p>
  //             </MenuItem> */}
  //             <MenuItem onClick={handleProfileMenuOpen}>
  //                 <IconButton className="topbarSectionMobileNavMenuIconBtn"
  //                     aria-label="account of current user"
  //                     aria-controls="profile-menu"
  //                     aria-haspopup="true"
  //                     color="inherit"
  //                 >
  //                     <AccountCircle />
  //                 </IconButton>
  //                 <p className="topbarSectionMobileNavMenuText">Profile</p>
  //             </MenuItem>
  //         </Menu>
  //     </>
  // );

  // Nav links bar in desktop.
  const navMenuDesktop = (
    <div className="topbarSectionDesktop">
      {/* <Tooltip title="Create new walkthrough">
                <IconButton aria-label="create new walkthrough" color="inherit" onClick={onShowCreateWalkthroughModal}>
                    <AddBoxIcon/>
                </IconButton>
            </Tooltip> */}

      {/* <IconButton aria-label="show 0 new notifications" color="inherit">
                { notificationIconWithBadge }
            </IconButton> */}

      <IconButton
        edge="end"
        aria-label="account of current user"
        aria-controls={profileMenuID}
        aria-haspopup="true"
        onClick={handleProfileMenuOpen}
        color="inherit"
      >
        <div className="flex flex-col items-center">
          <AccountCircle />
          <span className="text-sm">Account</span>
        </div>
      </IconButton>
    </div>
  );

  return (
    <div className="topbarMenu">
      <AppBar
        position="static"
        sx={{
          root: {
            backgroundColor: "#0d00ff",
          },
        }}
      >
        <Toolbar>
          {/*Nav menu expend icon in mobile*/}

          {/*Navigation bar title*/}

          {/*Empty space between nav title and nav links*/}
          <div className="flex-grow" />

          {/*Billing Page shortcut*/}
          {user.role !== "viewer" && (
            <span className="topbarMenuHeader">
              <IconButton
                aria-label="billing information and settings"
                aria-controls={profileMenuID}
                aria-haspopup="true"
                onClick={handleClickBillingOption}
                color="inherit"
              >
                <div className="flex flex-col items-center">
                  <ReceiptIcon />
                  <span className="text-sm">Billing</span>
                </div>
              </IconButton>
            </span>
          )}

          {/*Nav links in desktop*/}
          {navMenuDesktop}

          {/*Nav links as menu in mobile*/}
          {/* { navMenuMobile } */}

          {/*Profile dropdown menu*/}
          {profileMenu}
        </Toolbar>
      </AppBar>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    onShowCreateWalkthroughModal: () =>
      dispatch(actions.setCreateWalkthroughModalShow(true)),
  };
};

export default connect(null, mapDispatchToProps)(PageTopBar);
