import React from "react";
import { BuildingBlockType } from "../../../interfaces/interfaceTheme";

interface Props {
  style: BuildingBlockType;
}

const ButtonBB = ({ style }: Props) => {
  type textAlignType = "start" | "end" | "center";

  const textAlignMap = {
    "flex-start": "start",
    "flex-end": "end",
    center: "center",
  };

  const _color = style!.color != undefined ? style!.color : "#000000";
  const _fontWeight =
    style!.fontWeight != undefined ? style!.fontWeight : "300";
  const _fontSize =
    style!.fontSize != undefined && parseInt(style!.fontSize.toString())
      ? style!.fontSize + "px"
      : "16px";
  const _fontFamily = style!.fontFamily != undefined ? style!.fontFamily : "";
  const _lineHeight = style!.lineHeight != undefined ? style!.lineHeight : "";
  const _letterSpacing =
    style!.letterSpacing != undefined ? style!.letterSpacing + "px" : "";
  const _justifyContent =
    style!.justifyContent != undefined
      ? style!.justifyContent != "center"
        ? style!.justifyContent
        : style!.justifyContent
      : "flex-start";
  const borderRadius =
    style != undefined && style != null ? style.borderRadius : null;
  const _textAlign =
    style!.justifyContent != undefined
      ? (textAlignMap[style!.justifyContent] as textAlignType)
      : ("" as textAlignType);
  const _alignItems =
    style!.justifyContent != undefined ? style!.justifyContent : "";
  const _margin =
    style!.margin != undefined ? style!.margin + "px" : "6px 12px";
  const _padding =
    style!.padding != undefined ? style!.padding + "px" : "6px 12px";
  const _backgroundColor =
    style!.backgroundColor != undefined ? style!.backgroundColor : "#D3D3D3";
  const _borderRadius =
    borderRadius !== null && borderRadius !== undefined
      ? borderRadius + "px"
      : "6px";

  /* Style that user can't change */
  const _fontType = "";
  const _opacity = "";

  return (
    <div className="w-full mb-0 flex leading-normal box-border justify-end">
      <button
        className="floatRight ut-event ut-button"
        style={{
          color: _color,
          //FIXME:Object literal may only specify known properties, but 'fontType' does not exist in type 'Properties
          //@ts-ignore
          fontType: _fontType,
          fontWeight: _fontWeight,
          fontSize: _fontSize,
          fontFamily: _fontFamily,
          lineHeight: _lineHeight,
          letterSpacing: _letterSpacing,
          justifyContent: _justifyContent,
          textAlign: _textAlign,
          alignItems: _alignItems,
          margin: _margin,
          padding: _padding,
          backgroundColor: _backgroundColor,
          opacity: _opacity,
          borderRadius: _borderRadius,
        }}
      >
        Next
      </button>
    </div>
  );
};

export default ButtonBB;
