import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { googleSignIn } from "../../store/reducers/reducerAuth";

interface Props {
  type?: "signin" | "signup";
}

const GoogleSignIn = ({ type = "signin" }: Props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://accounts.google.com/gsi/client";
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);

    const handleCredentialResponse = (response: any) => {
      dispatch(googleSignIn(response.credential));
    };

    script.onload = () => {
      //@ts-ignore
      google.accounts.id.initialize({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        callback: handleCredentialResponse,
      });
      //@ts-ignore
      google.accounts.id.renderButton(
        document.getElementById("buttonDiv"),
        {
          theme: "outline",
          size: "large",
          width: "200",
          text: type === "signin" ? "signin_with" : "signup_with",
        } // customization attributes
      );
      //@ts-ignore
      google.accounts.id.prompt(); // also display the One Tap dialog
    };

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  const signOut = () => {
    var newWindow = window.open(
      "https://mail.google.com/mail/?logout&hl=fr",
      "Disconnect from Google",
      "width=500,height=400,menubar=no,status=no,location=no,toolbar=no,scrollbars=no,top=200,left=200"
    );
  };
  const onSignIn = (response: any) => {
    // Handle the sign-in response
    console.log("Sign-in successful", response);
  };

  return (
    <div>
      <div id="buttonDiv"></div>
      <div className="g-signin2" data-onsuccess={onSignIn}></div>
    </div>
  );
};

export default GoogleSignIn;
