import React from "react";

import { TableBody, TableCell, TableRow, Checkbox } from "@mui/material";
import { Status } from "usertip-component-library";

import ComponentAddTags from "./ComponentAddTags";
import ComponentListTableBodyActionBtns from "./ComponentListTableBodyActionBtns";

import { stablizeSorting, getComparator } from "./_functions/FunctionListTable";

import { convertDateToStandardFormat } from "./_functions/FunctionDate";

const ComponentListTableBody = ({
  listType,
  displayUserRoleFull,

  dataSet,
  dataObject,

  setSelectedUserList,
  setSelectedTeamList,
  setSelectedWalkthroughList,

  setSelectedGroupsList,
  setSelectedGroupWalkthroughsList,

  setSelectedWalkthroughViewingPermissionsList,
  setSelectedWalkthroughViewerTeamPermissionsList,

  setShowModalRemove,
  setRemoveMode,
  setShowModalMove,
  setMoveMode,

  setShowModalEdit,
  setEditMode,
  setShowModalView,
  setViewMode,

  setShowAlertSnackbar,

  selectedRows,
  setSelectedRows,
  setNumOfRowsSelected,

  order,
  orderBy,

  page,
  rowsPerPage,

  viewerCSHeader,
  metaDataTypes,
}) => {
  // Tracker to track if each row is being selected/checked
  const isSelected = (rowControllwer) => {
    return selectedRows.indexOf(rowControllwer) !== -1;
  };

  // Handles the individually-selected row-clicks and updating it to the array of "selectedRows"
  const handleClick = (event, rowController) => {
    const selectedIndex = selectedRows.indexOf(rowController);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedRows, rowController);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedRows.slice(1));
    } else if (selectedIndex === selectedRows.length - 1) {
      newSelected = newSelected.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1)
      );
    }

    setSelectedRows(newSelected);
    setNumOfRowsSelected(newSelected.length);
  };

  // Handles Pagination: Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataSet.length) : 0;

  return (
    <TableBody>
      {
        // Populates per-row table from dataSet
        stablizeSorting(dataSet, getComparator(order, orderBy))
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) // Handles pagination
          .map((row, index) => {
            const labelId = `checkbox-${index}`;
            // Highlight the "key" for the respective DataTable & do a row-check if "key" is being selected, so that the row can be "highlighted"
            let isItemSelected = null;
            if (listType === "User") {
              isItemSelected = isSelected(row.role_id);
            } else if (listType === "Team") {
              isItemSelected = isSelected(row._id);
            } else if (listType === "Group") {
              isItemSelected = isSelected(row._id);
            } else if (listType === "Walkthrough") {
              isItemSelected = isSelected(row.walkthrough_id);
            } else if (listType === "GroupWalkthrough") {
              isItemSelected = isSelected(row._id);
            } else if (listType === "WalkthroughViewingPermissions") {
              isItemSelected = isSelected(row.role_id);
            } else if (listType === "WalkthroughViewerTeamPermissions") {
              isItemSelected = isSelected(row._id);
            } else if (listType === "sharedWalkthrough") {
              isItemSelected = isSelected(row.guest_org_id);
            } else if (listType === "editSharedWalkthrough") {
              isItemSelected = isSelected(row.walkthrough_id);
            } else if (listType === "hostWalkthrough") {
              isItemSelected = isSelected(row.walkthrough_id);
            }

            if ((row.name === null) | undefined) {
              row.name = "(no name)";
            }

            return (
              <TableRow
                key={index}
                role={listType === "Walkthrough" ? "" : "checkbox"}
                aria-checked={isItemSelected}
                onClick={(event) => {
                  // Manage different types of "Key" push as "rowController", to control the rolls in the table
                  if (listType === "User") {
                    if (row.role !== "owner") {
                      // Disables Row-Clicking if "Owner"
                      handleClick(event, row.role_id);
                    }
                  } else if (listType === "Team") {
                    handleClick(event, row._id);
                  } else if (listType === "Group") {
                    handleClick(event, row._id);
                  } else if (listType === "Walkthrough") {
                    // Disabling Full-Row Click to have a better UI since within "Walkthrough", there is now a TagsList, where User may accidently click to delete tag and end up Selecting entire row by mistake
                    // handleClick(event, row.walkthrough_id);
                  } else if (listType === "GroupWalkthrough") {
                    handleClick(event, row._id);
                  } else if (listType === "WalkthroughViewingPermissions") {
                    handleClick(event, row.role_id);
                  } else if (listType === "WalkthroughViewerTeamPermissions") {
                    handleClick(event, row._id);
                  } else if (listType === "sharedWalkthrough") {
                    handleClick(event, row.guest_org_id);
                  } else if (listType === "editSharedWalkthrough") {
                    handleClick(event, row.walkthrough_id);
                  }
                }}
                selected={
                  listType === "User" && row.role === "owner"
                    ? false
                    : isItemSelected
                }
                tabIndex={-1}
                hover
              >
                {/* hide checkbox */}
                {listType !== "sharedWalkthrough" &&
                  listType !== "hostWalkthrough" && (
                    <TableCell id={labelId} padding={"checkbox"}>
                      <Checkbox
                        color={"primary"}
                        checked={
                          listType === "User" && row.role === "owner"
                            ? false
                            : isItemSelected
                        }
                        inputProps={{ "aria-labelledby": labelId }}
                        disabled={
                          listType === "User" && row.role === "owner"
                            ? true
                            : false
                        }
                        onChange={(e) => {
                          if (
                            listType === "User" ||
                            listType === "WalkthroughViewingPermissions"
                          ) {
                            handleClick(e, row.role_id);
                          } else if (listType === "sharedWalkthrough")
                            handleClick(e, row.guest_org_id);
                          else if (listType === "editSharedWalkthrough")
                            handleClick(e, row.walkthrough_id);
                          else if (listType === "Walkthrough")
                            handleClick(e, row.walkthrough_id);
                          else {
                            handleClick(e, row._id);
                          }
                        }}
                      />
                    </TableCell>
                  )}

                {listType === "User" &&
                  displayUserRoleFull !== "Viewer Code Snippet" && (
                    <>
                      <TableCell align="left">{row.name}</TableCell>
                      <TableCell align="left">{row.email}</TableCell>
                      <TableCell align="left">
                        {row.role === "owner"
                          ? "-"
                          : row.confirmed_invitation
                          ? "Confirmed"
                          : `Pending`}
                      </TableCell>
                      <TableCell align="left">
                        {convertDateToStandardFormat(row.createdAt)}
                      </TableCell>
                      {
                        // Display additional cols when "displayUserRoleFull" is "Viewer"
                        displayUserRoleFull === "Viewer" && (
                          <>
                            <TableCell align="left">Plugin</TableCell>
                            <TableCell align="left">Code Snippet</TableCell>
                          </>
                        )
                      }
                    </>
                  )}
                {/* displaying table for viewer code snippet */}
                {listType === "User" &&
                  displayUserRoleFull === "Viewer Code Snippet" && (
                    <>
                      {viewerCSHeader.map((data, index) => {
                        // if _id, ignore
                        if (data === "_id") {
                          return null;
                        } else if (row.hasOwnProperty(data)) {
                          return (
                            <TableCell key={index} align="left">
                              {data === "createdAt" || data === "updatedAt"
                                ? convertDateToStandardFormat(row[data])
                                : metaDataTypes[data] === "Date"
                                ? convertDateToStandardFormat(row[data])
                                : metaDataTypes[data] === "Boolean"
                                ? row[data].toString()
                                : row[data]}
                            </TableCell>
                          );
                        } else {
                          return (
                            <TableCell
                              key={index}
                              align="left"
                              className="empty-table-cell"
                            >
                              {row[data] ? row[data] : "NA"}
                            </TableCell>
                          );
                        }
                      })}
                    </>
                  )}
                {listType === "Team" && (
                  <>
                    <TableCell align="left">{row.name}</TableCell>
                    <TableCell align="left">
                      {convertDateToStandardFormat(row.createdAt)}
                    </TableCell>
                    <TableCell align="left">
                      {convertDateToStandardFormat(row.updatedAt)}
                    </TableCell>
                  </>
                )}
                {listType === "Group" && (
                  <>
                    <TableCell align="left">{row.name}</TableCell>
                    <TableCell align="left">
                      {convertDateToStandardFormat(row.createdAt)}
                    </TableCell>
                  </>
                )}
                {listType === "Walkthrough" && (
                  <>
                    <TableCell align="left">
                      <Status
                        variants={
                          row.publish === true ? "confirmed" : "pending"
                        }
                        size="fluid"
                        text={row.publish ? "Live" : "Draft"}
                      />
                    </TableCell>
                    <TableCell align="left">{row.name}</TableCell>
                    <TableCell align="right">{row.launched}</TableCell>
                    <TableCell align="right">{row.completed}</TableCell>
                    <TableCell align="right">{row.dismissed}</TableCell>
                    <TableCell align="left">
                      {convertDateToStandardFormat(row.createdAt)}
                    </TableCell>
                    <TableCell align="left">
                      <ComponentAddTags
                        inputWalkthroughTags={row.tags}
                        walkthroughID={row.walkthrough_id}
                      />
                    </TableCell>
                  </>
                )}
                {listType === "GroupWalkthrough" && (
                  <>
                    <TableCell align="left">
                      <div
                        className={`tableWalkthroughStatus ${
                          row.publish === true
                            ? "tableWalkthroughStatusLive"
                            : "tableWalkthroughStatusDraft"
                        }`}
                      >
                        {row.publish ? "Live" : "Draft"}
                      </div>
                    </TableCell>
                    <TableCell align="left">{row.name}</TableCell>
                    <TableCell align="left">
                      {convertDateToStandardFormat(row.createdAt)}
                    </TableCell>
                    <TableCell align="left">
                      <ComponentAddTags
                        inputWalkthroughTags={row.tags}
                        walkthroughID={row.walkthrough_id}
                      />
                    </TableCell>
                  </>
                )}

                {listType === "WalkthroughViewingPermissions" && (
                  <>
                    <TableCell align="left">
                      {row.name ? row.name : "-"}
                    </TableCell>
                    <TableCell align="left">
                      {row.unique_id ? row.unique_id : "-"}
                    </TableCell>
                    <TableCell align="left">
                      {row.email ? row.email : "-"}
                    </TableCell>
                    <TableCell align="left">{row.role_id}</TableCell>
                    <TableCell align="left">
                      <span className="capitalize">{row.role}</span>
                    </TableCell>
                    <TableCell align="left">
                      {convertDateToStandardFormat(row.createdAt)}
                    </TableCell>
                    <TableCell align="left">
                      {row.role === "viewer" ? `Yes` : `-`}
                    </TableCell>
                    <TableCell align="left">
                      {row.role === "viewer" ? `Yes` : `-`}
                    </TableCell>
                  </>
                )}
                {listType === "WalkthroughViewerTeamPermissions" && (
                  <>
                    <TableCell align="left">{row.name}</TableCell>
                    <TableCell align="left">
                      {convertDateToStandardFormat(row.createdAt)}
                    </TableCell>
                    <TableCell align="left">
                      {convertDateToStandardFormat(row.updatedAt)}
                    </TableCell>
                  </>
                )}

                {listType === "sharedWalkthrough" && (
                  <>
                    <TableCell align="left">{row.guest_org_name}</TableCell>
                    <TableCell align="left">
                      {convertDateToStandardFormat(row.date_create)}
                    </TableCell>
                    <TableCell align="left">
                      {convertDateToStandardFormat(row.last_edit)}
                    </TableCell>
                  </>
                )}

                {listType === "editSharedWalkthrough" && (
                  <>
                    <TableCell align="left">{row.walkthroughName}</TableCell>
                    <TableCell align="left">
                      {convertDateToStandardFormat(dataObject.last_edit)}
                    </TableCell>
                  </>
                )}

                {listType === "hostWalkthrough" && (
                  <>
                    <TableCell align="left">
                      <div
                        className={`tableWalkthroughStatus ${
                          row.status
                            ? "tableWalkthroughStatusLive"
                            : "tableWalkthroughStatusDraft"
                        }`}
                      >
                        {row.status ? "Display" : "Hide"}
                      </div>
                    </TableCell>
                    <TableCell align="left">{row.walkthrough_name}</TableCell>
                    <TableCell align="left">{row.hostName}</TableCell>
                    <TableCell align="left">
                      {convertDateToStandardFormat(row.dateCreated)}
                    </TableCell>
                  </>
                )}

                <ComponentListTableBodyActionBtns
                  listType={listType}
                  displayUserRoleFull={displayUserRoleFull}
                  dataSet={dataSet}
                  row={row}
                  dataObject={dataObject}
                  setShowModalRemove={setShowModalRemove}
                  setRemoveMode={setRemoveMode}
                  setShowModalMove={setShowModalMove}
                  setMoveMode={setMoveMode}
                  setShowModalEdit={setShowModalEdit}
                  setEditMode={setEditMode}
                  setShowModalView={setShowModalView}
                  setViewMode={setViewMode}
                  setShowAlertSnackbar={setShowAlertSnackbar}
                  setSelectedUserList={setSelectedUserList}
                  setSelectedTeamList={setSelectedTeamList}
                  setSelectedWalkthroughList={setSelectedWalkthroughList}
                  setSelectedGroupsList={setSelectedGroupsList}
                  setSelectedGroupWalkthroughsList={
                    setSelectedGroupWalkthroughsList
                  }
                  setSelectedWalkthroughViewingPermissionsList={
                    setSelectedWalkthroughViewingPermissionsList
                  }
                  setSelectedWalkthroughViewerTeamPermissionsList={
                    setSelectedWalkthroughViewerTeamPermissionsList
                  }
                />
              </TableRow>
            );
          })
      }

      {
        // Creating rows to help avoid a layout jump with emptyRows existing
        emptyRows > 0 && (
          <TableRow
            style={{
              height: 33 * emptyRows,
            }}
          >
            <TableCell colSpan={6} />
          </TableRow>
        )
      }
    </TableBody>
  );
};

export default ComponentListTableBody;
