import React from "react";
import TextBB from "./TextBB";
import ButtonBB from "./ButtonBB";
import { BuildingBlockType } from "../../../interfaces/interfaceTheme";
import DismissBB from "./DismissBB";

interface Props {
  buildingBlockStyle?: BuildingBlockType[];
}

const ContainerBB = ({ buildingBlockStyle }: Props) => {
  return (
    <div className="text-base block relative box-border p-6 w-[inherit]">
      {buildingBlockStyle?.map((val, index) => {
        const bbType = val.label;

        switch (bbType) {
          case "title":
            return <TextBB key={index} style={val} type="title" />;
          case "subtitle":
            return <TextBB key={index} style={val} type="subtitle" />;
          case "button":
            return <ButtonBB key={index} style={val} />;
        }
      })}
      <DismissBB />
    </div>
  );
};

export default ContainerBB;
