// Hardcoded GTM ID
// TODO: Add GTM ID to .env file
const GTM_ID = "GTM-KC7734G";

/** Google Tag Manager Code Snippets */
const Snippets = {
  tags: function ({
    id,
    events,
    dataLayer,
    dataLayerName,
    preview,
    auth,
    user_cognito_id,
  }) {
    const gtm_auth = `&gtm_auth=${auth}`;
    const gtm_preview = `&gtm_preview=${preview}`;

    if (!id) console.warn("[react-gtm]", "GTM Id is required");

    /** Google Tag Manager */
    const script = `
        (function (w, d, s, l, i) {
            w[l] = w[l] || [];
            w[l].push({
                'user_cognito_id': '${user_cognito_id}', 
                'gtm.start': new Date().getTime(),
                event:'gtm.js', 
            ${JSON.stringify(events).slice(1, -1)}
            });
            var f = d.getElementsByTagName(s)[0],
                j = d.createElement(s),
                dl = l != "dataLayer" ? "&l=" + l : "";
          j.async = true;
          j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl 
            + '${gtm_auth}${gtm_preview}&gtm_cookies_win=x';
          f.parentNode.insertBefore(j, f);
        })(window, document, 'script', '${dataLayerName}', '${id}');`;
    /** End Google Tag Manager */

    /** Google Tag Manager (noscript) */
    const iframe = `
        <iframe 
            src="https://www.googletagmanager.com/ns.html?id=${id}${gtm_auth}${gtm_preview}&gtm_cookies_win=x"
            height="0" 
            width="0" 
            style="display:none;
            visibility:hidden" 
            id="tag-manager"
        ></iframe>`;
    /** End Google Tag Manager (noscript) */

    const dataLayerVar = this.dataLayer(dataLayer, dataLayerName);

    return {
      iframe,
      script,
      dataLayerVar,
    };
  },
  dataLayer: function (dataLayer, dataLayerName) {
    return `
        window.${dataLayerName} = window.${dataLayerName} || [];
        window.${dataLayerName}.push(${JSON.stringify(dataLayer)})`;
  },
};
/** Google Tag Manager Code Snippets ENDS */

/** Google Tag Manager Object */
const TagManager = {
  dataScript: function (dataLayer) {
    const script = document.createElement("script");
    script.innerHTML = dataLayer;
    return script;
  },
  gtm: function (args) {
    const snippets = Snippets.tags(args);

    const noScript = () => {
      const noscript = document.createElement("noscript");
      noscript.innerHTML = snippets.iframe;
      return noscript;
    };

    const script = () => {
      const script = document.createElement("script");
      script.innerHTML = snippets.script;
      return script;
    };

    const dataScript = this.dataScript(snippets.dataLayerVar);

    return {
      noScript,
      script,
      dataScript,
    };
  },
  initialize: function ({
    gtmId = GTM_ID,
    events = {},
    dataLayer = {},
    dataLayerName = "dataLayer",
    auth = "",
    preview = "",
    user_cognito_id = "",
  }) {
    const gtm = this.gtm({
      id: gtmId,
      events,
      dataLayer: dataLayer || undefined,
      dataLayerName: dataLayerName,
      auth,
      preview,
      user_cognito_id,
    });

    if (dataLayer) document.head.appendChild(gtm.dataScript);
    document.head.insertBefore(gtm.script(), document.head.childNodes[0]);
    document.body.insertBefore(gtm.noScript(), document.body.childNodes[0]);
  },
  dataLayer: function ({ dataLayer, dataLayerName = "dataLayer" }) {
    if (window[dataLayerName]) return window[dataLayerName].push(dataLayer);
    const snippets = Snippets.dataLayer(dataLayer, dataLayerName);
    const dataScript = this.dataScript(snippets);
    document.head.insertBefore(dataScript, document.head.childNodes[0]);
  },
};
/** Google Tag Manager Object ENDS */

module.exports = TagManager;

/**
 * How to use TagManager Object:
 * 1. import TagManager from "./utility/functions/GA4TagManager";
 * 2. Call the TagManager.initialize() method with the following arguments:
 *    - TagManager.initialize({});  // DEFAULT Initialize GTM (For logged out users)
 *    - TagManager.initialize({     // Initialize GTM with with cognito_id (For logged in users)
          dataLayer: {
            user_cognito_id: cognito_id,
          },
          user_cognito_id: cognito_id, 
        }); 
 * 
 */
