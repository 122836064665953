import React from "react";

// import { Button } from "@mui/material";
import { Button } from "usertip-component-library";

import ComponentListTableBtnToolTipIcon from "./ComponentListTableBtnToolTipIcon";

import { retrieveBulkUserDetailsFromEmailList } from "./_functions/FunctionUser";
import { retrieveBulkTeamObjects } from "./_functions/FunctionTeam";
import { retrieveBulkGroupObjects } from "./_functions/FunctionGroup";
import {
  retrieveBulkSharedWalkthroughObjects,
  retrieveBulkWalkthroughObjects,
} from "./_functions/FunctionWalkthrough";
import { Delete } from "@mui/icons-material";

const ComponentListTableBtnBulk = ({
  btnType,
  listType,
  dataSet,

  setSelectedUserList,
  setSelectedTeamList,

  setSelectedWalkthroughList,

  setSelectedGroupsList,
  setSelectedGroupWalkthroughsList,

  setSelectedWalkthroughViewingPermissionsList,
  setSelectedWalkthroughViewerTeamPermissionsList,

  setShowModalRemove,
  setRemoveMode,
  setShowModalMove,
  setMoveMode,
  setShowModalEdit,
  setEditMode,

  selectedRows,

  selectMode,
}) => {
  // User
  const handleBulkMoveUserClick = (userEmailList) => {
    // Push selected user into "selectedUserList" array for processes
    setSelectedUserList(
      retrieveBulkUserDetailsFromEmailList(userEmailList, dataSet)
    );
    // Notify mode of Move
    setMoveMode("bulk");
    setShowModalMove(true);
  };

  const handleBulkRemoveUserClick = (userEmailList) => {
    // Push selected user into "selectedUserList" array for processes
    setSelectedUserList(
      retrieveBulkUserDetailsFromEmailList(userEmailList, dataSet)
    );
    // Notify mode of Removal
    setRemoveMode("bulk");
    setShowModalRemove(true);
  };

  // Team
  const handleBulkRemoveTeamClick = (teamNameList) => {
    // Push selected teams into "selectedTeamList" array for processes
    setSelectedTeamList(retrieveBulkTeamObjects(teamNameList, dataSet));

    // Notify mode of Removal
    setRemoveMode("bulk");
    setShowModalRemove(true);
  };

  // Group
  const handleBulkRemoveGroupClick = (teamGroupList) => {
    // Push selected teams into "selectedGroupsList" array for processes
    setSelectedGroupsList(retrieveBulkGroupObjects(teamGroupList, dataSet));

    // Notify mode of Removal
    setRemoveMode("bulk");
    setShowModalRemove(true);
  };

  // Walkthrough
  const handleBulkRemoveWalkthroughClick = (walkthroughNameList) => {
    // Push selected teams into "selectedTeamList" array for processes
    setSelectedWalkthroughList(
      retrieveBulkWalkthroughObjects(walkthroughNameList, dataSet)
    );

    // Notify mode of Removal
    setRemoveMode("bulk");
    setShowModalRemove(true);
  };

  // Group Walkthroughs
  const handleBulkRemoveGroupWalkthroughsClick = (walkthroughNameList) => {
    // Push selected teams into "selectedTeamList" array for processes
    setSelectedGroupWalkthroughsList(
      retrieveBulkWalkthroughObjects(walkthroughNameList, dataSet)
    );

    // Notify mode of Removal
    setRemoveMode("bulk");
    setShowModalRemove(true);
  };
  const handleBulkEditWithSelectionGroupWalkthroughsClick = () => {
    // No data processing required as data is pulled from API

    // Notify mode of Edit
    setEditMode("bulk");
    setShowModalEdit(true);
  };
  const handleBulkEditWithoutSelectionGroupWalkthroughsClick = () => {
    // No data processing required as data is pulled from API

    // Notify mode of Edit
    setEditMode("bulk");
    setShowModalEdit(true);
  };

  // Walkthrough Viewing Permissions
  const handleBulkRemoveWalkthroughViewingPermissionsClick = (
    WalkthroughViewingPermissionsNameList
  ) => {
    // Push selected teams into "selectedWalkthroughViewingPermissionsList" array for processes
    setSelectedWalkthroughViewingPermissionsList(
      retrieveBulkUserDetailsFromEmailList(
        WalkthroughViewingPermissionsNameList,
        dataSet
      )
    );

    // Notify mode of Removal
    setRemoveMode("bulk");
    setShowModalRemove(true);
  };
  const handleBulkEditWithSelectionWalkthroughViewingPermissionsClick = () => {
    // No data processing required as data is pulled from API

    // Notify mode of Edit
    setEditMode("bulk");
    setShowModalEdit(true);
  };
  const handleBulkEditWithoutSelectionWalkthroughViewingPermissionsClick =
    () => {
      // No data processing required as data is pulled from API

      // Notify mode of Edit
      setEditMode("bulk");
      setShowModalEdit(true);
    };

  // Walkthrough Viewer Team Permissions
  const handleBulkRemoveWalkthroughViewerTeamPermissionsClick = (
    walkthroughViewerTeamNameList
  ) => {
    // Push selected teams into "selectedWalkthroughViewerTeamPermissionsList" array for processes
    setSelectedWalkthroughViewerTeamPermissionsList(
      retrieveBulkTeamObjects(walkthroughViewerTeamNameList, dataSet)
    );

    // Notify mode of Removal
    setRemoveMode("bulk");
    setShowModalRemove(true);
  };
  const handleBulkEditWithSelectionWalkthroughViewerTeamPermissionsClick =
    () => {
      // No data processing required as data is pulled from API

      // Notify mode of Edit
      setEditMode("bulk");
      setShowModalEdit(true);
    };
  const handleBulkEditWithoutSelectionWalkthroughViewerTeamPermissionsClick =
    () => {
      // No data processing required as data is pulled from API

      // Notify mode of Edit
      setEditMode("bulk");
      setShowModalEdit(true);
    };

  //Shared walkthrough
  const handleBulkRemoveSharedWalkthrough = (selectedData) => {
    setSelectedWalkthroughList(
      retrieveBulkSharedWalkthroughObjects(selectedData, dataSet)
    );

    // Notify mode of Removal
    setRemoveMode("bulk");
    setShowModalRemove(true);
  };

  // Generate custom / default Button Name
  const generateBtnName = (listType, btnType) => {
    let btnName = null;
    if (listType === "WalkthroughViewingPermissions" && btnType === "Edit") {
      btnName = `Edit Permissions`;
    } else if (
      listType === "WalkthroughViewerTeamPermissions" &&
      btnType === "Edit"
    ) {
      btnName = `Edit Permissions`;
    } else if (listType === "GroupWalkthrough" && btnType === "Edit") {
      btnName = `Edit Walkthrough List`;
    } else {
      btnName = `Bulk ${btnType}`;
    }

    return btnName;
  };

  return (
    <Button
      variant="primary"
      color="primary"
      size="small"
      text={generateBtnName(listType, btnType)}
      icon={<Delete fontSize="small" />}
      iconLeft={true}
      customClass="mr-[5px]"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();

        if (selectedRows.length > 0) {
          if (listType === "User") {
            if (btnType === "Move") {
              handleBulkMoveUserClick(selectedRows);
            } else if (btnType === "Delete") {
              handleBulkRemoveUserClick(selectedRows);
            }
          } else if (listType === "Team") {
            if (btnType === "Delete") {
              handleBulkRemoveTeamClick(selectedRows);
            }
          } else if (listType === "Group") {
            if (btnType === "Delete") {
              handleBulkRemoveGroupClick(selectedRows);
            }
          } else if (listType === "Walkthrough") {
            if (btnType === "Delete") {
              handleBulkRemoveWalkthroughClick(selectedRows);
            }
          } else if (listType === "GroupWalkthrough") {
            if (btnType === "Delete") {
              handleBulkRemoveGroupWalkthroughsClick(selectedRows);
            } else if (btnType === "Edit") {
              handleBulkEditWithSelectionGroupWalkthroughsClick(selectedRows);
            }
          } else if (listType === "WalkthroughViewingPermissions") {
            if (btnType === "Delete") {
              handleBulkRemoveWalkthroughViewingPermissionsClick(selectedRows);
            } else if (btnType === "Edit") {
              handleBulkEditWithSelectionWalkthroughViewingPermissionsClick(
                selectedRows
              );
            }
          } else if (listType === "WalkthroughViewerTeamPermissions") {
            if (btnType === "Delete") {
              handleBulkRemoveWalkthroughViewerTeamPermissionsClick(
                selectedRows
              );
            } else if (btnType === "Edit") {
              handleBulkEditWithSelectionWalkthroughViewerTeamPermissionsClick(
                selectedRows
              );
            }
          } else if (listType === "editSharedWalkthrough") {
            if (btnType === "Delete") {
              handleBulkRemoveSharedWalkthrough(selectedRows);
            }
          }
        }
        // If No Rows are Selected & Button is Still Clicked
        else {
          if (listType === "WalkthroughViewingPermissions") {
            if (btnType === "Edit") {
              handleBulkEditWithoutSelectionWalkthroughViewingPermissionsClick(
                selectedRows
              );
            }
          } else if (listType === "WalkthroughViewerTeamPermissions") {
            if (btnType === "Edit") {
              handleBulkEditWithoutSelectionWalkthroughViewerTeamPermissionsClick(
                selectedRows
              );
            }
          } else if (listType === "GroupWalkthrough") {
            if (btnType === "Edit") {
              handleBulkEditWithoutSelectionGroupWalkthroughsClick(
                selectedRows
              );
            }
          }
        }
      }}
    >
      {generateBtnName(listType, btnType)}
    </Button>
  );
};

export default ComponentListTableBtnBulk;
