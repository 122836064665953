import {
  Button,
  Card,
  CardContent,
  Divider,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box } from "victory";
import { callPostSubscribe } from "../axios/v2/callsPayment";
import { OrgPlan } from "../interfaces/interface Organization";
import { RootState } from "../store";
import mapDateGetMonth from "../utility/functions/mapDateGetMonth";
import ComponentCancelSubscription from "./Payment/ComponentCancelSubscription";
import ComponentChangeSubscriptionPlan from "./Payment/ComponentChangeSubscriptionPlan";
import ComponentUpdateInvoiceEmailModal from "./Payment/ComponentUpdateInvoiceEmailModal";

const ComponentPaymentSetting = () => {
  const [changePayment, setChangePayment] = React.useState(false);
  const [selectPaymentTier, setSelectPaymentTier] = React.useState<
    "FREE1" | "PAID1" | "PAID2"
  >("FREE1");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);
  const [showModalChangeSubscription, setShowModalChangeSubscription] =
    useState(false);
  const [showModalCancelSubscription, setShowModalCancelSubscription] =
    useState(false);

  const currentTier = localStorage.getItem("ut-planTier");
  const loggedInUserRole = useSelector(
    (state: RootState) => state.user.userData.role
  );

  const paymentInfo = useSelector(
    (state: RootState) => state.payment.plan_info
  );

  const orgPlanInfo = useSelector((state: RootState) => state.org.org_plan);

  /** Due date of the next invoice. */
  const [dueDate, setDueDate] = useState<Date | null>(null);

  /** Email where invoices are sent to. Default is the org owner's email. */
  const [invoiceEmail, setInvoiceEmail] = useState<string | null>(null);

  /** State to manage invoice amount details */
  const [invoiceAmount, setInvoiceAmount] = useState<number | null>(null);

  /** State to manage current org plan details */
  const [orgPlan, setOrgPlan] = useState<string | null>(null);

  /** State to manage plan type details
   * Can be "subscription" or "custom"
   *
   * NOTE: subscription cannot change plan to custom and vice versa
   */
  const [planType, setPlanType] = useState<string | null>(null);

  useEffect(() => {
    if (paymentInfo) {
      setDueDate(new Date(paymentInfo.end_date));
      setInvoiceEmail(paymentInfo.invoice_email);
    }
  }, [paymentInfo]);

  useEffect(() => {
    if (orgPlanInfo) {
      /** TODO: For now price is taken from orgPlan, it should be from paymentInfoDoc */
      setInvoiceAmount(orgPlanInfo.plan_tier.price);

      const planTier = orgPlanInfo.plan_tier;
      console.log(orgPlanInfo, "plan tier");
      if (orgPlanInfo.plan_tier.name) {
        setOrgPlan(orgPlanInfo.plan_tier.name);
        setPlanType(orgPlanInfo.plan_type);
      } else {
        setOrgPlan("FREE");
        // setPlanType(null);
      }

      // setOrgPlan(orgPlanInfo.plan_tier.name);
    }
    console.log("---orgPlanInfo", orgPlanInfo);
  }, [orgPlanInfo]);

  /** Open Invoice Email Modal */
  const openUpdateInvoiceEmailModal = () => {
    setShowModal(true);
  };

  /** Function to convert the plan tier code for the end user. */
  const convertPlanTierCodeToValue = (tier: string) => {
    console.log("tier", tier);
    switch (tier) {
      case "FREE1": {
        return "Free Plan";
      }
      case "FREE2": {
        return "Custom Free Plan";
      }
      case "FREE3": {
        return "Free Plan";
      }
      case "PAID1": {
        return "Business Lite Plan";
      }
      case "PAID2": {
        return "Business Premium Plan";
      }
      case "STARTUP": {
        return "Startup Plan";
      }
      case "CUSTOM": {
        return "Custom Business Plan";
      }
      case "ENTERPRISE": {
        return "Enterprise";
      }
      case "BUSINESS_PLAN": {
        return "Business Plan";
      }
      case "STARTUP_PLAN": {
        return "Startup Plan";
      }
      case "MICRO_PLAN": {
        return "Micro Plan";
      }
      default: {
        return "Custom Plan";
      }
    }
  };

  return (
    <>
      <div className="pageSettings">
        <Paper className="mb-5">
          <div className="flex">
            <div className="p-6 mr-auto">
              <Typography
                sx={{ fontSize: "1rem" }}
                color="neutral-black"
                gutterBottom
              >
                Next invoice due date
              </Typography>
              <Typography
                sx={{ fontSize: "1.5rem", fontWeight: "900" }}
                color="primary"
                gutterBottom
              >
                {dueDate
                  ? `${dueDate.getDate()} ${
                      mapDateGetMonth[dueDate.getMonth()]
                    }, ${dueDate.getFullYear()}`
                  : null}
              </Typography>
            </div>
            <Divider orientation="vertical" flexItem />
            <div className="p-6 mr-auto">
              <Typography
                sx={{ fontSize: "1rem" }}
                color="neutral-black"
                gutterBottom
              >
                Invoice amount
              </Typography>
              <Typography
                sx={{ fontSize: "1.5rem", fontWeight: "900" }}
                color="primary"
                gutterBottom
              >
                {invoiceAmount !== null
                  ? `$${invoiceAmount.toLocaleString("en-US")}`
                  : null}
              </Typography>
            </div>
            <Divider orientation="vertical" flexItem />
            <div className="p-6 mr-auto flex flex-col">
              <Typography
                sx={{ fontSize: "1rem" }}
                color="neutral-black"
                gutterBottom
              >
                Plan Type
              </Typography>
              <Typography
                sx={{ fontSize: "1.5rem", fontWeight: "900" }}
                color="primary"
                // gutterBottom
              >
                {/* {orgPlan !== null
                  ? `${convertPlanTierCodeToValue(orgPlan)}`
                  : "Error displaying plan"} */}
                {orgPlanInfo !== null
                  ? orgPlanInfo.plan_tier.title
                  : "Error displaying plan"}
              </Typography>
              {planType && (
                <Typography
                  sx={{ fontSize: "1rem" }}
                  color="neutral-black"
                  gutterBottom
                >
                  {planType === "custom"
                    ? "(Custom Plan)"
                    : "(Subscription Plan)"}
                </Typography>
              )}
              {/* Cancel, change and update payment information is only for subscription plan type */}
              {planType === "subscription" && (
                <>
                  <span
                    className="blue__link"
                    onClick={() => {
                      setShowModalCancelSubscription(true);
                    }}
                  >
                    Cancel Subscription
                  </span>
                  <span
                    className="blue__link"
                    onClick={() => {
                      // setShowModalChangeSubscription(true);
                      navigate("/billing/subscription");
                    }}
                  >
                    Change subscription plan
                  </span>
                  {/* TODO: Update payment information */}
                  {/* <span
                className="blue__link"
                onClick={() => {
                  alert("change subscription plan");
                }}
              >
                Update payment information
              </span> */}
                </>
              )}
            </div>
          </div>
        </Paper>
        <Paper className="mb-5">
          <div className="flex">
            <div className="p-6 mr-auto">
              <Typography
                sx={{ fontSize: "1rem" }}
                color="neutral-black"
                gutterBottom
              >
                Next renewal date
              </Typography>
              <Typography
                sx={{ fontSize: "1.5rem", fontWeight: "900" }}
                color="primary"
                gutterBottom
              >
                {dueDate
                  ? `${dueDate.getDate()} ${
                      mapDateGetMonth[dueDate.getMonth()]
                    }, ${dueDate.getFullYear()}`
                  : null}
              </Typography>
            </div>
            <Divider orientation="vertical" flexItem />
            <div className="p-6 mr-auto flex flex-col">
              <Typography
                sx={{ fontSize: "1rem" }}
                color="neutral-black"
                gutterBottom
              >
                Invoices sent to
              </Typography>
              <Typography
                sx={{ fontSize: "1.5rem", fontWeight: "900" }}
                color="primary"
                gutterBottom
              >
                {invoiceEmail}
              </Typography>
              <span
                className="blue__link"
                onClick={openUpdateInvoiceEmailModal}
              >
                Change contact
              </span>
            </div>
          </div>
        </Paper>
        <div className="modalActionBtnBar mt-4">
          {/* TODO: if isOnboarding false remove the confirm button */}
          {/* <Button
            variant="contained"
            className="modalActionBtn"
            onClick={() => {
              navigate("/onboarding");
            }}
          >
            Confirm Payment
          </Button> */}
        </div>
      </div>
      <ComponentUpdateInvoiceEmailModal
        showModal={showModal}
        setShowModal={setShowModal}
      />
      <ComponentChangeSubscriptionPlan
        showModal={showModalChangeSubscription}
        setShowModal={setShowModalChangeSubscription}
        currentPlanType={orgPlan!}
      />
      <ComponentCancelSubscription
        setShowModal={setShowModalCancelSubscription}
        showModal={showModalCancelSubscription}
      />
    </>
  );
};

export default ComponentPaymentSetting;
