import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import ComponentListWalkthroughs from "../components/ComponentListWalkthroughs";
import {
  getRestOfWalkthroughs,
  getWalkthroughs,
  getWalkthroughsSummary,
  resetAlert,
} from "../store/reducers/reducerWalkthrough";
import ComponentLoading from "../components/ComponentLoading";
import ComponentAlertAppOverallSnackbar from "../components/ComponentAlertAppOverallSnackbar";
import { useNavigate } from "react-router-dom";
import TableWalkthroughs from "../components/Table/TableWalkthroughs";
import OnboardingChecklist from "../components/OnBoarding/OnBoardingCheckList";
import ComponentFilterTable from "../components/ComponentFilterTable";
import { RootState } from "../store";

const PageWalkthroughs = () => {
  const [filteredWalkthrough, setFilteredWalkthrough] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  //state from redux
  const walkthroughs = useSelector(
    (state: RootState) => state.walkthrough.walkthroughs
  );
  const lastUpdated = useSelector(
    (state: RootState) => state.walkthrough.resource_update_datetime
  );
  const user = useSelector((state: RootState) => state.user.userData);

  const isLoading = useSelector(
    (state: RootState) => state.walkthrough.appLoading
  );
  const alert = useSelector((state: RootState) => state.walkthrough.alert);

  const totalWalkthroughs = useSelector(
    (state: RootState) => state.walkthrough.walkthroughTotal
  );

  const walkthroughFilter = [
    {
      field: "publish", //status
      type: "Boolean",
      label: "Status",
    },
    {
      field: "name", //walkthrough
      type: "String",
      label: "Walkthrough",
    },

    {
      field: "launched",
      type: "Integer",
      label: "Launched",
    },
    {
      field: "completed",
      type: "Integer",
      label: "Completed",
    },
    {
      field: "dismissed",
      type: "Integer",
      label: "Dismissed",
    },
    {
      field: "createdAt",
      type: "Date",
      label: "Date Created",
    },
  ];

  useEffect(() => {
    if (lastUpdated) {
      if (user.cognito_id && user.cognito_id.length > 0) {
        dispatch(getWalkthroughsSummary(user.cognito_id));
        dispatch(getWalkthroughs(user.cognito_id));
      }
    }
    console.log("rerendering");
  }, [lastUpdated, dispatch, user.cognito_id]);

  useEffect(() => {
    if (walkthroughs) {
      if (walkthroughs.length < totalWalkthroughs) {
        dispatch(getRestOfWalkthroughs(user.cognito_id));
      }
    }
  }, [walkthroughs]);

  return (
    <>
      <ComponentLoading show={isLoading} />
      <div className="pageBase">
        {/*Walkthrough list*/}
        <ComponentListWalkthroughs
          displayShowAddBtn={true}
          introMsg={"View and manage walkthroughs here."}
          indicatorMsg={"No walkthroughs found. Please add one!"}
          dataSet={walkthroughs}
        />
      </div>

      {walkthroughs ? (
        <ComponentFilterTable
          filters={walkthroughFilter}
          originalData={walkthroughs}
          setFilteredData={setFilteredWalkthrough}
        />
      ) : (
        <div className="mb-16"></div>
      )}

      <TableWalkthroughs walkthroughData={filteredWalkthrough} />

      {alert.type !== "" && alert.type !== undefined && (
        <ComponentAlertAppOverallSnackbar
          alert={alert}
          reset={() => dispatch(resetAlert())}
        />
      )}
    </>
  );
};

export default PageWalkthroughs;
