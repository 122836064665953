import React from "react";
import generateClassName from "../../_functions/functionGenerateClassName";
interface Props {
  title: string;
  className?: string;
}
const Header = (props: Props) => {
  return (
    <div className={`pageHeader ${generateClassName(props.className)}`}>
      {props.title}
    </div>
  );
};

export default Header;
