import { FormControl, FormControlLabel, Switch } from "@mui/material";
import { DataGrid, GridCellParams, GridColDef } from "@mui/x-data-grid";
import React, { useState } from "react";
import { UserInteractivityProcDataSet } from "../../../../interfaces/interfaceAnalytic";
import ComponentFilterTable from "../../../ComponentFilterTable";
import "./cellStyle.css";

const ComponentUserInteractionTable = ({
  dataSet,
  setFilteredDataSet,
  filterDataSet,
}: {
  dataSet: UserInteractivityProcDataSet[];
  filterDataSet: UserInteractivityProcDataSet[];
  setFilteredDataSet: React.Dispatch<
    React.SetStateAction<UserInteractivityProcDataSet[]>
  >;
}) => {
  const [showAnonymous, setShowAnonymous] = useState(false);

  const handleAnonymousToggle = (event: any) => {
    setShowAnonymous(event.target.checked);
  };

  const columns: GridColDef[] = [
    {
      field: "role_id",
      headerName: "Role ID",
      description: "Role ID is an anonymous identifier assigned to the user.",
      headerAlign: "left",
      width: 150,
      sortable: false,
      cellClassName: (params: any) => {
        if (!params.value) {
          return "empty-table-cell";
        }

        return "";
      },
      valueFormatter(params: any) {
        return params.value ? params.value : "NA";
      },
      filterable: false,
    },
    {
      field: "email",
      headerName: "Email",
      headerAlign: "left",
      width: 175,
      cellClassName: (params: any) => {
        if (!params.value) {
          return "empty-table-cell";
        }

        return "";
      },
      valueFormatter(params: any) {
        return params.value ? params.value : "NA";
      },
      filterable: false,
    },
    {
      field: "unique_id",
      description:
        "(METADATA) The unique_id is a metadata value passed down when a new code snippet viewer is detected.",
      headerName: "unique_id",
      headerAlign: "left",
      width: 150,
      sortable: false,
      cellClassName: (params: any) => {
        if (!params.value) {
          return "empty-table-cell";
        }

        return "";
      },
      valueFormatter(params: any) {
        return params.value ? params.value : "NA";
      },
      filterable: false,
    },
    {
      field: "avg_spent",
      headerName: "Avg Completion Time",
      headerAlign: "center",
      align: "center",
      width: 200,
      valueFormatter(params: any) {
        /** Get the raw value, if it is null default value is 0. */
        const raw_value = params.value ? params.value : 0;

        /** Calculate hours, exclude any decimals */
        const hours = Math.floor(raw_value / 3600);

        /** Calculate minutes, exclude any decimals */
        const minutes = Math.floor((raw_value % 3600) / 60);

        /** Calculate seconds by getting the remainder */
        const seconds = raw_value % 60;

        /** Build the string to display */
        let string = "";
        /** If hours is more than 0, add hours first */
        if (hours > 0) {
          string += `${hours}h`;
        }
        /** If minutes is more than 0, add minutes second */
        if (minutes > 0) {
          string += `${minutes}m`;
        }

        /** Finally add the seconds */
        string += `${seconds}s`;

        /** Return the built string */
        return string;
      },
      filterable: false,
    },
    {
      field: "seen",
      headerName: "Seen",
      headerAlign: "center",
      align: "center",
      filterable: false,
    },
    {
      field: "completed",
      headerName: "Completed",
      headerAlign: "center",
      align: "center",
      filterable: false,
    },
    {
      field: "dismissed",
      headerName: "Dismissed",
      headerAlign: "center",
      align: "center",
      filterable: false,
    },
    {
      field: "incomplete",
      headerName: "Incomplete",
      headerAlign: "center",
      align: "center",
      filterable: false,
    },
    // {
    //   field: "manual_launch",
    //   headerName: "Manual Launch",
    //   headerAlign: "center",
    //   align: "center",
    //   width: 150,
    //   filterable: false,
    // },
    // {
    //   field: "automatic_launch",
    //   headerName: "Automatic Launch",
    //   headerAlign: "center",
    //   align: "center",
    //   width: 150,
    //   filterable: false,
    // },
    {
      field: "repeat_views",
      headerName: "Repeat Views",
      headerAlign: "center",
      align: "center",
      width: 150,
      filterable: false,
    },
  ];

  const rows = filterDataSet.map((d) => {
    return {
      ...d,
      id: d.role_id,
    };
  });

  const filtersLabel = [
    {
      field: "email",
      type: "String",
      label: "Email",
    },
    {
      field: "unique_id",
      type: "String",
      label: "unique_id",
    },
    // {
    //   field: "role_id",
    //   type: "String",
    //   label: "Role ID",
    // },
    {
      field: "avg_spent",
      type: "number",
      label: "Average Time Spent",
    },
    {
      field: "firstSeen",
      type: "Date",
      label: "First Seen",
    },
    {
      field: "seen",
      type: "number",
      label: "Seen",
    },
    {
      field: "completed",
      type: "number",
      label: "Completed",
    },
    {
      field: "incomplete",
      type: "number",
      label: "incomplete",
    },
    // {
    //   field: "manual_launch",
    //   type: "number",
    //   label: "Seen",
    // },
    // {
    //   field: "automatic_launch",
    //   type: "number",
    //   label: "Seen",
    // },
    {
      field: "repeat_views",
      type: "number",
      label: "Seen",
    },
  ];

  return (
    <>
      <div className="flex items-center">
        <ComponentFilterTable
          originalData={dataSet}
          setFilteredData={setFilteredDataSet}
          filters={filtersLabel}
        />
        <div className="ml-auto">
          <FormControl>
            <FormControlLabel
              label="Show Anonymous ID"
              control={
                <Switch
                  checked={showAnonymous}
                  onChange={handleAnonymousToggle}
                ></Switch>
              }
            />
          </FormControl>
        </div>
      </div>
      <div style={{ display: "flex", height: 700, width: "100%" }}>
        <div style={{ flexGrow: 1 }}>
          <DataGrid
            columns={columns}
            rows={rows}
            density="comfortable"
            rowHeight={70}
            columnVisibilityModel={{
              role_id: showAnonymous,
            }}
          />
        </div>
      </div>
    </>
  );
};

export default ComponentUserInteractionTable;
