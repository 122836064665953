import { Cancel } from "@mui/icons-material";
import { TextField } from "@mui/material";
import React, { useState } from "react";
import { Tags } from "usertip-component-library";
import { validateEmailFormat } from "../../../utility/functions/validation";

interface Props {
  /** This is for the title of the component */
  title: string;
  /** This is for the descritpion of the component */
  description: string;
  /** This is for the list of emails that are already in the list */
  usersEmails: any[];
  /** This is for update new email for the list */
  setUsersEmails: React.Dispatch<React.SetStateAction<any[]>>;
  /** This props is for checking if there's same email in the different email list */
  allEmail?: any[];
}

const TagsTextField = ({
  title,
  description,
  usersEmails,
  setUsersEmails,
  allEmail,
}: Props) => {
  const emailDefaultInfoText = "*Hit <Enter> to add email to list";
  const emailDefaultInvalidFormatText = "*Invalid email format";

  const [email, setEmail] = useState<string>("");
  const [emailError, setEmailError] = useState<{
    option: boolean;
    message: string;
  }>({ option: false, message: "" });

  /** This function is to reset email error */
  const resetEmailErrorHandler = () => {
    setEmailError({ option: false, message: "" });
  };

  /** This function is to input the textfield */
  const emailChangeHandler = (e: any) => {
    const value = e.target.value;

    setEmail(value.toLowerCase());
    if (value.trim()) {
      resetEmailErrorHandler();
    }
  };

  /** This function is to set email error */
  const setEmailErrorHandler = (message: string) => {
    setEmailError({ option: true, message });
  };

  /** This function is to validate email field */
  const validateEmailField = () => {
    const isEmpty = !email.trim();
    const isValidated = validateEmailFormat(email.trim());
    if (isEmpty) {
      setEmailErrorHandler(emailDefaultInfoText);
    } else if (!isValidated) {
      setEmailErrorHandler(emailDefaultInvalidFormatText);
    } else {
      resetEmailErrorHandler();
    }

    return !isEmpty && isValidated;
  };

  /** This function is to check if email already exists in the current arraylist */
  const verifyIfEmailExistsInUsersEmails = () => {
    for (let i = 0; i < usersEmails.length; i++) {
      // If email already exists in the current arraylist
      if (usersEmails[i].userEmail.includes(email)) {
        return true;
      }
    }
    return false;
  };

  /** This function is to check if email already exists in the all email arraylist */
  const verifyIfEmailExistInAllEmail = () => {
    if (allEmail) {
      for (let i = 0; i < allEmail!.length; i++) {
        // If email already exists in the current arraylist
        if (allEmail![i].userEmail.includes(email)) {
          return true;
        }
      }
    }
    return false;
  };

  /** For reseting the textfield input */
  const resetEmailHandler = () => {
    setEmail("");
  };

  /** Handler for adding new email to the arraylist */
  const handleAddNewEmail = (e: any) => {
    // Only process add email from textfield when "Enter" key is pressed
    if (e.key === "Enter") {
      // Execute check to valid email before adding to array list
      if (validateEmailField()) {
        // If email already exists on list, inform to try new email
        if (verifyIfEmailExistsInUsersEmails()) {
          setEmailErrorHandler(
            "*Email already added to list. Please try again."
          );

          return;
        } else if (verifyIfEmailExistInAllEmail()) {
          setEmailErrorHandler(
            "*Email already exists in another list. Please use a different email address."
          );

          return;
        } else {
          // Add new email to array list usersEmails if verified email is of valid format
          const data = {
            key: usersEmails.length,
            userEmail: email,
          };

          setUsersEmails([...usersEmails, data]);

          // Clears textfield after successful add
          resetEmailHandler();
        }
      }
    }
  };

  /** Handle to delete email on the list */
  const handleDeleteSelectedEmail = (emailSelectedToDelete: any) => () => {
    // Remove selected email to delete
    const newUsersEmailsAfterDeletion = usersEmails.filter(
      (emailToDelete) => emailToDelete.key !== emailSelectedToDelete.key
    );

    setUsersEmails(newUsersEmailsAfterDeletion);
  };

  return (
    <div className="flex flex-col gap-4">
      <div>
        <h2 className="font-bold text-lg">{title}</h2>
        <p className="text-sm text-default-primary">{description}</p>
      </div>

      {usersEmails.length > 0 && (
        <ul className="flex gap-2">
          {usersEmails.map((inputEmail) => {
            return (
              <li key={inputEmail.key} className="modalChip">
                <Tags
                  size="large"
                  variant="filled"
                  text={inputEmail.userEmail}
                  icon={
                    <div
                      onClick={handleDeleteSelectedEmail(inputEmail)}
                      className="cursor-pointer"
                    >
                      <Cancel fontSize="small" />
                    </div>
                  }
                />
              </li>
            );
          })}
        </ul>
      )}

      <div className="relative">
        <TextField
          label={"Enter the user's email"}
          value={email}
          onChange={emailChangeHandler}
          onKeyDown={handleAddNewEmail}
          error={emailError.option}
          helperText={
            emailError.option ? emailError.message : emailDefaultInfoText
          }
          variant={"standard"}
          fullWidth
        />
        {usersEmails.length > 0 && (
          <div className="text-xs absolute right-0 bottom-0 text-gray-600 font-medium">
            Total Emails: {usersEmails.length}
          </div>
        )}
      </div>
    </div>
  );
};

export default TagsTextField;
