import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import {
  AdditionalStatData,
  StepProcDataSet,
} from "../../../interfaces/interfaceAnalytic";
import { stackedBarLineStepData } from "../../_chartGenerator/stackedBarLineStepData";
import { functionCombineStep } from "../../_functions/FunctionCombineStep";
import ComponentToggleLegend from "../SeenDissmissed/ComponentToggleLegend";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import ComponentToggleLegendStep from "./ComponentToggleLegendStep";
import ComponentDashboard from "./MiniDetailedDashboard/ComponentDashboard";
import { functionAdditionalStats } from "../../_functions/FunctionAdditionalStats";

export interface PublishedVersionItem {
  published_walkthrough_id: string;
  published_version: number;
  components: string[];
}

const ComponentOverAllStep = ({
  selectionPublishedWalkthroughId,
  selectionStartDate,
  selectionEndDate,
}: {
  selectionPublishedWalkthroughId: string;
  selectionStartDate: Date;
  selectionEndDate: Date;
}) => {
  const [procDataSet, setProcDataSet] = useState<StepProcDataSet[]>([]);
  const [selectedLegends, setSelectedLegends] = useState<string[]>([
    "completed",
    "dismissed",
    "incomplete",
    "launched",
  ]);
  const [dashbooardData, setDashboardData] = useState<string | null>(null);
  const [additionalStatData, setAdditionalDataSet] =
    useState<AdditionalStatData | null>(null);
  const divRef = useRef<HTMLDivElement>(null);
  const walkthroughSessionActivityData = useSelector(
    (state: RootState) => state.analytics.walkthroughSessionActivities
  );

  // set dashboard data to null when changing date range/ published version / viewby
  useEffect(() => {
    setDashboardData(null);
    setAdditionalDataSet(null);
  }, [selectionEndDate, selectionEndDate, selectionPublishedWalkthroughId]);

  // Sorted list by position of the steps in the published walkthrough
  const stepList: PublishedVersionItem[] = useSelector((state: RootState) => {
    if (selectionPublishedWalkthroughId) {
      //@ts-ignore
      const versions = state.walkthrough.activeWalkthrough.versions; // get the list of versions
      const publishedVersionSelected = versions.filter(
        //@ts-ignore
        (x) => x.published_walkthrough_id === selectionPublishedWalkthroughId
      ); // retrieve the version that matches the selected published_walkthrough_id
      return publishedVersionSelected.length > 0
        ? publishedVersionSelected
        : [];
    } else {
      return [];
    }
    // let list = versions.length > 0 ?  : []
  });

  useEffect(() => {
    walkthroughSessionActivityData &&
      stepList.length > 0 &&
      setProcDataSet([...functionCombineStep(walkthroughSessionActivityData)]);
  }, [walkthroughSessionActivityData]);

  const handleClickChart = (selectedBar: string) => {
    setDashboardData(selectedBar);
    const getIndex = +selectedBar.slice(-1) - 1;
    if (walkthroughSessionActivityData && stepList.length > 0) {
      const additionalStats = functionAdditionalStats(
        walkthroughSessionActivityData,
        getIndex
      );
      //@ts-ignore
      setAdditionalDataSet(additionalStats);

      if (additionalStats === null) {
        setAdditionalDataSet(null);
      }
    }
  };

  useEffect(() => {
    if (procDataSet) {
      const chart = stackedBarLineStepData({
        y1Label: "Completed",
        y2Label: "Dismissed",
        y3Label: "Incomplete",
        yLine: "Launched",

        xValue: procDataSet.map((data) => `Step ${data.stepName}`),
        y1Value: procDataSet.map((data) => data.completed),
        y2Value: procDataSet.map((data) => data.dismissed),
        y3Value: procDataSet.map((data) => data.incomplete),
        yLineValue: procDataSet.map((data) => data.seen),

        xScaleRange: stepList[0].components.map(
          (data, index) => `Step ${index + 1}`
        ),
      });
      const selectChart = d3.select(".step_chart");

      //keep the chart to initial value
      if (chart) {
        // @ts-ignore
        chart.update({ activeLegends: selectedLegends, handleClickChart });
        divRef.current?.appendChild(chart);
      }

      return () => {
        selectChart &&
          Object.keys(selectChart).length !== 0 &&
          selectChart.selectAll("*").remove();
      };
    }
  }, [procDataSet, selectedLegends]);

  return (
    <>
      <div className="flex flex-col my-3 p-3 items-center">
        <div className="flex flex-col">
          <div className="step_chart stacked_chart" ref={divRef}></div>
        </div>
        <ComponentToggleLegendStep
          setSelectedLegends={setSelectedLegends}
          selectedLegends={selectedLegends}
        />
        {/* Mini dashboard goes here */}
        {dashbooardData !== null && additionalStatData !== null ? (
          <ComponentDashboard
            dashboardData={dashbooardData!}
            stepDataSet={procDataSet}
            additionalData={additionalStatData}
          />
        ) : (
          additionalStatData === null &&
          dashbooardData !== null && (
            <h3 className="pageSettingsHeader font-bold mt-5">
              No Step Activity Found on step [{dashbooardData}]
            </h3>
          )
        )}
      </div>
    </>
  );
};

export default ComponentOverAllStep;
