import React from "react";
import { InputNumber } from "usertip-component-library";

interface Props {
  /** Prop for set the label of the input */
  label: string;
  /** Prop for set the value of the input */
  value: number;
  /** Prop for handling the input */
  settingHandler: (value: number) => void;
  /** Prop for showing button for quick change value */
  rightButton?: boolean;
  /** Prop for set the min of number input */
  min?: number;
}

const SpacingSetting = ({
  label,
  value,
  settingHandler,
  rightButton = false,
  min = 0,
}: Props) => {
  return (
    <>
      <h1 className="text-sm">{label}:</h1>
      <div className="flex-1 flex gap-1">
        <div className="flex-1">
          <InputNumber
            value={value}
            adornment="px"
            onChange={settingHandler}
            width="full"
            minValue={min}
            maxValue={20}
            variant="outlined"
          />
        </div>
        {rightButton && (
          <div className="flex">
            <div
              className="w-9 h-9 border-default-primary border-[1px] rounded-l-md text-default-primary flex items-center justify-center cursor-pointer hover:bg-default-primary-lightest"
              onClick={() => settingHandler(6)}
            >
              6
            </div>
            <div
              className="w-9 h-9 border-default-primary border-y-[1px] text-default-primary flex items-center justify-center cursor-pointer hover:bg-default-primary-lightest"
              onClick={() => settingHandler(12)}
            >
              12
            </div>
            <div
              className="w-9 h-9 border-default-primary border-[1px] rounded-r-md text-default-primary flex items-center justify-center cursor-pointer hover:bg-default-primary-lightest"
              onClick={() => settingHandler(18)}
            >
              18
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default SpacingSetting;
