import { CircularProgress } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { getImageListScroll } from "../../../store/reducers/reducerMedia";
import ImageCardContainer from "../../03-organisms/04-ImageCardContainer/ImageCardContainer";
import { Spinner } from "usertip-component-library";

interface Props {
  sortBy: "dateCreated" | "name";
  sort: number;
  name: string;
  imagePerCol: number;
  handleSelectImageFromLibrary: (mediaId: string, mediaUrl: string) => void;
}

const ScrollSelectImageList = ({
  sortBy,
  sort,
  name,
  imagePerCol,
  handleSelectImageFromLibrary,
}: Props) => {
  const IMAGE_LIST_LIMIT = imagePerCol * 2;
  const gridStyle = {
    gridTemplateColumns: `repeat(${imagePerCol}, minmax(0, 1fr))`,
  };

  const loader = useRef<null | HTMLDivElement>(null);
  const [items, setItems] = useState(0);
  const [observer, setObserver] = useState<IntersectionObserver>();
  const imageListScroll = useSelector(
    (state: RootState) => state.media.imageListScroll
  );
  const cognitoId = useSelector(
    (state: RootState) => state.user.userData.cognito_id
  );
  const imageListScrollLoading = useSelector(
    (state: RootState) => state.media.imageListScrollLoading
  );

  const isObserverOn = useSelector((state: RootState) => state.media.observer);

  const dispatch = useDispatch();

  const handleObserver = useCallback((entries) => {
    const target = entries[0];
    if (target.isIntersecting) {
      /**load cards everytime target.isInteracting true and loading is false */
      setItems((prev) => prev + imagePerCol * 2);
    }
  }, []);

  useEffect(() => {
    if (!isObserverOn) {
      observer?.disconnect();
    }
  }, [isObserverOn]);

  useEffect(() => {
    const imageData = {
      items: items,
      cognitoId: cognitoId,
      sortBy: sortBy,
      sort: sort,
      name: name,
      limit: IMAGE_LIST_LIMIT,
    };

    if (items > 0) {
      dispatch(getImageListScroll(imageData));
    }
  }, [items, sortBy, sort, name]);

  useEffect(() => {
    if (isObserverOn) {
      const option = {
        root: null,
        rootMargin: "20px",
        threshold: 0,
      };
      const observer = new IntersectionObserver(handleObserver, option);
      setObserver(observer);
      if (loader.current) observer.observe(loader.current);
    }
  }, [handleObserver]);

  return (
    <>
      {imageListScroll.length > 0 && (
        <div
          className="w-full grid mt-4 auto-rows-auto gap-4 items-start"
          style={gridStyle}
        >
          {imageListScroll.map((val) => {
            return (
              <div
                key={val.media_id}
                className="bg-default-neutral-10 w-52 flex flex-col justify-center items-center mx-auto rounded-2xl overflow-hidden drop-shadow-[0px_4px_4px_rgba(10,0,204,0.1)] hover:drop-shadow-[0_4px_4px_rgba(118,87,241,0.5)] cursor-pointer"
                onClick={() => {
                  handleSelectImageFromLibrary(val.img_url, val.img_name);
                }}
              >
                <div className="w-52 h-36">
                  <img
                    src={val.img_url}
                    alt={val.img_name}
                    className="w-full h-full object-contain bg-default-neutral-20"
                  />
                </div>
                <p className="text-center text-sm p-4 font-semibold">
                  {val.img_name}
                </p>
              </div>
            );
          })}
        </div>
      )}
      {imageListScrollLoading && (
        <div className="flex justify-center mt-4">
          {/* <CircularProgress color="primary" /> */}
          <div className="ml-[-200px]">
            <Spinner size="l" />
          </div>
        </div>
      )}
      <div ref={loader} />
    </>
  );
};

export default ScrollSelectImageList;
