import React from "react";
import ContainerBB from "./ContainerBB";
import { SlideoutThemeType } from "../../../interfaces/interfaceTheme";

interface Props {
  style: SlideoutThemeType;
}

const SlideoutBB = ({ style }: Props) => {
  const backgroundColor =
    style != undefined && style != null ? style.backgroundColor : null;
  const padding = style != undefined && style != null ? style.padding : null;
  const width = style != undefined && style != null ? style.width : null;
  const height = style != undefined && style != null ? style.height : null;
  const borderRadius =
    style != undefined && style != null ? style.borderRadius : null;
  const animation =
    style != undefined && style != null
      ? style.animation
      : "ut-slideout-bottom";
  //spotlight positioning: flex-start center flex-end
  const position = style!.position;
  // const fade = style != undefined && style != null ? style.fade : null;
  // const margin = style != undefined && style != null ? style.margin : null;

  const mainWidth =
    //@ts-ignore
    width!.unit === "auto" || width === null || width === ""
      ? "auto"
      : `${width?.value}${width?.unit}`;
  /**old data have value like height === "" */
  const mainHeight =
    //@ts-ignore
    height!.unit === "auto" || height === null || height === ""
      ? "auto"
      : `${height?.value}${height?.unit}`;
  const mainPadding = padding !== null ? padding + "px" : "20px";
  const mainMargin = "12px";
  const mainBorderRadius =
    borderRadius !== null && borderRadius !== undefined
      ? borderRadius + "px"
      : "6px";

  return (
    <div
      style={{
        position: "relative",
        zIndex: 2,
        display: "flex",
        width: "100%",
        height: "100%",
        justifyContent: position ? position : "center",
        alignItems: "center",
        marginRight: "0px",
        // display: 'flex',
      }}
    >
      <div
        style={{
          backgroundColor: backgroundColor ? backgroundColor : "#FFFFFF",
          margin: mainMargin,
          padding: mainPadding,
          width: mainWidth,
          height: mainHeight,
          border: "1px solid #888",
          display: "flex",
          // flexDirection: 'column',
          // alignItems: 'flex-start',
          // justifyContent: 'space-between',
          borderRadius: mainBorderRadius,
        }}
        className={animation}
      >
        <ContainerBB buildingBlockStyle={style.buildingBlockStyle} />
      </div>
    </div>
  );
};

export default SlideoutBB;
