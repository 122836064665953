import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import React from "react";
import Title from "../../01-atoms/01-typography/Title";
import CardHeader from "../01-cards/CardHeader";

interface Props {
  className?: string | undefined;
  itemName: string;
  ButtonValue: string;
}

const ChangePaymentListItem = (props: Props) => {
  return (
    <ToggleButton value={props.ButtonValue}>
      <Title messageText={props.itemName} header="h6"></Title>
    </ToggleButton>
  );
};

export default ChangePaymentListItem;
