import React, { useEffect, useState, useMemo } from "react";

import ComponentEditListGenerator from "./ComponentEditListGenerator";
import ComponentEditListBtnArray from "./ComponentEditListBtnArray";
import ComponentAlertSnackbar from "./ComponentAlertSnackbar";

// import { retrieveUsersCognitoIDFromList } from "./_functions/FunctionTeam";
import {
  filterDuplicatedMembersByRoleID,
  not,
  intersection,
  union,
  numberOfChecked,
  checkMembersToAdd,
  checkMembersToRemove,
} from "./_functions/FunctionEditList";
import { useDispatch } from "react-redux";
import { EmptyModal, Button } from "usertip-component-library";

const ComponentEditTeams = ({
  displayUserRoleFull,
  selectedTeamList,
  teamDataSet,

  onAddUsersToBuilderTeam,
  onRemoveUsersFromBuilderTeam,
  onEditMembersInBuilderTeam,

  onAddUsersToViewerTeam,
  onRemoveUsersFromViewerTeam,
  onEditMembersInViewerTeam,

  showModalEdit,
  setShowModalEdit,

  editMode,
}) => {
  const dispatch = useDispatch();
  const [showAlertSnackbar, setShowAlertSnackbar] = useState(false);
  const [selectedTeamID, setSelectedTeamID] = useState("");

  const selectedTeam = selectedTeamList[0];

  // const [selectedTeamID, setSelectedTeamID] = useState("")

  const membersListName = `${displayUserRoleFull}s`;
  const membersTeamListName = `${selectedTeam.name}`;

  // Setting List-Store Variables
  const [checked, setChecked] = useState([]);
  const [memberTeamDataSet, setMemberTeamDataSet] = useState([]);

  // After identifying the respective list, Populate them into the Team List.
  // Right-Hand-Side List Data
  const [memberTeamList, setMemberTeamList] = useState([]);

  // Make a copy of "Builders / Viewers", then remove them if they are duplicated Members already on MembersTeam List
  const membersDataSet = useMemo(
    () => filterDuplicatedMembersByRoleID(teamDataSet, memberTeamDataSet),
    [memberTeamDataSet, teamDataSet]
  );

  // "teamDataSet" will store Existing "Builders / Viewers" List
  // Left-Hand-Side List Data
  const [membersList, setMembersList] = useState([]);

  // Tracks changes in "membersDataSet". Update when new change is available.
  useEffect(() => {
    // Only update "membersList" when "membersDataSet" is being filtered & has new data changes.
    if (membersDataSet) {
      setMembersList(membersDataSet);
    }
  }, [membersDataSet]);

  // Tracks changes of multiple variables. Update when new change is available.
  useEffect(() => {
    // Pull out the SelectedTeamID to check and verify
    let checkSelectedTeamID = "";
    if (displayUserRoleFull === "Builder") {
      checkSelectedTeamID = selectedTeam.builder_team_id;
    } else if (displayUserRoleFull === "Viewer") {
      checkSelectedTeamID = selectedTeam.viewer_team_id;
    }

    // Only update state data when there is change of "selectedTeamID" data
    if (checkSelectedTeamID !== selectedTeamID) {
      // "selectedTeam.builders / selectedTeam.viwers" will store Existing "Builder Team's Builders / Viewer Team's Viewers" List
      if (displayUserRoleFull === "Builder") {
        setMemberTeamDataSet([...selectedTeam.builders]);
        setMemberTeamList([...selectedTeam.builders]);
        // Assign the TeamID for future-checks when a new Team is selected
        setSelectedTeamID(selectedTeam.builder_team_id);
      } else if (displayUserRoleFull === "Viewer") {
        setMemberTeamDataSet([...selectedTeam.viewers]);
        setMemberTeamList([...selectedTeam.viewers]);
        // Assign the TeamID for future-checks when a new Team is selected
        setSelectedTeamID(selectedTeam.viewer_team_id);
      }
    }
  }, [
    displayUserRoleFull,
    membersDataSet,
    selectedTeam,
    selectedTeamID,
    selectedTeamList,
  ]);

  const handleEditTeamsFormSubmission = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (editMode === "single") {
      // Due to API structure, Compare differences between existing & new, then separate the data for processes seaparately for ADD + REMOVE
      //
      // When New Listings are confirmed:
      // 1. Update "membersList" to existing "Builders / Viewers" List
      // 2. Update "memberTeamList" to existing "Builder Team / Viewer Team" List
      const membersToAdd = checkMembersToAdd(memberTeamList, memberTeamDataSet);
      const membersToRemove = checkMembersToRemove(membersList, membersDataSet);

      // Checks if Both Add + Remove Lists have Changed
      if (membersToAdd.length === 0 && membersToRemove.length === 0) {
        setShowAlertSnackbar(true);
      } else {
        // Abstract the "cognito_id" of all members of the respective Add / Remove lists
        // const addListCognitoID = retrieveUsersCognitoIDFromList(membersToAdd);
        // const removeListCognitoID = retrieveUsersCognitoIDFromList(membersToRemove);

        // Push the following updates to the Server API
        let teamID = "";
        if (displayUserRoleFull === "Builder") {
          teamID = selectedTeam.builder_team_id;

          // onEditMembersInBuilderTeam
          const memberData = {
            builder_team_id: teamID,
            users: memberTeamList,
          };
          dispatch(onEditMembersInBuilderTeam(memberData));
          handleHideModal();
          // Push Add / Remove Users where applicable to respective Team List
          // if (addListCognitoID.length > 0) {
          //   onAddUsersToBuilderTeam(teamID, addListCognitoID);
          // }
          // if (removeListCognitoID.length > 0) {
          //   onRemoveUsersFromBuilderTeam(teamID, removeListCognitoID);
          // }
        } else if (displayUserRoleFull === "Viewer") {
          teamID = selectedTeam.viewer_team_id;
          const teamData = {
            viewer_team_id: teamID,
            users: memberTeamList,
          };
          console.log(teamData, "team data");
          dispatch(onEditMembersInViewerTeam(teamData));
          handleHideModal();
          // // Push Add / Remove Users where applicable to respective Team List
          // if (addListCognitoID.length > 0) {
          //   onAddUsersToViewerTeam(teamID, addListCognitoID);
          // }
          // if (removeListCognitoID.length > 0) {
          //   onRemoveUsersFromViewerTeam(teamID, removeListCognitoID);
          // }
        }
      }
    }
  };

  // Modal close handler
  const handleHideModal = () => {
    // Need to RESET all the original fields if "CONFIRM" Button Not Clicked
    setMemberTeamList(memberTeamDataSet);
    setMembersList(membersDataSet);
    // RESET Snackbar as well
    setShowAlertSnackbar(false);

    setShowModalEdit(false);
  };

  return (
    <EmptyModal
      isOpen={showModalEdit}
      onClose={handleHideModal}
      customClass="ut-modal-lg-percentage"
    >
      <div className="modalHeader">
        {editMode === "single" ? `Edit ${membersTeamListName}` : ""}
      </div>

      {editMode === "single" && (
        <div className="modalSubHeader">
          Move <i>{displayUserRoleFull}(s)</i> for{" "}
          <b>{`${membersTeamListName}`}</b>.
        </div>
      )}

      <form
        noValidate
        autoComplete="off"
        onSubmit={handleEditTeamsFormSubmission}
        className="modalForm"
      >
        <div className="inline-flex mb-8">
          <ComponentEditListGenerator
            numberOfChecked={numberOfChecked}
            checked={checked}
            setChecked={setChecked}
            not={not}
            union={union}
            title={membersListName}
            members={membersList}
            displayDetails={"teamMembers"}
          />

          <ComponentEditListBtnArray
            checked={checked}
            setChecked={setChecked}
            membersList={membersList}
            memberTeamList={memberTeamList}
            setMembersList={setMembersList}
            setMemberTeamList={setMemberTeamList}
            not={not}
            intersection={intersection}
          />

          <ComponentEditListGenerator
            numberOfChecked={numberOfChecked}
            checked={checked}
            setChecked={setChecked}
            not={not}
            union={union}
            title={`${membersTeamListName}`}
            members={memberTeamList}
            displayDetails={"teamMembers"}
          />
        </div>

        <div className="flex justify-end gap-2">
          <Button
            variant="primary"
            color="primary"
            size="medium"
            text="Cancel"
            onClick={handleHideModal}
          />

          <Button
            variant="primary"
            color="primary"
            size="medium"
            text="Confirm"
          />
        </div>
      </form>

      <ComponentAlertSnackbar
        showAlertSnackbar={showAlertSnackbar}
        setShowAlertSnackbar={setShowAlertSnackbar}
        variant={"error"}
        title="Please make changes to Team List to Confirm."
      />
    </EmptyModal>
  );
};

export default ComponentEditTeams;
