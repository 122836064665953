import attachHTTPRequestHeaders from "../../utility/functions/attachHTTPRequestHeaders";
import { api, baseUrl } from "./_axios";

/*
    ===================================================================    
    API 2.0: API-Calls To Server
    ===================================================================
    1. api_p1 is default for all API 2.0 calls
    2. api_p2 is now only for /signIn route  
    ===================================================================    
    CALLS-AUTH API
    ===================================================================
*/

// USING PATH2
// POST: Sign in to a user's account directly to API call
export const callPOSTSignInUserAccount = async (email, password) => {
  const data = {
    email: email.toLowerCase(),
    password: password,
  };

  const config = attachHTTPRequestHeaders(false);

  return api
    .post(`/signIn`, data, config)
    .then((res) => res)
    .catch((err) => {
      // Handles server reply errors that are 500x range
      // if (err.response.status === 500) {
      //     // If New User account is not verified, dispatch to REGISTRAION_NOT_VERIFIED, and throw to /login/notverified page
      //     if (err.response.data.code === "UserNotConfirmedException") {
      //         console.log("500 Error: UserNotConfirmedException")
      //         // Force-execute a dispatch call to notify REGISTRATION_NOT_VERIFIED
      //         store.dispatch(actions.authRegisterationNotVerified(), []);
      //     }
      // }
      return Promise.reject(err);
    });
};

// POST: Register a new user's account directly to API call
export const callPOSTRegisterUserAccount = async (
  email,
  password,
  name,
  orgName
  // countryCode,
  // phoneNumber,
) => {
  const data = {
    email: email.toLowerCase(),
    password: password,
    name: name,
    org_name: orgName,
  };

  // Since phone number is optional, if they are not null or the length > 0, we add phone number as a field after the checks
  // if (countryCode && phoneNumber) {
  //   if (countryCode.length > 0 && phoneNumber.length > 0) {
  //     data.phoneno = {
  //       intl: countryCode,
  //       value: phoneNumber,
  //     };
  //   }
  // }

  const config = attachHTTPRequestHeaders(true);
  return api
    .post(`/user/add`, data, config)
    .then((res) => res)
    .catch((err) => {
      // Handles server reply errors that are 500x range
      // if (err.response.status === 400) {
      //     // If New User account is not verified, dispatch to REGISTRAION_NOT_VERIFIED, and throw to /login/notverified page
      //     if (err.response.data.msg === "An account with the given email already exists.") {
      //     console.log("400 Error: An account with the given email already exists.")
      //     // Force-execute a dispatch call to notify REGISTRATION_NOT_VERIFIED
      //     store.dispatch(actions.authRegisterationUserAlreadyExists(), []);
      //     }
      // }
      return Promise.reject(err);
    });
};

// POST: Register a new user's account and do payment
export const callPOSTRegisterSubscribeUserAccount = async (
  email,
  password,
  name,
  orgName,
  planTier
  // countryCode,
  // phoneNumber,
) => {
  const data = {
    email: email,
    password: password,
    name: name,
    org_name: orgName,
  };

  // Since phone number is optional, if they are not null or the length > 0, we add phone number as a field after the checks
  // if (countryCode && phoneNumber) {
  //   if (countryCode.length > 0 && phoneNumber.length > 0) {
  //     data.phoneno = {
  //       intl: countryCode,
  //       value: phoneNumber,
  //     };
  //   }
  // }

  const config = attachHTTPRequestHeaders(false);
  return api
    .post(`user/register`, data, config)
    .then((res) => res)
    .catch((err) => {
      return Promise.reject(err);
    });
};

/** POST: Register User through By Email Invitation*/
export const callPOSTRegisterByInviteUserToOrganization = async (
  email,
  password,
  name,
  org_id,
  role_type,
  mfa
  // countryCode,
  // phoneNumber,
) => {
  const data = {
    email: email.toLowerCase(),
    password: password,
    org_id: org_id,
    role_type: role_type,
    mfa: mfa,
  };

  // Since name is optional, if they are not null or the length > 0, we add name as a field after the checks
  if (name) {
    if (name.length > 0) {
      data.name = name;
    }
  }
  // Since phone number is optional, if they are not null or the length > 0, we add phone number as a field after the checks
  // if (countryCode && phoneNumber) {
  //   if (countryCode.length > 0 && phoneNumber.length > 0) {
  //     data.phoneno = {
  //       intl: countryCode,
  //       value: phoneNumber,
  //     };
  //   }
  // }
  const config = attachHTTPRequestHeaders(false);
  return api
    .post(`/invitation/signup `, data, config)
    .then((res) => res)
    .catch((err) => {
      return Promise.reject(err);
    });
};

// USING PATH2
// DELETE: Delete an email invitation by identifier
export const callDELETEUserInvitationByIdentifier = async (identifier) => {
  const data = {
    identifier: identifier,
  };
  const config = attachHTTPRequestHeaders(true);
  return api
    .delete(`/delete-invitation`, data, config)
    .then((res) => res)
    .catch((err) => {
      return Promise.reject(err);
    });
};

/**api call for change password */
export const callPOSTChangePassword = async (
  previous_password,
  new_password
) => {
  const access_token = localStorage.getItem("ut-accsesToken");
  const data = {
    access_token: access_token,
    previous_password: previous_password,
    new_password: new_password,
  };
  const config = attachHTTPRequestHeaders(true);

  return api
    .post(`/changepassword`, data, config)
    .then((res) => res)
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const callPOSTForgotPassword = async (email) => {
  const config = attachHTTPRequestHeaders(false);

  const data = {
    email: email.toLowerCase(),
  };

  return api
    .post(`/forgotpassword`, data, config)
    .then((res) => res)
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const callPOSTForgotPasswordConfirm = async (
  email,
  password,
  confirmation_code
) => {
  const config = attachHTTPRequestHeaders(false);

  const data = {
    email: email.toLowerCase(),
    password: password,
    confirmation_code: confirmation_code,
  };

  return api
    .post(`/forgotpassword/confirm`, data, config)
    .then((res) => res)
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const callPostGoogleSignIn = async (credential) => {
  const config = attachHTTPRequestHeaders(false);
  return api
    .post(`/googleSignIn`, { token: credential }, config)
    .then((res) => res)
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const callPostlinkGoogleAccount = async (googleData) => {
  const config = attachHTTPRequestHeaders(false);
  const userData = {
    userData: {
      ...googleData,
    },
  };
  return api
    .post(`/createAndVerifyCognitoUser`, userData, config)
    .then((res) => res)
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const callPostRegisterGoogleAccount = async (googleData) => {
  const config = attachHTTPRequestHeaders(false);
  const data = {
    email: googleData.email,
    name: googleData.name,
    org_name: googleData.orgName,
    cognito_id: googleData.cognitoId,
  };

  return api
    .post(`/user/register/google`, data, config)
    .then((res) => res)
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const callPostGetGoogleToken = async (code) => {
  const config = attachHTTPRequestHeaders(true);

  return api
    .post(`/google/getTokens`, code, config)
    .then((res) => res)
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const callPostRefreshGoogleToken = async (token) => {
  const config = attachHTTPRequestHeaders(true);

  return api
    .post(`/google/refreshUser`, token, config)
    .then((res) => res)
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const callPostRefreshCognitoToken = async (token) => {
  const config = attachHTTPRequestHeaders(false);

  return api
    .post(`/refreshUser`, token, config)
    .then((res) => res)
    .catch((err) => {
      return Promise.reject(err);
    });
};
