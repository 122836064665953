import React, { useState, useEffect } from "react";

import { Button, InputField } from "usertip-component-library";
import { useDispatch } from "react-redux";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

const ComponentSettingsWalkthroughGeneralAction = ({
  walkthrough,
  updateSettings,
}) => {
  const [walkthroughName, setWalkthroughName] = useState("");
  const [walkthroughURL, setWalkthroughURL] = useState("");
  const dispatch = useDispatch();

  const [updateSettingsRequired, setUpdatedSettingsRequired] = useState(false);

  // Tracks "walkthrough" and updates Form Data when new data exists
  useEffect(() => {
    if (walkthrough) {
      if (walkthrough.name) {
        setWalkthroughName(walkthrough.name);
      }
      if (walkthrough.page_url) {
        setWalkthroughURL(walkthrough.page_url);
      }

      setUpdatedSettingsRequired(false);
    }
  }, [walkthrough]);

  // Walkthrough: Update Walkthrough Name field
  const handleUpdateWalkthroughName = (e) => {
    setWalkthroughName(e.target.value);

    if (
      e.target.value.length > 0 &&
      e.target.value.trim() !== walkthrough.name.trim()
    ) {
      setUpdatedSettingsRequired(true);
    } else {
      setUpdatedSettingsRequired(false);
    }
  };

  // Walkthrough: Update Walkthrough URL field
  const handleUpdateWalkthroughURL = (e) => {
    setWalkthroughURL(e.target.value);

    if (
      e.target.value.length > 0 &&
      e.target.value.trim() !== walkthrough.page_url.trim()
    ) {
      setUpdatedSettingsRequired(true);
    } else {
      setUpdatedSettingsRequired(false);
    }
  };

  // Walkthrough: Handles General Settings Submission Form
  const handleGeneralSettingsSubmissionForm = (e) => {
    e.preventDefault();

    if (updateSettingsRequired) {
      const trimmedWalkthroughName = walkthroughName.trim();
      const trimmedWalkthroughURL = walkthroughURL.trim();

      const originalTrimmedWalkthroughName = walkthrough.name.trim();
      const originalTrimmedWalkthroughUrl = walkthrough.page_url.trim();

      if (
        trimmedWalkthroughName !== originalTrimmedWalkthroughName ||
        trimmedWalkthroughURL !== originalTrimmedWalkthroughUrl
      ) {
        // Inject Update of Launch Settings
        const walkthroughData = {
          walkthroughID: walkthrough.walkthrough_id,
          name: trimmedWalkthroughName,
          page_url: trimmedWalkthroughURL,
        };
        dispatch(updateSettings(walkthroughData));
        setUpdatedSettingsRequired(false);
      } else {
        // If both new inputs are the same as originals, reset the need to update settings
        setUpdatedSettingsRequired(false);
      }
    }
  };

  const [collapse, setCollapse] = useState(true);

  return (
    <>
      {collapse ? (
        <div className="pageForm flex flex-row gap-2">
          <span
            className="-ml-8 cursor-pointer"
            onClick={() => setCollapse(!collapse)}
          >
            {collapse ? <AddCircleOutlineIcon /> : <RemoveCircleOutlineIcon />}
          </span>
          <div className="pageSettingsHeader">General Settings</div>
        </div>
      ) : (
        <div className="">
          <form
            className="pageForm"
            onSubmit={handleGeneralSettingsSubmissionForm}
          >
            <div className="flex flex-row gap-2">
              <span
                className="-ml-8 cursor-pointer"
                onClick={() => setCollapse(!collapse)}
              >
                {collapse ? (
                  <AddCircleOutlineIcon />
                ) : (
                  <RemoveCircleOutlineIcon />
                )}
              </span>
              <div className="pageSettingsHeader">General Settings</div>
            </div>

            <div className="pageSettingsSubHeader"></div>

            <div className="pageSettingSection flex items-center gap-2">
              <div className="flex-1">
                <div className="pageSettingsInputTextfield flex-1 ml-0 mr-2">
                  <InputField
                    variant="full"
                    label="Walkthrough Name"
                    placeholder={"MyWalkthrough"}
                    onChange={(e) => handleUpdateWalkthroughName(e)}
                    value={walkthroughName}
                    helperText=""
                  />
                </div>
              </div>
              <div className="flex-1">
                <div className="pageSettingsInputTextfield flex-1 ml-0 mr-2">
                  <InputField
                    variant="full"
                    label="Walkthrough URL"
                    placeholder={"https://www.example.com"}
                    value={walkthroughURL}
                    onChange={(e) => handleUpdateWalkthroughURL(e)}
                    helperText=""
                  />
                </div>
              </div>

              <div className="flex-grow" />

              <div className="flex-1">
                <div className="ml-auto w-[5.625rem]">
                  <Button
                    color="primary"
                    size="fluid"
                    variant="primary"
                    text="Save"
                    disabled={updateSettingsRequired === false ? true : false}
                    // onClick={handleGeneralSettingsSubmissionForm}
                  />
                </div>
              </div>
            </div>
          </form>

          <div className="pageDivider" />
        </div>
      )}
    </>
  );
};

export default ComponentSettingsWalkthroughGeneralAction;
