import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  callDELETEExistingBuilderTeam,
  callDELETEExistingViewerTeam,
  callGETRetrieveBuilderTeamByOrganization,
  callGETRetrieveViewerTeamByOrganization,
  callPOSTAddUsersToBuilderTeam,
  callPOSTAddUsersToViewerTeam,
  callPOSTCreateNewBuilderTeam,
  callPOSTCreateNewViewerTeam,
  callPOSTEditMembersBuilderTeam,
  callPOSTEditMembersViewerTeam,
  callPOSTRemoveUsersFromBuilderTeam,
  callPOSTRemoveUsersFromViewerTeam,
  callGETRetrieveSummaryOfViewerTeamByOrganization,
} from "../../axios/v2/callsTeam";
import { alertState } from "./reducerAlert";

// action
// Add new Builder Team
export const teamAddNewBuilderTeam = createAsyncThunk<
  any,
  { org_id: string; org_name: string }
>("team/addNewBuilderTeam", async (teamData, thunkApi) => {
  const { org_id, org_name } = teamData;
  try {
    const result = await callPOSTCreateNewBuilderTeam(org_id, org_name);
    if (result && result.data) {
      const res = {
        replyMsg: result.data.msg,
        replyPayload: result.data.payload,
      };
      return res;
    }
  } catch (err: any) {
    const errStatus = err.response.status; // Integer
    const errPayload = err.response.data;
    const errMsg = errPayload.msg;

    console.log(errStatus);
    console.log(errPayload);
    console.log(errMsg);
    const res = {
      alert: {
        type: "error",
        message: err,
        toString,
      },
    };
    return thunkApi.rejectWithValue(res);
  }
});

// Remove existing Builder Team
export const teamRemoveExistingBuilderTeam = createAsyncThunk<any, string>(
  "team/removeExistingBuilderTeam",
  async (builderTeam_id, thunkApi) => {
    try {
      const result = await callDELETEExistingBuilderTeam(builderTeam_id);
      if (result && result.data) {
        const res = {
          replyMsg: result.data.msg,
        };
        return res;
      }
    } catch (err: any) {
      const errStatus = err.response.status; // Integer
      const errPayload = err.response.data;
      const errMsg = errPayload.msg;

      console.log(errStatus);
      console.log(errPayload);
      console.log(errMsg);

      const res = {
        alert: {
          type: "error",
          message: err.toString(),
        },
      };

      return thunkApi.rejectWithValue(res);
    }
  }
);

// Add new Viewer Team
export const teamAddNewViewerTeam = createAsyncThunk<
  any,
  { org_id: string; org_name: string }
>("team/addNewViewerTeam", async (teamData, thunkApi) => {
  const { org_id, org_name } = teamData;
  try {
    const result = await callPOSTCreateNewViewerTeam(org_id, org_name);
    if (result && result.data) {
      const res = {
        replyMsg: result.data.msg,
        replyPayload: result.data.payload,
      };
      return res;
    }
  } catch (err: any) {
    const errStatus = err.response.status; // Integer
    const errPayload = err.response.data;
    const errMsg = errPayload.msg;

    console.log(errStatus);
    console.log(errPayload);
    console.log(errMsg);

    const res = {
      alert: {
        type: "error",
        message: err.toString(),
      },
    };

    return thunkApi.rejectWithValue(res);
  }
});

// Remove existing Viewer Team
export const teamRemoveExistingViewerTeam = createAsyncThunk<any, string>(
  "team/removeExistingViewerTea,",
  async (viewerTeam_id, thunkApi) => {
    try {
      const result = await callDELETEExistingViewerTeam(viewerTeam_id);
      if (result && result.data) {
        const res = {
          replyMsg: result.data.msg,
        };
        return res;
      }
    } catch (err: any) {
      const errStatus = err.response.status; // Integer
      const errPayload = err.response.data;
      const errMsg = errPayload.msg;

      console.log(errStatus);
      console.log(errPayload);
      console.log(errMsg);

      const res = {
        alert: {
          type: "error",
          message: err.toString(),
        },
      };

      return thunkApi.rejectWithValue(res);
    }
  }
);

// Get all builder teams by organization
export const teamGetBuilderTeamsByOrg = createAsyncThunk<any, string>(
  "team/getBuilderTeamsByOrg",
  async (org_id, thunkApi) => {
    try {
      const result = await callGETRetrieveBuilderTeamByOrganization(org_id);
      if (result && result.data) {
        const res = {
          teams: result.data.payload.teams,
        };
        return res;
      }
    } catch (err: any) {
      const errStatus = err.response.status; // Integer
      const errPayload = err.response.data;
      const errMsg = errPayload.msg;

      console.log(errStatus);
      console.log(errPayload);
      console.log(errMsg);

      const res = {
        alert: {
          type: "error",
          message: err.toString(),
        },
      };

      return thunkApi.rejectWithValue(res);
    }
  }
);

// Get new users to Builder Team
export const teamAddUsersToBuilderTeam = createAsyncThunk<
  any,
  { builderTeam_id: string; listOfUsers_id: string[] }
>("team/addUsersToBuilderTeam", async (groupData, thunkApi) => {
  const { builderTeam_id, listOfUsers_id } = groupData;
  try {
    const result = await callPOSTAddUsersToBuilderTeam(
      builderTeam_id,
      listOfUsers_id
    );
    if (result && result.data) {
      const res = {
        replyMsg: result.data.msg,
        replyPayload: result.data.payload,
      };
      return res;
    }
  } catch (err: any) {
    const errStatus = err.response.status; // Integer
    const errPayload = err.response.data;
    const errMsg = errPayload.msg;

    console.log(errStatus);
    console.log(errPayload);
    console.log(errMsg);

    const res = {
      alert: {
        type: "error",
        message: err.toString(),
      },
    };

    return thunkApi.rejectWithValue(res);
  }
});

// Remove existing users from Builder Team
export const teamRemoveUsersFromBuilderTeam = createAsyncThunk<
  any,
  { builderTeam_id: string; listOfUsers_id: string[] }
>("team/removeUsersFromBuilderTeam", async (teamData, thunkApi) => {
  const { builderTeam_id, listOfUsers_id } = teamData;
  try {
    const result = await callPOSTRemoveUsersFromBuilderTeam(
      builderTeam_id,
      listOfUsers_id
    );
    if (result && result.data) {
      const res = {
        replyMsg: result.data.msg,
        replyPayload: result.data.payload,
      };
      return res;
    }
  } catch (err: any) {
    const errStatus = err.response.status; // Integer
    const errPayload = err.response.data;
    const errMsg = errPayload.msg;

    console.log(errStatus);
    console.log(errPayload);
    console.log(errMsg);

    const res = {
      alert: {
        type: "error",
        message: err.toString(),
      },
    };

    return thunkApi.rejectWithValue(res);
  }
});

// edit members inside a builder team
export const teamEditMembersBuilderTeam = createAsyncThunk<
  any,
  { builder_team_id: string; users: any[] }
>("team/editMembersBuilderTeam", async (teamData, thunkApi) => {
  const { builder_team_id, users } = teamData;
  try {
    const result = await callPOSTEditMembersBuilderTeam(builder_team_id, users);
    if (result && result.data) {
      const res = result.data.payload;
      return res;
    }
  } catch (err: any) {
    const errStatus = err.response.status; // Integer
    const errPayload = err.response.data;
    const errMsg = errPayload.msg;

    console.log(errStatus);
    console.log(errPayload);
    console.log(errMsg);

    const res = {
      alert: {
        type: "error",
        message: err.toString(),
      },
    };

    return thunkApi.rejectWithValue(res);
  }
});

// Get all viewer teams by organization
export const teamGetViewerTeamsByOrg = createAsyncThunk<any, string>(
  "team/getViewerTeamsByOrg",
  async (org_id, thunkApi) => {
    try {
      const result = await callGETRetrieveViewerTeamByOrganization(org_id);
      if (result && result.data) {
        const res = {
          teams: result.data.payload.teams,
        };
        return res;
      }
    } catch (err: any) {
      const errStatus = err.response.status; // Integer
      const errPayload = err.response.data;
      const errMsg = errPayload.msg;

      console.log(errStatus);
      console.log(errPayload);
      console.log(errMsg);

      const res = {
        alert: {
          type: "error",
          message: err.toString(),
        },
      };

      return thunkApi.rejectWithValue(res);
    }
  }
);

// Get summary of all viewer teams by organization
export const teamGetViewerTeamsSummaryByOrg = createAsyncThunk<any, string>(
  "team/teamGetViewerTeamsSummaryByOrg",
  async (org_id, thunkApi) => {
    try {
      const result = await callGETRetrieveSummaryOfViewerTeamByOrganization(
        org_id
      );
      if (result && result.data) {
        const res = {
          teams: result.data.payload.teams,
        };
        return res;
      }
    } catch (err: any) {
      const errStatus = err.response.status; // Integer
      const errPayload = err.response.data;
      const errMsg = errPayload.msg;

      console.log(errStatus);
      console.log(errPayload);
      console.log(errMsg);

      const res = {
        alert: {
          type: "error",
          message: err.toString(),
        },
      };

      return thunkApi.rejectWithValue(res);
    }
  }
);

// Get new users to Viewer Team
export const teamAddUsersToViewerTeam = createAsyncThunk<
  any,
  { viewerTeam_id: string; listOfUsers_id: string[] }
>("team/addNewUsersToViewerTeam", async (teamData, thunkApi) => {
  const { viewerTeam_id, listOfUsers_id } = teamData;
  try {
    const result = await callPOSTAddUsersToViewerTeam(
      viewerTeam_id,
      listOfUsers_id
    );
    if (result && result.data) {
      const res = {
        replyMsg: result.data.msg,
        replyPayload: result.data.payload,
      };
      return res;
    }
  } catch (err: any) {
    const errStatus = err.response.status; // Integer
    const errPayload = err.response.data;
    const errMsg = errPayload.msg;

    console.log(errStatus);
    console.log(errPayload);
    console.log(errMsg);

    const res = {
      alert: {
        type: "error",
        message: err.toString(),
      },
    };

    return thunkApi.rejectWithValue(res);
  }
});

// Remove existing users from Viewer Team
export const teamRemoveUsersFromViewerTeam = createAsyncThunk<
  any,
  { viewerTeam_id: string; listOfUsers_id: string[] }
>("team/removeUsersFromViewerTeam", async (teamData, thunkApi) => {
  const { viewerTeam_id, listOfUsers_id } = teamData;
  try {
    const result = await callPOSTRemoveUsersFromViewerTeam(
      viewerTeam_id,
      listOfUsers_id
    );
    if (result && result.data) {
      const res = {
        replyMsg: result.data.msg,
        replyPayload: result.data.payload,
      };
      return res;
    }
  } catch (err: any) {
    const errStatus = err.response.status; // Integer
    const errPayload = err.response.data;
    const errMsg = errPayload.msg;

    console.log(errStatus);
    console.log(errPayload);
    console.log(errMsg);

    const res = {
      alert: {
        type: "error",
        message: err.toString(),
      },
    };

    return thunkApi.rejectWithValue(res);
  }
});

// edit members inside a viewer team
export const teamEditMembersViewerTeam = createAsyncThunk<
  any,
  { viewer_team_id: string; users: any[] }
>("team/editMembersViewerTeam", async (groupData, thunkApi) => {
  const { viewer_team_id, users } = groupData;
  try {
    const result = await callPOSTEditMembersViewerTeam(viewer_team_id, users);
    if (result && result.data) {
      const res = result.data.payload;
      return res;
    }
  } catch (err: any) {
    const errStatus = err.response.status; // Integer
    const errPayload = err.response.data;
    const errMsg = errPayload.msg;

    console.log(errStatus);
    console.log(errPayload);
    console.log(errMsg);

    const res = {
      alert: {
        type: "error",
        message: err.toString(),
      },
    };

    return thunkApi.rejectWithValue(res);
  }
});

const teamState = {
  builderTeamData: [],
  viewerTeamData: null,
  viewerTeamDataSummary: null,

  addBuilderTeamMsg: "",
  addBuilderTeamPayload: [],
  removeBuilderTeamMsg: "",

  addViewerTeamMsg: "",
  addViewerTeamPayload: [],
  removeViewerTeamMsg: "",

  addUsersToBuilderTeamMsg: "",
  addUsersToBuilderTeamPayload: [],
  removeUsersFromBuilderTeamMsg: "",
  removeUsersFromBuilderTeamPayload: [],

  addUsersToViewerTeamMsg: "",
  addUsersToViewerTeamPayload: [],
  removeUsersFromViewerTeamMsg: "",
  removeUsersFromViewerTeamPayload: [],

  appLoading: false,
  alert: {
    active: false,
    type: "",
    message: "",
    autoHideDuration: 6000,
  },
};
const teamSlice = createSlice({
  name: "team",
  initialState: teamState,
  reducers: {
    resetAlert: (state) => {
      state.alert.active = false;
      state.alert.type = "";
      state.alert.message = "";
      state.alert.autoHideDuration = 6000;
    },
  },
  extraReducers: (builder) => {
    builder
      // Add new Builder Team Success
      .addCase(teamAddNewBuilderTeam.pending, (state) => {
        state.appLoading = true;
      })
      .addCase(teamAddNewBuilderTeam.fulfilled, (state, action) => {
        state.addBuilderTeamMsg = action.payload.replyMsg;
        state.addBuilderTeamPayload = action.payload.replyPayload;
        state.builderTeamData = action.payload.replyPayload;
        state.appLoading = false;
      })
      .addCase(teamAddNewBuilderTeam.rejected, (state, action) => {
        const payload = action.payload as any;
        const { type, message } = payload;
        state.appLoading = false;
        state.alert.active = true;
        state.alert.type = type;
        state.alert.message = message;
        state.alert.autoHideDuration = payload.alert.autoHideDuration
          ? payload.alert.autoHideDuration
          : state.alert.autoHideDuration;
      })

      // Remove existing Builder Team Success
      .addCase(teamRemoveExistingBuilderTeam.pending, (state) => {
        state.appLoading = true;
      })
      .addCase(teamRemoveExistingBuilderTeam.fulfilled, (state, action) => {
        state.removeBuilderTeamMsg = action.payload.replyMsg;
        state.appLoading = false;
      })
      .addCase(teamRemoveExistingBuilderTeam.rejected, (state, action) => {
        const payload = action.payload as any;
        const { type, message } = payload;
        state.appLoading = false;
        state.alert.active = true;
        state.alert.type = type;
        state.alert.message = message;
        state.alert.autoHideDuration = payload.alert.autoHideDuration
          ? payload.alert.autoHideDuration
          : state.alert.autoHideDuration;
      })
      // Add new Viewer Team Success
      .addCase(teamAddNewViewerTeam.pending, (state) => {
        state.appLoading = true;
      })
      .addCase(teamAddNewViewerTeam.fulfilled, (state, action) => {
        state.addViewerTeamMsg = action.payload.replyMsg;
        state.addViewerTeamPayload = action.payload.replyPayload;
        state.viewerTeamData = action.payload.replyPayload;
        state.appLoading = false;
      })
      .addCase(teamAddNewViewerTeam.rejected, (state, action) => {
        const payload = action.payload as any;
        const { type, message } = payload;
        state.appLoading = false;
        state.alert.active = true;
        state.alert.type = type;
        state.alert.message = message;
        state.alert.autoHideDuration = payload.alert.autoHideDuration
          ? payload.alert.autoHideDuration
          : state.alert.autoHideDuration;
      })
      // Remove existing Builder Team Success
      .addCase(teamRemoveExistingViewerTeam.pending, (state, action) => {
        state.appLoading = true;
      })
      .addCase(teamRemoveExistingViewerTeam.fulfilled, (state, action) => {
        state.removeViewerTeamMsg = action.payload.replyMsg;
        state.appLoading = false;
      })
      .addCase(teamRemoveExistingViewerTeam.rejected, (state, action) => {
        const payload = action.payload as any;
        const { type, message } = payload;
        state.appLoading = false;
        state.alert.active = true;
        state.alert.type = type;
        state.alert.message = message;
        state.alert.autoHideDuration = payload.alert.autoHideDuration
          ? payload.alert.autoHideDuration
          : state.alert.autoHideDuration;
      })
      // Remove existing Builder Team Success
      .addCase(teamGetBuilderTeamsByOrg.pending, (state, action) => {
        state.appLoading = true;
      })
      .addCase(teamGetBuilderTeamsByOrg.fulfilled, (state, action) => {
        console.log("setting GET_BUILDER_TEAMS_BY_ORG");
        let builderTeamsListCount = 1;
        const builderTeamsList = action.payload.teams;
        if (builderTeamsList) {
          for (let i = 0; i < builderTeamsList.length; i++) {
            builderTeamsList[i].id = builderTeamsListCount;
            builderTeamsListCount++;
          }
        }
        state.builderTeamData = builderTeamsList;
        state.addBuilderTeamMsg = "";
        state.removeBuilderTeamMsg = "";
        state.appLoading = false;
      })
      .addCase(teamGetBuilderTeamsByOrg.rejected, (state, action) => {
        const payload = action.payload as any;
        const { type, message } = payload;
        state.appLoading = false;
        state.alert.active = true;
        state.alert.type = type;
        state.alert.message = message;
        state.alert.autoHideDuration = payload.alert.autoHideDuration
          ? payload.alert.autoHideDuration
          : state.alert.autoHideDuration;
      })
      // Add users to Builder Team Success
      .addCase(teamAddUsersToBuilderTeam.pending, (state) => {
        state.appLoading = true;
      })
      .addCase(teamAddUsersToBuilderTeam.fulfilled, (state, action) => {
        state.addUsersToBuilderTeamMsg = action.payload.replyMsg;
        state.addUsersToBuilderTeamPayload = action.payload.replyPayload;
        state.appLoading = false;
      })
      .addCase(teamAddUsersToBuilderTeam.rejected, (state, action) => {
        const payload = action.payload as any;
        const { type, message } = payload;
        state.appLoading = false;
        state.alert.active = true;
        state.alert.type = type;
        state.alert.message = message;
        state.alert.autoHideDuration = payload.alert.autoHideDuration
          ? payload.alert.autoHideDuration
          : state.alert.autoHideDuration;
      })
      // Remove users from Builder Team Success
      .addCase(teamRemoveUsersFromBuilderTeam.pending, (state, action) => {
        state.appLoading = true;
      })
      .addCase(teamRemoveUsersFromBuilderTeam.fulfilled, (state, action) => {
        state.removeUsersFromBuilderTeamMsg = action.payload.replyMsg;
        state.removeUsersFromBuilderTeamPayload = action.payload.replyPayload;
        state.appLoading = false;
      })
      .addCase(teamRemoveUsersFromBuilderTeam.rejected, (state, action) => {
        const payload = action.payload as any;
        const { type, message } = payload;
        state.appLoading = false;
        state.alert.active = true;
        state.alert.type = type;
        state.alert.message = message;
        state.alert.autoHideDuration = payload.alert.autoHideDuration
          ? payload.alert.autoHideDuration
          : state.alert.autoHideDuration;
      })
      // Edit members in Builder Team Success
      .addCase(teamEditMembersBuilderTeam.pending, (state, action) => {
        state.appLoading = true;
      })
      .addCase(teamEditMembersBuilderTeam.fulfilled, (state, action) => {
        state.addUsersToBuilderTeamMsg = action.payload.msg;
        state.builderTeamData = action.payload.team;
        state.addBuilderTeamMsg = "EDIT_MEMBERS_IN_BUILDER_TEAM_SUCCESS";
        state.appLoading = false;
      })
      .addCase(teamEditMembersBuilderTeam.rejected, (state, action) => {
        const payload = action.payload as any;
        const { type, message } = payload;
        state.appLoading = false;
        state.alert.active = true;
        state.alert.type = type;
        state.alert.message = message;
        state.alert.autoHideDuration = payload.alert.autoHideDuration
          ? payload.alert.autoHideDuration
          : state.alert.autoHideDuration;
      })
      // Fetch and set viewer teams by org.
      .addCase(teamGetViewerTeamsByOrg.pending, (state, action) => {
        state.appLoading = true;
      })
      .addCase(teamGetViewerTeamsByOrg.fulfilled, (state, action) => {
        let viewerTeamsListCount = 1;
        const viewerTeamsList = action.payload.teams;
        if (viewerTeamsList) {
          for (let i = 0; i < viewerTeamsList.length; i++) {
            viewerTeamsList[i].id = viewerTeamsListCount;
            viewerTeamsListCount++;
          }
        }
        state.viewerTeamData = viewerTeamsList;
        state.addViewerTeamMsg = "";
        state.removeViewerTeamMsg = "";
        state.appLoading = false;
      })
      .addCase(teamGetViewerTeamsByOrg.rejected, (state, action) => {
        const payload = action.payload as any;
        const { type, message } = payload;
        state.appLoading = false;
        state.alert.active = true;
        state.alert.type = type;
        state.alert.message = message;
        state.alert.autoHideDuration = payload.alert.autoHideDuration
          ? payload.alert.autoHideDuration
          : state.alert.autoHideDuration;
      })
      // Fetch and set sumary of all the viewer teams by org.
      .addCase(teamGetViewerTeamsSummaryByOrg.pending, (state, action) => {
        state.appLoading = true;
      })
      .addCase(teamGetViewerTeamsSummaryByOrg.fulfilled, (state, action) => {
        // let viewerTeamsListCount = 1;
        const viewerTeamsList = action.payload.teams;
        // if (viewerTeamsList) {
        //   for (let i = 0; i < viewerTeamsList.length; i++) {
        //     viewerTeamsList[i].id = viewerTeamsListCount;
        //     viewerTeamsListCount++;
        //   }
        // }
        state.viewerTeamDataSummary = viewerTeamsList;
        state.addViewerTeamMsg = "";
        state.removeViewerTeamMsg = "";
        state.appLoading = false;
      })
      .addCase(teamGetViewerTeamsSummaryByOrg.rejected, (state, action) => {
        const payload = action.payload as any;
        const { type, message } = payload;
        state.appLoading = false;
        state.alert.active = true;
        state.alert.type = type;
        state.alert.message = message;
        state.alert.autoHideDuration = payload.alert.autoHideDuration
          ? payload.alert.autoHideDuration
          : state.alert.autoHideDuration;
      })
      // Add users to Builder Team Success
      .addCase(teamAddUsersToViewerTeam.pending, (state, action) => {
        state.appLoading = true;
      })
      .addCase(teamAddUsersToViewerTeam.fulfilled, (state, action) => {
        state.addUsersToBuilderTeamMsg = action.payload.replyMsg;
        state.addUsersToBuilderTeamPayload = action.payload.replyPayload;
        state.appLoading = false;
      })
      .addCase(teamAddUsersToViewerTeam.rejected, (state, action) => {
        const payload = action.payload as any;
        const { type, message } = payload;
        state.appLoading = false;
        state.alert.active = true;
        state.alert.type = type;
        state.alert.message = message;
        state.alert.autoHideDuration = payload.alert.autoHideDuration
          ? payload.alert.autoHideDuration
          : state.alert.autoHideDuration;
      })
      // Remove users from Viewer Team Success
      .addCase(teamRemoveUsersFromViewerTeam.pending, (state, action) => {
        state.appLoading = true;
      })
      .addCase(teamRemoveUsersFromViewerTeam.fulfilled, (state, action) => {
        state.removeUsersFromViewerTeamMsg = action.payload.replyMsg;
        state.removeUsersFromViewerTeamPayload = action.payload.replyPayload;
        state.appLoading = false;
      })
      .addCase(teamRemoveUsersFromViewerTeam.rejected, (state, action) => {
        const payload = action.payload as any;
        const { type, message } = payload;
        state.appLoading = false;
        state.alert.active = true;
        state.alert.type = type;
        state.alert.message = message;
        state.alert.autoHideDuration = payload.alert.autoHideDuration
          ? payload.alert.autoHideDuration
          : state.alert.autoHideDuration;
      })
      .addCase(teamEditMembersViewerTeam.pending, (state, action) => {
        state.appLoading = true;
      })
      .addCase(teamEditMembersViewerTeam.fulfilled, (state, action) => {
        state.addUsersToBuilderTeamMsg = action.payload.msg;
        state.viewerTeamData = action.payload.team;
        state.addViewerTeamMsg = "EDIT_MEMBERS_IN_VIEWER_TEAM_SUCCESS";
        state.appLoading = false;
      })
      .addCase(teamEditMembersViewerTeam.rejected, (state, action) => {
        const payload = action.payload as any;
        const { type, message } = payload;
        state.appLoading = false;
        state.alert.active = true;
        state.alert.type = type;
        state.alert.message = message;
        state.alert.autoHideDuration = payload.alert.autoHideDuration
          ? payload.alert.autoHideDuration
          : state.alert.autoHideDuration;
      });
  },
});

const { actions, reducer } = teamSlice;
export const { resetAlert } = actions;
export const reducerTeam = reducer;

export default reducerTeam;
