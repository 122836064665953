import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { Container, createTheme } from "@mui/material";
import { UserData } from "../../store/reducers/_reducerIndex";
import Navigation from "../../components/03-organisms/03-navigation/Navigation";
import BackgroundSwirlDecoration from "../../components/01-atoms/00-icons/BackgroundSwirlDecoration";
import MenuIcon from "@mui/icons-material/Menu";
import { showHideNavbar } from "../../store/reducers/reducerNavigation";

/** This is the base page for admins and builders */

const PageHomeDefaultBasePage = ({
  children,
  user,
}: {
  children: any;
  user: UserData;
}) => {
  const org = useSelector((state: RootState) => state.org);

  // Sidebar open/close toggle handler.
  const handleDrawerToggle = () => {
    // Re-code when ready to use Toggle function.
  };

  const dispatch = useDispatch();
  // this is for handling something on navigation when screen smaller than 1240px
  const showNavbarSmallScreen = useSelector(
    (state: RootState) => state.navigation.showHideNavbar
  );

  // screen size detector, to handle some event related to navigation bar
  const [screenSize, setScreenSize] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setScreenSize(window.innerWidth);
      if (window.innerWidth > 1240) {
        dispatch(showHideNavbar(false));
      }
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // console.log(screenSize);

  return (
    <>
      {/* classname deleted frameSidebar */}
      <div className="w-full">
        {/* <div
          style={{
            // position: "fixed",
            top: 0,
            right: 0,
            backgroundColor: "pink",
            zIndex: 9999,
            width: "100%",
          }}
        >
          Your trial ends in 14 days.
        </div> */}
        <div className="flex-none">
          {
            // <PageSidebar
            //   isHost={org.isHost === true}
            //   isGuest={org.isGuest === true}
            //   // mobileOpen={mobileOpen}
            //   /**@ts-ignore */
            //   userEmail={user.email}
            // />
          }
          {/* */}
          <div
            className="pt-4 pl-4 absolute cursor-pointer xl2:hidden"
            onClick={() => dispatch(showHideNavbar(!showNavbarSmallScreen))}
          >
            <MenuIcon />
            {showNavbarSmallScreen && <Navigation />}
          </div>
          <div className="ut-navigation-display">
            <Navigation />
          </div>
        </div>
        <div className="frameBody flex-grow">
          {/* <PageTopBar handleDrawerToggle={handleDrawerToggle} /> */}
          <main className="frameBodyContent ml-0 xl2:ml-[18.75em]">
            <Container maxWidth="xl">
              {/* Component for showing billing modal */}
              {children}
            </Container>
          </main>
        </div>
      </div>
      <div
        className="fixed"
        style={{
          left: "18.75em",
          bottom: "0",
          transform: "scaleX(-1)",
          zIndex: -1,
        }}
      >
        <BackgroundSwirlDecoration scale={1.5} />
      </div>
      <div
        className="fixed"
        style={{
          right: "0",
          top: "0",
          transform: "rotate(180deg) scaleX(-1)",
          zIndex: -1,
        }}
      >
        <BackgroundSwirlDecoration scale={1.5} />
      </div>
    </>
  );
};

export default PageHomeDefaultBasePage;
