import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getFirstPageSnaps,
  getRestPageSnaps,
  resetAlert,
} from "../store/reducers/reducerGuide";
import TableAllSnaps from "../components/Table/TableAllSnaps";
import ComponentLoading from "../components/ComponentLoading";
import { RootState } from "../store";
import ComponentFilterTable from "../components/ComponentFilterTable";
import { SnapItems } from "../interfaces/interfaceGuide";
import ComponentAlertAppOverallSnackbar from "../components/ComponentAlertAppOverallSnackbar";
import { Tags } from "usertip-component-library";

const PageAllSnapGuide = () => {
  const [filteredGuide, setFilteredGuide] = useState<SnapItems[]>([]);

  const { appLoading, allSnaps, alert, totalSnaps } = useSelector(
    (state: RootState) => state.guide
  );

  const dispatch = useDispatch();

  const guideFilter = [
    {
      field: "publish",
      type: "Boolean",
      label: "Status",
    },
    {
      field: "name",
      type: "String",
      label: "Snaps",
    },
    {
      field: "date_created",
      type: "Date",
      label: "Date Created",
    },
  ];

  //get first 25 items
  useEffect(() => {
    dispatch(getFirstPageSnaps());
  }, []);

  //get the rest of the items
  useEffect(() => {
    if (allSnaps.length < totalSnaps) {
      dispatch(getRestPageSnaps());
    }
  }, [allSnaps]);

  return (
    <>
      <ComponentLoading show={appLoading} />
      <div className="pageBase">
        <div className="flex items-center gap-4">
          <div className="pageHeader">List of Snaps</div>
          <div className="cursor-default">
            <Tags
              size="medium"
              variant="filled"
              text={`${allSnaps.length} Snaps`}
            />
          </div>
        </div>

        <div className="text-[#9CA3AF]">View and manage snaps here.</div>
      </div>
      {allSnaps && (
        <ComponentFilterTable
          filters={guideFilter}
          originalData={allSnaps}
          setFilteredData={setFilteredGuide}
        />
      )}
      <TableAllSnaps snapData={filteredGuide} />

      {alert !== null && alert.type !== "" && alert.type !== undefined && (
        <ComponentAlertAppOverallSnackbar
          alert={alert}
          reset={() => dispatch(resetAlert())}
        />
      )}
    </>
  );
};

export default PageAllSnapGuide;
