import React from "react";
import { BuildingBlockType } from "../../../interfaces/interfaceTheme";

interface Props {
  style: BuildingBlockType;
  type: "title" | "subtitle";
}

const TextBB = ({ style, type }: Props) => {
  type textAlignType = "start" | "end" | "center";

  const textAlignMap = {
    "flex-start": "start",
    "flex-end": "end",
    center: "center",
  };

  const _color = style!.color != undefined ? style!.color : "#000000";
  const _fontWeight = style!.fontWeight != undefined ? style!.fontWeight : "";
  const _fontSize =
    style!.fontSize != undefined && parseInt(style!.fontSize.toString())
      ? style!.fontSize + "px"
      : "";
  const _fontFamily = style!.fontFamily != undefined ? style!.fontFamily : "";
  const _lineHeight = style!.lineHeight != undefined ? style!.lineHeight : "";
  const _letterSpacing =
    style!.letterSpacing != undefined ? style!.letterSpacing + "px" : "";
  const _justifyContent =
    style!.justifyContent != undefined
      ? style!.justifyContent != "center"
        ? style!.justifyContent
        : style!.justifyContent
      : "flex-start";
  const _textAlign =
    style!.justifyContent != undefined
      ? (textAlignMap[style!.justifyContent] as textAlignType)
      : ("" as textAlignType);
  const _alignItems =
    style!.justifyContent != undefined ? style!.justifyContent : "";
  const _margin = style!.margin != undefined ? style!.margin + "px" : "3px";
  const _padding = style!.padding != undefined ? style!.padding + "px" : "12px";
  const _backgroundColor =
    style!.backgroundColor != undefined ? style!.backgroundColor : "";

  /* Style that user can't change */
  const _fontType = "";
  const _opacity = "";

  return (
    <div
      className={`w-full border-2 border-black border-opacity-0 mb-5 block leading-normal box-border ${
        type === "title" ? "pr-6" : ""
      }`}
    >
      {type === "title" && (
        <h1
          style={{
            display: "block",
            flexDirection: "column",
            color: _color,
            //FIXME:Object literal may only specify known properties, but 'fontType' does not exist in type 'Properties
            //@ts-ignore
            fontType: _fontType,
            fontWeight: _fontWeight,
            fontSize: _fontSize,
            fontFamily: _fontFamily,
            lineHeight: _lineHeight,
            letterSpacing: _letterSpacing,
            justifyContent: _justifyContent,
            textAlign: _textAlign,
            alignItems: _alignItems,
            margin: _margin,
            padding: _padding,
            backgroundColor: _backgroundColor,
            opacity: _opacity,
          }}
        >
          Welcome to Usertip!
        </h1>
      )}
      {type === "subtitle" && (
        <p
          style={{
            display: "block",
            flexDirection: "column",
            color: _color,
            //FIXME:Object literal may only specify known properties, but 'fontType' does not exist in type 'Properties
            // @ts-ignore
            fontType: _fontType,
            fontWeight: _fontWeight,
            fontSize: _fontSize,
            fontFamily: _fontFamily,
            lineHeight: _lineHeight,
            letterSpacing: _letterSpacing,
            justifyContent: _justifyContent,
            textAlign: _textAlign,
            alignItems: _alignItems,
            margin: _margin,
            padding: _padding,
            backgroundColor: _backgroundColor,
            opacity: _opacity,
          }}
        >
          This is where you will start editing your content
        </p>
      )}
    </div>
  );
};

export default TextBB;
