import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ComponentAlertAppOverallSnackbar from "../components/ComponentAlertAppOverallSnackbar";
import ComponentListSharedWalkthroughs from "../components/ComponentListSharedWalkthroughs";
import ComponentLoading from "../components/ComponentLoading";
import { RootState } from "../store";
import {
  organizationGetGroupedOrgAndWalkthroughs,
  resetAlert,
} from "../store/reducers/reducerOrganization";

const PageSharedWalkthrough = () => {
  const dispatch = useDispatch();
  const orgID = useSelector((state: RootState) => state.user.userData.org);
  const sharedWalkthroughs = useSelector(
    (state: RootState) => state.org.sharedWalkthroughs
  );
  const isLoading = useSelector((state: RootState) => state.org.appLoading);
  const alert = useSelector((state: RootState) => state.org.alert);

  useEffect(() => {
    dispatch(organizationGetGroupedOrgAndWalkthroughs(orgID!));
  }, [dispatch, orgID]);

  return (
    <>
      <ComponentLoading show={isLoading} />
      <div className="pageBase">
        {/*Walkthrough list*/}
        <ComponentListSharedWalkthroughs
          displayShowAddBtn={true}
          introMsg={"View and manage shared walkthroughs here."}
          indicatorMsg={"No walkthroughs found. Please add one!"}
          dataSet={sharedWalkthroughs}
          orgID={orgID!}
        />
      </div>
      {alert.type !== "" && alert.type !== undefined && (
        <ComponentAlertAppOverallSnackbar
          alert={alert}
          reset={() => dispatch(resetAlert())}
        />
      )}
    </>
  );
};

export default PageSharedWalkthrough;
