import React from "react";

interface Props {
  /** This is for sidebar item label */
  label?: string;
  /** This is for sidebar item icon */
  icon?: JSX.Element;
  /** This is for grouping the radio input to one group (set the same name to make the style working) */
  name: string;
  /** This is for set function when sidebarItem is clicked */
  onClick?: () => void;
}

const SidebarItem = ({ label, icon, name, onClick }: Props) => {
  /** This const is for parent container style  */
  const statusClass: string =
    "w-full my-1 h-9 py-2 px-[0.625em] rounded-[2.5em] flex items-center gap-[0.625em] cursor-pointer relative group";
  /** This const is for icon style  */
  const iconClass: string =
    "fill-current text-default-neutral-60 group-hover:text-default-neutral-100 group-active:text-default-neutral-100 peer-checked/radio:text-default-primary";
  /** This const is for background style  */
  const bgClass: string =
    "w-full h-full absolute left-0 -z-10 peer-checked/radio:bg-default-neutral-10 rounded-[2.5em] bg-default-neutral-20 peer-checked/radio:drop-shadow-[0_4px_4px_rgba(0,0,0,0.2)] group-hover:bg-default-primary-lightest group-hover:drop-shadow-[0_4px_4px_rgba(0,0,0,0.2)] group-active:bg-[#E4DDFC] group-active:drop-shadow-[0_4px_4px_rgba(0,0,0,0.2)]";
  /** This const is for label style  */
  const labelClass: string =
    "font-medium text-default-neutral-60 peer-checked/radio:text-default-neutral-100 group-hover:text-default-neutral-100 group-active:text-default-neutral-100";

  return (
    <div className={`${statusClass}`} onClick={onClick}>
      <input
        type="radio"
        name={name}
        className="w-full h-full absolute cursor-pointer peer/radio opacity-0 left-0"
      />
      <div className={bgClass}></div>
      <div className={`${iconClass}`}>
        {!icon ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 14 14"
          >
            <path d="M1.16675 6.22414C1.16675 3.43099 3.40695 1.16669 6.17037 1.16669C7.49741 1.16669 8.77011 1.69952 9.70847 2.64798C10.6468 3.59644 11.174 4.88282 11.174 6.22414C11.174 9.0173 8.9338 11.2816 6.17037 11.2816C3.40695 11.2816 1.16675 9.0173 1.16675 6.22414ZM11.0912 10.2984L12.5814 11.5013H12.6073C12.9088 11.806 12.9088 12.3001 12.6073 12.6048C12.3058 12.9095 11.817 12.9095 11.5155 12.6048L10.2788 11.1875C10.1619 11.0697 10.0962 10.9097 10.0962 10.7429C10.0962 10.5761 10.1619 10.4161 10.2788 10.2984C10.5043 10.0744 10.8657 10.0744 11.0912 10.2984Z" />
          </svg>
        ) : (
          icon
        )}
      </div>
      <p className={labelClass}>{label}</p>
    </div>
  );
};

export default SidebarItem;
