import React from "react";
import { InputNumber, Toggle, ToggleItem } from "usertip-component-library";

interface Props {
  /** Prop for set label of the input */
  label: string;
  /** Prop for set value of the input */
  value: any;
  /** Prop for grouping the size unit input (should be different with other radio input) */
  name: string;
  /** Handler for changing number input value */
  valueSettingHandler: (value: number) => void;
  /** Handler for clicking size unit */
  valueUnitHandler: (value: any) => void;
}

const SizeSetting = ({
  label,
  value,
  name,
  valueSettingHandler,
  valueUnitHandler,
}: Props) => {
  return (
    <>
      <h1 className="text-sm">{label}:</h1>
      <div className="flex gap-1 items-center">
        <div className="flex-1">
          <InputNumber
            value={value.value}
            onChange={valueSettingHandler}
            width="full"
            disabled={value.unit === "auto"}
            minValue={0}
            variant="outlined"
          />
        </div>
        <div className="flex-initial">
          <Toggle name={name} width="fixed">
            <ToggleItem
              label="PX"
              id="px"
              value="px"
              selectedValue={value.unit}
              onSelectedValue={valueUnitHandler}
            />
            <ToggleItem
              label="%"
              id="percentage"
              value="%"
              selectedValue={value.unit}
              onSelectedValue={valueUnitHandler}
            />
            <ToggleItem
              label="AUTO"
              id="auto"
              value="auto"
              selectedValue={value.unit}
              onSelectedValue={valueUnitHandler}
            />
          </Toggle>
        </div>
      </div>
    </>
  );
};

export default SizeSetting;
