// Handles the Sorting of "Asc / Desc" order + Stablize the Sort for backward-compatibility browswers
// This method is created for cross-browser compatibility, if you don"t
// need to support IE11, you can use Array.prototype.sort() directly
export const stablizeSorting = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

export const descendingComparator = (a, b, orderBy) => {
  // Handling manual re-routing of Sort-Parameters to fit the dataset variables.
  if (orderBy === "invite_status") {
    orderBy = "confirmed_invitation";
  } else if (orderBy === "walkthrough_status") {
    orderBy = "publish";
  } else if (orderBy === "date_joined") {
    orderBy = "createdAt";
  } else if (orderBy === "date_created") {
    orderBy = "createdAt";
  } else if (orderBy === "date_last_edited") {
    orderBy = "updatedAt";
  }

  // Manually replace "undefined" entry fields in Table to empty string, so that sorting will not be broken.
  if (a[orderBy] === null) {
    a[orderBy] = "";
  }
  if (b[orderBy] === null) {
    b[orderBy] = "";
  }

  // Handles the standard sorting request parameters for asc / desc order
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

export const getComparator = (order, orderBy) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};
