import React, { useEffect, useState } from "react";
import ComponentSettingsAssistantAllowedPages from "./ComponentSettingsAssistantAllowedPages";
import ComponentSettingsAssistantExcludedPages from "./ComponentSettingsAssistantExcludedPages";
import { Button } from "usertip-component-library";
import { useDispatch, useSelector } from "react-redux";
import {
  resetAlert,
  updateAssistantAllowedPages,
  updateAssistantExcludedPages,
} from "../store/reducers/reducerOrganization";
import { RootState } from "../store";

const AssistantPageManagementSettings = () => {
  const [allowedPagesList, setAllowedPagesList] = useState<string[]>([]);
  const [excludedPagesList, setExcludedPagesList] = useState<string[]>([]);

  const { org_id: orgId } = useSelector((state: RootState) => state.org);

  const { allowedPages: allowedPagesData, excludedPages: excludedPagesData } =
    useSelector((state: RootState) => state.org.assistant);

  const dispatch = useDispatch();

  // Tracks if there are any changes for allowedPages and excludedPages list from redux
  useEffect(() => {
    if (allowedPagesData) {
      setAllowedPagesList(allowedPagesData);
    }

    if (excludedPagesData) {
      setExcludedPagesList(excludedPagesData);
    }
  }, [allowedPagesData, excludedPagesData]);

  /** Function for update assistant allowed pages */
  const handleSaveAssistantAllowedPages = async () => {
    if (orgId) {
      dispatch(
        updateAssistantAllowedPages({
          org_id: orgId,
          allowedPages: allowedPagesList,
        })
      );
    }
  };

  /** Function for update assistant excluded pages */
  const handleSaveAssistantExcludedPages = async () => {
    if (orgId) {
      dispatch(
        updateAssistantExcludedPages({
          org_id: orgId,
          excludedPages: excludedPagesList as string[],
        })
      );
    }
  };

  /** Handler for cancel button */
  const cancelButtonHandler = () => {
    setAllowedPagesList(allowedPagesData);
    setExcludedPagesList(excludedPagesData);
  };

  /** Handler for submit button */
  const handleSubmit = async () => {
    dispatch(resetAlert());

    if (allowedPagesChange()) {
      await handleSaveAssistantAllowedPages();
    }

    if (excludedPagesChange()) {
      await handleSaveAssistantExcludedPages();
    }
  };

  /** Indicator for check if allowedPages list has changed */
  const allowedPagesChange = () => {
    if (allowedPagesList.length !== allowedPagesData.length) {
      return true;
    }

    const newData = [];

    allowedPagesList.forEach((value) => {
      const data = allowedPagesData.find((item) => {
        return item === value;
      });

      if (data === undefined) {
        newData.push(value);
      }
    });

    if (newData.length > 0) {
      return true;
    }

    return false;
  };

  /** Indicator for check if exclude page list has changed */
  const excludedPagesChange = () => {
    if (excludedPagesList.length !== excludedPagesData.length) {
      return true;
    }

    const newData = [];

    excludedPagesList.forEach((value) => {
      const data = excludedPagesData.find((item) => {
        return item === value;
      });

      if (data === undefined) {
        newData.push(value);
      }
    });

    if (newData.length > 0) {
      return true;
    }

    return false;
  };

  /** Indicator for disabling the button */
  const buttonDisabled = !allowedPagesChange() && !excludedPagesChange();

  return (
    <div className="flex flex-col gap-6 ">
      <h1 className="font-semibold text-lg">
        Usertip Assistant Page Management Settings
      </h1>
      <div className="flex flex-col gap-4">
        <ComponentSettingsAssistantAllowedPages
          allowedPagesList={allowedPagesList}
          setAllowedPagesList={setAllowedPagesList}
        />
        <ComponentSettingsAssistantExcludedPages
          excludedPagesList={excludedPagesList}
          setExcludedPagesList={setExcludedPagesList}
        />
      </div>

      <div className="ml-auto flex gap-2">
        {!buttonDisabled && (
          <Button
            color="secondary"
            size="medium"
            text="Cancel"
            variant="primary"
            customClass="!px-7 !py-2"
            onClick={cancelButtonHandler}
          />
        )}
        <Button
          color="primary"
          size="medium"
          text="Save"
          variant="primary"
          customClass="!px-7 !py-2"
          disabled={buttonDisabled}
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
};

export default AssistantPageManagementSettings;
