import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import ComponentPageHeaderLinksBackBtn from "../components/ComponentPageHeaderLinksBackBtn";
import ComponentPageHeaderLinkArray from "../components/ComponentPageHeaderLinkArray";
import ComponentListWalkthroughViewingPermissions from "../components/ComponentListWalkthroughViewingPermissions";
import ComponentListWalkthroughViewerTeamsPermissions from "../components/ComponentListWalkthroughViewerTeamsPermissions";
import ComponentSelectUserSegmentForWalkthrough from "../components/ComponentSelectUserSegmentForWalkthrough";
import {
  deleteOneIndvSharingPermissionFromSingleWalkthrough,
  deleteOneTeamSharingPermissionFromSingleWalkthrough,
  getAllSharingPermissionsFromSingleWalkthrough,
  resetAlert,
  retrieveSingleWalkthroughUserSegmentation,
  updateIndvSharingPermissionsFromSingleWalkthrough,
  updateTeamSharingPermissionsFromSingleWalkthrough,
} from "../store/reducers/reducerWalkthrough";
import ComponentAlertAppOverallSnackbar from "../components/ComponentAlertAppOverallSnackbar";
import ComponentLoading from "../components/ComponentLoading";
import {
  organizationGetCodeSnippetViewers,
  organizationGetOrganizationInfo,
} from "../store/reducers/reducerOrganization";
import { teamGetViewerTeamsByOrg } from "../store/reducers/reducerTeam";
import { RootState } from "../store";
import { Walkthrough } from "../interfaces/interface Organization";

const PageWalkthroughViewingPermissions = () => {
  // TODO: review loading states, see if they are needed or can we do something better
  // state
  const [globalLoading, setGlobalLoading] = useState(false);
  const [users, setUser] = useState<any[] | undefined>();
  //state from redux
  const { userData } = useSelector((state: RootState) => state.user);
  const orgID = userData.org;
  const {
    walkthroughs,
    walkthroughSharingPermission,
    resource_type,
    resource_id,
    resource_update_datetime,
    activeWalkthroughUserSegmentation,
    alert,
  } = useSelector((state: RootState) => state.walkthrough);

  const selectedWalkthrough = resource_id;
  const lastPermissionUpdated = resource_update_datetime;
  /** Walkthrough's User Segmentation value */
  const walkthroughUserSegmentation = activeWalkthroughUserSegmentation;

  const { admins, builders, viewers, viewerersCodeSnippet } = useSelector(
    (state: RootState) => state.org
  );

  const viewerTeams = useSelector(
    (state: RootState) => state.team.viewerTeamData
  );

  /** Loading state when fetching api calls */
  const isLoading = useSelector(
    (state: RootState) => state.walkthrough.appLoading
  );

  const userSegmentationPermission = useSelector(
    (state: RootState) =>
      state.org.org_plan?.org_feature_plan.walkthrough_user_segmentation
  );

  const { walkthroughID } = useParams();
  const [activeWalkthrough, setActiveWalkthrough] =
    useState<null | Walkthrough>(null);

  const [sharingPermissionViewersList, setSharingPermissionViewersList] =
    useState([]);
  const [
    sharingPermissionViewerTeamsList,
    setSharingPermissionViewerTeamsList,
  ] = useState([]);

  const dispatch = useDispatch();

  // Tracks & updates only when there are changes to either "orgID" or "walkthroughID". (eg. Switching between Walkthroughs / Hard refresh)
  useEffect(() => {
    if (orgID) {
      dispatch(organizationGetOrganizationInfo(orgID));
      dispatch(teamGetViewerTeamsByOrg(orgID));
      dispatch(organizationGetCodeSnippetViewers(orgID));
    }

    if (walkthroughID) {
      dispatch(getAllSharingPermissionsFromSingleWalkthrough(walkthroughID));
      // onGetAllSharingPermissionsSingleWalkthrough(walkthroughID);
    }

    if (lastPermissionUpdated) {
      dispatch(getAllSharingPermissionsFromSingleWalkthrough(walkthroughID!));
      // onGetAllSharingPermissionsSingleWalkthrough(walkthroughID);
    }
  }, [orgID, lastPermissionUpdated, dispatch, walkthroughID]);

  // Tracks changes in "sharingPermission".
  useEffect(() => {
    if (walkthroughSharingPermission) {
      setSharingPermissionViewerTeamsList(
        walkthroughSharingPermission.viewer_teams_list
      );
      setSharingPermissionViewersList(
        walkthroughSharingPermission.viewers_list
      );
    }
    console.log(
      "---walkthroughSharingPermission",
      walkthroughSharingPermission
    );
  }, [walkthroughSharingPermission]);

  // Grab the Walkthrough details based on "walkthrough_id", and then Grab the first instance of the results out of the Array to reply as a Walkthrough Object
  useEffect(() => {
    if (lastPermissionUpdated && walkthroughs !== null && walkthroughID) {
      const filteredWalkthroughs = walkthroughs.filter(
        (walkthrough) =>
          walkthrough.walkthrough_id.toString() === walkthroughID.toString()
      )[0];
      setActiveWalkthrough(filteredWalkthroughs);
    }
  }, [
    resource_type,
    selectedWalkthrough,
    walkthroughSharingPermission,
    lastPermissionUpdated,
    walkthroughs,
    walkthroughID,
  ]);

  useEffect(() => {
    let adminsData = admins ? admins : [];
    let buildersData = builders ? builders : [];
    let viewersData = viewers ? viewers : [];
    let viewerCodeSnippetData = viewerersCodeSnippet
      ? viewerersCodeSnippet
      : [];
    if (adminsData && buildersData && viewersData && viewerCodeSnippetData) {
      /* Check if owner also in admins/builders/viewers list. if yes remove it. so it's not duplicated*/
      const addedRoleCSViewers = viewerCodeSnippetData.map((d: any) => {
        return {
          ...d,
          role: "cs_viewer",
        };
      });

      setUser([
        ...adminsData,
        ...buildersData,
        ...viewersData,
        ...addedRoleCSViewers,
      ]);
      setGlobalLoading(false);
    }
  }, [admins, builders, viewerersCodeSnippet, viewers]);

  /** Retrieve walkthrough user segmentation from the backend */
  useEffect(() => {
    if (walkthroughID) {
      dispatch(retrieveSingleWalkthroughUserSegmentation(walkthroughID));
    }
  }, [walkthroughID]);

  return (
    <div className="pageBase">
      <ComponentLoading show={globalLoading} />
      <ComponentLoading show={isLoading} />

      <ComponentPageHeaderLinksBackBtn
        btnURL={`/guide-permissions`}
        btnLabel={"Guide Permissions"}
        btnDisabled={false}
      />

      <ComponentPageHeaderLinkArray
        linkArrayType={"Walkthroughs"}
        walkthroughID={walkthroughID!}
      />

      <div className="pageHeader">
        Viewing Permissions: {activeWalkthrough ? activeWalkthrough.name : ""}
      </div>

      {/*Walkthrough viewers list*/}
      {users && (
        // TODO: Convert ComponentListWalkthroughViewingPermissions.js to TypeScript
        //@ts-ignore
        <ComponentListWalkthroughViewingPermissions
          viewingPermisionsType={"Individual"}
          introMsg={
            "Select which individuals should have access to this walkthrough."
          }
          indicatorMsg={"No Viewing Permissions found. Please add one!"}
          dataSet={users}
          permissionDataSet={sharingPermissionViewersList}
          orgID={orgID}
          walkthroughID={walkthroughID}
          onDeleteOneIndvPermissions={
            deleteOneIndvSharingPermissionFromSingleWalkthrough
          }
          onBulkUpdateIndvPermissions={
            updateIndvSharingPermissionsFromSingleWalkthrough
          }
        />
      )}

      {/*Walkthrough viewer teams list*/}
      {/* // TODO: Convert ComponentListWalkthroughViewerTeamsPermissions.js to TypeScript
      //@ts-ignore */}
      {
        //@ts-ignore
        <ComponentListWalkthroughViewerTeamsPermissions
          viewerTeamType={"Individual"}
          introMsg={
            "Select which teams should have access to this walkthrough."
          }
          indicatorMsg={"No Viewer Teams Permissions found. Please add one!"}
          dataSet={viewerTeams}
          permissionDataSet={sharingPermissionViewerTeamsList}
          orgID={orgID}
          walkthroughID={walkthroughID}
          onDeleteOneTeamPermissions={
            deleteOneTeamSharingPermissionFromSingleWalkthrough
          }
          onBulkUpdateViewerTeamPermission={
            updateTeamSharingPermissionsFromSingleWalkthrough
          }
        />
      }

      {/** Select user segment */}
      {console.log("select user segment")}
      {console.log(
        "select user segment data",
        walkthroughID,
        walkthroughUserSegmentation
      )}
      {walkthroughID && userSegmentationPermission && (
        <ComponentSelectUserSegmentForWalkthrough
          walkthroughId={walkthroughID}
          userSegmentation={walkthroughUserSegmentation}
        />
      )}

      {alert.type !== "" && alert.type !== undefined && (
        <ComponentAlertAppOverallSnackbar
          alert={alert}
          reset={() => dispatch(resetAlert())}
        />
      )}
    </div>
  );
};

export default PageWalkthroughViewingPermissions;
