import React from "react";

/** This is for popup item props */
interface ItemProps {
  /** This prop is for popup item label */
  label?: string;
  /** This prop is for popup item type */
  type?: "spacer" | "link" | "default" | "filter";
  /** This prop is for set icon for popup item type */
  icon?: JSX.Element;
  /** This prop is for link type only */
  href?: string;
  /** This prop is for set function when popup item is clicked */
  onClick?: (e: any) => void;
}

/** This is for popup container */
interface Props {
  /** This prop is for set PopupItem as children of Popup */
  children: JSX.Element | JSX.Element[];
  /** This prop is for popup item type */
  type?: "default" | "filter";
}

const PopupItem = ({ label, icon, type, href, onClick }: ItemProps) => {
  /** Default style */
  let styleClass: string =
    "p-3 text-sm cursor-pointer font-medium flex items-center gap-[0.625em]";

  /** This is for PopupSpacer (return when type is spacer) */
  if (type === "spacer") {
    return <div className="h-[1px] bg-default-neutral-60"></div>;
  }

  /** This is for style popup item by type */
  if (type === "default") {
    styleClass = styleClass + " text-default-neutral-60";
  } else if (type === "link") {
    styleClass = styleClass + " text-default-info underline";
  } else if (type === "filter") {
    styleClass = styleClass + " text-default-primary flex justify-center";
  }

  return (
    <div
      className={styleClass}
      onClick={(e) => {
        e.stopPropagation();
        if (onClick) {
          onClick(e);
        }
      }}
    >
      {icon}
      {type !== "link" ? (
        <p className="whitespace-nowrap">
          {type === "filter" ? label?.toUpperCase() : label}
        </p>
      ) : (
        <a
          href={href}
          className="hover:text-default-info visited:text-default-info"
          target="_blank"
        >
          {label}
        </a>
      )}
    </div>
  );
};

const Popup = ({ children, type }: Props) => {
  let style: string = "";
  switch (type) {
    case "default":
      style = style + " bg-default-neutral-20";
      break;
    case "filter":
      style = style + " bg-default-neutral-10 shadow-lg";
      break;
    default:
      style = style + " bg-default-neutral-20";
      break;
  }

  return (
    <div className={`px-5 py-1 rounded-[0.625em]  z-20 ${style}`}>
      {children}
    </div>
  );
};

export { Popup, PopupItem };
