import React from "react";

import ComponentViewTable from "./ComponentViewTable";

const ComponentViewTeamsInTable = ({
  displayUserRoleFull,

  dataSet,
}) => {
  // Sets the default HeaderCells
  let headerCells = [
    // {
    //   id: "name",
    //   label: "Name",
    //   numeric: false,
    //   disablePadding: false,
    //   flex: 1,
    // },
    {
      id: "email",
      label: "Email",
      numeric: false,
      disablePadding: false,
      flex: 1,
    },
    {
      id: "unique_id",
      label: "unique_id",
      numeric: false,
      disablePadding: false,
      flex: 1,
    },
    {
      id: "role_id",
      label: "Id",
      numeric: false,
      disablePadding: false,
      flex: 1,
    },
    {
      id: "role",
      label: "Role Name",
      numeric: false,
      disablePadding: false,
      flex: 1,
    },
    {
      id: "date_created",
      label: "Date Joined",
      numeric: true,
      disablePadding: false,
      flex: 1,
    },
  ];

  return (
    <ComponentViewTable
      listType={"Team"}
      displayUserRoleFull={displayUserRoleFull}
      headerCells={headerCells}
      dataSet={dataSet}
    />
  );
};

export default ComponentViewTeamsInTable;
