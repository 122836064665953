import React from "react";

const PageDashboard = () => {
  return (
    <div style={{ textAlign: "center" }}>
      <h1>Welcome to the app!</h1>
      <h3>Please navigate using sidebar links.</h3>
      <p>*Walkthrough page is available!</p>
    </div>
  );
};

export default PageDashboard;
