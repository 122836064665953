export const USERTIP_STATIC_URL = "https://static.usertip.com";

/** PLAN TIER SUBSCRIPTION CHOICES for the user */
export const PLAN_TIER_SUBSCRIPTION = {
  FREE1: "FREE1",
  PAID1: "PAID1",
  PAID2: "PAID2",
};

/** Full list of plan tiers for the user */
export const PLAN_TIER_LIST = {
  FREE1: "FREE1",
  FREE2: "FREE2",
  FREE3: "FREE3",
  PAID1: "PAID1",
  PAID2: "PAID2",
  FREE_UNLIMITED: "FREE_UNLIMITED",
  LIFETIME_EARLY_ADOPTER: "LIFETIME_EARLY_ADOPTER",
};

export const EXTENTION_LAUNCHER_ID = "aokmpcobffhkkfmafjacenckmgmacamg";
export const EXTENTION_BUILDER_ID = "doihhbnmkgcbfclnikmgdaldnoaogade";
