import * as d3 from "d3";
import { startOfWeek, startOfMonth } from "date-fns";

interface FunctionStackedBarWithLineProps {
  marginTop?: number;
  marginRight?: number;
  marginBottom?: number;
  marginLeft?: number;

  yLabelMargin?: number;

  line?: boolean;

  yLine: string;
  y2Line: string;
  y1Label: string;
  y2Label: string;
  y3Label: string;

  xValue: any[];
  y1Value: any[];
  y2Value: any[];
  y3Value: any[];
  yLineValue: any[];
  y2LineValue: any[];

  yLineColor?: string;
  y2LineColor?: string;
  y1Color?: string;
  y2Color?: string;
  y3Color?: string;

  width?: number;
  height?: number;
  xRange?: [number, number];

  viewBy?: "daily" | "monthly" | "weekly" | string;

  xScaleRange: any[];
}

export function stackedBarWithLine({
  marginTop = 10,
  marginRight = 30,
  marginBottom = 30,
  marginLeft = 30,

  yLabelMargin = 20, // margin spacing for yAxis labels

  line = true,

  yLine,
  y2Line,
  y1Label,
  y2Label,
  y3Label,

  xValue = [], //Date
  y1Value = [], //completed data
  y2Value = [], //dismissed data
  y3Value = [], //incomplete data
  yLineValue = [], // seen data
  y2LineValue = [], // repeat launches

  y2LineColor = "#5E46C1", //repeat value
  yLineColor = "#333357", //seen color
  y1Color = "#9179F4", //completed color
  y2Color = "#FB8C00", //dismissed color
  y3Color = "#CCCCD5", //incomplete

  width = 1056,
  height = 400,
  xRange = [marginLeft + 10, width - marginRight - marginRight], // [left, right]

  viewBy, // viewBy value, only needed for time series

  xScaleRange, //the xAxis scales, can be Date or numbers
}: FunctionStackedBarWithLineProps): HTMLDivElement | null {
  /** Set true range for xScale */
  let trueXScaleRange: any[] | Iterable<Date | d3.NumberValue>;
  if (viewBy === "daily") {
    trueXScaleRange = d3.extent(xScaleRange);
  } else if (viewBy === "weekly") {
    // if startDate is not a Sunday, we need to re-set the scale range to be the previous sunday
    let trueStartDate = startOfWeek(xScaleRange[0]);
    let trueEndDate = startOfWeek(xScaleRange[1]);
    trueXScaleRange = d3.extent([trueStartDate, trueEndDate]);
  } else if (viewBy === "monthly") {
    let trueStartDate = startOfMonth(xScaleRange[0]);
    let trueEndDate = startOfMonth(xScaleRange[1]);
    trueXScaleRange = d3.extent([trueStartDate, trueEndDate]);
  } else {
    trueXScaleRange = d3.extent(xScaleRange);
  }

  //svg
  const anchor = d3
    .create("div")
    .attr("id", "stacked-chart-anchor")
    .style("overflow", "auto")
    .attr("style", "max-width: 100%; height: auto; height: intrinsic;");

  const div = d3
    .select(anchor.node())
    .append("div")
    .attr("class", "tooltip")
    .style("opacity", 0)
    .style("overflow", "auto");

  const svg = d3
    .select(anchor.node())
    .append("svg")
    .attr("width", width)
    .attr("height", height)
    .attr("viewBox", [0, 0, width, height])
    .style("overflow", "visible")
    .style("z-index", 1);

  //processed the data if undefined
  const checkIfUndefined = (value: any) => {
    return value.map((d: any) => {
      if (d === undefined) {
        return 0;
      } else {
        return d;
      }
    });
  };

  y1Value = checkIfUndefined(y1Value);
  y2Value = checkIfUndefined(y2Value);
  y3Value = checkIfUndefined(y3Value);

  // setting the scaling
  const maxYDomain = [...y1Value, ...y2Value, ...y3Value, ...yLineValue];

  //scaling for line chart
  let scaleType = d3
    .scaleTime()
    // @ts-ignore
    .domain(trueXScaleRange)
    // @ts-ignore
    .range([marginLeft + yLabelMargin, width - marginRight])
    .nice();

  // @ts-ignore
  const xScale = scaleType;
  const yScale = d3
    .scaleLinear()
    .domain([0, d3.max(maxYDomain)])
    .range([height - marginBottom, marginTop]);

  /** Set the scale values/position for the bar chart */
  // @ts-ignore
  const xBarScale = d3
    .scaleBand()
    .domain(xValue)
    .range([marginLeft + yLabelMargin, width - marginRight])
    .paddingInner(0.25)
    .paddingOuter(0.5);

  // Add Y axis
  const yBarScale = d3
    .scaleLinear()
    .domain([0, d3.max(maxYDomain)])
    .range([height - marginBottom, marginTop]);

  // if the y data more then 10 divide it by 2
  const yTicks = d3.max(maxYDomain) > 10 ? 10 : d3.max(maxYDomain);

  const yAxis = d3.axisLeft(yScale).ticks(yTicks).tickSize(-width);

  // set position of y-axis labels
  const yAxisGroup = svg
    .append("g")
    .call(yAxis)
    .attr("transform", `translate(${yLabelMargin}, 0)`);

  yAxisGroup.select(".domain").remove();
  yAxisGroup.selectAll("line").attr("stroke", "rgba(145, 121, 245, 0.5)");

  // @ts-ignore
  return Object.assign(anchor.node(), {
    //@ts-ignore
    update({ activeLegends = [], format = "daily", handleClickChart } = {}) {
      const timeFormat =
        format === "weekly"
          ? [d3.timeSunday, d3.timeFormat("%d %b %y")]
          : format === "daily"
          ? [d3.timeDay, d3.timeFormat("%d%b%y")]
          : [d3.timeMonth, d3.timeFormat("%b%y")];

      let xAxis = d3
        // @ts-ignore
        .axisBottom(xScale)
        // .tickValues(xValue)
        .ticks(timeFormat[0])
        // @ts-ignore
        .tickFormat(timeFormat[1])
        .tickSizeOuter(0);

      //@ts-ignore
      if (xScale.ticks(timeFormat[0]).length >= 10) {
        /** set position of xAxis line */
        const xAxisGroup = svg
          .append("g")
          //@ts-ignore
          .call(xAxis ? xAxis : xScale)
          .attr("transform", `translate(0, ${height - marginBottom})`)
          .selectAll("text")
          .attr("transform", "translate(-10,0)rotate(-45)")
          .style("text-anchor", "end")
          .style("font-size", "12px");
      } else {
        /** set position of xAxis line */
        const xAxisGroup = svg
          .append("g")
          //@ts-ignore
          .call(xAxis ? xAxis : xScale)
          .attr("transform", `translate(0, ${height - marginBottom})`)
          .selectAll("text")
          .style("font-size", "12px");
      }

      // set font size of label
      //   xAxisGroup.selectAll(".tick text").attr("font-size", "9px");

      //process the data
      /* toggle line charts for total seen */
      // @ts-ignore
      activeLegends.includes(yLine.toLowerCase())
        ? (line = true)
        : (line = false);

      /* toggle line chart for total repeat launch */
      const combinedRepeatData = xValue.map((data, i) => ({
        x: data,
        y: y2LineValue[i],
      }));

      /* if list of legends doesn't include "repeat views change it value to 0" */
      combinedRepeatData.forEach((d) => {
        //@ts-ignoree
        !activeLegends.includes(y2Line.toLowerCase()) && (d.y = 0);
      });

      // toggle the stack bars
      const combinedData = xValue.map((data, i) => ({
        x: data,
        [y1Label]: y1Value[i],
        [y2Label]: y2Value[i],
        [y3Label]: y3Value[i],
      }));

      /* if legends is not active/false make label value 0 */
      combinedData.forEach((d, i) => {
        // @ts-ignore
        !activeLegends.includes(y1Label!.toLowerCase()) && (d[y1Label] = 0);
        // @ts-ignore
        !activeLegends.includes(y2Label!.toLowerCase()) && (d[y2Label] = 0);
        // @ts-ignore
        !activeLegends.includes(y3Label!.toLowerCase()) && (d[y3Label] = 0);
      });

      //stacked bar
      const subgroups = [y1Label, y2Label, y3Label];
      const subgroupColors = [y1Color, y2Color, y3Color];

      // color palette = one color per subgroup
      const color = d3.scaleOrdinal().domain(subgroups).range(subgroupColors);

      const stackedData = d3.stack().keys(subgroups)(combinedData);

      // Three function that change the tooltip when user hover / move / leave a cell
      // @ts-ignore
      const mouseover = function (event, d) {
        // @ts-ignore
        const subgroupName = d3.select(this.parentNode).datum().key;
        const subgroupValue = d.data[subgroupName];

        //generate percen
        const generatePercentage = (
          activeData: number,
          a: number,
          b: number,
          c: number
        ) => {
          c === undefined && (c = 0);
          a === undefined && (a = 0);
          b === undefined && (b = 0);
          return (activeData / (a + b + c)) * 100;
        };

        div
          .html(
            `${generatePercentage(
              subgroupValue,
              d.data[y1Label],
              d.data[y2Label],
              d.data[y3Label]
            ).toFixed(1)}% (${subgroupValue} ${subgroupName})`
          )
          .style("opacity", 1)
          .style("left", event.pageX + "px")
          .style("top", event.pageY - 28 + "px");
      };

      // @ts-ignore
      const mouseleave = function (event, d) {
        div.style("opacity", 0);
      };

      // // Show the bars
      svg
        .append("g")
        .selectAll("g")
        // Enter in the stack data = loop key per key = group per group
        .data(stackedData)
        .join("g")
        // @ts-ignore
        .attr("fill", (d) => color(d.key))
        .selectAll("rect")
        // enter a second time = loop subgroup per subgroup to add all rectangles
        .data((d) => {
          return d;
        })
        .join("rect")
        .attr("x", (d) => {
          return xScale(d.data.x) - Math.min(xBarScale.bandwidth() / 2, 30) / 2;
        })
        .attr("y", (d) => yBarScale(d[1]))
        .attr("height", (d) => yBarScale(d[0]) - yBarScale(d[1]))
        .attr("width", Math.min(xBarScale.bandwidth() / 2, 30))
        .attr("stroke", "grey")
        //create the custom attribute. will be selected later by onClick Handler
        .attr("data-step", (d) => d.data.x)
        .on("mouseover", mouseover)
        .on("mouseleave", mouseleave);

      /** Add number in the bar chart */
      // svg
      //   .append("g")
      //   .selectAll("g")
      //   .data(stackedData)
      //   .join("g")
      //   .selectAll("text")
      //   .data((d) => d)
      //   .join("text")
      //   .text((d) => {
      //     //if the bar text 0 or the bar height is less then 30 px hide it!
      //     if (d[1] === 0 || yBarScale(d[0]) - yBarScale(d[1]) <= 30) {
      //       return "";
      //     } else {
      //       return d[1] - d[0];
      //     }
      //   })
      //   .attr("text-anchor", "middle")
      //   //put text in the middle of each bar
      //   .attr("y", (d) => {
      //     return yBarScale((d[0] + d[1]) / 2);
      //   })

      //   .attr("x", (d) => {
      //     return xScale(d.data.x);
      //   })
      //   .attr("font-family", "sans-serif")
      //   .attr("font-size", "16px")
      //   .attr("font-weight", "bold")
      //   .attr("fill", "black");

      // const legend = svg
      //   .append("g")
      //   .attr("class", "legend")
      //   .attr("height", 100)
      //   .attr("width", 100)
      //   .attr("transform", "translate(-250,50)");

      // legend
      //   .selectAll("g")
      //   .data(subgroupColors)
      //   .join("circle")
      //   .attr("cx", width + 50)
      //   .attr("cy", (d, i) => i * 20 + 19)
      //   .attr("r", 8)
      //   .attr("fill", (d) => d);

      // legend
      //   .append("circle")
      //   .attr("cx", width + 50)
      //   .attr("cy", subgroupColors.length * 20 + 19)
      //   .attr("r", 8)
      //   .attr("fill", yLineColor);

      // legend
      //   .selectAll("text")
      //   .data(subgroups)
      //   .join("text")
      //   .attr("x", width + 65)
      //   .attr("y", (d, i) => i * 20 + 23)
      //   .style("font-size", "12px")
      //   .text((d) => d);

      // legend
      //   .append("text")
      //   .attr("x", width + 65)
      //   .attr("y", subgroups.length * 20 + 23)
      //   .style("font-size", "12px")
      //   .text(yLine);
      // animation
      //   svg
      //     .selectAll("rect")
      //     .transition()
      //     .duration(800)
      //     .attr("y", (d) => yBarScale(d.Value))
      //     .attr("height", (d) => height - yBarScale(d.Value))
      //     .delay((d, i) => {
      //       console.log(i);
      //       return i * 100;
      //     });

      //create the line if yLine is not empty/undefined
      const lineLocation = 0;

      if (line) {
        const combinedData = xValue.map((d, i) => ({
          x: d,
          y: yLineValue[i],
        }));

        const generateScaledLine = d3
          .line()
          .x((d) => {
            //@ts-ignore
            return xScale(d.x) - lineLocation;
          })
          // @ts-ignore
          .y((d) => yScale(d.y));

        // draw the line
        svg
          .append("path")
          // @ts-ignore
          .attr("d", generateScaledLine(combinedData))
          .attr("fill", "none")
          .attr("stroke", yLineColor)
          .attr("stroke-width", 1.5);

        // adding tooltip for line
        // add the dots with tooltips
        svg
          .selectAll("dot")
          .data(combinedData)
          .join("circle")
          .attr("r", (d) => (d.y === 0 ? 0 : 5))
          .attr("fill", yLineColor)
          // @ts-ignore
          .attr("cx", (d) => {
            return xScale(d.x) - lineLocation;
          })
          .attr("cy", (d) => yScale(d.y))
          .on("mouseover", (event, d) => {
            div.transition().duration(200).style("opacity", 0.9);
            div
              .html(`${yLine} ${d.y}`)
              .style("left", event.pageX + "px")
              .style("top", event.pageY - 28 + "px");
          })
          .on("mouseout", (d) => {
            div.transition().duration(500).style("opacity", 0);
          });
      }

      /* draw line for repeat views */
      const generateScaledLine = d3
        .line()
        .x((d) => {
          //@ts-ignore
          return xScale(d.x) - lineLocation;
        })
        // @ts-ignore
        .y((d) => yScale(d.y));

      // draw the line
      svg
        .append("path")
        // @ts-ignore
        .attr("d", generateScaledLine(combinedRepeatData))
        .attr("fill", "none")
        .attr("stroke", y2LineColor)
        .attr("stroke-width", 1.5);

      // adding tooltip for line
      // add the dots with tooltips
      svg
        .selectAll("dot")
        .data(combinedRepeatData)
        .join("circle")
        .attr("r", (d) => (d.y === 0 ? 0 : 5))
        .attr("fill", y2LineColor)
        // @ts-ignore
        .attr("cx", (d) => {
          return xScale(d.x) - lineLocation;
        })
        .attr("cy", (d) => yScale(d.y))
        .on("mouseover", (event, d) => {
          div.transition().duration(200).style("opacity", 0.9);
          div
            .html(`${y2Line} ${d.y}`)
            .style("left", event.pageX + "px")
            .style("top", event.pageY - 28 + "px");
        })
        .on("mouseout", (d) => {
          div.transition().duration(500).style("opacity", 0);
        });

      /* onClick handler for toggling mini dashboard */
      /* onclick event for clicking tick */
      svg.selectAll(".tick text").on("click", (d) => {
        let currentDate = d.target.innerHTML;
        if (format === "monthly") {
          currentDate = "1 " + d.target.innerHTML;
        }
        const convertDateToString = new Date(currentDate).toString();
        handleClickChart(convertDateToString);
      });

      /* onclick event for clicking bar chart */
      svg.selectAll("g rect").on("click", (d) => {
        const convertDateToString = new Date(
          d.target.getAttribute("data-step")
        ).toString();
        handleClickChart(convertDateToString);
      });
    },
  });
}
