import React from "react";

import { 
  TableHead, TableCell, TableRow, TableSortLabel, 
} from "@mui/material";


const ComponentViewTableHeader = ({
    headerCells,
    
    order, setOrder,
    orderBy, setOrderBy,
}) => {

    // Handles the different types of Sort-Orders to be update for processing
    const handleReSortRows = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };


    return (
        <TableHead>
            <TableRow>
                {
                    // Generate all the Header Cells with Sortable Controls
                    headerCells.map((headerCell, headerIndex) => (
                        <TableCell
                            key={headerIndex}
                            align={headerCell.numeric ? "right" : "left"}
                            padding={headerCell.disablePadding ? "none" : "normal"}
                            sortDirection={orderBy === headerCell.id ? order : false}
                        >
                            {
                                // Display Header with "Sorting Arrow" for all Headers, except "Action", "Tags", "Plugin" & "Code Snippet"
                                (headerCell.id !== "action" && headerCell.id !== "tags" && headerCell.id !== "plugin" && headerCell.id !== "code_snippet") ? (
                                    <TableSortLabel
                                        active={orderBy === headerCell.id}
                                        direction={orderBy === headerCell.id ? order : "asc"}
                                        onClick={(event) => handleReSortRows(event, headerCell.id)}
                                    >
                                        {headerCell.label}
                                        {orderBy === headerCell.id ? (
                                            <span className="hidden">
                                            {order === "desc" ? "sorted descending" : "sorted ascending"}
                                            </span>
                                        ) : null}
                                    </TableSortLabel>
                                ) : (
                                    <>{headerCell.label}</>
                                )
                            }
                        </TableCell>
                    ))
                }
            </TableRow>
        </TableHead>
    )
};

export default ComponentViewTableHeader;