import React, { useState } from "react";

import { Chip } from "@mui/material";
import { Button, InputField } from "usertip-component-library";

import DriveFolderUpload from "@mui/icons-material/DriveFolderUpload";

import { validateEmailFormat } from "../utility/functions/validation";
import { useDispatch } from "react-redux";

const ComponentAddUsersEmailsToChips = ({
  orgID,
  onInviteUserToOrg,
  displayUserRole,

  usersEmails,
  setUsersEmails,
  email,
  setEmail,
  emailError,
  setEmailError,

  handleHideModal,

  handleMassImportClick,

  resetUsersEmailsKeys,

  resetEmailErrorHandler,
  resetEmailHandler,
}) => {
  const dispatch = useDispatch();
  const [confirmBtnDisabled, setConfirmBtnDisabled] = useState(true);

  const emailDefaultInfoText = "*Hit <Enter> to add email to list";
  const emailDefaultInvalidFormatText = "*Invalid email format";

  // User email field change handler.
  const emailChangeHandler = (e) => {
    const value = e.target.value;

    setEmail(value.toLowerCase());
    if (value.trim()) {
      resetEmailErrorHandler();
    }
  };

  // Set user email input field warning.
  const setEmailErrorHandler = (message) => {
    setEmailError({ option: true, message });
  };

  // Validate email input field.
  const validateEmailField = () => {
    const isEmpty = !email.trim();
    const isValidated = validateEmailFormat(email.trim());
    if (isEmpty) {
      setEmailErrorHandler(emailDefaultInfoText);
    } else if (!isValidated) {
      setEmailErrorHandler(emailDefaultInvalidFormatText);
    } else {
      resetEmailErrorHandler();
    }

    return !isEmpty && isValidated;
  };

  // Create walkthrough form submission handler
  const handleAddNewUserRoleToOrgFormSubmission = (e) => {
    e.preventDefault();
    e.stopPropagation();

    // Checks if list of emails contains New Email(s) not in database

    let role = null;
    let invitedAt = null; // SMU FYP ANALYTICS PARAMETER
    // If New Email(s) Not in database Exists, send new user registration invites through email & Add the emails to the list
    if (displayUserRole === "Admin") {
      role = "admin";
      // Asssign timestamp to time invited admin/builder/viewer at
      invitedAt = new Date().toISOString();
    } else if (displayUserRole === "Builder") {
      role = "builder";
      invitedAt = new Date().toISOString();
    } else if (displayUserRole === "Viewer") {
      role = "viewer";
      invitedAt = new Date().toISOString();
    }

    // If the email array is not empty
    if (usersEmails && usersEmails.length > 0) {
      let finalEmailList = [];
      for (let i = 0; i < usersEmails.length; i++) {
        // Forward each new user email invite manually one-by-one, as server does not handle bulk invites at the moment
        // Format to send: orgID, email, role, mfa
        finalEmailList.push(usersEmails[i].userEmail);
      }
      const orgData = {
        orgID: orgID,
        emails: finalEmailList,
        role: role,
        invitedAt: invitedAt,
      };
      dispatch(onInviteUserToOrg(orgData));
      console.log("Orgdata:", orgData);

      /** START OF SMU ANALYTIPS CODE */
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Add any other headers if required
        },
        body: JSON.stringify(orgData),
      };

      // fetch(
      //   "https://hook.eu2.make.com/89uwl61i4br2791wtfnygzggo8i3qnx2",
      //   requestOptions
      // )
      //   .then((response) => {
      //     if (!response.ok) {
      //       throw new Error("Network response was not ok");
      //     }
      //     // Check if response content type is JSON
      //     const contentType = response.headers.get("content-type");
      //     if (contentType && contentType.includes("application/json")) {
      //       return response.json(); // Parse JSON response
      //     } else {
      //       return response.text(); // Return response as text
      //     }
      //   })
      //   .then((data) => {
      //     console.log("orgData sent successfully:", data);
      //   })
      //   .catch((error) => {
      //     console.error("There was a problem sending userdata:", error);
      //   });
      /** END OF SMU ANALYTIPS CODE */
    }
    handleHideModal();
  };

  // Checks if array list is empty, if true, disable Confirm btn
  const verifyIfArrayIsEmptyToDisableConfirmBtn = (usersEmailsArray) => {
    if (usersEmailsArray.length > 0) {
      setConfirmBtnDisabled(false);
    } else {
      setConfirmBtnDisabled(true);
    }
  };

  // Checks if new email already exists in array list
  const verifyIfEmailExistsInUsersEmails = () => {
    for (let i = 0; i < usersEmails.length; i++) {
      // If email already exists in the current arraylist
      if (usersEmails[i].userEmail.includes(email)) {
        return true;
      }
    }
    return false;
  };

  const handleAddNewEmail = (e) => {
    // Only process add email from textfield when "Enter" key is pressed
    if (e.key === "Enter") {
      // Execute check to valid email before adding to array list
      if (validateEmailField()) {
        // If email already exists on list, inform to try new email
        if (verifyIfEmailExistsInUsersEmails()) {
          setEmailErrorHandler(
            "*Email already added to list. Please try again."
          );
        }
        // If email does not exist, add to array list
        else {
          // Reset array list keys count
          resetUsersEmailsKeys();

          // Add new email to array list usersEmails if verified email is of valid format
          usersEmails.push({
            key: usersEmails.length,
            userEmail: email,
          });

          // Clears textfield after successful add
          resetEmailHandler();
          // Checks if array list is empty and whether to disable Confirm btn. Verify with usersEmails as the active array to check
          verifyIfArrayIsEmptyToDisableConfirmBtn(usersEmails);
        }
      }
    }
  };

  const handleDeleteSelectedEmail = (emailSelectedToDelete) => () => {
    // Remove selected email to delete
    const newUsersEmailsAfterDeletion = usersEmails.filter(
      (emailToDelete) => emailToDelete.key !== emailSelectedToDelete.key
    );
    setUsersEmails(newUsersEmailsAfterDeletion);

    // Reset array list keys count
    resetUsersEmailsKeys();
    // Checks if array list is empty and whether to disable Confirm btn. Verify with newUsersEmailsAfterDeletion as apparently the original array will not be deducted to zero, and it will only work when using newArray newUsersEmailsAfterDeletion
    verifyIfArrayIsEmptyToDisableConfirmBtn(newUsersEmailsAfterDeletion);
  };

  return (
    <>
      <div className="modalHeader">
        Add a new {displayUserRole} to your organization
      </div>

      <div className="modalSubHeader">
        {displayUserRole === "Admin" && (
          <>
            Add a new admin that can build & edit walkthroughs & manage
            organisation wide settings.
          </>
        )}
        {displayUserRole === "Builder" && (
          <>Add a new builder who can build & edit walkthroughs.</>
        )}
        {displayUserRole === "Viewer" && (
          <>Add a new viewer that can view and launch walkthroughs only.</>
        )}
      </div>

      <div className="mt-5 mb-8">
        <Button
          onClick={handleMassImportClick}
          color="primary"
          size="fluid"
          text="Mass Import"
          variant="outlined"
          icon={<DriveFolderUpload />}
          iconLeft={true}
        />
      </div>

      <div className="modalForm">
        <div className="modalChipsListBox">
          <ul className="modalChipsList max-h-60 mb-12 overscroll-contain">
            {usersEmails.map((inputEmail) => {
              let icon;

              return (
                <li key={inputEmail.key} className="modalChip">
                  <Chip
                    icon={icon}
                    label={inputEmail.userEmail}
                    onDelete={handleDeleteSelectedEmail(inputEmail)}
                  />
                </li>
              );
            })}
          </ul>
        </div>

        <div className="mb-4 relative">
          <InputField
            variant="full"
            value={email}
            helperText={emailDefaultInfoText}
            autoFocus
            customWidth="ut-w-full"
            error={emailError.option}
            errorText={emailError.message}
            placeholder="Enter the user's email"
            onKeyDown={handleAddNewEmail}
            onChange={emailChangeHandler}
          />

          {usersEmails.length > 0 && (
            <div className="text-xs absolute right-0 bottom-0 text-gray-600 font-medium">
              Total Emails: {usersEmails.length}
            </div>
          )}
        </div>

        <div className="modalActionBtnBar">
          <Button
            onClick={handleHideModal}
            color="primary"
            size="medium"
            text="Cancel"
            variant="primary"
          />
          <Button
            onClick={handleAddNewUserRoleToOrgFormSubmission}
            color="primary"
            size="medium"
            text="Confirm"
            variant="primary"
            disabled={confirmBtnDisabled}
          />
        </div>
      </div>
    </>
  );
};

export default ComponentAddUsersEmailsToChips;
