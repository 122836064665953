import React from "react";

interface Props {
  stepNumber: number;
}
const StepNumber = ({ stepNumber }: Props) => {
  return (
    <div className="w-10 h-10 rounded-full bg-default-primary flex items-center justify-center flex-shrink-0">
      <span className="text-neutral-100 text-lg font-bold">{stepNumber}</span>
    </div>
  );
};

export default StepNumber;
