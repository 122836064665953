import React from "react";

import {
  List,
  Card,
  CardHeader,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
} from "@mui/material";

const ComponentEditListGenerator = ({
  listType,
  numberOfChecked,

  checked,
  setChecked,

  not,
  union,

  title,
  members,

  displayDetails,
}) => {
  // Handles each Toggle of Checkbox-Checked/UnChecked
  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  // Handles Select All Checkboxes
  const handleToggleAll = (members) => () => {
    if (numberOfChecked(checked, members) === members.length) {
      setChecked(not(checked, members));
    } else {
      setChecked(union(checked, members));
    }
  };

  const generateDisplayText = (displayDetails, member) => {
    let displayText = "";
    if (displayDetails === "nameOnly") {
      /** display user name only */
      displayText = `${member.name === undefined ? `` : `${member.name}`}`;
    } else if (displayDetails === "withRole") {
      /** display user name with role */
      const name = member.name ? "Name: " + member.name + " |" : "";
      const role_id = member.role_id ? `Role ID: ${member.role_id}` : ``;
      const role = member.role ? `Role: ${member.role}` : ``;
      const unique_id = member.unique_id
        ? `Unique ID: ${member.unique_id}`
        : ``;
      const email = member.email ? `Email: ${member.email}` : ``;
      displayText = `${name} ${role_id} | ${role} ${
        unique_id ? `| ${unique_id}` : ""
      } ${email ? `| ${email}` : ""}`;
    } else if (displayDetails === "teamMembers") {
      /** display role_id */
      const role_id = member.role_id ? `Role ID: ${member.role_id}` : ``;
      const role = member.role ? `Role: ${member.role}` : ``;
      const unique_id = member.unique_id
        ? `Unique ID: ${member.unique_id}`
        : ``;
      const email = member.email ? `Email: ${member.email}` : ``;
      displayText = `${role_id} | ${role} ${
        unique_id ? `| ${unique_id}` : ""
      } ${email ? `| ${email}` : ""}`;
    } else {
      /** if no displayDetails parameter, use email */
      displayText = `${
        member.name === undefined
          ? `${member.email}`
          : `${member.name} (${member.email})`
      }`;
    }
    // TODO: modal size needs to be bigger
    // minimum of 12 px

    return displayText;
  };

  return (
    <div className="flex-1 w-44">
      <Card>
        <div className="flex flex-col">
          <div className="flex-row flex-grow w-full">
            <CardHeader
              avatar={
                <Checkbox
                  onClick={handleToggleAll(members)}
                  checked={
                    numberOfChecked(checked, members) === members.length &&
                    members.length !== 0
                  }
                  indeterminate={
                    numberOfChecked(checked, members) !== members.length &&
                    numberOfChecked(checked, members) !== 0
                  }
                  disabled={members.length === 0}
                  inputProps={{
                    "aria-label": "all members selected",
                  }}
                />
              }
              title={title}
              subheader={`${numberOfChecked(checked, members)}/${
                members.length
              } selected`}
              sx={{
                padding: "10px 10px",
              }}
            />
          </div>

          <div className="flex-row pageDivider my-0" />

          <div className="w-full">
            <List
              sx={{
                height: 230,
                bgcolor: "background.paper",
                overflow: "auto",
              }}
              dense
              component="div"
              role="list"
            >
              {members.length > 0 &&
                members.map((member) => {
                  let identifier = "";
                  // Identifying different dataType based on "listType" input.
                  if (listType === "WalkthroughViewerTeamPermissions") {
                    identifier = member.role_id;
                  } else {
                    if (member.email) {
                      identifier = member.email;
                    }
                    // Defaults unique identifier to "id"
                    else {
                      identifier = member.id;
                    }
                  }

                  const labelId = `transfer-list-all-item-${identifier}-label`;

                  return (
                    <div key={identifier}>
                      <ListItem
                        role="listitem"
                        button
                        onClick={
                          // Must push the entire Member Object in for the list to work
                          handleToggle(member)
                        }
                        sx={{
                          padding: "0px 10px",
                        }}
                      >
                        <ListItemIcon>
                          <Checkbox
                            checked={
                              // Using the entire Member Object as a Index check
                              checked.indexOf(member) !== -1
                            }
                            tabIndex={-1}
                            disableRipple
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          id={labelId}
                          primary={generateDisplayText(displayDetails, member)}
                          primaryTypographyProps={{ fontSize: "0.75rem" }}
                        />
                      </ListItem>
                    </div>
                  );
                })}

              <ListItem />
            </List>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default ComponentEditListGenerator;
