import { Box, ToggleButton, Typography } from "@mui/material";
import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
import React from "react";

interface Props {
  setSelectedLegends: React.Dispatch<React.SetStateAction<string[]>>;
  selectedLegends: string[];
}

const ComponentToggleLegendStep = ({
  setSelectedLegends,
  selectedLegends,
}: Props) => {
  const handleSelect = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    value: string
  ) => {
    // console.log(value);
    if (selectedLegends.includes(value)) {
      let array = selectedLegends.filter((item) => item !== value);

      setSelectedLegends([...array]);
    } else {
      selectedLegends.push(value);
      let array = selectedLegends;
      setSelectedLegends([...array]);
    }
  };

  const checkSelected = (value: string) => {
    if (selectedLegends.length > 0 && selectedLegends.includes(value)) {
      return true;
    } else {
      return false;
    }
  };
  // console.log(selectedLegends);
  return (
    <div className="flex justify-center mt-8">
      <ToggleButton
        value="completed"
        selected={checkSelected("completed")}
        onChange={handleSelect}
        sx={{
          margin: "6px",
        }}
      >
        <Box
          className="border-2 border-zinc-900 mx-0.5"
          sx={{
            backgroundColor: "#9179F4",
            width: "1.5em",
            height: "1.5em",
          }}
        />
        <Typography>Completed</Typography>
      </ToggleButton>
      <ToggleButton
        value="dismissed"
        selected={checkSelected("dismissed")}
        onChange={handleSelect}
        sx={{
          margin: "6px",
        }}
      >
        <Box
          className="border-2 border-zinc-900 mx-0.5"
          sx={{
            backgroundColor: "#FB8C00",
            width: "1.5em",
            height: "1.5em",
          }}
        />
        <Typography>Dismissed</Typography>
      </ToggleButton>
      <ToggleButton
        value="incomplete"
        selected={checkSelected("incomplete")}
        onChange={handleSelect}
        sx={{
          margin: "6px",
        }}
      >
        <Box
          className="border-2 border-zinc-900 mx-0.5"
          sx={{
            backgroundColor: "#CCCCD5",
            width: "1.5em",
            height: "1.5em",
          }}
        />
        <Typography>Incomplete</Typography>
      </ToggleButton>
      <ToggleButton
        value="launched"
        selected={checkSelected("launched")}
        onChange={handleSelect}
        sx={{
          margin: "6px",
        }}
      >
        <TimelineOutlinedIcon className="mx-0.5" sx={{ color: "#333357" }} />
        <Typography>Launched</Typography>
      </ToggleButton>
    </div>
  );
};

export default ComponentToggleLegendStep;
