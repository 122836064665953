import React, { useEffect, useState } from "react";
import { PictureGuideData } from "../../../interfaces/interfaceGuide";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import PictureViewer from "../../03-organisms/05-pictureViewer/PictureViewer";
import { GETSingleSnapImageCall } from "../../../axios/v2/callsQuickBuilder";
import { getImageListFromPresign } from "../../../axios/v2/callsMedia";
import { DetailedImgObj } from "../../../store/reducers/reducerMedia";

const PrivatePictureViewer = () => {
  const [pictureStep, setPictureStep] = useState<PictureGuideData[]>();
  const pictureGuideData = useSelector(
    (state: RootState) => state.guide.snapGuide
  );

  /** Useeffect for converting raw json data into picture data.)
   */
  useEffect(() => {
    console.log(pictureGuideData, "pictureGuideData");
    // @ts-ignore
    const data: PictureGuideData[] = pictureGuideData
      ? pictureGuideData.snap_component.map(async (item, index) => {
          const getImage = item.media_id
            ? await retrieveImageForStep(item.media_id)
            : false;

          if (item.content_type === "image" && !getImage) {
            throw new Error("Could not load the image");
          }

          return {
            ...(item.content_type === "image" &&
              getImage && {
                screen_capture_details: {
                  // image_url: item.screen_capture_details.image_url,
                  image_url: getImage!.img_url,
                  image_name: getImage!.img_name,
                },
              }),
            timeStamp: item.click_metadata?.timestamp
              ? item.click_metadata.timestamp
              : "",
            textContent: item.description ? item.description : item.content,
            position: {
              x: item.click_metadata?.position?.x
                ? item.click_metadata?.position?.x
                : 0,
              y: item.click_metadata?.position?.y
                ? item.click_metadata?.position?.y
                : 0,
            },
            id: item.snap_component_id,
          };
        })
      : [];

    Promise.all(data).then((res) => {
      setPictureStep(res);
    });
  }, []);

  console.log(pictureStep, "pictureStep");
  return (
    <div className=" h-full px-20 py-3 flex flex-col gap-3 ">
      {pictureStep?.map(
        (
          { screen_capture_details, id, timestamp, position, textContent },
          index
        ) => {
          return (
            <div
              className="justify-center items-center flex gap-2 flex-col"
              key={id}
            >
              <PictureViewer
                imageUrl={screen_capture_details?.image_url}
                key={id}
                index={index + 1}
                position={position}
                editMode={false}
                id={id}
                elementText={textContent}
              />
            </div>
          );
        }
      )}
    </div>
  );
};

const retrieveImageForStep = async (image_id: string) => {
  /**Get the presigned url */
  const response = await GETSingleSnapImageCall(image_id);
  if (response.data.payload.mediaObject) {
    const imageData = response.data.payload.mediaObject;
    /**Get the image from presigned url */
    const imgFile = await getImageListFromPresign(imageData.presignedUrl);

    /**Data is Image informations */
    const data: DetailedImgObj = {
      img_name: imageData.name,
      /**Convert the image to URL  */
      img_url: URL.createObjectURL(imgFile),
      media_id: imageData.mediaId,
      owner_email: imageData.ownerEmail,
      date_uploaded: imageData.dateuploaded,
      date_modified: imageData.datemodified,
    };
    return data;
  } else {
    return null;
  }
};
export default PrivatePictureViewer;
