import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ComponentAlertAppOverallSnackbar from "../components/ComponentAlertAppOverallSnackbar";

import ComponentListUsers from "../components/ComponentListUsers";
import ComponentLoading from "../components/ComponentLoading";
import {
  organizationGetOrganizationInfo,
  resetAlert,
} from "../store/reducers/reducerOrganization";
import { RootState } from "../store";
import TableOrgBuilder from "../components/Table/TableOrgBuilder";

const PageOrganizationBuilder = () => {
  //state from redux
  const { userData } = useSelector((state: RootState) => state.user);
  const orgID = userData.org;

  const { builders, emailInviteMsg, appLoading, alert } = useSelector(
    (state: RootState) => state.org
  );

  console.log(builders);

  const dispatch = useDispatch();

  useEffect(() => {
    // If user is logged in, who has a org in state.user.userData, initiate a getOrganizationInformation to pull data of existing organization to tally
    if (orgID) {
      dispatch(organizationGetOrganizationInfo(orgID));
    }
  }, [dispatch, orgID]);

  useEffect(() => {
    if (emailInviteMsg && orgID) {
      dispatch(organizationGetOrganizationInfo(orgID));
    }
  }, [emailInviteMsg, dispatch, orgID]);

  return (
    <div className="pageBase">
      <ComponentLoading show={appLoading} />
      {/*Builder list*/}
      {/* <ComponentListUsers
        displayUserRole={"Builder"}
        displayUserRoleFull={"Builder"}
        displayShowAddBtn={true}
        introMsg={
          "Builders are able to edit and publish walkthroughs. They can only add viewers to an organization."
        }
        dataSet={builders}
        indicatorMsg={"No builders found. Please add one!"}
        metaDataTypes={null}
      /> */}
      {alert.type !== "" &&
        alert.type !== undefined &&
        alert.type !== "success" && (
          <ComponentAlertAppOverallSnackbar
            alert={alert}
            reset={() => dispatch(resetAlert())}
          />
        )}
      {/* NEW TABLE COMPONENT */}
      <TableOrgBuilder />
    </div>
  );
};

export default PageOrganizationBuilder;
