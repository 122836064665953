import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import store from "../index";
import {
  callGETRetrieveAnOrganizationInformation,
  callGetViewerCodeSnippet,
  callPOSTAddAdminToOrganization,
  callPOSTAddBuilderToOrganization,
  callPOSTAddViewerToOrganization,
  callPOSTChangeUserRoleInOrganization,
  callPostConfirmJoinOrganization,
  callPOSTInviteUserToOrganization,
  callPOSTRemoveUserFromOrganization,
  callPOSTUpdateAssistantAllowedPages,
  callPOSTUpdateAssistantExlcludedPages,
  callPOSTUpdateAssistantPosition,
  callPOSTUpdateAssistantPublish,
} from "../../axios/v2/callsOrganization";
import {
  callDeleteShareWalkthroughToGuest,
  callGETGroupedOrgAndWalkthrough,
  callGETHostOrgAndWalkthroughList,
  callGetUserHostAccess,
  callPostEditSharedWalkthrough,
  callPostShareWalkthroughToGuest,
} from "../../axios/v2/callsHostOrganization";
import {
  callGETHostListWalkthrough,
  callPostDuplicateWalkthrough,
  callPostUpdateStatus,
} from "../../axios/v2/callGuestOrganization";
import {
  RoleList,
  GuestList,
  GuestWalkthrough,
  HostWalkthrough,
  Walkthrough,
  WalkthroughList,
  OrgPlan,
  OrgPlanUsage,
} from "../../interfaces/interface Organization";
import { setPaymentInformation } from "./reducerPayment";
//action
// Fetch organization information
export interface InviteToOrg {
  orgID: string;
  emails: [];
  role: string;
}

interface UserOrg {
  orgID: string;
  userID: string;
}

interface Widget {
  org_id: string;
  publish?: boolean;
  position?: string;
}

interface Role {
  org_id: string;
  new_role: string;
  users: [];
}

interface ShareToGuest {
  host_org_id: string;
  guestOrgList: GuestWalkthrough[];
  walkthroughList: Walkthrough[];
}

interface DeleteSharedWalkthrough {
  host_org_id: string;
  guest_org_name: string;
  guest_org_id: string;
  walkthroughList: Walkthrough[];
}

export interface EditSharedWalkthrough {
  hostOrgID: string;
  guestOrgName: string;
  guestOrgId: string;
  walkthroughList: Walkthrough[];
}

interface DuplicateWalkthrough {
  org_id: string;
  owner: string;
  duplicateWalkthrough: Walkthrough[];
}

export interface APIGetHost {
  msg: string;
  payload: Payload[];
}

export interface Payload {
  hostListWalkthrough: HostWalkthrough[];
}

// create async thunk
export const organizationGetOrganizationInfo = createAsyncThunk<any, string>(
  "organization/getOrg",
  async (orgID, thunkApi) => {
    try {
      console.log("dispatch organizationGetOrganizationInfo");
      const response = await callGETRetrieveAnOrganizationInformation(orgID);
      if (response.data) {
        const result = response.data.payload;
        const { payment_info, org_plan } = result as any;
        store.dispatch(setOrgPlanInformation({ orgPlan: org_plan }));
        store.dispatch(setPaymentInformation({ payment_info }));
        return result;
      } else {
        store.dispatch(getOrganizationError(response));
      }
    } catch (err) {
      console.log("error");
      console.log(err);
      return thunkApi.rejectWithValue(err);
      // API error messages will only be captured at calls-API function. Thus, error captures will be exectued there. Errors captured here are only when reply throws a 500 error that results in an empty 'result' variable, which will throw to this error exception
      // Catches sign in errors thrown from server
      /*
        // Disabling as this last catch will disable custom error handling for registration errors
        dispatch({
        type: actionTypes.REGISTRATION_ERROR,
        errorMsg: "Registration error with server. Please try again."
        })
      */
    }
  }
);

// Submit invite new user to organization to server.
export const organizationPostInviteUserToOrganization = createAsyncThunk(
  "organization/postInviteUserToOrg",
  async (byInviteUserData: InviteToOrg, thunkApi) => {
    const { orgID, emails, role } = byInviteUserData;
    try {
      const response = await callPOSTInviteUserToOrganization(
        orgID,
        emails,
        role
      );
      if (response.data) {
        const result = {
          data: response.data,
          msg: response.data.msg,
        };
        return result;
      } else {
        store.dispatch(sendOrganizerError(response));
      }
    } catch (err: any) {
      const errStatus = err.response.status; // Integer
      const errPayload = err.response.data;
      const errMsg = errPayload.msg;

      console.log(errStatus);
      console.log(errPayload);
      console.log(errMsg);

      const res = {
        alert: {
          type: "error",
          message: errMsg.toString(),
        },
      };

      return thunkApi.rejectWithValue(res);
    }
  }
);

// remove a user from an organization
export const removeUserFromOrganization = createAsyncThunk(
  "organization/removeUserFromOrg",
  async (orgData: UserOrg, thunkApi) => {
    const { orgID, userID } = orgData;
    try {
      await callPOSTRemoveUserFromOrganization(orgID, userID);
      console.log("user organization get removed");
      store.dispatch(organizationGetOrganizationInfo(orgID));
    } catch (err: any) {
      const errStatus = err.response.status; // Integer
      const errPayload = err.response.data;
      const errMsg = errPayload.msg;

      console.log(errStatus);
      console.log(errPayload);
      console.log(errMsg);

      const res = {
        alert: {
          type: "error",
          message: err.toString(),
        },
      };

      return thunkApi.rejectWithValue(res);
    }
  }
);

// add an admin to an organization
export const addAdminToOrg = createAsyncThunk(
  "organization/addAdminToOrg",
  async (orgData: UserOrg, thunkApi) => {
    const { orgID, userID } = orgData;
    try {
      await callPOSTAddAdminToOrganization(orgID, userID);
      store.dispatch(organizationGetOrganizationInfo(orgID));
    } catch (err: any) {
      const errStatus = err.response.status; // Integer
      const errPayload = err.response.data;
      const errMsg = errPayload.msg;

      console.log(errStatus);
      console.log(errPayload);
      console.log(errMsg);

      const res = {
        alert: {
          type: "error",
          message: err.toString(),
        },
      };

      return thunkApi.rejectWithValue(res);
    }
  }
);

// add a builder to an organization
export const addBuilderToOrg = createAsyncThunk(
  "organization/addBuilderToOrg",
  async (orgData: UserOrg, thunkApi) => {
    const { orgID, userID } = orgData;
    try {
      await callPOSTAddBuilderToOrganization(orgID, userID);
      console.log("Adding builder to org and reloading");
      store.dispatch(organizationGetOrganizationInfo(orgID));
    } catch (err: any) {
      const errStatus = err.response.status; // Integer
      const errPayload = err.response.data;
      const errMsg = errPayload.msg;

      console.log(errStatus);
      console.log(errPayload);
      console.log(errMsg);

      const res = {
        alert: {
          type: "error",
          message: err.toString(),
        },
      };

      return thunkApi.rejectWithValue(res);
    }
  }
);

// add a viewer to an organization
export const addViewerToOrg = createAsyncThunk(
  "organization/addViewerToOrg",
  async (orgData: UserOrg, thunkApi) => {
    const { orgID, userID } = orgData;
    try {
      await callPOSTAddViewerToOrganization(orgID, userID);
      console.log("Adding viewer to org and reloading");
      store.dispatch(organizationGetOrganizationInfo(orgID));
    } catch (err: any) {
      const errStatus = err.response.status; // Integer
      const errPayload = err.response.data;
      const errMsg = errPayload.msg;

      console.log(errStatus);
      console.log(errPayload);
      console.log(errMsg);

      const res = {
        alert: {
          type: "error",
          message: err.toString(),
        },
      };

      return thunkApi.rejectWithValue(res);
    }
  }
);

// get viewer code snippet
export const organizationGetCodeSnippetViewers = createAsyncThunk<any, string>(
  "organization/getCS",
  async (orgID, thunkApi) => {
    try {
      const res = await callGetViewerCodeSnippet(orgID);
      const result = res.data.payload.org;
      return result;
    } catch (error: any) {
      console.log(error, "err");
      console.log(error.msg);

      const err = {
        errorMsg: error.msg,
      };

      return thunkApi.rejectWithValue(err);
    }
  }
);

// Update Usertip Assistant publish settings
export const updateAssistantPublishSettings = createAsyncThunk(
  "organization/updateAssistantPublishSetting",
  async (orgData: { org_id: string; publish: boolean }, thunkApi) => {
    //@ts-ignore
    const { org_id, publish } = orgData;
    try {
      //@ts-ignore
      const res = await callPOSTUpdateAssistantPublish(org_id, publish);
      const payload = res.data.payload;
      return payload;
    } catch (err: any) {
      const errStatus = err.response.status; // Integer
      const errPayload = err.response.data;
      const errMsg = errPayload.msg;

      console.log(errStatus);
      console.log(errPayload);
      console.log(errMsg);

      const res = {
        alert: {
          type: "error",
          message: errMsg,
        },
      };

      return thunkApi.rejectWithValue(res);
    }
  }
);

// Update Usertip Assistant position settings
export const updateAssistantPositionSettings = createAsyncThunk(
  "organization/updateAssistantPositionSettings",
  async (orgData: { org_id: string; position: string }, thunkApi) => {
    //@ts-ignore
    const { org_id, position } = orgData;
    try {
      const res = await callPOSTUpdateAssistantPosition(org_id, position);
      const result = res.data.payload;
      return result;
    } catch (err: any) {
      const errStatus = err.response.status; // Integer
      const errPayload = err.response.data;
      const errMsg = errPayload.msg;

      console.log(errStatus);
      console.log(errPayload);
      console.log(errMsg);

      const res = {
        alert: {
          type: "error",
          message: errMsg,
        },
      };

      return thunkApi.rejectWithValue(res);
    }
  }
);

// Update Usertip Assistant position list of allowed pages
export const updateAssistantAllowedPages = createAsyncThunk(
  "organization/updateAssistantAllowedPages",
  async (orgData: { org_id: string; allowedPages: string[] }, thunkApi) => {
    //@ts-ignore
    const { org_id, allowedPages } = orgData;
    try {
      const res = await callPOSTUpdateAssistantAllowedPages(
        org_id,
        allowedPages
      );
      const result = res.data.payload;
      return result;
    } catch (err: any) {
      const errStatus = err.response.status; // Integer
      const errPayload = err.response.data;
      const errMsg = errPayload.msg;

      console.log(errStatus);
      console.log(errPayload);
      console.log(errMsg);

      const res = {
        alert: {
          type: "error",
          message: errMsg,
        },
      };

      return thunkApi.rejectWithValue(res);
    }
  }
);

// Update Usertip Assistant position list of excluded pages
export const updateAssistantExcludedPages = createAsyncThunk(
  "organization/updateAssistantExcludedPages",
  async (orgData: { org_id: string; excludedPages: string[] }, thunkApi) => {
    //@ts-ignore
    const { org_id, excludedPages } = orgData;
    try {
      const res = await callPOSTUpdateAssistantExlcludedPages(
        org_id,
        excludedPages
      );
      const result = res.data.payload;
      return result;
    } catch (err: any) {
      const errStatus = err.response.status; // Integer
      const errPayload = err.response.data;
      const errMsg = errPayload.msg;

      console.log(errStatus);
      console.log(errPayload);
      console.log(errMsg);

      const res = {
        alert: {
          type: "error",
          message: errMsg,
        },
      };

      return thunkApi.rejectWithValue(res);
    }
  }
);

// Submit invite new user to organization to server.
export const organizationChangeUserRole = createAsyncThunk(
  "organization/changeUserRole",
  async (groupData: Role, thunkApi) => {
    const { org_id, new_role, users } = groupData;
    try {
      const response = await callPOSTChangeUserRoleInOrganization(
        org_id,
        new_role,
        users
      );

      if (response.data?.success) {
        store.dispatch(organizationGetOrganizationInfo(org_id));
        return;
      }

      if (!response.response.data.success) {
        throw response;
      }
    } catch (err: any) {
      console.log("callPOSTChangeUserRoleInOrganization err", err);
      if (err.response) {
        console.log(err.response.data, "errResponse");
        const result = err.response.data;

        return thunkApi.rejectWithValue(result);
      } else {
        const result = {
          alert: {
            type: "error",
            message: err.toString(),
          },
        };
        return thunkApi.rejectWithValue(result);
      }
    }
  }
);

//get list of guest organization and list of shared walkthrough
export const organizationGetHostWalkthrough = createAsyncThunk<any, string>(
  "organization/getHostWalkthrough",
  async (host_org_id, thunkApi) => {
    try {
      const response = await callGETHostOrgAndWalkthroughList(host_org_id);
      if (!response.data.payload) {
        throw response;
      }
      if (response.data.payload) {
        const result = {
          walkthroughs: response.data.payload[0],
          guests: response.data.payload[1],
        };
        return result;
      }
    } catch (error: any) {
      console.log(error, "err");
      console.log(error.msg);

      // TODO: check the error message to back-end team
      const err = {
        errorMsg: error.msg,
      };

      return thunkApi.rejectWithValue(err);
    }
  }
);

export const organizationGetGroupedOrgAndWalkthroughs = createAsyncThunk<
  any,
  string
>("organization/getGroupedWalkthrough", async (host_org_id, thunkApi) => {
  try {
    const response = await callGETGroupedOrgAndWalkthrough(host_org_id);
    if (!response.data.payload) {
      throw response;
    }
    if (response.data.payload) {
      const { msg, payload } = response.data;
      const result = {
        msg: msg,
        sharedWalkthroughs: payload.SharedWalkthroughs,
      };
      return result;
    }
  } catch (error: any) {
    console.log(error);
    console.log(error.msg);

    // TODO: check the error message to back-end team
    const err = {
      errorMsg: error.msg,
    };

    return thunkApi.rejectWithValue(err);
  }
});

export const organizationPostShareWalkthroughToGuest = createAsyncThunk(
  "organization/shareToGuest",
  async (org_data: ShareToGuest) => {
    const { host_org_id, guestOrgList, walkthroughList } = org_data;
    try {
      const response = await callPostShareWalkthroughToGuest(
        host_org_id,
        guestOrgList,
        walkthroughList
      );
      console.log(response, "ress ");
    } catch (err) {
      console.log(err, "error share walkthrough to guest");
      // TODO: Check error message with the backend team
    }
  }
);

export const organizationDeleteSharedWalkthrough = createAsyncThunk(
  "organization/deleteSharedWalkthrough",
  async (orgData: DeleteSharedWalkthrough) => {
    const { host_org_id, guest_org_name, guest_org_id, walkthroughList } =
      orgData;

    try {
      const response = await callDeleteShareWalkthroughToGuest(
        host_org_id,
        guest_org_name,
        guest_org_id,
        walkthroughList
      );
      console.log(response);
    } catch (err) {
      console.log(err);
    }
  }
);

export const organizationPostEditSharedWalkthrough = createAsyncThunk<
  any,
  EditSharedWalkthrough
>(
  "organization/editSharedWalkthrough",
  async (orgData: EditSharedWalkthrough, thunkApi) => {
    const { hostOrgID, guestOrgName, guestOrgId, walkthroughList } = orgData;

    try {
      const response = await callPostEditSharedWalkthrough(
        hostOrgID,
        guestOrgName,
        guestOrgId,
        walkthroughList
      );
      if (!response) {
        throw response;
      }
      store.dispatch(organizationGetOrganizationInfo(hostOrgID));
    } catch (error) {
      console.log(error);
    }
  }
);

export const organizationGetSharedHostWalkthrough = createAsyncThunk<
  any,
  string
>("organization/getSharedHostWalkthrough", async (orgID, thunkApi) => {
  console.log("call organizationGetSharedHostWalkthrough");
  try {
    const response: any = await callGETHostListWalkthrough(orgID);
    if (!response.data.payload) {
      throw response;
    }
    if (response.data.payload) {
      const result = {
        walkthroughs: response.data.payload.hostListWalkthrough,
      };
      return result;
    }
  } catch (error: any) {
    console.log(error, "err");
    console.log(error.msg);

    // TODO: check the error message to back-end team
    const err = {
      errorMsg: error.msg,
    };

    return thunkApi.rejectWithValue(err);
  }
});

export const organizationPostDuplicateWalkthrough = createAsyncThunk(
  "organization/duplicateWalkthrough",
  async (orgData: DuplicateWalkthrough, thunkApi) => {
    const { org_id, owner, duplicateWalkthrough } = orgData;
    try {
      const response = await callPostDuplicateWalkthrough(
        org_id,
        owner,
        duplicateWalkthrough
      );
      console.log(response);
    } catch (err) {
      console.log(err);
    }
  }
);

export const organizationPostEditWalkthroughStatus = createAsyncThunk<
  any,
  HostWalkthrough[]
>("organization/editWalkthroughStatus", async (orgData, thunkApi) => {
  try {
    const response = await callPostUpdateStatus(orgData);
    console.log(response);
  } catch (err) {
    console.log(err);
  }
});

export const organizationGetAccses = createAsyncThunk<any, string>(
  "organization/getAccess",
  async (orgId, thunkApi) => {
    try {
      const response = await callGetUserHostAccess(orgId);
      const { isHostOrg, isGuestOrg } = response.data.payload;
      const res = {
        isHostOrg: isHostOrg,
        isGuestOrg: isGuestOrg,
      };
      return res;
    } catch (err) {
      console.log(err);
    }
  }
);

type InviteUserParams = {
  role_type: string;
  cognito_id: string;
  email: string;
  new_org_id: string;
  identifier: string;
};
export const orgInviteUserToOrg = createAsyncThunk<any, InviteUserParams>(
  "organization/inviteToOrg",
  async (params, thunkApi) => {
    try {
      const res = await callPostConfirmJoinOrganization(params);
      console.log(res);
    } catch (error) {
      //TODO:add toast when there's an error
      console.log(error);
    }
  }
);
const orgState = {
  errorOrg: "",
  name: null as null | string,
  org_id: null as null | string,
  owner: null as null | string,
  admins: null as RoleList[] | null,
  builders: null as RoleList[] | null,
  viewers: null as RoleList[] | null,
  viewerersCodeSnippet: null as any,
  changeOrgStatus: false,

  can_trial: false as boolean,
  //possible values:  false, "active", "cancelled", "trialing", "pending"
  paid_status: false as
    | boolean
    | "active"
    | "cancelled"
    | "trialing"
    | "pending",
  trialEndDate: null as null | string,

  isHost: false,
  guests: {} as GuestList,
  sharedWalkthroughs: [] as GuestWalkthrough[],

  isGuest: false,
  sharedHostWalkthrough: [] as HostWalkthrough[],
  hostWalkthroughs: {} as WalkthroughList,

  createdAt: null as null | Date,
  assistant: {
    publish: true,
    position: "center_right",
    /** Pages where the assistant is allowed to display */
    allowedPages: [],
    /** Pages where the assistant is excluded */
    excludedPages: [],
  },
  emailInviteMsg: "",

  appLoading: false,
  alert: {
    active: false,
    type: "",
    message: "",
    autoHideDuration: 6000,
  },
  org_plan: null as null | OrgPlan,
  org_plan_usage: null as null | OrgPlanUsage,
  isInviteFailed: null as any,
  theIndex: 0,
};

const reducerSlice = createSlice({
  name: "organization",
  initialState: orgState,
  reducers: {
    getOrganizationError: (state, action) => {
      if (action.payload.errorMsg !== undefined) {
        console.log(
          `Get Organization Data Error is: ${action.payload.errorMsg}`
        );
      }
      state.errorOrg = action.payload.errorMsg;
    },
    sendOrganizerError: (state, action) => {
      if (action.payload.errorMsg !== undefined) {
        console.log(
          `Get Organization Data Error is: ${action.payload.errorMsg}`
        );
      }
      state.errorOrg = action.payload.errorMsg;
    },
    setChangeOrgStatus: (state, action) => {
      state.changeOrgStatus = action.payload;
    },
    setOrgPlanInformation: (state, action) => {
      // console.log("-=-- redux setOrgPlanInformation", action.payload.orgPlan);
      state.org_plan = { ...action.payload.orgPlan };
    },
    resetAlert: (state) => {
      state.alert.active = false;
      state.alert.type = "";
      state.alert.message = "";
      state.alert.autoHideDuration = 6000;
    },
    setIsInviteFailed: (state, action) => {
      state.isInviteFailed = action.payload;
    },
    setTheIndex: (state, action) => {
      state.theIndex = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(organizationGetOrganizationInfo.pending, (state) => {
        state.appLoading = true;
      })
      .addCase(organizationGetOrganizationInfo.fulfilled, (state, action) => {
        const initialCount = 0;

        const org = action.payload.org;
        // const org_plan = action.payload.org_plan;
        const org_plan_usage = action.payload.org_plan_usage;
        //TODO: Change plan usage auto launch when backend is ready
        org_plan_usage.autoLaunch = 0;
        const ownerList = org.owner;
        ownerList.role = "owner";

        const adminsList = org.admins;
        if (adminsList) {
          for (let i = 0; i < adminsList.length; i++) {
            adminsList[i].role = "admin";
          }
        }

        // Adding Owner into list of Admins as well for referencing
        const adminsListWithRoles = [...adminsList];
        if (ownerList) {
          adminsListWithRoles.push(ownerList);
        }

        // Besides adding role, also included id into the list, for DataGrid display requirements
        let buildersListCount = initialCount;
        const buildersListWithRoles = org.builders;
        if (buildersListWithRoles) {
          for (let i = 0; i < buildersListWithRoles.length; i++) {
            buildersListWithRoles[i].role = "builder";
            buildersListWithRoles[i].id = buildersListCount;
            buildersListCount++;
          }
        }

        let viewersListCount = initialCount;
        const viewersListWithRoles = org.viewers;
        if (viewersListWithRoles) {
          for (let i = 0; i < viewersListWithRoles.length; i++) {
            viewersListWithRoles[i].role = "viewer";
            viewersListWithRoles[i].id = viewersListCount;
            viewersListCount++;
          }
        }

        let adminsListCount = initialCount;
        if (adminsListWithRoles) {
          for (let i = 0; i < adminsListWithRoles.length; i++) {
            adminsListWithRoles[i].id = adminsListCount;
            adminsListCount++;
          }
        }

        //change state
        state.name = org.name;
        state.org_id = org.org_id;
        state.owner = org.owner;

        state.admins = adminsListWithRoles;

        state.builders = buildersListWithRoles;

        state.viewers = viewersListWithRoles;

        state.createdAt = org.createdAt;
        state.emailInviteMsg = "";
        state.assistant = org.assistant;
        // state.org_plan = org_plan;
        state.org_plan_usage = org_plan_usage;
        state.can_trial = action.payload.can_trial;
        // state.can_trial = false;
        state.paid_status = action.payload.paid_status;
        state.trialEndDate = action.payload.payment_info.end_date;

        state.appLoading = false;
      })
      .addCase(
        organizationGetOrganizationInfo.rejected,
        (state, action: any) => {
          const { type, message } = action.payload.alert;
          state.appLoading = false;
          state.alert.active = true;
          state.alert.type = type;
          state.alert.message = message;
          state.alert.autoHideDuration = action.payload.alert.autoHideDuration
            ? action.payload.alert.autoHideDuration
            : state.alert.autoHideDuration;
        }
      )

      .addCase(organizationPostInviteUserToOrganization.pending, (state) => {
        state.appLoading = true;
      })
      .addCase(
        organizationPostInviteUserToOrganization.fulfilled,
        (state, action) => {
          if (action.payload!.msg !== undefined) {
            console.log(
              `Send Organization Invite Success is: ${action.payload!.msg}`
            );
          }
          console.log("SEND_ORGANIZATION_INVITE_SUCCESS");
          state.emailInviteMsg = action.payload!.msg;
          state.appLoading = false;

          const user = JSON.parse(localStorage.getItem("ut-user")!);

          if (user.is_onboarded) {
            state.alert.active = true;
            state.alert.type = "success";
            state.alert.message = "User invited successfully";
            state.alert.autoHideDuration = state.alert.autoHideDuration;
          }
        }
      )
      .addCase(
        organizationPostInviteUserToOrganization.rejected,
        (state, action: any) => {
          if (action.payload && action.payload.alert) {
            const { type, message } = action.payload.alert;
            state.appLoading = false;
            state.alert.active = true;
            state.alert.type = type;
            state.alert.message = message;
            state.alert.autoHideDuration = action.payload.alert.autoHideDuration
              ? action.payload.alert.autoHideDuration
              : state.alert.autoHideDuration;
          } else {
            // Handle the case where action.payload or action.payload.alert is undefined
            console.error("Invalid action payload or missing alert property.");
            state.isInviteFailed = true;
            state.appLoading = false;
          }
        }
      )
      .addCase(organizationGetCodeSnippetViewers.pending, (state) => {
        state.appLoading = true;
      })
      .addCase(organizationGetCodeSnippetViewers.fulfilled, (state, action) => {
        state.viewerersCodeSnippet = action.payload;
        state.appLoading = false;
      })
      .addCase(organizationGetCodeSnippetViewers.rejected, (state) => {
        state.appLoading = false;
      })
      .addCase(updateAssistantPublishSettings.pending, (state) => {
        state.appLoading = true;
      })
      .addCase(updateAssistantPublishSettings.fulfilled, (state, action) => {
        state.assistant.publish = action.payload.assistant.publish;
        state.appLoading = false;

        state.alert = {
          active: true,
          type: "success",
          message: "Assistant control settings updated successfully.",
          autoHideDuration: 6000,
        };
      })
      .addCase(updateAssistantPublishSettings.rejected, (state, action) => {
        //@ts-ignore
        const { type, message } = action.payload.alert;
        state.appLoading = false;
        state.alert.active = true;
        state.alert.type = type;
        state.alert.message = message;
        //@ts-ignore
        state.alert.autoHideDuration = action.payload.alert.autoHideDuration
          ? //@ts-ignore
            action.payload.alert.autoHideDuration
          : state.alert.autoHideDuration;
      })

      .addCase(updateAssistantPositionSettings.pending, (state) => {
        state.appLoading = true;
      })
      .addCase(updateAssistantPositionSettings.fulfilled, (state, action) => {
        state.appLoading = true;
        state.assistant.position = action.payload.assistant.position;
        state.alert = {
          active: true,
          type: "success",
          message: "Assistant control settings updated successfully.",
          autoHideDuration: 6000,
        };
      })
      .addCase(updateAssistantPositionSettings.rejected, (state, action) => {
        //@ts-ignore
        const { type, message } = action.payload.alert;
        state.appLoading = false;
        state.alert.active = true;
        state.alert.type = type;
        state.alert.message = message;
        //@ts-ignore
        state.alert.autoHideDuration = action.payload.alert.autoHideDuration
          ? //@ts-ignore
            action.payload.alert.autoHideDuration
          : state.alert.autoHideDuration;
      })
      /** updateAssistantAllowedPages */
      .addCase(updateAssistantAllowedPages.pending, (state) => {
        state.appLoading = true;
      })
      .addCase(updateAssistantAllowedPages.fulfilled, (state, action) => {
        const newAllowedPages = action.payload.assistant.allowedPages;
        const newExcludedPages = action.payload.assistant.excludedPages;
        let alertText = "";

        if (newAllowedPages.length === 0 && newExcludedPages.length === 0) {
          alertText = `Assistant is enabled across all pages.`;
        } else if (
          newAllowedPages.length > 0 &&
          newExcludedPages.length === 0
        ) {
          alertText = "Assistant is enabled ONLY on allowed pages";
        } else if (
          newAllowedPages.length === 0 &&
          newExcludedPages.length > 0
        ) {
          alertText = "Assistant is enabled on all pages EXCEPT excluded pages";
        } else if (newAllowedPages.length > 0 && newExcludedPages.length > 0) {
          alertText =
            "Assistant is enabled ONLY on allowed pages. Excluded pages will not have an assistant displayed.";
        } else {
          alertText = "";
        }

        state.appLoading = true;
        state.assistant.allowedPages = newAllowedPages;
        state.alert = {
          active: true,
          type: "success",
          message: alertText,
          autoHideDuration: 6000,
        };
      })
      .addCase(updateAssistantAllowedPages.rejected, (state, action) => {
        //@ts-ignore
        const { type, message } = action.payload.alert;
        state.appLoading = false;
        state.alert.active = true;
        state.alert.type = type;
        state.alert.message = message;
        //@ts-ignore
        state.alert.autoHideDuration = action.payload.alert.autoHideDuration
          ? //@ts-ignore
            action.payload.alert.autoHideDuration
          : state.alert.autoHideDuration;
      })
      /** updateAssistantAllowedPages -- END */
      /** updateAssistantExcludedPages */
      .addCase(updateAssistantExcludedPages.pending, (state) => {
        state.appLoading = true;
      })
      .addCase(updateAssistantExcludedPages.fulfilled, (state, action) => {
        const newAllowedPages = action.payload.assistant.allowedPages;
        const newExcludedPages = action.payload.assistant.excludedPages;
        let alertText = "";

        if (newAllowedPages.length === 0 && newExcludedPages.length === 0) {
          alertText = `Assistant is enabled across all pages.`;
        } else if (
          newAllowedPages.length > 0 &&
          newExcludedPages.length === 0
        ) {
          alertText = "Assistant is enabled ONLY on allowed pages";
        } else if (
          newAllowedPages.length === 0 &&
          newExcludedPages.length > 0
        ) {
          alertText = "Assistant is enabled on all pages EXCEPT excluded pages";
        } else if (newAllowedPages.length > 0 && newExcludedPages.length > 0) {
          alertText =
            "Assistant is enabled ONLY on allowed pages. Excluded pages will not have an assistant displayed.";
        } else {
          alertText = "";
        }

        state.appLoading = true;
        state.assistant.excludedPages = newExcludedPages;
        state.alert = {
          active: true,
          type: "success",
          message: alertText,
          autoHideDuration: 6000,
        };
      })
      .addCase(updateAssistantExcludedPages.rejected, (state, action) => {
        //@ts-ignore
        const { type, message } = action.payload.alert;
        state.appLoading = false;
        state.alert.active = true;
        state.alert.type = type;
        state.alert.message = message;
        //@ts-ignore
        state.alert.autoHideDuration = action.payload.alert.autoHideDuration
          ? //@ts-ignore
            action.payload.alert.autoHideDuration
          : state.alert.autoHideDuration;
      })
      /** updateAssistantExcludedPages -- END */
      .addCase(organizationChangeUserRole.pending, (state) => {
        state.appLoading = true;
      })
      .addCase(organizationChangeUserRole.rejected, (state, action: any) => {
        state.appLoading = false;
        state.alert.active = true;
        state.alert.type = "error";
        state.alert.message = action.payload.msg
          ? action.payload.msg
          : "User does not exist or has not completed registration!";
        state.alert.autoHideDuration = 6000;
      })

      //host organization reducer start here
      .addCase(organizationGetHostWalkthrough.pending, (state) => {
        state.appLoading = true;
      })
      .addCase(
        organizationGetHostWalkthrough.fulfilled,
        (state, action: any) => {
          const { walkthroughs, guests } = action.payload;
          state.guests = guests;
          state.hostWalkthroughs = walkthroughs;
        }
      )
      .addCase(
        organizationGetHostWalkthrough.rejected,
        (state, action: any) => {
          const { type, message } = action.payload;
          state.appLoading = false;
          state.alert.active = true;
          state.alert.type = type;
          state.alert.message = message;
        }
      )
      .addCase(
        organizationGetGroupedOrgAndWalkthroughs.pending,
        (state, action) => {
          state.appLoading = true;
        }
      )
      .addCase(
        organizationGetGroupedOrgAndWalkthroughs.fulfilled,
        (state, action) => {
          state.sharedWalkthroughs = action.payload.sharedWalkthroughs;
          state.appLoading = false;
        }
      )
      .addCase(
        organizationGetGroupedOrgAndWalkthroughs.rejected,
        (state, action: any) => {
          const { type, message } = action.payload.alert;
          state.appLoading = false;
          state.alert.active = true;
          state.alert.type = type;
          state.alert.message = message;
          state.alert.autoHideDuration = action.payload.alert.autoHideDuration
            ? action.payload.alert.autoHideDuration
            : state.alert.autoHideDuration;
        }
      )
      .addCase(organizationPostShareWalkthroughToGuest.pending, (state) => {
        state.appLoading = true;
      })
      .addCase(organizationPostShareWalkthroughToGuest.fulfilled, (state) => {
        console.log("walkthrough has been succesfully shared");
        state.appLoading = false;
      })
      .addCase(
        organizationPostShareWalkthroughToGuest.rejected,
        (state, action: any) => {
          state.appLoading = false;
          const { type, message } = action.payload.alert;
          state.appLoading = false;
          state.alert.active = true;
          state.alert.type = type;
          state.alert.message = message;
          state.alert.autoHideDuration = action.payload.alert.autoHideDuration
            ? action.payload.alert.autoHideDuration
            : state.alert.autoHideDuration;
        }
      )

      .addCase(organizationDeleteSharedWalkthrough.pending, (state) => {
        state.appLoading = true;
      })
      .addCase(organizationDeleteSharedWalkthrough.fulfilled, (state) => {
        state.appLoading = false;
      })
      .addCase(
        organizationDeleteSharedWalkthrough.rejected,
        (state, action) => {
          state.appLoading = false;
          console.log(action);
        }
      )
      .addCase(organizationPostEditSharedWalkthrough.pending, (state) => {
        state.appLoading = true;
      })
      .addCase(organizationPostEditSharedWalkthrough.fulfilled, (state) => {
        state.appLoading = false;
      })
      .addCase(organizationPostEditSharedWalkthrough.rejected, (state) => {
        state.appLoading = false;
      })
      // guest organization start here
      .addCase(organizationGetSharedHostWalkthrough.pending, (state) => {
        state.appLoading = true;
      })
      .addCase(
        organizationGetSharedHostWalkthrough.fulfilled,
        (state, action: any) => {
          state.sharedHostWalkthrough = [...action.payload.walkthroughs];
          state.appLoading = false;
        }
      )
      .addCase(organizationGetSharedHostWalkthrough.rejected, (state) => {
        state.appLoading = true;
      })
      .addCase(organizationPostDuplicateWalkthrough.pending, (state) => {
        state.appLoading = true;
      })
      .addCase(organizationPostDuplicateWalkthrough.fulfilled, (state) => {
        state.appLoading = false;
      })
      .addCase(organizationPostDuplicateWalkthrough.rejected, (state) => {
        state.appLoading = false;
      })
      .addCase(organizationPostEditWalkthroughStatus.pending, (state) => {
        state.appLoading = true;
      })
      .addCase(
        organizationPostEditWalkthroughStatus.fulfilled,
        (state, action) => {
          state.appLoading = false;
        }
      )
      .addCase(organizationPostEditWalkthroughStatus.rejected, (state) => {
        state.appLoading = false;
      })
      .addCase(organizationGetAccses.pending, (state) => {
        state.appLoading = true;
      })
      .addCase(organizationGetAccses.fulfilled, (state, action) => {
        const { isGuestOrg, isHostOrg } = action.payload;
        state.isGuest = isGuestOrg;
        state.isHost = isHostOrg;
        state.appLoading = false;
      })
      .addCase(orgInviteUserToOrg.pending, (state) => {
        state.appLoading = true;
      })
      .addCase(orgInviteUserToOrg.fulfilled, (state) => {
        state.appLoading = false;
        state.changeOrgStatus = true;
      })
      .addCase(orgInviteUserToOrg.rejected, (state) => {
        state.appLoading = true;
      });
  },
});

const { reducer, actions } = reducerSlice;

export const {
  getOrganizationError,
  resetAlert,
  sendOrganizerError,
  setChangeOrgStatus,
  setOrgPlanInformation,
  setIsInviteFailed,
  setTheIndex,
} = actions;

const reducerOrg = reducer;

export default reducerOrg;
