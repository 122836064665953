import React, { FormEventHandler } from "react";

import { useDispatch, useSelector } from "react-redux";
import { UserSegmentation } from "../../interfaces/interfaceSegmentation";
import { listAllUserSegmentation } from "../../store/reducers/reducerUserSegmentation";
import { RootState } from "../../store";
import { EmptyModal, Button } from "usertip-component-library";

interface Props {
  /** List to hold seleted User Segmentation in Page Walkthrough Permission */
  selectedUserSegmentationList: UserSegmentation[];

  onConfirmRemoveUserSegmentation: any;

  showModalRemove: boolean;
  setShowModalRemove: React.Dispatch<React.SetStateAction<boolean>>;

  removeMode: "single" | "bulk" | "";

  setResetTableSelectAll: React.Dispatch<React.SetStateAction<boolean>>;
}

const ComponentRemoveUserSegmentation = ({
  selectedUserSegmentationList,

  onConfirmRemoveUserSegmentation,

  showModalRemove,
  setShowModalRemove,

  removeMode,

  setResetTableSelectAll,
}: Props) => {
  const orgID = useSelector((state: RootState) => state.user.userData.org);

  const dispatch = useDispatch();
  // Handle Removal of selected teams after submission click
  const handleConfirmRemoveUserSegmentation = async (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    // Handles Removal submission where appropriate
    if (removeMode === "single") {
      handleHideModal();
      const userSegmentationId =
        selectedUserSegmentationList[0].segmentation_id;

      console.log("deleting single", userSegmentationId);
      /** Dispatch delete userSegmentation first */
      await dispatch(onConfirmRemoveUserSegmentation(userSegmentationId));
      await dispatch(listAllUserSegmentation(orgID!));
    } else if (removeMode === "bulk") {
      if (selectedUserSegmentationList) {
        handleHideModal();
        for (let i = 0; i < selectedUserSegmentationList.length; i++) {
          const userSegmentationId =
            selectedUserSegmentationList[i].segmentation_id;

          console.log("deleting bulk", userSegmentationId);
          await dispatch(onConfirmRemoveUserSegmentation(userSegmentationId));
        }
        await dispatch(listAllUserSegmentation(orgID!));

        // Resets the "Select All" control on the table.
        setResetTableSelectAll(true);
      }
    }
  };

  // Modal close handler
  const handleHideModal = () => {
    setShowModalRemove(false);
  };

  // Gemerate Somg;e Btm
  const GenerateSingleBtn = ({
    inputList,
  }: {
    inputList: UserSegmentation[];
  }) => (
    <>
      <div className="modalSubHeader">
        WARNING: You have selected <i>“Delete”</i> to remove{" "}
        <b>{`${inputList[0].segmentation_name}`}</b>, please confirm to{" "}
        <i>“Delete”</i>
        or select cancel to return to the previous page.
      </div>
    </>
  );

  // Generate Bulk Btn
  const GenerateBulkBtn = ({
    inputList,
  }: {
    inputList: UserSegmentation[];
  }) => (
    <>
      <div className="modalSubHeader">
        WARNING: You have selected to <i>“Bulk Delete”</i> the following User
        Segmentation(s), please confirm to proceed or select cancel to return to
        the previous page.
      </div>

      <ol className="list-decimal list-inside mb-8">
        {inputList &&
          inputList.map((item) => (
            <li key={item.segmentation_id}>{`${item.segmentation_name}`}</li>
          ))}
      </ol>
    </>
  );

  return (
    // FIXME: proper typescript for ComponentModal
    // @ts-ignore
    <EmptyModal
      isOpen={showModalRemove}
      onClose={handleHideModal}
      size="large"
      customClass="ut-py-5"
    >
      {
        // Process for Remove Teams
        selectedUserSegmentationList !== null &&
          selectedUserSegmentationList !== undefined && (
            <>
              <div className="modalHeader">
                {removeMode === "single"
                  ? `Confirm Removal of User Segmentation?`
                  : removeMode === "bulk"
                  ? `Confirm Removal of Multiple User Segmentation(s)?`
                  : ""}
              </div>

              {removeMode === "single" ? (
                <GenerateSingleBtn inputList={selectedUserSegmentationList} />
              ) : removeMode === "bulk" ? (
                <GenerateBulkBtn inputList={selectedUserSegmentationList} />
              ) : (
                ""
              )}
            </>
          )
      }

      <form
        noValidate
        autoComplete="off"
        onSubmit={handleConfirmRemoveUserSegmentation}
        className="modalForm"
      >
        <div className="w-1/3 flex gap-2 ml-auto">
          <Button
            onClick={handleHideModal}
            variant="primary"
            color="primary"
            size="fluid"
            text="Cancel"
          />
          <Button variant="primary" color="primary" size="fluid" text="Yes" />
        </div>
      </form>
    </EmptyModal>
  );
};

export default ComponentRemoveUserSegmentation;
