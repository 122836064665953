import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  // this is for handling something on navigation when screen smaller than 1240px
  showHideNavbar: false,
};

const navigationSlice = createSlice({
  name: "navigation",
  initialState: initialState,
  reducers: {
    showHideNavbar(state, action) {
      state.showHideNavbar = action.payload;
    },
  },
});

export const { showHideNavbar } = navigationSlice.actions;
const reducerNavigation = navigationSlice.reducer;

export default reducerNavigation;
