import React, { useState } from "react";

import ComponentListTable from "./ComponentListTable";
import ComponentEditWalkthroughsViewingPermissions from "./ComponentEditWalkthroughsViewingPermissions";
import ComponentRemoveUsers from "./ComponentRemoveUsers";
import TableWalkthroughsViewingPermission from "./Table/TableWalkthroughsViewingPermission";

const ComponentListWalkthroughViewingPermissionsInTable = ({
  viewingPermisionsType,

  dataSet,
  permissionDataSet,

  orgID,
  walkthroughID,

  onDeleteOneIndvWalkthroughViewingPermission,
  onHandleBulkEditIndvWalkthroughPermission,
}) => {
  // Variables setup for Modal display
  const [showModalRemove, setShowModalRemove] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);

  const [removeMode, setRemoveMode] = useState("");
  const [editMode, setEditMode] = useState("");

  const [
    selectedWalkthroughViewingPermissionsList,
    setSelectedWalkthroughViewingPermissionsList,
  ] = useState([]);

  const [resetTableSelectAll, setResetTableSelectAll] = useState(false);

  // Sets the default HeaderCells
  let headerCells = [
    {
      id: "name",
      label: "Name",
      numeric: false,
      disablePadding: false,
      flex: 1,
    },
    {
      id: "unique_id",
      label: "Unique ID",
      numeric: false,
      disablePadding: false,
      flex: 1,
    },
    {
      id: "email",
      label: "Email",
      numeric: false,
      disablePadding: false,
      flex: 1,
    },
    {
      id: "role_id",
      label: "Role ID",
      numeric: false,
      disablePadding: false,
      flex: 1,
    },
    {
      id: "role",
      label: "Role",
      numeric: false,
      disablePadding: false,
      flex: 1,
    },
    {
      id: "date_joined",
      label: "Date Joined",
      numeric: false,
      disablePadding: false,
      flex: 1,
    },
    {
      id: "plugin",
      label: "Plugin",
      numeric: false,
      disablePadding: false,
      flex: 1,
    },
    {
      id: "code_snippet",
      label: "Code Snippet",
      numeric: false,
      disablePadding: false,
      flex: 1,
    },
    {
      id: "action",
      label: "Action(s)",
      numeric: true,
      disablePadding: false,
    },
  ];

  return (
    <>
      <ComponentListTable
        listType={"WalkthroughViewingPermissions"}
        headerCells={headerCells}
        dataSet={permissionDataSet}
        setSelectedWalkthroughViewingPermissionsList={
          setSelectedWalkthroughViewingPermissionsList
        }
        setShowModalRemove={setShowModalRemove}
        setShowModalEdit={setShowModalEdit}
        setRemoveMode={setRemoveMode}
        setEditMode={setEditMode}
        resetTableSelectAll={resetTableSelectAll}
        setResetTableSelectAll={setResetTableSelectAll}
      />

      {/* <TableWalkthroughsViewingPermission dataSet={permissionDataSet} /> */}
      {/* PRE-LOAD MODALS */}
      {
        // Only load pre-load Modals when "selectedWalkthroughViewingPermissionsList" is not empty
        selectedWalkthroughViewingPermissionsList.length > 0 ? (
          <>
            {/* Pre-loading Edit Walkthroughs Viewing Permissions Modal */}
            <ComponentEditWalkthroughsViewingPermissions
              dataSet={dataSet}
              permissionDataSet={permissionDataSet}
              walkthroughID={walkthroughID}
              onHandleBulkEditIndvWalkthroughPermission={
                onHandleBulkEditIndvWalkthroughPermission
              }
              showModalEdit={showModalEdit}
              setShowModalEdit={setShowModalEdit}
              editMode={editMode}
            />

            {/* Pre-loading Remove Walkthrough Viewers Modal: Reusing ComponentRemoveUsers as overall uses the same Modal just to display. */}
            <ComponentRemoveUsers
              listType={"WalkthroughViewingPermissions"}
              viewingPermisionsType={viewingPermisionsType}
              selectedUserOrgID={orgID}
              selectedWalkthroughViewingPermissionsList={
                selectedWalkthroughViewingPermissionsList
              }
              onDeleteOneIndvWalkthroughViewingPermission={
                onDeleteOneIndvWalkthroughViewingPermission
              }
              onHandleBulkEditIndvWalkthroughPermission={
                onHandleBulkEditIndvWalkthroughPermission
              }
              showModalRemove={showModalRemove}
              setShowModalRemove={setShowModalRemove}
              removeMode={removeMode}
              setResetTableSelectAll={setResetTableSelectAll}
            />
          </>
        ) : (
          // Pre-loading Modals even if No Rows are selected
          <>
            {/* Pre-loading Edit Walkthroughs Viewing Permissions Modal */}
            <ComponentEditWalkthroughsViewingPermissions
              dataSet={dataSet}
              permissionDataSet={permissionDataSet}
              walkthroughID={walkthroughID}
              onHandleBulkEditIndvWalkthroughPermission={
                onHandleBulkEditIndvWalkthroughPermission
              }
              showModalEdit={showModalEdit}
              setShowModalEdit={setShowModalEdit}
              editMode={editMode}
            />
          </>
        )
      }
    </>
  );
};

export default ComponentListWalkthroughViewingPermissionsInTable;
