import attachHTTPRequestHeaders from "../../utility/functions/attachHTTPRequestHeaders";
import { api } from "./_axios";

/*
    ===================================================================    
    API 2.0: API-Calls To Server
    ===================================================================
    1. api_p1 is default for all API 2.0 calls
    2. api_p2 is now only for /signIn route  
    ===================================================================    
    CALLS-WALKTHROUGH API
    ===================================================================
*/

// DELETE: Delete walkthrough to API call
export const callDELETERemoveWalkthrough = async (walkthrough_id) => {
  const config = attachHTTPRequestHeaders(true);
  return api
    .delete(`/walkthrough/delete/${walkthrough_id}`, config)
    .then((res) => res)
    .catch((err) => {
      return Promise.reject(err);
    });
};

// GET: Get a walkthrough from API call
export const callGETRetrieveWalkthrough = async (walkthrough_id) => {
  const config = attachHTTPRequestHeaders(true);
  return api
    .get(`/walkthrough/${walkthrough_id}`, config)
    .then((res) => res)
    .catch((err) => {
      return Promise.reject(err);
    });
};

// POST: Update tags of a walkthrough to API call
export const callPOSTUpdateTagsOfWalkthrough = async (
  walkthrough_id,
  listOfTags
) => {
  const data = {
    tags: listOfTags,
  };
  const config = attachHTTPRequestHeaders(true);
  return api
    .post(`/walkthrough/tags/${walkthrough_id}`, data, config)
    .then((res) => res)
    .catch((err) => {
      return Promise.reject(err);
    });
};

// POST: Update general settings (name & page_url) of a walkthrough to API call
export const callPOSTUpdateGeneralSettingsOfWalkthrough = async (
  walkthrough_id,
  name,
  target
) => {
  const data = {
    name: name,
    page_url: target,
  };
  const config = attachHTTPRequestHeaders(true);
  return api
    .post(`/walkthrough/settings/general/${walkthrough_id}`, data, config)
    .then((res) => res)
    .catch((err) => {
      return Promise.reject(err);
    });
};

// POST: Update other settings (launch & post_action) of a walkthrough to API call
export const callPOSTUpdateOtherSettingsOfWalkthrough = async (
  walkthrough_id,
  listOfOtherSettings
) => {
  const data = {
    settings: listOfOtherSettings,
  };
  const config = attachHTTPRequestHeaders(true);
  return api
    .post(`/walkthrough/settings/other/${walkthrough_id}`, data, config)
    .then((res) => res)
    .catch((err) => {
      return Promise.reject(err);
    });
};

// POST: Update launch settings of a walkthrough to API call
export const callPOSTUpdateLaunchSettingsOfWalkthrough = async (
  walkthrough_id,
  listOfLaunchSettings
) => {
  const data = {
    launch: listOfLaunchSettings,
  };
  const config = attachHTTPRequestHeaders(true);
  return api
    .post(`/walkthrough/settings/launch/${walkthrough_id}`, data, config)
    .then((res) => res)
    .catch((err) => {
      return Promise.reject(err);
    });
};

// POST: Update post_action settings of a walkthrough to API call
export const callPOSTUpdatePostActionSettingsOfWalkthrough = async (
  walkthrough_id,
  listOfPostActionSettings
) => {
  const data = {
    post_action: listOfPostActionSettings,
  };
  const config = attachHTTPRequestHeaders(true);
  return api
    .post(`/walkthrough/settings/post_action/${walkthrough_id}`, data, config)
    .then((res) => res)
    .catch((err) => {
      return Promise.reject(err);
    });
};

// GET: Get all walkthroughs by user from API call
export const callGETRetrieveAllWalkthroughsByUser = async (walkthroughData) => {
  const { userID, page, perPage } = walkthroughData;
  const config = attachHTTPRequestHeaders(true);
  const data = {
    page: page,
    perPage: perPage,
  };
  return api
    .post(`/walkthrough/getWalkthroughs/${userID}`, data, config)
    .then((res) => res)
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const callGetRestOFWalkthroughs = async (userID) => {
  const data = {};
  const config = attachHTTPRequestHeaders(true);
  return api
    .post(`/walkthrough/getWalkthroughs/${userID}`, data, config)
    .then((res) => res)
    .catch((err) => {
      return Promise.reject(err);
    });
};

// GET: Get summarized walkthrough information by user from API call
export const callGETRetrieveSummaryOfWalkthroughByUser = async (cognito_id) => {
  const config = attachHTTPRequestHeaders(true);
  return api
    .get(`/walkthrough/getSummary/${cognito_id}`, config)
    .then((res) => res)
    .catch((err) => {
      return Promise.reject(err);
    });
};

// GET: Get published walkthroughs by user from API call
export const callGETRetrievePublishedWalkthroughsByUser = async (user_id) => {
  const config = attachHTTPRequestHeaders(true);
  return api
    .get(`/walkthrough/findPublishByUser/${user_id}`, config)
    .then((res) => res)
    .catch((err) => {
      return Promise.reject(err);
    });
};

// POST: Publish a walkthrough to API call
export const callPOSTPublishWalkthrough = async (walkthrough_id) => {
  const config = attachHTTPRequestHeaders(true);
  const data = {};
  return (
    api
      // /walkthrough/unpublish/
      .post(`/walkthrough/publish/${walkthrough_id}`, data, config)
      .then((res) => res)
      .catch((err) => {
        return Promise.reject(err);
      })
  );
};

// POST: Unpublish a walkthrough to API call
export const callPOSTUnpublishWalkthrough = async (walkthrough_id) => {
  const config = attachHTTPRequestHeaders(true);
  const data = {};
  return api
    .post(`/walkthrough/unpublish/${walkthrough_id}`, data, config)
    .then((res) => res)
    .catch((err) => {
      return Promise.reject(err);
    });
};

// POST: Add a step to a walkthrough to API call
export const callPOSTAddStepToWalkthrough = async (
  walkthrough_id,
  listOfStep
) => {
  const data = {
    step: listOfStep,
  };
  const config = attachHTTPRequestHeaders(true);
  return api
    .post(`/walkthrough/addStep/${walkthrough_id}`, data, config)
    .then((res) => res)
    .catch((err) => {
      return Promise.reject(err);
    });
};

// POST: Update a step's building block component to a walkthrough to API call
export const callPOSTUpdateStepBuildingBlockToWalkthrough = async (
  walkthrough_id,
  component_id,
  component,
  bblocks
) => {
  const data = {
    component_id: component_id,
    component: component,
    bblocks: bblocks,
  };
  const config = attachHTTPRequestHeaders(true);
  return api
    .post(`/walkthrough/updateStepbb/${walkthrough_id}`, data, config)
    .then((res) => res)
    .catch((err) => {
      return Promise.reject(err);
    });
};

// POST: Update a walkthrough's step to API call
export const callPOSTUpdateStepToWalkthrough = async (
  walkthrough_id,
  component_id,
  component,
  styles
) => {
  const data = {
    component_id: component_id,
    component: component,
    styles: styles,
  };
  const config = attachHTTPRequestHeaders(true);
  return api
    .post(`/walkthrough/updateStep/${walkthrough_id}`, data, config)
    .then((res) => res)
    .catch((err) => {
      return Promise.reject(err);
    });
};

// POST: Update a walkthrough's step's settings to API call
export const callPOSTUpdateStepSettingsToWalkthrough = async (
  walkthrough_id,
  component_id,
  page_settings,
  event
) => {
  const data = {
    component_id: component_id,
    page_settings: page_settings,
    event: event,
  };
  const config = attachHTTPRequestHeaders(true);
  return api
    .post(`/walkthrough/updateStepSettings/${walkthrough_id}`, data, config)
    .then((res) => res)
    .catch((err) => {
      return Promise.reject(err);
    });
};

// POST: Update a walkthrough's step's page's settings to API call
export const callPOSTUpdateStepPageSettingsToWalkthrough = async (
  walkthrough_id,
  component_id,
  page_settings
) => {
  const data = {
    component_id: component_id,
    page_settings: page_settings,
  };
  const config = attachHTTPRequestHeaders(true);
  return api
    .post(`/walkthrough/updateStepPageSettings/${walkthrough_id}`, data, config)
    .then((res) => res)
    .catch((err) => {
      return Promise.reject(err);
    });
};

// POST: Remove a step from walkthrough to API call
export const callPOSTRemoveStepFromWalkthrough = async (
  walkthrough_id,
  component_id
) => {
  const data = {
    component_id: component_id,
  };
  const config = attachHTTPRequestHeaders(true);
  return api
    .post(`/walkthrough/deleteStep/${walkthrough_id}`, data, config)
    .then((res) => res)
    .catch((err) => {
      return Promise.reject(err);
    });
};
