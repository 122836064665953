import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import store from "../index";
import { callPOSTRetrieveAnalyticsDataForSingleWalkthrough } from "../../axios/v2/_callsIndex";
import { callPOSTRetrieveWalkthroughSessionAndActivity } from "../../axios/v2/callsAnalytics";
import {
  AnalyticDataSet,
  StepDataSet,
  UserInteractivityProcDataSet,
} from "../../interfaces/interfaceAnalytic";
import { format } from "date-fns";

// Fetch analytics summary of a single walkthrough.
export const analyticsRetrieveDataForSingleWalkthrough = createAsyncThunk<
  any,
  { walkthroughId: string; apiKey: string; orgId: string }
>("analytics/getAnalyticsForSingleWalkthrough", async (params, thunkApi) => {
  const { walkthroughId, apiKey, orgId } = params;
  try {
    const response = (await callPOSTRetrieveAnalyticsDataForSingleWalkthrough(
      walkthroughId,
      apiKey,
      orgId
    )) as any;

    if (response.data) {
      const formatDate = (selectedDate: Date) => {
        if (selectedDate) {
          return format(selectedDate, "dd MMM yy - hh:mm aaa");
        }
      };
      const result = response.data.payload;

      result.lastUpdate = formatDate(new Date());
      return result;
    } else {
      store.dispatch(getAnalyticsError(response));
      throw response;
    }
  } catch (err: any) {
    const result = {
      alert: {
        type: "error",
        message: err.toString(),
      },
    };
    return thunkApi.rejectWithValue(result);
    // API error messages will only be captured at calls-API function. Thus, error captures will be exectued there. Errors captured here are only when reply throws a 500 error that results in an empty 'result' variable, which will throw to this error exception
    // Catches sign in errors thrown from server
    /*
        // Disabling as this last catch will disable custom error handling for registration errors
        dispatch({
        type: actionTypes.REGISTRATION_ERROR,
        errorMsg: "Registration error with server. Please try again."
        })
      */
  }
});

// Fetch the full sessions and activities of a single walkthrough for a specified date range.
export const analyticsRetrieveWalkthroughSessionAndActivities =
  createAsyncThunk<any, string>(
    "analytics/analyticsRetrieveWalkthroughSessionAndActivities",
    async (
      analyticsData: any,
      thunkApi: { rejectWithValue: (arg0: unknown) => any }
    ) => {
      const {
        viewerTeamId,
        startDate,
        endDate,
        publishedWalkthroughId,
      }: {
        viewerTeamId: string;
        startDate: string;
        endDate: string;
        publishedWalkthroughId: string;
      } = analyticsData;
      try {
        const response = await callPOSTRetrieveWalkthroughSessionAndActivity(
          viewerTeamId,
          startDate,
          endDate,
          publishedWalkthroughId
        );
        if (response.data) {
          const result = response.data.payload;
          return result;
        } else {
          store.dispatch(getAnalyticsError(response));
          throw response;
        }
      } catch (err: any) {
        console.log("error");
        console.log(err);
        const result = {
          alert: {
            type: "error",
            message: err.toString(),
          },
        };
        return thunkApi.rejectWithValue(result);
      }
    }
  );

const analyticsState = {
  walkthroughSessions: null as AnalyticDataSet[] | null,
  walkthroughSessionActivities: null as null | StepDataSet[],
  firstTimeViewsCount: null as null | number,
  totalViewsCount: null as null | number,
  averageTimeToFinish: null as null | number,
  totalManualLaunchCount: null as null | number,
  totalAutomaticLaunchCount: null as null | number,
  medianTime: null as null | number,
  totalRepeat: null as null | number,
  avgLaunch: null as null | number,
  longestTime: null as null | number,
  shortestTime: null as null | number,
  lastUpdate: null as null | string,
  errorAnalytics: "",
  appLoading: false,
  alert: {
    active: false,
    type: "",
    message: "",
    autoHideDuration: 6000,
  },
};

export const analyticsSlice = createSlice({
  name: "analytics",
  initialState: analyticsState,
  reducers: {
    getAnalyticsError: (state, action) => {
      if (action.payload.errorMsg !== undefined) {
        console.log(
          `Get Summary Analytics Data Error is: ${action.payload.errorMsg}`
        );
      }
      state.errorAnalytics = action.payload.errorMsg;
    },
    resetAlert: (state) => {
      state.alert.active = false;
      state.alert.type = "";
      state.alert.message = "";
      state.alert.autoHideDuration = 6000;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(analyticsRetrieveDataForSingleWalkthrough.pending, (state) => {
        state.appLoading = true;
      })
      .addCase(
        analyticsRetrieveDataForSingleWalkthrough.fulfilled,
        (state, action) => {
          const payload = action.payload;
          state.firstTimeViewsCount = payload.firstTimeViewsCount;
          state.totalViewsCount = payload.totalViewsCount;
          state.averageTimeToFinish = payload.averageTimeToFinish;
          state.totalManualLaunchCount = payload.totalManualLaunchCount;
          state.totalAutomaticLaunchCount = payload.totalAutomaticLaunchCount;
          state.medianTime = payload.medianTimeTaken;
          state.totalRepeat = payload.totalRepeatCount;
          state.avgLaunch = payload.averageLaunchCount;
          state.longestTime = payload.longestTimeTaken;
          state.shortestTime = payload.shortestTimeTaken;

          state.lastUpdate = payload.lastUpdate;
          state.appLoading = false;
        }
      )
      .addCase(analyticsRetrieveDataForSingleWalkthrough.rejected, (state) => {
        // TODO: error message state
        state.appLoading = false;
      })
      .addCase(
        analyticsRetrieveWalkthroughSessionAndActivities.pending,
        (state) => {
          state.appLoading = true;
        }
      )
      .addCase(
        analyticsRetrieveWalkthroughSessionAndActivities.fulfilled,
        (state, action) => {
          const payload = action.payload;
          state.walkthroughSessions = payload.walkthroughSessions;
          state.walkthroughSessionActivities =
            payload.walkthroughSessionActivities;
          state.appLoading = false;
        }
      )
      .addCase(
        analyticsRetrieveWalkthroughSessionAndActivities.rejected,
        (state) => {
          // TODO: error message state
          state.appLoading = false;
        }
      );
  },
});

const { reducer, actions } = analyticsSlice;

export const { getAnalyticsError, resetAlert } = actions;

const reducerAnalytics = reducer;

export default reducerAnalytics;
