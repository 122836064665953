import { AxiosResponse } from "axios";
import {
  ResponseGetList,
  ResponseGetSingleImage,
  ResponseUploadImg,
} from "../../store/reducers/reducerMedia";
import { api } from "./_axios";
import attachHTTPRequestHeaders from "../../utility/functions/attachHTTPRequestHeaders";

/*
    ===================================================================    
    CALLS-MEDIA API
    ===================================================================
*/

/**Get presigned url for uploading img: */
export const POSTUploadImage = async ({
  name,
  contentType,
  orgId,
  cognitoId,
  email,
  size,
}: {
  name: string;
  contentType: string;
  orgId: string;
  cognitoId: string;
  email: string;
  size: string;
}) => {
  const config = attachHTTPRequestHeaders(false);

  const data = {
    name,
    contentType,
    orgId,
    cognitoId,
    email,
    size,
  };

  return api
    .post(`media/image/upload`, data, config)
    .then((res: AxiosResponse<ResponseUploadImg>) => res);
};

//*upload image using presigned url*/
export const POSTUploadImagePresignedURL = async (
  file: File,
  url: string,
  contentType: string
) => {
  const result = await fetch(url, {
    method: "PUT",
    body: file,
    headers: {
      "Content-Type": contentType,
      Accept: "application/json",
    },
  });

  return result;
};

/**Get presigned url for list image: */
export const POSTGetListImg = async (imgData: {
  limit: number;
  page: number;
  cognitoId: string;
  sortBy: "name" | "dateCreated";
  name: string;
  sort: number;
}) => {
  const { limit, page, sortBy, sort, name } = imgData;
  const data = {
    limit: limit,
    page: page,
    sortBy: sortBy,
    sort: sort,
    parameters: {
      name: name,
    },
  };

  const cognitoId = imgData.cognitoId;

  const config = attachHTTPRequestHeaders(false);

  return api
    .post(`media/image/list/${cognitoId}`, data, config)
    .then((res: AxiosResponse<ResponseGetList>) => res);
};

/**Get the actual image by using the presigned url */
export const getImageListFromPresign = async (url: string) => {
  const response = await fetch(url, { method: "GET" });

  if (!response.ok) {
    console.log("err ", response);
    throw response;
  }

  const result = response.blob();

  return result;
};

/**get presigned url for single image */
export const GETSingleImageCall = async (mediaId: string) => {
  const config = attachHTTPRequestHeaders(true);

  return api
    .get(`media/image/${mediaId}`, config)
    .then((res: AxiosResponse<ResponseGetSingleImage>) => res);
};

/**API Call for Delete imaged */
export const DELETESingleImage = async (mediaId: string) => {
  const config = attachHTTPRequestHeaders(true);

  return api
    .delete(`media/image/delete/${mediaId}`, config)
    .then((res: AxiosResponse<{ msg: string }>) => res);
};

/**API Call for to Hard Delete image */
export const DELETEHardDeleteSingleImage = async (mediaId: string) => {
  const config = attachHTTPRequestHeaders(true);

  return api
    .delete(`media/image/delete/${mediaId}/confirm`, config)
    .then((res: AxiosResponse<{ msg: string }>) => res);
};

/**API Call for Bulk Delete imaged */
export const DELETEBulkImages = async (listOfMediaIds: string[]) => {
  const config = attachHTTPRequestHeaders(true);

  const data = {
    mediaIds: listOfMediaIds,
  };

  return api.post(`media/image/delete/`, data, config).then(
    (
      res: AxiosResponse<{
        msg: string;
        payload: {
          name: string;
          mediaId: string;
          msg: string;
          walkthroughDetails: {
            walkthrough_id: string;
            building_blocks: string;
            walkthrough_name: string;
          };
        }[];
      }>
    ) => res
  );
};
