import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import ComponentCard from "../ReusableComponents/ComponentCard";

const ComponentOverviewTimeSpent = () => {
  /**overview states */
  const {
    firstTimeViewsCount,
    totalViewsCount,
    averageTimeToFinish,
    medianTime,
    totalRepeat,
    avgLaunch,
    longestTime,
    shortestTime,
  } = useSelector((state: RootState) => state.analytics);

  const convertToTime = (timeTaken: number) => {
    let seconds = Math.floor(timeTaken / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);

    seconds = seconds % 60;
    minutes = minutes % 60;

    // const hours = parseInt(splitHourMinuteSeconds[0]);
    // const minutes = parseInt(splitHourMinuteSeconds[1]);
    // const seconds = parseInt(splitHourMinuteSeconds[2]);

    let timeString = "";
    if (hours > 0) {
      timeString += `${hours}h`;
    }
    if (minutes > 0) {
      timeString += `${minutes}m`;
    }
    if (seconds >= 0) {
      timeString += `${seconds}s`;
    }

    return timeString;
  };
  return (
    <div className="overview mt-3 flex justify-start flex-wrap gap-3">
      {totalViewsCount !== null && totalViewsCount !== undefined && (
        <ComponentCard
          cardType="normal"
          cardValue={totalViewsCount.toString()}
          cardText="Total Views"
        />
      )}
      {firstTimeViewsCount !== null && firstTimeViewsCount !== undefined && (
        <ComponentCard
          cardType="normal"
          cardValue={firstTimeViewsCount.toString()}
          cardText="First Time Views"
        />
      )}
      {totalRepeat !== null && totalRepeat !== undefined && (
        <ComponentCard
          cardType="normal"
          cardValue={totalRepeat.toString()}
          cardText="Total Repeat Views"
        />
      )}
      {averageTimeToFinish !== null && averageTimeToFinish !== undefined && (
        <ComponentCard
          cardType="normal"
          cardValue={convertToTime(averageTimeToFinish)}
          cardText="Average Completion Time"
        />
      )}
      {medianTime !== null && medianTime !== undefined && (
        <ComponentCard
          cardType="normal"
          cardValue={convertToTime(medianTime)}
          cardText="Median Completion Time"
        />
      )}
      {avgLaunch !== null && avgLaunch !== undefined && (
        <ComponentCard
          cardType="normal"
          cardValue={avgLaunch.toString()}
          cardText="Walkthroughs Per User"
        />
      )}
      {longestTime !== null && longestTime !== undefined && (
        <ComponentCard
          cardType="normal"
          cardValue={convertToTime(longestTime)}
          cardText="Longest Completion"
        />
      )}
      {shortestTime !== null && shortestTime !== undefined && (
        <ComponentCard
          cardType="normal"
          cardValue={convertToTime(shortestTime)}
          cardText="Fastest Completion"
        />
      )}
    </div>
  );
};

export default ComponentOverviewTimeSpent;
