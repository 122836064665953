import React from "react";
import { useSelector } from "react-redux";

import ComponentSettingsAccountInfoPersonal from "../components/ComponentSettingsAccountInfoPersonal";
import ComponentSettingsAccountInfoAccount from "../components/ComponentSettingsAccountInfoAccount";
import { RootState } from "../store";
import ComponentPaymentSetting from "../components/ComponentPaymentSetting";

const PageAccountSettings = () => {
  const user = useSelector((state: RootState) => state.user.userData);

  return (
    <div className="pageBase">
      <div className="pageHeader">Account Settings</div>

      <ComponentSettingsAccountInfoPersonal user={user} />

      <ComponentSettingsAccountInfoAccount user={user} />
      {user.role !== "viewer" && <ComponentPaymentSetting />}
    </div>
  );
};

export default PageAccountSettings;
