import React from "react";
import generateClassName from "../../_functions/functionGenerateClassName";

interface Props {
  className?: string;
  title: string;
}

const SubHeader = (props: Props) => {
  return (
    <div className={`pageSubHeader ${generateClassName(props.className)}`}>
      {props.title}
    </div>
  );
};

export default SubHeader;
