import React, { useEffect, useRef, useState } from "react";

import {
  Button,
  CloseSquare,
  ContentDropdown,
  EmptyModal,
} from "usertip-component-library";
import BaseSettings from "./BaseSettings";
import BasicSettings from "./BasicSettings";
import ModalBB from "../../02-molecules/06-BuildingBlock/ModalBB";
import SlideoutBB from "../../02-molecules/06-BuildingBlock/SlideoutBB";
import SpotlightBB from "../../02-molecules/06-BuildingBlock/SpotlightBB";
import TooltipBB from "../../02-molecules/06-BuildingBlock/TooltipBB";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store";
import {
  addOrUpdateTheme,
  applyThemeToPublished,
  applyThemeToUnpublished,
  cancelChange,
  resetThemeIndicator,
} from "../../../store/reducers/reducerTheme";

interface Props {
  /** This prop is for set the type of the component */
  type: "modal" | "slideout" | "spotlight" | "tooltip";
  /** This prop is for set component stle ModalThemeType | SlideoutThemeType | SpotlightTooltipThemeType */
  style: any;
}

const ThemeEditorPanel = ({ type, style }: Props) => {
  /** state for opening the settings dropdown */
  const [base, setBase] = useState(false);
  const [header, setHeader] = useState(false);
  const [subtitle, setSubtitle] = useState(false);
  const [button, setButton] = useState(false);

  /** state for opening modal for confirming apply theme to unpublished walkthrough */
  const [isOpen, setIsOpen] = useState(false);
  /** state for opening modal for confirming apply theme to published walkthrough */
  const [isSecondModalOpen, setIsSecondModalOpen] = useState(false);

  /** this ref is for spotlight and tooltip (to set the position of the target) */
  const targetRef = useRef<HTMLDivElement>(null);
  const contRef = useRef<HTMLDivElement>(null);

  const {
    modalChange,
    slideoutChange,
    spotlightChange,
    tooltipChange,
    modalTheme,
    slideoutTheme,
    spotlightTheme,
    tooltipTheme,
    dbModal,
    dbSlideout,
    dbSpotlight,
    dbTooltip,
    themeIndicator,
  } = useSelector((state: RootState) => state.theme);

  const { org_id } = useSelector((state: RootState) => state.org);

  const dispatch = useDispatch<AppDispatch>();

  /** For showing save or cancel button when style change */
  const showButton =
    (type === "modal" && modalChange) ||
    (type === "slideout" && slideoutChange) ||
    (type === "spotlight" && spotlightChange) ||
    (type === "tooltip" && tooltipChange);

  /** For disable apply button if there's no theme data on the database */
  const disableButton =
    (type === "modal" && dbModal) ||
    (type === "slideout" && dbSlideout) ||
    (type === "spotlight" && dbSpotlight) ||
    (type === "tooltip" && dbTooltip) ||
    showButton;

  /** For preventing setIsOpen and setIsSecondModalOpen not closed when api calls got rejected */
  useEffect(() => {
    if (themeIndicator === "error") {
      setIsOpen(false);
      setIsSecondModalOpen(false);
    }
  }, [themeIndicator]);

  /** handler for canceling current change */
  const cancelBtnHandler = (type: string) => {
    dispatch(cancelChange(type));
  };

  /** Handler for update theme to database */
  const saveBtnHandler = () => {
    if (type === "modal") {
      dispatch(addOrUpdateTheme({ orgId: org_id!, theme: modalTheme }));
    } else if (type === "slideout") {
      dispatch(addOrUpdateTheme({ orgId: org_id!, theme: slideoutTheme }));
    } else if (type === "spotlight") {
      dispatch(addOrUpdateTheme({ orgId: org_id!, theme: spotlightTheme }));
    } else if (type === "tooltip") {
      dispatch(addOrUpdateTheme({ orgId: org_id!, theme: tooltipTheme }));
    }

    setIsOpen(true);
  };

  const confirmApplyUnpublishedHandler = () => {
    onCloseHandler();
    setIsSecondModalOpen(true);
    dispatch(applyThemeToUnpublished({ orgId: org_id!, type }));
  };

  const confirmApplyPublishedHandler = () => {
    onCloseSecondModalHandler();
    dispatch(applyThemeToPublished({ orgId: org_id!, type }));
  };

  /** Handler for closing the confirm apply to published walkthrough modal */
  const onCloseHandler = () => {
    dispatch(resetThemeIndicator());
    setIsOpen(false);
  };

  const onCloseSecondModalHandler = () => {
    dispatch(resetThemeIndicator());
    setIsSecondModalOpen(false);
  };

  return (
    <div>
      <div className="bg-default-primary rounded-t-[20px] flex flex-row justify-between px-6 py-4 items-center text-white">
        <div className="text-base font-bold">
          <span>Edit Theme</span>
        </div>
        <div className="flex flex-row gap-2 items-center">
          {showButton && (
            <Button
              text="Cancel Current Changes"
              size="small"
              variant="primary"
              color="secondary"
              onClick={() => cancelBtnHandler(type)}
              customClass="h-[34px]"
            />
          )}
          <Button
            text="Save Style"
            size="small"
            variant="outlined"
            color="neutral"
            onClick={saveBtnHandler}
            customClass={"ut-btn-org"}
            disabled={disableButton ? false : true}
          />
        </div>
      </div>

      <div className="bg-white w-full min-h-[300px] flex rounded-b-[20px] overflow-hidden relative">
        <div className="absolute top-0 bottom-0 left-0 right-0 border-x-[1px] border-b-[1px] border-[#E4DDFC] rounded-b-[20px]"></div>
        <div className="flex-1 relative">
          <ContentDropdown
            label="Base"
            isOpen={base}
            setIsOpen={setBase}
            width="full"
            onClick={() => {
              setHeader(false);
              setSubtitle(false);
              setButton(false);
            }}
          >
            <BaseSettings type={type} style={style} />
          </ContentDropdown>
          {style.buildingBlockStyle.map((val: any, index: number) => {
            switch (val.label) {
              case "title":
                return (
                  <ContentDropdown
                    key={index}
                    label="Header"
                    isOpen={header}
                    setIsOpen={setHeader}
                    width="full"
                    onClick={() => {
                      setBase(false);
                      setSubtitle(false);
                      setButton(false);
                    }}
                  >
                    <BasicSettings type={type} style={val} />
                  </ContentDropdown>
                );
              case "subtitle":
                return (
                  <ContentDropdown
                    key={index}
                    label="Subtitle"
                    isOpen={subtitle}
                    setIsOpen={setSubtitle}
                    width="full"
                    onClick={() => {
                      setBase(false);
                      setHeader(false);
                      setButton(false);
                    }}
                  >
                    <BasicSettings type={type} style={val} />
                  </ContentDropdown>
                );
              case "button":
                return (
                  <ContentDropdown
                    key={index}
                    label="Button"
                    isOpen={button}
                    setIsOpen={setButton}
                    width="full"
                    onClick={() => {
                      setBase(false);
                      setHeader(false);
                      setSubtitle(false);
                    }}
                  >
                    <BasicSettings type={type} style={val} />
                  </ContentDropdown>
                );
            }
          })}
        </div>
        <div className="flex-1 border-l-[1px] border-[#E4DDFC] flex items-center justify-center p-4">
          <div
            className={`w-full max-w-2xl bg-[#E4DDFC] rounded-[10px] flex items-center justify-center py-12 relative overflow-hidden h-[700px]`}
            ref={contRef}
          >
            {(type === "spotlight" || type === "tooltip") && (
              <div
                className="font-semibold text-4xl text-default-primary relative top-0 left-0"
                ref={targetRef}
              >
                USERTIP
              </div>
            )}
            {type === "modal" && <ModalBB style={style} />}
            {type === "slideout" && <SlideoutBB style={style} />}
            {type == "spotlight" && (
              <SpotlightBB
                style={style}
                target={targetRef}
                container={contRef}
              />
            )}
            {type == "tooltip" && (
              <TooltipBB style={style} target={targetRef} container={contRef} />
            )}
          </div>
        </div>
      </div>

      <EmptyModal
        isOpen={themeIndicator === "saved" && isOpen}
        onClose={() => {}}
        customClass="ut-px-5 ut-py-6"
        size="large"
      >
        <div className="flex flex-col gap-8">
          <div className="w-full flex justify-between items-center">
            <h1 className="text-default-neutral-100 font-bold text-lg">
              {type.slice(0, 1).toLocaleUpperCase() + type.substring(1)} Theme
              Saved Succesfully
            </h1>
            <CloseSquare
              className="ut-w-5 ut-h-5 ut-fill-default-neutral-60 ut-cursor-pointer"
              onClick={onCloseHandler}
            />
          </div>
          <div className="text-default-neutral-70 text-base">
            Do you want to apply this style to all unpublished walkthroughs?
            Applying the style will affect every {type} step style on all
            walkthrough.
          </div>
          <div className="flex ml-auto gap-2">
            <Button
              color="secondary"
              variant="primary"
              size="small"
              text="Don't Apply"
              onClick={onCloseHandler}
            />
            <Button
              color="primary"
              variant="primary"
              size="small"
              text="Apply"
              onClick={confirmApplyUnpublishedHandler}
            />
          </div>
        </div>
      </EmptyModal>

      <EmptyModal
        isOpen={themeIndicator === "applied" && isSecondModalOpen}
        onClose={() => {}}
        customClass="px-5 py-6"
        size="large"
      >
        <div className="flex flex-col gap-8">
          <div className="text-default-neutral-70 text-base">
            Do you also want to apply this {type} style to all published
            walkthroughs?
          </div>
          <div className="flex ml-auto gap-2">
            <div className="w-16">
              <Button
                color="secondary"
                variant="primary"
                size="fluid-small"
                text="No"
                onClick={onCloseSecondModalHandler}
              />
            </div>
            <div className="w-16">
              <Button
                color="primary"
                variant="primary"
                size="fluid-small"
                text="Yes"
                onClick={confirmApplyPublishedHandler}
              />
            </div>
          </div>
        </div>
      </EmptyModal>
    </div>
  );
};

export default ThemeEditorPanel;
