import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import React, { useState } from "react";

import SearchIcon from "@mui/icons-material/Search";
import { InputField, SearchDropdown } from "usertip-component-library";
interface Props {
  setText: React.Dispatch<React.SetStateAction<string>>;
  setSortBy: React.Dispatch<React.SetStateAction<"name" | "dateCreated">>;
  setSortOrder: React.Dispatch<React.SetStateAction<1 | -1>>;
  onFilterHandler: (e: React.FormEvent<HTMLFormElement>) => void;
}
const ComponentFilterSearch = ({
  setText,
  setSortBy,
  setSortOrder,
  onFilterHandler,
}: Props) => {
  const [selectValue, setSelectValue] = useState<string>("newest");

  const dataArray = [
    {
      value: "Newest",
      id: "1",
    },
    {
      value: "Oldest",
      id: "2",
    },
    {
      value: "Name A-Z",
      id: "3",
    },
    {
      value: "Name Z-a",
      id: "4",
    },
  ];

  const valueParser = (value: string) => {
    switch (value) {
      case "newest":
        return "Newest";
      case "oldest":
        return "Oldest";
      case "nameAsc":
        return "Name A-Z";
      case "nameDes":
        return "Name Z-a";
      default:
        return "Newest";
    }
  };

  function handleOnSelectSort(id: string) {
    if (id === "1") {
      setSelectValue("newest");
      setSortBy("dateCreated");
      setSortOrder(-1);
    }

    if (id === "2") {
      setSelectValue("oldest");
      setSortBy("dateCreated");
      setSortOrder(1);
    }

    if (id === "3") {
      setSelectValue("nameAsc");
      setSortBy("name");
      setSortOrder(-1);
    }

    if (id === "4") {
      setSelectValue("nameDes");
      setSortBy("name");
      setSortOrder(-1);
    }
  }

  return (
    <form onSubmit={onFilterHandler} className="flex items-center gap-3">
      <FormControl size="small" sx={{ width: "130px" }}>
        <SearchDropdown
          dataArray={dataArray}
          selectedValue={{ value: valueParser(selectValue) }}
          onClick={handleOnSelectSort}
          customWidth="ut-w-full"
          placeholder="Sort"
          search={false}
        />
      </FormControl>
      <InputField
        variant="text"
        iconRight={true}
        icon={
          <button type="submit">
            <SearchIcon color="primary" />
          </button>
        }
        onChange={(e) => setText(e.target.value)}
        placeholder="Search image by name"
      />
    </form>
  );
};

export default ComponentFilterSearch;
