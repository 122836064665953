import { createSlice } from "@reduxjs/toolkit";

// initial state
export const alertState = {
    alert: {
        active: false,
        type: "",
        message: "",
        autoHideDuration: 6000,
    }
};


export const alertStateSlice = createSlice({
  name: "alertState",
  initialState: alertState,
  reducers: {
    setAlert: (state, action) => {
      state.active = true;
      state.type = action.payload.type;
      state.message = action.payload.message;
      state.autoHideDuration = action.payload.autoHideDuration;
    },
    resetAlert: (state) => {
        state.alert.active= false
        state.alert.type = ""
        state.alert.message = ""
        state.alert.autoHideDuration = 6000
    },
  },
});

export const { setAlert, resetAlert } = alertStateSlice.actions;
export default alertStateSlice.reducer;
