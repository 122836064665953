import { Button } from "usertip-component-library";
import React from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  publishWalkthroughPost,
  unPublishWalkthroughPost,
} from "../store/reducers/reducerWalkthrough";
import ComponentPlanLimitWarning from "./OrgPlan/ComponentPlanLimitWarning";

const ComponentSettingsPublishWalkthrough = ({
  walkthroughStatus,
}: {
  walkthroughStatus: boolean;
}) => {
  const [limitReach, setLimitReach] = React.useState(false);

  const dispatch = useDispatch();
  const { walkthroughID } = useParams();
  //publish
  const onPublishWalkthrough = () => {
    //@ts-ignore
    dispatch(publishWalkthroughPost(walkthroughID));
  };
  const ButtonPublish = () => {
    return (
      <Button
        disabled={limitReach}
        color="primary"
        variant="primary"
        size="medium"
        onClick={() => onPublishWalkthrough()}
        text="Publish"
      />
    );
  };

  //unPublish
  const ButtonUnpublish = () => {
    const onUnpublishWalkthrough = () => {
      //@ts-ignore
      dispatch(unPublishWalkthroughPost(walkthroughID));
    };
    return (
      <Button
        color="primary"
        variant="primary"
        size="medium"
        onClick={() => onUnpublishWalkthrough()}
        text="Unpublish"
      />
    );
  };

  return walkthroughStatus === true ? (
    <ButtonUnpublish />
  ) : (
    <>
      <ComponentPlanLimitWarning
        setLimitReach={setLimitReach}
        limitName={"publishedWalkthrough"}
      />
      <ButtonPublish />
    </>
  );
};
export default ComponentSettingsPublishWalkthrough;
