import * as actionTypes from "./_actionTypes";

// Modal visibility action for create new walkthrough.
export const setCreateWalkthroughModalShow = (modalShow) => {
  return {
    type: actionTypes.SET_CREATE_WALKTHROUGH_MODAL_SHOW,
    modalShow,
  };
};

// // Modal visibility action for change user name.
// export const setChangeNameModalShow = (modalShow) => {
//     return {
//         type: actionTypes.SET_CHANGE_NAME_MODAL_SHOW,
//         modalShow,
//     };
// };

// // Modal visibility action for change account password.
// export const setChangePasswordModalShow = (modalShow) => {
//     return {
//         type: actionTypes.SET_CHANGE_PASSWORD_MODAL_SHOW,
//         modalShow,
//     };
// };

// // Modal visibility action for change phone number.
// export const setChangePhoneNumberModalShow = (modalShow) => {
//     return {
//         type: actionTypes.SET_CHANGE_PHONE_NUMBER_MODAL_SHOW,
//         modalShow,
//     };
// };

// // Modal visibility action for change organizational information.
// export const setChangeOrganizationalInfoModalShow = (modalShow) => {
//     return {
//         type: actionTypes.SET_CHANGE_ORGANIZATIONAL_INFO_MODAL_SHOW,
//         modalShow,
//     };
// };
