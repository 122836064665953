import React, { useEffect, useRef, useState } from "react";

import { Alert, Box, Snackbar } from "@mui/material";
import ImageIcon from "@mui/icons-material/Image";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import {
  getImageList,
  resetImgList,
  resetMsg,
  uploadImage,
} from "../../../store/reducers/reducerMedia";
import ComponentLoading from "../../ComponentLoading";
import WarningMsg from "../../Account/WarningMsg/WarningMsg";
import bytesToSize from "../../../utility/functions/bytesToSize";
import sleepTimer from "../../../utility/functions/sleepTimer";
import { Button } from "usertip-component-library";

const maxImageBytes = 30000000; // 30MB
const allowedImageTypes = [
  "image/gif",
  "image/jpeg",
  "image/png",
  // "image/svg+xml"
];

const ComponentMediaManagerUploadImage = ({
  setUploadImgModal,
  setIsWaitingUploadLoading,
}: {
  setUploadImgModal: React.Dispatch<React.SetStateAction<boolean>>;
  setIsWaitingUploadLoading: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  /**State for image link */
  const [image, setImage] = useState<string | null>(null);
  /**State for image file that will uploaded */
  const [imageFile, setImageFile] = useState<File | null>(null);
  /**State for image file name that will uploaded */
  const [fileName, setFilename] = useState<string>("");
  /**State for image file size */
  const [fileSize, setFileSize] = useState<number>(0);
  /**State for image content type (jpg, jpeg, gif, png) that will uploaded */
  const [contentType, setContentType] = useState<string>("");
  /**State for error msg when uploading image */
  const [errorMsgs, setErrorMsgs] = useState<string[] | []>([]);

  const iconColor = "#E4DDFC";
  const placeholderColor = "#C8BCF9";

  const user = useSelector((state: RootState) => state.user.userData);
  const isLoading = useSelector((state: RootState) => state.media.appLoading);
  const cognitoId = useSelector(
    (state: RootState) => state.user.userData.cognito_id
  );
  const msg = useSelector((state: RootState) => state.media.msg);

  const dispatch = useDispatch();

  /**Reset the picture after success uploading image to the server */
  useEffect(() => {
    if (msg === "uploadSuccess") {
      setIsWaitingUploadLoading(true);
      setImage(null);
      setImageFile(null);
      setUploadImgModal(false);
      sleepTimer(2000).then(() => {
        setIsWaitingUploadLoading(false);
        dispatch(resetImgList());
        dispatch(resetMsg());
      });
    }
  }, [msg]);

  const handleClickUpload = () => {
    /** get the input tag within the shadow */
    let inputBtn = document.getElementById("ut-upload-image-input");

    inputBtn?.click();
  };

  /** Handles what happens after user has selected an image */
  const handleImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    /** Stop the form from reloading the page */
    e.preventDefault();

    // const formData = new FormData(e.target)

    /** If there's no element, do nothing */
    if (!e.target.files) {
      return;
    }
    /**dispatch reset msg so it accept new image */
    dispatch(resetMsg());
    /**get file name and render it*/
    const file = e.target.files[0];
    const { name } = file;
    setFilename(name);

    /** get the input tag within the shadow */
    let inputBtn = e.target;

    /** If there's no element, do nothing */
    if (!inputBtn) return;

    /** If there's no file, do nothing */
    if (!inputBtn!.value.length) return;

    /** If there's no file uploaded, do nothing */
    if (!inputBtn.files) return;

    let reader = new FileReader();

    const test = reader.readAsDataURL(inputBtn.files[0]);

    /**Add event listener load */
    reader.addEventListener("load", () => {
      /**set image url */
      setImage(reader.result as string);
    });

    setImageFile(inputBtn.files[0]);
    setContentType(inputBtn.files[0].type);

    /**Check if not exceeded maximum file size */
    if (inputBtn.files[0].size > maxImageBytes) {
      const errMsg = "Exceeded maximum file size of 30MB.";
      console.error(errMsg);
      setErrorMsgs([...errorMsgs, errMsg]);
    }

    /** Set file size if file is within the acceptable limit */
    if (inputBtn.files[0].size > 0 && inputBtn.files[0].size <= maxImageBytes) {
      setFileSize(inputBtn.files[0].size);
    }

    /**Check if correct file type*/
    if (!allowedImageTypes.includes(inputBtn.files[0].type)) {
      const errMsg = "File type error. File is not a gif, jpg, jpeg or png";
      console.error(errMsg);
      setErrorMsgs([...errorMsgs, errMsg]);
    }
  };

  /**Handler for uploading image */
  const handleUploadImage = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const fileSizeToString = bytesToSize(fileSize);
    const data = {
      name: fileName,
      contentType,
      imageFile: imageFile as File,
      size: fileSizeToString,
      orgId: user.org!,
      email: user.email!,
      cognitoId: user.cognito_id,
    };

    dispatch(uploadImage(data));
  };

  return (
    <>
      {/* show loading bar when uploading file */}
      <ComponentLoading show={isLoading} />
      {/* if upload success render snackbar */}
      <section
        className="py-2 my-2"
        style={{
          width: "inherit",
        }}
        id="media-upload-image-form"
      >
        <form
          style={{ width: "inherit" }}
          onSubmit={handleUploadImage}
          id="formImg"
        >
          <div
            id="ut-upload-image"
            className="ut__no__image__container"
            onClick={handleClickUpload}
          >
            {/* show image preview before uploading image */}
            {image ? (
              <div className="flex justify-center items-center my-3 ut__uploaded__image_">
                <img
                  className=" object-fit max-w-[240px] "
                  alt="current uploaded image"
                  src={image}
                />
                <input
                  style={{
                    opacity: 0,
                    height: "inherit",
                    width: "inherit",
                    position: "absolute",
                    visibility: "hidden",
                  }}
                  id="ut-upload-image-input"
                  type="file"
                  accept=".jpg, .jpeg, .png, .gif"
                  onChange={handleImage}
                  onClick={() => {
                    return false;
                  }}
                />
              </div>
            ) : (
              /**Upload image form */
              <Box className="ut__upload__image">
                <div
                  style={{
                    backgroundColor: placeholderColor,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "50px",
                    height: "60px",
                  }}
                >
                  <ImageIcon
                    style={{
                      // backgroundColor: iconColor,
                      color: iconColor,
                      fontSize: "80px",
                    }}
                  />
                </div>
                <h6 className="my-1" style={{ color: placeholderColor }}>
                  Click to upload an Image
                </h6>

                {/* <LoadingBarWithLabel value={progress} />

              <h6 className="my-1" style={{ color: "#DC2626" }}>
                <b>Error!</b>
              </h6> */}

                <input
                  style={{
                    opacity: 0,
                    height: "inherit",
                    width: "inherit",
                    position: "absolute",
                    visibility: "hidden",
                  }}
                  id="ut-upload-image-input"
                  type="file"
                  accept=".jpg, .jpeg, .png, .gif"
                  onChange={handleImage}
                  onClick={() => {
                    return false;
                  }}
                />
                {/* <label for="ut-upload-image">Upload a file</label> */}
              </Box>
            )}
          </div>
          {/* Display error msg */}
          {errorMsgs.length > 0 &&
            errorMsgs.map((err, i) => <WarningMsg msg={err} key={i} />)}
          {/* Upload button */}
          {image && errorMsgs.length === 0 && (
            <div className="mt-2">
              <Button
                color="primary"
                size="fluid"
                text="Upload"
                variant="primary"
              />
            </div>
          )}
        </form>
      </section>
    </>
  );
};

export default ComponentMediaManagerUploadImage;
