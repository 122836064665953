import React, { useEffect, useState } from "react";
import ReportIcon from "@mui/icons-material/Report";
import InputForm from "../../UI/Form/FormInput";
import Button from "../../UI/Button/Button";
import { useDispatch } from "react-redux";
import { changeForgotPwdSection } from "../../../store/reducers/reducerAuth";
import { InputField } from "usertip-component-library";
import { Button as UsertipButton } from "usertip-component-library";
import { useNavigate } from "react-router-dom";

interface Props {
  code: string;
  setCode: React.Dispatch<React.SetStateAction<string>>;
  AbortForgotPasswordHandler: () => void;
}

const CodeForm = ({ code, setCode, AbortForgotPasswordHandler }: Props) => {
  const [isCodeValid, setIsCodeValid] = useState<boolean | null>(null);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  /**check if the code is valid 6 digits */
  useEffect(() => {
    if (code.length === 6) {
      setIsCodeValid(true);
    } else if (code.length === 0) {
      setIsCodeValid(null);
    } else {
      setIsCodeValid(false);
    }
  });

  const onSubmitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(changeForgotPwdSection(code));
  };

  return (
    <div className="flex justify-center">
      <form
        action="submit"
        className="flex flex-col gap-4"
        onSubmit={onSubmitHandler}
      >
        <InputField
          variant="full"
          helperText="Check your email for 6 digits verification code"
          placeholder="Enter verification code"
          label="Verification Code"
          value={code}
          onChange={(e) => {
            setCode(e.target.value);
          }}
          error={isCodeValid === null ? undefined : !isCodeValid}
          errorText="Please input valid verification code format. Valid code is consisting by 6 digits number"
        />
        <p className="text-sm text-default-neutral-50 text-center">
          Didn't get the code?{" "}
          <a
            href="mailto:support@usertip.com"
            className="visited:text-default-neutral-50 font-medium hover:text-default-neutral-60 active:text-default-neutral-60 hover:no-underline"
            target="_blank"
          >
            Contact Support
          </a>
        </p>
        <UsertipButton
          color="primary"
          size="fluid"
          variant="primary"
          text="Confirm"
          disabled={!isCodeValid}
        />
        <UsertipButton
          color="secondary"
          size="fluid"
          variant="primary"
          text="Cancel"
          onClick={() => {
            AbortForgotPasswordHandler();
            navigate("/");
          }}
        />
      </form>
    </div>
  );
};

export default CodeForm;
