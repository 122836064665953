const blobUrlToFile = (blobUrl: string, image_name: string): Promise<File> =>
  new Promise((resolve) => {
    fetch(blobUrl).then((res) => {
      res.blob().then((blob) => {
        const file = new File([blob], `${image_name}.png`, { type: blob.type });
        resolve(file);
      });
    });
  });

export default blobUrlToFile;
