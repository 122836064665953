import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  PLAN_CHANGE_CODE,
  postSubscribe,
  resetCheckOutPage,
} from "../store/reducers/reducerPayment";
import { RootState } from "../store";
import { Button, Tabs, TabsItem } from "usertip-component-library";
import NewPaymentCardSelection from "../components/Templates/PaymentCard/NewPaymentCardSelection";
import {
  EmbedPricingCard,
  FeatureItem,
  MainPriceContainer,
} from "./EmbedPagePricingTable";
import { useNavigate } from "react-router-dom";

const PageBilling = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { temporaryAuth, plan_info } = useSelector(
    (state: RootState) => state.payment
  );

  const orgPlanInfo = useSelector((state: RootState) => state.org.org_plan);

  // State to show pricing values for month / year subscriptions
  const [billingType, setBillingType] = useState<"month" | "year">("month");

  const [currentPlan, setCurrentPlan] = useState<null | string>(null);

  // State to tell user if their subscription has been changed
  // Happens when checkOutLink == PLAN_CHANGE_CODE, when a active sub is upgraded/downgraded
  const [subUpdate, setSubUpdate] = useState(false);

  type PaymentTier = "MICRO_PLAN" | "STARTER_PLAN" | "BUSINESS_PLAN";
  // const [paymentTier, setPaymentTier] = useState<PaymentTier>("STARTER_PLAN");

  function onHandlingPayment(plan: PaymentTier) {
    const billing_cycle =
      billingType === "month" ? "BILLING_MONTHLY" : "BILLING_YEARLY";

    dispatch(postSubscribe({ tier: plan, sub_type: billing_cycle }));
  }

  const checkOutLink = useSelector(
    (state: RootState) => state.payment.checkOutLink
  );

  useEffect(() => {
    if (checkOutLink) {
      if (checkOutLink == PLAN_CHANGE_CODE) {
        dispatch(resetCheckOutPage());
        // navigate("/");
        setSubUpdate(true);
      } else {
        window.location.replace(checkOutLink);
      }
    }
  }, [checkOutLink]);
  //TODO: disable this url when user done payment after login

  useEffect(() => {
    if (orgPlanInfo && orgPlanInfo.plan_tier.name) {
      setCurrentPlan(orgPlanInfo.plan_tier.name);
    }
  }, [orgPlanInfo]);

  const openRegistrationPage = () => {
    let registrationURL = "";
    switch (process.env.NODE_ENV) {
      case "development": {
        registrationURL = "http://localhost:3000/registration";
        break;
      }
      case "staging": {
        registrationURL =
          "https://staging.d2rlkjfr40q6bq.amplifyapp.com/registration";
        break;
      }
      case "production": {
        registrationURL = "https://app.usertip.com/registration";
        break;
      }
      default: {
        registrationURL = "https://app.usertip.com/registration";
        break;
      }
    }
    window.open(registrationURL, "_blank", "noreferrer");
  };

  const onClickContactUs = () => {
    // send Email when user click on "Let's Talk" Enterprise offer
    window.open("https://www.usertip.com/bookings", "_blank", "noreferrer");
  };

  const isOrgUsingPlan = (
    plan_name: "MICRO_PLAN" | "STARTER_PLAN" | "BUSINESS_PLAN"
  ) => {
    if (billingType === "month" && currentPlan) {
      return plan_name === currentPlan;
    } else if (billingType === "year" && currentPlan) {
      // if plan name includes the plan code e.g. "MICRO_PLAN"
      return currentPlan.includes(plan_name) && currentPlan.includes("YEARLY");
    } else {
      return false;
    }
  };

  const handleReturnToHome = () => {
    navigate("/");
    setSubUpdate(false);
  };

  return (
    <>
      {/* OLD BILLING UI */}
      {/* <div className="py-10 px-12">
        <div className="modalSubHeader mt-6">
          <NewPaymentCardSelection
            activePlan={paymentTier}
            setActivePlan={setPaymentTier}
          />
        </div>
        <div className="modalActionBtnBar mt-4">
          <Button
            color="primary"
            size="medium"
            text="Proceed Payment"
            variant="primary"
            onClick={() => onHandlingPayment(paymentTier)}
          />
        </div>
      </div> */}
      <div className="flex flex-col w-full">
        {!subUpdate ? (
          <>
            <div
              className="ut--PricingTable is-fontFamily--NotoSans ut--is-whiteButtonText ut--is-lightColorBackground has-fourItems flex flex-col justify-center items-center"
              style={{
                // "--pt-color-primary: #7657f1; background-color: rgb(240, 240, 255)"
                // "--pt-color-primary": "#7657f1"
                color: "#7657f1",
                // backgroundColor: "rgb(240, 240, 255)",
                backgroundColor: "rgb(255, 255, 255)",
                borderRadius: "32px",
              }}
              data-testid="pricing-table-container"
            >
              <div className="flex flex-row self-center items-center w-auto py-2">
                <Tabs variant="light" name="tabs-pricing-type">
                  <TabsItem
                    variant="light"
                    label="Monthly"
                    onClick={() => setBillingType("month")}
                    id="month"
                  />
                  <TabsItem
                    variant="light"
                    label="Yearly - 2 months free!"
                    onClick={() => setBillingType("year")}
                    id="year"
                  />
                </Tabs>
                {/* <div>
            <CurlyArrowIcon />
            <span className="ut--Text ut--Text-color--default ut--Text-fontSize--14 ut--Text-fontWeight--600">
              Get 2 months free!
            </span>
          </div> */}
              </div>
              <div className="ut--PricingTable-grid">
                <EmbedPricingCard
                  planTitle={"Micro Plan"}
                  planDesc={`${
                    billingType === "month" ? "" : "Paid yearly. "
                  }For small teams looking to improve training.`}
                  onClickPricingButton={() => {
                    onHandlingPayment("MICRO_PLAN");
                  }}
                  pricingBtnText={"Subscribe"}
                  price={billingType === "month" ? "USD 29" : "USD 24"}
                  priceUnit={"per month"}
                  disabled={isOrgUsingPlan("MICRO_PLAN")}
                  featureList={
                    <>
                      <FeatureItem text="Unlimited Walkthroughs" />
                      <FeatureItem text="Unlimited Published Walkthroughs" />
                      <FeatureItem text="Unlimited Snaps" />
                      <FeatureItem text="Unlimited Snaps Shared" />
                      {/* <FeatureItem text="3 Allowed Domains" /> */}
                      <FeatureItem
                        text={`50 MAUs`}
                        tooltip="Monthy Active Users are users who have logged-in to their Usertip account or unique users that are detected through our code snippet installation"
                      />
                      <FeatureItem
                        text={`Unlimited Registered Users per Organization`}
                      />
                      <FeatureItem text={`Usertip watermark displayed`} />
                      <FeatureItem
                        text={`No access to Code Snippet integration`}
                      />
                      {/* <FeatureItem text={`Email Support within 15 working days`} /> */}
                    </>
                  }
                />
                <EmbedPricingCard
                  planTitle={"Starter Plan"}
                  planDesc={`${
                    billingType === "month" ? "" : "Paid yearly. "
                  }For departments or growing companies.`}
                  onClickPricingButton={() => {
                    onHandlingPayment("STARTER_PLAN");
                  }}
                  pricingBtnText={"Subscribe"}
                  price={billingType === "month" ? "USD 99" : "USD 83"}
                  priceUnit={"per month"}
                  disabled={isOrgUsingPlan("STARTER_PLAN")}
                  featureList={
                    <>
                      <FeatureItem text={`Everything in Micro, plus`} />
                      <FeatureItem text={`1000 MAUs`} />
                      <FeatureItem text={`User Segmentation`} />
                      <FeatureItem text={`Viewer Teams`} />
                      <FeatureItem text={`Usertip watermark displayed`} />
                      <FeatureItem
                        text={`Access to Code Snippet integration`}
                      />
                      {/* <FeatureItem text={`Email Support within 7 working days`} /> */}
                    </>
                  }
                />
                <EmbedPricingCard
                  planTitle={"Business Plan"}
                  planDesc={`${
                    billingType === "month" ? "" : "Paid yearly. "
                  }For businesses.`}
                  onClickPricingButton={() => {
                    onHandlingPayment("BUSINESS_PLAN");
                  }}
                  pricingBtnText={"Subscribe"}
                  price={billingType === "month" ? "USD 299" : "USD 249"}
                  priceUnit={"per month"}
                  disabled={isOrgUsingPlan("BUSINESS_PLAN")}
                  featureList={
                    <>
                      <FeatureItem text={`Everything in Starter, plus`} />
                      <FeatureItem text={`5000 MAUs`} />
                      <FeatureItem text={`Walkthrough Analytics`} />
                      <FeatureItem text={`Usertip watermark removed`} />
                      {/* <FeatureItem text={`Email Support within 1 working day`} /> */}
                    </>
                  }
                />
                <EmbedPricingCard
                  planTitle={"Need a Custom Plan?"}
                  planDesc={"Let us know what you need."}
                  onClickPricingButton={onClickContactUs}
                  pricingBtnText={"Let's Talk!"}
                  price={""}
                  priceUnit={""}
                  featureList={
                    <>
                      <FeatureItem
                        text={`Service Level Agreement`}
                        tooltip="Service Level Agreement is a contract that outlines the terms and conditions of the project."
                      />
                      <FeatureItem text={`Custom Integrations`} />
                      <FeatureItem text={`Custom Installation`} />
                      <FeatureItem text={`Custom MAUs`} />
                      <FeatureItem text={`Whitelabeling`} />
                    </>
                  }
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <SubscriptionUpdateSuccess onClick={handleReturnToHome} />
          </>
        )}
      </div>
    </>
  );
};

const SubscriptionUpdateSuccess = ({
  onClick,
}: {
  onClick: (e: any) => void;
}) => {
  return (
    <div className="py-10 px-20 flex flex-col items-center">
      <div className="pageHeader py-10">
        Your subscription has been succesfully updated!
      </div>
      <Button
        size={"medium"}
        variant={"primary"}
        color={"primary"}
        text={"Return to Home"}
        onClick={onClick}
      />
    </div>
  );
};

declare global {
  namespace JSX {
    interface IntrinsicElements {
      "stripe-pricing-table": React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >;
    }
  }
}

export default PageBilling;
