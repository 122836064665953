import {
  Card,
  CardHeader,
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React from "react";
import { not, numberOfChecked, union } from "./_functions/FunctionEditList";

const ComponentEditSharedOrgList = ({ dataSet, checked, setChecked }) => {
  const handleToggleAll = (lists) => () => {
    if (numberOfChecked(checked, lists) === lists.length) {
      setChecked(not(checked, lists));
    } else {
      setChecked(union(checked, lists));
    }
  };
  // Handles each Toggle of Checkbox-Checked/UnChecked
  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };
  return (
    <div className="flex-1 w-44">
      <Card>
        <div className="flex flex-col">
          <div className="flex-row flex-grow w-full">
            <CardHeader
              avatar={
                <Checkbox
                  onClick={handleToggleAll(dataSet)}
                  inputProps={{
                    "aria-label": "all item selected",
                  }}
                />
              }
              title={"Organization name"}
              sx={{
                padding: "10px 10px",
              }}
            />
          </div>

          <div className="flex-row pageDivider my-0" />

          <div className="w-full">
            <List
              sx={{
                height: 230,
                bgcolor: "background.paper",
                overflow: "auto",
              }}
              dense
              component="div"
              role="list"
            >
              {dataSet.length > 0 &&
                dataSet.map((item, index) => {
                  return (
                    <div key={index}>
                      <ListItem
                        role="listitem"
                        button
                        onClick={
                          // Must push the entire Member Object in for the list to work
                          handleToggle(item)
                        }
                        sx={{
                          padding: "0px 10px",
                        }}
                      >
                        <ListItemIcon>
                          <Checkbox
                            checked={
                              // Using the entire Member Object as a Index check
                              checked.indexOf(item) !== -1
                            }
                            tabIndex={-1}
                            disableRipple
                            // for cypress testing
                            testdata={"org " + index}
                          />
                        </ListItemIcon>
                        <ListItemText
                          id={item.host_org_id}
                          primary={item.name}
                        />
                      </ListItem>
                    </div>
                  );
                })}

              <ListItem />
            </List>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default ComponentEditSharedOrgList;
