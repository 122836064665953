import React from "react";
import generateClassName from "../../_functions/functionGenerateClassName";
interface Props {
  /** The text of the title */
  messageText: string;
  classNames?: string;
  size?:
    | "title-xxl"
    | "title-xl"
    | "xl"
    | "title-l"
    | "l"
    | "title-m"
    | "m"
    | "title-s"
    | "s";
  header: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
}
const Title = ({ messageText, classNames, size, header }: Props) => {
  /** Function to get the classnames for the headers */
  const getTextClassnames = (textSize: string | undefined) => {
    switch (textSize) {
      case "title-xxl":
        return "title-xxl";
      case "title-xl":
        return "title-xl";
      case "xl":
        return "xl";
      case "title-l":
        return "title-l";
      case "l":
        return "l";
      case "title-m":
        return "title-m";
      case "m":
        return "m";
      case "title-s":
        return "title-s";
      case "s":
        return "s";
      default:
        return "";
    }
  };

  /** Function to return header tag specified */
  const getHeaderType = (
    headerName: "h1" | "h2" | "h3" | "h4" | "h5" | "h6"
  ) => {
    switch (headerName) {
      case "h1":
        return (
          <h1
            className={`heading ${getTextClassnames(size)} ${generateClassName(
              classNames
            )}`}
          >
            {messageText}
          </h1>
        );
      case "h2":
        return (
          <h2
            className={`heading ${getTextClassnames(size)} ${generateClassName(
              classNames
            )}`}
          >
            {messageText}
          </h2>
        );
      case "h3":
        return (
          <h3
            className={`heading ${getTextClassnames(size)} ${generateClassName(
              classNames
            )}`}
          >
            {messageText}
          </h3>
        );
      case "h4":
        return (
          <h4
            className={`heading ${getTextClassnames(size)} ${generateClassName(
              classNames
            )}`}
          >
            {messageText}
          </h4>
        );
      case "h5":
        return (
          <h5
            className={`heading ${getTextClassnames(size)} ${generateClassName(
              classNames
            )}`}
          >
            {messageText}
          </h5>
        );
      case "h6":
        return (
          <h6 className={`heading ${getTextClassnames(size)} ${classNames}`}>
            {messageText}
          </h6>
        );
      default:
        return <div>Error displaying component</div>;
    }
  };

  return <>{getHeaderType(header)}</>;
};

export default Title;
