import Axios from "axios";
import attachHTTPRequestHeaders from "../../utility/functions/attachHTTPRequestHeaders";
import { api } from "./_axios";

export const callsGETRetrieveTheme = async (orgId: string) => {
  const config = attachHTTPRequestHeaders(true);

  return api
    .get(`/themebuilder/getTheme/${orgId}`, config)
    .then((res) => res)
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const callsPOSTAddUpdateTheme = async (orgId: string, data: any) => {
  const config = attachHTTPRequestHeaders(true);

  return api
    .post(`/themebuilder/addOrUpdate/${orgId}`, data, config)
    .then((res) => res)
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const callsPOSTApplyTheme = async (orgId: string, type: any) => {
  const config = attachHTTPRequestHeaders(true);

  const data = {
    type,
  };

  return api
    .post(`/themebuilder/applyThemeForAllWalkthrough/${orgId}`, data, config)
    .then((res) => res)
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const callsPOSTApplyThemeToPublished = async (
  orgId: string,
  type: any
) => {
  const config = attachHTTPRequestHeaders(true);

  const data = {
    type,
  };

  return api
    .post(
      `/themebuilder/applyThemeForAllPublishedWalkthrough/${orgId}`,
      data,
      config
    )
    .then((res) => res)
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const callsPOSTApplyThemeToUnpublished = async (
  orgId: string,
  type: any
) => {
  const config = attachHTTPRequestHeaders(true);

  const data = {
    type,
  };

  return api
    .post(
      `/themebuilder/applyThemeForAllUnpublishedWalkthrough/${orgId}`,
      data,
      config
    )
    .then((res) => res)
    .catch((err) => {
      return Promise.reject(err);
    });
};
