import React from "react";
import Title from "../../01-atoms/01-typography/Title";
import {
  Status,
  Tags,
  SettingIcon,
  AnalyticIcon,
  ShareIcon,
  Button,
  EditIcon,
} from "usertip-component-library";
import { useNavigate } from "react-router-dom";
import { Visibility } from "@mui/icons-material";
import { resetSnapGuide } from "../../../store/reducers/reducerGuide";
import { AppDispatch } from "../../../store";
import { useDispatch } from "react-redux";

interface Props {
  /** Id of the guide */
  id: string;
  className?: string;
  cardName: string;
  tag: string;
  onClick: (cardName: string) => void;
  status?: string;
  onClickSnapShare?: () => void;
}

const WalkthroughOverviewCard = ({
  id,
  className,
  cardName,
  tag,
  onClick,
  status,
  onClickSnapShare,
}: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  function generateBtnGroup() {
    if (tag === "Walkthrough") {
      return (
        <div className="guide_btn_group">
          <Button
            color="primary"
            size="medium"
            variant="primary"
            iconOnly
            icon={<ShareIcon />}
            text=""
            onClick={() =>
              navigate(`/walkthroughs/${id}/settings/viewing-permissions`)
            }
          />
          <Button
            color="primary"
            size="medium"
            variant="primary"
            iconOnly
            icon={<AnalyticIcon />}
            text=""
            onClick={() => navigate(`/walkthroughs/${id}/analytics`)}
          />

          <Button
            color="primary"
            size="medium"
            variant="primary"
            iconOnly
            icon={<Visibility />}
            text=""
            onClick={() => navigate(`walkthroughs/${id}/settings/general`)}
          />
        </div>
      );
    }

    if (tag === "Snap") {
      return (
        <div className="guide_btn_group">
          <Button
            color="primary"
            size="medium"
            variant="primary"
            iconOnly
            icon={<ShareIcon />}
            text=""
            onClick={() => onClickSnapShare && onClickSnapShare()}
          />
          <Button
            color="primary"
            size="medium"
            variant="primary"
            iconOnly
            icon={<Visibility />}
            text=""
            onClick={() => {
              dispatch(resetSnapGuide());
              navigate(`/guides/snaps/${id}`);
            }}
          />
          <Button
            color="primary"
            size="medium"
            variant="primary"
            iconOnly
            icon={<EditIcon />}
            text=""
            onClick={() => {
              dispatch(resetSnapGuide());
              navigate(`/guides/snaps/${id}/edit`);
            }}
          />
        </div>
      );
    }
    if (tag === "Video") {
      return (
        <div className="guide_btn_group">
          <Button
            color="primary"
            size="medium"
            variant="primary"
            iconOnly
            icon={<Visibility />}
            text=""
            onClick={() => navigate(`/guides/video/${id}`)}
          />
          <Button
            color="primary"
            size="medium"
            variant="primary"
            iconOnly
            icon={<EditIcon />}
            text=""
            onClick={() => navigate(`guides/video/${id}/edit`)}
          />
        </div>
      );
    }
    return <></>;
  }
  return (
    <div className="guide_card_container" onClick={() => onClick(tag)}>
      <Title header="h3" size="m" messageText={cardName} />
      <Tags text={tag} variant="outlined" size="small" clickable />
      {status && <Status variants="warning" size="small" text={status} />}
      {generateBtnGroup()}
    </div>
  );
};

export default WalkthroughOverviewCard;
