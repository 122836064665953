import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import { useParams } from "react-router-dom";
import {
  getSingleSnapGuide,
  verifySnapShareLink,
} from "../store/reducers/reducerGuide";
import GlobalLoading from "../components/UI/Loading/GlobalLoading";
import DefaultHeader from "../components/03-organisms/04-pageHeader/DefaultHeader";
import PrivatePictureViewer from "../components/Templates/PictureViewer/PrivatePictureViewer";
import ForbiddenPermissionView from "../components/Snap/ForbiddenPermissionView";

const PageSharePrivateSnap = () => {
  const snapName = useSelector(
    (state: RootState) => state.guide.snapGuide?.name
  );

  const { appLoading, isPublicSnap } = useSelector(
    (state: RootState) => state.guide
  );

  const dispatch = useDispatch();
  const { shareId, guideId } = useParams();

  //call to check if snap is for public viewing
  useEffect(() => {
    if (shareId && guideId) {
      const reducerParam = {
        shareId: shareId,
        snapId: guideId,
        isNeedToken: true,
      };
      dispatch(verifySnapShareLink(reducerParam));
    }
  }, [dispatch, shareId, guideId]);

  useEffect(() => {
    // Grab the Walkthrough details based on "walkthrough_id", and then Grab the first instance of the results out of the Array to reply as a Walkthrough Object
    if (guideId) {
      /**Dispatch walkthrough name */
      dispatch(getSingleSnapGuide(guideId));
    }
  }, [dispatch, guideId]);
  return (
    <>
      <GlobalLoading />
      {isPublicSnap ? (
        <div className="pageBase">
          <DefaultHeader
            headerTitle={`Picture guide for: ${snapName ? snapName : ""}`}
          />
          <div className="flex justify-center w-full mt-4">
            <PrivatePictureViewer />
          </div>
        </div>
      ) : (
        <ForbiddenPermissionView />
      )}
    </>
  );
};

export default PageSharePrivateSnap;
