import React from "react";

import { Snackbar, Alert } from "@mui/material";

const ComponentAlertAppOverallSnackbar = ({ alert, reset }) => {
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    reset();
  };

  return (
    <Snackbar
      open={alert.active}
      autoHideDuration={alert.autoHideDuration}
      onClose={handleClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    >
      <Alert
        elevation={6}
        variant="filled"
        onClose={handleClose}
        severity={alert.type}
      >
        {alert.message}
      </Alert>
    </Snackbar>
  );
};

export default ComponentAlertAppOverallSnackbar;
