import React, { useState, useEffect } from "react";

import { Table, TableContainer, Box, Paper } from "@mui/material";

import ComponentListTableToolbar from "./ComponentListTableToolbar";
import ComponentListTableHeader from "./ComponentListTableHeader";
import ComponentListTableBody from "./ComponentListTableBody";

import ComponentListTablePagination from "./ComponentListTablePagination";

const ComponentListTable = ({
  listType,
  displayUserRoleFull,
  headerCells,
  dataSet,
  dataObject,

  setSelectedUserList,
  setSelectedTeamList,
  setSelectedWalkthroughList,

  setSelectedGroupsList,
  setSelectedGroupWalkthroughsList,

  setSelectedWalkthroughViewingPermissionsList,
  setSelectedWalkthroughViewerTeamPermissionsList,

  setShowModalRemove,
  setRemoveMode,
  setShowModalMove,
  setMoveMode,

  setShowModalEdit,
  setEditMode,
  setShowModalView,
  setViewMode,

  setShowAlertSnackbar,

  resetTableSelectAll,
  setResetTableSelectAll,

  viewerCSHeader,
  metaDataTypes,
}) => {
  // Variables setup for DataTable
  const [selectedRows, setSelectedRows] = useState([]);
  const [numOfRowsSelected, setNumOfRowsSelected] = useState();
  const [totalRowCount, setTotalRowCount] = useState();

  // sort by name will sort by capital letter first -> then alphabetical
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // Tracks changes in "dataSet.length". Update when new change is available.
  useEffect(() => {
    // For check "dataSet" onLoad to check if "owner" exists.
    // If exists, remove Owner count of 1, to enable "Select All" checkbox to function properly
    if (
      listType === "User" &&
      dataSet.map((user) => user.role).includes("owner")
    ) {
      setNumOfRowsSelected(selectedRows.length);
      setTotalRowCount(dataSet.length - 1);
    }
    // Else, update as per normal
    else {
      setNumOfRowsSelected(selectedRows.length);
      setTotalRowCount(dataSet.length);
    }
  }, [dataSet, listType, selectedRows]);

  // Tracks changes in "resetTableSelectAll", to reset the "Select All" control upon "Bulk" action executed on modal.
  useEffect(() => {
    if (resetTableSelectAll) {
      // Resets the "Selected Rows" within the current Table
      setSelectedRows([]);
      // Resets the control
      setResetTableSelectAll(false);
    }
  }, [resetTableSelectAll, setResetTableSelectAll]);

  // Tracks changes in "listType". Update when new change is available.
  // Auto-Sort data by the respective "key" field
  useEffect(() => {
    if (listType === "User" || listType === "WalkthroughViewingPermissions") {
      setOrderBy("role_id");
    } else if (
      listType === "Team" ||
      listType === "Group" ||
      listType === "Walkthrough" ||
      listType === "GroupWalkthrough" ||
      listType === "WalkthroughViewerTeamPermissions"
    ) {
      setOrderBy("name");
    }
  }, [listType]);

  return (
    <>
      {dataSet.length > 0 && (
        <div style={{ width: "100%", marginTop: "15px", marginBottom: "10px" }}>
          <Box sx={{ width: "100%" }}>
            <Paper sx={{ width: "100%", mb: 5 }}>
              {/* Set up Top Toolbar */}
              <ComponentListTableToolbar
                listType={listType}
                displayUserRoleFull={displayUserRoleFull}
                dataSet={dataSet}
                setSelectedUserList={setSelectedUserList}
                setSelectedTeamList={setSelectedTeamList}
                setSelectedWalkthroughList={setSelectedWalkthroughList}
                setSelectedGroupsList={setSelectedGroupsList}
                setSelectedGroupWalkthroughsList={
                  setSelectedGroupWalkthroughsList
                }
                setSelectedWalkthroughViewingPermissionsList={
                  setSelectedWalkthroughViewingPermissionsList
                }
                setSelectedWalkthroughViewerTeamPermissionsList={
                  setSelectedWalkthroughViewerTeamPermissionsList
                }
                setShowModalRemove={setShowModalRemove}
                setRemoveMode={setRemoveMode}
                setShowModalMove={setShowModalMove}
                setMoveMode={setMoveMode}
                setShowModalEdit={setShowModalEdit}
                setEditMode={setEditMode}
                selectedRows={selectedRows}
                numOfRowsSelected={numOfRowsSelected}
              />

              {/* Set up Container where Table of Data is being displayed */}
              <TableContainer>
                <Table sx={{ minWidth: 750 }} size={"small"}>
                  {/* Setting Header Row */}
                  <ComponentListTableHeader
                    listType={listType}
                    headerCells={headerCells}
                    dataSet={dataSet}
                    setSelectedRows={setSelectedRows}
                    numOfRowsSelected={numOfRowsSelected}
                    setNumOfRowsSelected={setNumOfRowsSelected}
                    totalRowCount={totalRowCount}
                    order={order}
                    setOrder={setOrder}
                    orderBy={orderBy}
                    setOrderBy={setOrderBy}
                  />

                  <ComponentListTableBody
                    metaDataTypes={metaDataTypes}
                    viewerCSHeader={viewerCSHeader}
                    listType={listType}
                    displayUserRoleFull={displayUserRoleFull}
                    dataSet={dataSet}
                    dataObject={dataObject}
                    setSelectedUserList={setSelectedUserList}
                    setSelectedTeamList={setSelectedTeamList}
                    setSelectedWalkthroughList={setSelectedWalkthroughList}
                    setSelectedGroupsList={setSelectedGroupsList}
                    setSelectedGroupWalkthroughsList={
                      setSelectedGroupWalkthroughsList
                    }
                    setSelectedWalkthroughViewingPermissionsList={
                      setSelectedWalkthroughViewingPermissionsList
                    }
                    setSelectedWalkthroughViewerTeamPermissionsList={
                      setSelectedWalkthroughViewerTeamPermissionsList
                    }
                    setShowModalRemove={setShowModalRemove}
                    setRemoveMode={setRemoveMode}
                    setShowModalMove={setShowModalMove}
                    setMoveMode={setMoveMode}
                    setShowModalEdit={setShowModalEdit}
                    setEditMode={setEditMode}
                    setShowModalView={setShowModalView}
                    setViewMode={setViewMode}
                    setShowAlertSnackbar={setShowAlertSnackbar}
                    selectedRows={selectedRows}
                    setSelectedRows={setSelectedRows}
                    setNumOfRowsSelected={setNumOfRowsSelected}
                    order={order}
                    orderBy={orderBy}
                    page={page}
                    rowsPerPage={rowsPerPage}
                  />
                </Table>
              </TableContainer>

              {/* Bottom of Table Pagination */}
              <ComponentListTablePagination
                dataSet={dataSet}
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
              />
            </Paper>
          </Box>
        </div>
      )}
    </>
  );
};

export default ComponentListTable;
