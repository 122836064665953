import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import PageHomeDefaultBasePage from "../../pages/PageTemplates/PageHomeDefaultBasePage";
import { UserData } from "../../store/reducers/_reducerIndex";
import PageHomeViewerBasePage from "../../pages/PageTemplates/PageHomeViewerBasePage";
import { RootState } from "../../store";
import OnboardingChecklist from "../OnBoarding/OnBoardingCheckList";
import PageHomeFullscreen from "../../pages/PageTemplates/PageHomeFullscreen";
import { differenceInDays } from "date-fns";
import { Status } from "usertip-component-library";
import PageTrialOver from "../../pages/PageTrialOver";
import { ONBOARDING_FLOW_PAGE_PATH } from "../../App";

const ProtectedRoute = ({
  children,
  localStorageAuth,
  user,
  location,
}: {
  children: any;
  localStorageAuth: string | null;
  user: UserData;
  location: any;
}) => {
  const { is_onboarded } = useSelector(
    (state: RootState) => state.user.userData
  );
  let route = <div>Page Error</div>;
  const navigate = useNavigate();

  const { isAuthenticated } = useSelector((state: RootState) => state.auth);

  const { can_trial, paid_status, trialEndDate } = useSelector(
    (state: RootState) => state.org
  );

  const [dateDiff, setDateDiff] = useState<null | number>(null);

  const [isBillingPage, setIsBillingPage] = useState(false);

  const [isOnboardingInviteFlow, setIsOnboardingInviteFlow] = useState(false);

  useEffect(() => {
    // console.log(
    //   "Protected Route Location changed",
    //   window.location.href,
    //   new URL(window.location.href).pathname
    // );
    const currentPath = new URL(window.location.href).pathname;
    if (currentPath === "/billing/subscription") {
      setIsBillingPage(true);
    } else {
      setIsBillingPage(false);
    }
  }, [location]);

  useEffect(() => {
    const currentPath = new URL(window.location.href).pathname;
    if (currentPath === ONBOARDING_FLOW_PAGE_PATH) {
      setIsOnboardingInviteFlow(true);
    } else {
      setIsOnboardingInviteFlow(false);
    }
  }, [location]);

  useEffect(() => {
    if (can_trial && paid_status === "trialing" && trialEndDate) {
      const today = new Date();
      const trialDate = new Date(trialEndDate);
      const diff = differenceInDays(trialDate, today) + 1;
      setDateDiff(diff);
    }
  }, [can_trial, paid_status, trialEndDate]);

  if (isAuthenticated && localStorageAuth) {
    /** If admin or builder role, return PageHomeDefaultBasePage */
    if (user && user.role !== "viewer") {
      route = isUserAllowedAccess(can_trial, paid_status) ? (
        <>
          <PageHomeDefaultBasePage user={user}>
            {trialEndDate && dateDiff && (
              <div className="relative">
                <div className="absolute -top-11 left-0 right-0">
                  <Status
                    variants="warning"
                    size="fluid"
                    text={`Your organization has ${dateDiff} days of trial left!`}
                  />
                </div>
              </div>
            )}
            {children}
            {is_onboarded === null ? (
              <></>
            ) : (
              !isOnboardingInviteFlow &&
              !is_onboarded && <OnboardingChecklist />
            )}
          </PageHomeDefaultBasePage>
        </>
      ) : (
        // allow billing page to be seen, if not show trial expire page
        <PageHomeFullscreen>
          <>
            {isBillingPage ? (
              <>{children}</>
            ) : (
              <>{trialEndDate !== null && <TrialExpireComponent />}</>
            )}
          </>
        </PageHomeFullscreen>
      );
    }

    /** If user haven't onboard, make the fullscreen version view */
    if (user && !user.is_onboarded) {
      route = (
        <PageHomeFullscreen>
          {isUserAllowedAccess(can_trial, paid_status) ? (
            <>
              {trialEndDate && dateDiff && (
                <div className="relative">
                  <div className="absolute -top-11 left-0 right-0">
                    <Status
                      variants="warning"
                      size="fluid"
                      text={`Your organization has ${dateDiff} days of trial left!`}
                    />
                  </div>
                </div>
              )}
              {children}
              {is_onboarded === null ? (
                <></>
              ) : (
                !isOnboardingInviteFlow &&
                !is_onboarded && <OnboardingChecklist />
              )}
            </>
          ) : (
            // allow billing page to be seen, if not show trial expire page
            <>
              {isBillingPage ? (
                <>{children}</>
              ) : (
                <>{trialEndDate !== null && <TrialExpireComponent />}</>
              )}
            </>
          )}
        </PageHomeFullscreen>
      );
    }

    /** If viewer role, return PageHomeDefaultBasePage */
    if (user && user.role === "viewer") {
      route = (
        <PageHomeViewerBasePage user={user}>
          {isUserAllowedAccess(can_trial, paid_status) ? (
            <>
              {trialEndDate && dateDiff && (
                <div className="relative">
                  <div className="absolute -top-11 left-0 right-0">
                    <Status
                      variants="warning"
                      size="fluid"
                      text={`Your organization has ${dateDiff} days of trial left!`}
                    />
                  </div>
                </div>
              )}
              {children}
            </>
          ) : (
            <>
              {/* TODO: Tell viewer that they need to contact their admin, instead of generic.
                  This is because viewers cannot subscribe.
              */}
              {/* Don't need to show billing page if user is a viewer */}
              {trialEndDate !== null && <TrialExpireComponent />}
            </>
          )}
        </PageHomeViewerBasePage>
      );
    }
  } else {
    route = <Navigate to="/login" state={{ from: location }} replace />;
  }

  return route;
};

// Function to check if user has a trial, subscription, or has cancelled/expired trial
export const isUserAllowedAccess = (
  can_trial: boolean,
  paid_status: boolean | "active" | "cancelled" | "trialing" | "pending"
) => {
  let returnVal = false;
  if (!can_trial && paid_status === "trialing") {
    returnVal = false;
  }

  if (can_trial && paid_status === "trialing") {
    returnVal = true;
  }

  if (paid_status === "active") {
    returnVal = true;
  }

  if (paid_status === "cancelled") {
    returnVal = false;
  }

  // console.log("---isUserAllowedAccess", returnVal, can_trial, paid_status);

  return returnVal;
};

const TrialExpireComponent = () => {
  return (
    <>
      <Status
        variants="warning"
        size="fluid"
        text={`Your trial has expired. Subscribe to unlock features.`}
      />
      <PageTrialOver isLogoutButton />
    </>
  );
};

export default ProtectedRoute;
