import React, { useEffect, useRef, useState } from "react";
import SidebarItem from "../../01-atoms/02-buttons/SidebarItem";
import Logo from "../../01-atoms/02-buttons/Logo";
import NavigationProfile from "../../02-molecules/04-navigationProfile/NavigationProfile";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { Popup, PopupItem } from "../../02-molecules/05-Popup/Popup";
import { Button, LogoutIcon, Modal } from "usertip-component-library";
import { authSignOutUserAccount } from "../../../store/reducers/reducerAuth";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";

import AccountTreeIcon from "@mui/icons-material/AccountTree";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import AdUnitsIcon from "@mui/icons-material/AdUnits";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import InstallDesktopIcon from "@mui/icons-material/InstallDesktop";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import CollectionsIcon from "@mui/icons-material/Collections";
import { PersonAdd } from "@mui/icons-material";
import InviteMembersModal from "../../InviteMembersModal/InviteMembersModal";
import { setIsInviteFailed } from "../../../store/reducers/reducerOrganization";
import PaletteIcon from "@mui/icons-material/Palette";
import CameraIcon from "@mui/icons-material/Camera";
import { showHideNavbar } from "../../../store/reducers/reducerNavigation";
import MenuIcon from "@mui/icons-material/Menu";

/** This interface is blueprint for sidebarItem */
interface sideItemCategories {
  /** Set the category of sideitem */
  categories: string;
  /** Set the sideItem by category */
  item: sideItem[];
}

/** This interface is blueprint for iten att for sideItemCategories  */
interface sideItem {
  /** This is for key props and to differentiate sideItem */
  id: number;
  /** This is for sideItem label */
  title: string;
  /** This is for sideItem path when clicked */
  link: string;
  /** This is for sideItem icon */
  icon?: string;
}

/** This array is for map the sidebarItem (Change the sidebar item in this array) */
let sidebarItem: sideItemCategories[] = [
  {
    categories: "guides",
    item: [
      {
        id: 1,
        title: "Guides",
        link: "/guides/all",
        icon: "AccountTreeIcon",
      },
      {
        id: 2,
        title: "Walkthroughs",
        link: "/guides/walkthroughs",
        icon: "AccountTreeIcon",
      },
      {
        id: 3,
        title: "Host Walkthrough",
        link: "/guides/walkthroughs/host-walkthroughs",
        icon: "AccountTreeIcon",
      },
      // {
      //   id: 4,
      //   title: "Video Guides",
      //   link: "/guides/video-guides",
      //   icon: "AccountTreeIcon",
      // },
      {
        id: 5,
        title: "Snaps",
        link: "/guides/snaps",
        icon: "CameraIcon",
      },
    ],
  },
  {
    categories: "access",
    item: [
      {
        id: 6,
        title: "Guide Permissions",
        link: "/guide-permissions",
        icon: "CorporateFareIcon",
      },
      {
        id: 7,
        title: "Segmentation",
        link: "/segmentation",
        icon: "ManageAccountsIcon",
      },
      {
        id: 8,
        title: "Teams",
        link: "/teams/viewers",
        icon: "GroupWorkIcon",
      },
    ],
  },
  {
    categories: "guest-organization",
    item: [
      {
        id: 9,
        title: "Walkthrough Shared",
        link: "/guest-organization/walkthrough-shared",
        icon: "CorporateFareIcon",
      },
    ],
  },
  {
    categories: "delivery",
    item: [
      {
        id: 10,
        title: "Assistant",
        link: "/assistant/settings",
        icon: "AdUnitsIcon",
      },
      {
        id: 11,
        title: "Images",
        link: "/media-manager/images",
        icon: "CollectionsIcon",
      },
    ],
  },
  {
    categories: "organization",
    item: [
      {
        id: 12,
        title: "Organization",
        link: "/organization",
        icon: "PeopleAltIcon",
      },
      {
        id: 13,
        title: "Theme",
        link: "/theme",
        icon: "PaletteIcon",
      },
    ],
  },
];

interface PopupItem {
  /** This is for key props and to differentiate sideItem */
  id: number;
  /** This is for sideItem label */
  title?: string;
  /** This is for sideItem path when clicked */
  link?: string;
  /** This is for sideItem icon */
  icon?: any;
  /** This is for sideItem type */
  type: "spacer" | "link" | "default";
  /** This is for set a function when there's function when the item clicked */
  onClick?: () => void;
}

/** This array is for map the item to user popup (Change the userItem in this array) */
const userItem: PopupItem[] = [
  {
    id: 1,
    title: "Account Settings",
    link: "/account/settings",
    type: "default",
  },
  {
    id: 2,
    title: "Billing",
    link: "/billing",
    type: "default",
  },
  {
    id: 3,
    type: "spacer",
  },
  {
    id: 4,
    title: "Logout",
    icon: <LogoutIcon />,
    onClick: () => {
      authSignOutUserAccount();
    },
    type: "default",
  },
];

/** This array is for map the item to user popup (Change the helpItem in this array) */
const helpItem: PopupItem[] = [
  {
    id: 1,
    title: "SPA Installation",
    link: "/code-snippet-installation/spa",
    type: "default",
  },
  {
    id: 2,
    title: "MPA Installation",
    link: "/code-snippet-installation/mpa",
    type: "default",
  },
  {
    id: 3,
    title: "Download Builder",
    link: "https://chrome.google.com/webstore/detail/usertip-builder/ceiihcjapecalcblnbcodnnkpbdbpbem?hl=en",
    type: "link",
  },
  {
    id: 4,
    title: "Download Launcher",
    link: "https://chrome.google.com/webstore/detail/usertip-launcher/bfnanoabpjghcopngcdkladpoeijbgff?hl=en",
    type: "link",
  },
  {
    id: 5,
    title: "User Guide",
    link: "https://usertip.notion.site/Usertip-Guide-9debaeb3c94848e580a281ce0bb1ccd1",
    type: "link",
  },
  {
    id: 6,
    title: "Onboarding Guide",
    link: "/onboarding",
    type: "default",
  },
  {
    id: 7,
    title: "Ask for help?",
    link: "mailto:support@usertip.com",
    type: "link",
  },
];

/** This props is for MapSideItem Template */
interface MapSideItemProps {
  /** This prop is for sidebarItem array */
  items: sideItemCategories[];
}

/** This is the template for mapping the sidebarItem */
const MapSideItem = ({ items }: MapSideItemProps) => {
  const navigate = useNavigate();

  return (
    <>
      {items.map((val, index) => {
        if (index === sidebarItem.length - 1) {
          return (
            <React.Fragment key={index}>
              {val.item.map((val) => {
                return (
                  <SidebarItem
                    name="navigation"
                    label={val.title}
                    key={val.id}
                    onClick={() => {
                      navigate(val.link);
                    }}
                    icon={generateIcon(val.icon)}
                  />
                );
              })}
            </React.Fragment>
          );
        }

        if (val.item.length > 0) {
          return (
            <React.Fragment key={index}>
              {val.item.map((val) => {
                return (
                  <SidebarItem
                    name="navigation"
                    label={val.title}
                    key={val.id}
                    onClick={() => {
                      navigate(val.link);
                    }}
                    icon={generateIcon(val.icon)}
                  />
                );
              })}
              <div className="w-60 h-[1px] bg-default-neutral-60 my-3"></div>
            </React.Fragment>
          );
        }
      })}
    </>
  );
};

/** This props is for MapPopupItem Template */
interface MapPopupItemProps {
  /** This prop is for sidebarItem array */
  items: PopupItem[];
  /** This prop is gonna set state to false */
  closePopup?: React.Dispatch<React.SetStateAction<boolean>>;
}

/** This is the template for mapping the PopupItem */
const MapPopupItem = ({ items, closePopup }: MapPopupItemProps) => {
  const navigate = useNavigate();

  return (
    <Popup>
      {items.map((val) => {
        if (val.type == "link") {
          return (
            <PopupItem
              key={val.id}
              icon={val.icon}
              href={val.link}
              label={val.title}
              type={val.type}
            />
          );
        }

        return (
          <PopupItem
            key={val.id}
            type={val.type}
            label={val.title}
            icon={val.icon}
            onClick={() => {
              if (closePopup) {
                closePopup(false);
              }

              if (val.link) {
                navigate(val.link);
              }

              if (val.onClick) {
                val.onClick();
              }
            }}
          />
        );
      })}
    </Popup>
  );
};

/** This array is for set the icon */
const generateIcon: any = (icon: string) => {
  switch (icon) {
    case "AccountTreeIcon":
      return <AccountTreeIcon />;
    case "PeopleAltIcon":
      return <PeopleAltIcon />;
    case "GroupWorkIcon":
      return <GroupWorkIcon />;
    case "CorporateFareIcon":
      return <CorporateFareIcon />;
    case "AdUnitsIcon":
      return <AdUnitsIcon />;
    case "InstallDesktopIcon":
      return <InstallDesktopIcon />;
    case "ManageAccountsIcon":
      return <ManageAccountsIcon />;
    case "CollectionsIcon":
      return <CollectionsIcon />;
    case "PaletteIcon":
      return <PaletteIcon />;
    case "CameraIcon":
      return <CameraIcon />;
    default:
      return;
  }
};

const Navigation = () => {
  // this is for handling something on navigation when screen smaller than 1240px
  const showNavbarSmallScreen = useSelector(
    (state: RootState) => state.navigation.showHideNavbar
  );

  /** This state for set show the Avatar Popup */
  const [avatarIsClick, setAvatarIsClick] = useState<boolean>(false);
  /** This state for set show the Need Help Popup */
  const [helpIsClick, setHelpIsClick] = useState<boolean>(false);

  const sideItemContRef = useRef<HTMLDivElement>(null);

  /** To get user email and name */
  const { email } = useSelector((state: RootState) => state.user.userData);
  const { name } = useSelector((state: RootState) => state.org);

  /** This is for get the feature plan access */
  const walkthroughShared_feature_access = useSelector(
    (state: RootState) =>
      state.org.org_plan?.org_feature_plan?.guest_organization
  );
  const viewerTeam_feature_access = useSelector(
    (state: RootState) => state.org.org_plan?.org_feature_plan?.viewer_team
  );

  const userSegmentation_feature_access = useSelector(
    (state: RootState) =>
      state.org.org_plan?.org_feature_plan?.user_segmentation
  );

  const orgIsHost = useSelector((state: RootState) => state.org.isHost);

  const orgIsGuest = useSelector((state: RootState) => state.org.isGuest);

  /** This is for change the style of activated sidebarItem */
  useEffect(() => {
    const sideItemChild: ChildNode[] = [];
    let sideItemList: sideItem[] = [];

    /** This is for remove the sidebar spacer in sideItemChild */
    sideItemContRef.current?.childNodes.forEach((val) => {
      if (val.firstChild) {
        sideItemChild.push(val);
      }
    });

    /** This is for remove categories and get the item attribute only */
    sidebarItem.forEach((val) => {
      sideItemList = [...sideItemList, ...val.item];
    });

    /** This is for make button active by the router path */
    sideItemList.forEach((val, index) => {
      if (window.location.pathname === val.link) {
        /** Get the radio input */
        const itemInput = sideItemChild![index].firstChild as HTMLInputElement;
        itemInput.checked = true;
      } else {
        /** This is for make button active even the router have child */
        const linkArr = val.link.split("/").slice(1);
        const windowPathArr = window.location.pathname
          .split("/")
          .slice(1, linkArr.length + 1);

        if (val.link === `/${windowPathArr.join("/")}`) {
          const itemInput = sideItemChild![index]
            .firstChild as HTMLInputElement;
          itemInput.checked = true;
        }
      }
    });

    /** This is for set the first child button active when the path is "/" */
    // if (window.location.pathname === "/") {
    //   const firstChild = sideItemChild![0].firstChild as HTMLInputElement;
    //   firstChild.checked = true;
    // }

    /** This is for close the popup when  */
    window.addEventListener("click", function () {
      setAvatarIsClick(false);
      setHelpIsClick(false);
    });

    /** This is for get the router path that not a sidebarItem component path */
    const notItem = [...userItem, ...helpItem]
      .filter((val) => {
        return val.type === "default";
      })
      .filter((val) => {
        return val.link;
      });

    /** This is to disable all actived sidebarItem component when router path not a sidebarItem component path */
    notItem.forEach((val) => {
      if (window.location.pathname === val.link) {
        sideItemChild.forEach((val) => {
          const itemInput = val.firstChild as HTMLInputElement;
          itemInput.checked = false;
        });
      }
    });
  }, [avatarIsClick, helpIsClick, window.location.pathname]);

  /** This is for change the user plan access (set all of the features on sidebarItem array, remove the features by user plan access using filter in this useEffect)*/
  useEffect(() => {
    /** This is for check the sidebarItem array */
    sidebarItem.forEach((val) => {
      /** check the userplan if it not have a access. when the page reload the value will undefined so use (FEATURE_ACCESS === "false") instead of (!FEATURE_ACCESS) */
      if (viewerTeam_feature_access === false) {
        /** Search the categories where you set the object for sideItem that want to delete */
        if (val.categories === "teams") {
          /** Delete sideItem using filter (delete using object id) */
          val.item = val.item.filter((val) => {
            return val.id !== 8;
          });
        }
      }

      if (userSegmentation_feature_access === false) {
        if (val.categories === "access") {
          val.item = val.item.filter((val) => {
            return val.id !== 7;
          });
        }
      }

      if (walkthroughShared_feature_access === false || orgIsHost === false) {
        if (val.categories === "guest-organization") {
          val.item = val.item.filter((val) => {
            return val.id !== 9;
          });
        }
      }

      if (orgIsHost === false) {
        if (val.categories === "guides") {
          val.item = val.item.filter((val) => {
            return val.id !== 3;
          });
        }
      }
    });
  }, [
    viewerTeam_feature_access,
    userSegmentation_feature_access,
    walkthroughShared_feature_access,
    orgIsGuest,
    orgIsHost,
  ]);

  const [showInviteModal, setShowInviteModal] = useState(false);

  const dispatch = useDispatch();

  const handleInviteModal = () => {
    setShowInviteModal(true);
    // set state of isInviteFailed to null
    dispatch(setIsInviteFailed(null));
  };

  // const inviteFailed = useSelector(
  //   (state: RootState) => state.org.isInviteFailed
  // );
  // console.log("------", inviteFailed);

  return (
    <div
      className={`${
        showNavbarSmallScreen &&
        "transform -translate-x-4 -translate-y-[41px] fixed"
      } w-[18.75em] h-screen bg-default-neutral-20 border-r-2 border-default-neutral-20 flex flex-col fixed z-50 overflow-y-auto`}
    >
      <div className="py-4 border-b-[1px] border-default-neutral-30 w-full">
        <div
          className="pt-0 pl-4 absolute cursor-pointer xl2:hidden"
          onClick={() => dispatch(showHideNavbar(false))}
        >
          <MenuIcon />
        </div>
        <div
          className="flex items-center justify-center cursor-pointer"
          onClick={() => {
            window.location.pathname = "/";
          }}
        >
          <Logo />
          <h1 className="font-bold text-[1.125em]/[1.35em]">Usertip</h1>
        </div>
      </div>

      <div
        className="flex flex-col items-center w-full mx-auto px-5"
        ref={sideItemContRef}
        id="sideMenu"
      >
        <MapSideItem items={sidebarItem} />
      </div>
      {/* <Button
        text="Invite Member"
        iconRight
        icon={<PersonAdd />}
        size="small"
        variant="primary"
        color="primary"
        onClick={() => handleInviteModal()}
        customClass={"ut-btn-invite"}
      /> */}
      <NavigationProfile
        name={name ? name : ""}
        email={email}
        active={avatarIsClick}
        avatarOnClick={() => {
          setAvatarIsClick(!avatarIsClick);
          setHelpIsClick(false);
        }}
        buttonOnClick={() => {
          setHelpIsClick(!helpIsClick);
          setAvatarIsClick(false);
        }}
      />

      <div
        className={`${
          showNavbarSmallScreen
            ? "absolute bottom-[60px] left-[110px] border border-[#d3d3d3] rounded-lg"
            : "fixed bottom-[0.375em] left-[19.5em]"
        } `}
      >
        {avatarIsClick && (
          <MapPopupItem items={userItem} closePopup={setAvatarIsClick} />
        )}

        {helpIsClick && (
          <MapPopupItem items={helpItem} closePopup={setHelpIsClick} />
        )}
      </div>
      {showInviteModal && (
        <InviteMembersModal close={() => setShowInviteModal(false)} />
      )}
    </div>
  );
};

export default Navigation;
