import * as actionTypes from './_actionTypes';

// Set app alert action.
export const setAlert = (alert) => {
    return {
        type: actionTypes.SET_ALERT,
        alert,
    };
};

// Reset app alert action.
export const resetAlert = () => {
    return {
        type: actionTypes.RESET_ALERT,
    };
};