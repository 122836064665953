import attachHTTPRequestHeaders from "../../utility/functions/attachHTTPRequestHeaders";
import { api } from "./_axios";

/*
    ===================================================================    
    API 2.0: API-Calls To Server
    ===================================================================
    1. api_p1 is default for all API 2.0 calls
    2. api_p2 is now only for /signIn route  
    ===================================================================    
    CALLS-GROUP WALKTHROUGH PERMISSIONS API
    ===================================================================
*/

export const callPOSTAddIndividualPermissionsToSingleGroupWalkthrough = async (
  walkthrough_group_id,
  user
) => {
  const config = attachHTTPRequestHeaders(true);
  const data = {
    resource_id: walkthrough_group_id,
    resource_type: "walkthrough_group",
    plugin: true,
    code_snippet: true,
    user: user,
  };

  return api
    .post(`walkthrough/permission/create`, data, config)
    .then((res) => res)
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const callPOSTAddViewingTeamPermissionsToSingleGroupWalkthrough = async (
  walkthrough_group_id,
  viewer_team_id
) => {
  const config = attachHTTPRequestHeaders(true);
  const data = {
    resource_id: walkthrough_group_id,
    resource_type: "walkthrough_group",
    viewer_team_id: viewer_team_id,
    plugin: true,
    code_snippet: true,
  };

  return api
    .post(`walkthrough/permission/create`, data, config)
    .then((res) => res)
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const callGETAllPermissionsFromSingleGroupWalkthrough = async (
  walkthrough_group_id
) => {
  const config = attachHTTPRequestHeaders(true);
  return api
    .get(`walkthrough/group/permission/all/${walkthrough_group_id}`, config)
    .then((res) => res)
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const callPOSTBulkAddOrRemoveIndividualPermissionsFromSingleGroupWalkthrough =
  async (walkthrough_group_id, users) => {
    const config = attachHTTPRequestHeaders(true);
    const data = {
      resource_id: walkthrough_group_id,
      resource_type: "walkthrough_group",
      users: users,
    };

    return api
      .post(`walkthrough/permission/viewing/individuals/update`, data, config)
      .then((res) => res)
      .catch((err) => {
        return Promise.reject(err);
      });
  };

export const callPOSTBulkAddOrRemoveTeamPermissionsFromSingleGroupWalkthrough =
  async (walkthrough_group_id, teams) => {
    const config = attachHTTPRequestHeaders(true);
    const data = {
      resource_id: walkthrough_group_id,
      resource_type: "walkthrough_group",
      teams: teams,
    };

    return api
      .post(`walkthrough/permission/viewing/teams/update`, data, config)
      .then((res) => res)
      .catch((err) => {
        return Promise.reject(err);
      });
  };
