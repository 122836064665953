import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ComponentListHostWalkthroughs from "../components/ComponentListHostWalkthroughs";
import ComponentLoading from "../components/ComponentLoading";
import { RootState } from "../store";
import { organizationGetSharedHostWalkthrough } from "../store/reducers/reducerOrganization";

const PageHostWalkthrough = () => {
  const userOrgID = useSelector((state: RootState) => state.user.userData.org);

  const isLoading = useSelector((state: RootState) => state.org.appLoading);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(organizationGetSharedHostWalkthrough(userOrgID!));
  }, [dispatch, userOrgID]);

  const hostListWalkthroughs = useSelector(
    (state: RootState) => state.org.sharedHostWalkthrough
  );

  return (
    <div className="pageBase">
      <ComponentLoading show={isLoading} />
      <ComponentListHostWalkthroughs
        displayShowAddBtn={true}
        introMsg={"View and manage host walkthroughs here."}
        indicatorMsg={"No walkthroughs found."}
        dataSet={hostListWalkthroughs}
        orgID={userOrgID!}
      />
    </div>
  );
};

export default PageHostWalkthrough;
