import React, { MouseEventHandler, useEffect, useState } from "react";

import ComponentAlertSnackbar from "../ComponentAlertSnackbar";
import { validateEmailFormat } from "../../utility/functions/validation";
import { useDispatch, useSelector } from "react-redux";
import { paymentPOSTUpdateInvoiceEmail } from "../../store/reducers/reducerPayment";
import { RootState } from "../../store";
import { EmptyModal, Button, InputField } from "usertip-component-library";

interface Props {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const ComponentUpdateInvoiceEmailModal = ({
  showModal,
  setShowModal,
}: Props) => {
  const dispatch = useDispatch();

  /** Get orgID to from the reducer.*/
  const orgID = useSelector((state: RootState) => state.org.org_id);

  /** Email error state management. */
  const [emailError, setEmailError] = useState({ option: false, message: "" });

  /** Email state management. */
  const [email, setEmail] = useState<string>("");

  const [showAlertSnackbar, setShowAlertSnackbar] = useState(false);

  /** State management to allow user to update the email. */
  const [confirmBtnDisabled, setConfirmBtnDisabled] = useState(true);

  const emailDefaultInfoText = "";

  const emailDefaultInvalidFormat = "Wrong email format.";

  // Walkthrough name field change handler.
  const emailChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.trim();

    // Only allow Walkthrough names more than 3 characters
    if (value.length > 0) {
      setConfirmBtnDisabled(false);

      setEmail(value);
      resetEmailErrorHandler();
    } else {
      setConfirmBtnDisabled(true);
      setEmailErrorHandler(emailDefaultInvalidFormat);
    }
  };

  /** Function to set email errors. */
  const setEmailErrorHandler = (message: string) => {
    setEmailError({ option: true, message });
  };

  /** Reset email error. */
  const resetEmailErrorHandler = () => {
    setEmailError({ option: false, message: "" });
  };

  /** Handler to submit new invoice email information. */
  const handleUpdateInvoiceEmail = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();

    // Check if all fields have valid content.
    if (validateEmailField()) {
      const userData = {
        email: email.toLowerCase(),
        orgID: orgID!,
      };
      dispatch(paymentPOSTUpdateInvoiceEmail(userData));
    }
  };

  // Modal close handler
  const handleHideModal = () => {
    setShowAlertSnackbar(false);
    resetEmailErrorHandler();
    setEmail("");
    setShowModal(false);
  };

  /** Function to handle validation of email */
  const validateEmailField = () => {
    const isEmpty = !email.trim();
    const isValidated = validateEmailFormat(email.toLowerCase().trim());
    if (isEmpty) {
      setEmailErrorHandler("*Email required");
    } else if (!isValidated) {
      setEmailErrorHandler("*Invalid email");
    } else {
      resetEmailErrorHandler();
    }

    return !isEmpty && isValidated;
  };

  /** On modal init, set email to empty string */
  useEffect(() => {
    setEmail("");
  }, []);

  return (
    <EmptyModal
      isOpen={showModal}
      onClose={handleHideModal}
      size="large"
      customClass="ut-py-5"
    >
      <div className="modalHeader">Update invoice contact</div>

      <div className="modalForm">
        <div className="modalInputTextfield">
          <InputField
            variant="full"
            autoFocus={true}
            placeholder="Enter new email"
            onChange={emailChangeHandler}
            error={emailError.option}
            errorText={emailError.message}
            value={email}
            customWidth="ut-w-full"
            helperText={emailDefaultInfoText}
          />
        </div>

        <div className="flex justify-end gap-2">
          <Button
            onClick={handleHideModal}
            variant="primary"
            color="primary"
            size="medium"
            text="Cancel"
          />

          <Button
            //@ts-ignore
            onClick={handleUpdateInvoiceEmail}
            disabled={confirmBtnDisabled}
            variant="primary"
            color="primary"
            size="medium"
            text="Update"
          />
        </div>
      </div>

      <ComponentAlertSnackbar
        showAlertSnackbar={showAlertSnackbar}
        setShowAlertSnackbar={setShowAlertSnackbar}
        variant="error"
        title="Walkthrough Name already exists. Please use another name."
      />
    </EmptyModal>
  );
};

export default ComponentUpdateInvoiceEmailModal;
