import attachHTTPRequestHeaders from "../../utility/functions/attachHTTPRequestHeaders";
import { api } from "./_axios";

/*
    ===================================================================    
    CALLS-GUEST ORGANIZATION API
    ===================================================================
*/

export const callGETHostListWalkthrough = async (user_org_id) => {
  const config = attachHTTPRequestHeaders(true);
  return api
    .get(`sharedWalkthrough/getHostListWalkthrough/${user_org_id}`, config)
    .then((res) => res)
    .catch((err) => err);
};

export const callPostDuplicateWalkthrough = async (
  org_id,
  owner,
  duplicateWalkthrough
) => {
  const config = attachHTTPRequestHeaders(true);

  const renameKey = duplicateWalkthrough.map(
    ({ walkthrough_name: walkthroughName, walkthrough_id }) => ({
      walkthroughName,
      walkthrough_id,
    })
  );
  const data = {
    org_id: org_id,
    owner: owner,
    duplicateWalkthrough: renameKey,
  };

  return api
    .post(`walkthrough/duplicate/`, data, config)
    .then((res) => res)
    .catch((err) => err);
};

export const callPostUpdateStatus = async (walkthroughList) => {
  const config = attachHTTPRequestHeaders(true);

  const renameKey = walkthroughList.map(
    ({ sharedWalkthrough_id: sharedWalkthroughId, status }) => ({
      sharedWalkthroughId,
      status,
    })
  );

  const data = renameKey;
  console.log(data);

  return api
    .post(`sharedWalkthrough/updateSharedWalkthroughStatus/`, data, config)
    .then((res) => res)
    .catch((err) => err);
};
