import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { useNavigate } from "react-router-dom";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Button, EditIcon, Status } from "usertip-component-library";
import ShareIcon from "@mui/icons-material/Share";
import { convertDateToStandardFormat } from "../_functions/FunctionDate";
import { Delete, Visibility } from "@mui/icons-material";
import { resetSnapGuide } from "../../store/reducers/reducerGuide";
import { SnapItems } from "../../interfaces/interfaceGuide";
import ComponentRemoveSnap from "../ComponentRemoveSnap";

interface Props {
  snapData: SnapItems[];
}

const TableAllSnaps = ({ snapData }: Props) => {
  const [selectedRowIds, setSelectedRowIds] = useState<SnapItems[]>([]);
  const [singleData, setSingleData] = useState<null | SnapItems>(null);
  const [showModalRemove, setShowModalRemove] = useState(false);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const handleSelectionModelChange = (selectionModel: any) => {
    // Use selectionModel to find the selected rows from the rows array
    const selectedRowObjects = selectionModel.map((rowId: any) =>
      snapData.find((row: any) => row.id === rowId)
    );
    setSelectedRowIds(selectedRowObjects);
  };

  const columns: GridColDef[] = [
    {
      field: "publish",
      headerName: "Status",
      minWidth: 100,
      editable: false,
      valueFormatter: (params) => {
        return params.value ? "LIVE" : "DRAFT";
      },
      renderCell: (params) => {
        return (
          <span className="break-normal">
            {params.value ? (
              <Status text="LIVE" size="small" variants="confirmed" />
            ) : (
              <Status text="DRAFT" size="small" variants="pending" />
            )}
          </span>
        );
      },
    },
    {
      field: "name",
      headerName: "Snaps",
      flex: 0.9,
      minWidth: 150,
      editable: false,
    },
    {
      field: "date_created",
      headerName: "Date Created",
      minWidth: 150,
      editable: false,
      valueFormatter: (params) => convertDateToStandardFormat(params.value),
    },
    {
      field: "action",
      headerName: "Action(s)",
      minWidth: 120,
      renderCell: (params) => (
        <div className="flex flex-row gap-3">
          {/* <div
            className="cursor-pointer text-neutral-500"
          >
            <ShareIcon />
          </div> */}
          <div
            className="cursor-pointer text-neutral-500"
            onClick={() => {
              dispatch(resetSnapGuide());
              navigate(`/guides/snaps/${params.row.id}`);
            }}
            title="View Snap"
          >
            <Visibility />
          </div>
          <div
            className="cursor-pointer text-neutral-500"
            onClick={() => {
              dispatch(resetSnapGuide());
              navigate(`/guides/snaps/${params.row.id}/edit`);
            }}
            title="Edit Snap"
          >
            <EditIcon fill="#808096" />
          </div>
          <div
            className="cursor-pointer text-neutral-500"
            onClick={(e) => {
              setShowModalRemove(true);
              setSingleData(params.row);
            }}
            title="Delete Snap"
          >
            <Delete />
          </div>
        </div>
      ),
    },
  ];

  return (
    <div>
      <div className="bg-default-primary rounded-tr-[20px] rounded-tl-[20px] flex flex-row justify-between px-6 py-4 items-center text-white">
        <div className="text-base font-bold">
          {selectedRowIds.length > 0 ? (
            <span>{selectedRowIds.length} selected</span>
          ) : (
            <span>Manage Snaps</span>
          )}
        </div>
        <div
          className={` flex-row gap-2 items-center ${
            selectedRowIds.length > 0 ? "flex" : "hidden"
          }`}
        >
          <Button
            text="Delete Snaps"
            iconLeft
            icon={<Delete />}
            size="small"
            variant="outlined"
            color="neutral"
            onClick={() => {
              setShowModalRemove(true);
            }}
            customClass={"ut-btn-org"}
          />
        </div>
      </div>
      <div className="bg-white">
        <DataGrid
          rows={snapData || []}
          columns={columns}
          autoHeight
          checkboxSelection
          selectionModel={selectedRowIds.map((row: any) => row.id)}
          onSelectionModelChange={handleSelectionModelChange}
          initialState={{
            sorting: {
              sortModel: [{ field: "date_created", sort: "desc" }],
            },
            pagination: {
              page: 0,
              pageSize: 25,
            },
          }}
          rowsPerPageOptions={[25, 50, 100]}
          sx={{ borderEndEndRadius: 20, borderEndStartRadius: 20 }}
        />
      </div>
      <ComponentRemoveSnap
        isOpen={showModalRemove}
        onClose={setShowModalRemove}
        setSelectedRemoveData={setSelectedRowIds}
        selectedRemoveData={selectedRowIds}
        setSingleRemoveData={setSingleData}
        singleRemoveData={singleData}
      />
    </div>
  );
};

export default TableAllSnaps;
