import React from "react";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";

interface Props {
  hover: boolean;
  clickOnZoomIn: (e: React.MouseEvent<HTMLDivElement>) => void;
  clickOnZoomOut: (e: React.MouseEvent<HTMLDivElement>) => void;
}
const PictureController = ({ hover, clickOnZoomIn, clickOnZoomOut }: Props) => {
  return (
    <div
      className="picture-controller-container"
      style={{
        opacity: hover ? 1 : 0,
      }}
    >
      <div className="picture-controller-zoom " onClick={clickOnZoomIn}>
        <ZoomInIcon />
      </div>
      <div className="picture-controller-zoom" onClick={clickOnZoomOut}>
        <ZoomOutIcon />
      </div>
    </div>
  );
};

export default PictureController;
