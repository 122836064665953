import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ComponentAlertAppOverallSnackbar from "../components/ComponentAlertAppOverallSnackbar";

import ComponentListUsers from "../components/ComponentListUsers";
import ComponentLoading from "../components/ComponentLoading";
import {
  organizationGetOrganizationInfo,
  resetAlert,
} from "../store/reducers/reducerOrganization";
import { RootState } from "../store";
import TableOrgViewer from "../components/Table/TableOrgViewer";

const PageOrganizationViewer = () => {
  // state from redux
  const { userData } = useSelector((state: RootState) => state.user);
  const orgID = userData.org;
  // const orgID = useSelector((state) => state.user.userData.org);
  // const viewers = useSelector((state) => state.org.viewers);
  // const emailInviteMsg = useSelector((state) => state.org.emailInviteMsg);

  // const isLoading = useSelector((state) => state.org.appLoading);
  // const alert = useSelector((state) => state.org.alert);

  const { viewers, emailInviteMsg, appLoading, alert } = useSelector(
    (state: RootState) => state.org
  );

  const dispatch = useDispatch();

  useEffect(() => {
    // If user is logged in, who has a org in state.user.userData, initiate a getOrganizationInformation to pull data of existing organization to tally
    if (orgID && !viewers) {
      dispatch(organizationGetOrganizationInfo(orgID));
    }
  }, [dispatch, orgID]);

  //check if email invite was successful
  //receiving msg indicates success
  useEffect(() => {
    if (emailInviteMsg && orgID && !viewers) {
      dispatch(organizationGetOrganizationInfo(orgID));
    }
  }, [emailInviteMsg, dispatch, orgID]);

  return (
    <div className="pageBase">
      <ComponentLoading show={appLoading} />
      {/*Viewer list*/}
      {/* <ComponentListUsers
        displayUserRole={"Viewer"}
        displayUserRoleFull={"Viewer"}
        displayShowAddBtn={true}
        introMsg={
          "Viewers have no control over an organization's content. They can only view walkthroughs that they have permissions to."
        }
        dataSet={viewers}
        indicatorMsg={"No viewers found. Please add one!"}
        metaDataTypes={null}
      /> */}
      {alert.type !== "" &&
        alert.type !== undefined &&
        alert.type !== "success" && (
          <ComponentAlertAppOverallSnackbar
            alert={alert}
            reset={() => dispatch(resetAlert())}
          />
        )}
      {/* NEW TABLE COMPONENT */}
      <TableOrgViewer />
    </div>
  );
};

export default PageOrganizationViewer;
