import {
  StepDataSet,
  StepProcDataSet,
} from "../../interfaces/interfaceAnalytic";

export const functionCombineStep = (dataSet: StepDataSet[]) => {
  // console.log(dataSet);

  const collectSessionActivities =
    dataSet.length > 0
      ? dataSet
          .map((d) => d.session_activities.map((d) => d))
          .reduce((acc, curr) => [...acc, ...curr])
          .map((d) => ({
            component_id: d.component_id,
            index_position: d.index_position,
            status: d.status,
          }))
      : [];

  const combineDataSet = collectSessionActivities.reduce<StepProcDataSet[]>(
    (acc, curr) => {
      const index = acc.findIndex(
        (el) => el.stepName === (curr.index_position + 1).toString()
      );
      if (index === -1) {
        switch (curr.status) {
          case "completed":
            acc.push({
              stepName: (curr.index_position + 1).toString(),
              completed: 1,
              dismissed: 0,
              incomplete: 0,
              seen: 1,
            });
            break;
          case "dismissed":
            acc.push({
              stepName: (curr.index_position + 1).toString(),
              dismissed: 1,
              completed: 0,
              incomplete: 0,
              seen: 1,
            });
            break;
          case "seen":
            acc.push({
              stepName: (curr.index_position + 1).toString(),
              dismissed: 0,
              completed: 0,
              incomplete: 1,
              seen: 1,
            });
            break;
          case null:
            acc.push({
              stepName: (curr.index_position + 1).toString(),
              dismissed: 0,
              completed: 0,
              incomplete: 0,
              seen: 0,
            });
            break;
          default:
            break;
        }
      } else {
        switch (curr.status) {
          case "completed":
            acc[index].completed++;
            acc[index].seen++;
            break;
          case "dismissed":
            acc[index].dismissed++;
            acc[index].seen++;
            break;
          case "seen":
            acc[index].incomplete++;
            acc[index].seen++;
            break;
          case null:
            acc[index].incomplete = acc[index].incomplete + 0;
            acc[index].seen = acc[index].seen + 0;
            acc[index].completed = acc[index].completed + 0;
            acc[index].incomplete = acc[index].incomplete + 0;
            break;
          default:
            break;
        }
      }

      return acc;
    },
    []
  );

  return combineDataSet;
};
