import React, { useState } from "react";
import ComponentListTable from "./ComponentListTable";
import ComponentRemoveSharedWalkthrough from "./ComponentRemoveSharedWalkthrough";

const ComponentEditListSharedWalkthroughsInTable = ({ dataSet, hostOrgID }) => {
  const [selectedWalkthroughList, setSelectedWalkthroughList] = useState([]);
  const [resetTableSelectAll, setResetTableSelectAll] = useState(false);
  const [removeMode, setRemoveMode] = useState("");

  const [showModalRemove, setShowModalRemove] = useState(false);

  // Sets the default HeaderCells
  let headerCells = [
    {
      id: "name",
      label: "Walkthrough",
      numeric: false,
      disablePadding: false,
    },
    {
      id: "lastEdited",
      label: "Last Edited",
      numeric: false,
      disablePadding: false,
      flex: 1,
    },
    {
      id: "action",
      label: "Action",
      numeric: false,
      disablePadding: false,
      flex: 1,
    },
  ];
  return (
    <>
      <ComponentListTable
        listType={"editSharedWalkthrough"}
        headerCells={headerCells}
        dataObject={dataSet}
        dataSet={dataSet.sharedWalkthroughList}
        setSelectedWalkthroughList={setSelectedWalkthroughList}
        resetTableSelectAll={resetTableSelectAll}
        setResetTableSelectAll={setResetTableSelectAll}
        removeMode={removeMode}
        setRemoveMode={setRemoveMode}
        showModalRemove={showModalRemove}
        setShowModalRemove={setShowModalRemove}
      />
      {selectedWalkthroughList.length > 0 && (
        <ComponentRemoveSharedWalkthrough
          selectedWalkthroughList={selectedWalkthroughList}
          showModalRemove={showModalRemove}
          setShowModalRemove={setShowModalRemove}
          removeMode={removeMode}
          setResetTableSelectAll={setResetTableSelectAll}
          dataSet={dataSet}
          hostOrgID={hostOrgID}
        />
      )}
    </>
  );
};

export default ComponentEditListSharedWalkthroughsInTable;
