import React from "react";
import { InputNumber } from "usertip-component-library";

interface Props {
  /** Prop for set value of the input */
  value: number;
  /** Prop for handling the input */
  settingHandler: (value: number) => void;
}

const LetterSpacingSetting = ({ value, settingHandler }: Props) => {
  return (
    <>
      <h1 className="text-sm">Letter Spacing:</h1>
      <InputNumber
        value={value}
        onChange={settingHandler}
        width="full"
        adornment="px"
        minValue={0}
        maxValue={10}
        variant="outlined"
      />
    </>
  );
};

export default LetterSpacingSetting;
