import React, { useState } from "react";
import { Tags, Button } from "usertip-component-library";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { convertDateToStandardFormat } from "../_functions/FunctionDate";
import { Delete, Visibility } from "@mui/icons-material";
import { EditIcon } from "usertip-component-library";
import ComponentRemoveGuides from "../ComponentRemoveGuides";
import { AllGuideData } from "../../store/reducers/reducerGuide";
import ComponentEditGuidesName from "../ComponentEditGuidesName";

interface Props {
  guideData?: AllGuideData[];
}

const TableAllGuides = ({ guideData }: Props) => {
  const [showModalRemove, setShowModalRemove] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [editGuide, setEditGuide] = useState<{
    guideName: string;
    guideId: string;
  }>({ guideName: "", guideId: "" });
  const [selectedGuideData, setSelectedGuideData] = useState<AllGuideData[]>(
    []
  );
  const [singleData, setSingleData] = useState<null | AllGuideData>(null);

  /** This is for select guides using checkbox */
  const handleSelectionModelChange = (selectionModel: any) => {
    // Use selectionModel to find the selected rows from the rows array
    const selectedRowObjects = selectionModel.map((rowId: any) =>
      guideData!.find((row: any) => row.id === rowId)
    );
    setSelectedGuideData(selectedRowObjects);
  };

  const navigate = useNavigate();

  /** Column for the table */
  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Guides",
      flex: 0.9,
      minWidth: 150,
      editable: false,
    },
    {
      field: "guideType",
      headerName: "Guide Type",
      flex: 0.5,
      minWidth: 150,
      editable: false,
      renderCell: (params) => {
        return (
          <div className="flex flex-wrap gap-2">
            {params.value.snap && (
              <Tags
                size="medium"
                text="Snap"
                variant="outlined"
                color="default"
                clickable={true}
              />
            )}
            {params.value.video && (
              <Tags
                size="medium"
                text="Video"
                variant="outlined"
                color="default"
                clickable={true}
              />
            )}
            {params.value.walkthrough && (
              <Tags
                size="medium"
                text="Walkthrough"
                variant="outlined"
                color="default"
                clickable={true}
              />
            )}
          </div>
        );
      },
    },
    {
      field: "createdAt",
      headerName: "Date Created",
      minWidth: 150,
      editable: false,
      valueFormatter: (params) => convertDateToStandardFormat(params.value),
    },
    {
      field: "action",
      headerName: "Action(s)",
      minWidth: 120,
      align: "left",
      renderCell: (params) => {
        return (
          <div className="flex flex-row gap-3">
            <div
              className="cursor-pointer text-neutral-500"
              onClick={() =>
                navigate(`/guides/all/${params.row.quick_build_id}`)
              }
              title="View Guide"
            >
              <Visibility />
            </div>
            <div
              className="cursor-pointer text-neutral-500"
              onClick={() => {
                setShowModalEdit(true);
                setEditGuide({
                  guideName: params.row.name,
                  guideId: params.row.quick_build_id,
                });
              }}
              title="Edit Guide Name"
            >
              <EditIcon fill="#808096" />
            </div>
            <div
              className="cursor-pointer text-neutral-500"
              onClick={(e) => {
                setShowModalRemove(true);
                setSingleData(params.row);
              }}
              title="Delete Guide"
            >
              <Delete />
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <div className="bg-default-primary rounded-tr-[20px] rounded-tl-[20px] flex flex-row justify-between px-6 py-4 items-center text-white">
        <div className="text-base font-bold">
          {selectedGuideData.length > 0 ? (
            <span>{selectedGuideData.length} selected</span>
          ) : (
            <span>Manage Guides</span>
          )}
        </div>
        <div
          className={` flex-row gap-2 items-center border-whi ${
            selectedGuideData.length > 0 ? "flex" : "hidden"
          }`}
        >
          <Button
            text="Delete Guides"
            iconLeft
            icon={<Delete />}
            size="small"
            variant="outlined"
            color="neutral"
            onClick={() => {
              setShowModalRemove(true);
            }}
            customClass={"ut-btn-org"}
          />
        </div>
      </div>
      <div className="bg-white">
        <DataGrid
          rows={guideData || []}
          columns={columns}
          autoHeight
          checkboxSelection
          selectionModel={selectedGuideData.map((row: any) => row.id)}
          onSelectionModelChange={handleSelectionModelChange}
          initialState={{
            sorting: {
              sortModel: [{ field: "createdAt", sort: "desc" }],
            },
            pagination: {
              page: 0,
              pageSize: 25,
            },
          }}
          rowsPerPageOptions={[25, 50, 100]}
          sx={{ borderEndEndRadius: 20, borderEndStartRadius: 20 }}
        />
      </div>
      {showModalRemove && (
        <ComponentRemoveGuides
          onClose={setShowModalRemove}
          isOpen={showModalRemove}
          selectedRemoveData={selectedGuideData}
          setSelectedRemoveData={setSelectedGuideData}
          singleRemoveData={singleData}
          setSingleRemoveData={setSingleData}
        />
      )}

      {showModalEdit && (
        <ComponentEditGuidesName
          isOpen={showModalEdit}
          onClose={setShowModalEdit}
          guide={editGuide}
          setGuide={setEditGuide}
        />
      )}
    </div>
  );
};

export default TableAllGuides;
