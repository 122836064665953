import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { getWalkthroughsSummary } from "../../store/reducers/reducerWalkthrough";
import { callPostUpdateUserOnBoard } from "../../axios/v2/callsUser";
import { updateOnBoardingStatus } from "../../store/reducers/reducerUser";
import { organizationGetOrganizationInfo } from "../../store/reducers/reducerOrganization";
import { Button, Checkbox } from "usertip-component-library";

const OnboardingChecklist = () => {
  const [checkedItems, setCheckedItems] = useState({
    gettingStarted: true,
    createWalkthrough: false,
    inviteMember: false,
  });

  const navigate = useNavigate();
  const { walkthroughsSummary } = useSelector(
    (state: RootState) => state.walkthrough
  );
  const { admins, builders, viewers } = useSelector(
    (state: RootState) => state.org
  );

  const { userData: user } = useSelector((state: RootState) => state.user);

  const dispatch = useDispatch();

  useEffect(() => {
    if (user.cognito_id) {
      dispatch(getWalkthroughsSummary(user.cognito_id));
    }
  }, [user]);

  useEffect(() => {
    if (user.org && user.org !== "") {
      const orgId = user.org;
      dispatch(organizationGetOrganizationInfo(orgId));
    }
  }, [user]);

  type ChecklistItem = "gettingStarted" | "createWalkthrough" | "inviteMember";

  const finishOnboarding = async () => {
    const cognitoId = user?.cognito_id;
    await callPostUpdateUserOnBoard(cognitoId);
    dispatch(updateOnBoardingStatus());
  };

  /**Check if user already created walkthrough */
  useEffect(() => {
    if (walkthroughsSummary) {
      //if walkthrough summary > 0 set const to true
      const isWalkthroughCreated = walkthroughsSummary.length > 0;
      isWalkthroughCreated &&
        setCheckedItems({ ...checkedItems, createWalkthrough: true });
    }
  }, [walkthroughsSummary]);

  /**Check if user already add user to org */
  useEffect(() => {
    const isUserAddedToOrg =
      (admins && admins.length > 1) ||
      (builders && builders.length > 0) ||
      (viewers && viewers.length > 0);

    if (isUserAddedToOrg) {
      setCheckedItems({ ...checkedItems, inviteMember: true });
    }
  }, [admins, builders, viewers]);

  /**finish unboarding when all items checked */
  useEffect(() => {
    if (
      checkedItems.gettingStarted &&
      checkedItems.createWalkthrough &&
      checkedItems.inviteMember
    ) {
      finishOnboarding();
    }
  }, [checkedItems]);

  type CheckListItem = {
    item: ChecklistItem;
    label: string;
    navigateTo?: string;
  };

  const checkListItem: CheckListItem[] = [
    {
      item: "gettingStarted",
      label: "Read Getting Started",
      navigateTo: "/onboarding",
    },
    // TODO: Create New Walkthrough should navigate user to a flow where they can create walkthrough
    // {
    //   item: "createWalkthrough",
    //   label: "Create New Walkthrough",
    //   navigateTo: "/guides/walkthroughs",
    // },
    user.role !== "builder" && {
      item: "inviteMember",
      label: "Invite At least One Member To Organization",
      navigateTo: "/organization",
    },
  ].filter(Boolean) as CheckListItem[];

  return (
    <div className="fixed top-20 right-20 z-50 flex flex-col gap-2 p-4 bg-white rounded shadow-md">
      <h2 className="">Onboarding Checklist</h2>
      <ul className="flex flex-col gap-2">
        {checkListItem.map((item) =>
          generateChecklistItem(item.item, item.label, item.navigateTo)
        )}
      </ul>
      <Button
        onClick={() => finishOnboarding()}
        variant="outlined"
        color="primary"
        size="small"
        text="Finish onboarding"
      />
    </div>
  );

  function generateChecklistItem(
    item: ChecklistItem,
    label: string,
    navigateTo?: string
  ) {
    return (
      <li className="flex gap-2" key={item}>
        <Checkbox
          disabled={!checkedItems[item]}
          id="checkbox"
          name="checkbox"
          indeterminate={checkedItems[item]}
        />
        <label
          className="text-default-primary-100 text-sm hover:underline cursor-pointer"
          onClick={() => {
            navigate(navigateTo!);
          }}
        >
          {checkedItems[item] ? <s>{label}</s> : label}
        </label>
      </li>
    );
  }
};

export default OnboardingChecklist;
