import React, { useEffect } from "react";

import { Alert } from "usertip-component-library";

interface Props {
  /** This props is for boolean state must be true to show the alert */
  showAlertSnackbar: boolean;
  /** This props is for boolean setState */
  setShowAlertSnackbar: React.Dispatch<React.SetStateAction<boolean>>;
  /** This props is for alert variant */
  variant: "error" | "default" | "info" | "success" | "warning";
  /** This props is for alert title */
  title: string;
  /** This is props is to automaticly close the AlertSnackbar (Set number as millisecond) default = 5000*/
  hideAlert?: number;
}

const ComponentAlertSnackbar = ({
  showAlertSnackbar,
  setShowAlertSnackbar,
  variant,
  title,
  hideAlert = 5000,
}: Props) => {
  // Handles hiding of the Snackbar popup
  const handleHideAlert = () => {
    setShowAlertSnackbar(false);
  };

  /** This is for automatically close the alert */
  useEffect(() => {
    let timeout: any;
    if (showAlertSnackbar) {
      timeout = setTimeout(() => {
        setShowAlertSnackbar(false);
      }, hideAlert);
    }

    return () => clearTimeout(timeout);
  }, [showAlertSnackbar]);

  return showAlertSnackbar ? (
    <div className="fixed bottom-4 left-1/2 transform -translate-x-1/2 z-50">
      <Alert
        dismissible={true}
        title={title}
        type="card"
        variant={variant}
        closeOnClick={handleHideAlert}
        // @ts-ignore
        showLink={false}
      />
    </div>
  ) : null;
};

export default ComponentAlertSnackbar;

/**
 * Example
 *    const [testState, setTestState] = useState<boolean>(false);
 * 
 *    <ComponentAlertSnackbar
        showAlertSnackbar={testState}
        setShowAlertSnackbar={setTestState}
        variant={"error"}
        title="Please make changes to Permissions List to Confirm."
      />
 */
