import { InputLabel, TextField } from "@mui/material";
import { Button, InputField } from "usertip-component-library";
import React, { useEffect, useState } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import GroupRuleForm from "../components/AddUserSegmentationRule/GroupRuleForm";
import {
  RuleGroup,
  UserSegmentation,
} from "../interfaces/interfaceSegmentation";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import {
  createUserSegmentation,
  listAllUserSegmentation,
  updateUserSegmentation,
} from "../store/reducers/reducerUserSegmentation";
import ComponentLoading from "../components/ComponentLoading";
import { useNavigate, useParams } from "react-router-dom";
import GroupUpdateRuleForm from "../components/UpdateUserSegmentation/GroupUpdateRuleForm";

const PageUpdateUserSegmentation = () => {
  let { segmentationID } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  /**Segment name */
  const [segmentName, setSegmentName] = useState("");

  const { org, api_key } = useSelector(
    (state: RootState) => state.user.userData
  );

  const [userSegmentation, setUserSegmentation] =
    useState<UserSegmentation | null>(null);

  const fullUserSegmentationData = useSelector(
    (state: RootState) => state.userSegmentation
  );

  /** Load segmentation data on Page initial load */
  useEffect(() => {
    if (org) {
      dispatch(listAllUserSegmentation(org));
    }
  }, []);

  /** Load segmentation data on Page initial load */
  useEffect(() => {
    if (fullUserSegmentationData) {
      fullUserSegmentationData.userSegmentationList?.map((item) => {
        if (item.segmentation_id === segmentationID) {
          setUserSegmentation(item);
          setSegmentName(item.segmentation_name);
        }
      });
    }
  }, [fullUserSegmentationData]);

  /**List rule group */
  const [listRuleGroup, setListRuleGroup] = useState<{ groupIndex: number }[]>([
    { groupIndex: 0 },
  ]);

  /**List rule group seperated by groupIndex(AND)*/
  const [listRule, setListRule] = useState<RuleGroup[]>([]);

  /** Populate listRule and listRuleGroup states when Page Update Segment loads */
  useEffect(() => {
    if (userSegmentation) {
      /** List the groups that exist within the rules */
      let currentListRuleGroup: any[] = [];

      /** Lists the individual rules */
      let currentListRule: RuleGroup[] = [];

      /** Populate the list with the current values */
      userSegmentation.segmentation_rules.map((ruleGroup: any[], index) => {
        /** Update currentListRuleGroup first */
        const ruleGroupIndex = index;
        currentListRuleGroup.push({ groupIndex: ruleGroupIndex });
        ruleGroup?.map((rule: any, _index) => {
          /** Update currentListRule second */
          currentListRule.push({
            groupIndex: ruleGroupIndex,
            metaName: rule.metadata,
            metaType: rule.type,
            metaRule: rule.rule,
            metaValue: rule.value,
            index: _index,
          });
        });
      });

      setListRuleGroup([...currentListRuleGroup]);
      setListRule([...currentListRule]);
    }
  }, [userSegmentation]);

  /**Adding new rule group form(OR) everytime user click add */
  const onAddRuleGroupClick = (event: React.MouseEvent) => {
    const copyArray = listRuleGroup;

    setListRule([
      ...listRule,
      {
        index: 0,
        metaName: "",
        metaValue: "",
        metaType: "string",
        metaRule: "contain",
        groupIndex: copyArray.length + 1,
      },
    ]);
    setListRuleGroup([...listRuleGroup, { groupIndex: copyArray.length + 1 }]);
  };

  /** Removing rule group (OR) */
  const onRemoveGroupClick = (currGroupIndex: number) => {
    const removeRuleList = listRule.filter(
      (d) => d.groupIndex !== currGroupIndex
    );
    const removedRuleGroup = listRuleGroup.filter(
      (d) => d.groupIndex !== currGroupIndex
    );

    setListRule(removeRuleList);
    setListRuleGroup(removedRuleGroup);
  };

  const checkDisableButton = () => {
    for (const i of listRule) {
      return i.metaName.length === 0 ? true : false;
    }
  };

  /**Change list rule so it fit the API params */
  const groupArray = () => {
    let group = [] as [RuleGroup[]] | [];

    //javascript array has a method foreach that enumerates keyvalue pairs.
    listRule.forEach((r) => {
      //if an array index by the value of id is not found, instantiate it.
      if (!group[r.groupIndex!]) {
        //result gets a new index of the value at id.
        group[r.groupIndex!] = [];
      }
      //push that whole object from api_array into that list
      group[r.groupIndex!].push(r);
    });

    /** Filter out rule groups that are undefined/empty */
    const _filteredGroup = group.filter((element) => {
      return element !== undefined;
    });

    /**Rename the key so it fit api paramaters */
    const result = _filteredGroup.map((data) => {
      return data.map((meta) => {
        if (meta.metaType === "boolean") {
          return {
            metadata: meta.metaName,
            type: meta.metaType,
            rule: meta.metaRule,
          };
        }
        return {
          metadata: meta.metaName,
          type: meta.metaType,
          rule: meta.metaRule,
          value: meta.metaValue,
        };
      });
    });
    return result;
  };

  /**Handler submit form */
  const handleUpdateUserSegmentation = async (
    e: React.FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault();
    const groupListRule = groupArray();

    const data = {
      segmentationName: segmentName,
      orgId: org,
      apiKey: api_key,
      segmentationRules: groupListRule,
    };

    /** Call update API */
    await dispatch(
      updateUserSegmentation({
        segmentationId: segmentationID!,
        segmentData: data,
      })
    );

    navigate("/segmentation");
  };
  const loading = useSelector(
    (state: RootState) => state.userSegmentation.appLoading
  );

  return (
    <>
      <ComponentLoading show={loading} />
      {userSegmentation && (
        <>
          <div className="pageBase">
            <div className="pageHeader">
              Updating User Segmentation: {userSegmentation.segmentation_name}
            </div>
            <div className="pageSubHeader"></div>
          </div>
          <section id="add_segmentation form">
            <form onSubmit={handleUpdateUserSegmentation}>
              {/* Segment name form */}
              <div className="px-2 mb-6">
                <InputField
                  variant="full"
                  label="Segmentation Name"
                  error={segmentName.length === 0}
                  errorText="Segmentation name must not be empty"
                  placeholder="Segmentation Name"
                  helperText=""
                  onChange={(e) => setSegmentName(e.target.value)}
                  value={segmentName}
                />
              </div>
              {/* Group rule */}
              {listRuleGroup.length > 0 &&
                listRuleGroup.map((d) => (
                  <div key={d.groupIndex}>
                    {/* horizonatal line with text Inside */}
                    {d.groupIndex !== 0 && (
                      <div className="flex items-center my-8">
                        <div className="flex-grow bg bg-gray-300 h-0.5"></div>
                        <div className="flex-grow-0 mx-5 text-black">OR</div>
                        <div className="flex-grow bg bg-gray-300 h-0.5"></div>
                      </div>
                    )}
                    <GroupUpdateRuleForm
                      listRule={listRule}
                      groupIndex={d.groupIndex}
                      setListRule={setListRule}
                      onRemoveGroupClick={onRemoveGroupClick}
                    />
                  </div>
                ))}
              <div className="mt-6">
                <Button
                  color="primary"
                  size="medium"
                  text="ADD RULE GROUP"
                  variant="outlined"
                  icon={<AddCircleIcon fontSize="small" />}
                  iconRight
                  onClick={(e) => {
                    e.preventDefault();
                    onAddRuleGroupClick(e);
                  }}
                  customClass="ut-header-link-button"
                />
              </div>

              <div className="modalActionBtnBar mt-20">
                <Button
                  variant="primary"
                  color="primary"
                  size="medium"
                  text="Cancel"
                  onClick={() => {
                    navigate("/segmentation");
                  }}
                />

                <Button
                  variant="primary"
                  color="primary"
                  size="medium"
                  text="Confirm"
                  disabled={checkDisableButton()!}
                />
              </div>
            </form>
          </section>
        </>
      )}
    </>
  );
};

export default PageUpdateUserSegmentation;
