import React from "react";

import { Button } from "usertip-component-library";

const ComponentEditListBtnArray = ({
  checked,
  setChecked,

  membersList,
  setMembersList,
  memberTeamList,
  setMemberTeamList,

  not,
  intersection,
}) => {
  // List Checks on The Checked-Checkboxes on Each List
  const leftChecked = intersection(checked, membersList);
  const rightChecked = intersection(checked, memberTeamList);

  // Move selected members from Left to Right List
  const handleCheckedLeftToRight = () => {
    setMemberTeamList(memberTeamList.concat(leftChecked));
    setMembersList(not(membersList, leftChecked));
    setChecked(not(checked, leftChecked));
  };
  // Move selected members from Right to Left List
  const handleCheckedRightToLeft = () => {
    setMembersList(membersList.concat(rightChecked));
    setMemberTeamList(not(memberTeamList, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  return (
    <div className="mx-5 my-auto">
      <div className="mb-2 w-16">
        <Button
          color="primary"
          size="fluid-small"
          text="&gt;"
          variant="outlined"
          onClick={handleCheckedLeftToRight}
          disabled={leftChecked.length === 0}
        />
      </div>
      <div className="w-16">
        <Button
          color="primary"
          size="fluid-small"
          text="&lt;"
          variant="outlined"
          onClick={handleCheckedRightToLeft}
          disabled={rightChecked.length === 0}
        />
      </div>
    </div>
  );
};

export default ComponentEditListBtnArray;
