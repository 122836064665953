import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import {
  getImageList,
  resetImgList,
} from "../../../store/reducers/reducerMedia";
import ScrollSelectImageList from "./ScrollSelectImageList";
import ComponentFilterSearch from "../../MediaManager/MediaManagerImages/ComponentFilterSearch";

interface Props {
  handleSelectImageFromLibrary: (mediaId: string, mediaUrl: string) => void;
}

const SelectImageList = ({ handleSelectImageFromLibrary }: Props) => {
  /** Change how many image in 1 col here */
  const imagePerCol = 4;
  const gridStyle = {
    gridTemplateColumns: `repeat(${imagePerCol}, minmax(0, 1fr))`,
  };
  const IMAGE_LIST_LIMIT = imagePerCol * 2;
  const LIMIT_PAGE = 1;

  const [text, setText] = useState("");
  const [sortBy, setSortBy] = useState<"dateCreated" | "name">("dateCreated");
  const [sortOrder, setSortOrder] = useState<1 | -1>(-1);

  const dispatch = useDispatch();
  const cognitoId = useSelector(
    (state: RootState) => state.user.userData.cognito_id
  );

  const imageList = useSelector((state: RootState) => state.media.imageList);

  // get list of images. Limit images to 6
  useEffect(() => {
    console.log("---imageList changed", imageList);
    if (cognitoId) {
      dispatch(
        getImageList({
          limit: IMAGE_LIST_LIMIT,
          page: LIMIT_PAGE,
          cognitoId,
          name: text,
          sortBy,
          sort: sortOrder,
        })
      );
    }
  }, [cognitoId]);

  function onFilterHandler(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    //reset image list state before filtering image
    dispatch(resetImgList());
    dispatch(
      getImageList({
        limit: IMAGE_LIST_LIMIT,
        page: LIMIT_PAGE,
        cognitoId,
        name: text,
        sortBy,
        sort: sortOrder,
      })
    );
  }

  return (
    <section id="media-image-list" className="mt-6 w-full">
      {/* Filter image component */}
      <div className="mb-6">
        <ComponentFilterSearch
          onFilterHandler={onFilterHandler}
          setText={setText}
          setSortBy={setSortBy}
          setSortOrder={setSortOrder}
        />
      </div>
      {imageList && (
        <Box
          className="pb-6 overflow-x-auto overflow-y-scroll rounded-md h-96"
          id="ut-trigger-modal-scrollbar"
          sx={{
            width: "100%",
          }}
        >
          {/* display image list as grid 4x4 */}
          <div
            className="w-full grid auto-rows-auto gap-4 items-start"
            style={gridStyle}
          >
            {imageList.map((val) => {
              return (
                <div
                  key={val.media_id}
                  className="bg-default-neutral-10 w-52 flex flex-col justify-center items-center mx-auto rounded-2xl overflow-hidden drop-shadow-[0px_4px_4px_rgba(10,0,204,0.1)] hover:drop-shadow-[0_4px_4px_rgba(118,87,241,0.5)] cursor-pointer"
                  onClick={() => {
                    handleSelectImageFromLibrary(val.img_url, val.img_name);
                  }}
                >
                  <div className="w-52 h-36">
                    <img
                      src={val.img_url}
                      alt={val.img_name}
                      className="w-full h-full object-contain bg-default-neutral-20"
                    />
                  </div>
                  <p className="text-center text-sm p-4 font-semibold">
                    {val.img_name}
                  </p>
                </div>
              );
            })}
          </div>
          {/* only add infiniy scroll when image list is more than default (12) */}
          {imageList.length === IMAGE_LIST_LIMIT && (
            <ScrollSelectImageList
              sort={sortOrder}
              sortBy={sortBy}
              name={text}
              imagePerCol={imagePerCol}
              handleSelectImageFromLibrary={handleSelectImageFromLibrary}
            />
          )}
        </Box>
      )}
    </section>
  );
};

export default SelectImageList;
