import React, { useEffect, useState } from "react";

import { ImageCard, Checkbox } from "usertip-component-library";
import { convertDateToStandardFormat } from "../../_functions/FunctionDate";
import DeleteImageModal from "../../MediaManager/MediaManagerImages/DeleteImageModal";
import { DetailedImgObj } from "../../../store/reducers/reducerMedia";

interface Props extends DetailedImgObj {
  isBulkDeleteMode: boolean;
  handleDeleteBulkImage: (
    event: React.ChangeEvent<HTMLInputElement>,
    mediaId: string
  ) => void;
}

const ImageCardContainer = ({
  date_modified,
  date_uploaded,
  img_name,
  img_url,
  media_id,
  owner_email,
  isBulkDeleteMode,
  handleDeleteBulkImage,
}: Props) => {
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [selected, setSelected] = useState<boolean>(false);
  const [imageSize, setImageSize] = useState<"medium" | "small" | undefined>(
    "medium"
  );

  const handleCloseDeleteModal = () => {
    setDeleteModal(false);
  };

  const handleOpenDeleteModal = () => {
    setDeleteModal(true);
  };

  /** This useEffect to reset the checkbox when isBulkDeleteMode changes */
  useEffect(() => {
    setSelected(false);
  }, [isBulkDeleteMode]);

  useEffect(() => {
    if (window.innerWidth < 1752) {
      setImageSize("small");
    } else {
      setImageSize("medium");
    }
  }, []);

  useEffect(() => {
    const resizeFunction = (e: any) => {
      if (e.target.innerWidth < 1752) {
        setImageSize("small");
      } else {
        setImageSize("medium");
      }
    };

    window.addEventListener("resize", resizeFunction);

    return () => {
      window.removeEventListener("resize", resizeFunction);
    };
  }, []);

  return (
    <div className="mx-auto relative">
      <ImageCard
        key={media_id}
        name={img_name}
        src={img_url}
        dateUploaded={convertDateToStandardFormat(date_uploaded)}
        email={owner_email}
        dateModified={convertDateToStandardFormat(date_modified)}
        size={imageSize}
        onDelete={handleOpenDeleteModal}
        deleteModal={
          deleteModal ? (
            <DeleteImageModal
              handleClose={handleCloseDeleteModal}
              imageTitle={img_name}
              isOpen={deleteModal}
              mediaId={media_id}
            />
          ) : undefined
        }
      />
      {isBulkDeleteMode && (
        <div
          className={`absolute top-4 left-4 ${
            imageSize === "medium" ? "text-3xl" : ""
          }`}
        >
          <Checkbox
            id={media_id}
            name={media_id}
            onChange={(e) => {
              handleDeleteBulkImage(e, media_id);
              setSelected(!selected);
            }}
            value={media_id}
            selectedValue={selected ? media_id : undefined}
          />
        </div>
      )}
    </div>
  );
};

export default ImageCardContainer;
