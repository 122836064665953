import { createSlice } from "@reduxjs/toolkit";
import { Dispatch } from "redux";

import * as actionTypes from "./_actionTypes";
import {
  callGETRetrieveUserAccountInformation,
  callPOSTRetrieveUserRoleFromOrganization,
} from "../../axios/v2/_callsIndex";
import { UserData, UserState } from "./_reducerIndex";
import { googleSignIn, signInRequest } from "./reducerAuth";
import { organizationGetOrganizationInfo } from "./reducerOrganization";

// TODO: UNUSED DISPATCH FUNCTION
// Fetch user account information.
export const getUserAccountInfo = (orgID: string, userID: string) => {
  let userData = {};
  return (dispatch: Dispatch) => {
    // dispatch({
    //     type: actionTypes.START_APP_LOADING
    // });

    callGETRetrieveUserAccountInformation(userID)
      .then((res) => {
        console.log("got user data", res);
        userData = res.data;
        callPOSTRetrieveUserRoleFromOrganization(orgID, userID)
          .then((_res) => {
            console.log("_res is:");
            //@ts-ignore
            userData.orgData = _res.data.result;
            console.log(`OVERALL RES DATA IS`);

            dispatch({
              type: actionTypes.GET_USER_ACCOUNT_INFO,
              userData: userData,
            });
            dispatch({
              type: actionTypes.STOP_APP_LOADING,
            });
          })
          .catch((err) => {
            console.log(
              "error found in call get user information, logout user"
            );
            const errStatus = err.response.status; // Integer
            const errPayload = err.response.data;
            const errMsg = errPayload.msg;

            console.log(errStatus);
            console.log(errPayload);
            console.log(errMsg);
            dispatch({
              type: actionTypes.START_APP_LOADING,
            });

            alert("Your session has expired, please log in again.");

            // ========= Log out call here =============
            localStorage.removeItem("ut-token");
            localStorage.removeItem("ut-expires");
            localStorage.removeItem("ut-cognitoid");
            localStorage.removeItem("ut-email");
            localStorage.removeItem("ut-refresh-token");
            // localStorage.setItem("auth", "false");

            // On success.
            dispatch({
              type: actionTypes.SIGN_OUT_SUCCESS,
              // payload: res,
            });
            dispatch({
              type: actionTypes.STOP_APP_LOADING,
            });

            // Redirect to root path of app.
            window.location.replace(window.location.origin);
          });
      })
      .catch((err) => {
        console.log("error found in call get user information, logout user");
        const errStatus = err.response.status; // Integer
        const errPayload = err.response.data;
        const errMsg = errPayload.msg;

        console.log(errStatus);
        console.log(errPayload);
        console.log(errMsg);

        dispatch({
          type: actionTypes.START_APP_LOADING,
        });

        alert("Your session has expired, please log in again.");

        // ========= Log out call here =============
        localStorage.removeItem("ut-token");
        localStorage.removeItem("ut-expires");
        localStorage.removeItem("ut-cognitoid");
        localStorage.removeItem("ut-email");
        localStorage.removeItem("ut-refresh-token");
        // localStorage.setItem("auth", "false");

        // On success.
        dispatch({
          type: actionTypes.SIGN_OUT_SUCCESS,
          // payload: res,
        });
        dispatch({
          type: actionTypes.STOP_APP_LOADING,
        });

        // Redirect to root path of app.
        window.location.replace(window.location.origin);
      });
  };
};

// TODO: Update user name.

// TODO: Update user phone number.

const userState: UserState = {
  userData: {
    mfa: null,
    is_deleted: null,
    _id: "",
    email: "",
    name: "",
    cognito_id: "",
    api_key: "",
    phoneno: {},
    createdAt: "",
    updatedAt: "",
    __v: 0,
    id: "",
    role: "",
    is_onboarded: null,
  },
};

export const userSlice = createSlice({
  name: "user",
  initialState: userState,
  reducers: {
    getUserInfo: (state, action) => {
      //You CAN'T reassign object using spread operator
      // state = {...state, userData: action.payload.payload.user}

      //This code work
      state.userData = action.payload.user;
    },
    getUserRole: (state, action) => {
      state.userData.role = action.payload.role;
    },
    updateOnBoardingStatus: (state) => {
      state.userData.is_onboarded = true;

      const userStorage = JSON.parse(localStorage.getItem("ut-user")!);

      userStorage.is_onboarded = true;

      localStorage.setItem("ut-user", JSON.stringify(userStorage));
    },
    // TODO: updateUserName
    // TODO: updatePhoneNum:
  },
  extraReducers: (builder) => {
    builder
      .addCase(signInRequest.fulfilled, (state, action: any) => {
        if (action.payload?.auth !== undefined) {
          state.userData = action.payload.user;
          // state.errorSignIn = "";
          // state.auth = action.payload.auth;
          // state.appLoading = false;
        }
      })
      .addCase(googleSignIn.fulfilled, (state, action: any) => {
        if (action.payload?.auth !== undefined) {
          state.userData = action.payload.user;
          // state.errorSignIn = "";
          // state.auth = action.payload.auth;
          // state.appLoading = false;
        }
      })
      .addCase(
        organizationGetOrganizationInfo.fulfilled,
        (state, action: any) => {
          state.userData.api_key = action.payload.org.api_key;
        }
      )
      .addDefaultCase((state, action) => ({ ...state }));
  },
});

export const { getUserInfo, updateOnBoardingStatus } = userSlice.actions;

export default userSlice.reducer;
