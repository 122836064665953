import React from "react";
import { SearchDropdown } from "usertip-component-library";

interface Props {
  /** This prop is for selected dropdown value */
  value: number;
  /** This prop is for handling the dropdown */
  settingHandler: (id: any, value: any) => void;
}

const FontSizeSetting = ({ value, settingHandler }: Props) => {
  const fontSizeData: { id: string; value: string }[] = [
    { id: "1", value: "14" },
    { id: "2", value: "16" },
    { id: "3", value: "18" },
    { id: "4", value: "24" },
    { id: "5", value: "30" },
    { id: "6", value: "36" },
    { id: "7", value: "48" },
    { id: "8", value: "60" },
    { id: "9", value: "72" },
    { id: "10", value: "96" },
  ];
  return (
    <>
      <h1 className="text-sm">Font Size:</h1>
      <SearchDropdown
        dataArray={fontSizeData}
        selectedValue={{ value: `${value}` }}
        onClick={settingHandler}
        customWidth="w-full"
        scroll={true}
        search={false}
      />
    </>
  );
};

export default FontSizeSetting;
