import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Status } from "usertip-component-library";

import ComponentPageHeaderLinksBackBtn from "../components/ComponentPageHeaderLinksBackBtn";
import ComponentPageHeaderLinkArray from "../components/ComponentPageHeaderLinkArray";

import ComponentSettingsWalkthroughGeneralAction from "../components/ComponentSettingsWalkthroughGeneralAction";
import ComponentSettingsWalkthroughLaunchAction from "../components/ComponentSettingsWalkthroughLaunchAction";
import ComponentSettingsWalkthroughPostAction from "../components/ComponentSettingsWalkthroughPostAction";
import {
  getSingleWalkthroughReq,
  resetAlert,
  updateWalkthrougGeneralSetting,
  updateWalkthroughNonGeneralSetting,
} from "../store/reducers/reducerWalkthrough";
import ComponentLoading from "../components/ComponentLoading";
import ComponentAlertAppOverallSnackbar from "../components/ComponentAlertAppOverallSnackbar";
import ComponentSettingsPublishWalkthrough from "../components/ComponentSettingsPublishWalkthrough";
import ComponentPlanDetails from "../components/OrgPlan/ComponentPlanDetails";
import { RootState } from "../store";

const PageWalkthroughSettings = () => {
  const { walkthroughID } = useParams();
  const [activeWalkthrough, setActiveWalkthrough] = useState("") as any;

  // state from redux
  const walkthroughs = useSelector(
    (state: RootState) => state.walkthrough.walkthroughs
  ) as any;
  const walkthroughsSummary = useSelector(
    (state: RootState) => state.walkthrough.walkthroughsSummary
  );
  const walkthrough = useSelector(
    (state: RootState) => state.walkthrough.activeWalkthrough as any
  );
  const lastUpdated = useSelector(
    (state: RootState) => state.walkthrough.resource_update_datetime
  );

  const isLoading = useSelector(
    (state: RootState) => state.walkthrough.appLoading
  );
  const alert = useSelector((state: RootState) => state.walkthrough.alert);

  const dispatch = useDispatch();

  useEffect(() => {
    if (walkthrough.name) {
      setActiveWalkthrough(walkthrough);
    }
  }, [walkthrough.name, lastUpdated, walkthrough]);

  useEffect(() => {
    // Grab the Walkthrough details based on "walkthrough_id", and then Grab the first instance of the results out of the Array to reply as a Walkthrough Object
    if (walkthroughs !== null && walkthroughID) {
      dispatch(getSingleWalkthroughReq(walkthroughID));
    }
  }, [dispatch, walkthroughID, walkthroughs]);

  return (
    <>
      <ComponentLoading show={isLoading} />
      <div className="pageBase">
        <ComponentPageHeaderLinksBackBtn
          btnURL={`/guides/walkthroughs`}
          btnLabel={"Your Walkthroughs"}
          btnDisabled={false}
        />

        <ComponentPageHeaderLinkArray
          linkArrayType={"Walkthroughs"}
          walkthroughID={walkthroughID!}
        />

        <div className="flex items-center gap-2">
          <div className="pageHeader">
            General Settings: {activeWalkthrough.name}
          </div>
          <Status
            variants={walkthrough.publish ? "confirmed" : "pending"}
            size="small"
            text={walkthrough.publish ? "LIVE" : "DRAFT"}
          />
        </div>

        {activeWalkthrough && (
          <div className="pageSubHeader">
            {`${activeWalkthrough.name ? activeWalkthrough.name + " - " : ""}
            Overview`}
          </div>
        )}

        <ComponentPlanDetails
          planList={["publishedWalkthrough", "autoLaunch"]}
        />
        <ComponentSettingsPublishWalkthrough
          walkthroughStatus={walkthrough.publish}
        />

        <ComponentSettingsWalkthroughGeneralAction
          walkthrough={activeWalkthrough}
          updateSettings={updateWalkthrougGeneralSetting}
        />

        <ComponentSettingsWalkthroughLaunchAction
          walkthrough={activeWalkthrough}
          updateSettings={updateWalkthroughNonGeneralSetting}
        />

        <ComponentSettingsWalkthroughPostAction
          walkthrough={activeWalkthrough}
          updateSettings={updateWalkthroughNonGeneralSetting}
          walkthroughsSummary={walkthroughsSummary}
        />
      </div>
      {alert.type !== "" && alert.type !== undefined && (
        <ComponentAlertAppOverallSnackbar
          alert={alert}
          reset={() => dispatch(resetAlert())}
        />
      )}
    </>
  );
};

export default PageWalkthroughSettings;
