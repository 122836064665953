import React from "react";

import { useDispatch } from "react-redux";
import { EmptyModal, Button } from "usertip-component-library";

const ComponentRemoveTeams = ({
  displayUserRoleFull,
  listType,
  viewerTeamType,

  selectedTeamList,

  selectedWalkthroughViewerTeamPermissionsList,

  onRemoveBuilderTeam,
  onRemoveViewerTeam,

  onDeleteOneTeamPermissions,

  showModalRemove,
  setShowModalRemove,

  removeMode,

  setResetTableSelectAll,
}) => {
  const dispatch = useDispatch();
  // Handle Removal of selected teams after submission click
  const handleConfirmRemoveTeamsFormSubmission = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (listType === "Teams") {
      // Handles Removal submission where appropriate
      if (removeMode === "single") {
        // const orgID = selectedTeamList[0].org_id;
        // const teamName = selectedTeamList[0].name;

        if (displayUserRoleFull === "Builder") {
          const teamId = selectedTeamList[0].builder_team_id;
          handleHideModal();
          dispatch(onRemoveBuilderTeam(teamId));
        } else if (displayUserRoleFull === "Viewer") {
          const teamId = selectedTeamList[0].viewer_team_id;
          handleHideModal();
          dispatch(onRemoveViewerTeam(teamId));
        }
      } else if (removeMode === "bulk") {
        if (selectedTeamList) {
          handleHideModal();
          for (let i = 0; i < selectedTeamList.length; i++) {
            // const orgID = selectedTeamList[i].org_id;
            // const teamName = selectedTeamList[i].name;

            if (displayUserRoleFull === "Builder") {
              const teamId = selectedTeamList[i].builder_team_id;
              dispatch(onRemoveBuilderTeam(teamId));
            } else if (displayUserRoleFull === "Viewer") {
              const teamId = selectedTeamList[i].viewer_team_id;
              dispatch(onRemoveViewerTeam(teamId));
            }
          }

          // Resets the "Select All" control on the table.
          if (setResetTableSelectAll) {
            setResetTableSelectAll(true);
          }
        }
      }
    } else if (listType === "WalkthroughViewerTeamPermissions") {
      // Handles Individual Walkthrough Viewer Team Permissions
      if (viewerTeamType === "Individual") {
        if (removeMode === "single" || removeMode === "bulk") {
          // Remove one individual viewer permission from database on confirmation
          if (
            onDeleteOneTeamPermissions &&
            selectedWalkthroughViewerTeamPermissionsList
          ) {
            dispatch(
              onDeleteOneTeamPermissions(
                selectedWalkthroughViewerTeamPermissionsList[0]
                  .walkthrough_permission_id
              )
            );
          }
          handleHideModal();
        }
      }
      // Handles Group Walkthrough Viewer Team Permissions
      else if (viewerTeamType === "Group") {
        if (removeMode === "single") {
          // Remove one group viewer permission from database on confirmation
          if (
            onDeleteOneTeamPermissions &&
            selectedWalkthroughViewerTeamPermissionsList
          ) {
            dispatch(
              onDeleteOneTeamPermissions(
                selectedWalkthroughViewerTeamPermissionsList[0]
                  .walkthrough_permission_id
              )
            );
          }
          handleHideModal();
        } else if (removeMode === "bulk") {
          // Remove one group viewer permission from database on confirmation
          if (selectedWalkthroughViewerTeamPermissionsList) {
            for (
              let i = 0;
              i < selectedWalkthroughViewerTeamPermissionsList.length;
              i++
            ) {
              // Remove each user from "selectedUserEmailList"
              if (onDeleteOneTeamPermissions) {
                dispatch(
                  onDeleteOneTeamPermissions(
                    selectedWalkthroughViewerTeamPermissionsList[i]
                      .walkthrough_permission_id
                  )
                );
              }
            }
          }

          handleHideModal();
        }
      }
    }
  };

  // Modal close handler
  const handleHideModal = () => {
    setShowModalRemove(false);
  };

  // Gemerate Somg;e Btm
  const GenerateSingleBtn = ({ inputList }) => (
    <>
      <div className="modalSubHeader">
        WARNING: You have selected <i>“Delete”</i> to remove{" "}
        <b>{`${inputList[0].name}`}</b>, please confirm to proceed or select
        cancel to return to the previous page.
      </div>
    </>
  );

  // Generate Bulk Btn
  const GenerateBulkBtn = ({ inputList }) => (
    <>
      <div className="modalSubHeader">
        WARNING: You have selected to <i>“Bulk Delete”</i> the following
        user(s), please confirm to proceed or select cancel to return to the
        previous page.
      </div>

      <ol className="list-decimal list-inside mb-8">
        {inputList &&
          inputList.map((team) => <li key={team.name}>{`${team.name}`}</li>)}
      </ol>
    </>
  );

  return (
    <EmptyModal
      isOpen={showModalRemove}
      onClose={handleHideModal}
      size="large"
      customClass="ut-py-5"
    >
      {
        // Process for Remove Teams
        selectedTeamList !== null && selectedTeamList !== undefined && (
          <>
            <div className="modalHeader">
              {removeMode === "single"
                ? `Confirm Removal of Team?`
                : removeMode === "bulk"
                ? `Confirm Removal of Multiple Team(s)?`
                : ""}
            </div>

            {removeMode === "single" ? (
              <GenerateSingleBtn inputList={selectedTeamList} />
            ) : removeMode === "bulk" ? (
              <GenerateBulkBtn inputList={selectedTeamList} />
            ) : (
              ""
            )}
          </>
        )
      }

      {
        // Process for Remove Viewer Teams
        selectedWalkthroughViewerTeamPermissionsList !== null &&
          selectedWalkthroughViewerTeamPermissionsList !== undefined && (
            <>
              <div className="modalHeader">
                {removeMode === "single"
                  ? `Confirm Removal of Viewer Team?`
                  : removeMode === "bulk"
                  ? `Confirm Removal of Multiple Viewer Team(s)?`
                  : ""}
              </div>

              {removeMode === "single" ? (
                <GenerateSingleBtn
                  inputList={selectedWalkthroughViewerTeamPermissionsList}
                />
              ) : removeMode === "bulk" ? (
                <GenerateBulkBtn
                  inputList={selectedWalkthroughViewerTeamPermissionsList}
                />
              ) : (
                ""
              )}
            </>
          )
      }

      <form
        noValidate
        autoComplete="off"
        onSubmit={handleConfirmRemoveTeamsFormSubmission}
        className="modalForm"
      >
        <div className="flex justify-end gap-2 w-1/3 ml-auto">
          <Button
            variant="primary"
            color="primary"
            size="fluid"
            text="Cancel"
            onClick={handleHideModal}
          >
            Cancel
          </Button>
          <Button variant="primary" color="primary" size="fluid" text="Yes">
            Yes
          </Button>
        </div>
      </form>
    </EmptyModal>
  );
};

export default ComponentRemoveTeams;
