import {
  sortByNameAZ,
  sortByLastUpdated,
  sortByDateCreated,
} from "../../utility/functions/sorting";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  callDELETERemoveWalkthrough,
  callGetRestOFWalkthroughs,
  callGETRetrieveAllWalkthroughsByUser,
  callGETRetrieveSummaryOfWalkthroughByUser,
  callGETRetrieveWalkthrough,
  callPOSTPublishWalkthrough,
  callPOSTUnpublishWalkthrough,
  callPOSTUpdateGeneralSettingsOfWalkthrough,
  callPOSTUpdateOtherSettingsOfWalkthrough,
  callPOSTUpdateTagsOfWalkthrough,
} from "../../axios/v2/callsWalkthrough";
import { createWalkthroughByUser } from "../../axios/v1/calls";
import {
  callGETAllPermissionsFromSingleWalkthrough,
  callPOSTAddOrRemoveIndividualPermissionsFromSingleWalkthrough,
  callDELETEOneIndividualPermissionsFromSingleWalkthrough,
  callDELETEOneTeamPermissionsFromSingleWalkthrough,
  callPOSTAddOrRemoveTeamPermissionsFromSingleWalkthrough,
} from "../../axios/v2/callsWalkthroughPermissions";
import { alertState } from "./reducerAlert";
import {
  Walkthrough,
  walkthroughsSummary,
} from "../../interfaces/interface Organization";
import { callGETSingleWalkthroughUserSegmentation } from "../../axios/v2/callsUserSegment";
import { UserSegmentation } from "../../interfaces/interfaceSegmentation";

//action

// request for single walkthrough
export const getSingleWalkthroughReq = createAsyncThunk<any, string>(
  "walkthrough/getSingleWalkthrough",
  async (walkthroughID, thunkApi) => {
    try {
      const res = (await callGETRetrieveWalkthrough(walkthroughID)) as any;
      const result = res.data.payload;
      return result;
    } catch (err: any) {
      if (err.response) {
        const errStatus = err.response.status; // Integer
        const errPayload = err.response.data;
        const errMsg = errPayload.msg;

        console.log(errStatus);
        console.log(errPayload);
        console.log(errMsg);
      }
      const result = {
        alert: {
          type: "error",
          message: err.toString(),
        },
      };
      return thunkApi.rejectWithValue(result);
    }
  }
);

//request for delete single walkthrough
export const deleteSingleWalkthroughReq = createAsyncThunk<any, string>(
  "walkthrough/deleteSingleWalkthrough",
  async (walkthroughID, thunkApi) => {
    try {
      const res = (await callDELETERemoveWalkthrough(walkthroughID)) as any;
      const result = res.data;
      return result;
    } catch (err: any) {
      if (err.response) {
        const errStatus = err.response.status; // Integer
        const errPayload = err.response.data;
        const errMsg = errPayload.msg;

        console.log(errStatus);
        console.log(errPayload);
        console.log(errMsg);
      }

      const result = {
        alert: {
          type: "error",
          message: err.toString(),
        },
      };
      return thunkApi.rejectWithValue(result);
    }
  }
);

// Update general settings of a walkthrough
export const updateWalkthrougGeneralSetting = createAsyncThunk<
  any,
  { walkthroughID: string; name: string; page_url: string }
>("walkthrough/updateGeneralSetting", async (walkthroughData, thunkApi) => {
  const { walkthroughID, name, page_url } = walkthroughData;
  try {
    const res = (await callPOSTUpdateGeneralSettingsOfWalkthrough(
      walkthroughID,
      name,
      page_url
    )) as any;
    const result = res.data.payload;
    return result;
  } catch (error: any) {
    if (error.response) {
      const errStatus = error.response.status; // Integer
      const errPayload = error.response.data;
      const errMsg = errPayload.msg;

      console.log(errStatus);
      console.log(errPayload);
      console.log(errMsg);
    }

    const result = {
      alert: {
        type: "error",
        message: error.toString(),
      },
    };

    return thunkApi.rejectWithValue(result);
  }
});

export const updateWalkthroughNonGeneralSetting = createAsyncThunk<
  any,
  { walkthroughID: string; settings: any }
>("walkthrough/updateNonGeneralSetting", async (walkthroughData, thunkApi) => {
  const { walkthroughID, settings } = walkthroughData;
  console.log("walkthrough/updateNonGeneralSetting");
  try {
    const res = (await callPOSTUpdateOtherSettingsOfWalkthrough(
      walkthroughID,
      settings
    )) as any;
    const result = res.data.payload;
    return result;
  } catch (error: any) {
    if (error.response) {
      const errStatus = error.response.status; // Integer
      const errPayload = error.response.data;
      const errMsg = errPayload.msg;

      console.log(errStatus);
      console.log(errPayload);
      console.log(errMsg);
    }

    const result = {
      alert: {
        type: "error",
        message: error.toString(),
      },
    };
    return thunkApi.rejectWithValue(result);
  }
});

// Get walkthrough summaries
export const getWalkthroughsSummary = createAsyncThunk<any, string>(
  "walkthrough/getSummary",
  async (userID, thunkApi) => {
    try {
      const res = (await callGETRetrieveSummaryOfWalkthroughByUser(
        userID
      )) as any;
      const { payload } = res.data;
      const { walkthroughs } = payload;
      const result = {
        walkthroughsSummary: Array.isArray(walkthroughs) ? walkthroughs : [],
      };
      return result;
    } catch (err: any) {
      const errStatus = err.response.status; // Integer
      const errPayload = err.response.data;
      const errMsg = errPayload.msg;

      console.log(errStatus);
      console.log(errPayload);
      console.log(errMsg);

      const result = {
        alert: {
          type: "error",
          message: err,
        },
      };

      return thunkApi.rejectWithValue(result);
    }
  }
);

// Get walkthroughs for user
export const getWalkthroughs = createAsyncThunk<any, string>(
  "walkthrough/getWalkthrough",
  async (userID, thunkApi) => {
    try {
      const walkthroughData = {
        userID: userID,
        perPage: 25,
        page: 1,
      };

      const res = (await callGETRetrieveAllWalkthroughsByUser(
        walkthroughData
      )) as any;
      const payload = res.data.payload;
      const { walkthroughs, walkthroughCount } = payload;
      const result = {
        walkthroughs: Array.isArray(walkthroughs) ? walkthroughs : [],
        walkthroughCount: walkthroughCount,
      };
      return result;
    } catch (err: any) {
      if (err.response) {
        const errStatus = err.response.status; // Integer
        const errPayload = err.response.data;
        const errMsg = errPayload.msg;

        console.log(errStatus);
        console.log(errPayload);
        console.log(errMsg);
      }

      const result = {
        alert: {
          type: "error",
          message: err
            ? err.toString()
            : `Failed to retrieve walkthroughs for ${userID}`,
        },
      };
      thunkApi.rejectWithValue(result);
    }
  }
);

export const getRestOfWalkthroughs = createAsyncThunk<any, string>(
  "walkthrough/getRestWalkthrough",
  async (userId: string, thunkApi) => {
    try {
      const res = (await callGetRestOFWalkthroughs(userId)) as any;
      const payload = res.data.payload;
      const { walkthroughs } = payload;
      const result = {
        walkthroughs: Array.isArray(walkthroughs) ? walkthroughs : [],
      };
      return result;
    } catch (error: any) {
      if (error.response) {
        const errStatus = error.response.status; // Integer
        const errPayload = error.response.data;
        const errMsg = errPayload.msg;

        console.log(errStatus);
        console.log(errPayload);
        console.log(errMsg);
      }

      const result = {
        alert: {
          type: "error",
          message: error
            ? error.toString()
            : `Failed to retrieve walkthroughs for ${userId}`,
        },
      };
      thunkApi.rejectWithValue(result);
    }
  }
);

// Update walkthrough tags
export const updateWalkhtroughTags = createAsyncThunk<
  any,
  { walkthroughID: string; tags: string[] }
>("walkhtrough/updateTags", async (walkthroughData, thunkApi) => {
  const { walkthroughID, tags } = walkthroughData;
  try {
    const res = (await callPOSTUpdateTagsOfWalkthrough(
      walkthroughID,
      tags
    )) as any;
    const result: {
      walkthroughID: string;
      tags: string[] | null;
    } = {
      walkthroughID: walkthroughID,
      tags: res.data ? res.data.tags : null,
    };
    return result;
  } catch (err: any) {
    if (err.response) {
      const errStatus = err.response.status; // Integer
      const errPayload = err.response.data;
      const errMsg = errPayload.msg;

      console.log(errStatus);
      console.log(errPayload);
      console.log(errMsg);
    }

    const result = {
      alert: {
        type: "error",
        message: err.toString(),
      },
    };
    return thunkApi.rejectWithValue(result);
  }
});

//publish walkthrough
export const publishWalkthroughPost = createAsyncThunk<any, string>(
  "walkthrough/publishWalkthrough",
  async (walkthroughID, thunkApi) => {
    try {
      const res = (await callPOSTPublishWalkthrough(walkthroughID)) as any;
      const result = {
        walkthroughID: res.data && res.data._id,
        publish: res.data && res.data.publish,
      };
      return result;
    } catch (err: any) {
      const result = {
        alert: {
          type: "error",
          message: err.toString,
        },
      };
      return thunkApi.rejectWithValue(result);
    }
  }
);

//unpublish walkthrough
export const unPublishWalkthroughPost = createAsyncThunk<any, string>(
  "walkthrough/unpublishWalkthrough",
  async (walkthroughID, thunkApi) => {
    try {
      const res = (await callPOSTUnpublishWalkthrough(walkthroughID)) as any;
      const result = {
        walkthroughID: res.data && res.data._id,
        publish: res.data && res.data.publish,
      };
      return result;
    } catch (err: any) {
      const errStatus = err.response.status; // Integer
      const errPayload = err.response.data;
      const errMsg = errPayload.msg;

      console.log(errStatus);
      console.log(errPayload);
      console.log(errMsg);
      const result = {
        alert: {
          type: "error",
          message: err,
        },
      };
      return thunkApi.rejectWithValue(result);
    }
  }
);
//create walkthrough
export const createWalkthrough = createAsyncThunk<
  any,
  { owner: string; id: string; name: string; host_location: string }
>("walkthrough/createWalkthrough", async (walkthroughData, thunkApi) => {
  const { owner, id, name, host_location } = walkthroughData;
  try {
    const res = (await createWalkthroughByUser(
      owner,
      id,
      name,
      host_location
    )) as any;
    return res;
  } catch (err: any) {
    const errStatus = err.response.status; // Integer
    const errPayload = err.response.data;
    const errMsg = errPayload.msg;

    console.log(errStatus);
    console.log(errPayload);
    console.log(errMsg);

    const result = {
      alert: {
        type: "error",
        message: err.toString(),
      },
    };
    return thunkApi.rejectWithValue(result);
  }
});
// Get all permissions from single walkthrough
export const getAllSharingPermissionsFromSingleWalkthrough = createAsyncThunk<
  any,
  string
>(
  "walkthrough/getAllSharingPermissionFromSingleWk",
  async (walkthroughID, thunkApi) => {
    try {
      const res = (await callGETAllPermissionsFromSingleWalkthrough(
        walkthroughID
      )) as any;
      const result = res.data.payload;
      return result;
    } catch (err: any) {
      console.log("getAllSharingPermissionsFromSingleWalkthrough err", err);
      const result = {
        alert: {
          type: "error",
          message: err.toString(),
        },
      };
      return thunkApi.rejectWithValue(result);
    }
  }
);
// bulk add or remove individual sharing permissions from single walkthorugh
export const updateIndvSharingPermissionsFromSingleWalkthrough =
  createAsyncThunk<any, { walkthrough_id: string; users: any[] }>(
    "walkthrough/updateIndvSharingPermissionsFromSingleWalkthrough",
    async (walkthroughData, thunkApi) => {
      const { walkthrough_id, users } = walkthroughData;

      try {
        const res =
          (await callPOSTAddOrRemoveIndividualPermissionsFromSingleWalkthrough(
            walkthrough_id,
            users
          )) as any;
        const result = res.data.payload;
        return result;
      } catch (err: any) {
        console.log(
          "callPOSTAddOrRemoveIndividualPermissionsFromSingleWalkthrough err",
          err
        );
        const result = {
          alert: {
            type: "error",
            message: err.toString(),
          },
        };
        return thunkApi.rejectWithValue(result);
      }
    }
  );

// remove one individual sharing permissions from single walkthorugh
export const deleteOneIndvSharingPermissionFromSingleWalkthrough =
  createAsyncThunk<any, string>(
    "walkthrough/deleteOneIndvSharing",
    async (walkthrough_permissions_id, thunkApi) => {
      try {
        const res =
          (await callDELETEOneIndividualPermissionsFromSingleWalkthrough(
            walkthrough_permissions_id
          )) as any;
        const payload = res.data.payload;
        const result = payload;
        return result;
      } catch (err: any) {
        console.log(
          "callPOSTAddOrRemoveIndividualPermissionsFromSingleWalkthrough err",
          err
        );
        const result = {
          alert: {
            type: "error",
            message: err.toString(),
          },
        };
        return thunkApi.rejectWithValue(result);
      }
    }
  );

// remove one team sharing permissions from single walkthorugh
export const deleteOneTeamSharingPermissionFromSingleWalkthrough =
  createAsyncThunk<any, string>(
    "walkthrough/deleteOneTeamSharingPermission",
    async (walkthrough_permissions_id, thunkApi) => {
      try {
        const res = (await callDELETEOneTeamPermissionsFromSingleWalkthrough(
          walkthrough_permissions_id
        )) as any;
        const result = res.data.payload;
        return result;
      } catch (err: any) {
        console.log(
          "callDELETEOneTeamPermissionsFromSingleWalkthrough err",
          err
        );
        const result = {
          alert: {
            type: "error",
            message: err.toString(),
          },
        };
        return thunkApi.rejectWithValue(result);
      }
    }
  );
// bulk add or remove team sharing permissions from single walkthorugh
export const updateTeamSharingPermissionsFromSingleWalkthrough =
  createAsyncThunk<any, { walkthrough_id: string; teams: any[] }>(
    "walkthrough/updateTeamSharingPermission",
    async (walkthroughData, thunkApi) => {
      console.log("updateTeamSharingPermissionsFromSingleWalkthrough");
      const { walkthrough_id, teams } = walkthroughData;
      try {
        const res =
          (await callPOSTAddOrRemoveTeamPermissionsFromSingleWalkthrough(
            walkthrough_id,
            teams
          )) as any;
        const result = res.data.payload;
        return result;
      } catch (err: any) {
        console.log(
          "callPOSTAddOrRemoveTeamPermissionsFromSingleWalkthrough err",
          err
        );
        const result = {
          alert: {
            type: "error",
            message: err.toString(),
          },
        };
        return thunkApi.rejectWithValue(result);
      }
    }
  );

export const retrieveSingleWalkthroughUserSegmentation = createAsyncThunk<
  any,
  string
>(
  "walkthrough/retrieveSingleWalkthroughUserSegmentation",
  async (walkthroughID, thunkApi) => {
    console.log("retrieveSingleWalkthroughUserSegmentation");
    try {
      const res = (await callGETSingleWalkthroughUserSegmentation(
        walkthroughID
      )) as any;
      console.log(
        "---retrieveSingleWalkthroughUserSegmentation data",
        res.data.payload
      );
      const result = res.data.payload;
      return result;
    } catch (err: any) {
      console.log("callGETSingleWalkthroughUserSegmentation err", err);
      const result = {
        alert: {
          type: "error",
          message: err.toString(),
        },
      };
      return thunkApi.rejectWithValue(result);
    }
  }
);

/** Walkthrough Initial State */
const walkthroughState = {
  walkthroughs: null as null | Walkthrough[],

  filteredWalkthroughs: null as null | Walkthrough[],
  walkthroughsSummary: null as null | walkthroughsSummary[],
  walkthroughsLength: 0,
  activeWalkthrough: {} as {} | Walkthrough,
  walkthroughTotal: 0,

  walkthroughSharingPermissionMsg: "",
  walkthroughSharingPermission: {} as any,
  activeWalkthroughUserSegmentation: null as null | UserSegmentation,

  resource_id: "",
  resource_type: "",
  resource_update_datetime: "",

  filters: {
    active: false,
    name: "",
  },
  sortType: "Date Created",

  appLoading: false,
  alert: {
    active: false,
    type: "",
    message: "",
    autoHideDuration: 2000,
  },
};

// reducer
const walkthroughSlice = createSlice({
  name: "walkthrough",
  initialState: walkthroughState,
  reducers: {
    updateFilters(state, action) {
      const nameFilter = action.payload.name;
      if (state.walkthroughs) {
        const filteredWalkthroughs = [...state.walkthroughs].filter(
          (walkthrough) => {
            return walkthrough.name
              .toLowerCase()
              .includes(nameFilter.toLowerCase());
          }
        );
        state.filteredWalkthroughs = [...filteredWalkthroughs];
        state.filters.active = !!nameFilter;
      }
    },
    updateShort(state, action) {
      let sortedWalkthroughs = [];
      let sortedWalkthroughsAll = [];
      const sortType = action.payload;
      if (state.filteredWalkthroughs && state.walkthroughs) {
        if (sortType === "Name A-Z") {
          sortedWalkthroughs = sortByNameAZ([...state.filteredWalkthroughs]);
          sortedWalkthroughsAll = sortByNameAZ([...state.walkthroughs]);
        } else if (sortType === "Last Updated") {
          sortedWalkthroughs = sortByLastUpdated([
            ...state.filteredWalkthroughs,
          ]);
          sortedWalkthroughsAll = sortByLastUpdated([...state.walkthroughs]);
        } else if (sortType === "Date Created") {
          sortedWalkthroughs = sortByDateCreated([
            ...state.filteredWalkthroughs,
          ]);
          sortedWalkthroughsAll = sortByDateCreated([...state.walkthroughs]);
        }
        state.sortType = action.payload;
        state.walkthroughs = sortedWalkthroughsAll.length
          ? [...sortedWalkthroughsAll]
          : [...state.walkthroughs];
        state.filteredWalkthroughs = sortedWalkthroughs.length
          ? [...sortedWalkthroughs]
          : [...state.filteredWalkthroughs];
      }
    },
    resetAlert: (state) => {
      state.alert.active = false;
      state.alert.type = "";
      state.alert.message = "";
      state.alert.autoHideDuration = 6000;
    },
  },
  extraReducers: (builder) => {
    let updatedWalkthroughs = [];
    // reducer for updating walkthrough
    builder
      // reducer for get single walkthrough
      .addCase(getSingleWalkthroughReq.pending, (state) => {
        state.appLoading = true;
      })
      .addCase(getSingleWalkthroughReq.fulfilled, (state, action) => {
        state.activeWalkthrough = action.payload.walkthrough;
        const newDate = new Date().toString();
        state.resource_update_datetime = newDate;
        state.appLoading = false;
      })
      .addCase(getSingleWalkthroughReq.rejected, (state, action) => {
        const payload = action.payload as any;
        const { type, message } = payload;
        state.appLoading = false;
        state.alert.active = true;
        state.alert.type = type;
        state.alert.message = message;
        state.alert.autoHideDuration = payload.alert.autoHideDuration
          ? payload.alert.autoHideDuration
          : state.alert.autoHideDuration;
      })

      // //reducer for delete single walkthrouh
      .addCase(deleteSingleWalkthroughReq.pending, (state) => {
        state.appLoading = true;
      })
      .addCase(deleteSingleWalkthroughReq.fulfilled, (state, action) => {
        state.resource_update_datetime = new Date().toString();
      })
      .addCase(deleteSingleWalkthroughReq.rejected, (state, action) => {
        const payload = action.payload as any;
        const { type, message } = payload;
        state.appLoading = false;
        state.alert.active = true;
        state.alert.type = type;
        state.alert.message = message;
        state.alert.autoHideDuration = payload.alert.autoHideDuration
          ? payload.alert.autoHideDuration
          : state.alert.autoHideDuration;
        state.appLoading = false;
      })
      // //Reducer for update walkthrough general settings
      .addCase(updateWalkthrougGeneralSetting.pending, (state) => {
        state.appLoading = true;
      })
      .addCase(updateWalkthrougGeneralSetting.fulfilled, (state) => {
        state.resource_update_datetime = new Date().toString();
        state.appLoading = false;
      })
      .addCase(updateWalkthrougGeneralSetting.rejected, (state, action) => {
        const payload = action.payload as any;
        const { type, message } = payload;
        state.appLoading = false;
        state.alert.active = true;
        state.alert.type = type;
        state.alert.message = message;
        state.alert.autoHideDuration = payload.alert.autoHideDuration
          ? payload.alert.autoHideDuration
          : state.alert.autoHideDuration;
      })
      // reducer for update walkthrough non general setting
      .addCase(updateWalkthroughNonGeneralSetting.pending, (state) => {
        state.appLoading = true;
      })
      .addCase(
        updateWalkthroughNonGeneralSetting.fulfilled,
        (state, action) => {
          console.log("non general setting updated");
          updatedWalkthroughs = [...state.walkthroughs!].map((walkthrough) => {
            if (walkthrough._id === action.payload._id) {
              return {
                ...walkthrough,
                settings: { ...action.payload.settings },
              };
            }
            return walkthrough;
          });

          state.walkthroughs = [...updatedWalkthroughs];
          state.filteredWalkthroughs = [...updatedWalkthroughs];
          state.resource_update_datetime = new Date().toString();
          state.appLoading = false;
        }
      )
      .addCase(updateWalkthroughNonGeneralSetting.rejected, (state, action) => {
        const payload = action.payload as any;
        const { type, message } = payload;
        state.appLoading = false;
        state.alert.active = true;
        state.alert.type = type;
        state.alert.message = message;
        state.alert.autoHideDuration = payload.alert.autoHideDuration
          ? payload.alert.autoHideDuration
          : state.alert.autoHideDuration;
      })
      //reducer for fetch all walkthrough summary
      .addCase(getWalkthroughsSummary.pending, (state) => {
        state.appLoading = true;
      })
      .addCase(getWalkthroughsSummary.fulfilled, (state, action) => {
        state.walkthroughsSummary = action.payload.walkthroughsSummary;
        state.appLoading = false;
      })
      .addCase(getWalkthroughsSummary.rejected, (state, action) => {
        const payload = action.payload as any;
        const { type, message } = payload;
        state.appLoading = false;
        state.alert.active = true;
        state.alert.type = type;
        state.alert.message = message;
      })
      // // reducer for fetch one wk for user
      .addCase(getWalkthroughs.pending, (state) => {
        state.appLoading = true;
      })
      .addCase(getWalkthroughs.fulfilled, (state, action) => {
        let initialSortedWalkthroughs = [];
        let initialSortedWalkthroughsAll = [];
        if (action.payload !== undefined) {
          if (state.sortType === "Date Created") {
            initialSortedWalkthroughs = sortByDateCreated([
              ...action.payload.walkthroughs,
            ]);
            initialSortedWalkthroughsAll = sortByDateCreated([
              ...action.payload.walkthroughs,
            ]);
          }
          state.walkthroughs = initialSortedWalkthroughsAll.length
            ? [...initialSortedWalkthroughsAll]
            : [...action.payload.walkthroughs];
          state.filteredWalkthroughs = initialSortedWalkthroughs.length
            ? [...initialSortedWalkthroughs]
            : [...action.payload.walkthroughs];
          state.walkthroughsLength = [...action.payload.walkthroughs].length;
          state.walkthroughTotal = action.payload.walkthroughCount;
          state.appLoading = false;
        }
      })
      .addCase(getWalkthroughs.rejected, (state, action) => {
        const payload = action.payload as any;
        const { type, message } = payload;
        state.appLoading = false;
        state.alert.active = true;
        state.alert.type = type;
        state.alert.message = message;
        state.alert.autoHideDuration = payload.alert.autoHideDuration
          ? payload.alert.autoHideDuration
          : state.alert.autoHideDuration;
      })

      .addCase(getRestOfWalkthroughs.fulfilled, (state, action) => {
        //filter out walkthroughs that are already in the state
        const newWalkthroughs = action.payload.walkthroughs.filter(
          //@ts-ignore
          (walkthrough) =>
            !state.walkthroughs!.some(
              (stateWalkthrough) => stateWalkthrough._id === walkthrough._id
            )
        );

        const updatedWalkthroughs = [
          ...state.walkthroughs!,
          ...newWalkthroughs,
        ];

        state.walkthroughs = updatedWalkthroughs;
      })
      // // reducer for update wk tags
      .addCase(updateWalkhtroughTags.pending, (state) => {
        state.appLoading = true;
      })
      .addCase(updateWalkhtroughTags.fulfilled, (state, action) => {
        updatedWalkthroughs = [...state.walkthroughs!].map((walkthroughObj) => {
          if (walkthroughObj._id === action.payload.walkthroughID) {
            return { ...walkthroughObj, tags: [...action.payload.tags] };
          }
          return walkthroughObj;
        });
        state.walkthroughs = [...updatedWalkthroughs];
        state.filteredWalkthroughs = [...updatedWalkthroughs];
        state.appLoading = false;
      })
      .addCase(updateWalkhtroughTags.rejected, (state, action) => {
        const payload = action.payload as any;
        const { type, message } = payload;
        state.appLoading = false;
        state.alert.active = true;
        state.alert.type = type;
        state.alert.message = message;
        state.alert.autoHideDuration = payload.alert.autoHideDuration
          ? payload.alert.autoHideDuration
          : state.alert.autoHideDuration;
      })
      // // reducer publish walkthrough
      .addCase(publishWalkthroughPost.pending, (state) => {
        state.appLoading = true;
      })
      .addCase(publishWalkthroughPost.fulfilled, (state, action) => {
        updatedWalkthroughs = [...state.walkthroughs!].map((walkthroughObj) => {
          if (walkthroughObj._id === action.payload.walkthroughID) {
            return { ...walkthroughObj, publish: action.payload.publish };
          }
          return walkthroughObj;
        });
        state.walkthroughs = [...updatedWalkthroughs];
        state.filteredWalkthroughs = [...updatedWalkthroughs];
        state.appLoading = false;
      })
      .addCase(publishWalkthroughPost.rejected, (state, action) => {
        const payload = action.payload as any;
        const { type, message } = payload;
        state.appLoading = false;
        state.alert.active = true;
        state.alert.type = type;
        state.alert.message = message;
        state.alert.autoHideDuration = payload.alert.autoHideDuration
          ? payload.alert.autoHideDuration
          : state.alert.autoHideDuration;
      })
      .addCase(unPublishWalkthroughPost.pending, (state) => {
        state.appLoading = true;
      })
      .addCase(unPublishWalkthroughPost.fulfilled, (state, action) => {
        updatedWalkthroughs = [...state.walkthroughs!].map((walkthroughObj) => {
          if (walkthroughObj._id === action.payload.walkthroughID) {
            return { ...walkthroughObj, publish: action.payload.publish };
          }
          return walkthroughObj;
        });
        state.walkthroughs = [...updatedWalkthroughs];
        state.filteredWalkthroughs = [...updatedWalkthroughs];
      })
      .addCase(unPublishWalkthroughPost.rejected, (state, action) => {
        const payload = action.payload as any;
        const { type, message } = payload;
        state.appLoading = false;
        state.alert.active = true;
        state.alert.type = type;
        state.alert.message = message;
        state.alert.autoHideDuration = payload.alert.autoHideDuration
          ? payload.alert.autoHideDuration
          : state.alert.autoHideDuration;
      })
      .addCase(createWalkthrough.pending, (state, action) => {
        state.appLoading = false;
      })
      .addCase(createWalkthrough.fulfilled, (state, action) => {
        state.appLoading = false;
        //TODO:
      })
      .addCase(createWalkthrough.rejected, (state, action) => {
        const payload = action.payload as any;
        const { type, message } = payload;
        state.appLoading = false;
        state.alert.active = true;
        state.alert.type = type;
        state.alert.message = message;
        state.alert.autoHideDuration = payload.alert.autoHideDuration
          ? payload.alert.autoHideDuration
          : state.alert.autoHideDuration;
      })
      // // Get all sharing permissions for a single walkthrough
      .addCase(
        getAllSharingPermissionsFromSingleWalkthrough.pending,
        (state) => {
          state.appLoading = true;
        }
      )
      .addCase(
        getAllSharingPermissionsFromSingleWalkthrough.fulfilled,
        (state, action) => {
          const payload = action.payload;
          // console.log("---GET_ALL_SHARING_PERMISSIONS_SINGLE_WALKTHROUGH");
          state.walkthroughSharingPermissionMsg = action.payload.msg;
          state.walkthroughSharingPermission = {
            viewers_list: [...payload.viewers_list],
            viewer_teams_list: [...payload.viewer_teams_list],
          };
          state.resource_id = payload.resource_id;
          state.resource_type = payload.resource_type;
          state.appLoading = false;
        }
      )
      .addCase(
        getAllSharingPermissionsFromSingleWalkthrough.rejected,
        (state, action) => {
          const payload = action.payload as any;
          const { type, message } = payload;
          state.appLoading = false;
          state.alert.active = true;
          state.alert.type = type;
          state.alert.message = message;
          state.alert.autoHideDuration = payload.alert.autoHideDuration
            ? payload.alert.autoHideDuration
            : state.alert.autoHideDuration;
        }
      )
      // // Bulk update individual sharing permissions
      .addCase(
        updateIndvSharingPermissionsFromSingleWalkthrough.pending,
        (state) => {
          state.appLoading = true;
        }
      )
      .addCase(
        updateIndvSharingPermissionsFromSingleWalkthrough.fulfilled,
        (state, action) => {
          console.log(
            "BULK_UPDATE_INDIVIDUAL_SHARING_PERMISSIONS_SINGLE_WALKTHROUGH"
          );

          state.walkthroughSharingPermissionMsg = action.payload.msg;
          state.walkthroughSharingPermission.viewers_list = [
            ...action.payload.viewers_list,
          ];
          state.resource_update_datetime = new Date().toString();
          state.appLoading = false;
        }
      )
      .addCase(
        updateIndvSharingPermissionsFromSingleWalkthrough.rejected,
        (state, action) => {
          const payload = action.payload as any;
          const { type, message } = payload;
          state.appLoading = false;
          state.alert.active = true;
          state.alert.type = type;
          state.alert.message = message;
          state.alert.autoHideDuration = payload.alert.autoHideDuration
            ? payload.alert.autoHideDuration
            : state.alert.autoHideDuration;
        }
      )
      //delete one individual sharing permissions
      .addCase(
        deleteOneIndvSharingPermissionFromSingleWalkthrough.pending,
        (state) => {
          state.appLoading = true;
        }
      )
      .addCase(
        deleteOneIndvSharingPermissionFromSingleWalkthrough.fulfilled,
        (state) => {
          state.resource_update_datetime = new Date().toString();
          state.appLoading = false;
        }
      )
      .addCase(
        deleteOneIndvSharingPermissionFromSingleWalkthrough.rejected,
        (state, action) => {
          const payload = action.payload as any;
          const { type, message } = payload;
          state.appLoading = false;
          state.alert.active = true;
          state.alert.type = type;
          state.alert.message = message;
          state.alert.autoHideDuration = payload.alert.autoHideDuration
            ? payload.alert.autoHideDuration
            : state.alert.autoHideDuration;
        }
      )
      // Delete one team sharing permissions
      .addCase(
        deleteOneTeamSharingPermissionFromSingleWalkthrough.pending,
        (state) => {
          state.appLoading = true;
        }
      )
      .addCase(
        deleteOneTeamSharingPermissionFromSingleWalkthrough.fulfilled,
        (state) => {
          state.resource_update_datetime = new Date().toString();
          state.appLoading = false;
        }
      )
      .addCase(
        deleteOneTeamSharingPermissionFromSingleWalkthrough.rejected,
        (state, action) => {
          const payload = action.payload as any;
          const { type, message } = payload;
          state.appLoading = false;
          state.alert.active = true;
          state.alert.type = type;
          state.alert.message = message;
          state.alert.autoHideDuration = payload.alert.autoHideDuration
            ? payload.alert.autoHideDuration
            : state.alert.autoHideDuration;
        }
      )
      .addCase(
        updateTeamSharingPermissionsFromSingleWalkthrough.pending,
        (state) => {
          state.appLoading = true;
        }
      )
      .addCase(
        updateTeamSharingPermissionsFromSingleWalkthrough.fulfilled,
        (state, action) => {
          console.log(
            "BULK_UPDATE_TEAM_SHARING_PERMISSIONS_SINGLE_WALKTHROUGH"
          );
          state.walkthroughSharingPermission.viewer_teams_list = [
            ...action.payload.viewer_teams_list,
          ];
          state.resource_update_datetime = new Date().toString();
          state.appLoading = false;
        }
      )
      .addCase(
        updateTeamSharingPermissionsFromSingleWalkthrough.rejected,
        (state, action) => {
          const payload = action.payload as any;
          const { type, message } = payload;
          state.appLoading = false;
          state.alert.active = true;
          state.alert.type = type;
          state.alert.message = message;
          state.alert.autoHideDuration = payload.alert.autoHideDuration
            ? payload.alert.autoHideDuration
            : state.alert.autoHideDuration;
        }
      )
      /** retrieveSingleWalkthroughUserSegmentation */
      .addCase(retrieveSingleWalkthroughUserSegmentation.pending, (state) => {
        // NOTE: should not need to trigger appLoading for retrieving this data, as it is a single dropdown
        // This state.activeWalkthroughUserSegmentation is in the PageWalkthroughPermission
        state.appLoading = true;
      })
      .addCase(
        retrieveSingleWalkthroughUserSegmentation.fulfilled,
        (state, action) => {
          const payload = action.payload;
          const { segmentationDocument } = payload;
          state.activeWalkthroughUserSegmentation = segmentationDocument;
          state.appLoading = false;
        }
      )
      .addCase(retrieveSingleWalkthroughUserSegmentation.rejected, (state) => {
        // NOTE: should not need to trigger appLoading for retrieving this data
        // set state.activeWalkthroughUserSegmentation to null if this fails
        // state.appLoading = false;
        state.activeWalkthroughUserSegmentation = null;
      });
  },
});

const { actions } = walkthroughSlice;
export const { updateFilters, updateShort, resetAlert } = actions;

const reducerWalkthrough = walkthroughSlice.reducer;
export default reducerWalkthrough;
