import React, { useState } from "react";

import { HostWalkthrough } from "../interfaces/interface Organization";
import ComponentSearch from "./ComponentSearch";
import {
  Card,
  CardHeader,
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

interface Props {
  walkthroughList: HostWalkthrough[];
  setProccesedData: React.Dispatch<
    React.SetStateAction<HostWalkthrough[] | undefined>
  >;
}
const ComponentChangeStatus = ({
  walkthroughList,
  setProccesedData,
}: Props) => {
  const [checked, setChecked] = useState(walkthroughList);
  const [initStatus, setInitStatus] = useState(false);
  const [searchInput, setSearchInput] = useState("");

  const handleToggleAll = () => {
    const editProps = JSON.parse(JSON.stringify(checked));
    const newChecked = editProps.map((walkthrough: HostWalkthrough) => {
      return {
        ...walkthrough,
        status: !initStatus,
        change: true,
      };
    });
    setInitStatus(!initStatus);
    // checked state is for ui update
    setChecked(newChecked);
    // setCheckedState is for data that gonna send to api
    setProccesedData(newChecked);
  };
  // Handles each Toggle of Checkbox-Checked/UnChecked
  const handleToggle = (value: HostWalkthrough["walkthrough_id"]) => () => {
    const editProps = JSON.parse(JSON.stringify(checked));
    const newChecked = editProps.map((walkthrough: HostWalkthrough) =>
      walkthrough.walkthrough_id === value
        ? { ...walkthrough, status: !walkthrough.status, change: true }
        : walkthrough
    );
    setChecked(newChecked);
    const filterChecked = newChecked.filter(
      (walkthrough: HostWalkthrough) => walkthrough.change === true
    );
    setProccesedData(filterChecked);
  };

  //handle search bar
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setSearchInput(e.target.value);
  };
  return (
    <div className="flex-1 w-44">
      <ComponentSearch handleSearch={handleSearch} />
      <Card>
        <div className="flex flex-col">
          <div className="flex-row flex-grow w-full">
            <CardHeader
              avatar={
                <Checkbox
                  onClick={handleToggleAll}
                  inputProps={{
                    "aria-label": "all item selected",
                  }}
                />
              }
              title={"Walkthroughs"}
              sx={{
                padding: "10px 10px",
              }}
            />
          </div>

          <div className="flex-row pageDivider my-0" />

          <div className="w-full">
            <List
              sx={{
                height: 230,
                bgcolor: "background.paper",
                overflow: "auto",
              }}
              dense
              component="div"
              role="list"
            >
              {checked !== null && checked.length > 0
                ? checked
                    .filter((list) => {
                      if (searchInput === "") {
                        //if query is empty
                        return list;
                      } else if (
                        list.walkthrough_name
                          .toLowerCase()
                          .includes(searchInput.toLowerCase())
                      ) {
                        return list;
                      }
                      return false;
                    })
                    .map((item, index) => {
                      return (
                        <div key={index}>
                          <ListItem
                            role="listitem"
                            button
                            onClick={
                              // Must push the entire Member Object in for the list to work
                              handleToggle(item.walkthrough_id)
                            }
                            sx={{
                              padding: "0px 10px",
                            }}
                          >
                            <ListItemIcon>
                              <Checkbox
                                checked={item.status}
                                tabIndex={-1}
                                // for cypress testing
                                test-shared-walkthrough={index}
                                disableRipple
                              />
                            </ListItemIcon>
                            <ListItemText
                              primary={item.walkthrough_name}
                              //@ts-ignore
                              walkthrough_id={item.walkthrough_id}
                            />
                          </ListItem>
                        </div>
                      );
                    })
                : null}

              <List />
            </List>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default ComponentChangeStatus;
