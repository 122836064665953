import React from "react";
import { SearchDropdown } from "usertip-component-library";

interface Props {
  /** Prop for set selected dropdown value */
  value: number;
  /** Prop for handling dropdown */
  settingHandler: (id: any, value: any) => void;
}

const LineHeightSetting = ({ value, settingHandler }: Props) => {
  const lineHeightData: { id: string; value: string }[] = [
    { id: "1", value: "normal" },
    { id: "2", value: "1" },
    { id: "3", value: "1.15" },
    { id: "4", value: "1.5" },
    { id: "5", value: "2" },
  ];

  return (
    <>
      <h1 className="text-sm">Line Height:</h1>
      <SearchDropdown
        dataArray={lineHeightData}
        selectedValue={{ value: `${value}` }}
        onClick={settingHandler}
        customWidth="w-full"
        scroll={false}
        search={false}
      />
    </>
  );
};

export default LineHeightSetting;
