import React, { useState } from "react";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import CancelIcon from "@mui/icons-material/Cancel";

import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import sizeToBytes from "../../../utility/functions/sizeToBytes";
import bytesToSize from "../../../utility/functions/bytesToSize";
import { Button, EmptyModal } from "usertip-component-library";
import SelectImageList from "./SelectImageList";
import blobUrlToFile from "../../../utility/functions/blobUrlToFile";

interface Props {
  id: string;
  imageHandler?: (componentId: string, imageUrl: string, data: any) => void;
}

const allowedImageTypes = ["image/gif", "image/jpeg", "image/png"];

const ImageUpload = ({ id, imageHandler }: Props) => {
  const [image, setImage] = useState<string | null>(null);
  const [imgErrorMsg, setImgErrorMsg] = useState<string[]>([]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const { org_plan, org_plan_usage } = useSelector(
    (state: RootState) => state.org
  );

  const { userData } = useSelector((state: RootState) => state.user);

  const handleImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
    /** Stop the form from reloading the page */
    e.preventDefault();
    const inputBtn = e.target as HTMLInputElement;

    /** If there's no element, do nothing */
    if (!inputBtn) return;

    /** If there's no file, do nothing */
    if (!inputBtn!.value.length) return;

    /** If there's no file uploaded, do nothing */
    if (!inputBtn.files) return;

    console.log("---handleImage", e.target.value);

    if (inputBtn.files[0].size > 30000000) {
      console.error("Exceeded maximum file size of 30MB.");
      const errmsg = [...imgErrorMsg, "Exceeded maximum file size of 30MB."];
      setImgErrorMsg(errmsg);
      return;
    }

    const isMediaLimitReached = checkImageLimit(inputBtn.files[0].size);

    if (isMediaLimitReached) {
      const errmsg = [...imgErrorMsg, "Exceeded image storage limit"];
      setImgErrorMsg(errmsg);
      return;
    }

    if (!allowedImageTypes.includes(inputBtn.files[0].type)) {
      console.log("File type error. File is not a gif, jpg, jpeg or png");
      const errmsg = [
        ...imgErrorMsg,
        "File type error. File is not a gif, jpg, jpeg or png",
      ];
      setImgErrorMsg(errmsg);
      return;
    }

    const fileSizeToString = bytesToSize(inputBtn.files[0].size);

    const data = {
      name: inputBtn.files[0].name,
      contentType: inputBtn.files[0].type,
      imageFile: inputBtn.files[0] as File,
      size: fileSizeToString,
      orgId: userData.org!,
      email: userData.email!,
      cognitoId: userData.cognito_id,
    };

    setImgErrorMsg([]);
    const reader = new FileReader();

    reader.readAsDataURL(inputBtn.files[0]);

    /**Add event listener load */
    reader.addEventListener("load", () => {
      /**set image url */
      setImage(reader.result as string);
      imageHandler && imageHandler(id, reader.result as string, data);
    });
  };

  const checkImageLimit = (imageSize: number) => {
    const imageLimit = org_plan?.plan_limit_details.media_data_storage;
    const storageUsed = org_plan_usage?.media;

    const byteImageLimit = sizeToBytes(imageLimit!);
    const byteStorageUsed = sizeToBytes(storageUsed!);

    const storageLimit = byteImageLimit - byteStorageUsed;

    if (imageSize > storageLimit) {
      console.error("Exceeded image storage limit");
      return true;
    } else {
      return false;
    }
  };

  const handleSelectImageFromLibrary = async (
    mediaUrl: string,
    mediaName: string
  ) => {
    const imageName = mediaName.split(".");
    imageName.pop();

    const getImageAsFile = await blobUrlToFile(mediaUrl, imageName.join());

    const data = {
      name: getImageAsFile.name,
      contentType: getImageAsFile.type,
      imageFile: getImageAsFile as File,
      size: bytesToSize(getImageAsFile.size),
      orgId: userData.org!,
      email: userData.email!,
      cognitoId: userData.cognito_id,
    };

    setIsModalOpen(false);
    setImage(mediaUrl);
    imageHandler && imageHandler(id, mediaUrl, data);
  };

  return (
    <>
      <div
        className={`relative mx-auto cursor-pointer mt-4 w-[298px] h-[196px] ${
          imgErrorMsg.length > 0
            ? "bg-default-error-20"
            : "bg-default-primary-lightest"
        } rounded-md flex flex-col items-center justify-center shadow-[inset_0px_2px_5px_rgba(0,0,0,0.1)] hover:border-2 border-default-primary-lightest outline-offset-2`}
      >
        {!image && (
          <>
            <div
              className={
                "flex items-center justify-center  bg-default-primary-light w-[40px] h-[40px] rounded-full shadow-inner"
              }
            >
              <AddCircleIcon
                style={{
                  color: imgErrorMsg.length > 0 ? "#F9BCBC" : "#E4DDFC",
                  fontSize: "50px",
                }}
              />
            </div>
            <h2 className="text-default-primary">Add an Image</h2>
            <p className="text-xs text-default-neutral-60">Max 30Mb</p>
            <input
              className="w-full h-full absolute top-0 left-0 opacity-0 cursor-pointer text-[0]"
              type="file"
              accept=".jpg, .jpeg, .png, .gif"
              onChange={handleImage}
              onClick={() => {
                setImgErrorMsg([]);
                return false;
              }}
            />
          </>
        )}
        {image && (
          <img
            className="w-full h-full object-cover rounded-md p-4"
            src={image}
            alt="Image"
          />
        )}
        {image && (
          <div className="absolute top-2 right-2">
            <Button
              color="secondary"
              size="small"
              text="Remove"
              variant="primary"
              onClick={() => {
                setImage(null);
                imageHandler && imageHandler(id, "", null);
              }}
            />
          </div>
        )}
      </div>
      {imgErrorMsg.length > 0 &&
        imgErrorMsg.map((val, index) => (
          <span
            key={index}
            className="mt-3 text-sm text-default-error text-center"
          >
            <b>{val}</b>
          </span>
        ))}
      {!image && (
        <p className="text-center mt-3">
          Or{" "}
          <span
            className="text-default-primary cursor-pointer"
            onClick={() => setIsModalOpen(true)}
          >
            add image from library
          </span>
        </p>
      )}
      <EmptyModal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
        customWidthClass="w-3/4 pr-[2px] py-2"
      >
        <div className="py-5 px-7  relative overflow-hidden">
          <div className="flex justify-between">
            <h1 className="text-3xl font-bold">Image Library</h1>
            <div
              className="text-default-neutral-60 cursor-pointer"
              onClick={() => {
                setIsModalOpen(false);
              }}
            >
              <CancelIcon fontSize="small" />
            </div>
          </div>

          <SelectImageList
            handleSelectImageFromLibrary={handleSelectImageFromLibrary}
          />
        </div>
      </EmptyModal>
    </>
  );
};

export default ImageUpload;
