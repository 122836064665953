import React, { useEffect, useState, useMemo } from "react";

import ComponentEditListGenerator from "./ComponentEditListGenerator";
import ComponentEditListBtnArray from "./ComponentEditListBtnArray";
import ComponentAlertSnackbar from "./ComponentAlertSnackbar";

import {
  filterDuplicatedMembersByName,
  not,
  intersection,
  union,
  numberOfChecked,
  checkMembersToAdd,
  checkMembersToRemove,
} from "./_functions/FunctionEditList";
import { useDispatch } from "react-redux";
import { EmptyModal, Button } from "usertip-component-library";

const ComponentEditWalkthroughsViewerTeamPermissions = ({
  listType,

  dataSet,
  permissionDataSet,

  walkthroughID,
  onHandleBulkEditTeamWalkthroughPermission,

  showModalEdit,
  setShowModalEdit,

  editMode,
}) => {
  const [showAlertSnackbar, setShowAlertSnackbar] = useState(false);
  const [selectedWalkthroughID, setSelectedWalkthroughID] = useState("");

  const membersListName = `All Viewer Teams`;
  const membersTeamListName = `Selected Viewer Teams`;

  // Setting List-Store Variables
  const [checked, setChecked] = useState([]);
  const [memberTeamDataSet, setMemberTeamDataSet] = useState([]);

  // After identifying the respective list, Populate them into the Team List.
  // Right-Hand-Side List Data
  const [memberTeamList, setMemberTeamList] = useState([]);

  // Make a copy of "All Users", then remove them if they are duplicated Members already on MembersTeam List
  let membersDataSet = useMemo(
    () => filterDuplicatedMembersByName(dataSet, permissionDataSet),
    [permissionDataSet, dataSet]
  );

  // "permissionDataSet" will store Existing "Builders / Viewers" List
  // Left-Hand-Side List Data
  const [membersList, setMembersList] = useState([]);

  const dispatch = useDispatch();

  // Tracks changes in "membersDataSet". Update when new change is available.
  useEffect(() => {
    // Only update "membersList" when "membersDataSet" is being filtered & has new data changes.
    if (membersDataSet) {
      setMembersList(membersDataSet);
    }
  }, [membersDataSet]);

  // Tracks changes in "permissionDataSet". Update when new change is available.
  useEffect(() => {
    const checkSelectedWalkthroughID = walkthroughID;
    // Updates WalkthroughID when changed
    if (checkSelectedWalkthroughID !== selectedWalkthroughID) {
      setSelectedWalkthroughID(walkthroughID);
    }

    // Updates data to Modal view for Left + Right Lists
    if (permissionDataSet && permissionDataSet !== undefined) {
      setMemberTeamDataSet([...permissionDataSet]);
      setMemberTeamList([...permissionDataSet]);
    }
    // If "permissionDataSet" is undefined, set lists to empty to avoid error being thrown
    else {
      setMemberTeamDataSet([]);
      setMemberTeamList([]);
    }
  }, [membersDataSet, permissionDataSet, selectedWalkthroughID, walkthroughID]);

  const handleEditTeamsFormSubmission = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (editMode === "single") {
      // Due to API structure, Compare differences between existing & new, then separate the data for processes seaparately for ADD + REMOVE
      //
      // When New Listings are confirmed:
      // 1. Update "membersList" to existing "Builders / Viewers" List
      // 2. Update "memberTeamList" to existing "Builder Team / Viewer Team" List
      const membersToAdd = checkMembersToAdd(memberTeamList, memberTeamDataSet);
      const membersToRemove = checkMembersToRemove(membersList, membersDataSet);

      // Checks if Both Add + Remove Lists have Changed
      if (membersToAdd.length === 0 && membersToRemove.length === 0) {
        setShowAlertSnackbar(true);
      } else {
        // TODO: Update to API for Viewer Team Permissions.
        // Currently no API for UPDATE / DELETE
        // send the new list, backend will access and add / remove accordingly
        // for bulk edit
      }
    } else if (editMode === "bulk") {
      // Due to API structure, Compare differences between existing & new, then separate the data for processes seaparately for ADD + REMOVE
      //
      // When New Listings are confirmed:
      // 1. Update "membersList" to existing "Builders / Viewers" List
      // 2. Update "memberTeamList" to existing "Builder Team / Viewer Team" List
      // FIXME: membersToAdd and membersToRemove are comparing the wrong data
      //   const membersToAdd = checkMembersToAdd(memberTeamList, memberTeamDataSet);
      //   const membersToRemove = checkMembersToRemove(membersList, membersDataSet);

      //   console.log("membersToAdd", membersToAdd);
      //   console.log("membersToRemove", membersToRemove);

      //   // Checks if Both Add + Remove Lists have Changed
      //   if (membersToAdd.length === 0 && membersToRemove.length === 0) {
      //     setShowAlertSnackbar(true);
      //   } else {
      //     // TODO: Update to API for Viewer Team Permissions.
      //     // Currently no API for UPDATE / DELETE
      //   }
      const groupData = {
        walkthrough_id: walkthroughID,
        teams: memberTeamList,
      };
      dispatch(onHandleBulkEditTeamWalkthroughPermission(groupData));
      setShowModalEdit(false);
    }
  };

  // Modal close handler
  const handleHideModal = () => {
    // Need to RESET all the original fields if "CONFIRM" Button Not Clicked
    setMemberTeamList(memberTeamDataSet);
    setMembersList(membersDataSet);
    // RESET Snackbar as well
    setShowAlertSnackbar(false);

    setShowModalEdit(false);
  };

  return (
    <EmptyModal
      isOpen={showModalEdit}
      close={handleHideModal}
      customWidth=""
      customClass="ut-modal-lg-fix"
    >
      <div className="modalHeader">
        {editMode === "bulk" ? `Edit Viewer Team Permissions` : ""}
      </div>

      {editMode === "bulk" && (
        <div className="modalSubHeader">
          Edit <i>Viewer Team</i> Permissions for{" "}
          <b>{`${membersTeamListName}`}</b>.
        </div>
      )}

      <form
        noValidate
        autoComplete="off"
        onSubmit={handleEditTeamsFormSubmission}
        className="modalForm"
      >
        <div className="inline-flex mb-8">
          <ComponentEditListGenerator
            listType={listType}
            numberOfChecked={numberOfChecked}
            checked={checked}
            setChecked={setChecked}
            not={not}
            union={union}
            title={membersListName}
            members={membersList}
            displayDetails={"nameOnly"}
          />

          <ComponentEditListBtnArray
            checked={checked}
            setChecked={setChecked}
            membersList={membersList}
            memberTeamList={memberTeamList}
            setMembersList={setMembersList}
            setMemberTeamList={setMemberTeamList}
            not={not}
            intersection={intersection}
          />

          <ComponentEditListGenerator
            listType={listType}
            numberOfChecked={numberOfChecked}
            checked={checked}
            setChecked={setChecked}
            not={not}
            union={union}
            title={`${membersTeamListName}`}
            members={memberTeamList}
            displayDetails={"nameOnly"}
          />
        </div>

        <div className="flex justify-end gap-2">
          <Button
            variant="primary"
            color="primary"
            size="medium"
            text="Cancel"
            onClick={handleHideModal}
          />

          <Button
            variant="primary"
            color="primary"
            size="medium"
            text="Confirm"
            type="submit"
          />
        </div>
      </form>

      <ComponentAlertSnackbar
        showAlertSnackbar={showAlertSnackbar}
        setShowAlertSnackbar={setShowAlertSnackbar}
        variant={"error"}
        title="Please make changes to Viewer Team Permissions List to Confirm."
      />
    </EmptyModal>
  );
};

export default ComponentEditWalkthroughsViewerTeamPermissions;
