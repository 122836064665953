//TODO: create page onboarding
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { callPostUpdateUserOnBoard } from "../axios/v2/callsUser";
import ComponentOnBoarding from "../components/OnBoarding/ComponentOnBoarding";
import { RootState } from "../store";
import { updateOnBoardingStatus } from "../store/reducers/reducerUser";

const PageOnBoarding = () => {
  const isOnBoarded = useSelector(
    (state: RootState) => state.user.userData.is_onboarded
  );

  return <ComponentOnBoarding is_onboarded={isOnBoarded} />;
};

export default PageOnBoarding;
