// Gather selectedUser with Details for processing
export const retrieveUserDetailsFromEmailList = (role_id, dataSet) => {
  let userListWithDetails = [];
  for (let i = 0; i < dataSet.length; i++) {
    if (role_id === dataSet[i].role_id) {
      userListWithDetails.push(dataSet[i]);
      return userListWithDetails;
    }
  }
};

// Gather full list of selectedUsers with Details for processing
export const retrieveBulkUserDetailsFromEmailList = (
  userEmailList,
  dataSet
) => {
  let userListWithDetails = [];
  for (let i = 0; i < userEmailList.length; i++) {
    for (let j = 0; j < dataSet.length; j++) {
      if (userEmailList[i] === dataSet[j].role_id) {
        userListWithDetails.push(dataSet[j]);
        break;
      }
    }
  }

  return userListWithDetails;
};

// Gather full list of selectedUsers with Details for processing
export const retrieveBulkUserDetailsFromNameList = (userEmailList, dataSet) => {
  let userListWithDetails = [];
  for (let i = 0; i < userEmailList.length; i++) {
    for (let j = 0; j < dataSet.length; j++) {
      if (userEmailList[i] === dataSet[j].name) {
        userListWithDetails.push(dataSet[j]);
        break;
      }
    }
  }

  return userListWithDetails;
};
