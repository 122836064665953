import React from "react";
import ReportIcon from "@mui/icons-material/Report";
import { Children, cloneElement, ReactElement } from "react";

/** Atom Component */

const WarningMsg = ({ msg }: { msg: string; children?: React.ReactNode }) => {
  return (
    <div className="my-[6px] text-default-error">
      <div className="flex gap-1">
        <ReportIcon color="error" />
        <p>{msg}</p>
      </div>
    </div>
  );
};

export default WarningMsg;
